import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row} from 'antd';
import { hsvToRgb, fullColorHex } from "../../utils/utils";

import "../../asset/style/neotileviewer/tile_viewer_fixed_component.css";

class ColorScheme extends Component {

    getAllColorRows = () => {
        let colorRows = [];
        for(let i = 0; i < 180; i++) {
            let backgroundColorRGB = hsvToRgb(i / 360, 1 , 1);
            let hexColor = fullColorHex(backgroundColorRGB.r, backgroundColorRGB.g, backgroundColorRGB.b);
            colorRows.push(
                <Row className="color-row" style={{background: hexColor}}></Row>
            );
        }
        return colorRows;
    }

    render() {

        return (
            <div className="color-scheme-div">
                {this.getAllColorRows()}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(ColorScheme);
