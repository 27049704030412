import React, {Component} from "react";
import {connect} from "react-redux";
import {labellingLayer} from "../utils/layers_info";
import {Grid} from "@mui/material";
import AnnotationsExpandableList from "./AnnotationsExpandableList";
import {locateAnnotationOnMap} from "../utils/map_utils";
import {AnnotationsConstants} from "../../../utils/const";
import {updateActiveAnnoDrawer} from "../../../action/gamma.state.action";

class LabellingApp extends Component {

	constructor(props) {
		super(props);

		this.drawer = labellingLayer.drawer;
	}

	componentDidMount() {
		this.props.dispatch(updateActiveAnnoDrawer(this.drawer));
	}

	componentWillUnmount() {
		this.props.dispatch(updateActiveAnnoDrawer(null));
	}

	initState = () => {
		this.mapState = this.props.mapsState[this.props.activeMapId];
		this.slideState = this.mapState.slideState;
		this.annotationState = this.mapState.annotationState;
		this.labellingState = this.mapState.labellingState;
		this.annotations = ((this.annotationState || {}).annotations || []).filter(annotation =>
			annotation.anno_drawer === this.drawer);
	}

	emptyComponent = () => <Grid>
		No Annotations Available
	</Grid>;

	render() {
		this.initState();

		if (this.annotations.length === 0)
			return this.emptyComponent();

		return <AnnotationsExpandableList annotations={this.annotations}
		                                  slideId={this.slideState.slide_data.id}
		                                  onAnnotationClick={(annotation) =>
			                                  locateAnnotationOnMap(this.slideState.slidemap, annotation)}
		                                  colors={AnnotationsConstants.ANNO_COLORS}
		                                  showDownload={true}/>
	}
}

const mapStateToProps = (state) => ({
	activeMapId: state.gammaStateReducer.activeMapId,
	mapsState: state.mapsStateReducer,
	trigger: state.triggerReducer,
});

export default connect(mapStateToProps)(LabellingApp);
