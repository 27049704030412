import React, { Component } from 'react';
import { Row, Col, Icon, Button, Progress } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import { AuthHeader } from '../../helper/auth.token';
import { updateLiveView, updateLiveViewUrl } from "../../action/liveview.action";
import { updateZStackLevel } from '../../action/admin.state.action';

import "../../asset/style/scanner/scanner.css";
import "../../asset/style/manualmode/camera_app.css";

class LiveModeCameraView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveView: "",
            maxZLevel: this.props.livemode.zStackLevels,
            minZLevel: (-1 * this.props.livemode.zStackLevels),
            percentCompleted: 0,
            timeStarted: 0,
        }

        this.liveImageRef = React.createRef();
        this.timer = null;
    }

    componentDidMount() {
        if (this.props.adminState.showZStackImages) {
            this.liveImageRef.current.addEventListener('wheel', this.imageScrollEvent);
        } else {
            this.liveImageRef.current.removeEventListener('wheel', this.imageScrollEvent);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.adminState.showZStackImages != this.props.adminState.showZStackImages) {
            if (this.props.adminState.showZStackImages) {
                this.liveImageRef.current.addEventListener('wheel', this.imageScrollEvent);
            } else {
                this.liveImageRef.current.removeEventListener('wheel', this.imageScrollEvent);
            }
        }
        if (prevProps.adminState.lastImageTimstampLiveMode != this.props.adminState.lastImageTimstampLiveMode) {
            this.setState({
                maxZLevel: this.props.livemode.zStackLevels,
                minZLevel: (-1 * this.props.livemode.zStackLevels),
            });
        }
        if (prevProps.livemode.imageFetching != this.props.livemode.imageFetching) {
            if(this.props.livemode.imageFetching) {
                this.setState({
                    percentCompleted: 0,
                    timeStarted: Date.now(),
                });
                this.timer = setInterval(this.changePercent, 10);
            } else {
                this.setState({
                    percentCompleted: 100,
                });
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    }

    componentWillUnmount = () => {
        this.liveImageRef.current.removeEventListener('wheel', this.imageScrollEvent);
        clearInterval(this.timer);
        this.timer = null;
    }

    changePercent = () => {
        let timeSpent = parseInt(Date.now() - this.state.timeStarted);
        if (this.state.percentCompleted < 85 && timeSpent >= 1300) {
            this.setState({
                percentCompleted: 85,
            });
        } else if (this.state.percentCompleted < 85 && timeSpent < 1300) {
            let percent = parseInt((timeSpent / 1300) * 85);
            if (percent != this.state.percentCompleted) {
                this.setState({
                    percentCompleted: percent,
                });
            }
        }
    }

    imageScrollEvent = (e) => {
        if (e.deltaY > 0) {
            this.decrementZLevel();
        } else if (e.deltaY < 0) {
            this.incrementZLevel();
        }
    }

    incrementZLevel = () => {
        if (this.props.adminState.zStackLevel < this.state.maxZLevel) {
            let newLevel = this.props.adminState.zStackLevel + 1;
            this.props.dispatch(updateZStackLevel(newLevel));
            if (newLevel == 0) {
                if (this.props.livemode.useLiveModeInViewMode) {
                   this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/0.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode)); 
                } else {
                    // this.props.dispatch(updateLiveViewUrl("data:image/png;base64," + this.props.liveView.data));
                    this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/0.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode));
                }
            } else {
                this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/${newLevel}.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode));
            }
        }
    }

    decrementZLevel = () => {
        if (this.props.adminState.zStackLevel > this.state.minZLevel) {
            let newLevel = this.props.adminState.zStackLevel - 1;
            this.props.dispatch(updateZStackLevel(newLevel));
            if (newLevel == 0) {
                if (this.props.livemode.useLiveModeInViewMode) {
                   this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/0.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode)); 
                } else {
                    // this.props.dispatch(updateLiveViewUrl("data:image/png;base64," + this.props.liveView.data));
                    this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/0.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode));
                }
            } else {
                this.props.dispatch(updateLiveViewUrl(`/dev-ssd/last_preview/z_stack/${this.props.adminState.slotID}/${newLevel}.jpg?time=` + this.props.adminState.lastImageTimstampLiveMode));
            }
        }
    }

    render() {

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className={"relativeParent"}>
                            <Row>
                                <Col span={24} style={{position: 'relative'}}>
                                    <div ref={this.liveImageRef} tabindex="-1">
                                        <Row>
                                            <img id="liveCameraImage" alt="Live" 
                                                src={this.props.liveView.url}
                                                // src="/dev-ssd/last_preview/fine_focus/test.jpg"
                                                width={'100%'} height={window.visualViewport.height - 20} style={{objectFit: 'none', marginTop: 10}} />
                                        </Row>
                                        <Row style={{lineHeight: 0}}>
                                            <Progress
                                                // status={this.props.livemode.imageFetching ? "active" : "success"}
                                                style={{lineHeight: 0}}
                                                percent={this.state.percentCompleted}
                                                strokeColor={this.state.percentCompleted < 100 ? "#40b7ff" : "#111111"}
                                                showInfo={false}
                                                className="ant-progress-inner-custom"
                                            />
                                        </Row>
                                    </div>
                                    {this.props.adminState.showZStackImages ? 
                                        <div style={{position: 'absolute', top: '2em', right: '1em'}}>
                                            <Row>
                                                <Button 
                                                    className={"zoom-controls-button-live-mode plus-button-live-mode"}
                                                    onClick={this.incrementZLevel}
                                                    disabled={this.props.adminState.zStackLevel >= this.state.maxZLevel}
                                                    >
                                                    <Icon className="zoom-controls-icon" type="plus" />
                                                </Button>
                                            </Row>
                                            <Row>
                                                <Col className="zoom-controls-button-live-mode zoom-level-button-live-mode">
                                                    <Row>{this.props.adminState.zStackLevel}</Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Button 
                                                    className="zoom-controls-button-live-mode minus-button-live-mode"
                                                    onClick={this.decrementZLevel}
                                                    disabled={this.props.adminState.zStackLevel <= this.state.minZLevel}
                                                    ><Icon className="zoom-controls-icon" type="minus" /></Button>
                                            </Row>
                                        </div> :
                                        null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(LiveModeCameraView);
