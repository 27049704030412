import React, { Component } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Button, Select } from 'antd';
import { connect } from 'react-redux'
import axios from 'axios';
import { AuthHeader } from '../../../helper/auth.token'
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import FileViewer from 'react-file-viewer';
import { globalUrlPrefix } from '../../../utils/const';
// import PDFViewer from 'pdf-viewer-reactjs'
import '../../../asset/style/neoviewer/uploaded_trf.css'

const { Option } = Select;

class TRFApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide_data: this.props.slideData,
            key:'',
            showPDF: false,
        };
    }

    componentDidMount = () => {
        // this.setState({ key: Math.random() });
        // this.forceUpdate();
        console.log("mounted")
    }

    componentDidUpdate = () => {
        console.log("update")
    }

    URLify = (string)=>{
        return string.trim().replace(/\s/g, '%20');
      }

    togglePDF = () => {
        let curr = this.state.showPDF
        this.setState({
            showPDF: !curr,
        })
    }

    redirectCliaCase = () => {
        window.location.href = "/" + globalUrlPrefix + "/clia_case/" + this.state.slide_data.case;
    }

    render() {
        // console.log("slide data",this.state.slide_data)
        let link = "/case-files/" + this.state.slide_data.case +"/case_trf/"+this.state.slide_data.case+".pdf"
        let linkified = this.URLify(link)
        let not_aval_content;
        if(this.state.slide_data.case == null){
            not_aval_content =  
                <div className="slide-info-app-parent-case-info overlayed-component app-parent" style={{height: '10em', width:'20em'}}>
                    <br></br>
                    <p>Please add the slide to a case first.</p>
                </div>
            
        }
        else{
            not_aval_content = <div className="slide-info-app-parent-case-info overlayed-component app-parent" style={{height: '10em', width:'20em'}}>
            <br></br>
            <p>Please Upload The Information First on Clia Page.</p>
            <Button style={{marginLeft:'50px'}} onClick={this.redirectCliaCase}>
                GO TO CLIA CASE
            </Button>
        </div>
        }
        console.log(linkified)
        if(this.state.slide_data.trf_uploaded == "false" || this.state.slide_data.trf_uploaded == false){
            // return (
            //     <div className="slide-info-app-parent-case-info overlayed-component app-parent" style={{height: '10em', width:'20em'}}>
            //         <br></br>
            //         <p>Please Upload The Information First on Clia Page.</p>
            //         <Button style={{marginLeft:'50px'}} onClick={this.redirectCliaCase}>
            //             GO TO CLIA CASE
            //         </Button>
            //     </div>
            // )
            return not_aval_content;
        }
        console.log("pdf", this.state.showPDF)
        let pdf_div = ""
        if(this.state.showPDF){
            pdf_div = <div>
            <div  style={{height: '30em'}}>
            <FileViewer
                fileType={'pdf'}
                filePath={linkified}
                key={this.state.key}
                // errorComponent={CustomErrorComponent}
                // onError={this.onError}
                />
            
            {/* <PDFViewer
                document={{
                    url: linkified,
                }}
                /> */}
                </div>
        </div>
        }
        return (
                <div>
                    <div className="slide-info-app-parent-case-info overlayed-component app-parent" style={{height: '37em', textAlign:'center'}}>
                        <Button type="primary" onClick={this.togglePDF}>{this.state.showPDF?"HIDE PDF":"SHOW PDF"}</Button>
                        {pdf_div}
                    </div>
                </div>
            )
        
        
    }
}

const mapStateToProps = (state) => {
    return {
        
    }
}

export default connect(mapStateToProps)(TRFApp)
