import React, {Component} from  "react";
import {Row, Col, Divider } from 'antd';
import {connect} from 'react-redux';


class PreviewComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device : []
        }
    }

    render(){
        return(
            <Row >
                <Col span={24} className="sidebar-card">
                    <h3 className='scanner-heading-mp'>Slide Preview</h3>
                    <Divider/>
                    <Col span={24} id='overview-map'>
                    </Col>
                    <Divider/>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching : state.deviceAlert.isFetching,
        loginStatus :  state.loginAlerts.loginStatus,
        device : state.deviceAlert.deviceData,
    }
}

export default connect(mapStateToProps)(PreviewComp)