import React, { Component } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Row, message, Button, Select } from 'antd';
import { connect } from 'react-redux'
import axios from 'axios';
import { AuthHeader } from '../../../helper/auth.token'
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';

import '../../../asset/style/neoviewer/cap_report.css'

const { Option } = Select;

class ReportApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.slideData,
            edit: false,
            name: this.props.slideData.name,
            specimen_type: this.props.slideData.specimen_type,
            case_details: (this.props.slideData.case_details === null || this.props.slideData.case_details === "") ? "NIL" : this.props.slideData.case_details,
            scan_time: 0.0,
            slide_meta: {},
            slide_time: new Date(this.props.slideData.date_time),
        };

        if (this.props.slideData.case != "" && this.props.slideData.case != null) {
            this.state.saved_cap_report = this.props.slideData.case.cap_report;
            this.state.editor_content = this.props.slideData.case.cap_report;
            this.state.cap_selected = false;
        }
    }

    componentDidMount = () => {
        let url = "/api/fetch_cap_map/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let cap_map = response.data;
                this.setState({
                    cap_map: cap_map
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onChange = (value) => {
        if (value.length > 0) {
            let url = "/api/fetch_cap_reporting_template/?search_key=" + value;
            axios
                .get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    var reporting_template_data = response.data;
                    this.setState({
                        editor_content: undefined
                    }, () => {
                        this.setState({
                            editor_content: reporting_template_data.content
                        });
                    })
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({
                reprting_type_selection_status: "error"
            });
        }
    }

    saveEditorContent = () => {
        let data = {
            'report': this.state.editor_content,
            'case_id': this.props.slideData.case.case_id
        };
        let url = '/api/upload_cap_report/';
        axios.post(url, data, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    saved_cap_report: this.state.editor_content
                });
                message.success("Added Report to Case " + this.props.slideData.case.case_id)
        });
    }

    handleEditorChange = (e) => {
        this.setState({
            editor_content: e.target.getContent()
        });
    }

    revertToLastSaved = () => {
        this.setState({
            editor_content: undefined
        }, () => {
            this.setState({
                editor_content: this.state.saved_cap_report
            })
        });
    }

    render() {

        let cap_report_name_options = this.state.cap_map != undefined ?
            this.state.cap_map.map((item, index) => (
                <Option key={item.search_key} value={item.search_key}>
                    {item.display_name}
                </Option>
            )) : undefined;

        let noTemplateSelectedDiv = [
            <Row key={0}>
                <ExclamationCircleOutlined className="no-case-icon" />
            </Row>,
            <Row key={1} className="no-case-div">
                <span>No Template Selected</span>
            </Row>
        ]

        let editor = this.state.editor_content != undefined ?
            <Row key={1}>
                <Editor
                    initialValue={this.state.editor_content}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table code help wordcount'
                        ],
                        toolbar: ''
                        // 'undo redo | formatselect | bold italic backcolor | \
                        // alignleft aligncenter alignright alignjustify | \
                        // bullist numlist outdent indent | removeformat | help'
                    }}
                    onChange={this.handleEditorChange}
                />
                <Button className="save" onClick={this.saveEditorContent}>Save</Button>
            </Row>
            : noTemplateSelectedDiv;

        let reportContent = [
            <Row key={0}>
                <Col span={16}>
                    <Select
                        showSearch
                        className="report-select"
                        placeholder="Select a Report Template"
                        optionFilterProp="children"
                        onChange={this.onChange}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {cap_report_name_options}
                    </Select>
                </Col>
                <Col span={8} className="revert">
                    <a onClick={this.revertToLastSaved} className="revert-text">Revert To Last Saved</a>
                </Col>
            </Row>,
            editor
        ]

        let noCaseSelectedDiv = [
            <Row key={0}>
                <ExclamationCircleOutlined className="no-case-icon" />
            </Row>,
            <Row key={1} className="no-case-div">
                <span>Please assign this slide to a case to create a report.</span><br></br>
                <Link to={'/neo/categories'}>Link to Cases</Link>
            </Row>
        ]

        return (
            <Row>
                <Col span={24} className="report-app-parent overlayed-component app-parent">
                    {this.props.slideData.case == "" ? noCaseSelectedDiv : reportContent}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus
    }
}

export default connect(mapStateToProps)(ReportApp)
