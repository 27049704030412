import React, { Component } from 'react';
import SlideMap from '../component/neoviewer/slidemap';
import queryString from 'query-string';
import axios from 'axios';
import {Link} from 'react-router-dom';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { closeDrawer } from "../action/dashboard.action";
import { makeSlideRead } from '../action/slides.action';
import { Spin, Statistic, Progress, Row, PageHeader, Divider } from 'antd';
import { globalUrlPrefix } from '../utils/const';
import { logout } from '../action/login.action';

const { Countdown } = Statistic;


export class Scan extends Component {

    constructor(props) {
        super(props);

        this.state = {
            stitching_done: true,
            stitch_percent: 0.0,
            stitch_time: 0.0,
            scan_ongoing: false,
            isFetching: true,
            errorCount: 0,
        }

        this.max_txty_time = 0;
        this.timer = null;
        this.query_params = queryString.parse(this.props.location.search);
        this.slide_id = this.props.match.params.id;
    }

    componentDidMount = () => {
        this.props.dispatch(makeSlideRead(this.props.match.params.id));
        this.pollForStitchingStatus();
        this.timer = setInterval(() => this.pollForStitchingStatus(), 2000);
        this.props.dispatch(closeDrawer());
    }

    componentDidUpdate = () => {
        if(this.state.errorCount == 3) {
            this.props.dispatch(logout());
        }
    }

    pollForStitchingStatus = () => {
        this.setState({
            isFetching: true
        });

        let url = '/server/slide_status?id=' + this.slide_id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                console.log(response.data);
                let json_data = JSON.parse(response.data);
                if (json_data.status === 0) {
                    this.setState({
                        scan_ongoing: true,
                        stitching_done: false,
                        isFetching: false
                    });
                }
                if (json_data.status === 12 || json_data.status === 9) {
                    this.setState({
                        stitching_done: true,
                        scan_ongoing: false,
                        isFetching: false
                    });
                    clearInterval(this.timer);
                    this.timer = null;
                } else {
                    let txty_time = parseFloat(json_data.txty_time) / 60;
                    this.max_txty_time = Math.max(this.max_txty_time, txty_time)
                    let stitcher_percent = json_data.stitcher_percent;
                    let stitcher_time = json_data.stitcher_estimated_time / 60000;
                    if (stitcher_time === 0) {
                        stitcher_time = this.max_txty_time;
                    }
                    let final_percent = (parseFloat(stitcher_percent)).toFixed(2);
                    let final_time = (stitcher_time).toFixed(2);
                    let time_left = Date.now() + (final_time * 60 * 1000);
                    this.setState({
                        stitch_percent: final_percent,
                        stitch_time: time_left,
                        stitching_done: false,
                        isFetching: false
                    });
                }
            })
            .catch(err => {
                this.setState({
                    errorCount: this.state.errorCount + 1,
                });
                console.log("Failed Getting Value", err);
            });
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.timer = null;
    }

    render() {

        let stitchingInProgressComponent = <Row key="progress">
            <Row style={{ textAlign: 'center' }}>
                <h2>Stitching in Progress</h2>
                <Progress style={{ marginTop: "1%", marginBottom: "1%" }} type="circle" percent={parseFloat(this.state.stitch_percent)} />
                <Link to={"/" + globalUrlPrefix + "/dashboard"}>
                    <h2>Click to go back to Dashboard</h2>
                </Link>
            </Row>
        </Row>;

        let scanOngoingComponent = <Row>
            <Row style={{ textAlign: 'center', marginTop:"5em" }}>
                <b>Slide is being scanned. <br></br>Progress will be updated soon.</b>
                <Link to={"/" + globalUrlPrefix + "/dashboard"} className="click-to-go-to-dash"><h2>Click to go back to Dashboard</h2></Link>
            </Row>
        </Row>;

        let viewerComponent;

        if(!this.state.scan_ongoing && this.state.stitching_done && !this.state.isFetching) {
            viewerComponent = <SlideMap key="viewer" slide_id={this.slide_id} map_id={0} />;
        }
        
        return (
            <div key="view">
                {
                    <Spin key="loading" tip="Loading..." spinning={this.state.isFetching} delay={500}>
                        {
                            (this.state.scan_ongoing === true) ? 
                                scanOngoingComponent : 
                                ((this.state.stitching_done === true) ? 
                                    viewerComponent : 
                                    stitchingInProgressComponent)
                        }
                    </Spin>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(Scan);
