import React, {Component} from 'react';
import {Col, Row} from 'antd';
import {connect} from 'react-redux'
import axios from 'axios';
import {AuthHeader} from '../../../helper/auth.token'
import { PDFReader } from 'react-read-pdf';

import '../../../asset/style/neoviewer/cap_guide.css'

import { Select } from 'antd';

const { Option } = Select;

class CapGuideApp extends Component {
    constructor(props){
        super(props);

        this.state = {
            data :  this.props.slideData,
            edit : false,
            name : this.props.slideData.name,
            specimen_type :this.props.slideData.specimen_type,
            case_details : (this.props.slideData.case_details === null || this.props.slideData.case_details === "")?"NIL":this.props.slideData.case_details,
            scan_time: 0.0,
            slide_meta: {}, 
            slide_time: new Date(this.props.slideData.date_time),
        };
    }

    componentDidMount = () => {
        let url = "/api/fetch_cap_map/";
        axios.get(url, {headers: {Authorization : AuthHeader()}})
            .then(response => {
                let cap_map = response.data;
                this.setState({
                    cap_map: cap_map
                });
            })
            .catch(err => {
                console.log(err);
            });
        
        axios.get("/api/fetch_cap_pdf_lookup/", {headers: {Authorization : AuthHeader()}})
            .then(response => {
                let cap_pdf_lookup = response.data;
                this.setState({
                    cap_pdf_lookup: cap_pdf_lookup
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    onChange = (value) => {
        if (value.length > 0) {
            this.setState({
                cap_pdf_link: undefined
            }, () => {
                this.setState({
                    cap_pdf_link: this.state.cap_pdf_lookup[value]['pdf_link']
                });
            })
        } else {
            this.setState({
                reprting_type_selection_status: "error"
            });
        }
    }
    
    render() {
        
        let cap_report_name_options = this.state.cap_map != undefined ? 
            this.state.cap_map.map((item, index) => (
                <Option key={item.search_key} value={item.search_key}>
                    {item.display_name}
                </Option>
            )) : undefined;

        let cap_pdf_view = this.state.cap_pdf_link != undefined ? 
            <Row className="cap-pdf-div scrollbar">
                <PDFReader 
                    scale={1}
                    url={this.state.cap_pdf_link}/>
            </Row>
            : undefined;

        return(
            <div>
            <Row>
                <Col span={24} className="cap-guide-app-parent overlayed-component app-parent">
                    <Row>
                        <Col span={16} offset={4}>
                            <Select
                                className="cap-guide-select"
                                placeholder="Select a CAP Guide"
                                optionFilterProp="children"
                                onChange={this.onChange}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                >
                                {cap_report_name_options}
                            </Select>
                        </Col>
                    </Row>
                    {cap_pdf_view}
                </Col>
            </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus
    }
}

export default connect(mapStateToProps)(CapGuideApp)
