import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { CheckCircleOutlined } from '@ant-design/icons';
import cookie from "react-cookies";
import { Spin, Row, Col, PageHeader, Timeline, Button, Input, message } from 'antd';
import { globalUrlPrefix } from "../utils/const";

const { TextArea } = Input;

export class StageCleaner extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            deviceId: this.props.match.params.id,
            viewingMode: -1,
            outputMessage: '',
            startStep: -1,
            currentStep: -1,
        }

        this.steps = ["Start", "Rotate knob towards A", "Pull the stage out", "Clean the stage", "Put stage", "Rotate knob towards B", "Check Stage Position", "All Done"];

    }

    goToSettings = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/settings/" + this.state.deviceId;
    }

    getTimelineItems = () => {
        let timelineItems = [];
        for (let i = this.state.startStep; i < this.state.currentStep + 1; i++) {
            timelineItems.push(this.getCurrentTimelineItem(i));
        }
        return timelineItems;
    }

    getCurrentTimelineItem = (index) => {
        if (index == 0 && this.state.currentStep == 0) {
            return (
                <Timeline.Item>
                    <Button type="primary" size="small" onClick={this.stageCleaningProcess}>
                        Start
                    </Button>
                </Timeline.Item>
            )
        }

        if ((index == 1 && this.state.currentStep == 1)) {
            return (
                <Timeline.Item>
                    {this.steps[index]} &nbsp; &nbsp; &nbsp;
                    <Button type="primary" size="small" onClick={() => { this.checkStage(false) }}>
                        Check
                    </Button>
                </Timeline.Item>
            )
        }

        if (index == this.steps.length - 2 && this.state.currentStep == this.steps.length - 2) {
            return (
                <Timeline.Item>
                    <Button type="primary" size="small" onClick={() => { this.checkStage(true) }}>
                        Check Stage
                    </Button>
                </Timeline.Item>
            )
        }

        if (!(index == this.state.currentStep) || index == this.steps.length - 1) {
            return (
                <Timeline.Item dot={<CheckCircleOutlined style={{ fontSize: '16px' }} />} color="green">
                    {this.steps[index]}
                </Timeline.Item>
            );
        } else {
            return (
                <Timeline.Item>
                    {this.steps[index]} &nbsp; &nbsp; &nbsp;
                    <Button type="primary" size="small" onClick={() => this.updateStep(index + 1)}>
                        Done
                    </Button>
                </Timeline.Item>
            )
        }
    }

    stageCleaningProcess = () => {

        this.setState({
            isFetching: true,
        })

        let url = "/server/devices/" + this.state.deviceId + "/manualmode/start-stage-removal/"
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    currentStep: 1,
                    outputMessage: response.data,
                    isFetching: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    outputMessage: err.response.data,
                    isFetching: false,
                });
            });
    }

    checkStage = (ifEngaged=false) => {

        this.setState({
            isFetching: true,
        })

        let url = "/server/devices/" + this.state.deviceId + "/manualmode/check-stage/"
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let crs = this.state.currentStep
                let mess = response.data;
                if((response.status == 200 && !ifEngaged) || (response.status == 206 && ifEngaged)){
                    crs++;
                }
                else if(response.status == 205){
                    crs++;
                    mess = "Perfect. Please go ahead with next step.";
                }
                else{
                    mess = "Stage not in required Position! Rotate Knob and Try again."
                }
                this.setState({
                    currentStep: crs,
                    outputMessage: mess,
                    isFetching: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    outputMessage: err.response.data,
                    isFetching: false,
                });
            });
    }

    changeCassetteTemplate = () => {

        this.setState({
            isFetching: true,
        })

        let url = "/server/devices/" + this.state.deviceId + "/manualmode/update-cassette-template/"
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if(response.status == 200){
                    message.success("Template Changed Successfully")
                }
                else{
                    message.error(response.data)
                }
                this.setState({
                    isFetching: false,
                })
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    outputMessage: err.response.data,
                    isFetching: false,
                });
            });
    }

    updateStep = (step) => {
        this.setState({
            currentStep: step,
        });
    }

    getPageHeadingRow = (title) => {
        return (
            <Row>
                <Col span={20}>
                    <PageHeader style={{ backgroundColor: "#ffffff00", paddingBottom: "10px" }} title={title}></PageHeader>
                </Col>
                <Col span={4} style={{ padding: '16px 24px' }}>
                    <Button type="primary" onClick={this.goToSettings} ghost>
                        Back to Settings
                    </Button>
                </Col>
            </Row>
        )
    }

    getTimelineRow = () => {
        return (
            <Row>
                <Col offset={1} span={5}>
                    <Timeline>
                        {this.getTimelineItems()}
                    </Timeline>
                </Col>
                <Col span={4}>
                    <Row>
                        <b>Output Message</b>
                    </Row>
                    <br/>
                    <Row>
                        <TextArea
                            autosize={{ minRows: 6, maxRows: 6 }}
                            value={this.state.outputMessage}
                            style={{color: "black"}}
                            disabled
                        />
                    </Row>
                </Col>
            </Row>
        );
    }

    changeViewingMode = (mode, step) => {
        this.setState({
            viewingMode: mode,
            startStep: step,
            currentStep: step,
        })
    }

    render() {

        let viewer = undefined;

        if (this.state.viewingMode == -1) {

            if (cookie.loadAll().superuser === 'true') {
            viewer = (
                <Row>
                    {this.getPageHeadingRow("Stage Actions")}
                    <br />
                    <Col offset={2} span={3}>
                        <Button type="primary" onClick={() => this.changeViewingMode(1, 4)}>
                            Insert Stage
                        </Button>
                    </Col>
                    <Col offset={2} span={3}>
                        <Button type="primary" onClick={() => this.changeViewingMode(2, 0)}>
                            Stage Cleaning Routine
                        </Button>
                    </Col>
                    <Col offset={2} span={3}>
                        <Button type="primary" onClick={this.changeCassetteTemplate}>
                            Change Cassette Template
                        </Button>
                    </Col>
                </Row>
            );
            } else {
                viewer = (
                    <Row>
                        {this.getPageHeadingRow("Stage Actions")}
                        <br />
                        <Col offset={2} span={3}>
                            <Button type="primary" onClick={() => this.changeViewingMode(1, 4)}>
                                Insert Stage
                            </Button>
                        </Col>
                        <Col span={3}>
                            <Button type="primary" onClick={() => this.changeViewingMode(2, 0)}>
                                Stage Cleaning Routine
                            </Button>
                        </Col>
                    </Row>
                );
                }
        } else if (this.state.viewingMode == 1) {
            viewer = (
                <Row>
                    {this.getPageHeadingRow("Insert Stage")}
                    <br />
                    {this.getTimelineRow()}
                </Row>
            );
        } else if (this.state.viewingMode == 2) {
            viewer = (
                <Row>
                    {this.getPageHeadingRow("Stage Cleaning Routine")}
                    <br />
                    {this.getTimelineRow()}
                </Row>
            );
        }

        return (
            <div>
                <Spin tip="Loading..." spinning={this.state.isFetching}>
                    {viewer}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(StageCleaner);
