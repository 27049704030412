import React, { Component } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, PageHeader, Divider, Input, Select } from "antd";
import { connect } from "react-redux";
import { message, List } from "antd";
import { AuthHeader } from "../../helper/auth.token";
import axios from "axios";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { setUIBusy, setUIIdle } from "../../action/utils.action";
import { DatePicker } from 'antd';
import moment from 'moment';
import { Upload, Modal } from 'antd';
import { globalUrlPrefix } from "../../utils/const";
import { dictCaseEditComp } from '../../utils/dict_case_edit_comp';


const { Option } = Select;
const { TextArea } = Input;
const ref = React.createRef();

class CaseComp extends Component {
  constructor(props) {
    super(props);

    let slideImageList = _.filter(this.props.case.images, (image) => {
      return image.image_type=='SLIDE'
    });

    let grossImageList = _.filter(this.props.case.images, (image) => {
      return image.image_type=='GROSS'
    });

    let slideFileList = _.map(slideImageList, (slideImage) => {
      let newImage = {
        uid: slideImage.id,
        name: slideImage.path.split('/').pop(),
        status: 'done',
        url: slideImage.path,
        label: slideImage.label,
        storage_path: slideImage.storage_path
      }
      return newImage;  
    });

    let grossFileList = _.map(grossImageList, (slideImage) => {
      let newImage = {
        uid: slideImage.id,
        name: slideImage.path.split('/').pop(),
        status: 'done',
        url: slideImage.path,
        label: slideImage.label,
        storage_path: slideImage.storage_path
      }
      return newImage;  
    });

    this.state = {
      case_name: this.props.case.case_id,
      patient_name: this.props.case.patient_name,
      age_and_sex: this.props.case.age_and_sex,
      referred_by: this.props.case.referred_by,
      receiving_date: this.props.case.receiving_date,
      reporting_date: this.props.case.reporting_date,
      clinical_details: this.props.case.clinical_details,
      specimen_type: this.props.case.specimen_type,
      reporting_system: this.props.case.reporting_system,
      staining_type: this.props.case.staining_type,
      adequacy: this.props.case.adequacy,
      gross: this.props.case.gross,
      microscopy: this.props.case.microscopy,
      impression: this.props.case.impression,

      // slide_list: this.props.case.slide_list,
      isFetching: false,
      all_slide_list: [],
      dropDownOpen: false,

      previewVisible: false,
      previewImage: '',
      slideFileList: slideFileList,
      grossFileList: grossFileList,
    }
  }

  getCaseImages = (case_id) => {
    let url = `/api/get_case_image_list/?case_id=${case_id}`;
    axios
      .get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
        let slideFileList = response.data.slide_image_list.map((fileData, index) => {
          let file = fileData.path;
          let newImage = {
            uid: index,
            name: file.split('/').pop(),
            status: 'done',
            url: file,
            label: fileData.label,
            storage_path: fileData.storage_path
          }
          return newImage;  
        });
        let grossFileList = response.data.gross_image_list.map((fileData, index) => {
          let file = fileData.path;
          let newImage = {
            uid: index,
            name: file.split('/').pop(),
            status: 'done',
            url: file,
            label: fileData.label,
            storage_path: fileData.storage_path
          }
          return newImage;  
        });
        this.setState(prevState => ({
          slideFileList: slideFileList,
          grossFileList: grossFileList
        }));
      })
      .catch(err => {
        message.error("Case could not be found. Contact Admin.");
        console.log(err);
      });
  }

  handleChangeGrossImage = ({ fileList }) =>  {
    let caseImageList = _.map(fileList, (caseImage) => {
        if(caseImage.response) {
            let newImage = {
                uid: caseImage.response.result.id,
                name: caseImage.response.result.path.split('/').pop(),
                status: 'done',
                url: caseImage.response.result.path,
                label: caseImage.response.result.label,
                storage_path: caseImage.response.result.storage_path
            }
            return newImage; 
        } else {
            return caseImage; 
        }
         
    });
    this.setState({         
      grossFileList: caseImageList 
    });
  }

  handleChangeSlideImage = ({ fileList }) => this.setState({ 
    slideFileList: fileList });
  

  handleCancel = () => this.setState({ previewVisible: false });

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  saveEverything = () => {
    this.props.dispatch(setUIBusy("Saving"));
    let url = `/api/case/${this.props.case.id}/`;
    let slideImages = this.state.slideFileList.map((image) => {
      return {
        id: image.uid,
        path: image.url,
        label: image.label,
        storage_path: image.storage_path, 
        case: this.props.case.id,
        image_type: "SLIDE"
      }
    });
    let grossImages = this.state.grossFileList.map((image) => {
      return {
        id: image.uid,
        path: image.url,
        storage_path: image.storage_path, 
        label: image.label,
        case: this.props.case.id,
        image_type: "GROSS"
      }
    });
    let images = slideImages.concat(grossImages);
    let value = {
      case_category: this.props.case.case_category,
      case_id: this.state.case_name,
      patient_name: this.state.patient_name,
      age_and_sex: this.state.age_and_sex,
      referred_by: this.state.referred_by,
      receiving_date: this.state.receiving_date,
      reporting_date: this.state.reporting_date,
      clinical_details: this.state.clinical_details,
      specimen_type: this.state.specimen_type,
      reporting_system: this.state.reporting_system,
      staining_type: this.state.staining_type,
      adequacy: this.state.adequacy,
      gross: this.state.gross,
      microscopy: this.state.microscopy,
      impression: this.state.impression,
      images: images
    }
    axios.put(url, value, {headers: {Authorization: AuthHeader() }})
    .then(response => {
      message.success("Updated Case Details Successfully");
      this.props.dispatch(setUIIdle());
    })
    .catch(err => {
      message.error("Failed to Save Case. Contact Admin");
      console.log(err);
      this.props.dispatch(setUIIdle());
    });
  }

  changeCaseName = (event) => {
    this.setState({
      case_name: event.target.value
    });
  }

  changeAdequacy = (event) => {
    this.setState({
      adequacy: event.target.value
    });
  }

  changeAgeSex = (event) => {
    this.setState({
      age_and_sex: event.target.value
    });
  }

  changePatientName = (event) => {
    this.setState({
      patient_name: event.target.value
    });
  }

  changeClinicalDetails = (event) => {
    this.setState({
      clinical_details: event.target.value
    });
  }

  changeGross = (event) => {
    this.setState({
      gross: event.target.value
    });
  }

  changeImpression = (event) => {
    this.setState({
      impression: event.target.value
    });
  }

  changeMicroscopy = (event) => {
    this.setState({
      microscopy: event.target.value
    });
  }

  changeStainingType = (event) => {
    this.setState({
      staining_type: event.target.value
    });
  }

  changeReportingSystem = (event) => {
    this.setState({
      reporting_system: event.target.value
    });
  }

  changeSpecimenType = (event) => {
    this.setState({
      specimen_type: event.target.value
    });
  }

  changeReferrerName = (event) => {
    this.setState({
      referred_by: event.target.value
    });
  }

  onChangeReceivingDate = (date, dateString) => {
    this.setState({
      receiving_date: dateString
    });
  }

  onChangeReportingDate = (date, dateString) => {
    this.setState({
      reporting_date: dateString
    });
  }

  getSlidesBySearch = (value) => {
    this.setState({
      isFetching: true
    });
    let url = `/api/nonpagslides/?&search=${value}&ordering=-date_time`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
          let slide_list = response.data;
          this.setState({
            isFetching: false,
            all_slide_list: slide_list,
          });
        })
        .catch(err => {
          message.error("Slides could not be loaded. Contact Admin.");
          this.setState({
              isFetching: false
          });
          console.log(err);
        });
  }


  componentDidMount = () => {
    // this.getSlidesBySearch("");
  }

  addToSlideList = (slide_id) => {
    let url = `/api/add_slide_case/${this.props.case.id}/?slide_id=${slide_id}`;
    axios.get(url, {headers: {Authorization: AuthHeader() }})
    .then(response => {
    })
    .catch(err => {
      message.error("Failed to Save Case. Contact Admin");
      console.log(err);
    });
  }

  removeFromSlideList = (slide_id) => {
    let url = `/api/remove_slide_case/${this.props.case.id}/?slide_id=${slide_id}`;
    axios.get(url, {headers: {Authorization: AuthHeader() }})
    .then(response => {
    })
    .catch(err => {
      message.error("Failed to Save Case. Contact Admin");
      console.log(err);
    });
  }

  handleNewSlide = (value) => {
    let id = value.key;
    let jsonData = {
      'id': parseInt(value.key),
      'slide_name': value.label
    };
    this.setState(prevState => ({
      slide_list: [...prevState.slide_list, jsonData]
    }), () => {
      this.addToSlideList(id);
    });
  }

  getPdf = () => {
    let url = `/api/get_pdf/?case_id=${this.state.case_name}`;
    window.open(url);
  }

  handleDeselect = (value) => {
    let slide_list = this.state.slide_list;
    let id = value.key;
    let idToRemove = -1;
    for (var i = 0; i < slide_list.length; i ++) {
      if (id == slide_list[i].id) {
        idToRemove = i;
        break;
      }
    } 
    if (idToRemove != -1) slide_list.splice(idToRemove, 1);
    this.setState({
        slide_list: slide_list
      }, () => this.removeFromSlideList(id));
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  removeSlideImageFromReport = (file) => {
    let url = `/api/remove_case_image/?case_id=${this.state.case_name}&file=${file.uid}`;
    axios.get(url, {headers: {Authorization: AuthHeader() }})
    .then(response => {
      let slide_file_list = this.state.slideFileList;
      _.remove(slide_file_list, (val) => {
        return file.uid === val.uid
      });
      this.setState({
        slideFileList: slide_file_list
      });
      message.success("Removed Slide Image from report " + file.name);
    })
    .catch(err => {
      message.error("Failed to Save Case. Contact Admin");
      console.log(err);
    });

  }

  removeGrossImageFromReport = (file) => {
    let url = `/api/remove_case_image/?case_id=${this.state.case_name}&file=${file.uid}`;
    axios.get(url, {headers: {Authorization: AuthHeader() }})
    .then(response => {
      let slide_file_list = this.state.grossFileList;
      _.remove(slide_file_list, (val) => {
        return file.uid === val.uid
      });
      this.setState({
        grossFileList: slide_file_list
      });
      message.success("Removed Gross Image from report " + file.name);
    })
    .catch(err => {
      message.error("Failed to Save Case. Contact Admin");
      console.log(err);
    });
  }

  getInputRow(key, title, placeholder, value, callback) {
    return <Row key={key}>
      <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px"}} title={title}></PageHeader>
      <Input placeholder={placeholder} value={value} onChange={callback} disabled={true} />
    </Row>
  }

  getDatePickerRow(key, title, value, callback) {
    return <Row key={key}>
      <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px"}} title={title}></PageHeader>
      <DatePicker style={{ width : "100%" }} value={value} onChange={callback} />
    </Row>
  }

  getTextAreaRow(key, title, placeholder, value, callback) {
    return <Row key={key}>
      <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px"}} title={title}></PageHeader>
      <TextArea autosize={dictCaseEditComp.TEXTAREA_ROWS} placeholder={placeholder} value={value} onChange={callback} />
    </Row>
  }
  
  render() {

    let case_name = [
      this.getInputRow("case_name", "Case ID", "Case Identifier", this.state.case_name, this.changeCaseName)
    ];

    let patient_name = [
      this.getInputRow("patient_name", "Patient Name", "Patient Name", this.state.patient_name, this.changePatientName)
    ];

    let referrer_name = [
      this.getInputRow("referrer_name", "Referred By", "Referrer Name", this.state.referred_by, this.changeReferrerName)
    ];

    let age_sex = [
      this.getInputRow("age_sex", "DOB/Sex", "X/M or F", this.state.age_and_sex, this.changeAgeSex)
    ];

    let receiving_date = [
      this.getDatePickerRow("receiving_date", "Receiving Date", new moment(this.state.receiving_date), this.onChangeReceivingDate)
    ];

    let reporting_date = [
      this.getDatePickerRow("reporting_date", "Reporting Date", new moment(this.state.reporting_date), this.onChangeReportingDate)
    ];

    let clinical_details = [
      this.getTextAreaRow("clinical_details", "Clinical Details", "Clinical Details", this.state.clinical_details, this.changeClinicalDetails)
    ];

    let reporting_system = [
      this.getTextAreaRow("reporting_system", "Reporting System", "Reporting System", this.state.reporting_system, this.changeReportingSystem)
    ];

    let staining_type = [
      this.getTextAreaRow("staining_type", "Staining Type", "Staining Type", this.state.staining_type, this.changeStainingType)
    ];

    let adequacy = [
      this.getTextAreaRow("adequacy", "Adequacy", "Adequacy", this.state.adequacy, this.changeAdequacy)
    ];

    let microscopy = [
      this.getTextAreaRow("microscopy", "Microscopy", "Microscopy", this.state.microscopy, this.changeMicroscopy)
    ];

    let specimen_type = [
      this.getTextAreaRow("specimen_type", "Specimen Type", "Specimen Type", this.state.specimen_type, this.changeSpecimenType)
    ];

    let impression = [
      this.getTextAreaRow("impression", "Impression", "Impression", this.state.impression, this.changeImpression)
    ];

    let gross = [
      this.getTextAreaRow("gross", "Gross Findings", "Gross Findings", this.state.gross, this.changeGross)
    ];

    let renderSlide = (item) => {
      let val = item;
      return (
        <div className="caseViewSlideItem">
          <Link to={"/" + globalUrlPrefix + "/viewer/" + val.id}><List.Item>{val.slide_name}</List.Item></Link>
        </div>
      );
    }

    let uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return(
      <Row key="complete">
        <div ref={ref}>
        <Col ref={ref}>
          <div style={{ textAlign: 'center' }}>
            <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px", paddingTop: "26px"}} title="Case Data"></PageHeader>
          </div>
          <Divider style={{ height: "2px", backgroundColor: "#36454fff"}} />
          <Col span={8} offset={0}>
              {case_name}
          </Col>
          <Col span={7} offset={1}>
              {patient_name}
          </Col>
          <Col span={7} offset={1}>
              {age_sex}
          </Col>
          <Divider style={{backgroundColor:"#ffffff00"}} />
          <Col span={8} offset={0}>
              {referrer_name}
          </Col>
          <Col span={7} offset={1}>
              {receiving_date}
          </Col>
          <Col span={7} offset={1}>
              {reporting_date}
          </Col>
          <Divider style={{backgroundColor:"#ffffff00"}} />
          <div style={{ textAlign: 'center' }}>
            <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px", paddingTop: "26px"}}  title="Diagnosis Data"></PageHeader>
          </div>
          <Divider style={{ height: "2px", backgroundColor: "#36454fff"}} />
          <Row>
            <Col span={12}>
              {clinical_details}
            </Col>
            <Col span={11} offset={1}>
              {specimen_type}
            </Col>
            <Divider style={{backgroundColor:"#ffffff00"}} />
            <Col span={12} offset={0}>
              {reporting_system}
            </Col>
            <Col span={11} offset={1}>
              {staining_type}
            </Col>
            <Divider style={{backgroundColor:"#ffffff00"}} />
            <Col span={12} offset={0}>
              {adequacy}
            </Col>
            <Col span={11} offset={1}>
              {gross}
            </Col>
            <Divider style={{backgroundColor:"#ffffff00"}} />
            <Col span={12} offset={0}>
              {microscopy}
            </Col>
            <Col span={11} offset={1}>
              {impression}
            </Col>
          </Row>
          <Divider style={{backgroundColor:"#ffffff00"}} />
        <div style={{ textAlign: 'center' }}>

          <PageHeader style={{backgroundColor:"#ffffff00", paddingBottom: "10px", paddingTop: "26px"}} title="Gross Images"></PageHeader>
          <Divider style={{ height: "2px", backgroundColor: "#36454fff"}} />

          <Upload
            listType="picture"
            action="/api/upload_gross_image/"
            headers={{Authorization: AuthHeader() }}
            fileList={this.state.grossFileList}
            data={{'case_id': this.state.case_name}}
            onPreview={this.handlePreview}
            onChange={this.handleChangeGrossImage}
            onRemove={this.removeGrossImageFromReport}
            className="upload-list-inline"
          >
          {this.state.slideFileList.length >= 8 ? null : uploadButton}
          </Upload>
          <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>
          <Divider style={{backgroundColor:"#ffffff00"}} />

        </div>
        </Col>
        </div>
       
      <Col span={9} offset={7}>
        <Button key="save-button" type="primary" style={{ backgroundColor: "#5ea6e8", width: "100%"}} onClick={this.saveEverything}>Save Data</Button>
      </Col>
      <Col span={7} offset={1}>
        {/* <Button key="download-button" type="primary" style={{ backgroundColor: "#51b151", width: "100%"}} onClick={this.getPdf}>Generate Report</Button> */}
      </Col>
      <Divider/>
    </Row>

    );    
  }
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(CaseComp);