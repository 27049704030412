import React, {Component} from  "react";
import { connect } from "react-redux";
import axios from "axios";
import { CheckCircleTwoTone, EllipsisOutlined } from '@ant-design/icons';
import { Row, Col, Button, Divider, message, Progress } from 'antd';
import { setDeviceIdle, setDeviceBusy } from '../../action/device_status.action';
import { initPreviewForScan } from '../../action/preview_status.action';
import { errorCodeMap } from '../../utils/scanner_error_codes'
import { getScanProfileChooser } from './loader_wf_utils'
import { setScanSpeed, setScanSpeedForTray, setSpecimenTypeForTray, setObjectiveTypeForTray, setSpecimenType, setObjectiveType} from "../../action/preview_status.action"

class Tray extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleProfileSelector: false
        }
    }

    resetTray = (eject) => {
        this.props.dispatch(setDeviceBusy(this.props.id, eject ? "Ejecting" : "Resetting"));
        let url = '/server/devices/' + this.props.id + '/loader/' + (eject ? 'unload_tray' : 'reset_tray') + '/?tray=' + this.props.tray.trayNumber + '&rack=' + this.props.tray.rackNumber;
        axios.get(url)
        .then(response => {
            if (response.status === 200) {
                for (let i = 0; i < this.props.cassetteSize; i++) {
                    this.props.dispatch(initPreviewForScan([i, this.props.tray.trayNumber, this.props.tray.rackNumber], this.props.defaultConfig));
                }
            }
            else if (response.status != 200 && response.status in errorCodeMap) {
                message.warn(errorCodeMap[response.status].message);
            }
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
        .catch(err => {
            message.error("Eject Failed. Please contact administrator.")
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
    }

    unloadFromStage = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Unloading Cassette From Stage"));
        let url = '/server/devices/' + this.props.id + '/loader/unload_stage/?tray=' + this.props.tray.trayNumber + '&rack=' + this.props.tray.rackNumber;
        axios.get(url)
        .then(response => {
            if (response.status === 200) {
                this.props.dispatch(setDeviceIdle(this.props.id));
            }
            else {
                message.error("Unload from Stage Failed. Please contact administrator.")
                this.props.dispatch(setDeviceIdle(this.props.id));
            }
        })
        .catch(err => {
            message.error("Unload from Stage Failed. Please contact administrator.")
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
    }

    getSlotComponent = (i) => {

        let slideColSpan = 24 / this.props.cassetteSize;
        let slotIsActive = i == this.props.activeSlot && this.props.trayIsActive;
        let slotDiv;
        let unselectable;

        let slotFinished = (this.props.tray.onStage && ((this.props.cassetteStats || {})[i] || {scanCompleted: false}).scanCompleted);
        let trayFinished = this.props.tray.finished;
        if (trayFinished || slotFinished) {
            unselectable = true;
            if (this.props.tray.slotList[i].scanStatistics.morphleID == null) {
                slotDiv =   <EllipsisOutlined style={{lineHeight:"2em", verticalAlign:"center"}} />
            } else {
                slotDiv = <a style={{padding:10}} href={"/server/v4/tissue/" + this.props.tray.slotList[i].scanStatistics.morphleID}>
                            <CheckCircleTwoTone style={{lineHeight:"2em", verticalAlign:"center"}} twoToneColor="#52c41a" />
                        </a>
            }
        } else if ((this.props.tray.onStage && !((this.props.cassetteStats || {})[i] || {scanCompleted: true}).scanCompleted)) {
            unselectable = true;
            let PERCENT_LIMIT = 85;
            let currentScanProgress = Math.min(PERCENT_LIMIT, (this.props.cassetteStats[i] || {percentDone: 0}).percentDone).toFixed(2);
            slotDiv = <Progress style={{fontSize:10}} type="circle" width={40} percent={parseFloat(currentScanProgress)} />;
        } else if (this.props.tray.onStage && this.props.tray.started && (this.props.cassetteStats || {})[i] == undefined) {
            unselectable = true;
            slotDiv = <div className="slot-not-scheduled unselectable">Not Scheduled</div>;
        } else if (this.props.tray.previewsTaken) {
            let previewResponse = this.props.tray.slotList[i].previewResponse;
            slotDiv = <img className="tray-slide-preview-img" src={'/scan-ssd/' + (previewResponse || {}).rotatedThumbnail}></img>;
        }

        let isBarcodeFound = ((this.props.previews[[i, this.props.tray.trayNumber, this.props.tray.rackNumber]] || {}).response || {}).barcodeReadSuccess;

        return <Col 
                    key={i}
                    span={slideColSpan} 
                    className={slotIsActive ? "loader-active-slot-div": ""}
                    onClick={() => unselectable ? undefined : this.props.activateSlot(i, this.props.tray.trayNumber, this.props.rackNumber)}>
                    <Row className={"tray-slide-preview-row" + (trayFinished ? "-finished" : "")}>
                        {slotDiv}
                    </Row>
                    <Row className={"tray-slide-preview-tag" + (isBarcodeFound? "":" errored")}>
                        {i + 1}
                    </Row>
                </Col>
    }

    hideScanProfileSelector = () => {
        this.setState({
          visibleProfileSelector: false,
        });
    };
    
    handleVisibleChange = visibleProfileSelector => {
        this.setState({ visibleProfileSelector });
    };

    onScanSpeedChange = (event) => {
        this.props.dispatch(setScanSpeedForTray([this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        for(let i=0;i<this.props.cassetteSize;i++){
            this.props.dispatch(setScanSpeed([i, this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        }
    };

    onScanSpecimenChange = (event) => {
        this.props.dispatch(setSpecimenTypeForTray([this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        for(let i=0;i<this.props.cassetteSize;i++){
            this.props.dispatch(setSpecimenType([i, this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        }
    };

    onScanObjectiveChange = (event) => {
        this.props.dispatch(setObjectiveTypeForTray([this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        for(let i=0;i<this.props.cassetteSize;i++){
            this.props.dispatch(setObjectiveType([i, this.props.tray.trayNumber, this.props.tray.rackNumber], event.target.value));
        }
    };


    render() {

        let trayDiv = undefined;
        let previewNotTaken = false;
        let slidesDiv = [];
        
        for (let ci = 0; ci < this.props.cassetteSize; ci++) {
            let previewResponse = (((this.props.tray || {}).slotList || {})[ci] || {}).previewResponse;
            
            if (previewResponse == undefined && !this.props.tray.finished && !this.props.tray.started && !this.props.scannerStatus.scanning) {
                previewNotTaken = true;
                break;
            }
            
            slidesDiv.push(this.getSlotComponent(ci));
        }

        let trayActions = this.props.tray.onStage ? 
                            <div onClick={this.unloadFromStage} className="loader-tray-action-div loader-tray-action">Unload from Stage</div> 
                            : !this.props.isPowerFailure ? <div className="loader-tray-action-div">
                                <span className="loader-tray-action" onClick={() => this.resetTray(false)}>Reset</span> | <span className="loader-tray-action" onClick={() => this.resetTray(true)}>Eject</span>
                            </div> : undefined;

        let currentScanProfile = this.props.previews.scanProfiles[[this.props.tray.trayNumber, this.props.tray.rackNumber]] || this.props.previews.scanProfiles[-1];
        let scanProfileChooser = getScanProfileChooser(currentScanProfile, 
                this.onScanSpeedChange, this.onScanSpecimenChange, 
                this.onScanObjectiveChange, this.hideScanProfileSelector, 
                this.state.visibleProfileSelector, this.handleVisibleChange, 
                this.props.isPowerFailure || this.props.scannerStatus.takingPreviews || this.props.scannerStatus.scanning);


        let header = <Col span={24}>
            <Row className="tray-header">
                Cassette {this.props.tray.trayNumber + 1}
                <div className="loader-tray-action-right-div loader-tray-action">{scanProfileChooser}</div> 
                {this.props.allowInteraction ? 
                    trayActions
                    : undefined }
            </Row>
            <Row className="tray-divider">
                <Divider />
            </Row>
        </Col>

        if (!this.props.tray.loaded && !this.props.tray.onStage) {
            trayDiv = <Row key={this.props.tray.trayNumber} className="tray-div tray-div-no-cassette">
                        No Cassette
                    </Row>
        } else if (this.props.tray.finished) {
            trayDiv = <Row key={this.props.tray.trayNumber} className="tray-div tray-div-scan-done">
                        {header}
                        {slidesDiv}
                    </Row>
        } else if (this.props.tray.onStage) {
            trayDiv = <Row key={this.props.tray.trayNumber} className="tray-div tray-div-loaded-for-scanning">
                        {header}
                        {slidesDiv}
                    </Row>
        } else if (previewNotTaken) {
            trayDiv = <Row key={this.props.tray.trayNumber} className="tray-div tray-div-no-preview">
                        {header}
                        <Button 
                            className="tray-take-preview-button" 
                            size="small"
                            disabled={this.props.isPowerFailure || this.props.scannerStatus.takingPreviews}
                            onClick={() => this.props.takeTrayPreviews(this.props.tray.trayNumber, this.props.tray.rackNumber)}>
                                Take Previews
                        </Button>
                    </Row>
        } else {
            trayDiv = <Row key={this.props.tray.trayNumber} className="tray-div">
                        {header}
                        {slidesDiv}
                    </Row>
        }

        return trayDiv;
    }

}

const mapStateToProps = (state, ownProps) => {
	return {
        previews : state.previewStatusReducer,
	};
};

export default connect(mapStateToProps)(Tray);
