import {
	ADD_ANNOTATION,
	DELETE_ANNOTATION,
	UPDATE_ANNOTATION_STATE,
	UPDATE_DEEP_BIO_STATE,
	UPDATE_GRID_STATE,
	UPDATE_IHC_STATE,
	UPDATE_LABELLING_STATE,
	UPDATE_MAP_STATE,
	UPDATE_MAPS_STATE,
	UPDATE_SLIDE_STATE,
	UPDATE_VIEWER_SETTINGS,
} from "../actionTypes/maps.state.const";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import {message} from "antd";
import {
	brightnessKey,
	contrastKey,
	grayscaleKey,
	hueKey,
	invertKey,
	saturationKey
} from "../component/gammaviewer/utils/viewerSettingsKeys";
import {isResponseOk} from "../helper/response.status.check";
import {displayError} from "../helper/display.error";
import {AxiosConfig} from "../helper/axios.config";
import queryString from "query-string";
import {filterAnnotationsInROI, isROIAnnotation} from "../component/gammaviewer/utils/annotations_app_utils";

export const initSlides = (slides) => updateMapsState(
	Object.assign({}, slides.map(slide => ({
		slideId: slide.id,
		x: -1,
		y: -1,
		z: 0,
		r: -1,
		digitalZoomStatus: false,
		zStackLevel: 0,
		gridState: {
			grid: false,
			color: "e91e63",
			size: 500,
			width: 3,
		},
		...slide,
	})))
);

export const updateMapState = (mapId, mapState) => ({
	type: UPDATE_MAP_STATE,
	mapId: mapId,
	mapState: mapState
});

export const updateMapsState = (state) => ({
	type: UPDATE_MAPS_STATE,
	state: state
});


// Z Stack Actions
export const updateZStackLevel = (mapId, zStackLevel) => updateMapState(mapId, {
	zStackLevel: zStackLevel,
});


// Slide State Actions
export const updateSlideState = (mapId, slideState) => ({
	type: UPDATE_SLIDE_STATE,
	mapId: mapId,
	slideState: slideState,
});

export const initSlideData = (mapId, slideId) => dispatch => {
	axios.get(`/api/slide/${slideId}/`, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response =>
		isResponseOk(response) ?
			dispatch(updateSlideState(mapId, {
				slide_data: response.data,
			})) :
			displayError("Slide Data Init Failed", response)
	).catch(error => displayError("Slide Data Init Failed", error));
}

export const updateSlideData = (mapId, slideId, slideData) => dispatch =>
	axios.patch(`/api/slide/${slideId}/`, slideData, {
		headers: {
			Authorization: AuthHeader()
		}
	}).then(response => dispatch(updateSlideState(mapId, {
		slide_data: response.data,
	}))).catch(error => displayError("Slide Data Update Failed", error));

export const fullStitchSlide = (mapId, slideId) => dispatch => {
	axios.post(`/api/stitch/full/`, queryString.stringify({slideId: slideId}), AxiosConfig())
		.then(() => dispatch(initSlideData(mapId, slideId)));
}

export const updateDigitalZoomStatus = (mapId, digitalZoomStatus) => updateMapState(mapId, {
	digitalZoomStatus: digitalZoomStatus,
});


// Grid App Actions
export const updateGridState = (mapId, gridState) => ({
	type: UPDATE_GRID_STATE,
	mapId: mapId,
	gridState: gridState,
});

export const updateGridStatus = (mapId, grid) => updateGridState(mapId, {
	grid: grid,
});

export const updateGridColor = (mapId, color) => updateGridState(mapId, {
	color: color,
});

export const updateGridSize = (mapId, size) => updateGridState(mapId, {
	size: size,
});

export const updateGridWidth = (mapId, width) => updateGridState(mapId, {
	width: width
});


// Viewer Settings App ACTIONS
export const updateViewerSettings = (mapId, viewerSettings) => dispatch => {
	axios.patch(`/api/viewersetting/${viewerSettings.id}/`, viewerSettings, {
		headers: {
			Authorization : AuthHeader()
		}
	}).then(response => {
		if(response.status === 200 || response.status === 301 || response.status === 302) {
			// not dispatching here to remove ui lag, as server response time effect ui
		}
		else if(response.status === 403 || response.status === 401 || response.status === 400)
			message.error('Settings Update Failed', 2.5);
	})
		.catch(err =>{
			message.error('Settings Update Failed', 2.5);
			console.log(err);
		});

	dispatch({
		type: UPDATE_VIEWER_SETTINGS,
		mapId: mapId,
		viewerSettings: viewerSettings,
	});
}

export const updateBrightness = (mapId, viewerSettingId, brightness) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[brightnessKey.id]: brightness,
});

export const updateContrast = (mapId, viewerSettingId, contrast) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[contrastKey.id]: contrast,
});

export const updateHue = (mapId, viewerSettingId, hue) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[hueKey.id]: hue,
});

export const updateInvert = (mapId, viewerSettingId, invert) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[invertKey.id]: invert,
});

export const updateGrayscale = (mapId, viewerSettingId, grayscale) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[grayscaleKey.id]: grayscale,
});

export const updateSaturation = (mapId, viewerSettingId, saturation) => updateViewerSettings(mapId, {
	id: viewerSettingId,
	[saturationKey.id]: saturation,
});


// ANNOTATION APP ACTIONS
export const updateAnnotationState = (mapId, annotationState) => ({
	type: UPDATE_ANNOTATION_STATE,
	mapId: mapId,
	annotationState: annotationState,
});

// retrieveAnnotationsAppDataFromBackend
export const retrieveAnnotations = (mapId, slideId) => dispatch =>
	axios.get(`/api/annotation/?slide=${slideId}`, AxiosConfig())
		.then(response => dispatch(updateAnnotationState(mapId, {
			annotations: filterAnnotationsInROI(response.data),
		})))
		.catch(error => displayError("Annotation Retrieval Failed", error));

// [Priyanshu] - only add annotation in reducer, (frontend), if annotation of same id exist, replace it
export const _addAnnotation = (mapId, annotation) => ({
	type: ADD_ANNOTATION,  // reducer is made to facilitate extending the list
	mapId: mapId,
	annotation: annotation,
});

// addAnnotationToAnnotationApp  -> use getAnnotationFromPoints(color, area, perimeter, key, coord, center, creator,
// z, slide_id) before
export const addAnnotation = (mapId, annotation) => dispatch =>
	axios.post(
		`/api/annotation/`, annotation, AxiosConfig())
		.then(response => isROIAnnotation(annotation) ? dispatch(retrieveAnnotations(mapId, annotation.slide)) :
			dispatch(_addAnnotation(mapId, response.data)))
		.catch(error => displayError("Annotation Addition Failed", error));

export const updateAnnotation = (mapId, annotation) => dispatch =>
	axios.patch(
		`/api/annotation/${annotation.id}/`, annotation, AxiosConfig())
		.then(response => dispatch(_addAnnotation(mapId, response.data)))
		.catch(error => displayError("Failed to update annotation", error));

// [Priyanshu] - delete annotation from reducer, (frontend)
export const _deleteAnnotations = (mapId, annotationId) => ({
	type: DELETE_ANNOTATION,
	mapId: mapId,
	annotationId: annotationId,
});

export const deleteAnnotation = (mapId, annotation) => dispatch =>
	axios.delete(
		`/api/annotation/${annotation.id}/`, AxiosConfig())
		.then(_ => isROIAnnotation(annotation) ?
			dispatch(retrieveAnnotations(mapId, annotation.slide)) :
			dispatch(_deleteAnnotations(mapId, annotation.id)))
		.catch(error => displayError("Annotation Deletion Failed", error));

export const updateAnnotationVector = (mapId, vector) => updateAnnotationState(mapId, {
	vector: vector,
});

// DEEP BIO APP ACTIONS
export const updateDeepBioState = (mapId, deepBioState) => ({
	type: UPDATE_DEEP_BIO_STATE,
	mapId: mapId,
	deepBioState: deepBioState,
});

export const updateDeepBioVector = (mapId, vector) => updateDeepBioState(mapId, {
	vector: vector,
});

export const updateDeepBioResultVector = (mapId, vector) => updateDeepBioState(mapId, {
	resultVector: vector,
});


// IHC APP ACTIONS
export const updateIHCState = (mapId, ihcState) => ({
	type: UPDATE_IHC_STATE,
	mapId: mapId,
	ihcState: ihcState,
});

export const updateIHCVector = (mapId, vector) => updateIHCState(mapId, {
	vector: vector,
});

export const updateIHCResultVector = (mapId, vector) => updateIHCState(mapId, {
	resultVector: vector,
});

export const updateSelectedIHCResult = (mapId, selectedIHCResult) => updateIHCState(mapId, {
	selectedIHCResult: selectedIHCResult,
});


// Labelling App Actions
export const updateLabellingState = (mapId, labellingState) => ({
	type: UPDATE_LABELLING_STATE,
	mapId: mapId,
	labellingState: labellingState,
});

export const updateLabellingVector = (mapId, vector) => updateLabellingState(mapId, {
	vector: vector,
});
