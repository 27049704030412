import {
    CLEAR_NOTIFICATION,
    PUSH_NOTIFICATION,
    UPDATE_APP_INSTALLED_STATE,
    UPDATE_GAMMA_STATE,
    UPDATE_LAST_MAP_COUNT,
} from "../actionTypes/gamma.state.const";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import cookie from "react-cookies";
import {isResponseOk} from "../helper/response.status.check";
import {AxiosConfig} from "../helper/axios.config";
import {displayError} from "../helper/display.error";

export const updateGammaState = (state) => ({
    type: UPDATE_GAMMA_STATE,
    state: state,
});

export const pushNotification = (notification) => ({
    type: PUSH_NOTIFICATION,
    notification: notification,
});

export const clearNotification = (id) => ({
    type: CLEAR_NOTIFICATION,
    id: id,
});

export const updateAppInstalledStatus = (appId, installedStatus) => ({
    type: UPDATE_APP_INSTALLED_STATE,
    appKey: appId + "_installed",
    installedStatus: installedStatus,
});

export const updateActiveMap = (mapId) => updateGammaState({
    activeMapId: mapId,
});

export const updateSync = (sync) => updateGammaState({
    sync: sync
});

export const updateActiveAnnoDrawer = (activeAnnoDrawer) => updateGammaState({
    activeAnnoDrawer: activeAnnoDrawer,
});

export const updateLastMapCount = () => ({
    type: UPDATE_LAST_MAP_COUNT,
});

// helper function
const _updateMorpheusSetting = (morpheusSetting) => {
    localStorage.setItem('morpheus_setting', JSON.stringify(morpheusSetting));
    return updateGammaState({
        appsInstalled: morpheusSetting.apps_installed,
        viewer: morpheusSetting.viewer,
        morpheusSettingInitialised: true,
        keyboardShortcuts: morpheusSetting.keyboard_shortcuts,
        keyboardShortcutsEnable: morpheusSetting.keyboard_shortcuts_enable,
    });
}

export const loadMorpheusSetting = () => dispatch => {
    axios.get(`/api/morpheussetting/${JSON.parse(localStorage.getItem('morpheus_setting')).id}/`, AxiosConfig()
    ).then(response => isResponseOk(response) ?
        dispatch(_updateMorpheusSetting(response.data)) :
        displayError("Failed to load morpheus setting", response)
    ).catch(error => displayError("Failed to load morpheus setting", error));
}

export const updateMorpheusSetting = (morpheusSetting) => dispatch => {
    axios.patch(
        `/api/morpheussetting/${JSON.parse(localStorage.getItem('morpheus_setting')).id}/`, morpheusSetting,
        AxiosConfig()
    ).then(response => isResponseOk(response) ? dispatch(_updateMorpheusSetting(response.data)) :
        displayError("Failed to update morpheus setting", response)
    ).catch(error => displayError("Failed to update morpheus setting", error));
}

export const updateAppsInstalledinDB = (apps_installed, update_zoom_level_flag = false) => {
    if (!(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience &&
        cookie.loadAll().username !== 'uscap') {
        let url = `/api/morpheussetting/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
        axios.put(url, {
            apps_installed
        }, {headers: {Authorization : AuthHeader()}})
            .then(response => {
                if(response.status === 200 || response.status === 301 || response.status === 302) {
                    if(update_zoom_level_flag) {
                        window.location.reload();
                    }
                }
                else if(response.status === 403 || response.status === 401 || response.status === 400){
                    console.log("Apps Installed Save Failed", response);
                }
            })
            .catch(err =>{
                console.log("Apps Installed Save Failed, ", err);
            });
    }
}

export const toggleKeyboardShortcuts = (enable) =>
    updateMorpheusSetting({
        keyboard_shortcuts_enable: enable,
    });

export const updateKeyboardShortcuts = (keyboardShortcuts) =>
    updateMorpheusSetting({
        keyboard_shortcuts: keyboardShortcuts,
    });

export const loadAllViewerSettings = () => dispatch => {
    axios.get(`/api/viewersetting`, AxiosConfig())
        .then(response => isResponseOk(response) ?
            dispatch(updateGammaState({
                allViewerSettings: response.data,
            })) : displayError("Failed to load all viewer settings", response)
        ).catch(error => displayError("Failed to load all viewer settings", error));
}

export const createNewViewerSetting = (viewerSetting) => dispatch => {
    axios.post(`/api/viewersetting/`, viewerSetting, AxiosConfig())
        .then(dispatch(loadAllViewerSettings())
        ).catch(error => displayError("Failed to create new viewer setting", error));
}
