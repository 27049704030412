import React from  "react";

import {
    ArrowsAltOutlined,
    BookOutlined,
    EditOutlined,
    ExperimentOutlined,
    ExportOutlined,
    InfoOutlined,
    NumberOutlined,
    PicCenterOutlined,
    ProfileOutlined,
    ReadOutlined,
    SearchOutlined,
    SettingOutlined,
    TeamOutlined,
    DatabaseOutlined,
    FileOutlined,
    BarcodeOutlined,
    TableOutlined
} from '@ant-design/icons';

export const caseInfoAppKey = {
    id: "case_info", 
    title : "Case Info", 
    defaultInstalled: false, 
    showTitle : true, 
    icon: <BookOutlined />, 
    defaultY: 2, 
    defaultX: 30
}
export const slideInfoAppKey = {
    id: "slide_info", 
    title : "Slide Info", 
    defaultInstalled: false, 
    showTitle : true, 
    icon: <InfoOutlined />, 
    defaultY: 2, 
    defaultX: 30
}
export const zoomAppKey = {
    id: "zoom-controls", 
    title : "Zoom", 
    defaultInstalled: true,
    showTitle : false,
    icon: <SearchOutlined />, 
    defaultY: 75, 
    defaultX: 80
}
export const previewControlsKey = {
    id: "preview-controls", 
    title : "Preview", 
    defaultInstalled: true,
    showTitle : true, 
    icon: <PicCenterOutlined />, 
    defaultY: 5, 
    defaultX: 60
}
export const annotationsAppKey = {
    id: "annotations", 
    title : "Annotations", 
    defaultInstalled: true,
    showTitle : true, 
    icon: <EditOutlined />, 
    defaultY: 60, 
    defaultX: 30
}
export const gridOverlayAppKey = {
    id: "grid-overlay", 
    title : "Grid", 
    defaultInstalled: false, 
    showTitle : true, 
    icon: <NumberOutlined />, 
    defaultY: 20, 
    defaultX: 30
}
export const settingsAppKey = {
    id: "settings", 
    title : "Settings", 
    defaultInstalled: false, 
    showTitle : true, 
    icon: <SettingOutlined />, 
    defaultY: 10, 
    defaultX: 60
}
export const screenshotAppKey = {
    id: "screenshot", 
    title : "Screenshot", 
    defaultInstalled: true,
    showTitle : true, 
    icon: <ExportOutlined />, 
    defaultY: 20, 
    defaultX: 30
}
export const reportAppKey = {
    id: "cap_report",
    title: "Report", 
    defaultInstalled: false, 
    showTitle: true, 
    icon: <ProfileOutlined />, 
    defaultY: 2, 
    defaultX: 30
}
export const capGuideAppKey = {
    id: "cap_guide",
    title: "CAP Guidelines", 
    defaultInstalled: false, 
    showTitle: true, 
    icon: <ReadOutlined />, 
    defaultY: 2, 
    defaultX: 30
}
export const ihcAppKey = {
    id: "ihc_app", 
    title : "IHC", 
    defaultInstalled: false, 
    showTitle : true, 
    icon: <ExperimentOutlined />, 
    defaultY: 2, 
    defaultX: 30
}
export const presentAppKey = {
    id: "present_app", 
    title: "Scan Share", 
    defaultInstalled: false, 
    showTitle: true,
    icon: <TeamOutlined />,
    defaultY: 2, 
    defaultX: 30
}
// export const chatAppKey = {
//     id: "chat", 
//     title : "Chat", 
//     showTitle : true
// }
export const displaySizeCalibrationKey = {
    id: "display_size_calibration", 
    title: "Display Size Calibration", 
    defaultInstalled: false,
    showTitle: true,
    icon: <ArrowsAltOutlined />,
    defaultY: 2, 
    defaultX: 30
}
// export const ZStackKey = {
//     id: "z_stack", 
//     title: "Z Stack", 
//     defaultInstalled: false, 
//     showTitle: true,
//     icon: <Icon type="database" />,
//     defaultY: 2, 
//     defaultX: 30
// }

export const ZStackControlsKey = {
    id: "z_stack_controls", 
    title: "Z Stack Control", 
    defaultInstalled: false, 
    showTitle: false,
    icon: <DatabaseOutlined />,
    defaultY: 2, 
    defaultX: 30
}

export const TRFViewerAppKey = {
    id: "trf_viewer",
    title: "Ancillary Clinical Information",
    showTitle: true,
    icon: <FileOutlined />,
    defaultY: 2,
    defaultX: 30
}

export const BarcodeViewerKey = {
    id: "barcode_viewer",
    title: "Label Viewer",
    showTitle: true,
    icon: <BarcodeOutlined />,
    defaultY: 2,
    defaultX: 30
}

export const CliaResultsViewerKey = {
    id: "clia_results",
    title: "Clia Results",
    showTitle: true,
    icon: <TableOutlined />,
    defaultY: 2,
    defaultX: 30
}