import {olAlertConstant} from "../actionTypes/ol.constant";

const InitialState = {
    olmap : null,
    oldata : [],
}

export const olAlert = ( state = InitialState , action) => {
    switch(action.type){
        case olAlertConstant.OL_ADDED:
            return{
                olmap : action.response,
                oladdedStatus : true,
                isFetching : true,
            }
        case olAlertConstant.OL_FETCHING:
            return{
                ...state,
                isFetching : true
            }
        case olAlertConstant.OL_DATA:
            return{
                ...state,
                isFetching : false,
                oldata : action.response[0]
            }
        default :
            return state
    }
}