import {LoginAlertConstant} from "../actionTypes/login.alert.constant";

const InitialState = {
    status : false,
    auth : null,
    isFetching : false,
    loginStatus : false,
    csrfToken : null,
    username :null,
    deployment_mode: 'cloud',
    isWrongCredential : false,
    isForbidden: false,
    isLockedOut: false,
    isServerUnreachable : false,
    is_staff: false,
    superuser: false,
    djangoLoginDone: false,
}

export const loginAlerts = ( state = InitialState , action) => {
    switch(action.type){
        case LoginAlertConstant.LOGIN_REQUEST :
            return{
                ...state,
                isFetching : true
            }
        case LoginAlertConstant.LOGIN_SUCCESS :
            return {
                ...state,
                isFetching : false,
                loginStatus : true,
                authToken : action.response.data.token,
                status : true,
                username : Object.assign("", action.response.data.user.first_name),
                user_id : action.response.data.user.id,
                superuser : action.response.data.user.is_superuser,
                is_staff : action.response.data.user.is_staff,
                deployment_mode: action.response.data.deployment_mode,
                isWrongCredential : false,
                isServerUnreachable : false
            }
        case LoginAlertConstant.LOGIN_ERROR :
            return {
                ...state,
                // HTTP 502 Bad Gateway or HTTP 504 Gateway Timeout
                isServerUnreachable : action.response.status == 502 || action.response.status == 504,
                // HTTP 403 Forbidden
                isForbidden: action.response.status == 403,
                // User has been locked out for some reason
                isLockedOut: action.response.status == 403 && action.response.data && action.response.data.code == "locked_out",
                // User entered wrong credentials
                // TODO: check isn't properly done
                isWrongCredential : !(action.response.status == 504 || action.response.status == 403)
                    && action.response.status == 400,
                isFetching : false,
            }
        case LoginAlertConstant.LOGOUT :
            return {
                ...state,
                auth : null,
                isFetching : false,
                loginStatus : false,
                csrfToken : null,
                authToken : null,
                status : false,
                isWrongCredential : false,
                isServerUnreachable : false
            }
        case LoginAlertConstant.CSRF_TOKEN_SUCCESS :
            return {
                ...state,
                csrfToken : action.response,
            }
        case LoginAlertConstant.PROXY_LOGIN_SUCCESS :
            return {
                ...state,
                proxyLogin : action.response,
            }
        default :
            return state
    }
}