import { SlideTraversalConstant } from "../actionTypes/slide.traversal.constant";

export const changeCase = (case_id, slides, current_index, prev_index, next_index, current_slide_id, prev_slide_id, next_slide_id) => ({
    type: SlideTraversalConstant.CHANGE_CASE,
    case_id: case_id,
    slides: slides,
    current_index: current_index,
    prev_index: prev_index,
    next_index: next_index,
    current_slide_id: current_slide_id,
    prev_slide_id: prev_slide_id,
    next_slide_id: next_slide_id,
})

export const updateCase = (case_id, slides, slide_id) => (dispatch) => {
    slides.sort(function(a, b) {
        return a.original_name.localeCompare(b.original_name);
    });

    let current_index = slides.findIndex((item) => slide_id === item.id);

    let prev_index;
    let next_index;

    if(current_index == 0) {
        prev_index = slides.length - 1;
    } else {
        prev_index = current_index - 1;
    }

    if(current_index == slides.length - 1) {
        next_index = 0;
    } else {
        next_index = current_index + 1;
    }

    dispatch(changeCase(case_id, slides, current_index, prev_index, next_index, slides[current_index].id, slides[prev_index].id, slides[next_index].id));

}