import {SearchAlertUserConstant} from "../actionTypes/search.user.constant";
import {urlEncoder} from "../action/users.action";
import {UserActions} from '../actionTypes/users.constant';

export const getNewState = () => {
    return {
    isFetching : true,
    search : '',
    page: 1, 
    numPages: 1,
    total: 1,
    groups: {},
    usernames: []
}}

const InitialState = getNewState();

export const searchUserReducer = ( state = InitialState , action) => {
    var returnableState = {}
    switch(action.type){
        case SearchAlertUserConstant.USER_SEARCH_INITIATED:
            returnableState = {
                ...state,
                isFetching : true,
            }
            return returnableState;
        case SearchAlertUserConstant.USER_SEARCH_SUCCESS:
            returnableState = {
                ...state,
                isFetching : false,
            }
            return returnableState;
        case SearchAlertUserConstant.USER_SEARCH_FAILED:
            returnableState = {
                ...state, 
                err: action.error, 
                isFetching : false,
            }
            return returnableState;
        case SearchAlertUserConstant.USER_UPDATE_URL_STATE:
            returnableState = Object.assign({}, action.urlState);
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertUserConstant.USER_UPDATE_SEARCHES:
            returnableState = {
                ...state, 
            }
            returnableState.searches = action.searches;
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertUserConstant.USER_CHANGE_PAGE:
            returnableState = {
                ...state
            }
            returnableState.page = action.page
            return returnableState;
        case UserActions.CHANGE_TOTAL_NUMBER_OF_PAGES_USER:
            return {
                ...state, 
                numPages: action.numPages
            }
        case UserActions.CHANGE_TOTAL_USER:
            return {
                ...state, 
                total: action.total
            }
        case UserActions.UPDATE_GROUPS:
            var updatedInfo = {}
            for (var i = 0; i < action.groups.length; i++){
                let group = action.groups[i]
                updatedInfo[group.id] = group;
            }
            return {
                ...state, 
                groups: updatedInfo
            }
        case UserActions.UPDATE_USERNAMES:
            return {
                ...state, 
                usernames: action.usernames.usernames
            }
        default :
            returnableState = state
            return returnableState;
    }
}

export const updateUrl = (state) => {
    var encodedUrl = urlEncoder(state);
    window.history.replaceState(state, 'filter_params', encodedUrl);
}