import {SlideTraversalConstant} from "../actionTypes/slide.traversal.constant";

export const getNewState = () => {
    return {
        case_id : '',
        slides : [],
        current_index : 1,
        prev_index : -1,
        next_index : 2,
        current_slide_id : 1,
        prev_slide_id : 1,
        next_slide_id : 2,
    }
}

const InitialState = getNewState();

export const slideTraversalReducer = ( state = InitialState , action) => {
    var returnableState = {}
    switch(action.type){
        case SlideTraversalConstant.CHANGE_CASE:
            returnableState = {
                ...state,
                case_id: action.case_id,
                slides: action.slides,
                current_index: action.current_index,
                prev_index: action.prev_index,
                next_index: action.next_index,
                current_slide_id: action.current_slide_id,
                prev_slide_id: action.prev_slide_id,
                next_slide_id: action.next_slide_id,
            }
            return returnableState;
        default :
            returnableState = state
            return returnableState;
    }
}