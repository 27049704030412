import { hsvToRgb } from '../../utils/utils';
import { StitchDirection } from '../../utils/const';
import { drawRectangle, drawTriangle, drawLine } from './tile_viewer_map_utils'

export const plotDisagreementv2Unit = (disagreement, disagreementL2NormMax, disagreementL2NormMin, 
                                       slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource) => {
    // render `disagreement` at the field located at (xHori, yVerti)
    const tile_width_microns  = slideData.tile_width  * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;
    
    yVerti = slideData.y_fields - yVerti - 1;

    // NORTH MARKERS
    drawNorthMarkers(xHori, yVerti, sizeOfMarker, margin,
        tile_width_microns, tile_height_microns, disagreement, vectorSource,
        disagreementL2NormMax, disagreementL2NormMin);

    // WEST MARKERS
    drawWestMarkers(xHori, yVerti, sizeOfMarker, margin,
        tile_width_microns, tile_height_microns, disagreement, vectorSource,
        disagreementL2NormMax, disagreementL2NormMin);
}

export const plotDisagreementTextv2Unit = (disagreement, slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource) => {
    // render `disagreement` at the field located at (xHori, yVerti)
    const tile_width_microns  = slideData.tile_width  * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;
    
    yVerti = slideData.y_fields - yVerti - 1;

    // NORTH MARKERS
    drawNorthMarkersText(xHori, yVerti, sizeOfMarker, margin,
        tile_width_microns, tile_height_microns, disagreement, vectorSource);

    // WEST MARKERS
    drawWestMarkersText(xHori, yVerti, sizeOfMarker, margin,
        tile_width_microns, tile_height_microns, disagreement, vectorSource);
}

export const getLRArrowBodyBoundsTileMotion = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    // 5 pts to close a rect, convention: start at top left, move clockwise
    // note: start end end pts are same
    let xShift = -0.25;
    if (direction === StitchDirection.RIGHT) { xShift = 0.25; }
    const bounds = [
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
        [(xHori + (1 - sizeOfMarker) + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + (1 - sizeOfMarker) + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)]
    ];
    return bounds;
}

export const getLRArrowHeadBoundsTileMotion = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    let xShift = -0.4;
    if (direction === StitchDirection.RIGHT) { xShift = 0.4; }
    let bounds;
    if (direction === StitchDirection.LEFT)  {
        bounds = [
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ]
        ];
    } else if (direction === StitchDirection.RIGHT) {
        bounds = [
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
            [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ]
        ];
    }
    return bounds;
}

export const getUDArrowBodyBoundsTileMotion = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    // 5 pts to close a rect, convention: start at top left, move clockwise
    // note: start end end pts are same
    let yShift = -0.3;
    if (direction === StitchDirection.DOWN) { yShift = -0.7; }
    const bounds = [
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))],
        [(xHori + (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))],
        [(xHori + (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + (1-sizeOfMarker) + 0.5) * (tile_height_microns))],
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + (1-sizeOfMarker) + 0.5) * (tile_height_microns))],
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))]
    ];
    return bounds;
}

export const getUDArrowHeadBoundsTileMotion = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    let yShift = 0.4;
    if (direction === StitchDirection.DOWN) { yShift=-0.4; }
    let bounds;
    if (direction === StitchDirection.UP)  {
        bounds = [
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))]
        ];
    } else if (direction === StitchDirection.DOWN) {
        bounds = [
            [(xHori + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + margin/2 + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))]
        ];
    }
    return bounds;
}

export const getInvertedColor = (maxVal, minVal, val) => {
    const colorScore = 180 - Math.floor(180 * ((val - minVal) / (maxVal - minVal)));
    let {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
    return `rgb(${r},${g},${b},0.1)`;
}

export const drawArrowInDirection = (xHori, yVerti, tile_width_microns, tile_height_microns, direction, vectorSource, ccColor) => {
    const size = 0.5;
    const margin = size / 10;
    const sizeOfArrow = 0.2;
    // let color = "rgba(0, 185, 123, 0.6)";
    // let arrowColor = "rgba(0, 235, 0, 0.8)";
    let stroke = 1;
    if (direction === StitchDirection.LEFT || direction === StitchDirection.RIGHT) {
        const arrowBodyBounds = getLRArrowBodyBounds(xHori, yVerti, sizeOfArrow, margin, tile_width_microns, tile_height_microns, direction);
        // DRAW
        drawRectangle(arrowBodyBounds, vectorSource, true, stroke, ccColor);
        let arrowHeadBounds = getLRArrowHeadBounds(xHori, yVerti, 2*sizeOfArrow, 3*margin, tile_width_microns, tile_height_microns, direction);
        // DRAW
        drawTriangle(arrowHeadBounds, vectorSource, true, 2, ccColor);
    } else if (direction === StitchDirection.UP || direction === StitchDirection.DOWN) {
        const arrowBodyBounds = getUDArrowBodyBounds(xHori, yVerti, sizeOfArrow, margin, tile_width_microns, tile_height_microns, direction);
        // DRAW
        drawRectangle(arrowBodyBounds, vectorSource, true, stroke, ccColor);
        let arrowHeadBounds = getUDArrowHeadBounds(xHori, yVerti, 0.5*sizeOfArrow, 3*margin, tile_width_microns, tile_height_microns, direction);
        // DRAW
        drawTriangle(arrowHeadBounds, vectorSource, true, 2, ccColor);
    }
}

export const getArrowDirection = (traversalUnit) => {
    const xHori = traversalUnit["source"]["col"];
    const yVerti = traversalUnit["source"]["row"];
    const targetXHori = traversalUnit["target"]["col"];
    const targetYverti = traversalUnit["target"]["row"];
    if (targetXHori < xHori) { return  StitchDirection.LEFT; }
    if (targetXHori > xHori) { return  StitchDirection.RIGHT; }
    if (targetYverti < yVerti) { return  StitchDirection.UP; }
    if (targetYverti > yVerti) { return  StitchDirection.DOWN; }
    return StitchDirection.UNKNOWN_DIRECTION;
}
 
const getLRArrowBodyBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    // 5 pts to close a rect, convention: start at top left, move clockwise
    // note: start end end pts are same
    let xShift = -0.45;
    if (direction === StitchDirection.RIGHT) { xShift = 0.55; }
    const bounds = [
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
        [(xHori + (1 - sizeOfMarker) + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + (1 - sizeOfMarker) + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)]
    ];
    return bounds;
}

const getLRArrowHeadBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    let xShift = -0.8;
    if (direction === StitchDirection.RIGHT) { xShift = 0.9; }
    let bounds;
    if (direction === StitchDirection.LEFT)  {
        bounds = [
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ]
        ];
    } else if (direction === StitchDirection.RIGHT) {
        bounds = [
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
            [(xHori + sizeOfMarker + xShift) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
            [(xHori + xShift + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ]
        ];
    }
    return bounds;
}

const getUDArrowBodyBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    // 5 pts to close a rect, convention: start at top left, move clockwise
    // note: start end end pts are same
    let yShift = 0;
    if (direction === StitchDirection.DOWN) { yShift = -1.1; }
    const bounds = [
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))],
        [(xHori + (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))],
        [(xHori + (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + (1-sizeOfMarker) + 0.5) * (tile_height_microns))],
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + (1-sizeOfMarker) + 0.5) * (tile_height_microns))],
        [(xHori - (margin / 2) + 0.5) * (tile_width_microns), ((yVerti + yShift + sizeOfMarker + 0.5) * (tile_height_microns))]
    ];
    return bounds;
}

const getUDArrowHeadBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, direction) => {
    let yShift = 0.9;
    if (direction === StitchDirection.DOWN) { yShift=-1.0; }
    let bounds;
    if (direction === StitchDirection.UP)  {
        bounds = [
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))]
        ];
    } else if (direction === StitchDirection.DOWN) {
        bounds = [
            [(xHori + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + margin/2 + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori - margin/2 + 0.5) * (tile_width_microns), ((yVerti + sizeOfMarker + yShift + 0.5) * (tile_height_microns))],
            [(xHori + 0.5) * (tile_width_microns), ((yVerti - sizeOfMarker + yShift + 0.5) * (tile_height_microns))]
        ];
    }
    return bounds;
}

export const getTileCenterBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns) => {
    // 5 pts to close a rect, convention: start at top left, move clockwise
    // note: start end end pts are same
    const bounds = [
        [(xHori + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns) ],
        [(xHori + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + (1 - sizeOfMarker)) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker) * (tile_width_microns), ((yVerti + (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)],
        [(xHori + sizeOfMarker) * (tile_width_microns), ((yVerti - (margin / 2) + 1) * (tile_height_microns)) - (0.5*tile_height_microns)]
    ];
    return bounds;
}

export const getNorthBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns) => {
    const bounds = [
        [(xHori + sizeOfMarker) * (tile_width_microns), (yVerti - (margin / 2) + 1) * (tile_height_microns)],
        [(xHori + (1 - sizeOfMarker)) * (tile_width_microns), (yVerti - (margin / 2) + 1) * (tile_height_microns)],
        [(xHori + (1 - sizeOfMarker)) * (tile_width_microns), (yVerti + (margin / 2) + 1) * (tile_height_microns)],
        [(xHori + sizeOfMarker) * (tile_width_microns), (yVerti + (margin / 2) + 1) * (tile_height_microns)],
        [(xHori + sizeOfMarker) * (tile_width_microns), (yVerti - (margin / 2) + 1) * (tile_height_microns)]
    ];
    return bounds;
}

export const getWestBounds = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns) => {
    const bounds = [
        [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)],
        [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + (1 - sizeOfMarker)) * (tile_height_microns)],
        [(xHori + (margin / 2)) * (tile_width_microns), (yVerti + (1 - sizeOfMarker)) * (tile_height_microns)],
        [(xHori + (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)],
        [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)]
    ];
    return bounds;
}

export const drawNorthMarkersText = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, fieldStitchResult, vectorSource) => {
    // sample disagreement
    // computedTop: {tx: -35, ty: 1478}
    // computedLeft: {tx: 1859, ty: 63}
    // actualTop: {tx: -35, ty: 1478}
    // actualLeft: {tx: 1859, ty: 64}
    // disagreementTop: {deltaTx: 0, deltaTy: 0}
    // disagreementLeft: {deltaTx: 0, deltaTy: -1}
    // noActualLeftTxTy: false
    // noActualTopTxTy: true

    if (fieldStitchResult["noActualTopTxTy"] != undefined && fieldStitchResult["noActualTopTxTy"]) {
        return;
    }

    let txtColor = "#00d775";

    if(fieldStitchResult["computedTop"] != undefined) {
        const disagreementTop = fieldStitchResult["disagreementTop"];
        const l1Sum = Math.abs(disagreementTop["deltaTx"]) + Math.abs(disagreementTop["deltaTy"]);
        if(l1Sum > 0) {
            txtColor = "#666666";
        }
    }
    if (fieldStitchResult["computedTop"] != undefined) {
        if (fieldStitchResult["computedTop"]["ty"] == 0) {
            txtColor = "#00d775";
        }
    }
    if(fieldStitchResult["actualTop"] != undefined && fieldStitchResult["actualTop"]["ty"] == 0) {
        txtColor = "#00a532";
    }

    let disagreementTop = fieldStitchResult["disagreementTop"];
    if(Math.abs(disagreementTop["deltaTx"]) > 1000 || Math.abs(disagreementTop["deltaTy"]) > 1000) {
        return;
    }
    
    if (fieldStitchResult["computedTop"] != undefined) {
        let title = "Δtx=" + disagreementTop["deltaTx"] + "_Δty=" + disagreementTop["deltaTy"];
        if(disagreementTop["deltaTx"] == 0 && disagreementTop["deltaTy"] == 0) {
            title = "Δ is 0";
            return; // DON't draw
        }
        if(fieldStitchResult["computedTop"]["ty"] == 0) {
            title = "no Δtx Δty";
        }
        if(fieldStitchResult["actualTop"]["ty"] == 0) {
            title = "actual tx ty 0";
        }
        drawLine((xHori + 0.5) * tile_width_microns,  // x
            (yVerti + 1) * tile_height_microns,       // y
            1,                                   // length
            vectorSource,                        // vectorSource
            title,                               // title
            1,                                   // strokeWidth
            txtColor                             // color
        );
    }
}

export const drawWestMarkersText = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, fieldStitchResult, vectorSource) => {
    // sample disagreement
    // computedTop: {tx: -35, ty: 1478}
    // computedLeft: {tx: 1859, ty: 63}
    // actualTop: {tx: -35, ty: 1478}
    // actualLeft: {tx: 1859, ty: 64}
    // disagreementTop: {deltaTx: 0, deltaTy: 0}
    // disagreementLeft: {deltaTx: 0, deltaTy: -1}
    // noActualLeftTxTy: false
    // noActualTopTxTy: true

    if (fieldStitchResult["noActualLeftTxTy"] != undefined && fieldStitchResult["noActualLeftTxTy"]) {
        return;
    }

    let color = "rgba(85, 85, 23, 0.2)";
    let txtColor = "#00d775";
    
    if(fieldStitchResult["computedLeft"] != undefined) {
        const disagreementLeft = fieldStitchResult["disagreementLeft"];
        const l1Sum = Math.abs(disagreementLeft["deltaTx"]) + Math.abs(disagreementLeft["deltaTy"]);
        if(l1Sum > 0) {
            txtColor = "#666666";
        }
    }
    if(fieldStitchResult["computedLeft"] != undefined) {
        if(fieldStitchResult["computedLeft"]["tx"] == 0) {
            txtColor = "#00d775";
        }
    }
    if(fieldStitchResult["actualLeft"] != undefined && fieldStitchResult["actualLeft"]["tx"] == 0) {
        txtColor = "#00a532";
    }

    let disagreementLeft = fieldStitchResult["disagreementLeft"];
    if(Math.abs(disagreementLeft["deltaTx"]) > 1000 || Math.abs(disagreementLeft["deltaTy"]) > 1000) {
        return;
    }

    if (fieldStitchResult["computedLeft"] != undefined) {
        //const disagreementLeft = fieldStitchResult["disagreementLeft"];
        let title = "Δtx=" + disagreementLeft["deltaTx"] + "_Δty=" + disagreementLeft["deltaTy"];
        if(disagreementLeft["deltaTx"] == 0 && disagreementLeft["deltaTy"] == 0) {
            title = "Δ is 0";
            return; // DON't draw
        }
        if(fieldStitchResult["computedLeft"]["tx"] == 0) {
            title = "no Δtx Δty";
        }
        if(fieldStitchResult["actualLeft"]["tx"] == 0) {
            title = "actual tx ty 0";
        }
        drawLine(xHori * tile_width_microns,         // x
            (yVerti + 0.5) * tile_height_microns,   // y
            1,                                 // length
            vectorSource,                      // vectorSource
            title,                             // title
            0.25,                              // strokeWidth
            txtColor                           // color
        );
    }
}

export const drawNorthMarkers = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, 
                                 fieldStitchResult, vectorSource, maxl2norm, minl2norm) => {
    // sample disagreement
    // computedTop: {tx: -35, ty: 1478}
    // computedLeft: {tx: 1859, ty: 63}
    // actualTop: {tx: -35, ty: 1478}
    // actualLeft: {tx: 1859, ty: 64}
    // disagreementTop: {deltaTx: 0, deltaTy: 0}
    // disagreementLeft: {deltaTx: 0, deltaTy: -1}
    // noActualLeftTxTy: false
    // noActualTopTxTy: true

    if (fieldStitchResult["noActualTopTxTy"] != undefined && fieldStitchResult["noActualTopTxTy"]) {
        return;
    }

    let color = "rgba(85, 85, 23, 0.2)";
    let stroke = 0;
    const northBounds = getNorthBounds(xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns);
    let opacity = 0.9;

    if(fieldStitchResult["computedTop"] != undefined) {
        const disagreementTop = fieldStitchResult["disagreementTop"];
        const deltaTxSq = disagreementTop["deltaTx"]*disagreementTop["deltaTx"];
        const deltaTySq = disagreementTop["deltaTy"]*disagreementTop["deltaTy"];
        const l2Sum = Math.sqrt(deltaTxSq + deltaTySq);
        opacity = 0.9;
        stroke = 2;

        const MIN_VAL = Math.sqrt(2) + 0.01;
        // const cappedL2Sum = Math.min(l2Sum, MAX_VAL);
        const colorScore = 180 - Math.floor(180 * ((l2Sum - minl2norm) / (maxl2norm - minl2norm)));
        let {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        if(l2Sum < MIN_VAL) {
            opacity = 0; // hidden
        }
        color = `rgba(${r},${g},${b},${opacity})`;
        // color = `rgba(${rByte}, 0, ${bByte}, ${opacity})`;
    }
    if (fieldStitchResult["computedTop"] != undefined) {
        if (fieldStitchResult["computedTop"]["ty"] == 0) {
            color = "rgba(100, 100, 100, 0.2)";
        }
    }
    if(fieldStitchResult["actualTop"] != undefined && fieldStitchResult["actualTop"]["ty"] == 0) {
        color = "rgba(100, 100, 100, 0.2)";
    }

    let disagreementTop = fieldStitchResult["disagreementTop"];
    if(Math.abs(disagreementTop["deltaTx"]) > 1000 || Math.abs(disagreementTop["deltaTy"]) > 1000) {
        return;
    }
    
    if (opacity > 0) {
        drawRectangle(northBounds, vectorSource, true, stroke, color);
    }
}

export const drawWestMarkers = (xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns, 
                                fieldStitchResult, vectorSource, maxl2norm, minl2norm) => {
    // sample disagreement
    // computedTop: {tx: -35, ty: 1478}
    // computedLeft: {tx: 1859, ty: 63}
    // actualTop: {tx: -35, ty: 1478}
    // actualLeft: {tx: 1859, ty: 64}
    // disagreementTop: {deltaTx: 0, deltaTy: 0}
    // disagreementLeft: {deltaTx: 0, deltaTy: -1}
    // noActualLeftTxTy: false
    // noActualTopTxTy: true

    if (fieldStitchResult["noActualLeftTxTy"] != undefined && fieldStitchResult["noActualLeftTxTy"]) {
        return;
    }
    let color = "rgba(85, 85, 23, 0.2)";
    const westBounds = getWestBounds(xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns);
    let stroke = 0;
    let opacity = 0.9;
    
    if(fieldStitchResult["computedLeft"] != undefined) {
        const disagreementLeft = fieldStitchResult["disagreementLeft"];

        const deltaTxSq = disagreementLeft["deltaTx"]*disagreementLeft["deltaTx"];
        const deltaTySq = disagreementLeft["deltaTy"]*disagreementLeft["deltaTy"];
        const l2Sum = Math.sqrt(deltaTxSq + deltaTySq);
        opacity = 0.9;
        stroke = 2;

        const MAX_VAL = Math.sqrt(5*5 + 5*5);
        const MIN_VAL = Math.sqrt(2) + 0.01;
        // const cappedL2Sum = Math.min(l2Sum, MAX_VAL);
        const colorScore = 180 - Math.floor(180 * ((l2Sum - minl2norm) / (maxl2norm - minl2norm)));
        let {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);

        if(l2Sum < MIN_VAL) {
            opacity = 0; // hidden
        }
        color = `rgba(${r},${g},${b},${opacity})`;
    }
    if(fieldStitchResult["computedLeft"] != undefined) {
        if(fieldStitchResult["computedLeft"]["tx"] == 0) {
            color = "rgba(100, 100, 100, 0.2)";
        }
    }
    if(fieldStitchResult["actualLeft"] != undefined && fieldStitchResult["actualLeft"]["tx"] == 0) {
        color = "rgba(100, 100, 100, 0.2)";
    }

    let disagreementLeft = fieldStitchResult["disagreementLeft"];
    if(Math.abs(disagreementLeft["deltaTx"]) > 1000 || Math.abs(disagreementLeft["deltaTy"]) > 1000) {
        return;
    }

    if (opacity > 0) {
        drawRectangle(westBounds, vectorSource, true, stroke, color);
    }
}

