import {DashboardConstants} from "../actionTypes/dashboard.constant";

const InitialState = {
    numPages: 1,
    pageNumber: 1,
    total: 1,
    isBusy: false, 
    drawerCollapsed: false, 
    allTags: []
}

export const dashboardReducer = ( state = InitialState , action) => {
    switch(action.type){
        case DashboardConstants.CHANGE_PAGE:
            return {
                ...state, 
                pageNumber: action.pageNumber
            }
        case DashboardConstants.CHANGE_TOTAL_NUMBER_OF_PAGES:
            return {
                ...state, 
                numPages: action.numPages
            }
        case DashboardConstants.CHANGE_TOTAL_SLIDES:
            return {
                ...state, 
                total: action.total
            }
        case DashboardConstants.SET_BUSY:
            return {
                ...state, 
                isBusy: true
            }
        case DashboardConstants.SET_IDLE:
            return {
                ...state, 
                isBusy: false
            }
        case DashboardConstants.CLOSE_DRAWER:
            return {
                ...state, 
                drawerCollapsed: true
            }
        case DashboardConstants.OPEN_DRAWER:
            return {
                ...state, 
                drawerCollapsed: false
            }
        case DashboardConstants.INIT_TAGS:
            return {
                ...state, 
                allTags: action.tags
            }
        case DashboardConstants.ADD_TAG:
            return {
                ...state, 
                allTags: [...state.allTags, action.tag]
            }
        default :
            return state
    }
}