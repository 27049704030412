import React, { Component } from 'react';
import { ArrowLeftOutlined, ScanOutlined, UndoOutlined } from '@ant-design/icons';
import { Row, Col, Menu, Badge, Divider, Button, Radio, Input, message } from 'antd';
import { connect } from "react-redux";
import axios from "axios";
import "../../asset/style/scanner/scanner.css";
import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant";
import SlotComponent from "./slot_component";
import { setDeviceIdle, setDeviceBusy } from '../../action/device_status.action';
import { takeAllPreviews, initPreviewForScan, takePreview, startScanning, 
    setScanName, selectionCancelled, setScanSpeedForAllSlots } from "../../action/preview_status.action";
import { scanSpeed } from "./slots";

const { Search } = Input;

class ScanWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cassetteSize: 0,
            isObjectiveSwitcherPresent: false,
            activeSlot: -1,
            busy:false, 
            showSummary: false
        }
    }

    getScannerProperties = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Setting Up"));
        const url = "/server/devices/" + this.props.id + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(res => {
            let cassetteSize = res.data.cassetteSize;
            if(res.data.cassetteSize == 2) {
                cassetteSize = 1;
            }
            for (let i = 0; i < cassetteSize; i++) {
                this.initSlot(i, res.data.defaultConfig);
            }
            this.setState(Object.assign({}, this.state, {
                cassetteSize: cassetteSize,
                isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                allowScanOutputFormatChange: res.data.allowScanOutputFormatChange, 
                allowObjectiveChange: res.data.allowObjectiveChange,
                defaultConfig: res.data.defaultConfig
            }));
            this.props.dispatch(setDeviceIdle(this.props.id));

            if (cassetteSize === 1) {
                this.activate(0);
            }
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }

    handleSlotClick = ({ item, key, keyPath }) => {
        this.activate(key);
    }

    activate = (key) => {
        var selectedSlot = Object.assign({}, this.props.previews[key]);
        this.setState(Object.assign({}, this.state, {activeSlot: key, showSummary: false}));
        if (selectedSlot !== undefined && selectedSlot.status === RegionSelectionConstants.NOT_STARTED) {
            this.props.dispatch(takePreview(this.props.id, key));
        }
    }

    componentDidMount() {
        this.getScannerProperties();
    }

    initSlot = (slot, defaultConfig) => {
        this.props.dispatch(initPreviewForScan(slot, defaultConfig));
    }

    onStartScanning = () => {
        this.props.revokeWorkflowAccess();
        this.props.dispatch(startScanning(this.props.id, this.props.previews));
    }

    setShowSummary = (value) => {
        if (value) {
            this.setState({
                activeSlot: -1,
                showSummary: value
            })
        } else {
            this.setState({showSummary: value})
        }
    }

    setScanName = (slot, slide_name) => {
        this.props.dispatch(setScanName(slot, slide_name));
    }

    redoSlot = (slotId) => {
        this.props.dispatch(selectionCancelled(slotId));
        this.setState(Object.assign({}, this.state, {activeSlot: slotId, showSummary: false}));
    }

    scanAllSlots = () => {
		this.props.dispatch(takeAllPreviews(this.props.id, this.state.cassetteSize, this.setShowSummary));
    }

	readbarcode = (slot, barcodePath) => {
		let url = `/server/devices/${this.props.device_id}/read_barcode/?path=${barcodePath}`;
		axios.get(url).then(res => {
			this.setScanName(slot, res.data);
			if (res.data === "") {
				message.warn("No Barcode found on Slide. Please write Slide Name.")
			}
		})
		.catch(err => {
				console.log(err);
		});
    }
    
    render() {

        let cassetteSize = this.state.cassetteSize;
        let loader_menu = [];
        let atleastOneSlotConfirmed = false;
        
        for (let i = 0; i < cassetteSize; i++) {
            let badgeStatus = "warning";
            if (this.props.previews[i].status === RegionSelectionConstants.CONFIRMED) {
                badgeStatus = "success";
            } else if (i === this.state.activeSlot) {
                badgeStatus = "processing"
            }
            loader_menu.push(
                <Menu.Item key={i} onClick={this.handleSlotClick}>
                    <span>
                        <Badge status={badgeStatus} />
                        <span className="arial-font">Slot {i + 1}</span>
                    </span>
                </Menu.Item>
            );
            let region = ((this.props.previews || {})[i] || {}).region;
            atleastOneSlotConfirmed = atleastOneSlotConfirmed || (region !== undefined);
        }
        
        let slotMenu = null;
        let summaryDiv = null;
        let slotConfirmedButtons = atleastOneSlotConfirmed ? [
            <Row key={0} className="view-summary-button-div">
                <Button className={atleastOneSlotConfirmed ? "view-summary-button" : ""} disabled={!atleastOneSlotConfirmed} type="link" icon="scan" size={'default'} onClick={() => this.setShowSummary(true)}>View Summary</Button>
            </Row>, 
            <Row key={1} className="start-scan-button-div">
                <Button className={atleastOneSlotConfirmed ? "start-scan-button" : ""} disabled={!atleastOneSlotConfirmed} type="danger" icon="scan" size={'large'} onClick={this.onStartScanning}>Start Scanning</Button>
            </Row>
        ] : undefined;

        if (cassetteSize > 1) {
            slotMenu = 
                <Col span={4} className="slot-menu">
                    <Row className="arial-font slot-tray-header">
                        <Col span={24}>
                            <Divider>Slots</Divider>
                        </Col>
                    </Row>
                    <Row>
                        <Menu
                            onClick={this.handleClick}
                            defaultOpenKeys={['sub1']}
                            selectedKeys={[ this.state.activeSlot.toString()]}
                            mode="inline"
                        >
                            {loader_menu}
                        </Menu>
                    </Row>
                    {slotConfirmedButtons}
                </Col>
            
            if (this.state.showSummary) {
                let previewList = [];
                for (let i = 0; i < cassetteSize; i++) {
                    let preview = this.props.previews[i];

                    let date = new Date();
                    if (preview.status == RegionSelectionConstants.CONFIRMED) {
                        previewList.push(
                            <Row key={i} className="summary-slot-div">
                                <Col span={6}>
                                    <Row className="redo-slot-id">
                                        <Col span={20}>Slot {i + 1}
                                            <Row>
                                                <Col span={20}>
                                                <Divider/>
                                                </Col>
                                            </Row>                                    
                                        </Col>
                                        <Col span={4} className="redo-slot-text">
                                            <Row>
                                                <Button
                                                    type="default"
                                                    shape="circle"
                                                    icon={<UndoOutlined />}
                                                    onClick={() => this.redoSlot(i)} />
                                            </Row>
                                            <Row>Redo</Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <span className="arial-font" style={{marginRight:10, fontSize:13}}>Slide Name</span>
                                        <Search
                                            className="slide-name-input" 
                                            value={preview.slideName} 
                                            placeholder={preview.slideName} 
                                            enterButton="Read Barcode"  
                                            onChange={(e) => this.setScanName(i, e.target.value)}
                                            onSearch={() => this.readbarcode(i, preview.response.barcodeLocation)}/>
                                    </Row>
                                </Col>
                                <Col span={14}>
                                    <img 
                                        className="summary-preview"
                                        src={"/dev-ssd/" + preview.response.overlayedMaskLocation + "?d=" + date.getTime()}></img>
                                </Col>
                                <Col span={4}>
                                    <img 
                                        className="summary-barcode"
                                        src={"/dev-ssd/" + preview.response.barcodeLocation + "?d=" + date.getTime()}></img>
                                </Col>
                            </Row>)
                    }
                }
                summaryDiv = <Row>
                                <Col offset={3} span={18} className="summary-preview-list scrollbar">
                                    <Row key={0}>
                                        <Row style={{fontSize:13}}>Scanning Speed</Row> 
                                        <Radio.Group defaultValue={scanSpeed.FAST} buttonStyle="solid" onChange={(event) => {
                                                this.props.dispatch(setScanSpeedForAllSlots(event.target.value))
                                            }}>
                                            <Radio.Button value={scanSpeed.FAST}>Fast</Radio.Button>
                                            <Radio.Button value={scanSpeed.MEDIUM}>Medium</Radio.Button>
                                            <Radio.Button value={scanSpeed.SLOW}>Slow</Radio.Button>
                                        </Radio.Group>
                                    </Row>
                                    {previewList}
                                </Col>
                            </Row>;
            }
        }

        let defaultSlotComp = [
            <Row key={0} style={{textAlign:"center", fontSize:20, paddingTop:100}}>Select a slot to continue</Row>, 
            <Row key={1} style={{textAlign:"center", fontSize:30}}><ArrowLeftOutlined /></Row>
        ]

        if (cassetteSize > 1) {
            defaultSlotComp.push(
                <Row key={2}>
                    <Col span={12} offset={6}>
                        <Divider key={2} />
                    </Col>
                </Row>
            )
            defaultSlotComp.push(
                <Row key={3} className="scan-all-button-div">
                    <Button className={"scan-all-button"} type="danger" icon={<ScanOutlined />} size={'large'} onClick={this.scanAllSlots}>Scan All Slots</Button>
                </Row>
            )
            
        }

        let noRegionSelected = 0;
        for (let i = 0; i < cassetteSize; i++) {
            let preview = this.props.previews[i];
            if ((preview || {}).status == RegionSelectionConstants.NOT_STARTED) {
                noRegionSelected += 1;
            }
        }

        return (
            <Row className="rounded-container">
                {slotMenu}
                <Col key={0} span={cassetteSize > 1 ? 20 : 24}>
                    {this.state.showSummary ? 
                        summaryDiv : (
                            this.state.activeSlot === -1 || noRegionSelected == cassetteSize ? 
                            defaultSlotComp :
                            <SlotComponent id={this.state.activeSlot} 
                                            device_id={this.props.id} 
                                            isObjectiveSwitcherPresent={this.state.isObjectiveSwitcherPresent} 
                                            allowScanOutputFormatChange={this.state.allowScanOutputFormatChange}
                                            allowObjectiveChange={this.state.allowObjectiveChange}
                                            defaultConfig={this.state.defaultConfig}
                                            cassetteSize={cassetteSize} 
                                            startScanning={this.onStartScanning}/>
                                            )
                    }
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
    };
};

export default connect(mapStateToProps)(ScanWorkflow);
