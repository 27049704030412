import React, {Component} from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText
} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {getAnnotationIcon} from "../utils/annotations_app_utils";
import {limitString} from "../../../utils/utils";
import AnnotationListExpandedComponent from "./annotation_list_expanded_comp";

class AnnotationAccordion extends Component {

	render() {
		let annotation = this.props.annotation;

		return <Accordion expanded={annotation.expanded || false} disableGutters defaultChecked={false} onChange={e =>
			e.target.tagName !== 'INPUT' && this.props.onAccordionChange ? this.props.onAccordionChange() : undefined}>
			<AccordionSummary sx={{paddingX: 0}}
			                  expandIcon={<ExpandMore sx={{color: morphleBaseTheme1.palette.text.primary}}/>}>
				<Checkbox
					checked={annotation.checked || false}   // || false to handle undefined
					disableRipple
					size={'small'}
					icon={<CheckBoxOutlineBlankIcon/>}
					checkedIcon={<CheckBoxIcon color={"secondary"}/>}
					onChange={this.props.onCheckboxChange}
				/>
				<ListItemButton onClick={this.props.onAnnotationClick ?
					() => this.props.onAnnotationClick(annotation) : undefined}>
					{getAnnotationIcon(annotation)}
					<ListItemText
						id={annotation.id}
						primary={limitString(annotation.title, 13)}
						sx={{marginLeft: '8px'}}
					/>
				</ListItemButton>
				<ListItemSecondaryAction sx={{paddingX: 1}}>
					{annotation.actionComponent}
				</ListItemSecondaryAction>
			</AccordionSummary>
			<AccordionDetails>
				{annotation.onlyShowResult ?
					annotation.resultComponent :
					<AnnotationListExpandedComponent
						annotation={annotation}
						extra={annotation.resultComponent}
						// resultComponent if changed should render in all children (with more than depth 2)
						// keeping result component in annotation object won't render component (depth > 2),
						// so passing it as extra component
					/>
				}
			</AccordionDetails>
		</Accordion>;
	}
}

export default AnnotationAccordion;
