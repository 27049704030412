import { SlideUpdateAlertConstant } from "../actionTypes/slide.update.alert.constant";
import axios from 'axios';
import {message } from 'antd';
import {AuthHeader} from '../helper/auth.token'

export const slideUpdateInitiated = () => ({
    type : SlideUpdateAlertConstant.SLIDE_UPDATE_INITIATED,
})

export const slideUpdateSuccess = () => ({
    type : SlideUpdateAlertConstant.SLIDE_UPDATE_SUCCESS,
})

export const slideUpdateFailed = (error) => ({
    type : SlideUpdateAlertConstant.SLIDE_UPDATE_FAILED,
    response : error
})


export const updateSlide = (id , value) => (dispatch) => {
    dispatch(slideUpdateInitiated());
    let url = `/api/slide/${id}/`
    axios.put(url, value, {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(slideUpdateSuccess(response));
                message.success('Slide Update Successful', 2.5);
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400) {
                dispatch(slideUpdateFailed(response));
                message.error('Slide Update Failed', 2.5);
            }
        })
        .catch(err =>{
                dispatch(slideUpdateFailed(err));
        });
}
