import {  AnnoAlertConstant } from "../actionTypes/anno.constant";
import axios from 'axios';
import { message } from 'antd';
import {AuthHeader} from '../helper/auth.token'

export const annoUpdateInitiated = () => ({
    type : AnnoAlertConstant.ANNO_UPDATE_INITIATED,
});

export const annoSelectId= (id,area) => ({
    type : AnnoAlertConstant.ANNO_SELECT_ID,
    response : id,
    response2 : area
});

export const annoUpdateSuccess = (data) => ({
    type : AnnoAlertConstant.ANNO_UPDATE_SUCCESS,
    response : data
});

export const annoUpdateFailed = (error) => ({
    type : AnnoAlertConstant.ANNO_UPDATE_FAILED,
    response : error
});

export const annoDeleteSuccess = (id) => ({
    type : AnnoAlertConstant.ANNO_DELETE_SUCCESS,
    response : id
});


export const annoUpdateAlert = (value) => (dispatch) => {
    dispatch(annoUpdateInitiated());
    let url = `/api/annotation/`
    axios.put(url, value, {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(annoUpdateSuccess(response));
                message.success('Anno Update Successful', 2.5);
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400){
                message.error('Anno Update Failed', 2.5);
                dispatch(annoUpdateFailed(response));
            }
        })
        .catch(err =>{
                dispatch(annoUpdateFailed(err));
        });
}
