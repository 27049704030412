export const ScanApiConstant = { 
    TAKEN_PREVIEW: "TAKEN_PREVIEW", 
    INIT_PREVIEW: "INIT_PREVIEW", 
    INIT_PREVIEW_LOADER: "INIT_PREVIEW_LOADER", 
    TAKING_PREVIEW: "TAKING_PREVIEW", 
    REGION_SELECTION_DONE: "SELECTION_DONE", 
    SELECTION_CANCELLED: "SELECTION_CANCELLED", 
    SELECTION_CONFIRMED: "SELECTION_CONFIRMED", 
    SPEED_CHANGE: "SPEED_CHANGE", 
    Z_STACK_LEVELS_CHANGE: "Z_STACK_LEVELS_CHANGE", 
    STEP_SIZE_CHANGE: "STEP_SIZE_CHANGE", 
    SPEED_CHANGE_FOR_ALL_SLOTS: "SPEED_CHANGE_FOR_ALL_SLOTS", 
    NAME_CHANGE: "NAME_CHANGE", 
    START_LIVE_VIEW: "START_LIVE_VIEW",
    STOP_LIVE_VIEW: "STOP_LIVE_VIEW",
    UPDATE_LIVE_VIEW: "UPDATE_LIVE_VIEW", 
    FAILED_IN_LIVE_VIEW_UPDATE: "FAILED_IN_LIVE_VIEW_UPDATE", 
    UPDATE_ZOOM_LEVEL_LIVE_VIEW: "UPDATE_ZOOM_LEVEL_LIVE_VIEW", 
    ERRORED_WHILE_TAKING_PREVIEW : "ERRORED_WHILE_TAKING_PREVIEW", 
    ERRORED_WHILE_CONFIRMING_SELECTION : "ERRORED_WHILE_CONFIRMING_SELECTION", 
    SPEED_CHANGE_FOR_TRAY: "SPEED_CHANGE_FOR_TRAY",
    SPECIMEN_CHANGE_FOR_TRAY: "SPECIMEN_CHANGE_FOR_TRAY",
    OBJECTIVE_CHANGE_FOR_TRAY: "OBJECTIVE_CHANGE_FOR_TRAY",

    SET_DO_TILING_ONLY: "SET_DO_TILING_ONLY", 
    UNSET_DO_TILING_ONLY: "UNSET_DO_TILING_ONLY", 

    SET_DO_TILING_AFTER_SCAN: "SET_DO_TILING_AFTER_SCAN", 
    UNSET_DO_TILING_AFTER_SCAN: "UNSET_DO_TILING_AFTER_SCAN", 

    SPECIMEN_TYPE_CHANGE: "SPECIMEN_TYPE_CHANGE",
    OBJECTIVE_TYPE_CHANGE: "OBJECTIVE_TYPE_CHANGE", 
    SCAN_OUTPUT_FORMAT_CHANGE: "SCAN_OUTPUT_FORMAT_CHANGE",

    START_LIVE_VIEW_FULL_SCREEN: "START_LIVE_VIEW_FULL_SCREEN",
    STOP_LIVE_VIEW_FULL_SCREEN: "STOP_LIVE_VIEW_FULL_SCREEN",
    UPDATE_LIVE_VIEW_FULL_SCREEN: "UPDATE_LIVE_VIEW_FULL_SCREEN",

    UPDATE_QUADRANT_VIEW: "UPDATE_QUADRANT_VIEW",
    UPDATE_FULLSCREEN_MODE: "UPDATE_FULLSCREEN_MODE",

    UPDATE_LIVE_IMAGE_POLLING_STATUS: "UPDATE_LIVE_IMAGE_POLLING_STATUS",

    UPDATE_LIVE_VIEW_URL: "UPDATE_LIVE_VIEW_URL",

    UPDATE_DROP_DISTANCE_POINT: "UPDATE_DROP_DISTANCE_POINT",
    UPDATE_DROP_DISTANCE_POINT_ONLY: "UPDATE_DROP_DISTANCE_POINT_ONLY",
    UPDATE_DROP_DISTANCE_POINT_LENGTH: "UPDATE_DROP_DISTANCE_POINT_LENGTH",
    UPDATE_FOCUS_POINTS: "UPDATE_FOCUS_POINTS",

    UPDATE_STATUS: "UPDATE_STATUS",

    UPDATE_ACTION_ONGOING: "UPDATE_ACTION_ONGOING",

    UPDATE_CANVAS_SIZE: "UPDATE_CANVAS_SIZE"
 }