import React, { Component } from 'react';
import HeaderComp from '../component/header'
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { message, Col } from "antd";
import CaseComp from '../component/case/caseComp';
import { Spin, Divider, Table, Input, Button, Row, Tooltip, Radio, Card, Empty, Pagination, Tag, Select } from 'antd';
import { Link } from "react-router-dom";
import { globalUrlPrefix } from '../utils/const';
import { SearchOutlined, DoubleLeftOutlined, EyeOutlined, EditOutlined, FilePdfOutlined, HighlightOutlined , EditTwoTone} from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import AllScansCaseView from './scansCase';
import { updateUrlStateInReducer, updateCaseSearch, changePage } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert } from '../action/cases.action'
import { updateUrlStateInReducerClia } from '../action/search.case.action';
import { connect } from "react-redux";
import { closeDrawer } from "../action/dashboard.action";
import { CaseView } from '../view/caseview';
import { makeCaseRead } from '../action/cases.action';
import AllScansCliaCaseView from './caseClia';
import cookie from "react-cookies";
import {Alert, Result, Tabs, Typography } from 'antd';
import { loadCases } from '../action/cases.action';
import CliaView from './cliaview';

const { Search } = Input;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const { TabPane } = Tabs;
const { Title } = Typography;
const { TextArea } = Input;
var querystring = require('querystring');
const pageSize = 12;

class SingleValidationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValidationId: '',
            type : 'assigned',
            total_count: '',
            assigned_name: '',
            unassigned_name: '',
            search: '',
            description: '',
            assigned_count: '',
            unassigned_count: '',
            edit_name_description: false,
            old_name: '',
            old_description: '',
            validationIds: [],
            key: '1',
        }
        // this.props.dispatch(loadCases());
    }

    componentWillMount = () => {
        this.getValidationData(this.props.match.params.id);
        this.getCasesCount();
        this.getAllValidationIds();
    }

    getAllValidationIds = () => {
      let url = `/api/get_all_validation_id/`;
        axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
            .then(response => {
                let data = response.data['data']
                let ids = []
                for(let i=0; i<data.length; i++){
                  ids.push(data[i].id)
                }
                // let ids = response.data.ids
               // let op = ids.map((id,index) =><Option value={id} key={index}>{id}</Option>)
                this.setState({validationIds:ids})
            })
    }

    getValidationData = (id) => {
        let url = `/api/get_validation_data/?id=${this.props.match.params.id}`;
        axios.get(url)
            .then(response => {
              this.setState({
                selectedValidationId: response.data['name'],
                description: response.data['description']
              })
            })
      }
    

    // componentDidUpdate = (prevProps, prevState) => {
    //     if(prevProps.total != this.props.total){
    //         let assigned_name = ""
    //         let unassigned_name = ""
    //         if(this.state.type == "assigned"){
    //             assigned_name = `Assigned (${this.props.total})`
    //             unassigned_name = `Unassigned (${this.state.total_count-this.props.total})`
    //         }
    //         else{
    //             assigned_name = `Assigned (${this.state.total_count-this.props.total})`
    //             unassigned_name = `Unassigned (${this.props.total})`
    //         }
    //         this.setState({
    //             assigned_name: assigned_name,
    //             unassigned_name: unassigned_name
    //         })
    //     }
    // }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevState.type != this.state.type){
          this.props.dispatch(changePage(1, this.props.urlState))
        }
    }

    onTabChange = (key, event) => {
        if(key == 1){
            this.setState({
                type: 'assigned',
                key: '1'
            })
        }
        else{
            this.setState({
                type: 'unassigned',
                key: '2',
            })
        }
    }
    getCasesCount = () => {
        let url = `/api/total_cases_count/?id=${this.props.match.params.id}`;
        axios.get(url)
            .then(response => {
              this.setState({total_count:response.data["count"],
              assigned_count: response.data['assigned_count'],
              unassigned_count: response.data['unassigned_count']      })
              let count = response.data["count"]
              let assigned_count = response.data['assigned_count']
              let assigned_name = ""
              let unassigned_name = ""
              if(this.state.type == "assigned"){
                  assigned_name = `Assigned (${this.state.assigned_count})`
                  unassigned_name = `Unassigned (${this.state.unassigned_count})`
              }
              else{
                  assigned_name = `Assigned (${this.state.assigned_count})`
                  unassigned_name = `Unassigned (${this.state.unassigned_count})`
              }
              this.setState({
                  assigned_name: assigned_name,
                  unassigned_name: unassigned_name
              })
            })
      }

      onCaseSearch = (value) => {
        this.setState({
          selectedRowKeys: [],
        })
        this.props.dispatch(updateCaseSearch(value, this.props.urlState));
      }

      onInputChange = (e) => {
        if (e.target.value == '') {
          this.onSeachReset();
        }
        this.setState({
          search: e.target.value,
        });
      }
      onSeachReset = () => {
        if (this.state.search != '') {
          let search = '';
          this.props.dispatch(updateCaseSearch(search, this.props.urlState));
          this.setState({
            search,
            // selectedRowKeys: [],
          });
        }
      }
    
     changeValidationName = (newName) => {
        this.setState({
            selectedValidationId: newName.target.value,
     })}

     changeValidationDescription = (newDescription) => {
       console.log(newDescription.target.value)
        this.setState({
            description: newDescription.target.value,
         })
     }

     editValidationNameDescriptionSwitch = () => {
       let edit_name_description = this.state.edit_name_description
       if(edit_name_description){
          this.setState({
            selectedValidationId: this.state.old_name,
            description: this.state.old_description,
          })
       }else{
         this.setState({
          old_name: this.state.selectedValidationId,
          old_description: this.state.description,
         })
       }
       this.setState({
         edit_name_description : !edit_name_description,
       })
     }
    
     submitNameandDescription = () => {
      let validationIds = this.state.validationIds;
      if(validationIds.includes(this.state.selectedValidationId) && this.state.selectedValidationId!=this.state.old_name){
        message.warn("Validation Name already exists. Chose Another")
        return;
      }
      let url = `/api/update_validation/`;
      axios.post(url,querystring.stringify({id:this.props.match.params.id,name:this.state.selectedValidationId,
        description:this.state.description}))
            .then(response => {
              this.setState({
                edit_name_description : !this.state.edit_name_description,
              })
                message.success("Validation Updated Successfully")
            }).catch(err => {
              message.info("Validation could'nt be updated!")
            })
     }

    render() {
        let headerSwitchButton;
        if(!this.state.edit_name_description){
          headerSwitchButton =  <Tooltip placement="right" title={"Edit Validation Name/Description"}>
                                    <Button onClick={this.editValidationNameDescriptionSwitch}>
                                      <EditTwoTone />
                                    </Button>
                                </Tooltip>
          
        }

        let header;
        if(this.state.edit_name_description){
          header = <div>
                      <Row>
                        <Col span={6} offset={0.5} style={{ marginTop:'20px', marginLeft:'10px'}}>
                          <Input
                              value = {this.state.selectedValidationId}
                              onChange = {this.changeValidationName}
                              onPressEnter = {this.saveValidationData}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextArea
                                autosize = {{ minRows: 2, maxRows: 4 }}
                                defaultValue = {this.state.description}
                                onChange = {this.changeValidationDescription}
                                placeholder = {"Enter Validation Study Description here"}
                                // onPressEnter = {this.saveValidationData}
                                style = {{ marginTop:'20px', marginLeft:'10px', 
                              width:'25%'}}
                              />
                              <Button style={{marginLeft: '10px', marginTop: '40px'}} onClick={this.submitNameandDescription}>
                              SUBMIT
                              </Button>
                              <Button style={{marginLeft: '10px', marginTop: '40px'}} onClick={this.editValidationNameDescriptionSwitch}>
                              CANCEL
                              </Button>
                        </Col>    
                      </Row>
                      <Row>
                         
                      </Row>
          </div>
        }
        else{
          header = <div>
                      <Row>
                          <Col span={10} offset={0.5} style={{ marginTop:'20px', marginLeft:'10px'}}>
                          <Title> {this.state.selectedValidationId} </Title>
                          </Col>
                          <Col style={{display:'flex', justifyContent:'flex-end', marginRight:'10px', marginTop:'20px'}}>
                            {headerSwitchButton}
                          </Col>
                      </Row>
                      <Row>
                          <Col span={6} offset={0.5} style={{marginTop:'-10px', marginLeft:'10px'}}>
                              <Title level={4}
                              > {this.state.description} </Title>
                          </Col>

                          <Col span={6} offset={this.state.description==null||this.state.description==undefined||this.state.description==''?6:1} style={{ marginTop:'0px', marginBottom:'10px'}}>
                            <Search
                              value={this.state.search || ''}
                              onChange={this.onInputChange}
                              placeholder="Search by Case ID"
                              onSearch={this.onCaseSearch}
                              enterButton
                            />
                          </Col>
                          <Col span={6} style={{ marginTop:'0px', marginBottom:'10px'}}>
                            <Button type="danger" onClick={this.onSeachReset} style={{ marginLeft: 10 }}>
                              Reset
                            </Button>
                          </Col>
                      </Row>
                  </div>
        }

        // console.log("description", this.state.des)
        // let assigned_name = "";
        // let unassigned_name = "";
        // if(this.state.type == "assigned"){
        //     assigned_name = `Assigned (${this.props.total})`
        //     unassigned_name = `Unassigned (${this.state.total_count-this.props.total})`
        // }
        // else{
        //     assigned_name = `Assigned (${this.state.total_count-this.props.total})`
        //     unassigned_name = `Unassigned (${this.props.total})`
        // }

        let cases_component;
        if(cookie.loadAll().is_staff==="true"){
            cases_component = <Tabs defaultActiveKey="1" onTabClick={this.onTabChange} destroyInactiveTabPane={true} activeKey={this.state.key}>
            <TabPane tab={this.state.assigned_name} key="1">
                <CliaView validationId = {this.props.match.params.id} forceRender={true} type={"assigned"} total_count={this.state.assigned_count}/>
            </TabPane>
            <TabPane tab={this.state.unassigned_name} key="2">
                <CliaView validationId = {this.props.match.params.id} forceRender={true} type={"unassigned"} total_count={this.state.unassigned_count}/>
            </TabPane>
        </Tabs>
        }
        else{
            cases_component = <CliaView validationId = {this.props.match.params.id} type={this.state.type} total_count={this.state.unassigned_count}/>
        }
        
        return (
            <div>
                {/* <Row>
                    <Col span={10} offset={0.5} style={{ marginTop:'20px', marginLeft:'10px'}}>
                    <Title> {this.state.selectedValidationId} </Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={6} offset={0.5} style={{marginTop:'-10px', marginLeft:'10px'}}>
                        <Title level={4}
                        > {this.state.description} </Title>
                    </Col>

                    <Col span={6} offset={this.state.description==null?6:1} style={{ marginTop:'10px'}}>
                      <Search
                        value={this.state.search || ''}
                        onChange={this.onInputChange}
                        placeholder="Search by Case ID"
                        onSearch={this.onCaseSearch}
                        enterButton
                      />
                    </Col>
                    <Col span={6} style={{ marginTop:'10px'}}>
                      <Button type="danger" onClick={this.onSeachReset} style={{ marginLeft: 10 }}>
                        Reset
                      </Button>
                    </Col>
                </Row> */}
                {/* {headerSwitchButton} */}
                {header}
                {cases_component}
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        allCases: state.casesReducer,
        total: state.caseStatusReducer.total,
        urlState: state.searchCaseReducer,
    };
};

export default connect(mapStateToProps)(SingleValidationView);
