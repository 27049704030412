import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Row, Col, PageHeader, Tooltip, Button, Input, Divider } from 'antd';
import { globalUrlPrefix } from '../utils/const';

const { TextArea } = Input;

export class Videos extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allVideos: {},
            deviceId: this.props.match.params.id,
        }
    }

    componentDidMount = () => {
        this.getVideos();
    }

    getVideos = () => {
        let url = "/api/videos/"
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                var allVideos = {};
                for (var i = 0; i < response.data.length; i++) {
                    let video = response.data[i];
                    allVideos[video.id] = video;
                }
                this.setState({
                    allVideos,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
            });
    }

    renderVideos = (videos) => {
        let ids = Object.keys(videos);
        let videosComponent = [];
        for(let i = 0; i < ids.length; i+=4) {
            videosComponent.push(
                <Row style={{marginTop: 10, marginBottom: 10}}>
                    {
                        ids[i] != undefined ?
                        this.getVideoThumbnail(videos[ids[i]]) :
                        null
                    }
                    {
                        ids[i + 1] != undefined ?
                        this.getVideoThumbnail(videos[ids[i + 1]]) :
                        null
                    }
                    {
                        ids[i + 2] != undefined ?
                        this.getVideoThumbnail(videos[ids[i + 2]]) :
                        null
                    }
                    {
                        ids[i + 3] != undefined ?
                        this.getVideoThumbnail(videos[ids[i + 3]]) :
                        null
                    }
                </Row>
            )
        }
        return videosComponent;
    }

    getVideoThumbnail = (video) => {
        const isLongDesc = video.description.length > 150;
        const descElem = (
            <div>{isLongDesc ? `${video.description.slice(0, 150)}...` : video.description}</div>
        );
        return(
            <Col offset={1} span={5}>
                <div style={{width: 300, cursor: 'pointer'}} onClick={() => this.goToVideo(video.id)}>
                    <Row>
                        <img src={video.thumbnail_url} width={300} height={200} />
                    </Row>
                    <Row style={{textAlign: 'center'}}>
                        <b style={{fontSize: '1.4rem', fontWeight: 500}}>{video.name}</b>
                    </Row>
                    <Row style={{textAlign: 'justify'}}>
                        {descElem}
                    </Row>
                </div>
            </Col>
        )
    }

    goToVideo = (id) => {
        window.location.href = "/" + globalUrlPrefix + "/video-viewer/" + id;
    }

    goToSettings = (e) => {
        window.location.href = "/" + globalUrlPrefix + "/settings/" + this.state.deviceId;
    }

    render() {

        let videosComponent = undefined;

        videosComponent = this.renderVideos(this.state.allVideos);

        return (
            <div>
                <Row>
                    <Col offset={10} span={10}>
                        <PageHeader style={{ backgroundColor: "#ffffff00", paddingBottom: "10px" }} title="Helper Guide"></PageHeader>
                    </Col>
                    <Col span={4} style={{ padding: '16px 24px' }}>
                        <Button type="primary" onClick={this.goToSettings} ghost>
                            Back to Settings
                        </Button>
                    </Col>
                </Row>
                <Divider />
                {videosComponent}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(Videos);
