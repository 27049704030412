import React, { Component } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import './App.scss';
import '../node_modules/ol/ol.css'
import Morpheus from './view/morpheus';
import LoginView from './view/loginview';
import { historyMP } from './helper/history';
import { PrivateRoute, LoginRoute } from '../src/helper/private.route';
import { globalUrlPrefix, openInNewTabType } from './utils/const'
import "./asset/style/morpheus.css"
import "./asset/style/color.css";
import 'antd/dist/antd.css';
import Scan from './view/scan';
import StageCleaner from './view/stagecleanerview';
import Videos from './view/videosview';
import VideoViewer from './view/videoviewer';
import VignetteDiagnostics from './view/vignettediagnostics';
import CustomContext from './view/customcontext';
import { makeCaseRead } from './action/cases.action';
import { makeSlideRead } from './action/slides.action';
import ManualMode from "./view/manualmode";
import GammaScan from "./view/gammaScan";
import LiveMode from "./view/livemodenew";
import TileViewerScan from "./view/tileviewerscan";

class App extends Component {
    
  constructor(props) {
      super(props);
  }
    
  linkViewerNewTab = (id, type, displayOrder) => {
      let url = '';
      let value = {displayOrder: displayOrder};
      if(type === openInNewTabType.CASE) {
          url = `/${globalUrlPrefix}/case/${id}`;
          this.props.dispatch(makeCaseRead(id, true, value));
      } else if(type === openInNewTabType.SLIDE) {
          url = `/${globalUrlPrefix}/${(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).viewer}/${id}`;
          this.props.dispatch(makeSlideRead(id, true, value));
      }
      window.open(url, "_blank");
  };

  render() {

    let menu = [{"label": "Open in a new tab", "callback": this.linkViewerNewTab}];

    return (
      <Router history={historyMP}>
      <div className="App">
          <Switch>
            <LoginRoute exact path="/login" component={LoginView} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/viewer/:id/"} component={Scan} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/gamma-viewer/:id/"} component={GammaScan}/>
            <PrivateRoute path={"/" + globalUrlPrefix + "/tileviewer/:id/"} component={TileViewerScan} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/manualmode/:id/"} component={ManualMode} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/stagecleaner/:id/"} component={StageCleaner} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/videos/:id/"} component={Videos} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/video-viewer/:id/"} component={VideoViewer} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/vignette_diagnostics/:id/"} component={VignetteDiagnostics} />
            <PrivateRoute path={"/" + globalUrlPrefix + "/livemode/:id/"} component={LiveMode} />
            <PrivateRoute path={"/" + globalUrlPrefix} component={Morpheus}/>
            <Route path={"/dashboard"} render={() => <Redirect to={"/" + globalUrlPrefix + "/dashboard" }/>} />
            <Route path={"/viewer/:id/"} render={(props) =>
                <Redirect to={"/" + globalUrlPrefix + "/viewer/" + props.match.params.id }/>} />
            <Route path={"/gamma-viewer/:id/"} render={(props) =>
                <Redirect to={"/" + globalUrlPrefix + "/gamma-viewer/" + props.match.params.id} />} />
            <Route path={"/"} render={() => <Redirect to={"/login" }/>} />
          </Switch>
          <CustomContext 
              items={menu} 
              visible={this.props.popupProps.visible} 
              x={this.props.popupProps.x} 
              y={this.props.popupProps.y} 
              record={this.props.popupProps.record} 
              height={this.props.popupProps.height} 
          />
      </div>
      </Router>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
    return {
        popupProps: state.contextMenuReducer.popupProps,
        allCases: state.contextMenuReducer.popupProps,
        allSlides: state.contextMenuReducer.popupProps,
    };
};

export default connect(mapStateToProps)(App);
