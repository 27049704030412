import React, { Component } from 'react';
import { Row, Col, Icon, Divider, Tooltip } from 'antd';
import { connect } from "react-redux";
import LiveModeCameraSettings from "./camera_settings";
import LiveModeZStackSettings from "./z_stack_settings";
import LiveModeGeneralSettings from "./general_settings";
import LiveModeAdvancedSettings from "./advanced_settings";

class LiveModeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <Row className="relative-position" type="flex">
                <Col span={6} style={{ borderRight: '1px solid #e8e8e8' }} className="custom-left-right-padding-settings">
                    <Row>
                        <Col span={24}>
                            <LiveModeCameraSettings />
                        </Col>
                    </Row>
                    <br />
                </Col>
                <Col span={7} style={{ borderRight: '1px solid #e8e8e8' }} className="custom-left-right-padding-settings">
                    <Row>
                        <Col span={24}>
                            <LiveModeZStackSettings />
                        </Col>
                    </Row>
                    <br />
                </Col>
                <Col span={7} style={{ borderRight: '1px solid #e8e8e8' }} className="custom-left-right-padding-settings">
                    <Row>
                        <Col span={24}>
                            <LiveModeGeneralSettings />
                        </Col>
                    </Row>
                    <br />
                </Col>
                <Col span={4} className="custom-left-right-padding-settings">
                    <Row>
                        <Col span={24}>
                            <LiveModeAdvancedSettings />
                        </Col>
                    </Row>
                    <br />
                </Col>
                <div className="app-icon-closeable-handle settings-close-icon-div" onClick={this.props.toggleSettings}>
                    <Tooltip 
                        title="Click to Close"
                        placement="right"
                        overlayStyle={{
                            fontSize:"10px"
                        }}
                    >
                        <span className="settings-close-icon">x</span>
                    </Tooltip> 
                </div>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
    };
};

export default connect(mapStateToProps)(LiveModeSettings);
