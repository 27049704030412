import React from  "react";
import App from '../utils/app'
import * as keys from './apps/app_keys'

import ZoomControls from './apps/zoom_controls';
import SettingsApp from "../neoviewer/apps/settings";
import SlideInfoApp from "../neoviewer/apps/slide_info";
import PreviewMap from "./apps/preview";
import { slideViewerType } from "../../utils/const";
import SlideMetaApp from "./apps/slide_meta_app";
import AnnotationsApp from './apps/tagger/annotations_app';


const appify = (key, content, allAppsState) => {
    return (
        allAppsState.apps_installed[key.id + "_installed"] === undefined || allAppsState.apps_installed[key.id + "_installed"] === true ? 
            <App
                key={key.id}
                appKey={key}
                viewerType={slideViewerType.TILE_SLIDE_VIEWER}
                startTop={((allAppsState.app_state[key.id] || {}).position || {y : key.defaultY}).y}
                startLeft={((allAppsState.app_state[key.id] || {}).position || {x : key.defaultX}).x}
                defaultClosed={(allAppsState.app_state[key.id] || {}).closed}
                content={content} /> : undefined
    )
}

const getZoomControlsApp = (slideState, allAppsState) => {
    return appify(keys.zoomAppKey, 
                <ZoomControls 
                    view={slideState.view}
                    zoomScale={slideState.zoomScale}
                    maxZoom={slideState.maxZoom} 
                    goHome={slideState.goHome} />, 
                    allAppsState
    )
}

const getSettingsApp = (slideState, allAppsState) => {
    return appify(keys.settingsAppKey, 
                <SettingsApp
                    slideData={slideState.slide_data}
                    mapId={slideState.mapId}
                    slidemap={slideState.slidemap} />, 
                    allAppsState
    )
}

const getSlideInfoApp = (slideState, allAppsState) => {
    return appify(keys.slideInfoAppKey, 
                <SlideInfoApp
                    slideData={slideState.slide_data}
                    tileViewerSlideMetaData={slideState.tileViewerSlideMeta}
                    viewerType={slideViewerType.TILE_SLIDE_VIEWER}
                />, 
                allAppsState
    )
}

const getSlideMetaApp = (slideState, allAppsState, updatePlotStatus, changeSpinningState) => {
    return appify(keys.slideMetaAppKey, 
                <SlideMetaApp
                    slideData={slideState.slide_data}
                    tileViewerSlideMetaData={slideState.tileViewerSlideMeta}
                    imageCoordinate={slideState.imageCoordinate}
                    plotStatus={slideState.plotStatus}
                    txtyInfo={slideState.txtyInfo}
                    showGoToPositionButton={slideState.showGoToPositionButton}
                    goToPositionVal={slideState.goToPositionVal}
                    updatePlotStatus={updatePlotStatus}
                    changeSpinningState={changeSpinningState}
                    layer={slideState.layer}
                    rescanLayer={slideState.rescanLayer}
                    enhLayer={slideState.enhLayer}
                />, 
                allAppsState
    )
}

const getPreviewApp = (slideState, allAppsState) => {
    return appify(keys.previewControlsKey, 
            <PreviewMap
                slidemap={slideState.slidemap}
                view={slideState.view}
                layers={[slideState.layer, slideState.focusPointsPlotLayer]}
                addPreviewMapControl={slideState.addPreviewMapControl}
                zoomScale={slideState.zoomScale}
                maxZoom={slideState.maxZoom}
                zoomLevels={slideState.zoomLevels} />, 
                allAppsState
    )
}

const getAnnotationApp = (slideState, allAppsState) => {
    return appify(keys.annotationsAppKey, 
                <AnnotationsApp
                    id={keys.annotationsAppKey.id}
                    slide={slideState.slide_data}
                    slidemap={slideState.slidemap}
                    zoomScale={slideState.zoomScale} />, 
                    allAppsState
    )
}

export const getAllApps = (slideState, allAppsState, updatePlotStatus, changeSpinningState) => {
    return [
        getZoomControlsApp(slideState, allAppsState),
        getSettingsApp(slideState, allAppsState), 
        getSlideInfoApp(slideState, allAppsState), 
        getSlideMetaApp(slideState, allAppsState, updatePlotStatus, changeSpinningState), 
        getPreviewApp(slideState, allAppsState),
        getAnnotationApp(slideState, allAppsState), 
    ]
}