export const AdminStateConstants = {
    INIT_ADMIN_STATE: "INIT_ADMIN_STATE", 
    UPDATE_ADMIN_APP_CLOSED_STATE: "UPDATE_ADMIN_APP_CLOSED_STATE", 
    UPDATE_ADMIN_APP_POSITION: "UPDATE_ADMIN_APP_POSITION", 
    UPDATE_ADMIN_APP_CLICK_TIME: "UPDATE_ADMIN_APP_CLICK_TIME", 
    UPDATE_ADMIN_STATE: "UPDATE_ADMIN_STATE", 
    IMAGES_ACTION_ONGOING: "IMAGES_ACTION_ONGOING",
    UPDATE_SAVED_IMAGES: "UPDATE_SAVED_IMAGES",
    CLOSE_ALL_APPS_ADMIN: "CLOSE_ALL_APPS_ADMIN",
    UPDATE_DEVICE_IP: "UPDATE_DEVICE_IP",
    UPDATE_NUMBER_OF_SLOTS: "UPDATE_NUMBER_OF_SLOTS",
    ARRANGE_APPS_LIVE_MODE: "ARRANGE_APPS_LIVE_MODE",
    UPDATE_LIVE_MODE_PREVIEW_VECTOR_LAYER: "UPDATE_LIVE_MODE_PREVIEW_VECTOR_LAYER",
    UPDATE_DO_DROP_DISTANCE_AF: "UPDATE_DO_DROP_DISTANCE_AF",
    UPDATE_DO_AUTO_FOCUS: "UPDATE_DO_AUTO_FOCUS",
    UPDATE_TAKE_Z_STACK: "UPDATE_TAKE_Z_STACK",
    UPDATE_SHOW_Z_STACK_IMAGES: "UPDATE_SHOW_Z_STACK_IMAGES",
    UPDATE_LAST_IMAGE_TIMESTAMP_LIVE_MODE: "UPDATE_LAST_IMAGE_TIMESTAMP_LIVE_MODE",
    UPDATE_Z_STACK_LEVEL: "UPDATE_Z_STACK_LEVEL",
    UPDATE_FOCUS_MODE: "UPDATE_FOCUS_MODE",
    UPDATE_LIVE_MODE_ACTION: "UPDATE_LIVE_MODE_ACTION",
    UPDATE_MAIN_LIGHT: "UPDATE_MAIN_LIGHT",
    UPDATE_AREA_VISITED: "UPDATE_AREA_VISITED",
    UPDATE_CAMERA_CONFIG: "UPDATE_CAMERA_CONFIG",
    UPDATE_PREVIEW_IMAGE_EXTENT: "UPDATE_PREVIEW_IMAGE_EXTENT",
    UPDATE_SLOT_ID: "UPDATE_SLOT_ID",
    UPDATE_METRIC_STATE: "UPDATE_METRIC_STATE",
    UPDATE_USE_FOUR_X_FOR_PREVIEW: "UPDATE_USE_FOUR_X_FOR_PREVIEW",
    UPDATE_USE_STREAK_FOCUS: "UPDATE_USE_STREAK_FOCUS",
    UPDATE_INPUT_FIELD_IN_FOCUS: "UPDATE_INPUT_FIELD_IN_FOCUS",
    UPDATE_CURR_POINT: "UPDATE_CURR_POINT"
} 