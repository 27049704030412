import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { Spin, Row, Col} from 'antd';
import { Player } from 'video-react';
import "../../node_modules/video-react/dist/video-react.css";

export class VideoViewer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isFetching: true,
            videoId: this.props.match.params.id,
            video: {},
        }
    }

    componentDidMount = () => {
        this.getVideo();
    }

    getVideo = () => {
        let url = "/api/videos/" + this.state.videoId;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    video: response.data,
                    isFetching: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    isFetching: false,
                });
            });
    }

    render() {

        return (
            <div>
                <Spin tip="Loading..." spinning={this.state.isFetching}>
                    <Row style={{marginTop: 20}}>
                        <Col offset={1} span={16}>
                            {this.state.video.id != undefined ?
                                <Player
                                    playsInline
                                    poster={this.state.video.thumbnail_url}
                                    src={this.state.video.url}
                                    fluid={false}
                                    width={1200}
                                    height={678}
                                /> :
                                <div>Error while playing video.</div>
                            }
                        </Col>
                        <Col span={7}>
                            <Row>
                                <Col>
                                    <b style={{fontSize: '1.4rem', fontWeight: 500}}>{this.state.video.name}</b>
                                </Col>
                            </Row>
                            <Row style={{textAlign: 'justify', marginTop: 10}}>
                                <Col>
                                    <div style={{width: 500}}>{this.state.video.description}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(VideoViewer);
