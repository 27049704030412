import React, {Component} from  "react";
import {Row, Col, Divider, Switch, Image, Button } from 'antd';
import {connect} from 'react-redux';
// import Viewer from "react-viewer/dist/index.js";
import 'antd/dist/antd.css';
// import ReactPanZoom from "react-image-pan-zoom-rotate";
// import ImageZoom from 'react-image-zoom-rotate';
// import ImageView from 'react-image-viewer'
import { Slider } from 'antd';

class BarcodeViewer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentRotation:0,
        }

       
    }

    // changeRotation = (value) => {
    //     this.setState({
    //         currentRotation:value
    //     })
    //     document.getElementById('barcode-img').style.transformOrigin="50% 50%"
    //     document.getElementById('barcode-img').style.transform=`rotate(${value}deg)`
    // }

    rotate = () => {
        let rotation = this.state.currentRotation
        rotation = (rotation+90)%360
        this.setState({
            currentRotation:rotation
        })
        document.getElementById('barcode-img').style.transformOrigin="50% 50%"
        document.getElementById('barcode-img').style.transform=`rotate(${rotation}deg)`
    }

    render(){
        
        // console.log("barcode")
        return(
            <Row className="app-parent overlayed-component screenshot-app-parent" style={{width:'350px', height:'350px'}}>
                <Col>
                    {/* <ReactPanZoom
                        alt="Couldnt load BARCODE image"
                        image={"/scan-hdd/morphle_test/"+this.props.path+'/pre-processed/barcode_crop.jpg'}
                    /> */}

                    {/* <ImageZoom
                            // rotate={(clockwise, antiClockwise) => {
                            // return (
                            //     <div>
                            //     <Button onClick={clockwise} style={{ position: 'absolute', right: 0, zIndex: 1 }}>
                            //         clockwise
                            //     </Button>
                            //     <Button onClick={antiClockwise} style={{ position: 'absolute', left: 0, zIndex: 1 }}>
                            //         anti-clockwise
                            //     </Button>
                            //     </div>
                            // );
                            // }}
                            // src={"/scan-hdd/morphle_test/"+this.props.path+'/pre-processed/barcode_crop.jpg'}
                            // width={400}
                            // zoomWidth={500}
                        />     */}
    
                        {/* <ImageView smallImage={"/scan-hdd/morphle_test/"+this.props.path+'/pre-processed/barcode_crop.jpg'} /> */}
                        <img id = 'barcode-img' src={"/scan-hdd/morphle_test/"+this.props.path+'/pre-processed/barcode_crop.jpg'} style={{height:'250px', width:'250px', marginLeft:'40px'}} ></img>
                        {/* <p>Rotate</p>
                        <Slider defaultValue={0} onChange = {this.changeRotation} max={360} /> */}
                        <Button type="primary" style={{marginTop:'23px'}} onClick={this.rotate}>Rotate</Button>
                </Col>
                
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
    }
}

export default connect(mapStateToProps)(BarcodeViewer);