import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row, Col, Button, Icon, Divider, message} from 'antd'

import "../../../asset/style/neotileviewer/slide_meta_app.css"
import { tileViewerPlotTypes } from "../../../utils/const";
import { getPrefixedUrl } from "../../../utils/utils";
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";

class SlideMetaApp extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
            mapLayer: true,
            rescanLayer: true,
            enhLayer: true
        };
    }

    moveToPosition = () => {
        let partsOfUrl = "api~stage~move";
        let url = `/server/scano/1` + `/` + partsOfUrl;

        if (this.props.goToPositionVal.length > 0 && !isNaN(this.props.goToPositionVal[0])) {
            url += '?x=' + this.props.goToPositionVal[0];
        } else {
            url += '?x=';
        }

        if (this.props.goToPositionVal.length > 1 && !isNaN(this.props.goToPositionVal[1])) {
            url += '&y=' + this.props.goToPositionVal[1];
        } else {
            url += '&y=';
        }

        if (this.props.goToPositionVal.length > 2 && !isNaN(this.props.goToPositionVal[2])) {
            url += '&z=' + this.props.goToPositionVal[2];
        } else {
            url += '&z=';
        }

        url += '&abs=true&real=false';

        this.props.changeSpinningState(true);
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    message.success("Successfully moved to the position.", 2.5);
                }
                else {
                    console.log(response);
                    message.error("Not able to move to specified position!!", 2.5);
                }
                this.props.changeSpinningState(false);
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to move to specified position!!", 2.5);
                this.props.changeSpinningState(false);
            })
    }

    toggleMapLayer = () => {
        this.props.layer.setVisible(!this.props.layer.getVisible());
        this.setState({
            mapLayer: !this.state.mapLayer,
        })
    }

    toggleRescanLayer = () => {
        this.props.rescanLayer.setVisible(!this.props.rescanLayer.getVisible());
        this.setState({
            rescanLayer: !this.state.rescanLayer,
        })
    }

    toggleEnhLayer = () => {
        this.props.enhLayer.setVisible(!this.props.enhLayer.getVisible());
        this.setState({
            enhLayer: !this.state.enhLayer,
        })
    }

    render() {

        let XYMapPosition = "NA";

        if (this.props.imageCoordinate[0] >= 0 && this.props.imageCoordinate[0] < this.props.slideData.x_fields && 
            this.props.imageCoordinate[1] >= 0 && this.props.imageCoordinate[1] < this.props.slideData.y_fields) {
            XYMapPosition = "x" + this.props.imageCoordinate[0] + "y" + this.props.imageCoordinate[1];
        }

        let focusStreaksBaseUrl = `${this.props.slideData.bucket_name}/${this.props.slideData.path}meta/plots/focus_streaks_strobe.html`;
        let focusStreaksUrl = getPrefixedUrl(focusStreaksBaseUrl, this.props.slideData);

        let planeBaseUrl = `${this.props.slideData.bucket_name}/${this.props.slideData.path}meta/plots/plane.html`;
        let planeUrl = getPrefixedUrl(planeBaseUrl, this.props.slideData);

        let plane3dBaseUrl = `${this.props.slideData.bucket_name}/${this.props.slideData.path}meta/plots/plane3d.html`;
        let plane3dUrl = getPrefixedUrl(plane3dBaseUrl, this.props.slideData);

        let streaksDoneBaseUrl = `${this.props.slideData.bucket_name}/${this.props.slideData.path}meta/plots/streaks_done.html`;
        let streaksDoneUrl = getPrefixedUrl(streaksDoneBaseUrl, this.props.slideData);

        let scanFolderUrl = "app://" + this.props.slideData.loc_on_machine + "/scans/" + this.props.slideData.bucket_name + "/" + this.props.slideData.path;

        return (
            <div className="app-parent overlayed-component slide-meta-app-parent">
                <Divider />
                <Row>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <a href={scanFolderUrl}>{this.props.slideData.morphle_id}</a>
                    </Col>
                </Row>
                <Divider />
                {this.props.showGoToPositionButton ? 
                    <div>
                        <Row>
                            <Col span={24} style={{textAlign: 'center'}}>
                                <Button type="link" size="small" onClick={this.moveToPosition}>
                                    Go to recent position (Focus Profile)
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                    </div> : null
                }
                <Row>
                    <Col span={9}>
                        {"Disagreement: "}
                    </Col>
                    <Col span={15}>
                        {this.props.tileViewerSlideMetaData.disagreement_info}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Image XY Map Pos: "}
                    </Col>
                    <Col span={15}>
                        {XYMapPosition}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Image XY Motor Pos: "}
                    </Col>
                    <Col span={15}>
                        {this.props.tileViewerSlideMetaData.raw.motor_mappings[XYMapPosition + "." + this.props.slideData.img_type] != undefined ?
                            this.props.tileViewerSlideMetaData.raw.motor_mappings[XYMapPosition + "." + this.props.slideData.img_type] : "NA"}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"TXTY Histogram: "}
                    </Col>
                    <Col span={15}>
                        <a href={"/server/v3/tissue/" + this.props.slideData.morphle_id + "/tiling/" + this.props.tileViewerSlideMetaData.txty_histo_link} target="_blank">Click Here</a>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Focus Streaks: "}
                    </Col>
                    <Col span={15}>
                        <a href={focusStreaksUrl} target="_blank">Click Here</a>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Plane: "}
                    </Col>
                    <Col span={15}>
                        <a href={planeUrl} target="_blank">Click Here</a>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Plane 3D: "}
                    </Col>
                    <Col span={15}>
                        <a href={plane3dUrl} target="_blank">Click Here</a>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={9}>
                        {"Streaks Done: "}
                    </Col>
                    <Col span={15}>
                        <a href={streaksDoneUrl} target="_blank">Click Here</a>
                    </Col>
                </Row>
                <Divider />
                <h4 className="app-heading">Toggle Plots</h4>
                <Divider />
                <Row>
                    <Col offset={0} span={8} className="center-align">
                        <Button 
                            onClick={this.toggleMapLayer} 
                            size="small" 
                            type={this.state.mapLayer ? "primary": ""}
                        >
                            Background
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={this.toggleRescanLayer} 
                            size="small" 
                            type={this.state.rescanLayer ? "primary": ""}
                        >
                            Show Pre-Rescan
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={this.toggleEnhLayer} 
                            size="small" 
                            type={this.state.enhLayer ? "primary": ""}
                        >
                            Show Pre-Enhance
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STREAK)} 
                            size="small" 
                            type={this.props.plotStatus.streak ? "primary": ""}
                        >
                            Streak
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FOCUS_PROFILE)} 
                            size="small" 
                            type={this.props.plotStatus.focusProfile ? "primary": ""}
                        >
                            Image Grid
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FOCUS_POINTS)} 
                            size="small" 
                            type={this.props.plotStatus.focusPoints ? "primary": ""}
                        >
                            Focus Points
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FOCUS_TRIANGLES)} 
                            size="small" 
                            type={this.props.plotStatus.focusTriangles ? "primary": ""}
                        >
                            Focus Triangles
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.ISLANDS)} 
                            size="small" 
                            type={this.props.plotStatus.islands ? "primary": ""}
                        >
                            Islands
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.RESCAN_ISLANDS)} 
                            size="small" 
                            type={this.props.plotStatus.rescanIslands ? "primary": ""}
                        >
                            Rescan Islands
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={6} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS)} 
                            size="small" 
                            type={this.props.plotStatus.plannedVsActualFocus ? "primary": ""}
                        >
                            Est v Scan Z Up
                        </Button>
                    </Col>
                    <Col span={6} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS_DOWN)} 
                            size="small" 
                            type={this.props.plotStatus.plannedVsActualFocusDown ? "primary": ""}
                        >
                            Est v Scan Z Down
                        </Button>
                    </Col>
                    <Col span={6} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS)} 
                            size="small" 
                            type={this.props.plotStatus.plannedVsGoldenFocus ? "primary": ""}
                        >
                            Est v Best Z
                        </Button>
                    </Col>
                    <Col span={6} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS_DOWN)} 
                            size="small" 
                            type={this.props.plotStatus.plannedVsGoldenFocusDown ? "primary": ""}
                        >
                            Est v Best Z Down
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={4} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.TXTY_INFO)} 
                            size="small" 
                            type={this.props.plotStatus.txtyInfo ? "primary": ""}
                        >
                            TXTY
                        </Button>
                    </Col>
                    <Col span={4} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.TXTY_TEXT_INFO)} 
                            size="small" 
                            type={this.props.plotStatus.txtyTextInfo ? "primary": ""}
                        >
                            TXTY Text
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_FOURD)} 
                            size="small"
                            type={this.props.plotStatus.fftFourD ? "primary": ""}
                        >
                            FFT 4D
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_FOURD_TEXT)} 
                            size="small"
                            type={this.props.plotStatus.fftFourDText ? "primary": ""}
                        >
                            FFT 4D Text
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.RATIO_FFT_FOURD)} 
                            size="small"
                            type={this.props.plotStatus.ratioFFTFourD ? "primary": ""}
                        >
                            Ratio FFT 4D
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.RATIO_FFT_FOURD_TEXT)} 
                            size="small"
                            type={this.props.plotStatus.ratioFFTFourDText ? "primary": ""}
                        >
                            Ratio FFT 4D Text
                        </Button>
                    </Col>
                    {/* <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.SPARSE_FFT_HEATMAP)} 
                            size="small"
                            type={this.props.plotStatus.sparseFFTHeatmap ? "primary": ""}
                        >
                            Sparse FFT Heatmap
                        </Button>
                    </Col> */}
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_INFO)} 
                            size="small"
                            type={this.props.plotStatus.stitchInfo ? "primary": ""}
                        >
                            Stitch Info
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FOCUS_FOURD)} 
                            size="small" 
                            type={this.props.plotStatus.focusFourD ? "primary": ""}
                        >
                            Focus 4D
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_HEAT_MAP)} 
                            size="small" 
                            type={this.props.plotStatus.FFTHeatmap ? "primary": ""}
                        >
                            FFT Heatmap
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_RECOLORED)} 
                            size="small" 
                            type={this.props.plotStatus.FFTRecolored ? "primary": ""}
                        >
                            FFT Recolored
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.REPAIR_INFO)} 
                            size="small" 
                            type={this.props.plotStatus.repairInfo ? "primary": ""}
                        >
                            Repair Info
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.DROP_DISTANCE)} 
                            size="small" 
                            type={this.props.plotStatus.dropDistance ? "primary": ""}
                        >
                            Drop Distance
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_DISAGREEMENT)} 
                            size="small" 
                            type={this.props.plotStatus.stitchDisagreement ? "primary": ""}
                        >
                            Stitch Disagreement
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_DELTAS)} 
                            size="small" 
                            type={this.props.plotStatus.stitchDeltas ? "primary": ""}
                        >
                            Stitch Deltas
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.BLURS0)} 
                            size="small"
                            type={this.props.plotStatus.blurs0 ? "primary": ""}
                        >
                            Blurs 0
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.PATH)} 
                            size="small" 
                            type={this.props.plotStatus.path ? "primary": ""}
                        >
                            Path
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.DYNAMIC_FOCUS)} 
                            size="small" 
                            type={this.props.plotStatus.dynamicFocus ? "primary": ""}
                        >
                            Dynamic Focus
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_FINE)} 
                            size="small" 
                            type={this.props.plotStatus.fftFine ? "primary": ""}
                        >
                            FFT Fine
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.FFT_FINE_TEXT)} 
                            size="small" 
                            type={this.props.plotStatus.fftFineText ? "primary": ""}
                        >
                            FFT Fine Text
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={24} className="center-align">
                        Round 0
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND0_GRAPH_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound0Graphv2 ? "primary": ""}
                        >
                            Path
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_PRE_ROUND0_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPreRound0Disagreementv2 ? "primary": ""}
                        >
                            Before
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_POST_ROUND0_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPostRound0Disagreementv2 ? "primary": ""}
                        >
                            After
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND0_TILEMOTION_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound0TileMotionv2 ? "primary": ""}
                        >
                            Tiles Moved
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={24} className="center-align">
                        Round 1
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND1_GRAPH_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound1Graphv2 ? "primary": ""}
                        >
                            Path
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_PRE_ROUND1_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPreRound1Disagreementv2 ? "primary": ""}
                        >
                            Before
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_POST_ROUND1_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPostRound1Disagreementv2 ? "primary": ""}
                        >
                            After
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND1_TILEMOTION_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound1TileMotionv2 ? "primary": ""}
                        >
                            Tiles Moved
                        </Button>    
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={24} className="center-align">
                        Round 2
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND2_GRAPH_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound2Graphv2 ? "primary": ""}
                        >
                            Path
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_PRE_ROUND2_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPreRound2Disagreementv2 ? "primary": ""}
                        >
                            Before
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_POST_ROUND2_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPostRound2Disagreementv2 ? "primary": ""}
                        >
                            After
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND2_TILEMOTION_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound2TileMotionv2 ? "primary": ""}
                        >
                            Tiles Moved
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={24} className="center-align">
                        Round 3
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND3_GRAPH_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound3Graphv2 ? "primary": ""}
                        >
                            Path
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_PRE_ROUND3_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPreRound3Disagreementv2 ? "primary": ""}
                        >
                            Before
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_POST_ROUND3_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchPostRound3Disagreementv2 ? "primary": ""}
                        >
                            After
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_ROUND3_TILEMOTION_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchRound3TileMotionv2 ? "primary": ""}
                        >
                            Tiles Moved
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={12} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_DISAGREEMENT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchDisagreementv2 ? "primary": ""}
                        >
                            Final Disagreement
                        </Button>
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_DISAGREEMENT_TEXT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchDisagreementTextv2 ? "primary": ""}
                        >
                            Text
                        </Button>
                    </Col>
                    <Col span={12} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.STITCH_GRAPH_TEXT_v2)} 
                            size="small" 
                            type={this.props.plotStatus.stitchGraphTextv2 ? "primary": ""}
                        >
                            Text+Levels
                        </Button>
                    </Col>
                </Row>
                <Row className="top-row-margin">
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.TILT_FOCUS_POINTS)} 
                            size="small" 
                            type={this.props.plotStatus.tiltFocusPoints ? "primary": ""}
                        >
                            Tilt Focus Points
                        </Button>
                    </Col>
                    <Col span={8} className="center-align">
                        <Button 
                            onClick={() => this.props.updatePlotStatus(tileViewerPlotTypes.COUNTY_TILT_INFO)} 
                            size="small" 
                            type={this.props.plotStatus.countyTiltInfo ? "primary": ""}
                        >
                            County Adjacent FU
                        </Button>
                    </Col>
                </Row>
                <br />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.tileViewerUrlReducer,
    }
}

export default connect(mapStateToProps)(SlideMetaApp);
