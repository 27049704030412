import React from  "react";
import { Icon } from 'antd'

export const cameraAppKey = {
    id: "camera_app", 
    title : "Camera App", 
    showTitle : true, 
    icon: <Icon type="camera" />, 
    defaultY: 2, 
    defaultX: 30
}

export const previewAppLiveModeKey = {
    id: "preview_app_live_mode", 
    title : "Preview App", 
    showTitle : true, 
    icon: <Icon type="pic-center" />, 
    defaultY: 2, 
    defaultX: 30
}

export const savedImagesAppKey = {
    id: "saved_images_app", 
    title : "Saved Images App", 
    showTitle : true, 
    icon: <Icon type="picture" />, 
    defaultY: 2, 
    defaultX: 30
}