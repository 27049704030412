import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row, Col, Button, Icon, message} from 'antd'
import { updateDigitalZoomStatus, updateZStackLevel } from "../../../action/morpheus.state.action"
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import cookie from 'react-cookies';

import "../../../asset/style/neoviewer/zoom_controls.css"

class ZStackControls extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidUpdate = (prevProps) => {
    }

    incrementZoomLevel = () => {
        if(!this.props.urlState.onStitchedLayer) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel + 1));
        } else {
            message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        }
    }

    decrementZoomLevel = () => {
        if(!this.props.urlState.onStitchedLayer) {
            this.props.dispatch(updateZStackLevel(this.props.urlState.zStackLevel - 1));
        } else {
            message.error("Z Stack only allowed on higher zoom levels.", 2.5);
        }
    }

    render(){
        
        let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
        let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;
        

        return (
            <div className="app-parent overlayed-component">
                <Row>
                    <Button style={{padding: '0px 10px'}} className="zoom-controls-button disable-button-present-mode">
                        <Row style={{fontSize: 13}}>
                            Z
                        </Row>
                        <Row style={{fontSize: 13}}>
                            Stack
                        </Row>
                    </Button>
                </Row>
                <Row>
                    <Button 
                        className={"zoom-controls-button plus-button disable-button-present-mode"}
                        onClick={this.incrementZoomLevel}
                        disabled={this.props.urlState.zStackLevel >= maxZLevel || !this.props.urlState.takeZStack}
                        >
                        <Icon className="zoom-controls-icon" type="plus" />
                    </Button>
                </Row>
                <Row>
                    <Col className="zoom-controls-button unselectable zoom-level-button">
                        <Row>{this.props.urlState.zStackLevel}</Row>
                    </Col>
                </Row>
                <Row>
                    <Button 
                        className="zoom-controls-button minus-button disable-button-present-mode"
                        onClick={this.decrementZoomLevel}
                        disabled={this.props.urlState.zStackLevel <= minZLevel || !this.props.urlState.takeZStack}
                        ><Icon className="zoom-controls-icon" type="minus" /></Button>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
    }
}

export default connect(mapStateToProps)(ZStackControls);
