
class ErrorCode {
    constructor(code, message, adminOnly=false) {
        this.code = code;
        this.message = message;
        this.adminOnly = adminOnly;
    }
}

export const scannerErrorCodes = {
    SOFT_LIMITS_EXCEPTION : new ErrorCode(230, "Soft limits hit!", true),
    MORPHLE_HARDWARE_EXCEPTION : new ErrorCode(231, "Hardware exception occured!"),

    // Loader
    TRAY_HAS_CASSETTE_EXCEPTION : new ErrorCode(240, "Tray already has Cassette!"),
    STAGE_HAS_CASSETTE_EXCEPTION : new ErrorCode(241, "Stage already has Cassette!"),
    TRAY_DOES_NOT_HAVE_CASSETTE_EXCEPTION : new ErrorCode(242, "Tray does not have a Cassette!"),
    STAGE_DOES_NOT_HAVE_CASSETTE_EXCEPTION : new ErrorCode(243, "Stage does not have a Cassette!"),
    FORK_HAS_CASSETTE_EXCEPTION : new ErrorCode(244, "Fork has a Cassette"),
    BAY_HAS_CASSETTE_EXCEPTION : new ErrorCode(245, "Loading Bay already has a Cassette!"),
    BAY_DOES_NOT_HAVE_CASSETTE_EXCEPTION : new ErrorCode(246, "Loading Bay does not have a Cassette!"),
    LOADER_CALIBRATION_EXCEPTION : new ErrorCode(247, "Loader is not Calibrated!"),

    // Other Hardware Exception Codes
    CAMERA_EXCEPTION : new ErrorCode(232, "Camera exception occured!", true),
    PREVIEW_CAMERA_EXCEPTION : new ErrorCode(233, "Preview exception occured!", true),
    ARDUINO_EXCEPTION : new ErrorCode(234, "Arduino exception occured!", true),
}

export const errorCodeMap = {
    230: scannerErrorCodes.SOFT_LIMITS_EXCEPTION,
    231: scannerErrorCodes.MORPHLE_HARDWARE_EXCEPTION,    

    // Loader
    240: scannerErrorCodes.TRAY_HAS_CASSETTE_EXCEPTION,
    241: scannerErrorCodes.STAGE_HAS_CASSETTE_EXCEPTION,
    242: scannerErrorCodes.TRAY_DOES_NOT_HAVE_CASSETTE_EXCEPTION,
    243: scannerErrorCodes.STAGE_DOES_NOT_HAVE_CASSETTE_EXCEPTION,
    244: scannerErrorCodes.FORK_HAS_CASSETTE_EXCEPTION,
    245: scannerErrorCodes.BAY_HAS_CASSETTE_EXCEPTION,
    246: scannerErrorCodes.BAY_DOES_NOT_HAVE_CASSETTE_EXCEPTION,
    247: scannerErrorCodes.LOADER_CALIBRATION_EXCEPTION,

    // Other Hardware Exception Codes
    232: scannerErrorCodes.CAMERA_EXCEPTION,
    233: scannerErrorCodes.PREVIEW_CAMERA_EXCEPTION,
    234: scannerErrorCodes.ARDUINO_EXCEPTION,
}