import { SearchAlertConstant } from "../actionTypes/search.constant";
import { loadSlides, loadTags, loadInvalidSlides } from "./slides.action";
import { getNewState } from "../reducer/search.reducer";
import { loadGroups } from "./users.action";

export const searchInitiated = () => ({
    type: SearchAlertConstant.SEARCH_INITIATED
})

export const searchSuccess = () => ({
    type: SearchAlertConstant.SEARCH_SUCCESS
})

export const searchFailed = (error) => ({
    type: SearchAlertConstant.SEARCH_FAILED,
    response: error
})

export const clearAllFilters = () => dispatch => {
    dispatch(loadSlides(getNewState()));
}

export const updateTagsAndSearches = (tags, searches, currentState) => dispatch => {
    var newState = {
        ...currentState,
        tags: tags,
        searches: searches, 
        page: 1
    }
    dispatch(loadSlides(newState));
}

export const updateTags = (tags, currentState) => dispatch => {
    var newState = {
        ...currentState,
        tags: tags,
        page: 1
    }
    dispatch(loadSlides(newState));
}

export const getStarred = (currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState,
        starred: true, 
        barcode_failed: false,
        case_not_assigned: false, 
        page: 1
    }))
}

export const getAll = (currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState,
        starred: false, 
        barcode_failed: false, 
        case_not_assigned: false, 
        invalid: false,
        page: 1
    }))
}

export const getAllInvalid = (currentState) => dispatch => {
    dispatch(loadInvalidSlides({
        ...currentState,
        starred: false, 
        barcode_failed: false, 
        case_not_assigned: false, 
        invalid: true,
        page: 1
    }))
}

export const getBarcodeFailed = (currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState,
        starred: false, 
        barcode_failed: true, 
        case_not_assigned: false,
        page: 1
    }))
}

export const getCaseNotAssigned = (currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState,
        starred: false, 
        case_not_assigned: true, 
        barcode_failed: false, 
        page: 1
    }))
}

export const changeOrdering = (newOrdering, currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState,
        ordering: newOrdering, 
        page: 1
    }));
}

export const setDateRange = (newDateGte, newDateLte, currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState, 
        date__gte: newDateGte, 
        date__lte: newDateLte, 
        page: 1
    }));
}

export const changePage = (pageNumber, currentState) => dispatch => {
    dispatch(loadSlides({
        ...currentState, 
        page: pageNumber
    }))
}

export const updateUrlStateInReducer = (oldState) => dispatch => {
    var newState = urlParser(oldState);
    dispatch(loadSlides(newState));
    dispatch(loadTags());
    dispatch(loadGroups());
}

export const urlParser = (parsedPartsOfUrl) => {
    try {
        var url = window.location.href.split('?')[1];
        var partsOfUrl = url.split('&');
        partsOfUrl.map((part) => {
            var key = part.split('=')[0];
            var value = part.split('=')[1];
            if (key === 'tags') {
                var tags = value.split('%20');
                tags.map((tag) => {
                    if (tag.length > 0) {
                        parsedPartsOfUrl.tags.push(tag.replace('%23', '#'));
                    }
                });
            } else if (key === 'search') {
                if (value.length > 0) parsedPartsOfUrl.searches.push(value);
            } else if (key === 'starred') {
                parsedPartsOfUrl.starred = value === 'true' ? true : false;
            } else if (key === 'barcode_failed') {
                parsedPartsOfUrl.barcode_failed = value === 'true' ? true : false;
            } else if (key === 'case_not_assigned') {
                parsedPartsOfUrl.case_not_assigned = value === 'true' ? true : false;
            } else if (key === 'ordering') {
                parsedPartsOfUrl.ordering = value;
            } else if (key === 'date__gte') {
                parsedPartsOfUrl.date__gte = value;
            } else if (key === 'date__lte') {
                parsedPartsOfUrl.date__lte = value;
            } else if (key === 'page') {
                parsedPartsOfUrl.page = parseInt(value);
            }
        });
    } catch (err) {
        console.log('Could not parse Url : ' + err.message);
    }
    return parsedPartsOfUrl;
}