import React, { createRef, useRef } from "react";
import cookie from "react-cookies";
import { Spin, Progress, Popconfirm, Icon, Button, Menu, Dropdown, Tooltip, Alert, Row, Col, Tag, Rate, Modal, Carousel } from 'antd';

import {
    BuildOutlined,
    CloudSyncOutlined,
    DeleteFilled,
    EditOutlined,
    ExclamationCircleOutlined,
    ExclamationCircleTwoTone,
    ExportOutlined,
    FileDoneOutlined,
    FolderOutlined,
    LoadingOutlined,
    SolutionOutlined,
    StarFilled,
    UploadOutlined,
    RightOutlined, 
    LeftOutlined
} from '@ant-design/icons';
import { startTiling, startExport } from '../../action/slides.action'
import EditableTagGroup from '../viewer/viewer.tags';
import { getPreviewUrl } from '../../utils/utils';
import { scanSpeed, objectiveType, slideViewType, globalUrlPrefix } from '../../utils/const';

const iconFontSize = "15px";

export const getPreviewComponent = (slide, is_cloud, isMobile) => {
    let previewPath = slide.preview_image;

    if (is_cloud === false) {
        previewPath = previewPath.replace("/static/", "");
        previewPath = getPreviewUrl(previewPath.replace('stitched_small_viewer', 'selection_marked'), slide);
    }
    return <img
        width={isMobile ? 100 : 200}
        alt="logo"
        src={previewPath}
    />
}

export const getLabelComponent = (slide, is_cloud, isMobile) => {
    let labelPath = slide.preview_image;

    if (is_cloud === false) {
        labelPath = labelPath.replace("/static/", "");
        labelPath = getPreviewUrl(labelPath.replace('stitched_small_viewer', 'barcode_crop'), slide);
    } else {
        labelPath = labelPath.replace('stitched_small_viewer', 'barcode_crop');
    }

    return <img
        height={96}
        alt="barcode-does-not-exist"
        src={labelPath}
    />
}

export const getLabelComponentRotated = (slide, is_cloud, isMobile) => {
    let labelPath = slide.preview_image;

    if (is_cloud === false) {
        labelPath = labelPath.replace("/static/", "");
        labelPath = getPreviewUrl(labelPath.replace('stitched_small_viewer', 'barcode_crop'), slide);
    }

    return <img
        height={180}
        width={96}
        alt="barcode-does-not-exist"
        src={labelPath}
        className="rotate270"
    />
}

export const getNameComponent = (slide, handleAssignCase) => {
    return <div onClick={(event) => event.stopPropagation()}>
        <Row>
            {slide.name}
        </Row>
    </div>
}

export const getAssignCaseComponent = (slide, handleAssignCase) => {
    if (slide.case == null) {
        return null;
    } else {
        return (
            <Tooltip placement="bottomRight" title={"Change Name"}>
                <EditOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        handleAssignCase(slide, !slide.barcode_failed);
                    }}
                    className="icon-hover slide-icons" />
            </Tooltip>
        );
    }
}

export const getAssignCaseButtonComponent = (slide, handleAssignCase) => {
    return (
        <Button
            className="lighter-danger-button"
            type="danger"
            size='small'
            onClick={(event) => {
                event.stopPropagation();
                handleAssignCase(slide, !slide.barcode_failed);
            }}
            ghost
        >
            <ExclamationCircleOutlined style={{ color: 'red', fontSize: '15px' }} />
            Assign case
                </Button>
    );
}

export const getNameComponentCaseSlide = (slide) => {
    if (slide.unread) {
        return <b>{slide.name}</b>
    } else {
        return <span>{slide.name}</span>
    }
}

export const stitchingPercentComponent = (slide) => {
    if(slide.status === 0 || slide.status === 9 || slide.status === 12) {
        return null;
    } else {
        return <div>
            <Progress percent={slide.stitcher_percent} type="circle" width={45} className="custom-font-family" />
        </div>;
    }
}

export const getUploadComponent = (slide, allIds, handleUpload) => {

    var uploadStatus = slide.upload_status;

    var uploadComponent = null;

    if (slide.isFetching) {
        return getBusyComponent();
    }

    if (slide.status === 9 || slide.status === 12) {

        let adminMenu = <Menu>
            <Menu.Item>
                <Button onClick={(event) => {
                    event.stopPropagation();
                    handleUpload(slide.morphle_id, slide.id, allIds, false, false);
                }}>Upload without Debug</Button>
            </Menu.Item>
            <Menu.Item>
                <Button onClick={(event) => {
                    event.stopPropagation();
                    handleUpload(slide.morphle_id, slide.id, allIds, true, false);
                }}>Upload with Debug</Button>
            </Menu.Item>
        </Menu>

        let adminUploadButton = <Dropdown key="admin-upload" overlay={adminMenu} placement="topLeft">
            <UploadOutlined
                key="cloud-upload"
                onClick={(event) => { event.stopPropagation() }}
                className="slide-icons" />
        </Dropdown>

        let uploadButton = <Tooltip title="Upload">
            <UploadOutlined
                key="cloud-upload"
                onClick={(event) => {
                    event.stopPropagation();
                    handleUpload(slide.morphle_id, slide.id, allIds, false, false);
                }}
                className="slide-icons" />
        </Tooltip>

        if (uploadStatus === 0 && cookie.loadAll().deployment_mode === 'offline') {
            uploadComponent = cookie.loadAll().superuser === "true" ? getHoverIconEffect(adminUploadButton) : getHoverIconEffect(uploadButton)
        } else if (uploadStatus === 2) {
            uploadComponent = <Tooltip title="Uploaded">
                <CloudSyncOutlined
                    style={{ cursor: 'default' }}
                    className="slide-icons"
                    onClick={(event) => {
                        event.stopPropagation();
                    }} />
            </Tooltip>
        } else if (uploadStatus === 100) {
            uploadComponent = <div key="2">Error in Upload</div>
        } else if (uploadStatus === 1) {
            uploadComponent = <div key="2">
                Status : <Progress percent={slide.upload_percent} />
            </div>
        }
    } else {
        uploadComponent = <div>Scanning</div>
    }

    return uploadComponent;
}

export const getDbEntryComponent = (slide) => {
    var adminComponent = <Tooltip placement="bottomRight" title="DB Entry">
        <SolutionOutlined
            onClick={(event) => {
                event.stopPropagation();
                window.location.href = "/admin/api/slide/" + slide.id + "/change/";
            }}
            className="icon-hover slide-icons" />
    </Tooltip>;

    return adminComponent;
}

export const getMorphleIDComponent = (slide) => {
    let localPath = "app://" + slide.loc_on_machine + "/scans/" + slide.bucket_name + "/" + slide.path;
    var comp = <Tooltip placement="bottomRight" title={slide.morphle_id}>
        <FolderOutlined
            onClick={(event) => {
                event.stopPropagation();
                window.location.href = localPath;
            }}
            className="icon-hover slide-icons" />
    </Tooltip>;
    return comp;
}

export const getExecutableComponent = (slide) => {
    let localPath = "file:///" + slide.loc_on_machine + "/scans/" + slide.bucket_name + "/" + slide.path + "debug/zprofile/plane3d.html";
    console.log(localPath);
    var comp = <Tooltip placement="bottomRight" title={slide.morphle_id}>
        <Icon 
            onClick={(event) => {
                event.stopPropagation();
                // window.location.href = localPath;
                window.open(localPath, "_blank")
            }}
            type="area-chart"
            className="icon-hover slide-icons"
        />
    </Tooltip>;
    return comp;
}

var getBusyComponent = () => {
    let antIcon = <LoadingOutlined style={{ fontSize: iconFontSize }} spin />;
    return <Spin indicator={antIcon} />
}

export const getTagsComponent = (slide, dispatch, urlState) => {
    return <div onClick={(e) => e.stopPropagation()}>
        <EditableTagGroup tag={slide.tags}
            edit={true}
            morphle_id={slide.morphle_id}
            path={slide.path}
            date={slide.date}
            specimen_type={slide.specimen_type}
            slide_id={slide.id}
        />
    </div>
}


export const getGapsComponent = (slide) => {
    if (slide.gaps_found == -1) {
        return <div style={{fontSize: 10}}>Gap Detection Not Run</div>
    } else if (slide.gaps_found == 0) {
        return <div style={{fontSize: 10}}>No Gaps</div>
    } else {
        return <div><ExclamationCircleTwoTone twoToneColor="red" /> <div style={{fontSize:10}}>{slide.gaps_found} Gaps</div></div>;
    }
}


export const getStarredComponent = (slide, handleStarButton) => {
    let icon = <StarFilled
        onClick={(event) => {
            event.stopPropagation();
            handleStarButton(slide, !slide.starred);
        }}
        style={{ color: slide.starred === true ? "#f5667b" : "" }}
        className="slide-icons" />
    return slide.isFetching ? getBusyComponent() : getHoverIconEffect(icon);
}


export const getDeleteComponent = (slide, handleDelete) => {
    let icon = <DeleteFilled
        onClick={(event) => event.stopPropagation()}
        style={{ textAlign: 'center' }}
        className="icon-hover slide-icons" />
    return slide.isFetching ? getBusyComponent() : <Popconfirm key={0}
        title="Are you sure delete this slide?"
        onConfirm={(event) => {
            event.stopPropagation();
            handleDelete(slide, true);
        }}
        okText="Yes"
        palcement="topRight"
        style={{ padding: 20 }}
        cancelText="No"
        onCancel={(event) => {
            event.stopPropagation();
        }}>
        {/* {getHoverIconEffect(icon)} */}
        {icon}
    </Popconfirm>
}


export const getTilingComponent = (slide, allIds, dispatch) => {

    let tilingStatus = slide.tiling_status;

    let tilingComponent = null;

    if (slide.isFetching) {
        return getBusyComponent();
    }

    if (slide.status === 9 || slide.status === 12) {
        if (cookie.loadAll().superuser === "true") {
            if (tilingStatus === 0 && cookie.loadAll().deployment_mode === 'offline') {
                tilingComponent = <a className="underline-hover"
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(startTiling(slide.morphle_id, slide.id, allIds));
                    }}>
                    <div style={{fontSize: 11}}>Start <br/> Tiling</div>
                </a>;
            } else if (tilingStatus === 2) {
                tilingComponent = <Tooltip placement="bottomRight" title="Tiled">
                    <BuildOutlined
                        onClick={(event) => {
                            event.stopPropagation();
                            window.location.href = "/" + globalUrlPrefix + "/tileviewer/" + slide.id + "/";
                        }}
                        type="build"
                        className="icon-hover slide-icons"
                    />
                </Tooltip>;
            } else if (tilingStatus === 100) {
                tilingComponent =
                    <div key="2">Error occured while Tiling. Check Jenkins 'raw_tiling'.</div>
            } else if (tilingStatus === 1) {
                tilingComponent = <div key="2">
                    Status : <Progress percent={slide.tiling_percent} />
                </div>
            }
        }

        if (slide.specimen_type === "blood" && tilingStatus === 2) {
            tilingComponent = <a href={"/server/v3/tissue/" + slide.morphle_id + "/tiling/"} key="2">
                <div>Tiled</div>
            </a>
        }
    }

    return tilingComponent;
}


export const getOldTilingComponent = (slide, allIds, dispatch) => {

    let tilingStatus = slide.tiling_status;

    let tilingComponent = null;

    if (slide.isFetching) {
        return getBusyComponent();
    }

    if (slide.status === 9 || slide.status === 12) {
        if (cookie.loadAll().superuser === "true") {
            if (tilingStatus === 0 && cookie.loadAll().deployment_mode === 'offline') {
                tilingComponent = <a className="underline-hover"
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(startTiling(slide.morphle_id, slide.id, allIds));
                    }}>
                    <div style={{fontSize: 11}}>Start <br/> Tiling</div>
                </a>;
            } else if (tilingStatus === 2) {
                tilingComponent = <Tooltip placement="bottomRight" title="Old Tile Viewer">
                    <Icon
                        onClick={(event) => {
                            event.stopPropagation();
                            window.location.href = "/server/v3/tissue/" + slide.morphle_id + "/tiling/";
                        }}
                        className="icon-hover slide-icons" />
                </Tooltip>;
            } else if (tilingStatus === 100) {
                tilingComponent =
                    <div key="2">Error occured while Tiling. Check Jenkins 'raw_tiling'.</div>
            } else if (tilingStatus === 1) {
                tilingComponent = <div key="2">
                    Status : <Progress percent={slide.tiling_percent} />
                </div>
            }
        }

        if (slide.specimen_type === "blood" && tilingStatus === 2) {
            tilingComponent = <a href={"/server/v3/tissue/" + slide.morphle_id + "/tiling/"} key="2">
                <div>Tiled</div>
            </a>
        }
    }

    return tilingComponent;
}


export const getExportComponent = (slide, allIds, dispatch) => {

    let exportStatus = slide.export_status;

    let exportComponent = null;

    if (slide.isFetching) {
        return getBusyComponent();
    }

    if (slide.status === 9 || slide.status === 12) {
        if (exportStatus === 0 && cookie.loadAll().deployment_mode === 'offline') {
            exportComponent = <Tooltip placement="bottomRight" title="Export">
                <ExportOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(startExport(slide.morphle_id, slide.name, slide.id, allIds));
                    }}
                    className="icon-hover slide-icons" />
            </Tooltip>
        } else if (exportStatus === 2) {
            exportComponent = <Tooltip placement="bottomRight" title="Exported (Click to Redo)">
                <FileDoneOutlined
                    onClick={(event) => {
                        event.stopPropagation();
                        dispatch(startExport(slide.morphle_id, slide.name, slide.id, allIds));
                    }}
                    className="icon-hover slide-icons" />
            </Tooltip>
        } else if (exportStatus === 100) {
            exportComponent =
                <div key="2">Error occured while Exporting. Contact Administrator.</div>
        } else if (exportStatus === 1) {
            exportComponent = <div key="2">
                Status : <Progress percent={slide.export_percent} />
            </div>
        }
    }

    return exportComponent;
}

export const getObjectiveComponent = (slide, type) => {
    let objectiveComponent = undefined;
    if (slide.objective_type.toLowerCase() == objectiveType.HUNDRED_X.toLowerCase()) {
        objectiveComponent = slide.unread && type == slideViewType.CASEVIEW ? <b>100x</b> : <span>100x</span>;
    } else if (slide.objective_type.toLowerCase() == objectiveType.FORTY_X.toLowerCase()) {
        objectiveComponent = slide.unread && type == slideViewType.CASEVIEW ? <b>40x</b> : <span>40x</span>;
    } else if (slide.objective_type.toLowerCase() == objectiveType.TWENTY_X.toLowerCase()) {
        objectiveComponent = slide.unread && type == slideViewType.CASEVIEW ? <b>20x</b> : <span>20x</span>;
    }
    return objectiveComponent;
}


const getTextWidth = (text, dataIndex, font = "14px -apple-system") => {
    if (dataIndex == "previewComponent") {
        return 300;
    }
    if (dataIndex == "labelComponent") {
        return 100;
    }
    if (dataIndex == "starredComponent") {
        return 5;
    }
    if (dataIndex == "uploadComponent") {
        return 5;
    }
    if (dataIndex == "deleteComponent") {
        return 5;
    }
    if (dataIndex == "name") {
        return 300;
    }
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return Math.round(metrics.width + 80);
};

export const calculateColumnsWidth = (
    columns,
    source,
    maxWidthPerCell = 500
) => {
    const columnsParsed = JSON.parse(JSON.stringify(columns));

    const columnsWithWidth = columnsParsed.map(column =>
        Object.assign(column, {
            width: getTextWidth(column.title, column.dataIndex)
        })
    );

    source.map(entry => {
        columnsWithWidth.map((column, indexColumn) => {
            const columnWidth = column.width;
            const cellValue = Object.values(entry)[indexColumn];

            let cellWidth = getTextWidth(cellValue);

            if (cellWidth < columnWidth) cellWidth = columnWidth;

            if (cellWidth > maxWidthPerCell) cellWidth = maxWidthPerCell;

            columnsWithWidth[indexColumn].width = cellWidth;
        });
    });

    const tableWidth = columnsWithWidth
        .map(column => column.width)
        .reduce((a, b) => {
            return a + b;
        });

    return {
        columns: columnsWithWidth,
        source,
        tableWidth
    };
};

export const encodeStringWithNewLine = (string) => {
    let modifiedValue = string.replace(/\n/g, '&nbnl');
    return modifiedValue;
};

export const decodeStringWithNewLine = (string) => {
    let modifiedValue = string.replace(/&nbnl/g, '\n');
    return modifiedValue;
};

export const getLoadingComponent = () => {
    return <Spin tip="Loading..." /*delay={500}*/>
        <Alert
            message="Your data is loading..."
            description="Please wait..."
            type="info"
        />
    </Spin>
}

export const getErrorComponent = (message = "") => {
    return <Alert
        message="Error"
        description={message.length > 0 ? message : "Some error occured. Please contact admin."}
        type="error"
        showIcon
    />
}

export const assignCaseComponent = (slide, handleAssignCase) => {
    return slide.isFetching ? getBusyComponent() :
        slide.case == null ?
            <Tooltip placement="bottomRight" title="Barcode not detected properly">
                <Button
                    type="danger"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleAssignCase(slide, !slide.barcode_failed);
                    }}
                    ghost
                >
                    <ExclamationCircleOutlined /*theme='filled'*/ style={{ color: 'red', fontSize: '15px' }} />
                    Click to assign case
                    </Button>
            </Tooltip> : null;
}

export const getHoverIconEffect = (icon) => {
    return <div className="icon-hover">
        {icon}
    </div>
}

export const getUnauthorizedComponent = (message = "") => {
    return <Alert
        message="Unauthorized"
        description={message.length > 0 ? message : "You are not allowed to view this page."}
        type="error"
        showIcon
    />
}

export const getScreenshotCarousel = (screenshots) => {

    const slider = createRef()

    let carousel = <Row align={"middle"} justify={"center"}>
        <Col span={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '679px', cursor: 'pointer'}}
            onClick={(e) => {e.stopPropagation(); slider.current.prev();}}>
            <LeftOutlined  />
        </Col>
        <Col span={22}>
            <Carousel ref={ref => {
                slider.current = ref;
                console.log(ref, slider)
            }}>
                {screenshots.map((screenshot) => { 
                    return <div>
                        <img src={screenshot} style={{width: '1328px', height: '679px'}}/>
                    </div>
                })}
            </Carousel>
        </Col>
        <Col span={1} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '679px', cursor: 'pointer'}} 
            onClick={(e) => {e.stopPropagation(); slider.current.next();}}>
            <RightOutlined />
        </Col>
    </Row>

    return carousel
}
