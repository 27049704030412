import React from "react";
import {Icon} from "@mui/material";
import DeepBIoSVG from "../asset/img/deepbio_logo.svg";
import {Science} from "@mui/icons-material";
import {morphleBaseTheme1} from "../component/gammaviewer/themes";


export const  globalUrlPrefix = 'neo';

export const openInNewTabType = {
    CASE: 'case',
    SLIDE: 'slide'
}

export const cameraModeTypes = {
    LIVE_CAMERA: "live_camera",
    QUADRANT_MODE: "quadrant_mode"
}

export const specimenType = {
    HNE_TISSUE: "HNE_TISSUE", 
    BLOOD: "BLOOD", 
    LBC: "PAP", 
    IHC: "IHC",
    BM: "BM"
}

export const scanSpeed = {
    SLOW: "SLOW",
    MEDIUM: "MEDIUM",
    FAST: "FAST",
    ZSTACK: "ZSTACK",
}

export const objectiveType = {
    HUNDRED_X: 'HUNDRED_X',
    TWENTY_X: 'TWENTY_X',
    FORTY_X: 'FORTY_X',
    FOUR_X: 'FOUR_X'
}

export const slideViewType = {
    CASEVIEW: 'caseview',
    DASHBOARDVIEW: 'dashboardview'
}

export const slotCorners = {
    TL: 'TL',
    TR: 'TR',
    BL: 'BL',
    BR: 'BR'
}

export const draggableType = {
    SLIDE_VIEWER: 'SLIDE_VIEWER',
    MANUAL_MODE: 'MANUAL_MODE'
}

export const previewTuningTestAppType = {
    STITCHED_IMAGE: 'stitched_image',
    FULL_SLOT_IMAGE: 'full_slot_image',
    CROPPED_SLOT_IMAGE: 'cropped_slot_image'
}

export const qcSettingsAppConsts = {
    LEVER_REDUCTION: 'leverReduction',
    MIN_X_SOFT_LIMIT: 'minXSoftLimit',
    MAX_X_SOFT_LIMIT: 'maxXSoftLimit',
    MIN_Y_SOFT_LIMIT: 'minYSoftLimit',
    MAX_Y_SOFT_LIMIT: 'maxYSoftLimit',
    MIN_Z_SOFT_LIMIT: 'minZSoftLimit',
    MAX_Z_SOFT_LIMIT: 'maxZSoftLimit',
    X_STEPS_PER_MM: 'xStepsPermm',
    Y_STEPS_PER_MM: 'yStepsPermm',
    Z_STEPS_PER_MM_ON_SCREW: 'zStepsPermmOnScrew',
    X_MAX_SPEED: 'xMaxSpeed',
    Y_MAX_SPEED: 'yMaxSpeed',
    Z_MAX_SPEED: 'zMaxSpeed',
    X_MAX_ACCEL: 'xMaxAccel',
    Y_MAX_ACCEL: 'yMaxAccel',
    Z_MAX_ACCEL: 'zMaxAccel',
}

export const liveModeLoadingMessages = {
    MAIN_LIGHT_ON: 'Turning Main Light On...',
    MAIN_LIGHT_OFF: 'Turning Main Light Off...',
    DROP_DISTANCE_AF: 'Doing Coarse Focus...',
    AUTO_FOCUS: 'Doing Fine Focus...',
    TAKING_IMAGES: 'Saving Image...',
    SET_GAMMA_VALUE: 'Setting Gamma value...',
    CHANGE_LUT_VALUE: 'Changing LUT value...',
    SET_WB: 'Setting WB...',
    MOVE: 'Moving to the specified position...',
    TAKING_PREVIEW: 'Taking Preview...',
    LOADING_IMAGE: 'Loading Image...',
    EJECTING_SLIDE: 'Ejecting Slide...',
    SETTINGS_COARSE_Z_LEVEL: 'Setting Coarse Focus Z Level...',
    DOWNLOADING_IMAGES: 'Downloading Images...',
    SWITCHING_OBJECTIVE: 'Switching Objective...'
}

export const previewCameraType = {
    NORMAL: 'NORMAL',
    FOUR_X: 'FOUR_X'
}

export const metricType = {
    MIN: 'min',
    MAX: 'max',
    SUM: 'sum',
    AVG: 'avg',
    NUM: 'num'
}

export const monthMapping = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
}

export const mapLayerTypes = {
    STITCHED_LAYER_NAME: 'stitched_layer',
    TILED_LAYER_NAME: 'tiled_layer',
    ANNOTATION_LAYER_NAME: 'annotation_layer',
    ANNOTATION_LAYER_NEW : 'annotation_layer_new'
}

export const focusMode = {
    DO_NOTHING: 'DO_NOTHING',
    FINE_FOCUS: 'FINE_FOCUS',
    Z_STACK_MODE: 'Z_STACK_MODE'
}

export const objectiveTypeLiveMode = {
    NO_COVER_SLIP: 'HUNDRED_X',
    COVER_SLIP: 'FORTY_X'
}

export const slideViewerType = {
    NORMAL_SLIDE_VIEWER: 'NORMAL_SLIDE_VIEWER',
    TILE_SLIDE_VIEWER: 'TILE_SLIDE_VIEWER',
    GAMMA_SLIDE_VIEWER: 'GAMMA_SLIDE_VIEWER',
}

export const tileViewerPlotColors = {
    startOfStripColor: "#f0932b",
    endOfStripColor: "#888888",
    startOfSubpathColor: "#eb4d4b",
    endOfSubpathColor: "#e056fd",
    combBoundaryColor: "#27ae60",
    actualFocusColor: "#80ff00",
    plannedFocusColor: "#00BCFF",
    fftRatioCleanColor: "#299646",
    fftRatioStrongColor: "#ffa500",
    fftRatioWeakColor: "#ffa500",
    doneFocusColor: "#f4c2c2",
    focusTrianglesColor: "#00ff00",
    focusFilterTrianglesColor: "#ff0000",
    tiltFocusPointColor: "#006400"
}

export const tileViewerPlotTypes = {
    PATH: "path",
    BLURS0: "blurs0",
    BLURS1: "blurs1",
    FFT_FOURD: "fftFourD",
    FFT_FOURD_TEXT: "fftFourDText",
    RATIO_FFT_FOURD: "ratioFFTFourD",
    RATIO_FFT_FOURD_TEXT: "ratioFFTFourDText",
    FOCUS_FOURD: "focusFourD",
    FFT_HEAT_MAP: "FFTHeatmap",
    FFT_RECOLORED: "FFTRecolored",
    SPARSE_FFT_HEATMAP: "sparseFFTHeatmap",
    STITCH_INFO: "stitchInfo",
    REPAIR_INFO: "repairInfo",
    DYNAMIC_FOCUS: "dynamicFocus",
    DROP_DISTANCE: "dropDistance",
    STITCH_DISAGREEMENT: "stitchDisagreement",
    STITCH_DELTAS: "stitchDeltas",
    STITCH_DISAGREEMENT_v2: "stitchDisagreementv2",
    STITCH_DISAGREEMENT_TEXT_v2: "stitchDisagreementTextv2",
    STITCH_PRE_ROUND0_DISAGREEMENT_v2: "stitchPreRound0Disagreementv2",
    STITCH_POST_ROUND0_DISAGREEMENT_v2: "stitchPostRound0Disagreementv2",
    STITCH_ROUND0_TILEMOTION_v2: "stitchRound0TileMotionv2",
    STITCH_ROUND0_GRAPH_v2: "stitchRound0Graphv2",
    STITCH_PRE_ROUND1_DISAGREEMENT_v2: "stitchPreRound1Disagreementv2",
    STITCH_POST_ROUND1_DISAGREEMENT_v2: "stitchPostRound1Disagreementv2",
    STITCH_ROUND1_TILEMOTION_v2: "stitchRound1TileMotionv2",
    STITCH_ROUND1_GRAPH_v2: "stitchRound1Graphv2",
    STITCH_PRE_ROUND2_DISAGREEMENT_v2: "stitchPreRound2Disagreementv2",
    STITCH_POST_ROUND2_DISAGREEMENT_v2: "stitchPostRound2Disagreementv2",
    STITCH_ROUND2_TILEMOTION_v2: "stitchRound2TileMotionv2",
    STITCH_ROUND2_GRAPH_v2: "stitchRound2Graphv2",
    STITCH_PRE_ROUND3_DISAGREEMENT_v2: "stitchPreRound3Disagreementv2",
    STITCH_POST_ROUND3_DISAGREEMENT_v2: "stitchPostRound3Disagreementv2",
    STITCH_ROUND3_TILEMOTION_v2: "stitchRound3TileMotionv2",
    STITCH_ROUND3_GRAPH_v2: "stitchRound3Graphv2",
    STITCH_GRAPH_v2: "stitchGraphv2",
    STITCH_GRAPH_TEXT_v2: "stitchGraphTextv2",
    TXTY_INFO: "txtyInfo",
    TXTY_TEXT_INFO: "txtyTextInfo",
    STREAK: "streak",
    FOCUS_PROFILE: "focusProfile",
    FOCUS_POINTS: "focusPoints",
    FFT_FINE: "fftFine",
    FFT_FINE_TEXT: "fftFineText",
    FOCUS_TRIANGLES: "focusTriangles",
    FOCUS_FILTER_TRIANGLES: "focusFilterTriangles",
    PLANNED_VS_ACTUAL_FOCUS: "plannedVsActualFocus",
    PLANNED_VS_GOLDEN_FOCUS: "plannedVsGoldenFocus",
    PLANNED_VS_ACTUAL_FOCUS_DOWN: "plannedVsActualFocusDown",
    PLANNED_VS_GOLDEN_FOCUS_DOWN: "plannedVsGoldenFocusDown",
    ISLANDS: "islands",
    RESCAN_ISLANDS: "rescanIslands",
    TILT_FOCUS_POINTS: "tiltFocusPoints",
    COUNTY_TILT_INFO: "countyTiltInfo",
}

export const tileViewerThresholdValues = {
    FFT_RATIO_STRONG_THRESHOLD: 0.80,
    FFT_RATIO_WEAK_THRESHOLD: 0.80,
}

export const tileViewerMinZoomIncrement = 1;

export const progressStage = {
    LOAD_SLIDES : "0",
    SET_PARAMETERS : "1",
    SCANNING: "2"
}

export const brushType = {
    REDRAW : "redraw",
    BRUSH : "brush",
    ERASER: "eraser"
}

export const scanTypeVals = {
    WSI : "wsi",
    Z_STACK : "z_stack"
}

export const scanSettingsTypeVals = {
    GENERAL : "general",
    ADVANCED : "advanced"
}

export const pointSelectionModeVals = {
    COARSE : "coarse",
    FINE : "fine",
    CAMERA_SETTINGS : "camera_settings"
}

export const scanWorkflowLoadingMessages = {
    GETTING_IMAGE: 'Getting Image...',
    SET_EXPOSURE_VALUE: 'Setting Exposure value...',
    SET_GAMMA_VALUE: 'Setting Gamma value...',
    SET_COARSE_FOCUS_POINT: 'Setting Coarse Focus Point...',
    ADD_FINE_FOCUS_POINT: 'Adding Fine Focus Point...',
    REMOVE_FINE_FOCUS_POINT: 'Removing Fine Focus Point...',
    CALCULATE_FOCUS_POINTS: 'Calculating Focus Points...',
    LOADING: 'Loading...',
    EMPTY_MESSAGE: ''
}

export const StitchDirection = {
    LEFT: "LEFT_DIRECTION",
    RIGHT: "RIGHT_DIRECTION",
    UP: "UP_DIRECTION",
    DOWN: "DOWN_DIRECTION",
    UNKNOWN_DIRECTION: "UNKNOWN_DIRECTION"
}

export const AnnotationsConstants = {
    ALL_COLORS: "all_colors",
    ANNO_COLORS: {
        charcoal: '#36454f',
        lawnGreen: '#7CFC00',
        razzmatazz: '#e91e63',
        darkOrchid: '#9c27b0',
        violetBlue: '#3f51b5',
        buttonBlue: '#2196f3',
        hotPink: '#FF69B4',
        ao: '#008000',
        venetianRed: '#FF8C00',
    },
    DEFAULT_ANNO_COLOR: "#36454f",
    LINE: "line",
    POLYGON: "polygon",
    CIRCLE: "circle",
    MAGIC_TOOL: "Magic Tool",
    ROI_TITLE: "Region of Interest",
    ROI_COLOR: morphleBaseTheme1.palette.primary.main,
}

export const DeepBioConstants = {
    ALL_LABELS : "all_labels",
    LABELS: {
        'IDC-P': '#0000FF',
        Pattern3: '#FFFF00',
        Pattern4: '#FFA500',
        Pattern5: '#FF0000',
    },
    LABELS_LIST : ["Pattern3","Pattern4","Pattern5","IDC-P"],
    LABEL_COLORS : ["","","",""]
}

export const DeepBioIcon = (props) =>
    <Icon {...props}>
        <img style={{width: "90px", margin: "0px 0px 0px 0px"}} {...props} src={DeepBIoSVG} alt="DeepBioIcon" />
    </Icon>;


export const IHCConstants = {
    RESULT_OPERATIONS: {
        ADD_POSITIVE: "add_positive",
        ADD_NEGATIVE: "add_negative",
        EDIT: "edit",
        DELETE: "delete",
    }
}

export const IHCIcon = (props) => <Science {...props} />;
