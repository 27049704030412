import React from "react";
import {Paper, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import axios from "axios";
import {AxiosConfig} from "../../../helper/axios.config";
import {notifyError, notifyInfo, notifySuccess} from "./display.notification";
import queryString from "query-string";
import {store} from "../../../helper/store";
import {updateSelectedIHCResult} from "../../../action/maps.state.action";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import {blue, red} from "./annotations_app_utils";
import {IHCConstants} from "../../../utils/const";

export const OPERATIONS = IHCConstants.RESULT_OPERATIONS;

export const prepareStatus = ["Prepare Slide", "In progress", "Completed"];

export const annotationActionTooltip =
	["Prepare Slide for Analysis", "Wait for slide to get prepared", "Run IHC Analysis"];

export const runIHCAnalysis = (annotation) =>
	axios.post(`/api/ihc/run/`, queryString.stringify({annotationId: annotation.id}), AxiosConfig())
		.then(response =>
			notifyInfo(`ihcAnalysis${annotation.id}`, response.data)
		).catch(() => notifyError(`ihcAnalysis${annotation.id}`,
		`Error caused in IHC analysis for ${annotation.title}`));

export const saveIHCCounts = (annotation) =>
	axios.post(`/api/ihc/save/`,
		{
			slideId: annotation.slide,
			annotationId: annotation.id,
			result: annotation.result,
		}, AxiosConfig())
		.then(() =>
			notifySuccess(`ihcSave${annotation.id}`, `IHC counts saved for ${annotation.title}`)
		).catch(() => notifyError(`ihcSave${annotation.id}`,
		`Error caused in IHC counts saving for ${annotation.title}`));

export const getIHCResultComponent = (ihcResult) => {
	if (!ihcResult)
		return null;
	let posCount = ihcResult.pos.length;
	let negCount = ihcResult.neg.length;
	let totalCount = posCount + negCount;
	let posPercent = Math.round((posCount / totalCount) * 100);
	let negPercent = Math.round((negCount / totalCount) * 100);
	return <TableContainer component={Paper}>
		<TableHead>
			<TableRow>
				<TableCell>Positive</TableCell>
				<TableCell>Negative</TableCell>
				<TableCell>Total</TableCell>
			</TableRow>
		</TableHead>
		<TableRow>
			<TableCell>{posCount}</TableCell>
			<TableCell>{negCount}</TableCell>
			<TableCell>{totalCount}</TableCell>
		</TableRow>
		<TableRow>
			<TableCell>{posPercent}%</TableCell>
			<TableCell>{negPercent}%</TableCell>
			<TableCell>100%</TableCell>
		</TableRow>
	</TableContainer>
}

export const getIHCResultFeatures = (annotation) => ([
	...annotation.result.pos.map(polygon =>
		new Feature({
			id: `${annotation.id}pos${Math.random() * 1e9}`,
			geometry: new Polygon([polygon]),
			coordinates: polygon,
			color: blue,
		})),
	...annotation.result.neg.map(polygon =>
		new Feature({
			id: `${annotation.id}neg${Math.random() * 1e9}`,
			geometry: new Polygon([polygon]),
			coordinates: polygon,
			color: red,
		})),
].map(feature => {feature.setId(feature.values_.id); return feature;}))


export const onIHCResultSelect = (mapId, feature) =>
	store.dispatch(updateSelectedIHCResult(mapId, feature));

export const isAddOperation = (operation) => operation &&
	(operation === OPERATIONS.ADD_POSITIVE || operation === OPERATIONS.ADD_NEGATIVE);
