import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Photo from './photo'
import {Collapse, Spin, Card, Row, Col, Divider} from 'antd';
import {urls} from '../../helper/backend.urls'
import {tokens} from '../../helper/backend.tokens'

const { Panel } = Collapse;

export default class ClassifierUI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            images: {},
            currentImage: 0,
            viewerIsOpen: false,
            total_image_count: 0,
            isFetching: true,
        };
    }

    getAllImages = () => {
        let morphle_id = this.props.match.params.id;
        // // ****  Can use these two values whenever required *** 
        //  let spec_type = this.props.match.params.spectype;
        //  let spec_sub_type = this.props.match.params.subtype;
        let url = `${urls['zeus_url']}/api/wbcClassification?morphle_id=${morphle_id}`;
        let image_array_dict = {};
        let headers = {'Content-Type': 'application/json', 'Authorization': "Token "+tokens['zeus_token']}
        axios.get(url, { headers: headers }).then(response => {
            this.state.total_image_count = response.data.length;
            return response.data;
        }).then((results_data) => {
            results_data.map((value, index) => {
                let image = {
                    src: value.image.path,
                    original: value.image.path,
                    thumbnail: value.image.path,
                    caption: value.image.id + "",
                    title: value.image.id,
                    width: 0.5,
                    height: 0.5,
                    key: value.image.id,
                };
                let subType = value.predicted_class.sub_type;
                if(!(subType in image_array_dict)){
                    image_array_dict[subType] = [];
                }
                image_array_dict[subType].push(image);
                return image_array_dict;
            });
            console.log(image_array_dict);
            this.setState({
                images: image_array_dict,
                isFetching: false,
            });
        })
        .catch(err =>{
        });
    }

    componentDidMount = () => {
        this.getAllImages();
    }

    setCurrentImage = (index) => {
        this.setState({
            currentImage: index,
        });
    }

    setViewerIsOpen = (isOpen) => {
        this.setState({
            viewerIsOpen: isOpen,
        });
    }

    openLightbox = (event,  photo, index ) => {
        this.setCurrentImage(index);
        this.setViewerIsOpen(true);
    };

    toggleModal = () => {
        this.setState(state => ({ viewerIsOpen: !state.viewerIsOpen }));
    };

    imageRenderer = ( {index, left, top, key, photo, margin, onClick} ) => {
        let wbcTypes = Object.keys(this.state.images);
          return <Photo
            index={index}
            photo={photo}
            left={left}
            top={top}
            key={key}
            onClick={this.openLightbox}
            wbcTypes={wbcTypes}
            classView={this}
          />
    };

    getComp = (wbcType, height) => {
        let compStyle = {
            position: "relative",
            height: height,
            overflow: "auto",
        };

        let typeLength = this.state.images[wbcType].length;
        let totalLength  = this.state.total_image_count;

        let comp = 
            <Panel header={wbcType + " " + typeLength+"/"+totalLength + " " + (typeLength*100/totalLength).toFixed(2)+"%"} key={wbcType}>
                <div style={compStyle}>
                    <Gallery photos={this.state.images[wbcType]} direction="row" targetRowHeight="100" onClick={this.openLightbox}
                    renderImage={this.imageRenderer}/>
                    <ModalGateway>
                        {this.state.viewerIsOpen ? (
                        <Modal onClose={this.toggleModal}>
                            <Carousel views={this.state.images[wbcType]} />
                        </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </Panel>
        return comp;
    }

    getAllComps = () => {
        let compHeight = "200px";
        let comps = []
        for(var wbcType in this.state.images){
            comps.push(this.getComp(wbcType, compHeight));
        }
        return comps;
    }

    getCardComp = (wbcType, height) => {
        let compStyle = {
            position: "relative",
            height: height,
            overflow: "auto",
        };

        let typeLength = this.state.images[wbcType].length;
        let totalLength  = this.state.total_image_count;

        let comp = 
        <Row>
            <Col span={10}> {wbcType}</Col> 
            <Col span={6} offset={1}> {typeLength+"/"+totalLength} </Col> 
            <Col span={6} offset={1}> {(typeLength*100/totalLength).toFixed(2)+"%"} </Col>
            <Divider/>
        </Row>
        return comp;
    }

    getAllCardComps = () => {
        let compHeight = "200px";
        let comps = []
        for(var wbcType in this.state.images){
            comps.push(this.getCardComp(wbcType, compHeight));
        }
        return comps;
    }
    
    render = () => {
        console.log(this.state.images);
        const classifier_style = {
            "margin-left": "300px",
            "position": "absolute",
        }
        const card_style = {
            "position": "absolute",
            "margin-left": "0px",
            // "margin-top": "400px",
            "width": "300px",
        }
        let collapseComp = this.state.isFetching || Object.keys(this.state.images).length==0 ? <div></div> : 
                    <Collapse defaultActiveKey={Object.keys(this.state.images)}>
                        {this.getAllComps()}
                    </Collapse>
        
        let cardComp = this.state.isFetching || Object.keys(this.state.images).length==0 ? <div></div> : 
            <Card title="WBC Parameters">
                {this.getAllCardComps()}
            </Card>
        
        let errorComp = Object.keys(this.state.images).length==0 ? 
                            <div><h1>Results not uploaded for this slide</h1></div> :
                            <div></div>
        
        errorComp = this.state.isFetching ? <div></div> : errorComp; 

        return(
            <div>
                <Spin tip="Loading..." spinning={this.state.isFetching} delay={500}>
                    <div style={card_style}>
                        {cardComp}
                    </div>
                    <div style={classifier_style}>
                        {collapseComp}
                    </div>
                    {errorComp}
                </Spin>
            </div>
        );

    }
}