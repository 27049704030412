import React, { Component } from 'react';
import { DownloadOutlined, LoadingOutlined, PicCenterOutlined, ScanOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Alert,
    Typography,
    Popover,
    Badge,
    Divider,
    Button,
    message,
    Spin,
    notification,
} from 'antd';
import { connect } from "react-redux";
import axios from "axios";
import "../../asset/style/scanner/scanner.css"
import "../../asset/style/scanner/loader.css"
import SlotComponent from "./slot_component"
import {AuthHeader} from "../../helper/auth.token"
import { setDeviceIdle, setDeviceBusy } from '../../action/device_status.action';
import { initPreviewForScan, fetchingPreview, receivedPreviewStatus, setScanSpeed, setScanSpeedForTray, setSpecimenTypeForTray, setObjectiveTypeForTray, setSpecimenType, setObjectiveType, startScanning, setPreviewStatusToConfirmed } from "../../action/preview_status.action"
import Tray from './tray';
import { ScanApiConstant } from '../../actionTypes/scan.constant';
import { errorCodeMap } from '../../utils/scanner_error_codes'
import LiveView from './live_view'
import { cancelScanning, cancelPreview } from "../../action/preview_status.action"
import { getSpecimenChooser, getObjectiveChooser, getSpeedPanel } from './slot_component_utils'
import { getScanProfileChooser } from './loader_wf_utils'

const { Text, Link } = Typography;

class LoaderWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cassetteSize: 0,
            isObjectiveSwitcherPresent: false,
            activeSlot: -1,
            activeTray: -1,
            activeRack: -1,
            busy:false, 
            showSummary: false,
            visibleProfileSelector: false
        }
        this.powerStatusDict = {
            DISCONNECTED : "DISCONNECTED",
            ONBATT: "ONBATT"
        }
        this.systemStateDict = {
            CRITICAL : "CRITICAL"
        }
    }

    getScannerProperties = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Setting Up"));
        const url = "/server/devices/" + this.props.id + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(res => {
            this.setState(Object.assign({}, this.state, {
                rackSize: res.data.rackSize,
                numberOfRacks: res.data.numberOfRacks,
                cassetteSize: res.data.cassetteSize,
                isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
                allowScanOutputFormatChange: res.data.allowScanOutputFormatChange, 
                allowObjectiveChange: res.data.allowObjectiveChange,
                defaultConfig: res.data.defaultConfig
            }), () => {
                this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                    this.props.dispatch(initPreviewForScan([slot, tray, rack], this.state.defaultConfig));
                    let previewResponse = loaderState.racks[rack].trays[tray].slotList[slot].previewResponse;
                    if (previewResponse != undefined) {
                        let regions = this.getFullSelectedRegions(previewResponse);
                        const slotKey = [previewResponse.slot, previewResponse.tray, previewResponse.rack];
                        this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, previewResponse, slotKey))
                        this.props.dispatch(setPreviewStatusToConfirmed(slotKey, regions[0], regions[1]));
                    }
                });
                this.getScanningProfiles();    
            });
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }

    getScanningProfiles = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Loading Scanning Profiles"));
        const url =`/api/scanningprofile/`;
        axios.get(url , {headers: {Authorization : AuthHeader()}})
        .then(res => {
            for(let i=0;i<res.data.length;i++){
                if(res.data[i].default){
                    console.log("setting default profile", res.data[i]);
                    this.setScanProfile('speed', res.data[i].speed);
                    this.setScanProfile('specimen', res.data[i].specimenType);
                    this.setScanProfile('objective', res.data[i].objectiveType);
                }
            }
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }

    loadNextTray = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Loading Tray"));
        const url = "/server/devices/" + this.props.id + "/loader/load_next/";
        axios
        .get(url)
        .then(res => {
            this.props.dispatch(setDeviceIdle(this.props.id));
            if (res.status != 200 && res.status in errorCodeMap) {
                message.warn(errorCodeMap[res.status].message);
            }
        })
        .catch(err => {
            console.log(err);   
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }
    
    onCancelScanning = () => {
        this.props.dispatch(cancelScanning(this.props.id, true));
    }

    takeAllPreviews = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Taking Previews"));
        const url = "/server/devices/" + this.props.id + "/loader/take_all_previews/";

        this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
            if (loaderState.racks[rack].trays[tray].loaded) {
                this.props.dispatch(fetchingPreview([slot, tray, rack]));
            }
        })
        axios
        .get(url)
        .then(response => {
            this.processPreviewResponses(response);
        })
        .catch(err => {
            this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                if (loaderState.racks[rack].trays[tray].loaded) {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, [slot, tray, rack]));
                }
            })
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }

    cancelPreviews = () => {
        this.props.dispatch(cancelPreview(this.props.id, true));
    }

    takeTrayPreviews = (trayNumber, rackNumber) => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Taking Previews"));
        const url = "/server/devices/" + this.props.id + "/loader/take_tray_previews/?tray=" + trayNumber + '&rack=' + rackNumber;

        this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
            if (tray == trayNumber && rack == rackNumber) {
                this.props.dispatch(fetchingPreview([slot, tray, rack]));
            }
        })
        axios
        .get(url)
        .then(response => {
            this.processPreviewResponses(response);
        })
        .catch(err => {
            this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                if (tray == trayNumber && rack == rackNumber) {
                    this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, err, [slot, tray, rack]));
                }
            })
            this.props.dispatch(setDeviceIdle(this.props.id));
        });
    }

    applyFunctionToAllSlots = (functionToApply) => {
        let loaderState = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).loaderState || {});
        for (let i = 0; i < this.state.numberOfRacks; i++) {
            for (let j = 0; j < this.state.rackSize; j++) {
                for (let k = 0; k < this.state.cassetteSize; k++) {
                    if(k != undefined && j != undefined && i != undefined)
                        functionToApply(k, j, i, loaderState);
                }
            }
        }
    }

    applyFunctionToAllTrays = (functionToApply) => {
        let loaderState = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).loaderState || {});
        for (let i = 0; i < this.state.numberOfRacks; i++) {
            for (let j = 0; j < this.state.rackSize; j++) {
                if(j != undefined && i != undefined)
                    functionToApply(j, i, loaderState);
            }
        }
    }

    getFullSelectedRegions = (previewResponse) => {
        let reg = {
            data: { index: 0 },
            height: 100,
            isChanging: false,
            new: false,
            width: 100,
            x: 0,
            y: 0,
        }
        let x1 = parseInt((reg.x * previewResponse.previewWidth) / 100.0);
        let x2 = parseInt(((reg.x + reg.width) * previewResponse.previewWidth) / 100.0);
        let y1 = parseInt((reg.y * previewResponse.previewHeight) / 100.0);
        let y2 = parseInt(((reg.y + reg.height) * previewResponse.previewHeight) / 100.0);
        let actualReg = [x1, y1, x2, y2];
        return [reg, actualReg];
    }

    processPreviewResponses(response) {
        console.log(response);
        if (response.status === 200) {
            for (let i in response.data) {
                let previewResponse = response.data[i];
                let regions = this.getFullSelectedRegions(previewResponse);
                const slotKey = [previewResponse.slot, previewResponse.tray, previewResponse.rack];
                this.props.dispatch(receivedPreviewStatus(ScanApiConstant.TAKEN_PREVIEW, previewResponse, slotKey));
                this.props.dispatch(setPreviewStatusToConfirmed(slotKey, regions[0], regions[1]));
            }
            this.props.dispatch(setDeviceIdle(this.props.id));
        }
        else {
            for (let i in response.data) {
                let previewResponse = response.data[i];
                this.props.dispatch(receivedPreviewStatus(ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW, previewResponse, [previewResponse.slot, previewResponse.tray, previewResponse.rack]));
            }
            this.props.dispatch(setDeviceIdle(this.props.id));
        }
    }

    componentDidMount() {
        this.getScannerProperties();
    }

    startScanning = () => {
        this.props.dispatch(setDeviceBusy(this.props.id, "Initialising"));
        let url = '/server/devices/' + this.props.id + '/loader/start_scan/';
        axios({
            method:"post",
            url : url, 
            data: {
                previewData: this.props.previews
            }
        })
        .then(response => {
            if (response.status === 200) {
                message.success("Started Scans");
                this.props.dispatch(setDeviceIdle(this.props.id));
            }
            else {
                message.error("Could not start scans. Please contact administrator.")
                this.props.dispatch(setDeviceIdle(this.props.id));
            }
        })
        .catch(err => {
            message.error("Could not start scans. Please contact administrator.")
            this.props.dispatch(setDeviceIdle(this.props.id));
        })
    }

    activateSlot = (slot, tray, rack) => {
        this.setState({
            activeSlot: slot,
            activeTray: tray,
            activeRack: rack
        })
        let e = document.getElementById("#" + rack + "-" + tray + "-" + slot);
        if (e != null) e.scrollIntoView();
    }

    hideScanProfileSelector = () => {
        this.setState({
          visibleProfileSelector: false,
        });
    };
    
    handleVisibleChange = visibleProfileSelector => {
        this.setState({ visibleProfileSelector });
    };

    setScanProfile = (type, value) => {
        switch(type){
            case 'speed':
                this.props.dispatch(setScanSpeedForTray([-1], value));
                this.applyFunctionToAllTrays((tray, rack, loaderState) => {
                    console.log('scan speed set ', tray, rack)
                    this.props.dispatch(setScanSpeedForTray([tray, rack], value));
                });
                console.log('setting scan speed for all slots');
                this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                    this.props.dispatch(setScanSpeed([slot, tray, rack], value));
                })
                return
            case 'objective':
                this.props.dispatch(setObjectiveTypeForTray([-1], value));
                this.applyFunctionToAllTrays((tray, rack, loaderState) => {
                    this.props.dispatch(setObjectiveTypeForTray([tray, rack], value));
                });
                this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                    this.props.dispatch(setObjectiveType([slot, tray, rack], value));
                });
                return
            case 'specimen':
                this.props.dispatch(setSpecimenTypeForTray([-1], value));
                this.applyFunctionToAllTrays((tray, rack, loaderState) => {
                    this.props.dispatch(setSpecimenTypeForTray([tray, rack], value));
                });
                this.applyFunctionToAllSlots((slot, tray, rack, loaderState) => {
                    this.props.dispatch(setSpecimenType([slot, tray, rack], value));
                })
                return
        }
    }

    onScanSpeedChange = (event) => {this.setScanProfile('speed', event.target.value);};

    onScanSpecimenChange = (event) => {this.setScanProfile('specimen', event.target.value);};

    onScanObjectiveChange = (event) => {this.setScanProfile('objective', event.target.value);};

    openNotification = (key, busyMessage) => {
        notification.open({
            key,
            message: busyMessage,
            icon:  <LoadingOutlined style={{color:"#FF0000"}} />,
            duration: 0
        });
    };

    closeNotification = (key) => {
        notification.close(key);
    };

    render() {

        let cassetteSize = this.state.cassetteSize;
        let scannerStatus = ((this.props.device || {}).scanner_health || {}).scanner_response;
        let upsPowerStatus = ((scannerStatus || {}).upsPowerStatus || {});
        let systemState = ((scannerStatus || {}).systemState || {});
        let isPowerFailure = (upsPowerStatus.powerStatus == this.powerStatusDict.ONBATT || 
                              upsPowerStatus.powerStatus == this.powerStatusDict.DISCONNECTED);
        let isCriticalDisk = systemState.ssdStatus == this.systemStateDict.CRITICAL || systemState.hddStatus == this.systemStateDict.CRITICAL;
        let loaderState = ((scannerStatus || {}).loaderState || {});
        let workflowComp = [];
        let regionSelectorDiv = [];
        let loaderComp = [];

        let atleastOneSlotLoaded = false;
        let atleastOnePreviewTaken = false;
        let numFreeSlotsLeft = this.state.numberOfRacks * this.state.rackSize;

        let rackLength = 24 / (loaderState.racks || {length: 1}).length;

        let busyMessage = null;
        if (scannerStatus.busy) {
            let busyOrder = scannerStatus.busyOrder;
            let busyDict = scannerStatus.busyDict;
            busyMessage = busyOrder.length > 0 ? busyDict[busyOrder[busyOrder.length - 1]] : "";
            this.openNotification("notifier", busyMessage);
        }
        else{
            this.closeNotification("notifier");
        }

        let scanProfileChooser = getScanProfileChooser(this.props.previews.scanProfiles[-1], this.onScanSpeedChange, this.onScanSpecimenChange, 
            this.onScanObjectiveChange, this.hideScanProfileSelector, this.state.visibleProfileSelector, this.handleVisibleChange,
            scannerStatus.scanning || isPowerFailure || scannerStatus.busy );

        let racksComp = [];
        for(let i = 0; i < this.state.numberOfRacks; i++) {
            let rackComp = [];
            for (let j = 0; j < this.state.rackSize; j++) {
                let tray = loaderState.racks[i].trays[j];
                rackComp.push(
                    <Tray
                        id={this.props.id}
                        key={tray.trayNumber}
                        tray={tray} 
                        cassetteSize={cassetteSize} 
                        rackNumber={i}
                        activateSlot={this.activateSlot}
                        activeSlot={this.state.activeSlot}
                        defaultConfig={this.state.defaultConfig}
                        takeTrayPreviews={this.takeTrayPreviews}
                        allowInteraction={!scannerStatus.scanning}
                        cassetteStats = {scannerStatus.scanning && tray.onStage ? scannerStatus.currentCassetteStats : undefined}
                        trayIsActive={tray.trayNumber == this.state.activeTray && i == this.state.activeRack}
                        isPowerFailure={isPowerFailure}
                        scannerStatus={scannerStatus}/>)
                if (tray.loaded || tray.onStage) {
                    atleastOneSlotLoaded = true;
                    numFreeSlotsLeft --;
                }
            }

            racksComp.push(
                <Col span={rackLength} className="tray-panel" key={i}>
                    <div className="rounded-container tray-panel-inside-div">
                        <Row className="rack-header"><b>Rack {i + 1}</b></Row>
                        <Row>{rackComp}</Row>
                    </div>
                </Col>
            )
        }

        loaderComp.push(
            <Col span={12} className="tray-panel" key={1000}>
                <Spin spinning={scannerStatus.takingPreviews || 
                    (scannerStatus.busy && !scannerStatus.scanning) || scannerStatus.terminating} tip={busyMessage} size="small">
                    {racksComp}
                </Spin>
            </Col>
        );

        let sideBar, scanInteractionButton;

        if (!scannerStatus.scanning) {
            for(let i = 0; i < this.state.numberOfRacks; i++) {
                let rackRegionSelector = [];
                for (let j = 0; j < this.state.rackSize; j++) {
                    let trayRegionSelector = [];
                    let tray = loaderState.racks[i].trays[j];
                    for (let k = 0; k < cassetteSize; k++) {
                        if ((this.props.previews[[k, j, i]] || {}).response != undefined) {
                            if (!tray.finished) {
                                atleastOnePreviewTaken = true;
                            } 
                            trayRegionSelector.push (
                                <div id={"#" + i + "-" + j + "-" + k} key={k} className="loader-region-selector-parent">
                                <Row style={{textAlign: "center"}}>Slide ID: {this.props.previews[[k, j, i]].slideName} (Rack {i + 1} - Tray {j + 1} - Slot {k + 1})
                                {this.props.previews[[k, j, i]].response.barcodeReadSuccess ? undefined : 
                                 <Col style={{color: 'red'}}>Could not read barcode. Please update manually.</Col>}
                                 </Row>
                                <Divider />
                                <SlotComponent id={[k, j, i]} 
                                                device_id={this.props.id} 
                                                showSummary={false}
                                                isObjectiveSwitcherPresent={this.state.isObjectiveSwitcherPresent} 
                                                allowObjectiveChange={this.state.allowObjectiveChange}
                                                cassetteSize={cassetteSize} 
                                                startScanning={this.onStartScanning} />
                                </div>
                            );
                        }
                    }
                    rackRegionSelector.push(trayRegionSelector);
                }
                regionSelectorDiv.push(rackRegionSelector);
            }

            let helpMessage = "Please Click on Load Next Tray to begin.";
    
            if (atleastOneSlotLoaded) {
                helpMessage = <p>Click <b>Start Scanning</b> to being scanning or <b>Take Previews</b> to select regions to scan</p>
            }
    
            sideBar = atleastOnePreviewTaken ? 
                <Row className="rounded-container loader-active-parent">
                    <Row className="active-slot-header">
                        Slide Information
                    </Row>
                    <Row>
                        <Divider />
                    </Row>
                    <Row className="loader-active-slot-child scrollbar">
                        {regionSelectorDiv}
                    </Row>
                </Row>
                : <Row className="loader-help-message">{helpMessage}</Row>
            
            scanInteractionButton = <Button disabled={!atleastOneSlotLoaded || isPowerFailure || scannerStatus.busy || isCriticalDisk || scannerStatus.takingPreviews} type="danger" className={"loader-action-button loader-action-scan"} icon={<ScanOutlined />} onClick={this.startScanning}>Start Scanning</Button>
        } else {
            sideBar = <LiveView id={this.props.id} />;
            scanInteractionButton = <Button disabled={!scannerStatus.cancellable} type="danger" className={"loader-action-button loader-action-scan"} icon={<ScanOutlined />} onClick={this.onCancelScanning}>Stop Scanning</Button>
        }
        
        loaderComp.push(
            <Col span={12} className="selected-scan-info-panel" key={loaderState.racks.length + 1} key='loader-info-panel'>
                <Row className="rounded-container loader-action-panel">
                    <Button.Group>
                        {scanProfileChooser}
                        <Button disabled={numFreeSlotsLeft == 0 || scannerStatus.scanning || isPowerFailure || scannerStatus.busy} 
                            className={"loader-action-button loader-action-load"} icon={<DownloadOutlined />} onClick={this.loadNextTray}>Load Next Tray</Button>
                        
                        {scannerStatus.takingPreviews ? <Button type="danger" className={"loader-action-button loader-action-preview"} icon={<PicCenterOutlined />} onClick={this.cancelPreviews}>Cancel Preview</Button> :
                        <Button disabled={!atleastOneSlotLoaded || scannerStatus.scanning || isPowerFailure || scannerStatus.busy}
                        className={"loader-action-button loader-action-preview"} icon={<PicCenterOutlined />} onClick={this.takeAllPreviews}>Take All Previews</Button>}
                        {scanInteractionButton}
                    </Button.Group>
                </Row>
                {sideBar}
            </Col>
        )

        workflowComp.push(
            <Row className="loader-container" key={0}>
                {loaderComp}
            </Row>
        );

        return workflowComp;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        previews : state.previewStatusReducer,
        device: state.deviceStatusReducer[ownProps.id]
    };
};

export default connect(mapStateToProps)(LoaderWorkflow);
