import React, { Component } from 'react';
import HeaderComp from '../component/header'
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { message, Col } from "antd";
import CaseComp from '../component/case/caseComp';
import { Spin, Divider, Table, Input, Button, Row, Tooltip, Radio, Card, Empty, Pagination, Tag, Select } from 'antd';
import { Link } from "react-router-dom";
import { globalUrlPrefix } from '../utils/const';
import { SearchOutlined, DoubleLeftOutlined, EyeOutlined, EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import AllScansCaseView from './scansCase';
import { updateUrlStateInReducer, updateCaseSearch, changePage } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert } from '../action/cases.action'
import { connect } from "react-redux";
import { closeDrawer } from "../action/dashboard.action";
import { CaseView } from '../view/caseview';
import { makeCaseRead } from '../action/cases.action';
import AllScansCliaCaseView from './caseClia';
import cookie from "react-cookies";
import {Alert, Result } from 'antd';

const { Search } = Input;
const ButtonGroup = Button.Group;
const Option = Select.Option;


const pageSize = 12;

class SingleCaseView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            caseMode: 'viewMode',
            selectedCaseId: this.props.match.params.id,
            showTabs: false,
            showClia: false,
        }
    }

    showClia = () => {
        if(window.location.href.split('/')[4] == "clia_case"){
            this.setState({showClia:true})
        }
    }

    componentWillMount = () => {
        this.props.dispatch(makeCaseRead(this.props.match.params.id));
        this.showClia();
    }

    isValidUUID = (uuid) => {
        let token = ""+uuid
        token=token.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        if (token === null) {
          return false;
        }
        return true;
      }

    getCaseView = () => {
        if(this.state.showClia == true) {
            if(this.isValidUUID(cookie.loadAll().screen_supported) || cookie.loadAll().clia_screen_check == "false"){
                return (
                    <div>
                        <AllScansCliaCaseView case={this.state.selectedCaseId} setTabsView={this.changeTabsView} />
                    </div>
                )
            }
            else{
                return (
                    <div>
                        <Result
                            title="Your Screen Isnt Supported"
                            extra={
                                <Button type="primary" key="console" onClick={this.openDashboard}>
                                Go To Dashboard
                                </Button>
                            }
                            />
                    </div>
                )
            }
         
        }
        else if (this.state.caseMode === 'viewMode') {
            return (
                <div>
                    <AllScansCaseView case={this.state.selectedCaseId} setTabsView={this.changeTabsView} />
                </div>

            );
        } else {
            return (
                <div>
                    <CaseView case={this.state.selectedCaseId} />
                </div>
            );
        }
    }

    changeTabsView = (value) => {
        this.setState({
            showTabs: value,
        });
    }

    onChangeMode = (e) => {
        this.setState({
            caseMode: e.target.value,
        });
    }

    render() {
        console.log("cookie",cookie.loadAll().screen_supported)
        return (
            <div>
                <Row>
                    <Col span={24} offset={0} className="all-slides">
                        {this.state.selectedCaseId ?
                            <div>
                                {/* {this.state.showTabs ?
                                        <Row justify="center">
                                            <Col span={14} offset={7}>
                                                <Radio.Group value={this.state.caseMode} buttonStyle="solid" onChange={this.onChangeMode}>
                                                    <Radio.Button value="viewMode">View the Selected Case</Radio.Button>
                                                    <Radio.Button value="editMode">Edit the Selected Case</Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                        </Row> : null
                                    } */}
                                <Row>
                                    {this.getCaseView()}
                                </Row>
                            </div>
                            :
                            <Empty description="No Case to Display" />
                        }
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loginStatus: state.loginAlerts.loginStatus,
        allCases: state.casesReducer,
        allCaseStatus: state.caseStatusReducer.case_status,
        allUsers: state.caseStatusReducer.users,
        numPages: state.caseStatusReducer.numPages,
        pageNumber: state.caseStatusReducer.pageNumber,
        urlState: state.searchCaseReducer,
        isFetching: state.searchCaseReducer.isFetching
    };
};

export default connect(mapStateToProps)(SingleCaseView);