import {DeepBioActions} from "../actionTypes/deepbio.constant";

const InitialState = {
    ai_results_displayed : [],
    active_filter: "ALL",
}

export const deepBioReducer = (state = InitialState, action) => {
    switch (action.type){
        case DeepBioActions.UPDATE_AI_RESULTS_DISPLAYED:
            return{
                ...state,
                ai_results_displayed: action.response,
            }

        case DeepBioActions.UPDATE_FILTER_COLOR:
            return{
                ...state,
                active_filter: action.response,
            }
        default :
            return state;
    }
}
