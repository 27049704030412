import { PreviewTuningConstant } from '../actionTypes/preview.tuning.constant';

export const updateSelectedPoint = (point) => ({
    type: PreviewTuningConstant.UPDATE_SELECTED_POINT,
    point: point, 
})

export const updatePixelValues = (pixelValue) => ({
    type: PreviewTuningConstant.UPDATE_PIXEL_VALUES,
    pixelValue: pixelValue, 
})

export const updatePositionValues = (positionValue) => ({
    type: PreviewTuningConstant.UPDATE_POSITION_VALUES,
    positionValue: positionValue, 
})

export const updateSlotsCroppingMode = (mode) => ({
    type: PreviewTuningConstant.UPDATE_SLOTS_CROPPING_MODE,
    slotsCroppingMode: mode, 
})

export const updateSelectedSlot = (slot) => ({
    type: PreviewTuningConstant.UPDATE_SELECTED_SLOT,
    slot: slot, 
})

export const updateSlotCropPositionValue = (value) => ({
    type: PreviewTuningConstant.UPDATE_SLOT_CROP_POSITION_VALUES,
    slotCropPositionValue: value, 
})

