import {onSelectAnnotationOnMap} from "./annotations_app_utils";
import {
    retrieveAnnotations,
    updateAnnotationVector,
    updateDeepBioResultVector,
    updateDeepBioVector,
    updateIHCResultVector,
    updateIHCVector,
    updateLabellingVector
} from "../../../action/maps.state.action";
import {
    circleDrawingKey,
    freehandDrawingKey,
    freelineDrawingKey,
    lineDrawingKey,
    magicToolDrawingKey,
    polygonDrawingKey,
    rectangleDrawingKey
} from "../drawer/draw_tool_keys";
import {onIHCResultSelect} from "./ihc_app_utils";


export const annotationLayer = {
    id: "annotations",
    name: "Annotations Layer",
    drawer: 0,
    defaultPermission: true,
    store: updateAnnotationVector,
    loadDataOnRender: true,
    dataLoad: retrieveAnnotations,
    selectInteraction: true,
    tools: [lineDrawingKey, freelineDrawingKey, circleDrawingKey, freehandDrawingKey, rectangleDrawingKey,
        polygonDrawingKey, magicToolDrawingKey],
    onFeatureSelect: function (mapId, feature) {
        onSelectAnnotationOnMap(mapId, this.id, feature.getId())
    },
}

export const deepBioLayer = {
    id: "deep_bio",
    name: "DeepBio Layer",
    drawer: 1,
    defaultPermission: true,
    store: updateDeepBioVector,
    loadDataOnRender: true,
    dataLoad: retrieveAnnotations,
    selectInteraction: false,
    tools: [circleDrawingKey, rectangleDrawingKey, polygonDrawingKey],
    onFeatureSelect: function (mapId, feature) {
        onSelectAnnotationOnMap(mapId, this.id, feature.getId())
    },
}

export const deepBioResultLayer = {
    id: "deep_bio",
    name: "DeepBio Analysis",
    defaultPermission: true,
    store: updateDeepBioResultVector,
    loadDataOnRender: false,
    selectInteraction: false,
};

export const ihcLayer = {
    id: "ihc",
    name: "IHC Layer",
    drawer: 2,
    defaultPermission: true,
    store: updateIHCVector,
    loadDataOnRender: true,
    dataLoad: retrieveAnnotations,
    selectInteraction: false,
    tools: [circleDrawingKey, rectangleDrawingKey, polygonDrawingKey],
    onFeatureSelect: function (mapId, feature) {
        onSelectAnnotationOnMap(mapId, this.id, feature.getId())
    },
}

export const ihcResultLayer = {
    id: "ihc",
    name: "IHC Analysis",
    defaultPermission: true,
    store: updateIHCResultVector,
    loadDataOnRender: false,
    onFeatureSelect: onIHCResultSelect,
    onFeatureHover: () => undefined,
}

export const labellingLayer = {
    id: "labelling",
    name: "Labelling Layer",
    drawer: 3,
    defaultPermission: false,
    store: updateLabellingVector,
    loadDataOnRender: true,
    dataLoad: retrieveAnnotations,
    selectInteraction: false,
    tools: [freehandDrawingKey, rectangleDrawingKey],
    roiTool: rectangleDrawingKey,
    onFeatureSelect: function (mapId, feature) {
        onSelectAnnotationOnMap(mapId, this.id, feature.getId())
    },
}

export const getAllLayers = () => {
    return [
        annotationLayer,
        deepBioLayer,
        deepBioResultLayer,
        ihcLayer,
        ihcResultLayer,
        labellingLayer,
    ];
}
