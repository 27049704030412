import React, {Component} from  "react";
import { connect } from "react-redux";
import {updateAppPosition, updateAppClickTime} from '../../action/morpheus.state.action';
import {updateAppPosition as updateTileViewerAppPosition, updateAppClickTime as updateTileViewerAppClickTime} from '../../action/tile.viewer.state.action';
import { updateAppPosition as updateAppPositionAdminState, updateAppClickTime as updateAppClickTimeAdminState } from '../../action/admin.state.action';
import { draggableType, slideViewerType } from '../../utils/const';

import "../../asset/style/utils/draggable.css"

class Draggable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            moved: false
        }

        this.oldPosX = 0;
        this.oldPosY = 0;
        this.newPosX = 0;
        this.newPosY = 0;

        this.startTop = this.props.startTop >= 100 ? 20 : this.props.startTop;
        this.startLeft = this.props.startLeft >= 100 ? 20 : this.props.startLeft;

        this.finalPosX = this.startLeft;
        this.finalPosY = this.startTop;
        if(this.props.type == draggableType.SLIDE_VIEWER) {
            if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
                this.props.dispatch(updateAppPosition(this.props.id, {
                    x: this.finalPosX,
                    y: this.finalPosY
                }, this.props.appState, this.props.urlState));
            } else {
                this.props.dispatch(updateTileViewerAppPosition(this.props.id, {
                    x: this.finalPosX,
                    y: this.finalPosY
                }, this.props.tileViewerAppState, this.props.tileViewerUrlState));
            }
        } else {
            this.props.dispatch(updateAppPositionAdminState(this.props.id, {
                x: this.finalPosX,
                y: this.finalPosY
            }, this.props.appStateAdminState, this.props.urlStateAdminState))
        }
    }

    onMouseDown = (e) => {
        e = e || window.event;
        e.preventDefault();
        
        this.newPosX = e.clientX;
        this.newPosY = e.clientY;
        document.onmouseup = this.closeDragElement;
        document.onmousemove = this.elementDrag;
        this.setState({
            moved: true
        })
    }

    onTouchStart = (e) => {
        e = e || window.event;
        e.preventDefault();
        
        this.newPosX = e.touches[0].pageX;
        this.newPosY = e.touches[0].pageY;
        document.ontouchend = this.closeDragElement;
        document.ontouchmove = this.elementTouchDrag;
        this.setState({
            moved: true
        })
    }

    elementTouchDrag = (e) => {
        e = e || window.event;
        e.preventDefault();
        
        this.oldPosX = this.newPosX - e.touches[0].pageX;
        this.oldPosY = this.newPosY - e.touches[0].pageY;
        this.newPosX = e.touches[0].pageX;
        this.newPosY = e.touches[0].pageY;

        let clientHeight = document.documentElement.scrollHeight;
        let clientWidth = document.documentElement.clientWidth;
        
        this.finalPosY = (this.currentElement.offsetTop - this.oldPosY) * 100 / clientHeight;
        this.finalPosX = (this.currentElement.offsetLeft - this.oldPosX) * 100 / clientWidth;
        
        let maxAllowedY = (clientHeight - this.currentElement.clientHeight) * 100 / clientHeight;
        let maxAllowedX = (clientWidth - this.currentElement.clientWidth) * 100 / clientWidth;

        this.finalPosX = Math.min(maxAllowedX, this.finalPosX);
        this.finalPosY = Math.min(maxAllowedY, this.finalPosY);
        this.finalPosX = Math.max(0, this.finalPosX);
        this.finalPosY = Math.max(0, this.finalPosY);

        this.currentElement.style.top = (this.finalPosY) + "%";
        this.currentElement.style.left = (this.finalPosX) + "%";
    }
    
    elementDrag = (e) => {
        e = e || window.event;
        e.preventDefault();
        
        this.oldPosX = this.newPosX - e.clientX;
        this.oldPosY = this.newPosY - e.clientY;
        this.newPosX = e.clientX;
        this.newPosY = e.clientY;

        let clientHeight = document.documentElement.clientHeight;
        let clientWidth = document.documentElement.clientWidth;

        this.finalPosY = (this.currentElement.offsetTop - this.oldPosY) * 100 / clientHeight;
        this.finalPosX = (this.currentElement.offsetLeft - this.oldPosX) * 100 / clientWidth;
        
        let maxAllowedY = (clientHeight - this.currentElement.clientHeight) * 100 / clientHeight;
        let maxAllowedX = (clientWidth - this.currentElement.clientWidth) * 100 / clientWidth;

        this.finalPosX = Math.min(maxAllowedX, this.finalPosX);
        this.finalPosY = Math.min(maxAllowedY, this.finalPosY);
        this.finalPosX = Math.max(0, this.finalPosX);
        this.finalPosY = Math.max(0, this.finalPosY);

        this.currentElement.style.top = (this.finalPosY) + "%";
        this.currentElement.style.left = (this.finalPosX) + "%";
    }
    
    closeDragElement = () => {
        if(this.props.type == draggableType.SLIDE_VIEWER) {
            if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
                this.props.dispatch(updateAppPosition(this.props.id, {
                    x: this.finalPosX,
                    y: this.finalPosY
                }, this.props.appState, this.props.urlState));
            } else {
                this.props.dispatch(updateTileViewerAppPosition(this.props.id, {
                    x: this.finalPosX,
                    y: this.finalPosY
                }, this.props.tileViewerAppState, this.props.tileViewerUrlState));
            }
        } else {
            this.props.dispatch(updateAppPositionAdminState(this.props.id, {
                x: this.finalPosX,
                y: this.finalPosY
            }, this.props.appStateAdminState, this.props.urlStateAdminState))
        }
        document.onmouseup = null;
        document.ontouchend = null;
        document.onmousemove = null;
        document.ontouchmove = null;
    }

    componentDidMount = () => {
        this.currentElement = document.getElementById(this.props.id);
    }

    componentDidUpdate = (prevProps) => {
        if((prevProps.startTop != this.props.startTop || prevProps.startLeft != this.props.startLeft) && 
            ((this.props.type == draggableType.SLIDE_VIEWER && (this.props.urlState || {}).presentCode != undefined) ||
            this.props.type == draggableType.MANUAL_MODE)) {
            this.finalPosX = this.props.startTop;
            this.finalPosY = this.props.startLeft;
            this.currentElement.style.top = (this.finalPosX) + "%";
            this.currentElement.style.left = (this.finalPosY) + "%";
        }
    }

    componentWillUnmount = () => {
        this.oldPosX = 0;
        this.oldPosY = 0;
        this.newPosX = 0;
        this.newPosY = 0;
        this.setState({
            moved: false
        })
    }

    render(){
        let calculatedZIndex;
        try {
            if(this.props.type == draggableType.SLIDE_VIEWER) {
                calculatedZIndex = (this.props.appState[this.props.id] || {}).zIndex;
            } else {
                calculatedZIndex = (this.props.appStateAdminState[this.props.id] || {}).zIndex;
            }
        } catch (err) {
            calculatedZIndex = 9;
        }

        return (
            <div 
                onMouseDown={() => this.props.type == draggableType.SLIDE_VIEWER ? 
                    this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ?
                        this.props.dispatch(updateAppClickTime(this.props.id, this.props.urlState)) : 
                        this.props.dispatch(updateTileViewerAppClickTime(this.props.id, this.props.tileViewerUrlState)) :
                    this.props.dispatch(updateAppClickTimeAdminState(this.props.id, this.props.urlStateAdminState))}
                id={this.props.id}
                style={{top: this.startTop + "%", left: this.startLeft + "%", zIndex: calculatedZIndex}} 
                className={(this.props.type == draggableType.SLIDE_VIEWER && this.props.urlState || {}).presentCode != undefined ? 
                    "no-pointer-activity draggable" : "draggable"}>
                <div 
                    className="draggable-handle" 
                    onMouseDown={this.onMouseDown}
                    onTouchStart={this.onTouchStart}>
                </div>
                {this.props.content}
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    appState: state.viewerUrlReducer.app_state,
    urlState: state.viewerUrlReducer,
    tileViewerAppState: state.tileViewerUrlReducer.tile_viewer_app_state,
    tileViewerUrlState: state.tileViewerUrlReducer,
    appStateAdminState: state.adminUrlReducer.app_state,
    urlStateAdminState: state.adminUrlReducer
  };
};

export default connect(mapStateToProps)(Draggable);
