import { DeviceAlertConstant as DeviceConstants } from '../actionTypes/device.constant';
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token"
import { delay, call} from 'redux-saga/effects'
import { updateMainLight, updateUseFourXForPreview, updateUseStreakFocus } from "./admin.state.action";
import cookie from "react-cookies";

function* pollStatus(dispatch, id) {
    while (cookie.load('token')) {
        try {
            yield call(() => dispatch(getDeviceStatus(id)));
            yield delay(1000);
        } catch (err) {
            console.log(err);
        }
    }
}

export const watchPollStatus = function* (dispatch, id) {
    while (cookie.load('token')) {
        yield call(pollStatus, dispatch, id);
    }
}

export const setDeviceBusy = (id, message) => ({
    type: DeviceConstants.SET_DEVICE_UI_BUSY,
    id: id, 
    busyMessage: message === undefined ? "..." : message,
})

export const setDeviceIdle = (id) => ({
    type: DeviceConstants.SET_DEVICE_UI_IDLE,
    id: id
})

export const fetchDeviceStatus = (id) => ({
    type: DeviceConstants.FETCHING_DEVICE_STATUS,
    id: id
})

export const receivedDeviceStatus = (type, response, id) => ({
    type: type,
    response: response,
    id: id
})

export const getDeviceStatus = (id) => (dispatch) => {
    let url = `/server/devices/` + id + '/poll_scanner_status/';
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch(receivedDeviceStatus(DeviceConstants.RECEIVED_DEVICE_STATUS, response, id));
                // dispatch(updateMainLight(response.data.scanner_response.mainLightOn));
                // dispatch(updateUseFourXForPreview(response.data.scanner_response.useFourXForPreview));
                // dispatch(updateUseStreakFocus(response.data.scanner_response.useStreakFocusForLiveMode));
            }
            else {
                dispatch(receivedDeviceStatus(DeviceConstants.FAILED_TO_RECEIVE_DEVICE_STATUS, response, id))
            }
        })
        .catch(err => {
            dispatch(receivedDeviceStatus(DeviceConstants.FAILED_TO_RECEIVE_DEVICE_STATUS, err, id))
            console.log(err)
        })
}

