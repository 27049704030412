import React, {Component} from  "react";
import { connect } from "react-redux";
import Draggable from '../utils/draggable'
import { CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { updateAppInstalledStatus, updateAppClosedStatus } from '../../action/morpheus.state.action'
import { updateAppClosedStatus as updateTileViewerAppClosedStatus } from '../../action/tile.viewer.state.action'
import * as keys from '../neoviewer/apps/app_keys'
import { draggableType, slideViewerType } from '../../utils/const';

import "../../asset/style/utils/app.css"

class App extends Component {
    constructor(props) {
        super(props);

        this.props.dispatch(updateAppInstalledStatus(this.props.appKey.id, this.props.app_installed, this.props.urlState));

        let closed = this.props.defaultClosed === undefined ? true : this.props.defaultClosed;
        if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
            this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, closed, this.props.urlState));
        } else {
            this.props.dispatch(updateTileViewerAppClosedStatus(this.props.appKey.id, closed, this.props.tileViewerUrlState));
        }
    }

    closeApp = () => {
        if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
            this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, true, this.props.urlState));
        } else {
            this.props.dispatch(updateTileViewerAppClosedStatus(this.props.appKey.id, true, this.props.tileViewerUrlState));
        }
    }

    render(){
        let app = undefined;
        let title = this.props.appKey.showTitle ? this.props.appKey.title : '';

        let closed = this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
            (this.props.appState[this.props.appKey.id] || {}).closed : (this.props.tileViewerAppState[this.props.appKey.id] || {}).closed;

        let allowedAppsInScanShare = (this.props.appKey.id != keys.presentAppKey.id && 
                                        this.props.appKey.id != keys.displaySizeCalibrationKey.id &&
                                        this.props.appKey.id != keys.reportAppKey.id &&
                                        this.props.appKey.id != keys.capGuideAppKey.id &&
                                        this.props.appKey.id != keys.ihcAppKey.id
                                        );

        app = <div style={closed ? {display: "none"} : {}}>
                <Draggable
                    id={this.props.appKey.id}
                    startBottom={this.props.startBottom}
                    startLeft={this.props.startLeft}
                    startTop={this.props.startTop}
                    startRight={this.props.startRight}
                    type={draggableType.SLIDE_VIEWER}
                    viewerType={this.props.viewerType}
                    content={
                        <div 
                            id={this.props.id}
                            className={"closeable"}>
                            {(this.props.urlState || {}).presentCode != undefined && allowedAppsInScanShare ?
                                null :
                                <div 
                                    className="closeable-handle" 
                                    onClick={this.closeApp}>
                                    <Tooltip 
                                        title="Click to Close"
                                        placement="right"
                                        mouseLeaveDelay={0}
                                        overlayStyle={{
                                            fontSize:"10px"
                                        }}>
                                        <CloseCircleTwoTone className="close-icon" twoToneColor="#de2323" />   
                                    </Tooltip> 
                                </div>
                            }
                            <div className="app-title">{title}</div>
                            {this.props.content}
                        </div>
                    } />
                </div>

        return app;
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
      appState: state.viewerUrlReducer.app_state,
      urlState: state.viewerUrlReducer,
      tileViewerAppState: state.tileViewerUrlReducer.tile_viewer_app_state,
      tileViewerUrlState: state.tileViewerUrlReducer
  };
};

export default connect(mapStateToProps)(App);