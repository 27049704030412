import html2canvas from "html2canvas";
import {convertSettingsToFilter} from "../../../utils/utils";
import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";
import {message} from "antd";

export function takeScreenshot(elementId, extension, viewerSettings, handler) {
	document.getElementById('morphle-watermark').style.visibility = "visible";

	const img = document.getElementById(elementId);
	html2canvas(img).then(canvas => {
		extension = extension.slice(1);
		// TODO: filter needs to be merged for split moded
		let filter = convertSettingsToFilter(viewerSettings);
		let downloadableCanvas = document.createElement('canvas');
		downloadableCanvas.width = canvas.width;
		downloadableCanvas.height = canvas.height;
		let context = downloadableCanvas.getContext('2d');
		downloadableCanvas.setAttribute('crossOrigin', 'anonymous');
		context.filter = filter;
		context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
		let url = downloadableCanvas.toDataURL('image/' + extension, 0.97);
		document.getElementById('morphle-watermark').style.visibility = "hidden";
		handler(url);
	});
}

export function downloadScreenshot(elementId, name, extension, viewerSettings) {
	takeScreenshot(elementId, extension, viewerSettings, (url) => {
		let anchor = document.createElement('a');
		anchor.href = url;
		anchor.style.display = 'none';
		anchor.setAttribute('download', name);
		document.body.appendChild(anchor);
		anchor.click();
		document.body.removeChild(anchor);
	});
}

export function addScreenshotToReport(elementId, slide_id, name, extension, currCase, viewerSettings) {
	takeScreenshot(elementId, extension, viewerSettings, (url) => {
		let data = {
			'image_data': url.replace('data:image/' + extension + ';base64,', ''),
			'image_path': currCase.image_path_root,
			'filename': name + ".png",
			'case_id': currCase.case_id,
			'slide_id': slide_id
		};
		axios.post('/api/upload_report_image/', data, {
			headers: { Authorization: AuthHeader() } })
			.then(response => {
				message.success("Added Snap to Case " + currCase.case_id)
			});
	})
}
