import React, { Component } from 'react';

import {
    CheckCircleFilled,
    CloseOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    PlusOutlined,
} from '@ant-design/icons';

import { Col, Row, Input, Divider, Table, message, Button, Tag, Tooltip, Empty, Spin ,Select, Form} from 'antd';
import { connect } from 'react-redux'
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from "../../../helper/auth.token"
import EditableFormTable from '../../../view/editable_table';


class CliaResultsApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currCase:[],
            isFetchingCase:false,
            case_id:'',
            caseValidationData:[],
            slidesPostGlassEligibility:[],
            slidesPostGlassReason:[],
            hasCaseThrownError:false,
            slideInCase: false,
            caseInCliaStudy: false,
            user_id: cookie.load('user_id'),
            caseScansArray:[],
            hasSlidesThrownError: false,
            isFetchingSlides: false,
        }
    }

    componentDidMount = () => {
        if(this.props.caseId != undefined && this.props.caseId != null){
            this.getCase();
        }
    }

    getCaseSlides = (currCase) => {
        this.setState({
            hasSlidesThrownError: true,
            isFetchingSlides: true,
        });
        let url = `/api/nonpagslides/?case_id=` + currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                let allSlidesUpdated = {};
                for (let i = 0; i < response.data.length; i++) {
                    allSlidesUpdated[i] = response.data[i];
                }
                console.log("slides", response.data)
                this.setState({
                    caseScansArray: response.data,
                    isFetchingSlides: false,
                    hasSlidesThrownError: false,
                })
            })
            .catch(err => {
                message.error("Slides could not be retrieved. Contact Admin.");
                console.log(err);
                this.setState({
                    caseScansArray: [],
                    isFetchingSlides: false,
                    hasSlidesThrownError: true  ,
                })
            });
    }

    getCase = () => {
        this.setState({
            hasCaseThrownError: true,
            isFetchingCase: true,
        });
        let url = `/api/case/` + this.props.caseId + `/`;
        axios
            .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(response => {
                this.setState({
                    currCase: response.data,
                    isFetchingCase: false,
                    hasCaseThrownError: false,
                    case_id : response.data['case_id'],
                });
                this.getCaseSlides(response.data)
            }).then(() => {
                this.getCaseValidationData();
                this.getSlidesPostGlassEligibility();
            })
            .catch(err => {
                message.error("Cases could not be retrieved. Contact Admin.");
                this.setState({
                    currCase: [],
                    isFetchingCase: false,
                    hasCaseThrownError: true,
                });
                console.log("err", err);
            });
    }



    getCaseValidationData = () => {
        console.log("getting data ");
        let url = `/api/user_case_specific_validation_data/?case_id=${this.state.currCase.id}&user=${parseInt(cookie.load('user_id'))}`;
        axios.get(url)
            .then(response => {
                console.log("Getting data for ", this.state.currCase.id, parseInt(cookie.load('user_id')), url)
                console.log("user validation ", response.data.result)
                // let validation_data = response.data.result
                // setTimeout(() => {  this.setState({caseValidationData:response.data["result"]}) }, 2000);
                // let validation_data = response.data.result
                // this.setState({
                //     caseValidationData: response.data.result,
                // })

                this.setState(Object.assign({}, this.state, {
                    caseValidationData: response.data.result,
                }));
                console.log("printing again ", response.data.result, this.state.caseValidationData);
            })
    }

    getSlidesPostGlassEligibility = () => {
        let url = `/api/get_slides_postglass_eligibility/?case_id=${this.state.currCase.id}&user=${parseInt(cookie.load('user_id'))}`
        axios.get(url).then(response => {
            console.log("postpost",response.data['data'])
            this.setState({
                slidesPostGlassEligibility: response.data["data"],
                slidesPostGlassReason: response.data["reason"]
            })
        })
    }

    changeUser = (value) => {
        console.log(value)
        this.setState({
            user_id: value
        })
        let url = `/api/user_case_specific_validation_data/?case_id=${this.state.currCase.id}&user=${value}`;
        axios.get(url)
            .then(response => {
                console.log("Getting data for ", this.state.currCase.id, parseInt(cookie.load('user_id')), url)
                console.log("user validation ", response.data.result)
                // let validation_data = response.data.result
                // setTimeout(() => {  this.setState({caseValidationData:response.data["result"]}) }, 2000);
                // let validation_data = response.data.result
                // this.setState({
                //     caseValidationData: response.data.result,
                // })

                this.setState(Object.assign({}, this.state, {
                    caseValidationData: response.data.result,
                }));
                console.log("printing again ", response.data.result, this.state.caseValidationData);
            })
    }
    render() {
        if(!this.props.inValidationStudy){
            return <div className="app-parent overlayed-component screenshot-app-parent">
                    <p>Not in a Clia Validation Study</p>
            </div>
        }

        let assigned_users_options;
        if((this.state.currCase||{}).assigned_users){
            let curr_case = JSON.parse(JSON.stringify(this.state.currCase))
            let assigned_users = curr_case['assigned_users']
            assigned_users.push({
                "id":  cookie.load('user_id'),
                "username": cookie.load('username'),
            })
            assigned_users_options = Object.values(assigned_users).map((user, index) => {
                return <Select.Option key={index} value={user.id}>{user.username}</Select.Option>
            })
        }
        console.log("case--", this.state.caseScansArray, this.props.slideData)
        //this.props.slideData
        // let caseScansArray = this.state.caseScansArray;
        // // caseScansArray.splice(currScanIdx,1)
        // // caseScansArray.unshift(this.props.slideData)
        // let currScanIdx;
        // let currScan;
        // for(let i=0;i<caseScansArray.length;i++){
        //     if(caseScansArray[i].id == this.props.slideData.id){
        //         currScanIdx = i;
        //         currScan = caseScansArray[i];
        //         caseScansArray=caseScansArray.splice(currScanIdx,1)
        //         break;
        //     }
        // }
        let table;
        if(!this.state.isFetchingCase && !this.state.hasCaseThrownError){
            table = <EditableFormTable 
                        currCase = {this.state.currCase}
                        caseValidationData = {this.state.caseValidationData}
                        caseScansArray = {[this.props.slideData,]}
                        slidesPostGlassEligibility = {this.state.slidesPostGlassEligibility}
                        user_id = {this.state.user_id}
                        slidesPostGlassReason = {this.state.slidesPostGlassReason}
                    />
        }
        else{
            table = <p>Couldnt load case</p>
        }
        return (
            
            <div className="app-parent overlayed-component screenshot-app-parent" style={{ width:'1200px',height:'600px', overflowY:'scroll'}}>
                <Spin spinning={this.state.isFetchingCase || this.state.isFetchingSlides}>
                    {cookie.loadAll().is_staff === "true"?
                            <Form.Item label="User Name">
                                <Select style={{width:'300px'}} placeholder={"Select assigned user to see info"} onChange={this.changeUser} value={this.state.user_id}>
                                    {assigned_users_options}
                                </Select>
                            </Form.Item>
                            :null}
                    <EditableFormTable 
                            currCase = {this.state.currCase}
                            caseValidationData = {this.state.caseValidationData}
                            caseScansArray = {this.state.caseScansArray}
                            slidesPostGlassEligibility = {this.state.slidesPostGlassEligibility}
                            user_id = {this.state.user_id}
                            slidesPostGlassReason = {this.state.slidesPostGlassReason}
                            app={true}
                            currSlideId={this.props.slideData.id}
                        />
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(CliaResultsApp)
