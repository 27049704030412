import {DeviceAlertConstant} from "../actionTypes/device.constant";

const InitialState = {
    deviceData : null ,
    isFetching : true,
}

export const deviceAlert = ( state = InitialState , action) => {
    switch(action.type){
        case DeviceAlertConstant.FETCHING_DEVICES_LIST:
            return{
                ...state,
                isFetching : true
            }
        case DeviceAlertConstant.RECEIVED_DEVICES_LIST:
            return{
                ...state,
                isFetching : false,
                deviceData : action.response.data,
            }
        case DeviceAlertConstant.FAILED_TO_RECEIVE_DEVICES_LIST:
            return{
                ...state,
            }
        case DeviceAlertConstant.RECIEVED_DEVICE_PROPERTIES:
            return{
                ...state,
                deviceProperties : action.response.data,
            }
        default :
            return state
    }
}