import React, { Component } from "react";
import { connect } from 'react-redux';

import "../../../asset/style/neoviewer/screenshot.css"
import {Button, Divider, Grid, MenuItem, Select, Stack, TextField, Typography, FormControl} from "@mui/material";
import {downloadScreenshot} from "../utils/screenshot_utils";
import {updateExtension} from "../../../action/screenshot.state.action";

class ScreenshotApp extends Component {

	constructor(props) {
		super(props);

		this.initData();
		this.state = {
			fileName: this.slideState.slide_data.name,
		}
	}

	initData = () => {
		this.activeMapId = this.props.activeMapId;
		this.slideState = this.props.mapsState[this.activeMapId].slideState;
		this.viewerSettings = this.slideState.slide_data.viewer_settings;
	}

	render() {
		this.initData();
		if (!this.slideState || !this.viewerSettings) return <div />;

		return (
			<Grid container>
				<Grid item>
					<Typography sx={{color: 'white', textAlign : 'center', margin: "5px"}}>File Details:</Typography>
					<Divider sx={{margin: "10px"}} />

					<Stack sx={{width:'100%'}} spacing={2} justifyContent="flex-start" alignItems="center"
					       direction={"column"}>
						<Stack sx={{width:'100%'}} justifyContent="space-between" alignItems="center" direction={"row"}>
							<Typography sx={{p:0, width: '35%', color: 'white', textAlign : 'center',
								justifyContent:'center'}}>File Name:</Typography>
							<TextField autoFocus size="small" color="secondary" defaultValue={this.state.fileName}
										variant="filled"
										onChange={(e) =>
											e.target.value !== undefined && e.target.value !== "" ?
										this.setState({ fileName: e.target.value }) : undefined}
										sx={{my: 1, p:0, backgroundColor:'#0b1c3b', justifyContent:'center',
											alignContent:'center', width: '65%',}}
										disabled={(this.props.urlState || {}).presentCode !== undefined} />
						</Stack>

						<Stack sx={{width:'100%'}} spacing={1} justifyContent="flex-start" alignItems="center"
						       direction={"row"}>
							<Typography sx={{p:0, width: '35%', color: 'white', textAlign : 'center',
								justifyContent:'center'}}>Extension:</Typography>
							<FormControl  color="secondary" size="small" variant="filled" 
								sx={{ width:"40%" , minWidth: 100 }}>
								<Select value={this.props.screenshotState.extension}
									sx={{p:0}} 
									onChange={event => this.props.dispatch(updateExtension(event.target.value))}
									disabled={(this.props.urlState || {}).presentCode !== undefined}>
										<MenuItem sx={{p:0}} value=".jpeg">.jpeg</MenuItem>
										<MenuItem sx={{p:0}} value=".png">.png</MenuItem>
								</Select>
							</FormControl>
						</Stack>

						<Stack sx={{width:'100%'}} spacing={3} direction={"row"} alignItems="center" justifyContent="center">
							<Button color={"secondary"} onClick={() =>
								downloadScreenshot("viewer", this.state.fileName,
									this.props.screenshotState.extension, this.viewerSettings)}
									disabled={(this.props.urlState || {}).presentCode !== undefined}>
								Save
							</Button>
						</Stack>
					</Stack>
				</Grid>
			</Grid>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		urlState: state.viewerUrlReducer,
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
		screenshotState: state.screenshotStateReducer,
	}
}

export default connect(mapStateToProps)(ScreenshotApp);
