import React, { Component } from 'react';
import SlideMap from '../component/neotileviewer/tileviewerslidemap'
import { connect } from "react-redux";


class TileViewerScan extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
        this.slide_id = this.props.match.params.id;
    }

    componentDidMount = () => {
    }

    componentDidUpdate = () => {
    }

    componentWillUnmount = () => {
    }

    render() {
        
        return (
            <div key="tileview">
                <SlideMap key="tileviewer" slide_id={this.slide_id} map_id={0} />
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(TileViewerScan);
