import React, { Component } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { connect } from "react-redux";
import { AuthHeader } from '../helper/auth.token';
import { EditOutlined } from '@ant-design/icons';
import {
    Spin,
    Row,
    Col,
    Button,
    Tooltip,
    Pagination,
    Table,
    Checkbox,
    Popover,
    Divider,
} from 'antd';
import { loadAdminSettings, changeStatisticsColumnVisibleStatus, changeMetricColumnVisibleStatus } from "../action/admin.state.action";
import * as keys from "../component/slidestatictics/statistic_keys";
import MetricSettings from "../component/slidestatictics/metric_settings";
import { metricType } from "../utils/const";

import "../asset/style/slide_statistics.css";


export class SlideTimeStatistics extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slideStatistics: [],
            total: 0,
            pageNumber: 1,
            totalPages: 1,
            isFetching: false,
            columnsUpdated: 0,
            editMetricSettingsModalVisible: false,
            popoverVisible: {},
            selectedKey: {},
            plotData: [],
            showPlot: false,
        }

        this.props.dispatch(loadAdminSettings());

    }

    componentDidMount = () => {
        this.getSlideStatistics(1);
    }

    getSlideStatistics = (pageNumber) => {
        this.setState({
            isFetching: true,
        });
        let url = `/api/slide_time_statistics/?page=` + pageNumber;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        isFetching: false,
                        slideStatistics: response.data.results,
                        total: response.data.count,
                        pageNumber: response.data.page_number,
                        totalPages: response.data.total_pages,
                    });
                }
                else {
                    console.log(response);
                    this.setState({
                        isFetching: false,
                        slideStatistics: [],
                    });
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({
                    isFetching: false,
                    slideStatistics: [],
                });
            })
    }

    reloadStatisticsComponent = () => {
        let data = [...this.state.slideStatistics];
        for (let i = 0; i < data.length; i++) {
            data[i].key = data[i].slide.id;
            data[i].slideDetails = this.getSlideDetails(data[i].slide);
            data[i].scanAreaComponent = data[i].scan_area;
            data[i].scanSpeedComponent = data[i].scan_speed;
            data[i].gapsFoundComponent = data[i].gaps_found;
            data[i].totalScanTimeComponent = this.getMetricComponent(data[i], keys.totalScanTimeKey);
            data[i].fineFocusStreakTimeComponent = this.getMetricComponent(data[i], keys.fineFocusStreakTimeKey);
            data[i].fineFocusTimeComponent = this.getMetricComponent(data[i], keys.fineFocusTimeKey);
            data[i].dropDistanceTimeComponent = this.getMetricComponent(data[i], keys.dropDistanceTimeKey);
            data[i].totalFineFocusTimeComponent = this.getMetricComponent(data[i], keys.totalFineFocusTimeKey);
            data[i].scanContinousModeSingleImageCaptureTimeComponent = this.getMetricComponent(data[i], keys.scanContinousModeSingleImageCaptureTimeKey);
            data[i].scanImageSendFromBaumerTimeComponent = this.getMetricComponent(data[i], keys.scanImageSendFromBaumerTimeKey);
            data[i].removeVignetteTimeComponent = this.getMetricComponent(data[i], keys.removeVignetteTimeKey);
            data[i].lensUndistortionTimeComponent = this.getMetricComponent(data[i], keys.lensUndistortionTimeKey);
            data[i].cameraUndistortionTimeComponent = this.getMetricComponent(data[i], keys.cameraUndistortionTimeKey);
            data[i].normalizerTimeComponent = this.getMetricComponent(data[i], keys.normalizerTimeKey);
            data[i].cropTimeComponent = this.getMetricComponent(data[i], keys.cropTimeKey);
            data[i].backgroundDetectorTimeComponent = this.getMetricComponent(data[i], keys.backgroundDetectorTimeKey);
            data[i].featureCalculationTimeComponent = this.getMetricComponent(data[i], keys.featureCalculationTimeKey);
            data[i].txtyCalculationTimeComponent = this.getMetricComponent(data[i], keys.txtyCalculationTimeKey);
            data[i].txtyRetryTimeComponent = this.getMetricComponent(data[i], keys.txtyRetryTimeKey);
            data[i].totalFeatureCalculationTimeComponent = this.getMetricComponent(data[i], keys.totalFeatureCalculationTimeKey);
            data[i].totalPostprocessingTimeComponent = this.getMetricComponent(data[i], keys.totalPostprocessingTimeKey);
            data[i].motionToPathunitTimeComponent = this.getMetricComponent(data[i], keys.motionToPathunitTimeKey);
            data[i].streakAdjustAfterDynamicFocusTimeComponent = this.getMetricComponent(data[i], keys.streakAdjustAfterDynamicFocusTimeKey);
            data[i].streakChangeTimeComponent = this.getMetricComponent(data[i], keys.streakChangeTimeKey);
            data[i].stroberStreakeHertzComponent = this.getMetricComponent(data[i], keys.stroberStreakeHertzKey);
            data[i].stripHertzComponent = this.getMetricComponent(data[i], keys.stripHertzKey);
            data[i].compensationTimeComponent = this.getMetricComponent(data[i], keys.compensationTimeKey);
            data[i].stroberStreakTranslationTimeComponent = this.getMetricComponent(data[i], keys.stroberStreakTranslationTimeKey);
            data[i].stripTranslationTimeComponent = this.getMetricComponent(data[i], keys.stripTranslationTimeKey);
            data[i].totalTranslationTimeWithoutCompensationComponent = this.getMetricComponent(data[i], keys.totalTranslationTimeWithoutCompensationKey);
            data[i].createPostprocessorBankTimeComponent = this.getMetricComponent(data[i], keys.createPostprocessorBankTimeKey);
            data[i].postprocessorInitTimeComponent = this.getMetricComponent(data[i], keys.postprocessorInitTimeKey);
            data[i].createPathTimeComponent = this.getMetricComponent(data[i], keys.createPathTimeKey);
            data[i].cpuWaitTimeComponent = this.getMetricComponent(data[i], keys.cpuWaitTimeKey);
            data[i].cleanupTimeComponent = this.getMetricComponent(data[i], keys.cleanupTimeKey);
            data[i].boilerplateTimeComponent = this.getMetricComponent(data[i], keys.boilerplateTimeKey);
            data[i].unaccountedTimeComponent = this.getMetricComponent(data[i], keys.unaccountedTimeKey);
        }

        return data;
    }

    getSlideDetails = (slide) => {
        return <div style={{fontSize: 10}}>
            <Row>
                <Col span={5}>
                    <b>{"Name: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.name}</span>
                </Col>
                <Col span={5}>
                    <b>{"Objective: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.objective_type}</span>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <b>{"X Step: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.x_step}</span>
                </Col>
                <Col span={5}>
                    <b>{"Y Step: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.y_step}</span>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <b>{"Uperpixel: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.uperpixel}</span>
                </Col>
                <Col span={5}>
                    <b>{"Img: "}</b>
                </Col>
                <Col span={7}>
                    <span>{slide.img_type}</span>
                </Col>
            </Row>
        </div>
    }

    getPlot = () => {
        let plotData = [];
        let xVals = [];
        let yVals = [];
        let keysArray = Object.values(keys);
        for (let i = 0; i < this.state.slideStatistics.length; i++) {
            xVals.push(this.state.slideStatistics[i].slide.name);
        }
        for (let i = 0; i < keysArray.length; i++) {
            let key = keysArray[i];
            if ((key.isMetric && this.props.metricState[key.id] || {}).visible) {

                yVals = [];

                if ((this.props.metricState[key.id] || {}).show_min) {
                    for (let i = 0; i < this.state.slideStatistics.length; i++) {
                        yVals.push(this.state.slideStatistics[i][key.id] != null && this.state.slideStatistics[i][key.id].min != null ?
                            this.state.slideStatistics[i][key.id].min : 0);
                    }
                    plotData.push({
                        x: xVals,
                        y: yVals,
                        mode: 'lines+markers',
                        name: key.title + " (Min)"
                    });
                } 

                yVals = [];
                
                if ((this.props.metricState[key.id] || {}).show_max) {
                    for (let i = 0; i < this.state.slideStatistics.length; i++) {
                        yVals.push(this.state.slideStatistics[i][key.id] != null && this.state.slideStatistics[i][key.id].max != null ?
                            this.state.slideStatistics[i][key.id].max : 0);
                    }
                    plotData.push({
                        x: xVals,
                        y: yVals,
                        mode: 'lines+markers',
                        name: key.title + " (Max)"
                    });
                } 
                
                yVals = [];
                
                if ((this.props.metricState[key.id] || {}).show_sum) {
                    for (let i = 0; i < this.state.slideStatistics.length; i++) {
                        yVals.push(this.state.slideStatistics[i][key.id] != null && this.state.slideStatistics[i][key.id].sum != null ?
                            this.state.slideStatistics[i][key.id].sum : 0);
                    }
                    plotData.push({
                        x: xVals,
                        y: yVals,
                        mode: 'lines+markers',
                        name: key.title + " (Sum)"
                    });
                } 
                
                yVals = [];
                
                if ((this.props.metricState[key.id] || {}).show_avg) {
                    for (let i = 0; i < this.state.slideStatistics.length; i++) {
                        yVals.push(this.state.slideStatistics[i][key.id] != null && this.state.slideStatistics[i][key.id].avg != null ?
                            this.state.slideStatistics[i][key.id].avg : 0);
                    }
                    plotData.push({
                        x: xVals,
                        y: yVals,
                        mode: 'lines+markers',
                        name: key.title + " (Avg)"
                    });
                } 
                
                yVals = [];
                
                if ((this.props.metricState[key.id] || {}).show_num) {
                    for (let i = 0; i < this.state.slideStatistics.length; i++) {
                        yVals.push(this.state.slideStatistics[i][key.id].num != null ? this.state.slideStatistics[i][key.id].num : 0);
                    }
                    plotData.push({
                        x: xVals,
                        y: yVals,
                        mode: 'lines+markers',
                        name: key.title + " (Num)"
                    });
                }
            }
        }

        this.setState({
            plotData,
        });
    }

    getMetricComponent = (data, key) => {
        return <div style={{fontSize: 12}}>
            {(this.props.metricState[key.id] || {}).show_min ?
                <Row>
                    {data[key.id] == null ? "Min: null" : "Min: " + data[key.id].min}
                </Row> : null
            }
            {(this.props.metricState[key.id] || {}).show_max ?
                <Row>
                    {data[key.id] == null ? "Max: null" : "Max: " + data[key.id].max}
                </Row> : null
            }
            {(this.props.metricState[key.id] || {}).show_sum ?
                <Row>
                    {data[key.id] == null ? "Sum: null" : "Sum: " + data[key.id].sum}
                </Row> : null
            }
            {(this.props.metricState[key.id] || {}).show_avg ?
                <Row>
                    {data[key.id] == null ? "Avg: null" : "Avg: " + data[key.id].avg}
                </Row> : null
            }
            {(this.props.metricState[key.id] || {}).show_num ?
                <Row>
                    {data[key.id] == null ? "Num: null" : "Num: " + data[key.id].num}
                </Row> : null
            }
        </div>
    }

    getColumns = () => {
        let columns = [{
            title: 'Slide Details',
            dataIndex: 'slideDetails',
            key: 'slideDetails',
        }];

        let keysArray = Object.values(keys);

        for (let i = 0; i < keysArray.length; i++) {
            let key = keysArray[i];
            if ((this.props.metricState[key.id] || {}).visible) {
                columns.push({
                    title: key.title,
                    dataIndex: key.component,
                    key: key.component,
                });
            }
        }

        return columns;
    }

    getPaginationDiv = () => {
        return <Pagination
            total={this.state.total}
            showTotal={(total, range) =>
                `Showing ${range[0]}-${range[1]} out of ${total} total scans`
            }
            current={this.state.pageNumber}
            pageSize={12}
            defaultCurrent={1}
            onChange={this.updatePage}
            size="small"
            className="pagination-mp"
        />
    }

    updatePage = (pageNumber) => {
        this.setState({
            pageNumber: pageNumber,
        });
        this.getSlideStatistics(pageNumber);
    }

    getColumnSelectionComponent = () => {
        let keysArray = Object.values(keys);
        let columnSelectionRows = [];
        for (let i = 0; i < keysArray.length; i += 4) {
            columnSelectionRows.push(this.getColumnSelectionRows(i));
        }
        return columnSelectionRows;

    }

    getColumnSelectionRows = (i) => {
        let keysArray = Object.values(keys);
        let colSpan = 6;
        return (
            <Row>
                {i < keysArray.length ?
                    <Col span={colSpan}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i].id] || {}).visible}
                            onChange={(e) => this.onChange(e, keysArray[i])}>
                            {/* <Row>
                                    <Col span={16}>
                                        {keysArray[i].title} &nbsp;
                                    </Col>
                                    <Col span={4}>
                                        <Tooltip title="Edit Metric Settings" key={keysArray[i].id + '_edit_tooltip'}>
                                            <Button size="small" onClick={() => this.openMetricSettingsModel(keysArray[i])}>
                                                <Icon type="edit" />
                                            </Button>
                                        </Tooltip>
                                    </Col>
                                </Row> */}
                            {keysArray[i].title} &nbsp; &nbsp;
                            {/* <Tooltip title="Edit Metric Settings" key={keysArray[i].id + '_edit_tooltip'}>
                                <Button size="small" onClick={() => this.openMetricSettingsModel(keysArray[i])}>
                                    <Icon type="edit" />
                                </Button>
                            </Tooltip> */}
                            <Popover
                                content={this.getMetricCheckboxComponent(keysArray[i])}
                                title="Edit Metric Settings"
                                trigger="click"
                                visible={this.state.popoverVisible[keysArray[i].id]}
                                onVisibleChange={(visible) => this.handleVisibleChange(visible, keysArray[i])}
                            >
                                <Button size="small">
                                    <EditOutlined />
                                </Button>
                            </Popover>
                        </Checkbox>
                    </Col> : null
                }
                {i + 1 < keysArray.length ?
                    <Col span={colSpan}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i + 1].id] || {}).visible}
                            onChange={(e) => this.onChange(e, keysArray[i + 1])}>
                            {keysArray[i + 1].title} &nbsp; &nbsp;
                            {/* <Tooltip title="Edit Metric Settings" key={keysArray[i + 1].id + '_edit_tooltip'}>
                                <Button size="small" onClick={() => this.openMetricSettingsModel(keysArray[i + 1])}>
                                    <Icon type="edit" />
                                </Button>
                            </Tooltip> */}
                            <Popover
                                content={this.getMetricCheckboxComponent(keysArray[i + 1])}
                                title="Edit Metric Settings"
                                trigger="click"
                                visible={this.state.popoverVisible[keysArray[i + 1].id]}
                                onVisibleChange={(visible) => this.handleVisibleChange(visible, keysArray[i + 1])}
                            >
                                <Button size="small">
                                    <EditOutlined />
                                </Button>
                            </Popover>
                        </Checkbox>
                    </Col> : null
                }
                {i + 2 < keysArray.length ?
                    <Col span={colSpan}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i + 2].id] || {}).visible}
                            onChange={(e) => this.onChange(e, keysArray[i + 2])}>
                            {keysArray[i + 2].title} &nbsp; &nbsp;
                            {/* <Tooltip title="Edit Metric Settings" key={keysArray[i + 2].id + '_edit_tooltip'}>
                                <Button size="small" onClick={() => this.openMetricSettingsModel(keysArray[i + 2])}>
                                    <Icon type="edit" />
                                </Button>
                            </Tooltip> */}
                            <Popover
                                content={this.getMetricCheckboxComponent(keysArray[i + 2])}
                                title="Edit Metric Settings"
                                trigger="click"
                                visible={this.state.popoverVisible[keysArray[i + 2].id]}
                                onVisibleChange={(visible) => this.handleVisibleChange(visible, keysArray[i + 2])}
                            >
                                <Button size="small">
                                    <EditOutlined />
                                </Button>
                            </Popover>
                        </Checkbox>
                    </Col> : null
                }
                {i + 3 < keysArray.length ?
                    <Col span={colSpan}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i + 3].id] || {}).visible}
                            onChange={(e) => this.onChange(e, keysArray[i + 3])}>
                            {keysArray[i + 3].title} &nbsp; &nbsp;
                            {/* <Tooltip title="Edit Metric Settings" key={keysArray[i + 3].id + '_edit_tooltip'}>
                                <Button size="small" onClick={() => this.openMetricSettingsModel(keysArray[i + 3])}>
                                    <Icon type="edit" />
                                </Button>
                            </Tooltip> */}
                            <Popover
                                content={this.getMetricCheckboxComponent(keysArray[i + 3])}
                                title="Edit Metric Settings"
                                trigger="click"
                                visible={this.state.popoverVisible[keysArray[i + 3].id]}
                                onVisibleChange={(visible) => this.handleVisibleChange(visible, keysArray[i + 3])}
                            >
                                <Button size="small">
                                    <EditOutlined />
                                </Button>
                            </Popover>
                        </Checkbox>
                    </Col> : null
                }
                {/* {i + 4 < keysArray.length ?
                    <Col span={4}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i + 4].id] || {}).visible} 
                            onChange={(e) => this.onChange(e, keysArray[i + 4])}>{keysArray[i + 4].title}</Checkbox>
                    </Col> : null
                } */}
                {/* {i + 5 < keysArray.length ?
                    <Col span={4}>
                        <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[keysArray[i + 5].id] || {}).visible} 
                            onChange={(e) => this.onChange(e, keysArray[i + 5])}>{keysArray[i + 5].title}</Checkbox>
                    </Col> : null
                } */}
            </Row>
        );
    }

    getMetricCheckboxComponent = (key) => {
        return <div>
            <Row>
                <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[key.id] || {}).show_min}
                    onChange={(e) => this.onChangeMetric(e, key, metricType.MIN)}>Show Min</Checkbox>
            </Row>
            <br />
            <Row>
                <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[key.id] || {}).show_max}
                    onChange={(e) => this.onChangeMetric(e, key, metricType.MAX)}>Show Max</Checkbox>
            </Row>
            <br />
            <Row>
                <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[key.id] || {}).show_sum}
                    onChange={(e) => this.onChangeMetric(e, key, metricType.SUM)}>Show Sum</Checkbox>
            </Row>
            <br />
            <Row>
                <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[key.id] || {}).show_avg}
                    onChange={(e) => this.onChangeMetric(e, key, metricType.AVG)}>Show Avg</Checkbox>
            </Row>
            <br />
            <Row>
                <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[key.id] || {}).show_num}
                    onChange={(e) => this.onChangeMetric(e, key, metricType.NUM)}>Show Num</Checkbox>
            </Row>
        </div>
    }

    onChangeMetric = (e, key, type) => {
        this.setState({
            columnsUpdated: this.state.columnsUpdated + 1,
        });
        this.props.dispatch(changeMetricColumnVisibleStatus(e.target.checked, key, type, this.props.metricState));
    }

    onChange = (e, key) => {
        this.setState({
            columnsUpdated: this.state.columnsUpdated + 1,
        })
        this.props.dispatch(changeStatisticsColumnVisibleStatus(e.target.checked, key, this.props.metricState));
    }

    handleVisibleChange = (visible, key) => {
        let popoverVisible = Object.assign({}, this.state.popoverVisible);
        popoverVisible[key.id] = visible;
        this.setState({
            popoverVisible,
        });
    }

    openMetricSettingsModel = (key) => {
        this.setState({
            editMetricSettingsModalVisible: true,
            selectedKey: key,
        });
    }

    closeModal = () => {
        this.setState({
            editMetricSettingsModalVisible: false,
            selectedKey: {},
        });
    }

    columnsUpdatedChange = () => {
        this.setState({
            columnsUpdated: this.state.columnsUpdated + 1,
        });
    }

    togglePlot = () => {
        this.getPlot();
        this.setState({
            showPlot: !this.state.showPlot,
        });
    }

    render() {

        let slideStatistics = this.reloadStatisticsComponent();

        let columns = this.getColumns();

        return (
            <div>
                <Spin spinning={this.state.isFetching}>
                    
                    {this.state.showPlot ? 
                        <div>
                            <br />
                            <Divider />
                            <Row>
                                <Col offset={1}>
                                    <Plot data={this.state.plotData}
                                        layout={{width: 1500, height: 500}}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                        </div> : null
                    }
                    <br />
                    <Row>
                        <Divider />
                        {this.getColumnSelectionComponent()}
                        <Divider />
                    </Row>
                    <Divider />
                    <Row>
                        <Col offset={1}>
                            <Button
                                onClick={this.togglePlot}
                                type={this.state.showPlot ? "danger" : "primary"}
                            >
                                {this.state.showPlot ? "Hide Plot" : "Show Plot"}
                            </Button>
                        </Col>
                    </Row>
                    <Divider />
                    <Row key={0}>
                        {this.getPaginationDiv()}
                    </Row>
                    <br />
                    <Row key={1} className="scrollable-dashboard">
                        <Table
                            key={1}
                            loading={this.state.isFetching}
                            dataSource={slideStatistics}
                            columns={columns}
                            pagination={false}
                        />
                        <Row key={2}>
                            {this.getPaginationDiv()}
                        </Row>
                    </Row>
                    {/* <MetricSettings modalVisible={this.state.editMetricSettingsModalVisible} selectedKey={this.state.selectedKey}
                        closeModal={this.closeModal} columnsUpdatedChange={this.columnsUpdatedChange} /> */}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        metricState: state.adminUrlReducer.metric_state
    };
};

export default connect(mapStateToProps)(SlideTimeStatistics);
