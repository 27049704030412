import React, { Component } from "react";
import { Spin, Menu } from 'antd';
import { getDevicesList } from "../../action/device.action";
import { connect } from 'react-redux';
import {Link} from "react-router-dom";
import {urls} from "../../helper/backend.urls";
import {globalUrlPrefix} from "../../utils/const";
import {CameraOutlined} from "@ant-design/icons";

class ScannersListComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: []
        }
    }

    componentWillMount() {
        this.props.dispatch(getDevicesList())
    }

    render() {

        return this.props.isFetching ?
            [<Spin spinning={true}><Menu.Item onItemHover={null} onClick={null} /></Spin>] :
            [<Menu.ItemGroup {...this.props}>
                {this.props.deviceList.map((device, index) => (
                    <Menu.Item name={device.name} id={device.id} key={"scanner-" + device.id} {...this.props}>
                        <Link to={urls.current + `/` + globalUrlPrefix + `/scanners/${device.id}/`}>
                            <CameraOutlined />
                            <span>{device.name}</span>
                        </Link>
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>]
    }
}

const mapStateToPropsScannerList = (state) => {
    return {
        isFetching: state.deviceAlert.isFetching,
        deviceList: state.deviceAlert.deviceData
    }
}

export default connect(mapStateToPropsScannerList)(ScannersListComp);
