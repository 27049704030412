import React, {Component} from "react";
import {connect} from "react-redux";
import {
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import {EditOff, ModeEdit} from "@mui/icons-material";
import cookie from "react-cookies";
import {getPreviewUrl, openInNewTab} from "../../../utils/utils";
import StorageIcon from '@mui/icons-material/Storage';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

class SlideNavigatorExpandedComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            edit_mode: false,
            slide_name: this.props.slide.name
        }
        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile === 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    saveName = () => {
        // add whatever api call is used in main branch
    }

    getNameComponent = () => {
        if(this.state.edit_mode){
            return <Grid display={"flex"} justifyContent={"center"}>
                        <TextField autoFocus color={"secondary"} margin={"normal"} variant={"filled"}
                                   value={this.state.slide_name}
                                   onKeyPress={(e) => {
                                            if(e.key === "Enter") {
                                                this.saveName();
                                            }
                                        }
                                   }
                                   onChange={(e)=>{
                                       this.setState({
                                           inputValue: e.target.value,
                                       })
                                   }}
                                   sx={{px:1, backgroundColor:'#0b1c3b', justifyContent:'center'}}
                        />
                        <IconButton size={"small"} onClick={() => {this.saveName()}}>
                            <CheckIcon color={"secondary"}/>
                        </IconButton>
                   </Grid>
        }else{
            return  <Grid display={"flex"} justifyContent={"center"}>
                        <Typography gutterBottom={true} sx={{marginLeft:'0.5vw'}}>
                            {this.state.slide_name}
                        </Typography>
                    </Grid>
        }
    }

    switchEditMode = () => {
        let edit_mode = this.state.edit_mode;
        if(edit_mode){
            this.setState({
                edit_mode: !edit_mode,
            })
        } else {
            this.setState({
                edit_mode: !edit_mode,
            })
        }
    }

    getEditButton = () => {
        return <IconButton onClick={this.switchEditMode} color={"secondary"} sx={{position:"absolute", right:"0"}}>
                    {this.state.edit_mode ? <EditOff/> : <ModeEdit/> }
               </IconButton>
    }

    getSlidePreviewImage = () => {
        let slide = this.props.slide;
        let preview_path = slide.preview_image;
        if(this.is_cloud === false) {
            preview_path = preview_path.replace("/static/","");
            preview_path = getPreviewUrl(preview_path.replace("stitched_small_viewer", "selection_marked"), slide);
        }
        return <img
                    width={this.isMobile ? 100 : 170}
                    alt = "logo"
                    src = {preview_path}
                />
    }

    getBarcodeImage = () => {
        let slide = this.props.slide;
        let barcodePath = slide.preview_image;
        if(!this.is_cloud) {
            barcodePath = barcodePath.replace("/static/", "")
            barcodePath = getPreviewUrl(barcodePath.replace('stitched_small_viewer', 'barcode_crop'), slide);
        }
        return <img
                    height={75}
                    width={96}
                    alt={"barcode doesnt exist"}
                    src={barcodePath}
                    className={"rotate270"}
                />
    }

    getDbEntryComponent = () => {
        let slide = this.props.slide;
        return <IconButton color={"secondary"}
                    onClick={(e) => {
                            e.stopPropagation();
                            openInNewTab(`/admin/api/slide/${slide.id}/change/`)
                        }
                    }
                >
                    <StorageIcon/>
               </IconButton>
    }

    getOpenFolderComponent = () => {
        let slide = this.props.slide;
        let local_path = "app://" + slide.loc_on_machine + "/scans/" + slide.bucket_name + "/" + slide.path;
        return <IconButton color={"secondary"}
                     onClick={(e) => {
                            e.stopPropagation();
                            window.location.href = local_path
                        }
                     }
                >
                    <FolderOpenIcon/>
               </IconButton>
    }

    // getDeleteSlideComponent = () => {
    //     let slide = this.props.slide;
    //     return <IconButton color={"secondary"}
    //                  onClick={}
    //             >
    //                 <DeleteIcon/>
    //            </IconButton>
    //
    // }


    getSlideActions = () => {
        return <Grid>
                    {cookie.loadAll().superuser === "true" ?
                            this.getDbEntryComponent()
                            : null
                    }
                    {cookie.loadAll().superuser === "true" ?
                            this.getOpenFolderComponent()
                            : null
                    }
                    {/*{cookie.loadAll().superuser === "true" || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_delete_slide_action ?*/}
                    {/*        this.getDeleteSlideComponent()*/}
                    {/*        : null*/}
                    {/*}*/}
                    {/*<Grid display={"flex"} justifyContent={"flex-end"}>*/}
                        {this.getEditButton()}
                    {/*</Grid>*/}
               </Grid>
    }

    createData = (key, value) => {
        return {key, value};
    }

    getRows = () => {
        let slide = this.props.slide;
        return [
            this.createData("MorphleId", slide.morphle_id),
            this.createData("Scan Speed", slide.scan_speed),
            this.createData("Scan Time", slide.scan_time),
            // this.createData("Owner", slide.owner_name),
        ];
    }

    getDataComponent = () => {
        let rows = this.getRows();
        return <TableContainer component={Paper} sx={{padding:'0px', marginTop:'10px',overflowX:'hidden', backgroundColor:"#0B2044"}}>
                    <Table size={"small"}>
                        <TableBody>
                            {
                                rows.map((row) => {
                                    return <TableRow key={row.key}>
                                                <TableCell component={"th"} align={"left"}>
                                                    {row.key}
                                                </TableCell>
                                                <TableCell align={"left"}>
                                                    {row.value}
                                                </TableCell>
                                            </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
    }

    render(){
        return <Grid sx={{backgroundColor:"#0B2044", width:"100%"}}>
                    <Grid>
                        {this.getSlideActions()}
                    </Grid>
                    <Grid>
                        {this.getNameComponent()}
                    </Grid>
                    <Grid sx={{marginTop:'10px'}}>
                        {this.getSlidePreviewImage()}
                        {this.getBarcodeImage()}
                    </Grid>
                    <Grid>
                        {this.getDataComponent()}
                    </Grid>
               </Grid>
    }
}

export default SlideNavigatorExpandedComponent;
