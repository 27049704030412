import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, message, Row, Select, Table } from 'antd';
import axios from "axios";
import moment from 'moment';
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { loadCaseIDs, loadCaseStatus, loadUsers } from '../action/cases.action';
import '../asset/style/add_case.css';
import { getLabelComponent, getNameComponentCaseSlide, getPreviewComponent, getStarredComponent, getTagsComponent } from "../component/case/add_case_utils";
import { AuthHeader } from "../helper/auth.token";
import { globalUrlPrefix } from '../utils/const';
var querystring = require('querystring');

const { TextArea } = Input;
const { Option } = Select;

const ButtonGroup = Button.Group;

const bareColoumns = [
    {
        dataIndex: 'previewComponent',
        key: 'preview',
        width: 300,
    },
    {
        dataIndex: 'labelComponent',
        key: 'label',
        width: 150,
    },
    {
        dataIndex: 'name',
        key: 'name',
        title: "Name",
    },
    {
        dataIndex: 'slide_details',
        key: 'slide_details',
        title: "Description",
        editable: true,
    }
]

const columns = [
    {
        dataIndex: 'starredComponent',
        key: 'starred',
        width: 100,
    },
    ...bareColoumns
    ,
    {
        dataIndex: 'tagsComponent',
        key: 'tags',
        title: "Tags",
    },
    {
        dataIndex: 'date',
        key: 'date',
        title: "Date",
    }
];

const insert = (arr, index, newItem) => [
    // part of the array before the specified index
    ...arr.slice(0, index),
    // inserted item
    newItem,
    // part of the array after the specified index
    ...arr.slice(index)
]

const staffColumns = [
    {
        dataIndex: 'scanTimeComponent',
        key: 'scanTime',
        title: "Scan Time",
    },
    {
        dataIndex: 'scanSpeedComponent',
        key: 'scanSpeed',
        title: "Scan Speed",
    }
]

const pageSize = 12;

class AddCaseView extends Component {
    constructor(props) {
        super(props);

        let case_id = '';
        let selected_slide_ids = [];

        if (window.location.href.split('?').length > 1) {
            let partsOfUrl = window.location.href.split('?')[1].split('&');
            partsOfUrl.map((part) => {
                var key = part.split('=')[0];
                var value = part.split('=')[1];
                if (key === 'case_id') {
                    case_id = value;
                } else if (key === 'slides') {
                    selected_slide_ids = value.split(',');
                }
            });
        }

        this.state = {
            case_id: case_id.toUpperCase(),
            patient_name: '',
           // dob: new moment().format('YYYY-MM-DD'),
            dob:null,
            gender: '',
            // receiving_date:'',
            // reporting_date:'',
            receiving_date: null,
            reporting_date: null,
            case_status: -1,
            case_check_flag: false,
            selected_slide_ids: selected_slide_ids,
            selected_slides: {},
            part_designation: "",
            case_type: ""
        }

        this.props.dispatch(loadCaseStatus());
        this.props.dispatch(loadUsers());
        this.props.dispatch(loadCaseIDs());

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidMount = () => {
        if(this.state.selected_slide_ids.length > 0) {
            let url = `/api/get_slides_using_ids/?ids=` + this.state.selected_slide_ids.join(',');
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    var selected_slides = {};
                    for (var i = 0; i < response.data.slides.length; i++) {
                        let slide = response.data.slides[i]
                        selected_slides[slide.id] = slide;
                    }
                    this.setState({
                        selected_slides: selected_slides,
                    })
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.allCaseStatus != this.props.allCaseStatus) {
            Object.keys(this.props.allCaseStatus).map((key) => {
                if(this.props.allCaseStatus[key].status == 'New') {
                    this.setState({
                        case_status: this.props.allCaseStatus[key].id,
                    });
                }
            });
        }
    }

    getTableSource = (allSlides) => {
        let allSlidesList = []
        Object.keys(allSlides).map((key) => {
            let slide = allSlides[key];
            if (slide.slide_details == undefined || slide.slide_details == "") {
                slide.slide_details = "-";
            }
            if (!slide.isDeleted) {
                allSlidesList.push({
                    key: parseInt(key),
                    id: slide.id,
                    name: getNameComponentCaseSlide(slide),
                    tagsComponent: getTagsComponent(slide),
                    previewComponent: getPreviewComponent(slide, this.is_cloud, this.isMobile),
                    labelComponent: getLabelComponent(slide, this.is_cloud, this.isMobile),
                    starredComponent: getStarredComponent(slide),
                    scanTimeComponent: slide.unread ? <b>{slide.scan_time}</b> : <div>{slide.scan_time}</div>,
                    scanSpeedComponent: slide.unread ? <b>{slide.scan_speed}</b> : <div>{slide.scan_speed}</div>,
                    date: slide.unread ? <b>{slide.date}</b> : <div>{slide.date}</div>,
                    slide_details: slide.slide_details,
                })
            }
        });
        return allSlidesList;
    }

    onChangeDOB = (date, dateString) => {
        this.setState({
            dob: dateString
        });
    }

    onChangeReceivingDate = (date, dateString) => {
        this.setState({
            receiving_date: dateString
        });
    }

    onChangeReportingDate = (date, dateString) => {
        this.setState({
            reporting_date: dateString
        });
    }

    handleChangeGender = (value) => {
        this.setState({
            gender: value,
        });
    }

    handleChangePartDesignation = (event) => {
        this.setState({
            part_designation:event.target.value
        });
    }

    handleCaseTypeChange = (value) => {
        this.setState({
            case_type:value
        });
    }

    getStatusOptions = () => {
        let status_options = Object.keys(this.props.allCaseStatus).map((key) => {
            let value = this.props.allCaseStatus[key];
            return <Option key={value.id} value={value.id}>{value.status}</Option>
        });
        // status_options.unshift(<Option value={-1} key={-1}>Select Status</Option>);
        return status_options;
    }

    handleTypeChange = (status) => {
        this.setState({
            case_status: status,
        });
    }

    changeCaseName = (event) => {
        let index = this.props.allCaseIDs.findIndex((item) => event.target.value.toLowerCase() == item.toLowerCase());
        if (index == -1) {
            this.setState({
                case_id: event.target.value,
                case_check_flag: false,
            });
        } else {
            this.setState({
                case_id: event.target.value,
                case_check_flag: true,
            });
        }
    }

    changePatientName = (event) => {
        this.setState({
            patient_name: event.target.value
        });
    }

    createCase = (e) => {
        let val = {
            case_id: this.state.case_id.toUpperCase(),
            patient_name: this.state.patient_name,
            age_and_sex: this.state.dob + '/' + this.state.gender,
            receiving_date: this.state.receiving_date,
            reporting_date: this.state.reporting_date,
            case_status: this.state.case_status,
            part_designation: this.state.part_designation,
            case_type: this.state.case_type
        }
        let url = '/api/check_for_slides_owner/';
        axios.post(url, querystring.stringify({ slides: this.state.selected_slide_ids }), { headers: { Authorization: AuthHeader() } })
            .then(result => {
                if(result.status == 200) {
                    let url = `/api/case/`;
                    axios.post(url, val, { headers: { Authorization: AuthHeader() } })
                        .then(response => {
                            // this.props.dispatch(makeCaseReadEntries({id: response.data.id}));
                            let value = { id: response.data.id, case_status: val.case_status, slides: this.state.selected_slide_ids };
                            let url = `/api/make_case_read_entries/`;
                            axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
                                .then(res => {
                                    if(res){
                                        window.location.href = "/" + globalUrlPrefix + "/clia_case/" + response.data.id;
                                        if(window.location.href.split('?')[1].split('=')[1]==true || window.location.href.split('?')[1].split('=')[1]=="true"){
                                            window.location.href = "/" + globalUrlPrefix + "/clia_case/" + response.data.id;
                                        }
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        })
                        .catch(err => {
                            message.error("Failed to Create Case. Contact Admin");
                            console.log(err);
                        });
                }
            })
            .catch(err => {
                if(err.response.status == 406) {
                    message.error(err.response.data, 2.5);
                } else {
                    message.error("Failed to Create Case. Contact Admin");
                }
                console.log(err);
            });
        
    }

    render() {

        console.log(this.state.part_designation)
        console.log(this.state.case_type)
        let tableSource = [];

        tableSource = this.getTableSource(this.state.selected_slides);

        let finalColumns = columns;

        if (this.isMobile) {
            finalColumns = bareColoumns;
        }

        if (cookie.loadAll().is_staff === "true") {
            for (var i = 0; i < staffColumns.length; i++) {
                finalColumns = insert(finalColumns, finalColumns.length - 1, staffColumns[i]);
            }
        }

        return (
            <div>
                <Row>
                    <Col span={24} offset={0} className="all-slides">
                        <div className="heading-style main-heading-style">
                            <b>Create Case</b>
                        </div>
                        <div>
                            <Row>
                                <Col offset={2} pull={1}>
                                    <div>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Case ID</b>
                                            </Col>
                                            <Col span={8}>
                                                {this.state.case_check_flag ?
                                                    <div className="errormessage">
                                                        Case with this Case ID already exists.
                                                            </div> : null
                                                }
                                                <Input placeholder="Case ID" value={this.state.case_id}
                                                    className={this.state.case_check_flag ? "input-field-add-case errors-input" : "input-field-add-case"}
                                                    onChange={this.changeCaseName}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Patient's Name</b>
                                            </Col>
                                            <Col span={8}>
                                                <Input placeholder="Patient's Name (Optional)" value={this.state.patient_name} className="input-field-add-case" onChange={this.changePatientName} />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>DOB</b>
                                            </Col>
                                            <Col span={8}>
                                                <DatePicker onChange={this.onChangeDOB}
                                                    placeholder={'Select DOB (Optional)'}
                                                   // value={new moment(this.state.dob)}
                                                    className="input-field-add-case"
                                                    allowClear={false}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Gender</b>
                                            </Col>
                                            <Col span={8}>
                                                <Select className="input-field-add-case"
                                                    placeholder="Select Gender (Optional)"
                                                   // value={this.state.gender}
                                                    onChange={this.handleChangeGender}
                                                >
                                                    <Option value="M">Male</Option>
                                                    <Option value="F">Female</Option>
                                                    <Option value="Others">Others</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Receiving Date</b>
                                            </Col>
                                            <Col span={8}>
                                                <DatePicker onChange={this.onChangeReceivingDate}
                                                    //value={new moment(this.state.receiving_date)=="Invalid date"?"":new moment(this.state.receiving_date)}
                                                    //value={(this.state.receiving_date)}
                                                    placeholder={'Select Date (Optional)'}
                                                    className="input-field-add-case"
                                                    allowClear={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Reporting Date</b>
                                            </Col>
                                            <Col span={8}>
                                                <DatePicker onChange={this.onChangeReportingDate}
                                                    //value={new moment(this.state.reporting_date)}
                                                    placeholder={'Select Date (Optional)'}
                                                    className="input-field-add-case"
                                                    allowClear={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Status</b>
                                            </Col>
                                            <Col span={8}>
                                                <Select
                                                    value={this.state.case_status}
                                                    placeholder="Select Status"
                                                    onChange={this.handleTypeChange}
                                                    className="input-field-add-case"
                                                >
                                                    {this.getStatusOptions()}
                                                </Select>
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Part Designation</b>
                                            </Col>
                                            <Col span={8}>
                                                <Input placeholder="Part Designation (Optional)" value={this.state.part_designation} className="input-field-add-case" onChange={this.handleChangePartDesignation} />
                                            </Col>
                                        </Row>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Case Type</b>
                                            </Col>
                                            <Col span={8}>
                                                <Select
                                                    placeholder="Select Case Type (Optional)"
                                                    //value={this.state.case_type}
                                                    onChange={this.handleCaseTypeChange}
                                                    className="input-field-add-case"
                                                >
                                                    <Option value="H&E">H&E</Option>
                                                    <Option value="IHC">IHC</Option>
                                                    <Option value="Cyto(pap)">Cyto(pap)</Option>
                                                    <Option value="Cyto(DQ)">Cyto(DQ)</Option>
                                                    <Option value="Frozen">Frozen</Option>
                                                    <Option value="Spec. stain - GMS">Spec. stain - GMS</Option>
                                                    <Option value="Spec. stain - AFB">Spec. stain - AFB</Option>
                                                    <Option value="Spec. stain - PAS">Spec. stain - PAS</Option>
                                                    <Option value="Spec. stain - PASd">Spec. stain - PASd</Option>
                                                    <Option value="Spec. stain -
                                                    Trichrome">Spec. stain - Trichrome</Option>
                                                    <Option value="Spec. stain - Iron">Spec. stain - Iron</Option>
                                                    <Option value="Spec. stain - Retic">Spec. stain - Retic</Option>
                                                </Select>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                        </div>

                        {this.state.selected_slide_ids.length > 0 ?
                            <div>
                                <div className="heading-style sub-heading-style">
                                    <b className="case-scans-heading-style">Case Scans</b>
                                </div>
                                <div>
                                    <br />
                                    <Row>
                                        <Col>
                                            <div>
                                                <Row key={1} className="scrollable-dashboard">
                                                    <Table
                                                        key={2}
                                                        loading={this.state.isFetching}
                                                        dataSource={tableSource}
                                                        columns={finalColumns}
                                                        pagination={false}
                                                        scroll={{ y: '550px' }}
                                                    />

                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>
                            </div> : null
                        }

                        <div className="submit-row-add-case">
                            <Button type="primary"
                                onClick={this.createCase}
                                disabled={!(!this.state.case_check_flag && this.state.case_id.length > 0 && this.state.case_status != -1)}>
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        height: state.contextMenuReducer.popupProps.height,
        allCaseStatus: state.caseStatusReducer.case_status,
        allUsers: state.caseStatusReducer.users,
        allCaseIDs: state.caseStatusReducer.case_ids,
    }
}

export default connect(mapStateToProps)(AddCaseView);
