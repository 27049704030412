import React from  "react";

import {
    AppstoreOutlined,
    CameraOutlined,
    CarOutlined,
    DragOutlined,
    PicCenterOutlined,
    PictureOutlined,
} from '@ant-design/icons';

export const motionAppKey = {
    id: "motion_app", 
    title : "Motion App", 
    showTitle : true, 
    icon: <CarOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const cameraAppKey = {
    id: "camera_app", 
    title : "Camera App", 
    showTitle : true, 
    icon: <CameraOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

// export const diagnosticsAppKey = {
//     id: "diagnostic_app", 
//     title : "Diagnostic Actions App", 
//     showTitle : true, 
//     icon: <Icon type="camera" />, 
//     defaultY: 2, 
//     defaultX: 30
// }

// export const cameraControlsAppKey = {
//     id: "camera_controls_app", 
//     title : "Camera Controls App", 
//     showTitle : true, 
//     icon: <Icon type="camera" />, 
//     defaultY: 2, 
//     defaultX: 30
// }

export const previewAppKey = {
    id: "preview_app", 
    title : "Preview App", 
    showTitle : true, 
    icon: <PicCenterOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

// export const mainCameraPreviewAppKey = {
//     id: "main_camera_preview_app", 
//     title : "Main Camera Preview App", 
//     showTitle : true, 
//     icon: <Icon type="pic-center" />, 
//     defaultY: 2, 
//     defaultX: 30
// }

export const savedImagesAppKey = {
    id: "saved_images_app", 
    title : "Saved Images App", 
    showTitle : true, 
    icon: <PictureOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const intrafieldAppKey = {
    id: "intrafield_app", 
    title : "Intrafield App", 
    showTitle : true, 
    icon: <AppstoreOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const previewTuningAppKey = {
    id: "preview_tuning_app", 
    title : "Preview Tuning App", 
    showTitle : true, 
    icon: <PicCenterOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const previewTuningControlsAppKey = {
    id: "preview_tuning_controls_app", 
    title : "Preview Tuning Controls App", 
    showTitle : true, 
    icon: <PicCenterOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const previewMovementTestKey = {
    id: "preview_movement_test", 
    title : "Preview Movement Test", 
    showTitle : true, 
    icon: <PicCenterOutlined />, 
    defaultY: 2, 
    defaultX: 30
}

export const slotsMarkingAppKey = {
    id: "slots_marking", 
    title : "Slots Marking App", 
    showTitle : true, 
    icon: <DragOutlined />, 
    defaultY: 2, 
    defaultX: 30
}