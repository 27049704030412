import React, {Component} from  "react";
import {Row, Col, Divider, Switch } from 'antd';
import {connect} from 'react-redux';

import {View} from "ol";
import {OverviewMap} from 'ol/control.js';
import TileLayer from "ol/layer/Tile";
import {ImageLayer} from "ol/layer/Image";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';

import {addToVisitedList, getColorLayer, getPreviewColorLegend} from './preview_coloring';
import {showSeenAreaChanged} from "../../../action/viewerSetting.action";

import "../../../asset/style/neoviewer/preview.css"
import LayerGroup from "ol/layer/Group";

class PreviewMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewColouring: false
        }

        this.tileSize = this.props.tileSize
        this.zoomLevels = this.props.zoomLevels
        this.uperpixel = this.props.uperpixel

        let resolutions = [];
        (this.zoomLevels).forEach((level) => {
            resolutions.push(this.uperpixel * Math.pow(2, parseInt(level)));
        });
    
        resolutions = resolutions.reverse();

        this.previewMap = new OverviewMap({
          className: "preview-map",
        //   layers: this.props.layers,
          layers: [new TileLayer({
                        source: new TileImage({
                          tileGrid: new TileGrid({
                               extent:  this.props.view.getProjection().getExtent(),
                               origin: [0,  this.props.view.getProjection().getExtent()[3]],
                               resolutions: resolutions,
                               tileSize: this.tileSize,
                            }),
                            projection:  this.props.view.getProjection(),
                            // url: '/scan-hdd/morphle_test/2021/07/15/21/53/1_2021-07-15_21-53-38-0000131_Beta177_S1T1R1/stitched/{z}/x{x}y{y}.jpg',
                            url: '/scan-hdd/morphle_test/'+this.props.path+'stitched/{z}/x{x}y{y}.jpg',
                            wrapX: false,
                            crossOrigin: 'anonymous'})
                        }),],
          view: new View({
            projection: this.props.view.getProjection(),
            rotation: this.props.view.getRotation(),
            resolutions: this.props.view.getResolutions(),
          }),
          collapsible: false,
          collapsed: false
        });

        this.zoomVisitedMap = {};
        this.zoomVisitedFullMap = {};

        this.props.zoomLevels.map((level) => {
          this.zoomVisitedMap[parseInt(level)] = [];
        });
    
        this.colorLayer = getColorLayer(this.previewMap, this.props.view, this.zoomVisitedMap);
        this.previewMap.getOverviewMap().addLayer(this.colorLayer);

        this.props.slidemap.on(
          ['click', 'singleclick', 'moveend'],
          () => {
            if  (this.state.previewColouring) {
                addToVisitedList(this.props.view, this.zoomVisitedFullMap, this.zoomVisitedMap);
                this.colorLayer.getSource().changed();
            }
          }
        )
    }

    setRotationToZero = () => {
        if(this.state.previewColouring!=false){
            this.props.slidemap.getView().setRotation(0);
        }
    }

    componentDidMount() {
        this.previewMap.setTarget('preview-map-to-target');
        this.props.addPreviewMapControl(this.previewMap);
        this.setRotationToZero();
    }

    componentDidUpdate(prevProps, prevState) {
        this.setRotationToZero();
        console.log("layers", this.props.layers)
        if (this.props.layers != prevProps.layers) {
            this.previewMap = new OverviewMap({
                className: "preview-map",
                layers: this.props.layers,
                view: new View({
                    projection: this.props.view.getProjection(),
                    rotation: this.props.view.getRotation(),
                    resolutions: this.props.view.getResolutions(),
                }),
                collapsible: false,
                collapsed: false
            });
            this.props.addPreviewMapControl(this.previewMap);
        }
    }

    switchPreviewColoring = (value) => {
        this.setState({
            previewColouring: value
        })
        this.props.dispatch(showSeenAreaChanged());
        this.setRotationToZero();
    }

    render(){

        let previewColorLegend = getPreviewColorLegend(this.props.zoomScale, this.props.maxZoom, this.props.urlState.z);

        return(
            <Row className='preview-component overlayed-component app-parent'>
                <Col span={24}>
                    <Row>
                        <Col span={24} id='preview-map-to-target'>
                        </Col>
                    </Row>
                    <Divider/>
                    <Row className='preview-tracking-component'>
                        <Switch 
                            className="preview-tracking-switch"
                            defaultChecked={false} 
                            size="small"
                            checked={this.state.previewColouring}
                            onChange={this.switchPreviewColoring}
                        />
                        <span className="preview-tracking-text unselectable">Show Seen Area</span> 
                    </Row>
                    {this.state.previewColouring ? previewColorLegend : undefined}
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(PreviewMap);