import React, {Component} from  "react";
import { connect } from "react-redux";
import { updateAppClosedStatus, updateAppClickTime } from '../../action/admin.state.action';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip, message, Row, Col } from 'antd';

import "../../asset/style/utils/app.css";
import "../../asset/style/manualmode/manualmode.css";

class ManualModeAppIcon extends Component {
    constructor(props) {
        super(props);
    }

    toggleShowApp = (e) => {
        let closed = !this.props.appState[this.props.appKey.id].closed;
        this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, closed, this.props.urlState));
        if(!closed) {
            this.props.dispatch(updateAppClickTime(this.props.appKey.id, this.props.urlState));
        }
        e.stopPropagation();
    }

    closeApp = (e) => {
        this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, true, this.props.urlState));
        e.stopPropagation();
    }

    render() {

        let closeButton = undefined;

        if (!(this.props.appState[this.props.appKey.id] || {}).closed) {
            closeButton = <div 
                            className="app-icon-closeable-handle-manualmode" 
                            onClick={this.closeApp}>
                            <Tooltip 
                                title="Click to Close"
                                placement="right"
                                overlayStyle={{
                                    fontSize:"10px"
                                }}>
                                <CloseCircleTwoTone className="app-icon-close" twoToneColor="#de2323" />   
                            </Tooltip> 
                        </div>
        }
        
        return (
            <div  
                className={"app-icon-manual-mode " + ((this.props.appState[this.props.appKey.id] || {}).closed ? "" : " app-icon-open-manual-mode")} 
                onClick={this.toggleShowApp}
            >
                {closeButton}
                <Row>
                    <Col span={2}>
                        {this.props.content}
                    </Col>
                    <Col span={22} className="app-icon-title-manualmode">
                        {this.props.appKey.title}
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    appState: state.adminUrlReducer.app_state,
    urlState: state.adminUrlReducer
  };
};

export default connect(mapStateToProps)(ManualModeAppIcon);