import React, {Component} from 'react';
import HeaderComp from '../component/header'
import { connect } from "react-redux";
import { Divider, PageHeader, Button, Col, Spin, message } from 'antd';
import axios from 'axios';

export class TuningView extends Component {

    constructor(props) {
	super(props);
	this.state = {
	    isFetching : false
	}
    }


    componentDidMount = () => {
    }

    switchObjective = (objective) => {
	this.setState({
	    isFetching: true
	});
	let url = `/server/devices/` + this.props.match.params.id + "/switchObjective?objective=" + objective;
	axios.get(url, {
	    timeout: 180000 })
	    .then(response => {
		console.log(response.data);
		this.setState({
		    isFetching: false
		});
	    }).catch(err => {
		message.error("Failed");
		console.log(err);
		this.setState({
		    isFetching: false
		});
	    });
    }

    render() {
	return(
	        <div>
		<HeaderComp/>
		<Divider/>
		<PageHeader title="Objective Switching"></PageHeader>

	        <Spin key="loading" tip="Loading..." spinning={this.state.isFetching} delay={500}>
		<Col span={2} offset={1}>
		<Button type="primary" style={{ backgroundColor: "#5ea6e8"}} onClick={() => this.switchObjective("FORTY_X")} >40X</Button>
		</Col>
		<Col span={2} offset={1}>
		<Button type="primary" style={{ backgroundColor: "#5ea6e8"}} onClick={() => this.switchObjective("TWENTY_X")}>20X</Button>
		</Col>
		<Col span={2} offset={1}>
		<Button type="primary" style={{ backgroundColor: "#5ea6e8"}} onClick={() => this.switchObjective("HUNDRED_X")}>100X</Button>
		</Col>
		<Col span={2} offset={1}>
		<Button type="primary" style={{ backgroundColor: "#5ea6e8"}} onClick={() => this.switchObjective("TEN_X")} >10X</Button>
		</Col>
		<Col span={2} offset={1}>
		<Button type="primary" style={{ backgroundColor: "#5ea6e8"}} onClick={() => this.switchObjective("FOUR_X")} >4X</Button>
		</Col>
		</Spin>
		</div>
	)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(TuningView);
