import React, { Component } from 'react';
import { CloseOutlined, HighlightOutlined } from '@ant-design/icons';
import { Row, Popover, Button, Menu } from 'antd';
import Draw, { createBox }  from 'ol/interaction/Draw.js';
import { Snap } from 'ol/interaction.js';
import LineString from 'ol/geom/LineString';
import { getFormattedLength, getFormattedArea } from '../../utils/utils'
import axios from 'axios';
import { AuthHeader } from '../../helper/auth.token';
import { annoSelectId } from "../../action/anno.action";
import { connect } from "react-redux";
import { getCenter } from 'ol/extent.js'
import { Fill, Stroke, Style, Text as OlText } from 'ol/style.js';
import { CirclePicker } from 'react-color';
import { updateAnnoColor } from "../../action/morpheus.state.action"


class DrawingControls extends Component {
  constructor(props) {
      super(props);
      this.state = {

        //Annotations
        value: 'Polygon',
        new_bounds: [],
        perimeter: 0,
        area: 0,
        color: 'red',
        bounds: "NIL",
        selectedId: null,
        selectedArea: null,
        isMenuOpen: false
      }

      this.select = null;
      this.vector = this.props.vector;
      this.viewer = this.props.viewer;
      this.selectHover = this.props.selectHover;
      this.popup = this.props.popup;
      this.draw = null;
    }


    escFunction = (event) => {
      if(event.keyCode === 27) {
        this.removeInteraction();
      }
    }

    addInteractionButton = () => {
      this.removeInteraction();
      let value = this.state.value;
      if (value === 'Polygon') {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "Polygon",
        });
        this.viewer.addInteraction(this.draw);
        this.snap = new Snap({ source: this.vector.getSource() });
        this.viewer.addInteraction(this.snap);
        
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.popup.setPosition(undefined);
          this.viewer.removeInteraction(select);
        });
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let coordinates = geometry.getCoordinates();
            let area = geometry.getArea();
            let areaString = getFormattedArea(area);
            let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
            let perimeterString = getFormattedLength(perimeter);
            this.addAnno(area, perimeter, "polygon", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);
          }, this);
      }
      else if (this.state.value === "LineString") {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "LineString",
          maxPoints: 2,
        });
        this.viewer.addInteraction(this.draw);
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.viewer.removeInteraction(select);
          this.popup.setPosition(undefined);
        });
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let coordinates = geometry.getCoordinates();
            let length = geometry.getLength();
            let lengthString = getFormattedLength(length);
            this.addAnno(0, length, "line", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Length : </b><span>" + lengthString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);
          }, this);
      }
      else if (this.state.value === "Freehand") {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "Polygon",
          freehand: true
        });
        this.viewer.addInteraction(this.draw);
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.viewer.removeInteraction(select);
          this.popup.setPosition(undefined);
        });
  
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let coordinates = geometry.getCoordinates();
            let area = geometry.getArea();
            let areaString = getFormattedArea(area);
            let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
            let perimeterString = getFormattedLength(perimeter);
            this.addAnno(area, perimeter, "polygon", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);
          }, this);
      }
      else if(this.state.value === "Circle") {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "Circle",
        });
        this.viewer.addInteraction(this.draw);
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.viewer.removeInteraction(select);
          this.popup.setPosition(undefined);
        });
  
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let area = geometry.getRadius() * geometry.getRadius() * Math.PI;
            let areaString = getFormattedArea(area);
            let perimeter = 2 * geometry.getRadius() * Math.PI;
            let perimeterString = getFormattedLength(perimeter);
            let coordinates = [geometry.getCenter(), geometry.getRadius()];
            this.addAnno(area, perimeter, "circle", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);

          }, this);
      }
      else if(this.state.value === "Rectangle") {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "Circle",
          geometryFunction: createBox()
        });
        this.viewer.addInteraction(this.draw);
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.viewer.removeInteraction(select);
          this.popup.setPosition(undefined);
        });
  
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let coordinates = geometry.getCoordinates();
            let area = geometry.getArea();
            let areaString = getFormattedArea(area);
            let perimeter = new LineString(geometry.getLinearRing(0).getCoordinates()).getLength();
            let perimeterString = getFormattedLength(perimeter);
            this.addAnno(area, perimeter, "polygon", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);
          }, this);
      }
      else if(this.state.value === "Freeline") {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "LineString",
          freehand: true
        });
        this.viewer.addInteraction(this.draw);
        this.draw.on("drawstart", e => {
          let select = this.selectHover;
          this.viewer.removeInteraction(select);
          this.popup.setPosition(undefined);
        });
        this.draw.on(
          "drawend",
          e => {
            let feature = e.feature;
            let geometry = feature.getGeometry();
            let endCoord = geometry.getLastCoordinate();
            let coordinates = geometry.getCoordinates();
            let length = geometry.getLength();
            let lengthString = getFormattedLength(length);
            this.addAnno(0, length, "line", JSON.stringify(coordinates), getCenter(geometry.getExtent()));
            let element = document.getElementById('popup');
            let content = document.getElementById('measure');
            content.innerHTML = "<b>Length : </b><span>" + lengthString + "</span>";
            this.popup.setElement(document.getElementById('popup'))
            element.classList.remove("hidden-mp");
            this.popup.setPosition(endCoord);
            this.viewer.removeInteraction(this.draw);
            this.viewer.addInteraction(this.select);
          }, this);
      }
    }

    addAnno = (area, perimeter, shape, coord, center) => {
      let value = {
        area: area,
        perimeter: perimeter,
        bounds: this.state.bounds.toString(),
        color: this.state.color,
        new_bounds: coord,
        slide: this.props.slide_data.id,
        creator: this.props.userId,
        shape: shape,
        center: center.toString()
      };
      let url = `/api/annotation/`;
      axios
        .post(url, value, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            let data = response;
            let id = data.data.id;
            this.props.dispatch(annoSelectId(id));
            let featuress = this.vector.getSource().getFeatures();
            let len = featuress.length;
            let feature = featuress[len - 1];
            feature.setId(id);
            feature.set('title', data.data.title);
            feature.set('name', data.data.shape);
            let annoColor = '#' + this.props.urlState.annoColor;
            let style = new Style({
              stroke: new Stroke({
                color: annoColor,
                width: 3
              }),
              fill: new Fill({
                color: 'rgba(0, 0, 0, 0.0)'
              }),
              text: new OlText({
                font: 'bold 20px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'line',
                textBaseline: 'top',
                overflow: 'true',
                fill: new Fill({
                  color: annoColor
                })
              })});
            feature.setStyle(style);          
            feature.getStyle().getText().setText(feature.get('title'));
          })
        .catch(err => {
          console.log("Annotation add failed", err);
        });
    }

    addInteraction = () => {
      let value = this.state.value;
      if (value === 'polygon') {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "Polygon",
        });
        this.viewer.addInteraction(this.draw);
      }
      else {
        this.draw = new Draw({
          source: this.vector.getSource(),
          type: "LineString",
          maxPoints: 2
        });
        this.viewer.addInteraction(this.draw);
      }
    }

    removeInteraction = () => {
      this.viewer.removeInteraction(this.draw);
      this.viewer.removeInteraction(this.select);
    }

    selectDrawingMode = (item) => {
      let key = parseInt(item.key);
      let draw_mode;
      switch(key) {
        case 0: draw_mode = "LineString";
                break;
        case 1: draw_mode = "Rectangle";
                break;
        case 2: draw_mode = "Circle";
                break;
        case 3: draw_mode = "Polygon";
                break;
        case 4: draw_mode = "Freehand";
                break;
        case 5: draw_mode = "Freeline"
                break;
        default:
                draw_mode = "Polygon";
      }
      this.setState({
        value: draw_mode,
      }, () => {
        this.addInteractionButton();
      });
    }

    changeInteraction = () => {
      this.select = this.selectHover;
      if (this.select !== null) {
        this.viewer.removeInteraction(this.select);
      }
      if (this.select !== null) {
        this.viewer.addInteraction(this.select);
        this.select.on("select", e => {
          let geom = e.mapBrowserEvent.coordinate;
          let feature = e.selected[0];
  
          if (feature !== undefined && this.props.select === true) {
            let type = feature.getGeometry().getType()
            let id = e.selected[0].getId();
            if (type === "Polygon") {
              let area = feature.getGeometry().getArea();
              let areaString = getFormattedArea(area);
              let perimeter = new LineString(feature.getGeometry().getLinearRing(0).getCoordinates()).getLength();
              let perimeterString = getFormattedLength(perimeter);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById("measure");
              content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            }
            else if (type === 'LineString') {
              let length = feature.getGeometry().getLength();
              let lengthString = getFormattedLength(length);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById("measure");
              content.innerHTML = "<b>Length : </b><span>" + lengthString + "</span>";
            }
            else if (type === 'Circle') {
              let area = feature.getGeometry().getArea();
              let areaString = getFormattedArea(area);
              let perimeter = new LineString(feature.getGeometry().getLinearRing(0).getCoordinates()).getLength();
              let perimeterString = getFormattedLength(perimeter);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById('measure');
              content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            }
            let element = document.getElementById("popup");
            element.classList.remove("hidden-mp");
  
            this.popup.setElement(document.getElementById("popup"));
            this.popup.setPosition(geom);
          }
          else if (feature !== undefined && this.props.select === false) {
            let type = feature.getGeometry().getType()
            let id = e.selected[0].getId();
            if (type === "Polygon") {
              let area = feature.getGeometry().getArea();
              let areaString = getFormattedArea(area);
              let perimeter = new LineString(feature.getGeometry().getLinearRing(0).getCoordinates()).getLength();
              let perimeterString = getFormattedLength(perimeter);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById("measure");
              content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            }
            else if (type === 'LineString') {
              let length = feature.getGeometry().getLength();
              let lengthString = getFormattedLength(length);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById("measure");
              content.innerHTML = "<b>Length : </b><span>" + lengthString + "</span>";
            }
            else if (type === 'Circle') {
              let area = feature.getGeometry().getRadius() * feature.getGeometry().getRadius() * Math.PI;
              let areaString = getFormattedArea(area);
              let perimeter = 2 * feature.getGeometry().getRadius() * Math.PI;
              let perimeterString = getFormattedLength(perimeter);
              this.props.dispatch(annoSelectId(id));
              let content = document.getElementById('measure');
              content.innerHTML = "<b>Area : </b><span>" + areaString + "</span><br><b>Perimeter : </b><span>" + perimeterString + "</span>";
            }
  
            let element = document.getElementById("popup");
            element.classList.remove("hidden-mp");
  
            this.popup.setElement(document.getElementById("popup"));
            this.popup.setPosition(geom);
          }
          else {
            this.popup.setPosition(undefined);
          }
        });
      }
    };

    componentDidMount = () => {
      document.addEventListener("keydown", this.escFunction, false);
      this.changeInteraction();
    }

    closeMenu = () => {
      this.setState(
        {
          isMenuOpen: false
        }
      );
    }

    openMenu = () => {
      this.setState(
        {
          isMenuOpen: true
        }
      );
    }

    changeAnnoColor =(color) => {
      this.props.dispatch(updateAnnoColor(
        this.props.urlState, 
        color.hex.replace('#', ''), 
      ));
    }

    render() {
      let menu = (
        <Row style={{ textAlign: "center" }}>
          <Menu selectable={false} forceSubMenuRender={true} style={{ textAlign: "center" }} onClick={this.selectDrawingMode}>
            <Menu.Item key={0}>
                Line
            </Menu.Item>
            <Menu.Item key={1}>
                Rectangle
            </Menu.Item >
            <Menu.Item key={2}>
                Circle
            </Menu.Item>
            <Menu.Item key={3}>
                Polygon
            </Menu.Item>
            <Menu.Item key={4}>
                Drag Loop
            </Menu.Item>
            <Menu.Item key={5}>
                Drag Line
            </Menu.Item>
          </Menu>
          <CirclePicker
          color={'#' + this.props.urlState.gridColor}
          circleSize={20}
          circleSpacing={5}
          onChangeComplete={this.changeAnnoColor}
          colors={["#e91e63", "#D6D6D6", "#3f51b5", "#03a9f4", "#4caf50", "#8BFF4A", "#ffeb3b", "#ff9800", "#607d8b"]} />
            <Button style={{top: '10px', color: "white" }} onClick={this.removeInteraction} className='view-sidebar stop-draw-btn'>Stop Draw</Button>
        </Row>
      );

      return (
        <Row style={{marginTop: "0%"}}>
          <Popover 
            title={<Row>Drawing Tools <CloseOutlined style={{float: "right"}} onClick={this.closeMenu} /></Row>}
            trigger="click" 
            arrowPointAtCenter={true}
            content={menu} 
            placement="right"
            visible={this.state.isMenuOpen}
            onClick={this.openMenu}>
            <Button className='view-sidebar sidebar-textbutton'>
              <HighlightOutlined /><br></br>
              Drawing Tools
            </Button>
          </Popover>
        </Row>
      );
    }
  }

const mapStateToProps = state => {
  return {
    annoData: state.annoUpdate.data,
    userId: state.loginAlerts.user_id,
    select: state.annoUpdate.select,
    urlState: state.viewerUrlReducer
  };
};

export default connect(mapStateToProps)(DrawingControls);