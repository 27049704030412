import cookie from "react-cookies";
import {AnnotationsConstants} from "../../../utils/const";
import Feature from "ol/Feature";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";
import Circle from "ol/geom/Circle";
import {Fill, Stroke, Style, Text as OlText} from "ol/style";
import {getFormattedArea, getFormattedLength} from "../../../utils/utils";
import * as keys from '../drawer/draw_tool_keys';
import {magicToolDrawingKey, magicToolResultDrawingKey} from '../drawer/draw_tool_keys';
import {expandAnnotation, openApp,} from "../../../action/triggers.action";
import {store} from "../../../helper/store";
import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";
import {isResponseOk} from "../../../helper/response.status.check";
import {displayError} from "../../../helper/display.error";
import {message} from "antd";
import {updateActiveMap} from "../../../action/gamma.state.action";
import {getCenter} from "ol/extent.js";


const annoColor = AnnotationsConstants.DEFAULT_ANNO_COLOR;

const baseText = new OlText({
    font: 'bold 20px "Open Sans", "Helvetica", "sans-serif"',
    placement: AnnotationsConstants.LINE,
    textBaseline: 'top',
    fill: new Fill({
        color: annoColor
    }),
    backgroundFill: new Fill({
        color: "#ffffff"
    }),
    backgroundStroke: new Fill({
        color: "#ffffff"
    }),
});

const baseStyle = new Style({
    stroke: new Stroke({
        color: annoColor,
        width: 2
    }),
    fill: new Fill({
        color: 'rgba(255, 0, 0, 0.0)'
    }),
});

const selectedStyle = new Style({
    stroke: new Stroke({
        color: "#00b3ff",
        width: 8
    }),
});

const otherStyle = new Style({
    stroke: new Stroke({
        color: "white",
        width: 5
    }),
});

export const blue = "rgba(0, 91, 183, 0.81)";
export const red = "#ff0000";

export const blueStyle = new Style({
    stroke: new Stroke({
        color: blue,
        width: 1
    }),
});

export const redStyle = new Style({
    stroke: new Stroke({
        color: red,
        width: 1
    }),
});

export const styleFunction = (feature) => {
    baseText.setText(feature.get('title'));
    if (isROIFeature(feature)) {
        baseStyle.getStroke().setColor(AnnotationsConstants.ROI_COLOR);
        baseText.getFill().setColor(AnnotationsConstants.ROI_COLOR);
    } else if (feature.getProperties().color) {
        baseStyle.getStroke().setColor(feature.getProperties().color);
        baseText.getFill().setColor(feature.getProperties().color);
    }
    baseStyle.setText(baseText);
    if (feature.getProperties().selected)
        return [selectedStyle, baseStyle];
    return [otherStyle, baseStyle];
}

export const getAnnotationsFeature = (annotations) => annotations &&
    annotations.map(annotation => getAnnotationFeature(annotation));

export const getAnnotationFeature = (annotation) => {
    if (!(annotation || {}).new_bounds) return undefined;
    let bounds = JSON.parse(annotation.new_bounds);
    annotation.feature = annotation.shape === AnnotationsConstants.POLYGON ?
        new Feature({
            geometry: new Polygon(bounds),
            id: annotation.id,
            name: annotation.shape,
            title: annotation.title,
        }) :
        annotation.shape === AnnotationsConstants.LINE ?
            new Feature({
                geometry: new LineString(bounds),
                id: annotation.id,
                name: annotation.shape,
                title: annotation.title,
            }) :
            annotation.shape === AnnotationsConstants.CIRCLE ?
                new Feature({
                    geometry: new Circle(bounds[0], bounds[1]),
                    id: annotation.id,
                    name: annotation.shape,
                    title: ""
                }) : undefined;
    if (annotation.feature) {
        annotation.feature.setId(annotation.id);
        annotation.feature.set('color', annotation.color);
    }
    return annotation.feature;
}

export const createAnnotation = (feature, tool, color, slideId, drawer, z, isROI) => {
    if (tool === magicToolDrawingKey) tool = magicToolResultDrawingKey; // TODO: [Priyanshu] fix this stupidity
    let geometry = feature.getGeometry();
    let geometricParams = tool.getGeometricParams(geometry);
    return {
        area: geometricParams.area,
        perimeter: geometricParams.perimeter,
        color: isROI ? AnnotationsConstants.ROI_COLOR : color,
        new_bounds: JSON.stringify(geometricParams.coordinates),
        slide: slideId,
        creator: parseInt(cookie.load('user_id')),
        shape: tool.db_key,
        tool_type: tool.name,
        center: getCenter(geometry.getExtent()).toString(),
        anno_drawer: drawer,
        created_z_level: z,
        title: isROI ? AnnotationsConstants.ROI_TITLE : getGeometricTitle(tool, geometricParams),
    }
}

export const getGeometricTitle = (tool, geometricParams) => tool && geometricParams ?
    'R ' + (tool.db_key === AnnotationsConstants.LINE ?
        getFormattedLength(geometricParams.perimeter) : getFormattedArea(geometricParams.area)) : undefined;

/**
 * @deprecated: [Priyanshu] Use createAnnotation instead
 * */
export const getAnnotationFromPoints = (color, area, perimeter, key, coord, center, taggerType, z, slide_id) => {
    return {
        area: area,
        perimeter: perimeter,
        color: color,
        new_bounds: coord,
        slide: slide_id,
        creator: parseInt(cookie.load('user_id')),
        shape: key.db_key,
        tool_type: key.name,
        center: center.toString(),
        anno_drawer: taggerType,
        created_z_level: z,
        title: key.db_key === AnnotationsConstants.LINE ? "R " + getFormattedLength(perimeter) :
            "R " + getFormattedArea(area),
    }
}

export const getAnnotationIcon = (annotation) =>
    annotation.icon || getToolTypeIcon(annotation.tool_type);

export const getToolTypeIcon = (toolType) => {
    if (toolType === keys.lineDrawingKey.name)
        return keys.lineDrawingKey.icon;
    if (toolType === keys.freelineDrawingKey.name)
        return keys.freelineDrawingKey.icon;
    if (toolType === keys.freehandDrawingKey.name)
        return keys.freehandDrawingKey.icon;
    if (toolType === keys.circleDrawingKey.name)
        return keys.circleDrawingKey.icon;
    if (toolType === keys.rectangleDrawingKey.name)
        return keys.rectangleDrawingKey.icon;
    if (toolType === keys.polygonDrawingKey.name)
        return keys.polygonDrawingKey.icon;
    if (toolType === keys.magicToolDrawingKey.name)
        return keys.magicToolDrawingKey.icon;
    if (toolType === keys.magicToolResultDrawingKey.name)
        return keys.magicToolResultDrawingKey.icon;
    return null;
}

export const onSelectAnnotationOnMap = (mapId, appId, annotationId) => {
    store.dispatch(updateActiveMap(mapId));
    store.dispatch(openApp(appId));
    store.dispatch(expandAnnotation(annotationId));
}

export const downloadAnnotations = (slideId, annoDrawer, extension) =>
    axios
        .get(
            `/api/annotation_download?slide=${slideId}&anno_drawer=${annoDrawer}`,
            {
                headers: {
                    Authorization: AuthHeader(),
                }
            }
        )
        .then(response => {
            if (isResponseOk(response))
                if (response.data.length > 0) {
                    const link = document.createElement('a');
                    link.href = extension === 'json' ?
                        URL.createObjectURL(new Blob(
                        [JSON.stringify(response.data)],
                        {
                            type:'application/json'}
                        )) :
                        // written by priyanshu - using keys as headers -
                        // values object (will convert to string and double quotes replaced to none) -
                        // each value can contain comma (that's why enclosed in double quotes)
                        URL.createObjectURL(new Blob(
                            [[Object.keys(response.data[0]).join(), ...response.data].reduce((res, json) =>
                                `${res}\n${['', ...Object.keys(json)].reduce((res, key) => 
                                    `${res}"${typeof json[key] === 'object' ?
                                        JSON.stringify(json[key]).split(`"`).join('') :
                                        json[key]}",`)}`)],
                            {
                                type: 'text/csv',
                            }
                        ))
                    link.download = `${annoDrawer === 0 ? "annotations" : "deep_bio_annotations"}.${extension}`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else message.info("No annotations available");
            else displayError("Failed to download annotations");
        })
        .catch(error => displayError("Failed to download annotations", error));

export const isROIAnnotation = (annotation) =>
    (annotation.title || '').toLowerCase() === AnnotationsConstants.ROI_TITLE.toLowerCase();

export const isROIFeature = (feature) =>
    (feature.get('title') || '').toLowerCase() === AnnotationsConstants.ROI_TITLE.toLowerCase();

export const getAllROI = (annotations) => annotations.reduce((roi, annotation) => {
    if (isROIAnnotation(annotation))
        roi[annotation.anno_drawer] = getAnnotationFeature(annotation);
    return roi;
}, {});

export const annotationIntersectsROI = (annotation, roi) =>
    roi.getGeometry().intersectsCoordinate(annotation.center.split(','));

export const filterAnnotationsInROI = (annotations) => {
    let roi = getAllROI(annotations);
    return annotations.filter(annotation => !roi[annotation.anno_drawer] ||
        annotationIntersectsROI(annotation, roi[annotation.anno_drawer]));
}
