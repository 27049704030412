import {DeepBioActions} from "../actionTypes/deepbio.constant";

export const updateDeepBioResultsDisplayed = (annos) => ({
    type: DeepBioActions.UPDATE_AI_RESULTS_DISPLAYED,
    response: annos,
})

export const updateDeepBioDisplayColor = (color) => ({
    type: DeepBioActions.UPDATE_FILTER_COLOR,
    response: color,
})
