import React from "react";
import { Route , Redirect } from "react-router-dom" ;
import {store} from '../helper/store';
import cookie from "react-cookies";
import { globalUrlPrefix } from '../utils/const'
import { tokens } from '../helper/backend.tokens';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        {...rest} 
        render = { props => {
            if (store.getState().loginAlerts.status === true || cookie.loadAll().token !== undefined) {
                return <Component {...props} />
            } else {
                var url = window.location.href.split('?')[1];
                console.log(window.location.href)
                console.log(url)
                let token = '';
                if (url != undefined) {
                    var partsOfUrl = url.split('&');
                    partsOfUrl.map((part) => {
                        var key = part.split('=')[0];
                        var value = part.split('=')[1];
                        console.log(key)
                        console.log(value)
                        if (key === 'token') {
                            token = value;
                        }
                    });
                }

                console.log(token)

                if(token == tokens.proxy_user_token) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location, token: tokens.proxy_user_token} }} />
                } else if(token == tokens.proxy_user_sparrc_morphle_admin) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location, token: tokens.proxy_user_sparrc_morphle_admin} }} />
                } else if(token == tokens.proxy_user_uscap) {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location, token: tokens.proxy_user_uscap} }} />
                } else {
                    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                }
            }

    }} />
);

export const LoginRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => ( store.getState().loginAlerts.status === true || cookie.loadAll().token !== undefined
            ? (store.getState().loginAlerts.is_staff || store.getState().loginAlerts.superuser || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) ||
            (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).collapse_menu.scans ? 
                <Redirect to={{ pathname: '/' + globalUrlPrefix + '/dashboard', state: { from: props.location } }} /> :
                <Redirect to={{ pathname: '/' + globalUrlPrefix + '/cases', state: { from: props.location } }} />
            : <Component {...props} />
    )} />
);


const unsubscribe = store.subscribe(PrivateRoute)
unsubscribe()
