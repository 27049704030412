import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip, notification, Select, Button } from 'antd';
import {connect} from 'react-redux'
import { slideUpdateInitiated, slideUpdateSuccess, slideUpdateFailed } from '../../action/slide.update.action';
import { slideListUpdateSuccess } from '../../action/slides.action';
import { addTagToTagList } from '../../action/dashboard.action';
import axios from 'axios';
import {message} from 'antd';
import {AuthHeader} from '../../helper/auth.token'
var querystring = require('querystring');


class MultipleTagAddition extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedSlides: this.props.selectedSlides,
            inputVisible: false,
            inputValue: '',
            tag_suggestions: [],
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.selectedSlides) != JSON.stringify(this.props.selectedSlides)) {
            this.setState({
                selectedSlides: this.props.selectedSlides,
                inputVisible: false,
                inputValue: '',
                tag_suggestions: [],
            });
        }
    }

    typeAheadSearch = (value) => {
        if (value.length >= 1) {
          var prefix = '_' + value;
          let url = "/api/typeahead/?prefix=" + prefix;
          axios
            .get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
              var res_list = response.data;
              var i=0;
              for (i=0; i<res_list.length; i++){
                res_list[i].display_text = res_list[i].display_text.replace(/^_/, "#");
              }
              this.setState({
                tag_suggestions: res_list
              });
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.setState({
            tag_suggestions: []
          });
        }
    }

    showInput = () => {
        this.setState({ inputVisible: true }/*, () => this.input.focus()*/);
    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    addSlideTag= (values) => {
        if(values.length > 0) {
            const {inputValue}  = this.state;
            let { selectedSlides } = this.state;
            let stripped = ('#' + values.toString().replace('#', '').replace(' ', '')).toLowerCase();
            let fail_flag = false;
            for(let i = 0; i < selectedSlides.length; i++) {
                if (stripped && selectedSlides[i].tags.indexOf(stripped) === -1) {
                    selectedSlides[i].tags = [...selectedSlides[i].tags, stripped];
                }
                this.props.dispatch(slideUpdateInitiated());
                let url = `/api/add_slide_tag/`;
                let val = {"title": stripped, "slide_id" : selectedSlides[i].morphle_id};
                axios.post(url, querystring.stringify(val), {headers: {Authorization : AuthHeader()}})
                    .then(response => {
                    if(response.status === 200 || response.status === 301 || response.status === 302) {
                        this.props.dispatch(slideUpdateSuccess(selectedSlides[i].id, response));
                        this.props.dispatch(slideListUpdateSuccess(selectedSlides[i].id, selectedSlides[i]));
                        if(!this.props.allTags.includes(stripped)) {
                            this.props.dispatch(addTagToTagList(stripped));
                        }
                    }
                    else if(response.status === 403 || response.status === 401 || response.status === 400) {
                        this.props.dispatch(slideUpdateFailed(selectedSlides[i].id, response));
                        fail_flag = true;
                    }
                })
                    .catch(err =>{
                        this.props.dispatch(slideUpdateFailed(selectedSlides[i].id, err));
                        fail_flag = true;
                });
            }
            if(fail_flag) {
                message.error('Slide Update Failed', 2.5);
            } else {
                message.success('Slide Update Successful', 2.5);
            }
            this.setState({
                inputVisible: false,
                inputValue: '',
                tag_suggestions: []
            });
        } else {
            this.setState({
                inputVisible: false,
                inputValue: '',
                tag_suggestions: []
            });
        }
    }

    onBlurAction= (values) => {
        this.setState({
            inputVisible: false,
            inputValue: '',
            tag_suggestions: []
        });
    }

    //saveInputRef = input => this.input = input

    render() {
        const { tags, inputVisible, inputValue } = this.state;

        let selected = [];

        let numSuggested = this.state.tag_suggestions.length;
        let tagSuggestionsBox = numSuggested > 0 ?

            <Select.OptGroup label={<div><b>{numSuggested}</b> result{numSuggested > 1 ? 's' : ''} found </div>}>
                {this.state.tag_suggestions.map((item, index) => (
                <Select.Option
                    key={index}
                    value={item.display_text}
                    label={item.display_text}
                    className='search-result-tag'
                    size='small'
                    type='tag'
                >
                    {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
                </Select.Option>
                ))}
            </Select.OptGroup> : undefined;

        return (
            <div>
                {inputVisible && (
                    <Select
                        showSearch
                        allowClear
                        autoFocus
                        className="search-input"
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        mode="tags"
                        onSearch={this.typeAheadSearch}
                        onSelect={this.addSlideTag}
                        onBlur={this.onBlurAction}
                        optionLabelProp="label"
                        value={selected}
                    >
                        {tagSuggestionsBox}
                    </Select>
                )}
                {!inputVisible && (
                <Button
                    onClick={this.showInput}
                >
                    <PlusOutlined /> Add New Tag
                </Button>
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus,
        urlState: state.viewerUrlReducer,
        allTags: state.dashboardReducer.allTags
    }
}

export default connect(mapStateToProps)(MultipleTagAddition)