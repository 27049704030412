import React from  "react";
import { Icon } from 'antd'

export const zoomAppKey = {
    id: "zoom-controls", 
    title : "Zoom", 
    showTitle : false,
    icon: <Icon type="search" />, 
    defaultY: 75, 
    defaultX: 80
}

export const settingsAppKey = {
    id: "settings", 
    title : "Settings", 
    showTitle : true, 
    icon: <Icon type="setting" />, 
    defaultY: 10, 
    defaultX: 60
}

export const slideInfoAppKey = {
    id: "slide_info", 
    title : "Slide Info", 
    showTitle : true, 
    icon: <Icon type="info" />, 
    defaultY: 2, 
    defaultX: 30
}

export const slideMetaAppKey = {
    id: "slide_meta", 
    title : "Tiled Info", 
    showTitle : true, 
    icon: <Icon type="control" />, 
    defaultY: 2, 
    defaultX: 30
}

export const previewControlsKey = {
    id: "preview-controls", 
    title : "Preview", 
    showTitle : true, 
    icon: <Icon type="pic-center" />, 
    defaultY: 5, 
    defaultX: 60
}

export const annotationsAppKey = {
    id: "annotations", 
    title : "Annotations", 
    showTitle : true, 
    icon: <Icon type="edit" />, 
    defaultY: 60, 
    defaultX: 30
}