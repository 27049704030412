import React from "react";
import SettingsIcon from '@mui/icons-material/Settings'
import GestureIcon from '@mui/icons-material/Gesture';
import SlideInfoApp from "./sidebarTop_apps/slide_info";
import GradingIcon from '@mui/icons-material/Grading';
import NavigationIcon from '@mui/icons-material/Navigation';
import {
    BorderColorRounded,
    Class,
    Crop169,
    Grid3x3,
    Info,
    KeyboardAltRounded,
    ScreenshotTwoTone,
} from "@mui/icons-material";
import CaseInfoApp from "./sidebarTop_apps/case_info";
import {DeepBioIcon, IHCIcon, slideViewerType} from "../../utils/const";

import AnnotationsApp from "./sidebarTop_apps/annotationsApp";
import SettingsApp from "./sidebarTop_apps/settings";
import PreviewMap from "./sidebarBottom_apps/preview";
import PreviewIcon from '@mui/icons-material/Preview';
import Barcode from "./sidebarBottom_apps/barcode";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import GridOverlay from "./sidebarTop_apps/GridOverlay";
import Screenshot from "./sidebarTop_apps/Screenshot";
import Thumbnail from "./sidebarBottom_apps/thumbnail";
import DeepBioApp from "./sidebarTop_apps/deepBioApp";

import CliaApp from "./sidebarTop_apps/cliaApp";
import ZStackControl from "./rightBottom_apps/ZStackControlApp";
import ZoomControl from "./rightBottom_apps/zoomControl";
import Layers from "./rightBottom_apps/Layers";
import ShortcutsApp from "./sidebarTop_apps/Shortcuts";
import SlideNavigatorApp from "./sidebarTop_apps/slideNavigatorApp";
import IHCApp from "./sidebarTop_apps/IHC";
import LabellingApp from "./sidebarTop_apps/Labelling";

// Side Bar Top Apps

const getAnnotationsApp = () => ({
    id: "annotations",
    component:<AnnotationsApp />,//send your component
    defaultPermission: false,
    makeSideBarIcon: true,// show user without clicking
    icon: <GestureIcon sx={{fontSize:'inherit'}}/>,// custom mui icon
    title: "Annotations", //will be shown inside all apps section only
    showTitleOnHover: false,//only works if "makeSideBarIcon=true" and title is given
});

const getSlideInfoApp = () => ({
    id: "slide_info",
    component: <SlideInfoApp viewerType={slideViewerType.GAMMA_SLIDE_VIEWER}/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <Info sx={{fontSize: 'inherit'}} />,
    title: "Slide Info",
    showTitleOnHover: true,
});

const getCaseInfoApp = () => ({
    id: "case_info",
    component: <CaseInfoApp />,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <Class sx={{fontSize: 'inherit'}} />,
    title: "Case Info",
    showTitleOnHover: true,
});

const getSettingsApp = () => ({
    id: "settings",
    component: <SettingsApp />,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon : <SettingsIcon sx={{fontSize:'inherit'}}/>,
    title: "Settings",
    showTitleOnHover: true,
});

const getGridOverlayApp = () => ({
    id: "grid-overlay",
    component: <GridOverlay />,
    defaultPermission: false,
    makeSideBarIcon: false,
    icon: <Grid3x3 sx={{fontSize: 'inherit'}}/>,
    title: "Grid",
    showTitleOnHover: true,
});

const getScreenshotApp = () => ({
    id: "screenshot",
    component: <Screenshot />,
    defaultPermission: false,
    makeSideBarIcon: false,
    icon: <ScreenshotTwoTone sx={{fontSize: 'inherit'}} />,
    title: "Screenshot",
    showTitleOnHover: true,
});

const getShortcutsApp = () => ({
    id: "shortcuts",
    component: <ShortcutsApp />,
    defaultPermission: true,
    makeSideBarIcon: true,
    icon : <KeyboardAltRounded sx={{fontSize:'inherit'}}/>,
    title: "Shortcuts",
    showTitleOnHover: true,
});

const getDeepBioApp = () => ({
    id: "deep_bio",
    component: <DeepBioApp />,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <DeepBioIcon sx={{filter: "brightness(100) contrast(100)"}}/>,
    title: "DeepBio",
    showTitleOnHover: true,
});

const getIHCApp = () => ({
    id: "ihc",
    component: <IHCApp/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <IHCIcon sx={{fontSize: 'inherit'}}/>,
    title: "IHC",
    showTitleOnHover: true,
});

export const labellingApp = {
    id: "labelling",
    component: <LabellingApp/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <BorderColorRounded sx={{fontSize: 'inherit'}}/>,
    title: "Labelling",
    showTitleOnHover: true,
};

const getCliaApp = () => ({
    id: "clia",
    component: <CliaApp/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <GradingIcon sx={{fontSize: 'inherit'}}/>,
    title: "Clia",
    showTitleOnHover: true,
});

const getSlideNavigationApp = () => ({
    id: "slide_navigator",
    component:  <SlideNavigatorApp />,  //<SlideNavigatorApp />,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <NavigationIcon sx={{fontSize: 'inherit'}} />,
    title: "Slide Navigator",
    showTitleOnHover: true,
});

// Side Bar Bottom Apps

const getPreviewApp = () => ({
    id: "preview-controls",
    component:<PreviewMap />,
    defaultPermission: true,
    makeSideBarIcon: true,
    icon: <PreviewIcon sx={{fontSize:'inherit'}}/>,
    title: "Preview",
    showTitleOnHover: true,
});

const getThumbnailViewerApp = () => ({
    id: "thumbnail",
    component: <Thumbnail/>,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <Crop169 sx={{fontSize: 30}}/>,
    title: "Thumbnail",
    showTitleOnHover: true,
});

const getBarcodeApp = () => ({
    id: "barcode",
    component:<Barcode />,
    defaultPermission: false,
    makeSideBarIcon: true,
    icon: <QrCode2Icon sx={{fontSize:'inherit'}}/>,
    title: "Barcode",
    showTitleOnHover: true,
});

// Right Bottom Apps

const getLayersApp = (gammaState) => {
    const id = "layers";
    return {
        id: id,
        component: <Layers opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Layers",
        showTitleOnHover: true,
    }
}

const getZoomControlApp = (gammaState) => {
    const id = "zoomControl";
    return {
        id: id,
        component: <ZoomControl opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Zoom Control",
        showTitleOnHover: true,
    }
}

const getZStackControlApp = (gammaState) => {
    const id = "zStackControl";
    return {
        id: id,
        component: <ZStackControl opacity={1}/>,
        defaultPermission: true,
        permission: gammaState.appsInstalled[id + "_installed"],
        title: "Z Stack Control",
        showTitleOnHover: true,
    }
}

export const getAllSideBarTopApps = () => {
    // now this should return a list of app components
    return [
        getAnnotationsApp(),
        getSettingsApp(),
        getSlideInfoApp(),
        getCaseInfoApp(),
        getGridOverlayApp(),
        getScreenshotApp(),
        getCliaApp(),
        getSlideNavigationApp(),
        getDeepBioApp(),
        getIHCApp(),
        labellingApp,
        getShortcutsApp(),
    ];
}

export const getAllSideBarBottomApps = () => {
    return [
        getPreviewApp(),
        getThumbnailViewerApp(),
        getBarcodeApp(),
    ];
}

export const getAllRightBottomApps = (gammaState) => {
    // app make in row reverse
    return [
        getZStackControlApp(gammaState),
        getZoomControlApp(gammaState),
        getLayersApp(gammaState),
    ];
}
