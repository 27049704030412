import React, { Component } from 'react';
import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from '../helper/auth.token';
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
  message,
} from 'antd';
import { globalUrlPrefix } from '../utils/const';
import { updateUrlStateInReducer, updateUserSearch, changePage } from '../action/search.user.action';
import { assignGroupsUpdateAlert, removeGroupUpdateAlert, loadGroups, userListUpdateSuccess } from '../action/users.action';
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import ChangePassword from '../component/settings/manageusers/change.password';
import '../asset/style/custom_antd_table_style.css';
const { Search } = Input;
const ButtonGroup = Button.Group;
const Option = Select.Option;


const pageSize = 15;

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      search: this.props.urlState.search,
      initializationDone: false,
      selectedGroups: [],
      groups: this.props.allGroups,
      showChangePasswordModel: false,
      selectedUser: {},
      addNewOwner: false,
      newOwnerName: '',
      errorOwnerName: false,
    }

    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.allGroups != this.props.allGroups) {
      this.setState({
        groups: this.props.allGroups,
      });
    }
  }

  getUsers = (users) => {
    let newData = [];
    Object.keys(users).sort(
      function (a, b) {
        return users[a].displayOrder - users[b].displayOrder;
      }
    ).map((key) => {
      let curr = users[key];
      curr.key = curr.id;
      curr.user = this.getUserDesign(curr);
      newData.push(curr);
    });
    return newData;
  }

  getUserDesign = (userInfo) => {
    const isLongUsername = userInfo.username.length > 15;
    const usernameElem = (
      <span>{isLongUsername ? `${userInfo.username.slice(0, 15)}...` : userInfo.username}</span>
    );
    const finalUsernameElem = isLongUsername ? <Tooltip title={userInfo.username} key={userInfo.username}>{usernameElem}</Tooltip> : usernameElem;

    const name = userInfo.first_name + " " + userInfo.last_name;

    const isLongName = name.length > 25;
    const nameElem = (
      <span>{isLongName ? `${name.slice(0, 25)}...` : name}</span>
    );
    const finalNameElem = isLongName ? <Tooltip title={name} key={name}>{nameElem}</Tooltip> : nameElem;

    let typeElem;

    if (userInfo.is_superuser) {
      typeElem = (
        <Tag key={userInfo.id + '_superuser'} color="red">superuser</Tag>
      );
    } else if (userInfo.is_staff) {
      typeElem = (
        <Tag key={userInfo.id + '_staff'} color="pink">staff</Tag>
      );
    }

    const assigned_groups = this.getAssignedGroupsComponent(userInfo);

    const editUserElem = (
      <Tooltip title="Edit User" key={userInfo.id + '_edit_tooltip'}>
        <Button size="small" onClick={() => this.editUser(userInfo)}>
          <EditOutlined />
        </Button>
      </Tooltip>
    );

    const changePasswordElem = (
      <Button size="small" onClick={() => this.changePassword(userInfo)} style={{padding: '0px 15px'}}>
        <span style={{fontSize: 12}}>Change Password</span>
      </Button>
    );

    return <Row>
      <Col span={3} offset={1}>
        {finalUsernameElem}
      </Col>
      <Col span={3}>
        {finalNameElem}
      </Col>
      <Col span={2}>
        {typeElem}
      </Col>
      <Col span={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? 12 : 15}>
        {assigned_groups}
      </Col>
      <Col span={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? 1 : 0}>
        {editUserElem}
      </Col>
      <Col span={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? 2 : 0}>
        {changePasswordElem}
      </Col>
    </Row>
  }

  editUser = (userInfo) => {
    window.location.href = "/" + globalUrlPrefix + "/edituser/" + userInfo.id;
  }

  changePassword = (userInfo) => {
    this.setState({
      showChangePasswordModel: true,
      selectedUser: userInfo,
    });
  }

  closeModal = () => {
    this.setState({
      showChangePasswordModel: false,
    });
  }

  getAssignedGroupsComponent = (userInfo) => {
    return userInfo.assigned_groups.map((group, index) => {
      const full_name = group.name;
      const isLongName = full_name.length > 20;
      let color = 'blue';
      const nameElem = (
        <Tag key={group.id} color={color} onClick={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? (event) => event.stopPropagation() : undefined}
          style={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? { cursor: 'pointer' } : {}}>
          {isLongName ? `${full_name.slice(0, 20)}...` : full_name}
        </Tag>
      );
      const finalGroupTag = isLongName ? <Tooltip title={full_name} key={group.id}>{nameElem}</Tooltip> : nameElem;
      return (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ?
        <Popconfirm
          title={"Remove user from group " + full_name + "?"}
          onConfirm={(event) => this.handleCancelAssignedGroup(event, userInfo, group.id)}
          okText="Yes"
          palcement="topRight"
          style={{ padding: 20 }}
          cancelText="No"
          onCancel={(event) => {
            event.stopPropagation();
          }}>
          {finalGroupTag}
        </Popconfirm> : finalGroupTag
    });
  }

  handleCancelAssignedGroup = (event, currUser, group_id) => {
    event.stopPropagation();
    let val = {
      id: currUser.id,
      username: currUser.username,
      displayOrder: currUser.displayOrder,
      group_id: group_id
    };
    this.props.dispatch(removeGroupUpdateAlert(currUser.id, val, this.props.urlState));
  }

  initializeSearch = () => {
    if (!this.state.initializationDone && this.props.urlState.search.length > 0) {
      this.setState({
        search: this.props.urlState.search,
        initializationDone: true,
      })
    }
  }

  onUserSearch = (value) => {
    this.setState({
      selectedRowKeys: [],
    })
    this.props.dispatch(updateUserSearch(value, this.props.urlState));
  }

  onInputChange = (e) => {
    if (e.target.value == '') {
      this.onSeachReset();
    }
    this.setState({
      search: e.target.value,
    });
  }

  onSeachReset = () => {
    if (this.state.search != '') {
      let search = '';
      this.props.dispatch(updateUserSearch(search, this.props.urlState));
      this.setState({
        search,
        selectedRowKeys: [],
        selectedGroups: [],
      });
    }
  }

  getGroupOptions = () => {
    return Object.keys(this.props.allGroups).map((key) => {
      let value = this.props.allGroups[key];
      return <Option key={value.id} value={value.id}>{value.name}</Option>
    });
  }

  getRowActionsIfAtleastOneRowSelected = () => {

    return this.state.selectedRowKeys.length > 0 ?
      <div>
        <Col span={6}>
          <ButtonGroup>
            <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allUsers).map((value) => parseInt(value)) }) }}>
              Select All Users
                    </Button>
            <Button onClick={() => { this.setState({ selectedRowKeys: [], selectedGroups: [], }) }}>
              Cancel Selection
                    </Button>
          </ButtonGroup>
        </Col>
        <Col span={9} offset={1}>
          <Select
            mode="multiple"
            value={this.state.selectedGroups}
            placeholder="Select Groups"
            onChange={this.handleGroupChange}
            filterOption={this.filterOptionGroups}
            style={{ width: 500, marginLeft: 8 }}
          >
            {this.getGroupOptions()}
          </Select>
        </Col>
        <Col span={3} offset={1}>
          <Button onClick={this.submitGroups} disabled={this.state.selectedGroups.length <= 0}>
            Submit
                </Button>
        </Col>
      </div>
      :
      (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ?
        <div>
          <Col span={3}>
            <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allUsers).map((value) => parseInt(value)) }) }}>
              Select All Users
            </Button>
          </Col>
          <Col span={12}>
            {this.getAddNewOwnerComponent()}
          </Col>
        </div>
        : null
  }

  getAddNewOwnerComponent = () => {
    if (this.state.addNewOwner) {
      return (
        <div>
          <Row>
            <Col span={10}>
              <Input value={this.state.newOwnerName} onChange={this.changeNewOwnerName} style={{ width: 300 }} />
            </Col>
            <Col span={3}>
              <Button
                type="primary"
                onClick={this.addOwner}
                disabled={!(this.state.newOwnerName.length > 0 && !this.state.errorOwnerName)}
              >
                Add
              </Button>
            </Col>
            <Col span={2}>
              <Tooltip placement="bottomRight" title="Cancel">
                <Button
                  type="danger"
                  onClick={this.changeInputView}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row>
            {this.state.errorOwnerName ?
              <div style={{ color: "red", marginLeft: 8 }}>
                Owner with this name already exists.
              </div> : null
            }
          </Row>
        </div>
      );
    } else {
      return <Button onClick={() => { this.setState({ addNewOwner: true }) }}>
        Add New Owner
      </Button>
    }
  }

  addOwner = () => {
    let url = `/api/create_new_group/?new_owner_name=` + this.state.newOwnerName;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            addNewOwner: false,
            newOwnerName: '',
          })
          Object.keys(this.props.allUsers).map((key) => {
            let user = this.props.allUsers[key];
            if (user.username == cookie.loadAll().username) {
              user.assigned_groups.push(response.data.result);
              this.props.dispatch(userListUpdateSuccess(user.id, user));
            }
          });
          message.success("New Owner added successfully", 2.5);
          this.props.dispatch(loadGroups());
        }
      })
      .catch(err => {
        message.error("Not able to create new owner.", 2.5);
        console.log(err);
      })
  }

  changeNewOwnerName = (event) => {
    let index = -1;
    Object.keys(this.props.allGroups).map((key) => {
      let value = this.props.allGroups[key];
      if (event.target.value.toLowerCase() == value.name.toLowerCase()) {
        index = key;
      }
    });
    if (index == -1) {
      this.setState({
        newOwnerName: event.target.value,
        errorOwnerName: false,
      });
    } else {
      this.setState({
        newOwnerName: event.target.value,
        errorOwnerName: true,
      });
    }
  }

  changeInputView = () => {
    this.setState({
      addNewOwner: false,
      newOwnerName: '',
    })
  }

  handleGroupChange = (value) => {
    this.setState({
      selectedGroups: value,
    })
  }

  filterOptionGroups = (inputValue, option) => {
    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  }

  submitGroups = () => {
    if (this.state.selectedGroups.length > 0) {
      this.state.selectedRowKeys.map((value, index) => {
        this.handleChangeAssignGroups(this.props.allUsers[value], this.state.selectedGroups, this.state.selectedRowKeys);
      });
    }

    this.setState({
      selectedGroups: [],
    })
  }

  handleChangeAssignGroups = (currUser, input, selectedRows) => {
    let val = {
      id: currUser.id,
      username: currUser.username,
      displayOrder: currUser.displayOrder,
      assigned_groups: input
    };
    this.props.dispatch(assignGroupsUpdateAlert(currUser.id, val, selectedRows));
  };

  getFilters = () => {
    return null;
  }

  getRowActions = () => {
    return <Row>
      <Col span={this.state.selectedRowKeys.length > 0 ? 24 : 24}>
        <Row>
          <Col span={this.state.selectedRowKeys.length > 0 ? 5 : 0} style={{ marginTop: "5px" }}>
            <span style={{ marginRight: "10px" }}>
              {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected cases ? " : ""}
            </span>
          </Col>
          <Col span={this.state.selectedRowKeys.length > 0 ? 19 : 24}>
            {this.getRowActionsIfAtleastOneRowSelected()}
          </Col>
        </Row>
      </Col>
      <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 20}>{this.getFilters()}</Col>
    </Row>
  }

  getPaginationDiv = () => {
    return <Pagination
      total={this.props.total}
      showTotal={(total, range) =>
        this.isMobile ? `` : `Showing ${range[0]}-${range[1]} out of ${total} total users`
      }
      current={this.props.urlState.page}
      pageSize={pageSize}
      defaultCurrent={1}
      onChange={this.updatePage}
      size="small"
      className="pagination-mp"
    />
  }

  updatePage = (pageNumber) => {
    this.props.dispatch(changePage(pageNumber, this.props.urlState));
  }

  openAddNewUser = () => {
    window.location.href = "/" + globalUrlPrefix + "/adduser/";
  }

  render() {
    this.initializeSearch();
    let data = this.getUsers(this.props.allUsers);

    let columns = [
      {
        title: () => {
          return <div>
            <Row>
              <Col span={3} offset={1}>
                <b className="white-text">USERNAME</b>
              </Col>
              <Col span={3}>
                <b className="white-text">NAME</b>
              </Col>
              <Col span={2}>
                <b className="white-text">TYPE</b>
              </Col>
              <Col span={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? 13 : 15}>
                <b className="white-text">ASSIGNED OWNERS</b>
              </Col>
              <Col span={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? 2 : 0}>
              </Col>
            </Row>
          </div>
        },
        dataIndex: "user",
        key: "user",
      }
    ]

    let rowActions = [
      <Divider />,
      <Row className="row-actions">
        {this.getRowActions()}
      </Row>
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowKeys
        })
      },
      selectedRowKeys: this.state.selectedRowKeys,
      columnWidth: '10px',

    };

    return (
      <div>
        <Spin tip="Loading..." spinning={this.props.isFetching} delay={500}>
          {this.props.isFetching === true ? <div></div> :
            <div>
              <Row>
                <Col span={24} offset={0} className="all-slides">
                  <br />
                  <Row>
                    <Col span={5}>
                      {(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ?
                        <Button onClick={this.openAddNewUser} className="custom-button-style add-case-button-position custom-button-background">
                          Add New User
                                        <PlusOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    </Col>
                    <Col span={8} offset={1}>
                      <Search
                        value={this.state.search || ''}
                        onChange={this.onInputChange}
                        placeholder="Search by Username"
                        onSearch={this.onUserSearch}
                        enterButton
                      />
                    </Col>
                    <Col span={6}>
                      <Button type="danger" onClick={this.onSeachReset} style={{ marginLeft: 10 }}>
                        Reset
                      </Button>
                    </Col>
                    <br />
                    <br />
                  </Row>
                  <Row>
                    {this.getPaginationDiv()}
                  </Row>
                  {rowActions}
                  <Divider />
                  <Row>
                    <Table
                      className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      locale={{ emptyText: (<Empty description="No Users Found" />) }}
                      rowSelection={(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ? rowSelection : null}
                      size="small"
                    />
                  </Row>
                  <Row>
                    {this.getPaginationDiv()}
                  </Row>
                </Col>
              </Row>
              <ChangePassword modalVisible={this.state.showChangePasswordModel} selectedUser={this.state.selectedUser} closeModal={this.closeModal} />
            </div>
          }
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allUsers: state.usersReducer,
    numPages: state.searchUserReducer.numPages,
    pageNumber: state.searchUserReducer.pageNumber,
    total: state.searchUserReducer.total,
    urlState: state.searchUserReducer,
    isFetching: state.searchUserReducer.isFetching,
    allGroups: state.searchUserReducer.groups
  };
};

export default connect(mapStateToProps)(ManageUsers);
