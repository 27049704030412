import React, {Component} from "react";
import {connect} from "react-redux";
import {Grid, IconButton, Menu, MenuItem, Popover, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import {AccountCircleTwoTone} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import {logout} from "../../../action/login.action";

class Profile extends Component {

	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null
		}
		this.settings = [
			// {
			// 	key: 'Account',
			// 	onClick: undefined,
			// },
			{
				key: 'LogOut',
				onClick: () => this.props.dispatch(logout()),
			}
		]
	}

	render() {
		return <Grid container alignItems={"center"}>
			<Tooltip title="Account">
				<IconButton onClick={(event) => this.setState({
					anchorEl: event.currentTarget
				})} >
					<AccountCircleTwoTone sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}}/>
				</IconButton>
			</Tooltip>
			<Menu open={this.state.anchorEl !== null} anchorEl={this.state.anchorEl}
			      anchorOrigin={{
					  vertical: 'bottom',
				      horizontal: 'right',
			      }}
			      transformOrigin={{
					  vertical: 'top',
				      horizontal: 'right',
			      }}
			      onClose={() => this.setState({
					anchorEl: null,
			      })}>
				{this.settings.map((setting) => (
					<MenuItem onClick={setting.onClick} key={setting.key}>
						<Typography textAlign="center">{setting.key}</Typography>
					</MenuItem>
				))}
			</Menu>
		</Grid>
	}
}
const mapStateToProps = (state) => {
	return {
	}
}
export default connect(mapStateToProps)(Profile);
