import React, { Component } from 'react';
import ScanControls from './scanControls';
import OlSelect from 'ol/interaction/Select.js';
import { CameraOutlined, CloseOutlined, FilePdfOutlined } from '@ant-design/icons';
import {
  Input,
  Col,
  Row,
  Button,
  Radio,
  Switch,
  message,
  Divider,
  notification,
  Popover,
  Slider,
  Typography,
  Select,
  Tag,
} from 'antd';
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { Zoom, OverviewMap, ScaleLine } from 'ol/control.js';
import { FullScreen } from "ol/control";
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import Overlay from 'ol/Overlay.js';
import { AuthHeader } from '../../helper/auth.token';
import axios from 'axios';
import { connect } from "react-redux";
import { PopupComp } from "./popup";
import TileImage from 'ol/source/TileImage';
import TileGrid from 'ol/tilegrid/TileGrid';
import _ from 'lodash';
import { copyStringToClipboard, otpGenerator } from '../../utils/utils'
import DrawingControls from './drawingControls';
import ZoomControls from './zoomControls';
import cookie from "react-cookies";
import GridReference from "../../custom_libs/gridReference";
import { CirclePicker } from 'react-color';
import ImageLayer from 'ol/layer/Image.js';
import { getBottomRight, getTopLeft, getIntersection, intersects } from 'ol/extent.js'
import {ImageCanvas as ImageCanvasSource} from 'ol/source.js';
import screenshot from 'image-screenshot';
import { resetMapParams, updateMapParams, updateGridShow, updateGridColor, updateGridWidth, updateGridSize, updatePreviewColorStatus } from "../../action/morpheus.state.action"
import html2canvas from 'html2canvas';
import { globalUrlPrefix } from '../../utils/const'
import { convertSettingsToFilter } from '../../utils/utils'

const { Option } = Select;
const { Text } = Typography;

class PublicMap extends Component {

  constructor(props) {
    super(props);

    let syncMode, syncBrowsing;
    if (this.props.sync_code) {
      syncBrowsing = true;
      syncMode = 1;
    }
    else {
      syncBrowsing = false;
      syncMode = null;
    }


    this.state = {

      slide: this.props.slide_data.id,

      //Sync browsing
      syncBrowsing: syncBrowsing,
      syncMode: syncMode,
      syncCode: this.props.sync_code,

      //Save Image
      image_extension: ".jpeg",
      isScreenshotMenuOpen: false, 

      //Annotations
      annoList: this.props.annoList,

      //Viewer map
      zoom: 0,
      max_zoom: this.props.slide_data.specimen_type === "blood" ? this.props.slide_data.tiling_z_levels.slice(-1)[0]: this.props.slide_data.z_levels.slice(-1)[0], 
      projection: this.props.projection,
      resolutions: this.props.resolutions,
      url: this.props.url,
      imageSize: this.props.imageSize,
      slide_data: this.props.slide_data,
    };

    this.colorList = {
      9: 'rgba(255, 0, 0, 0.5)',
      8: 'rgba(0, 255, 0, 0.5)',
      7: 'rgba(0, 0, 255, 0.5)',
      6: 'rgba(255, 255, 0, 0.5)',
      5: 'rgba(0, 255, 255, 0.5)',
      4: 'rgba(255, 0, 255, 0.5)',
      3: 'rgba(128, 128, 128, 0.5)',
      2: 'rgba(255, 128, 128, 0.5)',
      1: 'rgba(128, 128, 255, 0.5)',
      0: 'rgba(128, 255, 128, 0.5)',
    }

    this.source = this.props.source;

    this.gridRef = undefined;
    this.timer = null;
    this.snap = null;

    // select interaction working on "singleclick"
    this.selectHover = new OlSelect({
      layer: this.vector,
      wrapX: false,
      hitTolerance: 5
    });

    this.popup = new Overlay({
      autoPan: true,
      insertFirst: true,
      className: "over-mp"
    });

    this.vector = new VectorLayer({
      source: this.props.source,
      // style: styles[0]
    });
  
    this.visited = [];
    this.zoomVisitedMap = {};
    this.zoomVisitedFullMap = {};

    let tileSize;
    if (this.state.slide_data.specimen_type === "blood") {
      tileSize = [this.state.slide_data.tile_width, this.state.slide_data.tile_height];
    } else {
      tileSize = [this.state.slide_data.stitched_tile_width, this.state.slide_data.stitched_tile_height];
    }

    this.layer = new TileLayer({
      extent: this.state.projection.getExtent(),
      source: new TileImage({
        tileGrid: new TileGrid({
          extent: this.state.projection.getExtent(),
          origin: [0, this.state.imageSize[1]],
          resolutions: this.state.resolutions,
          tileSize: tileSize,
        }),
        projection: this.state.projection,
        url: this.state.url,
        wrapX: false,
        crossOrigin: 'anonymous'
      }),
    });

    this.viewer = new Map({
      controls: [],
      target: null,
      overlays: [this.popup],
      layers: [
        this.layer,
      ],
      keyboardEventTarget: document,
      view: this.props.view,
      loadTilesWhileAnimating: true,
      loadTilesWhileInteracting: true
    });

    this.updatePermalinkWithSync = () => this.props.updatePermalink(this.props.sync_browsing_enabled);

    this.viewer.addEventListener('moveend', this.updatePermalinkWithSync, false);

    this.extensionSelectAfter = (
      <Select defaultValue=".jpeg" style={{ width: 80 }} onSelect={this.changeExtension}>
        <Option value=".jpeg">.jpeg</Option>
        <Option value=".png">.png</Option>
      </Select>
    );

    let zoomScale = () => {
      var scale = [];
      let max = (0) + parseInt(this.state.max_zoom);
      let i = max - 1;
      if (this.props.slide_data.objective_type === "HUNDRED_X") {
        scale[max] = 200;
      } else if (this.props.slide_data.objective_type === "TWENTY_X"){
        scale[max] = 40;
      } 
      else {
        scale[max] = 80;
      }
      for (i; i > -1; i--) {
        scale[i] = parseFloat((scale[i + 1] / 2).toFixed(1));
      }
      return scale;
    }

    this.zoomUnits =  zoomScale();
  }

  changeExtension = (value) => {
    this.setState({
      image_extension: value
    });
  }

  componentWillUnmount() {
    this.viewer.removeEventListener('moveend', this.updatePermalinkWithSync, false);
    this.source = new VectorSource({ wrapX: false });
    this.vector = new VectorLayer({
      source: this.source
    });
    clearInterval(this.timer);
    this.timer = null;
  }

  updateMap() {
    this.viewer.getView().setCenter([this.props.urlState.x, this.props.urlState.y]);
    this.viewer.getView().setZoom(this.props.urlState.z);
    this.viewer.getView().setRotation(this.props.urlState.r);
  }

  setSyncBrowsingData = (angle, zoom, center_x, center_y) => {
    let url = `/api/set_sync_browsing/?sync_code=${this.state.syncCode}&morphle_id=${this.state.slide_data.morphle_id}&angle=${angle}&zoom=${zoom}&center_x=${center_x}&center_y=${center_y}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
      })
      .catch(err => {
        console.log("Failed Setting Key Value");
      });
  }

  deleteAnno = (id) => {
    let features = this.vector.getSource().getFeatures();
    let result = features.filter(index => index.getId() === id);
    this.props.source.removeFeature(result[0]);
    this.popup.setPosition(undefined);
    this.selectHover.getFeatures().remove(result[0]);
  }

  resetViewerState = () => {
    this.props.dispatch(resetMapParams());
  }

  getExtentListFromIntersection = (intersectionList, newExtent) => {
    // this function works only if every individual bboxe in the intersectionlist has a corner
    let xSet = new Set(), xYDict = {};
    let newExtentMinY = newExtent[1];
    let newExtentMaxY = newExtent[3];
    let disjointExtents = []
    intersectionList.map((extent) => {
      xSet.add(extent[0]);
      xSet.add(extent[2]);
    });
    xSet.add(newExtent[0]);
    xSet.add(newExtent[2]);
    let xList = [...xSet].sort(function(a, b){return a-b}); // stores all the x cordinates of the boxes in ascending order
    //
    //  ______ur_____________
    //  |    |              |
    //  |__ul|              |
    //  |                   |
    //  |                   |
    //  |__dl_dr____________|
    //       x
    //       |- this is an x coord for which ur and ul is different
    // For every x co-ordinate we find the up-left(ul), up-right(ur), down-left(dl), down-right(dr) points
    // Actually only the ur and dl points are needed for forming boxes, but ul and dr are provided for the sake of closure.
    // so if a box starts from a x-coord, and if the x-coord == xmin then y = ur (the bbox standard format is xmin, ymin, xmax, ymax)
    //                                           the x-coord == xmax then y = dl
    xList.map(xCord => {
      xYDict[xCord] = {}
      xYDict[xCord]["ul"] = newExtentMinY;
      xYDict[xCord]["ur"] = newExtentMinY;
      xYDict[xCord]["dl"] = newExtentMaxY;
      xYDict[xCord]["dr"] = newExtentMaxY;
      intersectionList.map((extent) => {
        let xmin = extent[0]
        let ymin = extent[1]
        let xmax = extent[2]
        let ymax = extent[3]
        if(xCord >= xmin && xCord <= xmax){ // the x-coord lies inside the bbox
          if(ymin === newExtentMinY){ // the bbox shares a north corner with the newExtent
            if (xYDict[xCord]["ul"] < ymax) {
                if (xCord !== xmin)
                  xYDict[xCord]["ul"] = ymax;
            }
            if (xYDict[xCord]["ur"] < ymax) {
                if (xCord !== xmax)
                   xYDict[xCord]["ur"] = ymax;
            }
            
          }
          if(ymax === newExtentMaxY){  // the bbox shares a south corner with the newExtent
            if (xYDict[xCord]["dl"] > ymin) {
                if (xCord != xmin)
                    xYDict[xCord]["dl"] = ymin;
            }
            if (xYDict[xCord]["dr"] > ymin) {
                if (xCord != xmax)
                  xYDict[xCord]["dr"] = ymin;
            }
          }

        }

      });
    });
    // now we have all the ul,ur,dl,dr for all the x-coords
    for (var i=0; i<xList.length-1; i++){
      if(xYDict[xList[i]]["ur"] < xYDict[xList[i+1]]["dl"]) { // check for valid height
        disjointExtents.push([xList[i], xYDict[xList[i]]["ur"], xList[i+1], xYDict[xList[i+1]]["dl"]]); // a bbox from ith x-coord at north to (i+1)th x-coord at south
      }
    }

    return disjointExtents;
  }
  
  addToVisitedList= () => {
    let newExtent = this.viewer.getView().calculateExtent();
    let currentZoom = this.viewer.getView().getZoom();
    
    if (this.zoomVisitedMap[currentZoom] === undefined) {
      return;
    }
    if (this.zoomVisitedMap[currentZoom].length === 0) {
      this.zoomVisitedMap[currentZoom].push(newExtent);
      this.zoomVisitedFullMap[currentZoom] = [];
      this.zoomVisitedFullMap[currentZoom].push(newExtent);
      return;
    } 
    let newVisitedMap = this.zoomVisitedMap[currentZoom];
    let intersectionList = [];
    let return_out = false;
    this.zoomVisitedFullMap[currentZoom].map((extent) => {
      if (intersects(newExtent, extent)) {
        let tmp = getIntersection(newExtent, extent);
        if (tmp === newExtent) {
            return_out = true;
        }
        intersectionList.push(getIntersection(newExtent, extent));
      }
    });
    if (return_out) {
        return;  
    }
    if (_.isEmpty(intersectionList)) {
      newVisitedMap.push(newExtent);
    }
    else {
      this.getExtentListFromIntersection(intersectionList, newExtent).map((innerExtent) => {
        newVisitedMap.push(innerExtent);
      })
    }
    this.zoomVisitedMap[currentZoom] = newVisitedMap;
    this.zoomVisitedFullMap[currentZoom].push(newExtent);
  }

  getPreviewColorForZoom = (zoom) => {
    return this.colorList[zoom];
  }

  componentDidMount() {

    document.addEventListener("keydown", this.escFunction, false);

    this.viewer.addOverlay(this.popup);
    let closer = document.getElementById("popup-closer");
    closer.onclick = () => {
      this.popup.setPosition(undefined);
      closer.blur();
      return false;
    };

    this.viewer.setTarget("map-" + this.props.map_id);
    this.viewer.addLayer(this.vector);
    this.canvas = null;

    this.drawCircle = (ctx, [x, y], radius = 5) => {
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, Math.PI * 2);
      ctx.closePath();
      ctx.fill();
    }

    this.overviewLayers = [
      this.layer
    ];


    this.overviewMap = new OverviewMap({
      target: "overview-map",
      className: "nav-mp",
      layers: this.overviewLayers,
      view: new View({
        projection: this.state.projection,
        rotation: this.props.urlState.r,
        resolutions: this.state.resolutions,
      }),
      autoPan: true
    });

    this.viewer.addControl(
      this.overviewMap
    );


    let zoomLevels = this.props.slide_data.specimen_type === "blood"?this.props.slide_data.tiling_z_levels.split(','):this.props.slide_data.z_levels.split(',');

    zoomLevels.map((level) => {
      this.zoomVisitedMap[parseInt(level)] = [];
    });
     
    this.canvasFunction = (extent, resolution, pixelRatio, size, projection) => {
      let canvasWidth = size[0], canvasHeight = size[1];
      this.canvas = document.createElement('canvas');
      let currentZoom = this.viewer.getView().getZoom();
      this.canvas.setAttribute('width', canvasWidth);
      this.canvas.setAttribute('height', canvasHeight);
      this.ctx = this.canvas.getContext("2d");
      this.ctx.clearRect(0,0, canvasWidth, canvasHeight);
      let overViewExtent = this.overviewMap.getOverviewMap().getView().calculateExtent();
      if (this.zoomVisitedMap[currentZoom] === undefined) {
        return;
      }
      this.zoomVisitedMap[currentZoom].map((extent) => {
        if (intersects(overViewExtent, extent)) {
            this.ctx.fillStyle = this.getPreviewColorForZoom(currentZoom);
            this.ctx.save();
            let corner1 = this.overviewMap.getOverviewMap().getPixelFromCoordinate(getBottomRight(extent));
            let corner2 = this.overviewMap.getOverviewMap().getPixelFromCoordinate(getTopLeft(extent));
            let rect = [corner2[0], corner2[1],(corner1[0] - corner2[0]) * pixelRatio, (corner1[1] - corner2[1]) * pixelRatio];
            this.ctx.fillRect(rect[0] * pixelRatio, rect[1] * pixelRatio, rect[2], rect[3]);
          }
      });
      return this.canvas;
    };

    this.colorSource = new ImageCanvasSource({
      canvasFunction: this.canvasFunction,
      projection: this.state.projection,
      ratio: 1.0
    });

    this.colorLayer = new ImageLayer({
      source: this.colorSource
    });

    this.overviewMap.getOverviewMap().addLayer(this.colorLayer);

    this.viewer.addControl(
      new ScaleLine({
        units: "metric",
        minWidth: 100
      })
    );

    this.viewer.getView().on(
      ['change:center', 'change:resolution', 'change:rotation'],
      () => {
        if (this.state.syncBrowsing === true) {
          if (this.state.syncMode === 0) {
            let center = this.viewer.getView().getCenter();
            this.setSyncBrowsingData(this.viewer.getView().getRotation(), this.viewer.getView().getZoom(), center[0], center[1]);
          }
        }
      }
    );

    this.viewer.on(
      ['click', 'singleclick', 'moveend'],
      () => {
        if  (this.props.urlState.previewColor) {
            this.addToVisitedList();
            this.colorSource.changed();
        }
      }
    )

    this.startSyncIfValid();
  }

  drawGrid = () => {

    if (this.gridRef) {
      this.viewer.removeControl(this.gridRef);
    }

    var ext = this.state.projection.getExtent();
    var height = ext[3];
    var width = ext[2];

    var gridEveryU = this.props.urlState.gridSize;
    var gridCols = width / gridEveryU;
    var gridRows = height / gridEveryU;

    this.gridRef = new GridReference(
      {
        extent: this.state.projection.getExtent(),
        size: [gridCols, gridRows],
        target: document.querySelector(".options div"),
        property: "commune",
        color: '#' + this.props.urlState.gridColor,
        width: this.props.urlState.gridWidth
      }
    );

    this.viewer.addControl(this.gridRef);
  }

  toggleDrawGrid = () => {
    this.props.dispatch(updateGridShow(
      this.props.urlState, 
      !this.props.urlState.showGrid, 
    ));
  }

  changeGridColor = (color, event) => {
    this.props.dispatch(updateGridColor(
      this.props.urlState, 
      color.hex.replace('#', ''), 
    ));
  }

  changeGridSize = (value) => {
    this.props.dispatch(updateGridSize(
      this.props.urlState, 
      value, 
    ));
  }

  changeGridWidth = (value) => {
    this.props.dispatch(updateGridWidth(
      this.props.urlState, 
      value, 
    ));
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (nextProps.urlState.x !== this.props.urlState.x) return true;

    if (nextProps.urlState.y !== this.props.urlState.y) return true;

    if (nextProps.urlState.z !== this.props.urlState.z) return true;

    if (nextProps.urlState.r !== this.props.urlState.r) return true;

    if (nextProps.urlState.showGrid !== this.props.urlState.showGrid) return true;

    if (nextProps.urlState.gridSize !== this.props.urlState.gridSize) return true;

    if (nextProps.urlState.gridColor !== this.props.urlState.gridColor) return true;

    if (nextProps.urlState.gridWidth !== this.props.urlState.gridWidth) return true;

    if (nextProps.urlState.previewColor !== this.props.urlState.previewColor) return true;

    if ((nextState.syncBrowsing !== this.state.syncBrowsing) || (nextState.syncMode !== this.state.syncMode)) {
      return true;
    }

    if (nextState.isScreenshotMenuOpen !== this.state.isScreenshotMenuOpen) return true;

    if (this.props.deleteId !== nextProps.deleteId) {
      this.deleteAnno(nextProps.deleteId);
      return false;
    }

    let id = this.state.selectedId;
    let area = this.state.selectedArea;

    if (id !== nextState.selectedId || area !== nextState.selectedArea) {
      return true;
    }

    if (this.state.resolutions !== nextState.resolutions) {
      return true;
    }

    if (this.props.annoData !== nextProps.annoData) {
      return true;
    }

    if (this.props.viewerSettingData !== nextProps.viewerSettingData) {
      return true;
    }

    return false;
  }


  syncWithMaster = () => {
    let url = `/api/get_sync_browsing/?sync_code=${this.state.syncCode}&morphle_id=${this.state.slide_data.morphle_id}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
        if (_.isEmpty(response.data)) {
          message.error("Bad Request. Contact Admin");
        } else {
          this.props.dispatch(updateMapParams(
            this.props.urlState, 
            parseFloat(response.data.center_x), parseFloat(response.data.center_y),
            parseFloat(response.data.zoom),
            parseFloat(response.data.angle),
          ));
          this.updateMap();
        }
      })
      .catch(err => {
        console.log("Failed Getting Value");
      });
  }

  cleanSyncState = () => {
    let url = `/api/clean_sync_browsing/?morphle_id=${this.state.slide_data.morphle_id}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
        window.location.href = "/" + globalUrlPrefix + "/viewer/" + this.state.slide_data.id;
      })
      .catch(err => {
        console.log("Failed Cleaning");
      });
  }

  generateCodeAndInformUser = () => {
    let syncCode = otpGenerator(4);
    this.setState({
      syncCode: syncCode
    }, () => {
      copyStringToClipboard(this.state.slide + "-" + syncCode);
      notification['info']({
        message: <div>Share the Code <b>{this.state.slide + "-" + syncCode}</b>  to start presenting!</div>,
        description: "The code is already copied to clipboard",
        duration: 0
      });
      let center = this.viewer.getView().getCenter();
      this.setSyncBrowsingData(this.viewer.getView().getRotation(), this.viewer.getView().getZoom(), center[0], center[1]);
    })
  }

  switchSyncchronousBrowsing = (checked) => {
    this.setState({
      syncBrowsing: checked,
      syncMode: 0
    });

    if (checked) {
      this.generateCodeAndInformUser();
    }

    if (!checked) {
      clearInterval(this.timer);
      this.timer = null;
      this.resetViewerState();
      this.cleanSyncState();
    }
  }

  switchPreviewColor = (checked) => {
    this.props.dispatch(updatePreviewColorStatus(
      this.props.urlState, 
      checked
    ));
  };

  changeSyncMode = e => {
    if (e.target.value === 1 && (this.props.sync_code === undefined)) {
      this.setState({
        syncMode: 0
      });
      message.warn("No Sync Code Found. Changing mode to Master.", 3);
    }
    else {
      this.setState({
        syncMode: e.target.value
      }, () => {
        this.startSyncIfValid();
      });
    }
  }

  startSyncIfValid = () => {
    if (this.state.syncMode === 1) {
      this.timer = setInterval(() => this.syncWithMaster(), 100);
    }
  }


  _download = (url, fullName) => {
    var anchor = document.createElement('a');
    anchor.href = url;
    anchor.style.display = 'none';
    anchor.setAttribute('download', fullName);
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }

  takeScreenShot = (name) => {
    const img = document.getElementById('map-' + this.props.map_id);
    html2canvas(img).then(canvas => {
      let file_extension = this.state.image_extension.slice(1);
      let filename = name;
      let filter = "contrast(" + this.props.viewerSettingData.contrast + ") " +
        "brightness(" + this.props.viewerSettingData.brightness + ") " +
        "grayscale(" + this.props.viewerSettingData.grayscale + ") " +
        "saturate(" + this.props.viewerSettingData.saturation + ") " +
        "invert(" + this.props.viewerSettingData.invert + ") " +
        "hue-rotate(" + this.props.viewerSettingData.hue + "deg) ";
      let downloadableCanvas = document.createElement('canvas');
      downloadableCanvas.width = canvas.width;
      downloadableCanvas.height = canvas.height;
      let context = downloadableCanvas.getContext('2d');
      downloadableCanvas.setAttribute('crossOrigin', 'anonymous');
      context.filter = filter;
      context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
      let url = downloadableCanvas.toDataURL('image/' + file_extension, 0.97);
      this._download(url,filename);
    });
  }

  takeScreenShotForReport = (name) => {
    this.viewer.once('rendercomplete', (event) => {
      let filename = name;
      let canvasDiv = event.context.canvas;
      canvasDiv.style.filter = 
        "contrast(" + this.props.viewerSettingData.contrast + ") " +
        "brightness(" + this.props.viewerSettingData.brightness + ") " +
        "grayscale(" + this.props.viewerSettingData.grayscale + ") " +
        "saturate(" + this.props.viewerSettingData.saturation + ") " +
        "invert(" + this.props.viewerSettingData.invert + ") " +
        "hue-rotate(" + this.props.viewerSettingData.hue + "deg) ";
      let url = '/api/upload_report_image/';
      var oldHeight = canvasDiv.style.height;
      canvasDiv.style.height = "85vh";
      let base64String;
      screenshot(canvasDiv).then((url) => base64String = url);
      let canvas = base64String.toString().replace('data:image/png;base64,', '');
      let data = {
        'image_data': canvas,
        'image_path': this.state.slide_data.case.image_path_root,
        'filename': filename + ".png",
        'case_id': this.state.slide_data.case.case_id
      };
      canvasDiv.style.filter = "";
      canvasDiv.style.height = oldHeight;
      axios.post(url, data, { headers: { Authorization: AuthHeader() } })
        .then(response => {
          message.success("Added Snap to Case " + this.props.slide_data.case.case_id)
      });      
    });
    this.viewer.renderSync();
  }

  closeScreenshotMenu = () => {
    this.setState(
      {
        isScreenshotMenuOpen: false
      }
    );
  }

  openScreenshotMenu = () => {
    this.setState(
      {
        isScreenshotMenuOpen: true
      }
    );
  }


  render() {

    let isMobile = cookie.load('isMobile') === 'true';
    let iOS = cookie.load('iOS') === 'true';

    if (this.props.urlState.showGrid) {
      this.drawGrid();
    } else if (this.gridRef) {
      this.viewer.removeControl(this.gridRef);
    }

    const feature = this.vector.getSource().getFeatures();

    let showSyncBrowsingControls = [
      (this.state.syncBrowsing === true) ? (
        <Radio.Group key={0} style={{ marginLeft: "5%", marginTop: "5%" }} name='controls-text' onChange={this.changeSyncMode} value={this.state.syncMode}>
          <Radio value={0}><span className='controls-text'>Master</span></Radio>
          <Radio value={1}><span className='controls-text'>Follow</span></Radio>
        </Radio.Group>
      ) : (<div key={0}></div>)
    ];

    let filterStyle = {
      filter:
        "contrast(" + this.props.viewerSettingData.contrast + ") " +
        "brightness(" + this.props.viewerSettingData.brightness + ") " +
        "grayscale(" + this.props.viewerSettingData.grayscale + ") " +
        "saturate(" + this.props.viewerSettingData.saturation + ") " +
        "invert(" + this.props.viewerSettingData.invert + ") " +
        "hue-rotate(" + this.props.viewerSettingData.hue + "deg) ",
      height: "85vh"
    }

    var viewerSize = isMobile ? 20 : 22;
    var sidebarSize = isMobile ? 4 : 2;

    const marks = {
      500: {
        style: {
          color: 'rgba(19, 144, 228, 0.863)',
        },
        label: <strong>micro<br></br>scope</strong>,
      },
    };

    let gridSizeSlider =
      <Row>
        <Text>Size</Text>
        <Slider
          style={{ width: 400 }}
          min={2}
          max={6}
          value={this.props.urlState.gridWidth}
          onChange={this.changeGridWidth}
        />
        <Divider />
        <Text>Color</Text>
        <CirclePicker
          color={'#' + this.props.urlState.gridColor}
          circleSize={20}
          circleSpacing={5}
          onChangeComplete={this.changeGridColor}
          colors={["#e91e63", "#D6D6D6", "#3f51b5", "#03a9f4", "#4caf50", "#8BFF4A", "#ffeb3b", "#ff9800", "#607d8b"]} />
        <br></br>
        <Divider />
        <Text>Spacing</Text>
        <Slider
          style={{ width: 400 }}
          min={100}
          max={2500}
          step={100}
          dots={true}
          value={this.props.urlState.gridSize}
          onChange={this.changeGridSize}
          marks={marks}
        />
      </Row>

    var gridOptionsDiv = !this.props.urlState.showGrid ? undefined :
      <Row xs={{ span: 0, offset: 0 }} style={{ marginTop: "5%" }}>
        <p className='controls-header'>Grid Options</p>
        <Popover trigger="hover" placement="right" content={gridSizeSlider}>
          <Button style={{ padding: '0' }} className='view-sidebar grid-size'>
            <div style={{ height: 20, width: 20, backgroundColor: '#' + this.props.urlState.gridColor, borderRadius: 50, margin: "auto", marginTop: 5, marginBottom: 5 }}></div>
            <b>{this.props.urlState.gridSize}</b> <br></br>um
          </Button>
        </Popover>
      </Row>

    var gridOverlay = isMobile ? undefined
      :
      [<Divider key={0} />,
      <Row key={1}>
        <p className='controls-header'>Grid Overlay</p>
        <Switch size="small" onChange={this.toggleDrawGrid} checked={this.props.urlState.showGrid} style={{ marginLeft: "5px", marginTop: "5px" }}></Switch>
        {gridOptionsDiv}
      </Row>]

    let fileName = this.props.slide_data.name.replace('/', '-').replace(/\s+/g, '') + "-morphle-image-X" + this.props.urlState.x.toFixed(2) + "-Y" + this.props.urlState.y.toFixed(2) + "-" + this.zoomUnits[this.viewer.getView().getZoom()] + "X";
    
    let screenshotFileName = [
      <Row key="file">
        <Text>Filename: (Press Enter To Save)</Text><CloseOutlined style={{float: "right"}} onClick={this.closeScreenshotMenu} />
        <Input defaultValue={fileName} addonAfter={this.extensionSelectAfter} onPressEnter={(e) => this.takeScreenShot(e.target.value)}/>
        <Row style={{textAlign: 'center'}}>
          <Button style={{top: '10px', color: "white" }} onClick={() => this.takeScreenShot(fileName)} className='view-sidebar'>Save</Button>
        </Row>
      </Row>
    ]

    let screenshotDiv =
      <Row xs={{ span: 0, offset: 0 }} style={{ marginTop: "5%" }}>
        <Popover 
          trigger="click"
          placement="right" 
          content={screenshotFileName}
          visible={this.state.isScreenshotMenuOpen}
          onClick={this.openScreenshotMenu}>
          <Button className='view-sidebar sidebar-textbutton'>
            <CameraOutlined></CameraOutlined><br></br>
            Screen Snap
          </Button>
        </Popover>
      </Row>
    
    let previewColorLegend = () => {
      if (this.props.urlState.previewColor) {
        let colorArray = [];
        for(var i=0;i<this.state.max_zoom;i++) {
          let color = this.colorList[i].replace('0.5', '1.0')
          colorArray.push({'zoom': this.zoomUnits[i], 'color': color});
        }
        return(
          <Row style={{textAlign: 'center', marginLeft: '10px', marginTop:'5px'}}>
            {
              colorArray.map((value) => {
                return (<Row key={value['zoom']}><Tag key={value['zoom']} color={value['color']}>{value['zoom']}</Tag></Row>);
              })
            }
          </Row>
        )
      }
    };

    let addToReportDiv = this.state.slide_data.case !== ""? 
    (<Row xs={{ span: 0, offset: 0 }} style={{ marginTop: "5%" }}>
        <Button className='view-sidebar sidebar-textbutton' onClick={() => this.takeScreenShotForReport(fileName)}>
          <FilePdfOutlined></FilePdfOutlined><br></br>
          Add Snap to Case
        </Button>
    </Row>):<div></div>;

    return (
      <Row>
        <Col style={{ zIndex: 1 }} span={sidebarSize} className="viewer-controls">
          <ScanControls 
            slide_data={this.state.slide_data} 
            viewer={this.viewer} 
            map_id={"map-" + this.props.map_id} 
            sync_browsing_enabled={this.props.sync_browsing_enabled} />
          <Divider></Divider>
          {screenshotDiv}
          <Divider></Divider>
          <DrawingControls viewer={this.viewer} popup={this.popup} slide_data={this.state.slide_data} vector={this.vector} selectHover={this.selectHover} />
          {gridOverlay}
          <Divider />
          {this.props.sync_browsing_enabled ? undefined : <ZoomControls zoomUnits={this.zoomUnits} max_zoom={this.state.max_zoom} viewer={this.viewer} map_id={this.props.map_id} />}
          {addToReportDiv}
        </Col>
        <Col id={"screen-" + this.props.map_id}>
          <div>{this.state.slide_data.name}</div>
          <PopupComp fea={feature} />
          <Row>
            <Col style={filterStyle} tabIndex="0" id={"map-" + this.props.map_id} span={viewerSize} className="viewer-main-map">
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    annoData: state.annoUpdate.data,
    userId: state.loginAlerts.user_id,
    deleteId: state.annoUpdate.deleteId,
    select: state.annoUpdate.select,
    viewerSettingData: state.viewerSettingReducer,
    urlState: state.viewerUrlReducer
  };
};

export default connect(mapStateToProps)(PublicMap);
