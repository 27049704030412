import {store} from './store';
import cookie from 'react-cookies';



export const AuthHeader = () => {
    let Token ;
    if(cookie.loadAll().token !== undefined){
        Token = cookie.loadAll().token
    }
    else{
        Token =  store.getState().loginAlerts.authToken ;
    }
    
    const AuthStr = 'Token '.concat(Token);
    return AuthStr
}


const unsubscribe = store.subscribe(AuthHeader);
unsubscribe();