import React, { Component } from 'react';
import { Row, Col, Divider, Button, Progress } from 'antd';
import { connect } from "react-redux";
import "../../asset/style/scanner/scanner.css"
import { sagaMiddleware } from "../../helper/store"
import {watchPollLiveView, startLiveView, stopLiveView} from "../../action/liveview.action"

class LiveView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveView: "",
        }
    }

    componentDidMount() {
        sagaMiddleware.run(watchPollLiveView, this.props.id);
        this.props.dispatch(startLiveView());
    }

    componentWillUnmount() {
        this.props.dispatch(stopLiveView());
    }

    render() {

        return (
            <Row className="rounded-container">
                <Col span={12} offset={3}>
                    <Row><img alt="Live" className="live-view" src={"data:image/png;base64," + this.props.liveView.data} /></Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer
    };
};

export default connect(mapStateToProps)(LiveView);
