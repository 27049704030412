import React, {Component} from "react";
import AnnotationsExpandableList from "./AnnotationsExpandableList";
import {connect} from "react-redux";
import {AnnotationsConstants} from "../../../utils/const";
import {clearSelectFeatures, locateAnnotationOnMap, setActiveAllSelectInteractions} from "../utils/map_utils";
import {updateActiveAnnoDrawer} from "../../../action/gamma.state.action";
import {ihcLayer} from "../utils/layers_info";
import {
	annotationActionTooltip,
	getIHCResultComponent,
	getIHCResultFeatures,
	isAddOperation,
	OPERATIONS,
	prepareStatus,
	runIHCAnalysis,
	saveIHCCounts,
} from "../utils/ihc_app_utils";
import {Button, CircularProgress, IconButton, Stack, ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {
	AddCircleTwoTone,
	DeleteTwoTone,
	EditTwoTone,
	PlayArrow,
	RemoveCircleTwoTone,
	SaveTwoTone,
	VisibilityOff
} from "@mui/icons-material";
import {fullStitchSlide, initSlideData, updateAnnotation} from "../../../action/maps.state.action";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import {getEyeCheckbox} from "../components/components";
import {blue, red} from "../utils/annotations_app_utils";
import Polygon from "ol/geom/Polygon";
import {unByKey} from "ol/Observable";
import RefreshIcon from "@mui/icons-material/Refresh";

class IHCApp extends Component {

	constructor(props) {
		super(props);

		this.drawer = ihcLayer.drawer;
		this.drawedAnnotations = [];
		this.initState();

		this.state = {
			operation: null,
		}
	}

	initState = () => {
		this.mapState = this.props.mapsState[this.props.activeMapId];
		this.slideState = this.mapState.slideState;
		this.slidemap = this.slideState.slidemap;
		this.annotationState = this.mapState.annotationState;
		this.ihcState = this.mapState.ihcState;
	}

	componentDidMount() {
		this.props.dispatch(updateActiveAnnoDrawer(this.drawer));
		this.clickEventKey = this.slidemap.on('click', e => this.add(e.coordinate));
		this.draw();
	}

	componentWillUnmount() {
		this.props.dispatch(updateActiveAnnoDrawer(null));
		unByKey(this.clickEventKey);
		setActiveAllSelectInteractions(this.slidemap, true);
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		setActiveAllSelectInteractions(this.slidemap, !isAddOperation(this.state.operation));
	}


	add = (coordinate) => {
		if (this.state.operation === OPERATIONS.ADD_POSITIVE || this.state.operation === OPERATIONS.ADD_NEGATIVE) {
			let intersectingAnnotation = this.drawedAnnotations.find(annotation =>
				annotation.feature.getGeometry().intersectsCoordinate(coordinate));
			if (!intersectingAnnotation) return;
			let [x, y] = coordinate;
			let size = 3;
			let coordinates = [[x, y - size], [x - size, y], [x, y + size], [x + size, y], [x - size, y], [x, y - size],
				[x, y + size], [x + size, y], [x, y - size]];
			let [to, color] = this.state.operation === OPERATIONS.ADD_POSITIVE ? ['pos', blue] : ['neg', red];
			let result = intersectingAnnotation.result[to];
			let id = `${intersectingAnnotation.id}${to}${Math.random() * 1e9}`
			let feature = new Feature({
				id, color, coordinates,
				geometry: new Polygon([coordinates]),
			});
			feature.setId(id);
			result.push(coordinates);
			this.source.addFeature(feature);
			this.setState({});
		}
	}

	edit = (feature) => {
		let featureId = feature.getId();
		let coordinates = feature.getProperties().coordinates;
		let annotationId = parseInt(featureId);
		let result = this.drawedAnnotations.find(annotation => annotation.id === annotationId).result;
		let isPos = featureId.includes('pos');
		let [from, to, color] = isPos ? ['pos', 'neg', red] : ['neg', 'pos', blue];
		let id = featureId.replace(from, to);
		this.source.removeFeature(feature);
		let newFeature = new Feature({
			id,
			color,
			coordinates,
			geometry: new Polygon([coordinates]),
		});
		newFeature.setId(id);
		result[from] = result[from].filter(polygon => polygon !== coordinates);
		result[to].push(coordinates);
		this.source.addFeature(newFeature);
	}

	delete = (feature) => {
		let featureId = feature.getId();
		let coordinates = feature.getProperties().coordinates;
		let annotationId = parseInt(featureId);
		let result = this.drawedAnnotations.find(annotation => annotation.id === annotationId).result;
		let isPos = featureId.includes('pos');
		let from = isPos ? 'pos' : 'neg';
		result[from] = result[from].filter(polygon => polygon !== coordinates);
		this.source.removeFeature(feature);
	}

	save = () => this.drawedAnnotations.forEach(annotation => saveIHCCounts(annotation));

	refreshAnnotation = (annotation) =>
		this.props.dispatch(updateAnnotation(this.props.activeMapId, {id: annotation.id}))

	refreshSlide = () => this.props.dispatch(initSlideData(this.props.activeMapId, this.mapState.slideId));

	refresh = () => {
		((this.annotationState || {}).annotations || []).filter(annotation =>
			annotation.anno_drawer === this.drawer).forEach(annotation => this.refreshAnnotation(annotation));
		this.refreshSlide();
	}

	prepare = () => this.props.dispatch(fullStitchSlide(this.props.activeMapId, this.mapState.slideId));

	run = (annotation) => runIHCAnalysis(annotation).finally(() => this.refresh(annotation));

	draw = () => {
		this.source = new VectorSource({
			features: this.drawedAnnotations.map(getIHCResultFeatures).flat(),
			wrapX: false,
		});
		this.ihcState.resultVector.setSource(this.source);
	}

	onEyeCheckbox = (annotation, checked) => {
		if (checked)
			this.drawedAnnotations = [...this.drawedAnnotations.filter(annotation_ => annotation_.id !== annotation.id),
				annotation];
		else this.drawedAnnotations = this.drawedAnnotations.filter(annotation_ => annotation_.id !== annotation.id);
		this.draw();
	}

	setOperation = (event, operation) => {
		this.setState({
			operation: operation === this.state.operation ? null : operation,
		});
	}

	getIHCAnnotationAction = (annotation) => annotation.ihc_analysis.status === 'running' ?
		<CircularProgress color={"secondary"} size={20} sx={{marginX: 1}}/> : annotation.result ?
			getEyeCheckbox(undefined, false,
				e => this.onEyeCheckbox(annotation, e.target.checked), <VisibilityOff/>) :
			<Tooltip title={annotationActionTooltip[this.slideState.slide_data.full_stitch_status]}
			         placement={"top-start"}>
				<IconButton disabled={this.slideState.slide_data.full_stitch_status !== 2}
				            onClick={() => this.run(annotation)} color={"secondary"}><PlayArrow/></IconButton>
			</Tooltip>

	getTopComponent = () =>
		<Stack aria-disabled={true} direction={"column"} spacing={1}>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<Button variant={"contained"} color={"secondary"} onClick={this.prepare}
				        disabled={this.slideState.slide_data.full_stitch_status !== 0}>
					{prepareStatus[this.slideState.slide_data.full_stitch_status]}
				</Button>
				<IconButton onClick={this.refresh} color={"secondary"}><RefreshIcon/></IconButton>
			</Stack>
			<Stack direction={"row"} justifyContent={"space-between"}>
				<ToggleButtonGroup value={this.state.operation} exclusive onChange={this.setOperation}>
					<ToggleButton sx={{color: '#94b4ff'}} value={OPERATIONS.ADD_POSITIVE}>
						<Tooltip title={"Add Positive"} placement={"top-start"}>
							<AddCircleTwoTone/>
						</Tooltip>
					</ToggleButton>
					<ToggleButton sx={{color: '#fa8e8e'}} value={OPERATIONS.ADD_NEGATIVE}>
						<Tooltip title={"Add Negative"} placement={"top-start"}>
							<RemoveCircleTwoTone/>
						</Tooltip>
					</ToggleButton>
					<ToggleButton sx={{color: '#ffe294'}} value={OPERATIONS.EDIT}>
						<Tooltip title={"Alter"} placement={"top-start"}>
							<EditTwoTone/>
						</Tooltip>
					</ToggleButton>
					<ToggleButton sx={{color: '#ffe294'}} value={OPERATIONS.DELETE}>
						<Tooltip title={"Delete"} placement={"top-start"}>
							<DeleteTwoTone/>
						</Tooltip>
					</ToggleButton>
				</ToggleButtonGroup>
				<ToggleButton sx={{color: '#93edab'}} value={undefined} onClick={this.save}>
					<Tooltip title={"Save"} placement={"top-start"}>
						<SaveTwoTone/>
					</Tooltip>
				</ToggleButton>
			</Stack>
		</Stack>


	render() {
		this.initState();

		if (!this.ihcState || !(this.annotationState || {}).annotations) return <div/>;

		let annotations = this.annotationState.annotations.filter(
			annotation => annotation.anno_drawer === this.drawer);

		if (this.state.operation && this.ihcState.selectedIHCResult) {
			switch (this.state.operation) {
				case OPERATIONS.EDIT:
					this.edit(this.ihcState.selectedIHCResult);
					break;
				case OPERATIONS.DELETE:
					this.delete(this.ihcState.selectedIHCResult);
					break;
				default:
					break;
			}
			clearSelectFeatures(this.slidemap);
			this.ihcState.selectedIHCResult = null;
		}
		annotations.forEach(annotation => {
			annotation.actionComponent = this.getIHCAnnotationAction(annotation);
			annotation.resultComponent = getIHCResultComponent(annotation.result);
		});
		let annotationsComponent = <AnnotationsExpandableList annotations={annotations} slideId={this.mapState.slideId}
		                                  colors={AnnotationsConstants.ANNO_COLORS} showColorLabl={true}
		                                  onAnnotationClick={annotation =>
			                                  locateAnnotationOnMap(this.slidemap, annotation)}
		                                  actionOnSelected={annotations =>
			                                  annotations.forEach(annotation => this.run(annotation))}
		                                  actionTooltip={"Run on selected Annotations"} />;
		return <Stack direction={"column"} spacing={1}>
			{this.getTopComponent()}
			{annotationsComponent}
		</Stack>
	}
}

const mapStateToProps = (state) => ({
	activeMapId: state.gammaStateReducer.activeMapId,
	mapsState: state.mapsStateReducer,
	trigger: state.triggerReducer,
});

export default connect(mapStateToProps)(IHCApp);
