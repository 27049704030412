import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Input, Button, message, Spin, Descriptions, Divider } from 'antd';
import { ongoingMotionCameraPreviewAction, updateQuarterSize, updateStepSize, updateSteps } from '../../../action/admin.state.action';
import { intrafieldAppKey } from '../initial_setup_app_keys';

import "../../../asset/style/manualmode/intrafield_app.css";
import {CaretLeftOutlined, CaretRightOutlined} from "@ant-design/icons";

const { TextArea } = Input;

class IntrafieldApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quarterSizeInputValue: this.props.adminState.quarter_size,
            stepSizeInputValue: this.props.adminState.step_size,
            stepsInputValue: this.props.adminState.steps,
            outputMessages: [],
            tlFFTVal: '',
            trFFTVal: '',
            blFFTVal: '',
            brFFTVal: '',
            idx: 0,
            // fullFFTVal: '',
        }
        
        this.fftValueArrayLength = 11;
        
        this.fftTLValuesArray = [];
        this.fftTRValuesArray = [];
        this.fftBLValuesArray = [];
        this.fftBRValuesArray = [];
        // this.fftFullValuesArray = [];

        for(let i = 0; i < this.fftValueArrayLength; i++) {
            this.fftTLValuesArray.push(0);
            this.fftTRValuesArray.push(0);
            this.fftBLValuesArray.push(0);
            this.fftBRValuesArray.push(0);
            // this.fftFullValuesArray.push(0);
        }

        this.timer = null;

    }

    componentDidMount() {
        if(!(this.props.appState || {}).closed) {
            this.timer = setInterval(() => this.getLiveFFTVals(), 500);
        } else {
            clearInterval(this.timer);
            this.timer = null;
        }
    }

    componentDidUpdate = (prevProps) => {
        if((prevProps.appState || {}).closed !== (this.props.appState || {}).closed) {
            if(!(this.props.appState || {}).closed) {
                this.timer = setInterval(() => this.getLiveFFTVals(), 500);
            } else {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    calMedian = (arr) => {
        const mid = Math.floor(arr.length / 2);
        let nums = [...arr].sort((a, b) => parseFloat(a) - parseFloat(b));
        return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    }

    getLiveFFTVals = () => {
        let url = '/server/devices/' + this.props.currentState.deviceId + '/get_quartered_fft_vals?quarter_size=' + this.props.adminState.quarter_size;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let res = response.data;
                    this.fftTLValuesArray.shift();
                    this.fftTRValuesArray.shift();
                    this.fftBLValuesArray.shift();
                    this.fftBRValuesArray.shift();
                    // this.fftFullValuesArray.shift();

                    this.fftTLValuesArray.push(JSON.parse(res.live_fvals).fVals.tl.toFixed(2));
                    this.fftTRValuesArray.push(JSON.parse(res.live_fvals).fVals.tr.toFixed(2));
                    this.fftBLValuesArray.push(JSON.parse(res.live_fvals).fVals.bl.toFixed(2));
                    this.fftBRValuesArray.push(JSON.parse(res.live_fvals).fVals.br.toFixed(2));
                    // this.fftFullValuesArray.push(JSON.parse(res.live_fvals).fVals.full.toFixed(2));

                    this.setState({
                        tlFFTVal: this.calMedian(this.fftTLValuesArray) + " ( max " + JSON.parse(res.highest_fvals).tl.toFixed(2) + 
                                    " delta " + (JSON.parse(res.highest_fvals).blZ - JSON.parse(res.highest_fvals).tlZ).toFixed(2) + ")",
                        trFFTVal: this.calMedian(this.fftTRValuesArray) + " ( max " + JSON.parse(res.highest_fvals).tr.toFixed(2) + 
                                    " delta " + (JSON.parse(res.highest_fvals).blZ - JSON.parse(res.highest_fvals).trZ).toFixed(2) + ")",
                        blFFTVal: this.calMedian(this.fftBLValuesArray) + " ( max " + JSON.parse(res.highest_fvals).bl.toFixed(2) + 
                                    " delta " + (JSON.parse(res.highest_fvals).blZ - JSON.parse(res.highest_fvals).blZ).toFixed(2) + ")",
                        brFFTVal: this.calMedian(this.fftBRValuesArray) + " ( max " + JSON.parse(res.highest_fvals).br.toFixed(2) + 
                                    " delta " + (JSON.parse(res.highest_fvals).blZ - JSON.parse(res.highest_fvals).brZ).toFixed(2) + ")",
                        // fullFFTVal: this.calMedian(this.fftFullValuesArray) + " ( max " + JSON.parse(res.highest_fvals).full.toFixed(2) + ")",
                    });
                }
                else {
                    console.log(response);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    autoIntrafieldTest = () => {
        this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, true));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/manualmode/auto-intrafield-test?quarter_size=" +
            this.props.adminState.quarter_size + "&step_size=" + this.props.adminState.step_size;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    let outputMessage = "";
                    let deltas = response.data.deltas;
                    for(let i = 0; i < deltas.length; i++) {
                        if(i % 2 === 0) {
                            outputMessage += deltas[i][0] + ': ' + parseFloat(deltas[i][1]).toFixed(3) +
                                '\t\t\t';
                        } else {
                            outputMessage += deltas[i][0] + ': ' + parseFloat(deltas[i][1]).toFixed(3) +
                                '\n';
                        }
                    }
                    outputMessage += '\n' + response.data.plot_path;
                    this.setState({
                        outputMessages: [outputMessage, ...this.state.outputMessages],
                        idx: 0,
                    });
                }
                else {
                    console.log(response);
                    message.error("Not able to start auto intrafield test!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to start auto intrafield test!!", 2.5);
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
    }

    runIntrafieldTest = () => {
        this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, true));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/manualmode/run-intrafield-test?quarter_size=" +
            this.props.adminState.quarter_size + "&step_size=" + this.props.adminState.step_size + 
            '&steps=' + this.props.adminState.steps;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let outputMessage = "";
                    let deltas = response.data.deltas;
                    for(let i = 0; i < deltas.length; i++) {
                        if(i % 2 === 0) {
                            outputMessage += deltas[i][0] + ': ' + parseFloat(deltas[i][1]).toFixed(3) +
                                '\t\t\t';
                        } else {
                            outputMessage += deltas[i][0] + ': ' + parseFloat(deltas[i][1]).toFixed(3) +
                                '\n';
                        }
                    }
                    outputMessage += '\n' + response.data.plot_path;
                    this.setState({
                        outputMessages: [outputMessage, ...this.state.outputMessages],
                        idx: 0,
                    });
                }
                else {
                    console.log(response);
                    message.error("Not able to start run intrafield test!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to start run intrafield test!!", 2.5);
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
    }

    resetZVals = () => {
        this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, true));
        let url = `/server/devices/` + this.props.currentState.deviceId + `/manualmode/reset-intrafield-vals`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to reset Z Vals!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to reset Z Vals!!", 2.5);
                this.props.dispatch(ongoingMotionCameraPreviewAction(this.props.adminState, false));
            })
    }

    quarterSizeValueChange = (e) => {
        this.setState({
            quarterSizeInputValue: e.target.value
        })
    }

    stepSizeValueChange = (e) => {
        this.setState({
            stepSizeInputValue: e.target.value
        })
    }

    stepsValueChange = (e) => {
        this.setState({
            stepsInputValue: e.target.value
        })
    }

    setQuarterSizeValue = () => {
        this.props.dispatch(updateQuarterSize(this.props.adminState, this.state.quarterSizeInputValue));
    }

    setStepSizeValue = () => {
        this.props.dispatch(updateStepSize(this.props.adminState, this.state.stepSizeInputValue));
    }

    setStepsValue = () => {
        this.props.dispatch(updateSteps(this.props.adminState, this.state.stepsInputValue));
    }

    getIntrafieldAppInputField = (inputValue, onChangeInput) => {
        return <Input size="small" value={inputValue} onChange={onChangeInput} className="intrafield-app-input-field" />
    }

    getIntrafieldAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" type="primary" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    getIntrafieldButtonsRow = (haveRowStyle, offset, button1, button2, button3) => {
        let span = Math.floor((24 - offset) / 3);
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={offset} span={span}>
                    {button1}
                </Col>
                <Col span={span}>
                    {button2}
                </Col>
                <Col span={span}>
                    {button3}
                </Col>
            </Row>
        )
    }

    getIntrafieldInputFieldRow = (haveRowStyle, showButton, heading, inputField, button) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={6} span={5}>
                    <b>{heading}</b>
                </Col>
                <Col span={8}>
                    {inputField}
                </Col>
                {showButton ?
                    <Col span={5}>
                        {button}
                    </Col>
                    : null
                }
            </Row>
        )
    }

    render() {

        return (
            <Row className="app-parent overlayed-component intrafield-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.camera_app_action || this.props.adminState.motion_app_action 
                        || this.props.adminState.preview_app_action}>
                        <Row>
                            <Col offset={1} span={22}>
                                <div>
                                    <Descriptions size="small" column={2}>
                                        <Descriptions.Item label={<b>TL: </b>}>{this.state.tlFFTVal}</Descriptions.Item>
                                        <Descriptions.Item label={<b>TR: </b>}>{this.state.trFFTVal}</Descriptions.Item>
                                        <Descriptions.Item label={<b>BL: </b>}>{this.state.blFFTVal}</Descriptions.Item>
                                        <Descriptions.Item label={<b>BR: </b>}>{this.state.brFFTVal}</Descriptions.Item>
                                        {/* <Descriptions.Item label={<b>Full: </b>}>{this.state.fullFFTVal}</Descriptions.Item> */}
                                    </Descriptions>
                                </div>
                            </Col>
                        </Row>
                        <Divider />
                        {this.getIntrafieldButtonsRow(false, 2,
                            this.getIntrafieldAppButton('Auto Intrafield Test', this.autoIntrafieldTest),
                            this.getIntrafieldAppButton('Run Intrafield Test', this.runIntrafieldTest),
                            this.getIntrafieldAppButton('Reset Highest Z Vals', this.resetZVals)
                        )}
                        <br />
                        {this.getIntrafieldInputFieldRow(false,
                            (this.state.quarterSizeInputValue !== this.props.adminState.quarter_size),
                            'Quarter Size: ', this.getIntrafieldAppInputField(this.state.quarterSizeInputValue,
                                this.quarterSizeValueChange), this.getIntrafieldAppButton('Set', this.setQuarterSizeValue)
                        )}
                        {this.getIntrafieldInputFieldRow(true,
                            (this.state.stepSizeInputValue !== this.props.adminState.step_size), 'Step Size: ',
                            this.getIntrafieldAppInputField(this.state.stepSizeInputValue, this.stepSizeValueChange),
                            this.getIntrafieldAppButton('Set', this.setStepSizeValue)
                        )}
                        {this.getIntrafieldInputFieldRow(true,
                            (this.state.stepsInputValue !== this.props.adminState.steps), 'Steps: ',
                            this.getIntrafieldAppInputField(this.state.stepsInputValue, this.stepsValueChange),
                            this.getIntrafieldAppButton('Set', this.setStepsValue)
                        )}
                        <br />
                        {this.state.outputMessages.length > 0 ? this.getIntrafieldButtonsRow(false, 6,
                            this.getIntrafieldAppButton(<CaretLeftOutlined />, () => this.setState({
                                idx: Math.max(this.state.idx - 1, 0)
                            })),
                            this.getIntrafieldAppButton(
                                <p>{this.state.idx + 1} of {this.state.outputMessages.length}</p>, () =>
                                    this.setState({
                                        idx: 0,
                                    })
                            ),
                            this.getIntrafieldAppButton(<CaretRightOutlined />, () => this.setState({
                                idx: Math.min(this.state.idx + 1, this.state.outputMessages.length - 1)
                            })),
                        ) : null}
                        <Col offset={3} style={{width: 460}}>
                            <TextArea
                                autosize={{ minRows: 6, maxRows: 6 }}
                                value={this.state.outputMessages[this.state.idx]}
                                style={{color: 'black'}}
                                disabled
                            />
                        </Col>
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        appState: state.adminUrlReducer.app_state[intrafieldAppKey.id],
    }
}

export default connect(mapStateToProps)(IntrafieldApp);
