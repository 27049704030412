import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row, Col, Button, Icon, Divider, message} from 'antd'

import "../../asset/style/neotileviewer/tile_viewer_fixed_component.css"
import { tileViewerPlotTypes } from "../../utils/const";

class FixedComponent extends Component {

    render() {

        let XYMapPosition = "NA";

        if (this.props.imageCoordinate[0] >= 0 && this.props.imageCoordinate[0] < this.props.slideData.x_fields && 
            this.props.imageCoordinate[1] >= 0 && this.props.imageCoordinate[1] < this.props.slideData.y_fields) {
            XYMapPosition = "x" + this.props.imageCoordinate[0] + "y" + this.props.imageCoordinate[1];
        }

        let motorPos = "NA";
        if(this.props.tileViewerSlideMetaData.scan_cells.scanCellMap != undefined){
            motorPos = this.props.tileViewerSlideMetaData.scan_cells.scanCellMap[XYMapPosition] != undefined ?
            this.props.tileViewerSlideMetaData.scan_cells.scanCellMap[XYMapPosition]['position']['xPosition'] + " " + 
            this.props.tileViewerSlideMetaData.scan_cells.scanCellMap[XYMapPosition]['position']['yPosition'] : "NA";
        }

        let valuesToCheck = [
            this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS],
            this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS],
            this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS_DOWN],
            this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS_DOWN],
            this.props.plotStatus[tileViewerPlotTypes.ISLANDS]
        ];

        let heightNumber = 2;
        let countActive = 0;

        for (let i = 0; i < valuesToCheck.length; i++) {
            if (valuesToCheck[i]) {
                countActive++;
            }
        }

        heightNumber += (1.5 * countActive);
        let height = heightNumber + "em";


        return (
            <div className="app-parent overlayed-component fixed-component" style={{height: height}}>
                <Row>
                    <Col span={22} offset={1}>
                        {XYMapPosition + ", " + motorPos}
                    </Col>
                </Row>
                {this.props.plotStatus[tileViewerPlotTypes.ISLANDS] || this.props.plotStatus[tileViewerPlotTypes.RESCAN_ISLANDS] ?
                    <Row>
                        <Col span={22} offset={1}>
                            {"Island: " + this.props.islandID}
                        </Col>
                    </Row> : null
                }
                {this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS] ?
                    <Row>
                        <Col span={22} offset={1}>
                            {"Est vs Scan: " + this.props.minPlannedvsActualFUpDiff.toFixed(2) + " - " + this.props.maxPlannedvsActualFUpDiff.toFixed(2)}
                        </Col>
                    </Row> : null
                }
                {this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS] ?
                    <Row>
                        <Col span={22} offset={1}>
                            {"Est vs Best: " + this.props.minPlannedvsBestFUpDiff.toFixed(2) + " - " + this.props.maxPlannedvsBestFUpDiff.toFixed(2)}
                        </Col>
                    </Row> : null
                }
                {this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_ACTUAL_FOCUS_DOWN] ?
                    <Row>
                        <Col span={22} offset={1}>
                            {"Est vs Scan Down: " + this.props.minPlannedvsActualFDownDiff.toFixed(2) + " - " + this.props.maxPlannedvsActualFDownDiff.toFixed(2)}
                        </Col>
                    </Row> : null
                }
                {this.props.plotStatus[tileViewerPlotTypes.PLANNED_VS_GOLDEN_FOCUS_DOWN] ?
                    <Row>
                        <Col span={22} offset={1}>
                            {"Est vs Best Down: " + this.props.minPlannedvsBestFDownDiff.toFixed(2) + " - " + this.props.maxPlannedvsBestFDownDiff.toFixed(2)}
                        </Col>
                    </Row> : null
                }
                <br />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(FixedComponent);
