export const DeviceAlertConstant = {
    FETCHING_DEVICES_LIST : 'FETCHING_DEVICES_LIST',
    RECEIVED_DEVICES_LIST : 'RECEIVED_DEVICES_LIST',
    FAILED_TO_RECEIVE_DEVICES_LIST : 'FAILED_TO_RECEIVE_DEVICES_LIST',
    FETCHING_DEVICE_STATUS : 'FETCHING_DEVICE_STATUS',
    RECEIVED_DEVICE_STATUS : 'RECEIVED_DEVICE_STATUS',
    FAILED_TO_RECEIVE_DEVICE_STATUS : 'FAILED_TO_RECEIVE_DEVICE_STATUS',
    SET_DEVICE_UI_BUSY : 'SET_DEVICE_UI_BUSY',
    SET_DEVICE_UI_IDLE : 'SET_DEVICE_UI_IDLE',
    FETCHING_DEVICE_PROPERTIES : 'FETCHING_DEVICE_PROPERTIES',
    RECIEVED_DEVICE_PROPERTIES : 'RECIEVED_DEVICE_PROPERTIES',
 }