export const CaseActions = {
    ALL_CASES_UPDATE_SUCCESS: "ALL_CASES_UPDATE_SUCCESS",
    CASE_LIST_UPDATE: "CASE_LIST_UPDATE",
    CASE_LIST_UPDATE_FAILED: "CASE_LIST_UPDATE_FAILED",
    CHANGE_TOTAL_NUMBER_OF_PAGES_CASE: "CHANGE_TOTAL_NUMBER_OF_PAGES_CASE",
    CHANGE_PAGE_CASE: "CHANGE_PAGE_CASE",
    CHANGE_TOTAL_CASE: "CHANGE_TOTAL_CASE",
    UPDATE_CASE_STATUS: "UPDATE_CASE_STATUS",
    UPDATE_CASE_IDS: "UPDATE_CASE_IDS",
    UPDATE_USERS: "UPDATE_USERS",
    CASE_LIST_DELETE: "CASE_LIST_DELETE"
 }