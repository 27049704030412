import React, {Component} from "react";
import {connect} from "react-redux";
import {ClickAwayListener, Grid, Input, TextField} from "@mui/material";
import {updateSlideData} from "../../../action/maps.state.action";
import {Tooltip} from "antd";

class SlideName extends Component {

	constructor(props) {
		super(props);

		this.state = {
			edit: false,
		}
		this.maxCharacters = 20;
	}

	initState = () => {
		this.slideId = ((this.props.mapsState[this.props.mapId].slideState || {}).slide_data || {}).id || '';
		this.slideName = ((this.props.mapsState[this.props.mapId].slideState || {}).slide_data || {}).name || '';
	}

	clickAway = () => {
		this.props.dispatch(updateSlideData(this.props.mapId, this.slideId, {
			name: this.state.name || this.slideName,
		}));
		this.setState({
			edit: false
		});
	}


	render() {
		this.initState();
		let length = this.slideName.length;
		let width = Math.min(length, this.maxCharacters) * 12;

		return <Grid item xs={7} style={{fontWeight : 300}}>
			{!this.state.edit ? <Tooltip title={"Click to edit slide name"} placement={"bottom"}>
					<Grid sx={{width: width, justifyContent: 'center'}}
					      onClick={() => this.setState({edit: true})}>
						{this.slideName.slice(0, this.maxCharacters) + (length > this.maxCharacters ? '...' : '')}
					</Grid>
				</Tooltip> :
				<ClickAwayListener onClickAway={this.clickAway}>
					<TextField defaultValue={this.slideName} color='secondary' margin='normal' variant="filled"
					           size="small" onChange={e => this.setState({name: e.target.value})} autoFocus
					           onKeyDown={(e) => e.keyCode === 13 ? this.clickAway() :
						           e.stopPropagation()}
					           sx={{px: 1, width: width, backgroundColor: '#0b1c3b'}} />
				</ClickAwayListener> }
		</Grid>
	}
}

const mapStateToProps = (state) => {
	return {
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(SlideName);
