export const defaultKeyboardShortcuts = {
	zoom: {
		label: "Full Zoom in/out",
		shortcut: "Z",
	},
	inverse: {
		label: "Inverse",
		shortcut: "I",
	},
	rotate: {
		label: "Rotate +90",
		shortcut: "R",
	},
	line: {
		label: "Line",
		shortcut: 1,
	},
	freeline: {
		label: "Freeline",
		shortcut: 2,
	},
	circle: {
		label: "Circle",
		shortcut: 3,
	},
	freehand: {
		label: "Freehand",
		shortcut: 4,
	},
	rectangle: {
		label: "Rectangle",
		shortcut: 5,
	},
	polygon: {
		label: "Polygon",
		shortcut: 6,
	},
	magictool: {
		label: "MagicTool",
		shortcut: 7,
	},
};
