import React, {Component} from  "react";
import {connect} from 'react-redux';
import {updateDigitalZoomStatus} from "../../../action/maps.state.action";
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import {BottomNavigation, BottomNavigationAction, IconButton, Stack} from "@mui/material";
import {ZoomIn, ZoomOut} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

class ZoomControl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openPanel: false,
        }
    }

    initState = () => {
        this.activeMapId = this.props.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((this.props.urlState || {}).presentCode !== undefined &&
            prevProps.urlState.zoom_timestamp !== this.props.urlState.zoom_timestamp) {
            let url = `/api/get_sync_browsing_zoom/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.slideState.slide_morphle_id}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    try {
                        if(response.data.digitalZoomStatus !== undefined) {
                            let digitalZoomStatus = response.data.digitalZoomStatus === 'true';
                            let currMaxZoom = this.props.slideState.view.getMaxZoom();
                            if(!(prevProps.urlState.zoom_timestamp === -1 && !digitalZoomStatus)) {
                                if (!digitalZoomStatus) {
                                    this.props.dispatch(updateDigitalZoomStatus(
                                        this.activeMapId,
                                        digitalZoomStatus
                                    ));
                                    this.props.slideState.view.setMaxZoom(currMaxZoom - 1);
                                    this.props.slideState.view.setZoom(this.props.slideState.ZValues[this.props.slideState.ZValues.length - 2]);
                                } else {
                                    this.props.dispatch(updateDigitalZoomStatus(
                                        this.activeMapId,
                                        digitalZoomStatus
                                    ));
                                    this.props.slideState.view.setMaxZoom(currMaxZoom + 1);
                                }
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(err => {
                    console.log("Failed Getting Value");
                });
        }
    }

    toggleDigitalZoom = () => {
        let currMaxZoom = this.slideState.view.getMaxZoom();
        let digitalZoomStatus;
        if (this.mapState.digitalZoomStatus) {
            this.props.dispatch(updateDigitalZoomStatus(
                this.activeMapId,
                false
            ));

            this.slideState.view.setMaxZoom(currMaxZoom - 1);
            this.slideState.view.setZoom(this.slideState.ZValues[this.slideState.ZValues.length - 2]);
            digitalZoomStatus = false;
        } else {
            this.props.dispatch(updateDigitalZoomStatus(
                this.activeMapId,
                true
            ));
            this.slideState.view.setMaxZoom(currMaxZoom + 1);
            digitalZoomStatus = true;
        }
        if(((this.props.urlState.app_state || {})['present_app'] || {}).code !== undefined) {
            let url = `/api/set_sync_browsing_zoom/?sync_code=${this.props.urlState.app_state['present_app'].code}&morphle_id=${this.slideState.slide_morphle_id}&digitalZoomStatus=${digitalZoomStatus}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                })
                .catch(err => {
                    console.log("Failed Setting Key Value");
                });
        }
    }

    incrementZoomLevel = () => {
        let index = Math.floor(this.mapState.z);
        if(index !== this.slideState.ZValues) {
            this.slideState.view.setZoom(this.slideState.ZValues[index + 1]);
        }
    }

    decrementZoomLevel = () => {
        let index = Math.floor(this.mapState.z);
        if(index !== 0) {
            this.slideState.view.setZoom(this.slideState.ZValues[index - 1]);
        }
    }

    getIntegerZoomLevel = () => {
        let zoomFloor = Math.floor(this.mapState.z);
        // let zoomMantissa = this.mapState.z - zoomFloor;
        let zoomMantissa = 0;
        let zoomCeil = Math.ceil(this.mapState.z);
        let actualZoomValue = (this.slideState.zoomScale[zoomFloor] + (zoomMantissa * (this.slideState.zoomScale[zoomCeil] - this.slideState.zoomScale[zoomFloor])));

        if (Number.isInteger(actualZoomValue)) {
            actualZoomValue = parseInt(actualZoomValue);
        } else {
            actualZoomValue = Math.round((actualZoomValue.toPrecision(2)) * 100) / 100;
        }

        return actualZoomValue;
    }

    checkIfDigital = () => {
        let zoomFloor = parseInt(Math.floor(this.mapState.z));
        return zoomFloor >= this.slideState.zoomScale.length - 1;
    }


    render() {
        this.initState();
        if (!this.slideState) return <div />;
        let actualZoomValue = this.getIntegerZoomLevel();
        let isDigital = this.checkIfDigital();

        let showDigitalText = (Math.ceil(this.slideState.view.getZoom()) === this.slideState.view.getMaxZoom() &&
                this.mapState.z.toFixed(5) >= this.slideState.ZValues[Math.floor(this.slideState.view.getZoom())].toFixed(5))
            || (!this.mapState.digitalZoomStatus && (this.mapState.z >= this.slideState.maxZoom) );


        let zoomOverlayComponent;
        if(this.state.openPanel){
           zoomOverlayComponent = <Box sx={{height:'20vh', width:'4.3vw', border:'5px', bgcolor: "rgba(0,60,136,0.8)",
                                    display: "flex", overflowY: 'scroll'}}>
                                    <Typography sx={{width:'100%'}}>
                                        <Stack direction="column" justifyContent={"space-around"} marginTop={1} marginBottom={1} sx={{height:'18vh'}}>
                                            {/*<Button*/}
                                            {/*    color={"secondary"}*/}
                                            {/*    onClick={this.slideState.goHome}*/}
                                            {/*    disabled={(this.props.urlState || {}).presentCode !== undefined}>*/}
                                            {/*    <HomeOutlined />*/}
                                            {/*</Button>*/}
                                            {showDigitalText ?
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={this.toggleDigitalZoom}
                                                    disabled={(this.props.urlState || {}).presentCode !== undefined}
                                                    sx={{height:'5vh', fontSize:'0.8vw'}}
                                                >
                                                    <Typography sx={{fontSize:'0.8vw'}}>
                                                        {this.mapState.digitalZoomStatus ? "Disable": "Enable"}
                                                        <br/>
                                                        Digital
                                                        <br/>
                                                        Zoom
                                                    </Typography>
                                                </IconButton> :
                                                <IconButton
                                                    color={"secondary"}
                                                    onClick={this.incrementZoomLevel}
                                                    disabled={(this.props.urlState || {}).presentCode !== undefined}
                                                    sx={{height:'5vh'}}
                                                >
                                                    <ZoomIn fontSize="large"/>
                                                </IconButton>
                                            }
                                            <IconButton
                                                color={"secondary"}
                                                onClick={this.decrementZoomLevel}
                                                disabled={(this.props.urlState || {}).presentCode !== undefined}
                                                sx={{height:'5vh'}}
                                            >
                                                <ZoomOut fontSize="large"/>
                                            </IconButton>
                                        </Stack>
                                    </Typography>
                                </Box>
        }

        let textColor ;
        textColor = isDigital ? "#ed6111" : this.state.openPanel ? "secondary" : "white";

        return(
            <Stack direction="column-reverse" justifyContent={"flex-end"} >
                <BottomNavigation sx={{borderRadius:0, bgcolor:"rgba(0,60,136,0.8)"}} value={this.state.app}
                                  onChange={() => this.setState({openPanel : !this.state.openPanel,})}>
                    <BottomNavigationAction icon={<>
                        <Typography variant={'h5'} align={"center"} gutterBottom={!isDigital}
                                    color={textColor}>
                            {actualZoomValue}X
                        </Typography>
                        {isDigital ?
                            <Typography marginBottom={1} variant="caption" align={"center"} color={textColor} >
                                Digital
                            </Typography> : null}
                    </>} />
                </BottomNavigation>
                {zoomOverlayComponent}
            </Stack>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(ZoomControl);
