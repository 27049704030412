import React, {Component} from  "react";
import { connect } from "react-redux";
import { updateAppClosedStatus } from '../../action/morpheus.state.action'
import { updateAppClosedStatus as updateTileViewerAppClosedStatus } from '../../action/tile.viewer.state.action'
import {Icon, Tooltip} from 'antd'
import { CloseCircleTwoTone } from '@ant-design/icons';
import * as keys from '../neoviewer/apps/app_keys'
import cookie from 'react-cookies';
import "../../asset/style/utils/app.css"
import { slideViewerType } from "../../utils/const";

class AppIcon extends Component {
    constructor(props) {
        super(props);

        this.openable = this.props.openable === undefined ? true : this.props.openable;
        this.isMobile = cookie.loadAll().isMobile === 'true';
    }

    toggleShowApp = (e) => {

        let closed = this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
            !((this.props.appState[this.props.appKey.id] || {}).closed) : !((this.props.tileViewerAppState[this.props.appKey.id] || {}).closed);
        
        if (!closed && this.isMobile) {
            for (let app in keys) {
                if (keys[app].id != this.props.appKey.id) {
                    if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
                        this.props.dispatch(updateAppClosedStatus(keys[app].id, true, this.props.urlState));
                    } else {
                        this.props.dispatch(updateTileViewerAppClosedStatus(keys[app].id, true, this.props.tileViewerUrlState));
                    }
                }
            }
        }
        if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
            this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, closed, this.props.urlState));
        } else {
            this.props.dispatch(updateTileViewerAppClosedStatus(this.props.appKey.id, closed, this.props.tileViewerUrlState));
        }
        e.stopPropagation();
    }

    closeApp = (e) => {
        if (slideViewerType.NORMAL_SLIDE_VIEWER == this.props.viewerType) {
            this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, true, this.props.urlState));
        } else {
            this.props.dispatch(updateTileViewerAppClosedStatus(this.props.appKey.id, true, this.props.tileViewerUrlState));
        }
        e.stopPropagation();
    }

    render() {

        let closeButton = undefined;

        let closed = this.props.viewerType == slideViewerType.NORMAL_SLIDE_VIEWER ? 
            (this.props.appState[this.props.appKey.id] || {}).closed : (this.props.tileViewerAppState[this.props.appKey.id] || {}).closed;

        if (!closed) {
            closeButton = <div 
                            className="app-icon-closeable-handle" 
                            onClick={this.closeApp}>
                            <Tooltip 
                                title="Click to Close"
                                placement="right"
                                overlayStyle={{
                                    fontSize:"10px"
                                }}>
                                <CloseCircleTwoTone className="app-icon-close" twoToneColor="#de2323" />   
                            </Tooltip> 
                        </div>
        }

        let allowedAppsInScanShare = (this.props.appKey.id != keys.presentAppKey.id && 
                                        this.props.appKey.id != keys.displaySizeCalibrationKey.id &&
                                        this.props.appKey.id != keys.reportAppKey.id &&
                                        this.props.appKey.id != keys.capGuideAppKey.id &&
                                        this.props.appKey.id != keys.ihcAppKey.id &&
                                        this.props.appKey.id != "all_slides" &&
                                        this.props.appKey.id != "cases"
                                        );

        return (
            <div 
                className={"app-icon " + (closed || !this.openable ? "" : " app-icon-open")} 
                onClick={this.openable && !((this.props.urlState || {}).presentCode != undefined && allowedAppsInScanShare) ? this.toggleShowApp : () => {}}
                style={(this.props.urlState || {}).presentCode != undefined && allowedAppsInScanShare ? {cursor: 'not-allowed'} : {}}>
                {this.openable && !((this.props.urlState || {}).presentCode != undefined && allowedAppsInScanShare) ? closeButton : undefined}
                <div>
                    {this.props.content}
                </div>
                <div className="app-icon-text unselectable">
                    {this.props.appKey.title}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
      appState: state.viewerUrlReducer.app_state,
      urlState: state.viewerUrlReducer,
      tileViewerAppState: state.tileViewerUrlReducer.tile_viewer_app_state,
      tileViewerUrlState: state.tileViewerUrlReducer
  };
};

export default connect(mapStateToProps)(AppIcon);