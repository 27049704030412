import axios from 'axios';
import { delay, call} from 'redux-saga/effects';
import { SessionConstant } from '../actionTypes/session.constant';
import { AuthHeader } from "../helper/auth.token";
import cookie from "react-cookies";

function* sessionValidity(dispatch) {
    while (cookie.load('token')) {
        try {
            yield call(() => dispatch(checkSessionValidity()));
            yield delay(1000);
        } catch (err) {
            console.log(err);
        }
    }
}

export const watchSessionValidity = function* (dispatch) {
    while (cookie.load('token')) {
        yield call(sessionValidity, dispatch);
    }
};

export const sessionValidityCheckInitiated = (type) => ({
    type: type,
})

export const receivedSessionValid = (type, response) => ({
    type: type,
    valid: response.data.valid
});

export const sessionValidityCheckFailed = (type, err) => ({
    type: type,
    err: err
})


export const checkSessionValidity = () => (dispatch) => {
    dispatch(sessionValidityCheckInitiated(SessionConstant.SESSION_VALIDITY_CHECK_INITIATED));
    let url = "/server/check_session_validity";

    axios.get(url, {
            headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' }
        }).then(response => {
            if (response.status === 200) {
                dispatch(receivedSessionValid(SessionConstant.SESSION_VALIDITY_STATUS_RECEIVED, response));
            } else {
                dispatch(sessionValidityCheckFailed(SessionConstant.SESSION_VALIDITY_CHECK_FAILED, response));
            }
        })
        .catch(err => {
            dispatch(sessionValidityCheckFailed(SessionConstant.SESSION_VALIDITY_CHECK_FAILED, err));
            console.log(err);
        })
}
