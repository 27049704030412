import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row, Col, Button, Icon} from 'antd'
import { updateDigitalZoomStatus } from "../../../action/tile.viewer.state.action"

import "../../../asset/style/neoviewer/zoom_controls.css"

class ZoomControls extends Component {

    toggleDigitalZoom = () => {
        if (this.props.urlState.digitalZoomStatus) {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.urlState, 
                false
            ));
            this.props.view.setMaxZoom(this.props.maxZoom - 1);
        } else {
            this.props.dispatch(updateDigitalZoomStatus(
                this.props.urlState, 
                true
            ));
            this.props.view.setMaxZoom(this.props.maxZoom);
        }
    }

    incrementZoomLevel = () => {
        this.props.view.setZoom(Math.min(this.props.maxZoom, parseInt(Math.floor(this.props.urlState.z)) + 1));
    }

    decrementZoomLevel = () => {
        this.props.view.setZoom(Math.max(0, parseInt(Math.floor(this.props.urlState.z)) - 1));
    }

    getIntegerZoomLevel = () => {
        let zoomFloor = parseInt(Math.floor(this.props.urlState.z));
        let zoomMantissa = this.props.urlState.z - zoomFloor;
        let zoomCeil = parseInt(Math.ceil(this.props.urlState.z));
        let actualZoomValue = (this.props.zoomScale[zoomFloor] + (zoomMantissa * (this.props.zoomScale[zoomCeil] - this.props.zoomScale[zoomFloor])));
        
        if (Number.isInteger(actualZoomValue)) {
            actualZoomValue = parseInt(actualZoomValue);
        } else {
            actualZoomValue = Math.round(actualZoomValue * 100) / 100;
        }

        return actualZoomValue;
    }

    checkIfDigital = () => {
        let zoomFloor = parseInt(Math.floor(this.props.urlState.z));
        return zoomFloor == this.props.zoomScale.length - 1;
    }

    render(){
        let actualZoomValue = this.getIntegerZoomLevel();
        let isDigital = this.checkIfDigital();

        return (
            <div className="app-parent overlayed-component">
                <Row>
                    <Button 
                        className="zoom-controls-button"
                        onClick={this.props.goHome}>
                        <Icon className="zoom-controls-icon" type="home" />
                    </Button>
                </Row>
                <Row>
                {(this.props.view.getZoom() == this.props.view.getMaxZoom()) || (!this.props.urlState.digitalZoomStatus && (this.props.urlState.z >= this.props.max_zoom) ) ? 
                    <Button 
                        className={"zoom-controls-button digizoom-button"}
                        onClick={this.toggleDigitalZoom}
                        >   
                        <span>{this.props.urlState.digitalZoomStatus ? "Disable": "Enable"}<br></br>Digital<br></br>Zoom</span>
                    </Button> : 
                    <Button 
                        className={"zoom-controls-button plus-button"}
                        onClick={this.incrementZoomLevel}
                        >
                        <Icon className="zoom-controls-icon" type="plus" />
                    </Button>
                    }
                </Row>
                <Row>
                    <Col className="zoom-controls-button unselectable zoom-level-button">
                        <Row style={isDigital ? {lineHeight:"1.5em"} : {}}>{actualZoomValue}X</Row>
                        {isDigital ? <Row style={{fontSize:10, lineHeight:"1.5em"}}>Digital</Row> : undefined}
                    </Col>
                </Row>
                <Row>
                    <Button 
                        className="zoom-controls-button minus-button"
                        onClick={this.decrementZoomLevel}
                        ><Icon className="zoom-controls-icon" type="minus" /></Button>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.tileViewerUrlReducer,
    }
}

export default connect(mapStateToProps)(ZoomControls);
