import '@ant-design/compatible/assets/index.css';
import Icon, { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import { Button, Col, Input, List, Row, Spin } from 'antd';
import axios from 'axios';
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { setUIBusy, setUIIdle } from "../action/utils.action";
import { AuthHeader } from "../helper/auth.token";
import { globalUrlPrefix } from "../utils/const";

const { TextArea } = Input;

class CategoryView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: undefined, 
            showAddForm: false, 
            askedToDelete: {}
        }
    }

    getCategories = value => {
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/casecategory/`;
        axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.setState({
                categories : response.data
            })
            this.props.dispatch(setUIIdle());
        })
        .catch(err => {
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }
  
    componentDidMount = () => {
        console.log("here");
      this.getCategories();
    }
  
    addCategory = () => {
        let caseName = document.getElementById("category-name").value;
        let caseDesc = document.getElementById("category-description").value;
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/casecategory/`;
        let value = {
            name: caseName,
            description: caseDesc,
            owner: cookie.loadAll().user_id
        };
        axios
        .post(url, value, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.setState(
                Object.assign(
                    {}, 
                    this.state, 
                    {
                        categories: [...this.state.categories, response.data], 
                        showAddForm: false
                    }
                )
            );
            this.props.dispatch(setUIIdle());
        })
        .catch(err => {
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }

    askedToDelete = (id) => {
        var newAskedToDelete = Object.assign({}, this.state.askedToDelete);
        if (this.state.askedToDelete[id] != undefined) {
            delete newAskedToDelete[id];
        } else {
            newAskedToDelete[id] = true;
        }
        this.setState(
            Object.assign(
                {}, 
                this.state, 
                {
                    askedToDelete: newAskedToDelete
                }
            )
        );
    }

    deleteCategory = (id) => {
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/casecategory/` + id;
        axios
        .delete(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.askedToDelete(id);
            this.getCategories();
        })
        .catch(err => {
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }

    deleteAction = (id) => {
        return this.state.askedToDelete[id] !== undefined ? 
        [<span>Confirm Delete</span>, <a onClick={() => this.deleteCategory(id)}>Yes</a>, <a onClick={() => this.askedToDelete(id)}>No</a>]
        :
        [<DeleteOutlined style={{fontSize:"1.3em"}} onClick={() => this.askedToDelete(id)}></DeleteOutlined>];
    }

    render() {

        let actionButton = this.state.showAddForm ? 
        [<Row className="add-to-list-icon" key={0}>
            <Icon type="close">
            </Icon>
        </Row>, 
        <Row className="add-to-list-text" key={1}>
            Cancel
        </Row>]
        :
        [<Row style={{ cursor: 'pointer' }} className="add-to-list-icon" key={0}>
            <Icon type="plus">
            </Icon>
        </Row>, 
        <Row className="add-to-list-text" key={1}>
            Add Category
        </Row>];

        var addToList = 
        <Row>
            <Col span={24} className="generic-list-item">
                <Row onClick={() => this.setState(Object.assign({}, this.state, {showAddForm : !this.state.showAddForm}))}>
                    {actionButton}
                </Row>
                <Row>
                    <Col span={12} offset={6} style={{marginTop:10}}>
                        <Form onSubmit={this.handleSubmit} className="add-list-item-form" style={this.state.showAddForm ? {}:{display: "none"}}>
                                <Form.Item>
                                    <Input
                                        id="category-name"
                                        placeholder="Name"
                                    />
                                </Form.Item>
                                <Form.Item>
                                    <TextArea
                                        id="category-description"
                                        placeholder="Description"
                                        autosize={{ minRows: 2, maxRows: 6 }}
                                    />
                                </Form.Item>
                                <Form.Item>
                                <Button type="primary" htmlType="submit" className="generic-submit-button" onClick={this.addCategory}>
                                    Submit
                                </Button>
                                </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Col>
        </Row>

        return (  
            <Spin spinning={this.props.uiBusy} tip={this.props.busyMessage} size="large">
                <Row className="scanner-block">
                    <Col className="header-style-1 arial-font" span={24}>
                        Categories
                    </Col>
                </Row>  
                <Row className="scanner-block">
                    <Col span={24}>
                        <List
                            header={addToList}
                            itemLayout="horizontal"
                            dataSource={this.state.categories}
                            renderItem={item => (
                                <List.Item className="generic-list-item" actions={this.deleteAction(item.id)}>
                                <Link to={"/" + globalUrlPrefix + "/category/" + item.id + "/"} className="list-item-link">
                                    <List.Item.Meta
                                    title={<p>{item.name}</p>}
                                    description={item.description}
                                    />
                                </Link>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        uiBusy: state.utilsReducer.uiBusy,
        busyMessage: state.utilsReducer.busyMessage
    };
};

export default connect(mapStateToProps)(CategoryView);