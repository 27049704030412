import { hsvToRgb } from '../../utils/utils';
import { drawRectangle, drawTriangle, drawLine } from './tile_viewer_map_utils'
import { StitchDirection } from '../../utils/const';
import { getLRArrowBodyBoundsTileMotion, getLRArrowHeadBoundsTileMotion, getUDArrowBodyBoundsTileMotion,
    getUDArrowHeadBoundsTileMotion, getInvertedColor, drawArrowInDirection, getArrowDirection, 
    getTileCenterBounds, getNorthBounds, getWestBounds, 
    plotDisagreementv2Unit, plotDisagreementTextv2Unit } from './stitching_helpers'

/*
 *
 * DISAGREEMENT PLOTS (before and after)
 * 
 */
const plotDisagreementsHelper = (stitchResultv2, slideData, vectorSource) => {
    const size = 3;
    const margin = size / 10;
    const sizeOfMarker = 0.2;

    let disagreementL2NormMax = 0;
    let disagreementL2NormMin = 100000000; // some large number
    for(const key in stitchResultv2) {
        // sample key is "x9y32.jpg"
        const disagreement = stitchResultv2[key];
        const bothComputedDeltaZero = disagreement["computedTop"]["tx"] === 0 
                                      && disagreement["computedTop"]["ty"] === 0;
        if (disagreement["noActualTopTxTy"] != undefined && disagreement["noActualTopTxTy"] === false
            && !bothComputedDeltaZero) {
            const disagreementTop = disagreement["disagreementTop"];
            const deltaTxSq = disagreementTop["deltaTx"]*disagreementTop["deltaTx"];
            const deltaTySq = disagreementTop["deltaTy"]*disagreementTop["deltaTy"];
            const l2norm = Math.sqrt(deltaTxSq + deltaTySq);
            if (l2norm < 1000 && l2norm > disagreementL2NormMax) {
                console.log("new max top of ", l2norm, key, disagreement);
                disagreementL2NormMax = l2norm;
            }
            if (l2norm < 1000 && l2norm < disagreementL2NormMin) {
                disagreementL2NormMin = l2norm;
            }
        }
        const bothComputedDeltaZero2 = disagreement["computedLeft"]["tx"] === 0 
                                       && disagreement["computedLeft"]["ty"] === 0;
        if (disagreement["noActualLeftTxTy"] != undefined && disagreement["noActualLeftTxTy"] === false
            && !bothComputedDeltaZero2) {
            const disagreementLeft = disagreement["disagreementLeft"];
            const deltaTxSq = disagreementLeft["deltaTx"]*disagreementLeft["deltaTx"];
            const deltaTySq = disagreementLeft["deltaTy"]*disagreementLeft["deltaTy"];
            const l2norm = Math.sqrt(deltaTxSq + deltaTySq);
            if (l2norm < 1000 && l2norm > disagreementL2NormMax) {
                console.log("new max left of ", l2norm, key, disagreement);
                disagreementL2NormMax = l2norm;
            }
            if (l2norm < 1000 && l2norm < disagreementL2NormMin) {
                disagreementL2NormMin = l2norm;
            }
        }
    }

    for(const key in stitchResultv2) {
        // sample key is "x9y32.jpg"
        const xHori = parseInt(key.split('y')[0].split('x')[1]);
        const yVerti = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0]);
        const disagreement = stitchResultv2[key];
        plotDisagreementv2Unit(disagreement, disagreementL2NormMax, disagreementL2NormMin,
            slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource);
        plotDisagreementTextv2Unit(disagreement, slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource);
    }
}
export const plotStitchPreRound0Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_pre_round0_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPreRound1Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_pre_round1_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPreRound2Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_pre_round2_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPreRound3Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_pre_round3_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}

export const plotStitchPostRound0Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_round0_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPostRound1Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_round1_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPostRound2Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_round2_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}
export const plotStitchPostRound3Disagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_round3_v2.result;
    plotDisagreementsHelper(stitchResultv2, slideData, vectorSource);
}


/*
 *
 * TILE MOTION plots (tiles moved by diffusion)
 * 
 */
const plotTileMotionHelper = (tileMotionList, slideData, vectorSource) => {
    console.log(tileMotionList);
    if (tileMotionList != undefined 
        && (tileMotionList.length == 0 || Object.keys(tileMotionList).length == 0)) {
            return;
        }

    const tile_width_microns = slideData.tile_width * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;
    const ccColor = `rgba(255,255,255,0.8)`;

    console.debug("Legend: xmotion +ve means left motion, -ve means right motion");
    console.debug("Legend: ymotion +ve means up motion, -ve means down motion");
    for (const tileMotion of tileMotionList) {
        const xMotion = tileMotion["xmotion"];
        const yMotion = tileMotion["ymotion"];
        const xHori = tileMotion["fieldPosition"]["col"];
        let yVerti = tileMotion["fieldPosition"]["row"];
        console.debug(`tile at x${xHori}y${yVerti} moved by xmotion:${xMotion} ymotion:${yMotion}`);
        yVerti = slideData.y_fields - yVerti - 1;

        const size = 0.5;
        const margin = size / 10;
        const sizeOfArrow = 0.35;
        let stroke = 1;
        
        if (Math.abs(xMotion) > 0) {
            const direction = xMotion > 0 ? StitchDirection.LEFT : StitchDirection.RIGHT;
            const arrowBodyBounds = getLRArrowBodyBoundsTileMotion(xHori, yVerti, sizeOfArrow, margin, tile_width_microns, tile_height_microns, direction);
            // DRAW
            drawRectangle(arrowBodyBounds, vectorSource, true, stroke, ccColor);
            let arrowHeadBounds = getLRArrowHeadBoundsTileMotion(xHori, yVerti, 0.45, 3*margin, tile_width_microns, tile_height_microns, direction);
            // DRAW
            drawTriangle(arrowHeadBounds, vectorSource, true, 2, ccColor);
            // Write xMotion value
            let xShift = 0.125;
            if (xMotion < 0) { xShift = 0.875; }
            drawLine((xHori + xShift) * tile_width_microns,  // x
                (yVerti+0.5125) * tile_height_microns,           // y
                1,                                   // length
                vectorSource,                        // vectorSource
                Math.abs(xMotion).toString(),        // title
                1,                                   // strokeWidth
                "#00d775"                            // color
            );
        }
        if (Math.abs(yMotion) > 0) {
            const direction = yMotion > 0 ? StitchDirection.UP : StitchDirection.DOWN;
            const arrowBodyBounds = getUDArrowBodyBoundsTileMotion(xHori, yVerti, sizeOfArrow, margin, tile_width_microns, tile_height_microns, direction);
            // DRAW
            drawRectangle(arrowBodyBounds, vectorSource, true, stroke, ccColor);
            let arrowHeadBounds = getUDArrowHeadBoundsTileMotion(xHori, yVerti, 0.075, 3*margin, tile_width_microns, tile_height_microns, direction);
            // DRAW
            drawTriangle(arrowHeadBounds, vectorSource, true, 2, ccColor);
            // Write yMotion value
            let yShift = 0.9;
            if (yMotion < 0) { yShift = 0.125; }
            drawLine((xHori + 0.5) * tile_width_microns,  // x
                (yVerti+yShift) * tile_height_microns,           // y
                1,                                   // length
                vectorSource,                        // vectorSource
                Math.abs(yMotion).toString(),        // title
                1,                                   // strokeWidth
                "#00d775"                            // color
            );
        }
    }
}
export const plotStitchRound0TileMotionv2 = (slideMetaData, slideData, vectorSource) => {
    // FieldPosition is dict{col: int, row: int}
    // TileMotion is dict{fieldPosition: FieldPosition, xmotion: int, ymotion: int}
    const tileMotionList = slideMetaData.stitch_tilemotion_round0_v2; // array of TileMotion
    plotTileMotionHelper(tileMotionList, slideData, vectorSource);
}
export const plotStitchRound1TileMotionv2 = (slideMetaData, slideData, vectorSource) => {
    // FieldPosition is dict{col: int, row: int}
    // TileMotion is dict{fieldPosition: FieldPosition, xmotion: int, ymotion: int}
    const tileMotionList = slideMetaData.stitch_tilemotion_round1_v2; // array of TileMotion
    plotTileMotionHelper(tileMotionList, slideData, vectorSource);
}
export const plotStitchRound2TileMotionv2 = (slideMetaData, slideData, vectorSource) => {
    // FieldPosition is dict{col: int, row: int}
    // TileMotion is dict{fieldPosition: FieldPosition, xmotion: int, ymotion: int}
    const tileMotionList = slideMetaData.stitch_tilemotion_round2_v2; // array of TileMotion
    plotTileMotionHelper(tileMotionList, slideData, vectorSource);
}
export const plotStitchRound3TileMotionv2 = (slideMetaData, slideData, vectorSource) => {
    // FieldPosition is dict{col: int, row: int}
    // TileMotion is dict{fieldPosition: FieldPosition, xmotion: int, ymotion: int}
    const tileMotionList = slideMetaData.stitch_tilemotion_round3_v2; // array of TileMotion
    plotTileMotionHelper(tileMotionList, slideData, vectorSource);
}


/*
 *
 * STITCH GRAPHS (for each round)
 * 
 */
const plotStitchGraphHelper = (allPaths, slideData, vectorSource) => {
    const tile_width_microns = slideData.tile_width * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;

    if (allPaths != undefined 
        && (allPaths.length == 0 || Object.keys(allPaths).length == 0)) {
        return;
    }

    // draw arrows from source -> target for `entirePath`
    const tileCounts = allPaths.map(function(ccPath) {return 1+ccPath.length;})
                               .filter(function(tileCount) {return tileCount > 1;});
    let maxTileCount2 = Math.max(...tileCounts);
    let minTileCount2 = Math.min(...tileCounts);
    for (const ccPath of allPaths) {
        const tileCount = 1 + ccPath.length;
        const colorScore = 180 - Math.floor(180 * ((tileCount - minTileCount2) 
                                                    / (maxTileCount2 - minTileCount2)));
        const {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        const ccColor = `rgba(${r},${g},${b},0.4)`;
        
        for (const traversalUnit of ccPath) {
            const xHori = traversalUnit["source"]["col"];
            let yVerti = traversalUnit["source"]["row"];
            yVerti = slideData.y_fields - yVerti - 1;
            const direction = getArrowDirection(traversalUnit);
            // DRAW
            drawArrowInDirection(xHori, yVerti, tile_width_microns, tile_height_microns, 
                direction, vectorSource, ccColor);
        }
    }
}
const plotStitchGraphStartPositions = (ccReducedMetas, slideData, vectorSource) => {
    const tile_width_microns = slideData.tile_width * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;
    if (ccReducedMetas != undefined 
        && (ccReducedMetas.length == 0 || Object.keys(ccReducedMetas).length == 0)) {
        return;
    }

    const tileCounts1 = ccReducedMetas.map(function(ccMeta) {return ccMeta["tileCount"];})
                                      .filter(function(tileCount) {return tileCount > 1;});
    let maxTileCount = Math.max(...tileCounts1);
    let minTileCount = Math.min(...tileCounts1);
    // draw squares at all `ccReducedMetas`
    for (const ccMeta of ccReducedMetas) {
        const position = ccMeta["ccStartPos"];
        const tileCount = ccMeta["tileCount"];
        const colorScore = 180 - Math.floor(180 * ((tileCount - minTileCount) / (maxTileCount - minTileCount)));
        const {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        let opacity = 0.7;
        if(tileCount < 2) {
            opacity = 0.1;
        }
        const ccColor = `rgba(${r},${g},${b},${opacity})`;

        const xHori = position["col"];
        let yVerti = position["row"];
        yVerti = slideData.y_fields - yVerti - 1;

        const size = 5;
        const margin = size / 10;
        const sizeOfMarker = 0.25;
        let stroke = 1;
        const centerBounds = getTileCenterBounds(xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns);
        drawRectangle(centerBounds, vectorSource, true, stroke, ccColor);
    }
}
export const plotStitchRound0Graphv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    // array of array of dict{"source": FieldPosition, "target": FieldPosition}
    const allPaths = slideMetaData.stitch_path_round0_v2;

    // type FieldPosition is dict{"col" : int, "row" : int}
    const ccReducedMetas = slideMetaData.stitch_start_pos_round0_v2; // array of dict{"ccStartPos": FieldPosition, "tileCount": int}

    plotStitchGraphStartPositions(ccReducedMetas, slideData, vectorSource);

    plotStitchGraphHelper(allPaths, slideData, vectorSource);
}
export const plotStitchRound1Graphv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    // array of array of dict{"source": FieldPosition, "target": FieldPosition}
    const allPaths = slideMetaData.stitch_path_round1_v2;

    // type FieldPosition is dict{"col" : int, "row" : int}
    const ccReducedMetas = slideMetaData.stitch_start_pos_round1_v2; // array of dict{"ccStartPos": FieldPosition, "tileCount": int}

    plotStitchGraphStartPositions(ccReducedMetas, slideData, vectorSource);

    plotStitchGraphHelper(allPaths, slideData, vectorSource);
}
export const plotStitchRound2Graphv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    // array of array of dict{"source": FieldPosition, "target": FieldPosition}
    const allPaths = slideMetaData.stitch_path_round2_v2;

    // type FieldPosition is dict{"col" : int, "row" : int}
    const ccReducedMetas = slideMetaData.stitch_start_pos_round2_v2; // array of dict{"ccStartPos": FieldPosition, "tileCount": int}

    plotStitchGraphStartPositions(ccReducedMetas, slideData, vectorSource);

    plotStitchGraphHelper(allPaths, slideData, vectorSource);
}
export const plotStitchRound3Graphv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    // array of array of dict{"source": FieldPosition, "target": FieldPosition}
    const allPaths = slideMetaData.stitch_path_round3_v2;

    // type FieldPosition is dict{"col" : int, "row" : int}
    const ccReducedMetas = slideMetaData.stitch_start_pos_round3_v2; // array of dict{"ccStartPos": FieldPosition, "tileCount": int}

    plotStitchGraphStartPositions(ccReducedMetas, slideData, vectorSource);

    plotStitchGraphHelper(allPaths, slideData, vectorSource);
}


export const plotStitchGraphTextv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    // array of dict{"ccStartPos": FieldPosition, "tileCount": int}
    const ccReducedMetas = slideMetaData.stitch_start_positions_v2;
    // sample tileKey is "x20y13.jpg"
    const doubtLevels = slideMetaData.stitch_doubt_map_v2; // dict{tileKey: dict{"top": int, "left": int}}

    if (ccReducedMetas != undefined 
        && (ccReducedMetas.length == 0 || Object.keys(ccReducedMetas).length == 0)) {
        return;
    }
    if (doubtLevels != undefined 
        && (doubtLevels.length == 0 || Object.keys(doubtLevels).length == 0)) {
        return;
    }

    const tile_width_microns = slideData.tile_width * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;

    const doubtLevelsList = Object.keys(doubtLevels).map(function(key){return doubtLevels[key];});
    const topDoubtVals = doubtLevelsList.map(function(tileDoubt) {return tileDoubt["top"];});
    const leftDoubtVals = doubtLevelsList.map(function(tileDoubt) {return tileDoubt["left"];});
    const allDoubtVals = topDoubtVals.concat(leftDoubtVals);
    let maxDoubtLevel = Math.max(...allDoubtVals);
    let minDoubtLevel = Math.min(...allDoubtVals);

    const size = 3;
    const margin = size / 10;
    const sizeOfMarker = 0.2;
    const stroke = 1;
    let color = `rgba(200,200,200,0.1)`;
    let txtColor = `rgba(10,10,10,0.5)`;
    for (const [tileKey, doubt] of Object.entries(doubtLevels)) {
        const xHori = parseInt(tileKey.split('y')[0].split('x')[1]);
        const yVerti = parseInt(tileKey.split('y')[1].split('.' + slideData.img_type)[0]);
        yVerti = slideData.y_fields - yVerti - 1;

        color = getInvertedColor(maxDoubtLevel, minDoubtLevel, doubt["top"]);
        // NORTH MARKERS
        const northBounds = getNorthBounds(xHori, yVerti, sizeOfMarker, margin, 
            tile_width_microns, tile_height_microns);
        drawRectangle(northBounds, vectorSource, true, stroke, color);
        drawLine((xHori + 0.5) * tile_width_microns,  // x
            (yVerti + 1) * tile_height_microns,       // y
            1,                                   // length
            vectorSource,                        // vectorSource
            "lvl="+doubt["top"],                 // title
            1,                                   // strokeWidth
            txtColor                             // color
        );

        color = getInvertedColor(maxDoubtLevel, minDoubtLevel, doubt["left"]);
        // WEST MARKERS
        const westBounds = getWestBounds(xHori, yVerti, sizeOfMarker, margin, 
            tile_width_microns, tile_height_microns);
        drawRectangle(westBounds, vectorSource, true, stroke, color);
        drawLine(xHori * tile_width_microns,         // x
            (yVerti + 0.5) * tile_height_microns,   // y
            1,                                 // length
            vectorSource,                      // vectorSource
            "lvl="+doubt["left"],              // title
            0.25,                              // strokeWidth
            txtColor                           // color
        );
    }      
    
    const tileCounts1 = ccReducedMetas.map(function(ccMeta) {return ccMeta["tileCount"];})
                                      .filter(function(tileCount) {return tileCount > 1;});
    let maxTileCount = Math.max(...tileCounts1);
    let minTileCount = Math.min(...tileCounts1);
    // draw squares at all `ccReducedMetas`
    for (const ccMeta of ccReducedMetas) {
        const position = ccMeta["ccStartPos"];
        const tileCount = ccMeta["tileCount"];
        const colorScore = 180 - Math.floor(180 * ((maxTileCount - tileCount) / (maxTileCount - minTileCount)));
        const {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        const txtColor = `rgb(${r},${g},${b})`;

        const xHori = position["col"];
        let yVerti = position["row"];
        yVerti = slideData.y_fields - yVerti - 1;

        if(tileCount > 1) {
            drawLine((xHori + 0.5) * tile_width_microns,  // x
                (yVerti + 0.5) * tile_height_microns,       // y
                1,                                   // length
                vectorSource,                        // vectorSource
                "tile=" + tileCount.toString(),      // title
                1,                                   // strokeWidth
                txtColor                             // color
            );
        }
    }
}

export const plotStitchGraphv2 = (slideMetaData, slideData, vectorSource) => {
    // type FieldPosition is dict{"col" : int, "row" : int}
    const ccReducedMetas = slideMetaData.stitch_start_positions_v2; // array of dict{"ccStartPos": FieldPosition, "tileCount": int}
    const allPaths = slideMetaData.stitch_path_v2; // array of array of dict{"source": FieldPosition, "target": FieldPosition}
    const ancestors = slideMetaData.stitch_ancestors_v2; // array of FieldPosition

    if (ancestors != undefined && 
        (Object.keys(ancestors).length == 0 || ancestors.length == 0)) {
        return;
    }
    if (allPaths != undefined && 
        (Object.keys(allPaths).length == 0 || allPaths.length == 0)) {
        return;
    }
    if (ccReducedMetas != undefined && 
        (Object.keys(ccReducedMetas).length == 0 || ccReducedMetas.length == 0)) {
        return;
    }

    const tile_width_microns = slideData.tile_width * slideData.uperpixel;
    const tile_height_microns = slideData.tile_height * slideData.uperpixel;

    let ancestorKeys = ancestors.map(ancestor => `x${ancestor["col"]}y${ancestor["row"]}.jpg`);
    let counts = {};
    for (const ancestorKey of ancestorKeys) {
        counts[ancestorKey] = (counts[ancestorKey] || 0) + 1;
    }
    const uniqAncestorKeys = Array.from(new Set(ancestorKeys));
    console.log("uniqAncestorKeys", uniqAncestorKeys);

    for (const ancestorKey of uniqAncestorKeys) {
        const xHori = parseInt(ancestorKey.split('y')[0].split('x')[1]);
        const yVerti = parseInt(ancestorKey.split('y')[1].split('.' + slideData.img_type)[0]);
        yVerti = slideData.y_fields - yVerti - 1;
        const ancestorCount = counts[ancestorKey];

        const ccColor = `rgba(240,240,240,0.6)`;

        const size = 5;
        const margin = size / 10;
        const sizeOfMarker = 0.25;
        let stroke = 1;
        const centerBounds = getTileCenterBounds(xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns);
        drawRectangle(centerBounds, vectorSource, true, stroke, ccColor);
        drawLine((xHori + 0.5) * tile_width_microns,  // x
            (yVerti + 0.5) * tile_height_microns,       // y
            1,                                   // length
            vectorSource,                        // vectorSource
            "count="+ancestorCount,                       // title
            1,                                   // strokeWidth
            `rgba(10,10,10,1)`                             // color
        );
    }
    
    const tileCounts1 = ccReducedMetas.map(function(ccMeta) {return ccMeta["tileCount"];}).filter(function(tileCount) {return tileCount > 1;});
    let maxTileCount = Math.max(...tileCounts1);
    let minTileCount = Math.min(...tileCounts1);
    // draw squares at all `ccReducedMetas`
    for (const ccMeta of ccReducedMetas) {
        const position = ccMeta["ccStartPos"];
        const tileCount = ccMeta["tileCount"];
        const colorScore = 180 - Math.floor(180 * ((tileCount - minTileCount) / (maxTileCount - minTileCount)));
        const {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        let opacity = 0.7;
        if(tileCount < 2) {
            opacity = 0.1;
        }
        const ccColor = `rgba(${r},${g},${b},${opacity})`;

        const xHori = position["col"];
        let yVerti = position["row"];
        yVerti = slideData.y_fields - yVerti - 1;

        const size = 5;
        const margin = size / 10;
        const sizeOfMarker = 0.25;
        let stroke = 1;
        const centerBounds = getTileCenterBounds(xHori, yVerti, sizeOfMarker, margin, tile_width_microns, tile_height_microns);
        drawRectangle(centerBounds, vectorSource, true, stroke, ccColor);
    }

    // draw arrows from source -> target for `entirePath`
    const tileCounts = allPaths.map(function(ccPath) {return 1+ccPath.length;}).filter(function(tileCount) {return tileCount > 1;});
    let maxTileCount2 = Math.max(...tileCounts);
    let minTileCount2 = Math.min(...tileCounts);
    for (const ccPath of allPaths) {
        const tileCount = 1 + ccPath.length;
        const colorScore = 180 - Math.floor(180 * ((tileCount - minTileCount2) / (maxTileCount2 - minTileCount2)));
        const {r, g, b} = hsvToRgb(colorScore / 360, 1, 1);
        const ccColor = `rgba(${r},${g},${b},0.4)`;
        
        for (const traversalUnit of ccPath) {
            const xHori = traversalUnit["source"]["col"];
            let yVerti = traversalUnit["source"]["row"];
            yVerti = slideData.y_fields - yVerti - 1;
            const direction = getArrowDirection(traversalUnit);
            // DRAW
            drawArrowInDirection(xHori, yVerti, tile_width_microns, tile_height_microns, direction, vectorSource, ccColor);
        }
    }
}


/*
 *
 * FINAL STITCH DISAGREEMENTS
 * 
 */
export const plotStitchDisagreementsv2 = (slideMetaData, slideData, vectorSource) => {
    // sample FieldStitchResult
    // computedTop: {tx: -35, ty: 1478}
    // computedLeft: {tx: 1859, ty: 63}
    // actualTop: {tx: -35, ty: 1478}
    // actualLeft: {tx: 1859, ty: 64}
    // disagreementTop: {deltaTx: 0, deltaTy: 0}
    // disagreementLeft: {deltaTx: 0, deltaTy: -1}
    
    // stitchResultv2 is dict{key1: FieldStitchResult, key2: FieldStitchResult, ...}
    const stitchResultv2 = slideMetaData.stitch_disagreements_final_v2.result;

    const size = 3;
    const margin = size / 10;
    const sizeOfMarker = 0.2;

    let disagreementL2NormMax = 0;
    let disagreementL2NormMin = 100000000; // some large number
    for(const key in stitchResultv2) {
        // sample key is "x9y32.jpg"
        const disagreement = stitchResultv2[key];
        const bothComputedDeltaZero = disagreement["computedTop"]["tx"] === 0 
                                      && disagreement["computedTop"]["ty"] === 0;
        if (disagreement["noActualTopTxTy"] != undefined && disagreement["noActualTopTxTy"] === false
            && !bothComputedDeltaZero) {
            const disagreementTop = disagreement["disagreementTop"];
            const deltaTxSq = disagreementTop["deltaTx"]*disagreementTop["deltaTx"];
            const deltaTySq = disagreementTop["deltaTy"]*disagreementTop["deltaTy"];
            const l2norm = Math.sqrt(deltaTxSq + deltaTySq);
            if (l2norm < 1000 && l2norm > disagreementL2NormMax) {
                console.log("new max top of ", l2norm, key, disagreement);
                disagreementL2NormMax = l2norm;
            }
            if (l2norm < 1000 && l2norm < disagreementL2NormMin) {
                disagreementL2NormMin = l2norm;
            }
        }
        const bothComputedDeltaZero2 = disagreement["computedLeft"]["tx"] === 0 
                                       && disagreement["computedLeft"]["ty"] === 0;
        if (disagreement["noActualLeftTxTy"] != undefined && disagreement["noActualLeftTxTy"] === false
            && !bothComputedDeltaZero2) {
            const disagreementLeft = disagreement["disagreementLeft"];
            const deltaTxSq = disagreementLeft["deltaTx"]*disagreementLeft["deltaTx"];
            const deltaTySq = disagreementLeft["deltaTy"]*disagreementLeft["deltaTy"];
            const l2norm = Math.sqrt(deltaTxSq + deltaTySq);
            if (l2norm < 1000 && l2norm > disagreementL2NormMax) {
                console.log("new max left of ", l2norm, key, disagreement);
                disagreementL2NormMax = l2norm;
            }
            if (l2norm < 1000 && l2norm < disagreementL2NormMin) {
                disagreementL2NormMin = l2norm;
            }
        }
    }

    for(const key in stitchResultv2) {
        // sample key is "x9y32.jpg"
        const xHori = parseInt(key.split('y')[0].split('x')[1]);
        const yVerti = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0]);
        const disagreement = stitchResultv2[key];
        plotDisagreementv2Unit(disagreement, disagreementL2NormMax, disagreementL2NormMin,
            slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource);
    }
}

export const plotStitchDisagreementsTextv2 = (slideMetaData, slideData, vectorSource) => {
    const stitchResultv2 = slideMetaData.stitch_disagreements_final_v2.result;

    const size = 3;
    const margin = size / 10;
    const sizeOfMarker = 0.2;
    for(const key in stitchResultv2) {
        // sample key is "x9y32.jpg"
        const xHori = parseInt(key.split('y')[0].split('x')[1]);
        const yVerti = parseInt(key.split('y')[1].split('.' + slideData.img_type)[0]);
        const disagreement = stitchResultv2[key];
        plotDisagreementTextv2Unit(disagreement, slideData, xHori, yVerti, margin, sizeOfMarker, vectorSource);
    }
}



// Archived code (was part of the first draft of stitching v2 by Abhishek Kandoi)
// not being used
// export const plotStitchGraphv2 = (slideMetaData, slideData, vectorSource) => {
//     const stitchGraphv2 = slideMetaData.stitch_graph_v2.groupStitchOutputs;
//     const StitchDirection = {
//         LEFT_DIRECTION: "LEFT_DIRECTION",
//         RIGHT_DIRECTION: "RIGHT_DIRECTION",
//         TOP_DIRECTION: "TOP_DIRECTION",
//         DOWN_DIRECTION: "DOWN_DIRECTION"
//     }
    
//     const tile_width_microns = slideData.tile_width * slideData.uperpixel;
//     const tile_height_microns = slideData.tile_height * slideData.uperpixel;
//     let order = 1;
//     for (const stitchOutput of stitchGraphv2) {
//         console.log("stitchOutput", stitchOutput);
        
//         // draw the ref field used to stitch
//         let stitchRow = stitchOutput["stitchRow"];
//         stitchRow = slideData.y_fields - stitchRow - 1;
//         const stitchCol = stitchOutput["stitchCol"];
//         const stitchDirection = stitchOutput["stitchDirection"];
//         let txtColor = "#00d775";

//         const bounds = [
//             [stitchCol * (tile_width_microns), stitchRow * (tile_height_microns)],
//             [(stitchCol + 1) * (tile_width_microns), stitchRow * (tile_height_microns)],
//             [(stitchCol + 1) * (tile_width_microns), (stitchRow + 1) * (tile_height_microns)],
//             [stitchCol * (tile_width_microns), (stitchRow + 1) * (tile_height_microns)],
//             [stitchCol * (tile_width_microns), stitchRow * (tile_height_microns)]
//         ];
//         drawRectangle(bounds, vectorSource, false, 3, "#ea0000");
//         // const boundsBg = [
//         //     [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)],
//         //     [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + (1 - sizeOfMarker)) * (tile_height_microns)],
//         //     [(xHori + (margin / 2)) * (tile_width_microns), (yVerti + (1 - sizeOfMarker)) * (tile_height_microns)],
//         //     [(xHori + (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)],
//         //     [(xHori - (margin / 2)) * (tile_width_microns), (yVerti + sizeOfMarker) * (tile_height_microns)]
//         // ];

//         drawLine((stitchCol + (stitchDirection == StitchDirection.LEFT_DIRECTION ? 0 : 1)) * tile_width_microns,      // x
//             (stitchRow + 0.2) * tile_height_microns,  // y
//             1,                                                                  // length
//             vectorSource,                                                       // vectorSource
//             stitchDirection == StitchDirection.LEFT_DIRECTION ? "<--" + order: "-->" + order,   // title
//             0.25,                              // strokeWidth
//             txtColor                           // color
//            );
//         order += 1;


//         // draw the stitched field interval
//         const group = stitchOutput["group"];
//         const xHori = group["column"];
//         let yVertiTop = group["topY"];
//         let yVertiBottom = group["bottomY"];
//         const numFields = yVertiBottom - yVertiTop + 1;
//         yVertiTop = slideData.y_fields - yVertiTop - 1;
//         yVertiBottom = slideData.y_fields - yVertiBottom - 1;

//         const bounds2 = [
//             [xHori * (tile_width_microns), yVertiBottom * (tile_height_microns)],
//             [(xHori + 1) * (tile_width_microns), yVertiBottom * (tile_height_microns)],
//             [(xHori + 1) * (tile_width_microns), (yVertiBottom + numFields) * (tile_height_microns)],
//             [xHori * (tile_width_microns), (yVertiBottom + numFields) * (tile_height_microns)],
//             [xHori * (tile_width_microns), yVertiBottom * (tile_height_microns)]
//         ];
//         drawRectangle(bounds2, vectorSource, false, 5, "#eaff00");
        
//     }
// }

