import React, { Component } from "react";
import { connect } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';
import { Row, Col, Tooltip, Button, Spin } from 'antd';
import { makePanZoomCanvas, drawCircle, drawLine } from '../../../utils/panzoomcanvas';
import { updatePixelValues } from '../../../action/preview.tuning.action';

import "../../../asset/style/manualmode/preview_tuning_app.css";

class PreviewTuningApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            drawnShapesCoordinates: [],
        }

        this.lineWidth = 1;
        this.crossLength = 15;
        this.circleRadius = 25;
        this.scale = 2;
        this.circleColor = "#83f52c";
        this.crossColor = "#83f52c";
        this.lineColor = "#83f52c";

        this.canvasRef = React.createRef();

        this.image = new Image();

        this.image.onload = this.onload_runner2;

        this.onload_runner = this.onload_runner2.bind(this);

    }

    componentDidMount = () => {
        this.getStitchedImage();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.adminState.numberOfSlots == 0 && this.props.adminState.numberOfSlots != 0) {
            this.getStitchedImage();
        }
    }

    getStitchedImage = () => {
        let timestamp = Date.now();
        if(this.props.adminState.numberOfSlots == 6) {
            this.image.src = "/diagnostics/preview/latest/stitched_preview_image.jpg?timestamp=" + timestamp;
        } else if(this.props.adminState.numberOfSlots == 1) {
            this.image.src = "/diagnostics/preview/latest/cropped_0.jpg?timestamp=" + timestamp;
        }
    }

    onload_runner2 = () => {
        let canvas = this.canvasRef.current; 
        makePanZoomCanvas(canvas, this.image);
    }

    drawCoordinates = (event) => {
        let canvas = this.canvasRef.current; 
        let border = parseInt(getComputedStyle(canvas, null).getPropertyValue('border-left-width'));
        let x = event.nativeEvent.offsetX - border;
        let y = event.nativeEvent.offsetY - border;
        let ctx = canvas.getContext('2d');
        let pt = ctx.transformedPoint(x, y);
        ctx.lineWidth = this.lineWidth;
        drawLine(ctx, x - this.crossLength, y, x + this.crossLength, y, this.crossColor);
        drawLine(ctx, x, y - this.crossLength, x, y + this.crossLength, this.crossColor);
        drawCircle(ctx, x, y, this.circleRadius, this.circleColor);
        this.props.dispatch(updatePixelValues([Math.floor(pt.x), Math.floor(pt.y)]));
    }

    reloadPage = () => {
        window.location.reload();
    }

    render() {

        return (
            <Row className="app-parent overlayed-component preview-tuning-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Row>
                                <Col span={2} push={22}>
                                    <Tooltip title="Reload Image" placement="rightTop">
                                        <Button type={"primary"} size="small" onClick={this.reloadPage} style={{marginLeft: 30}}>
                                            <ReloadOutlined />
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col offset={1}>
                                    <canvas ref={this.canvasRef} onDoubleClick={this.drawCoordinates} 
                                        id="preview-cassette-canvas" width="1000" height="400" />
                                </Col>
                            </Row>
                        </Row>
                    </Spin>
                </div>
                <br />
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        previewTuning: state.previewTuningReducer
    }
}

export default connect(mapStateToProps)(PreviewTuningApp);