import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Row, Col, Button, Icon} from 'antd'
import Draw from 'ol/interaction/Draw.js';
import { Snap } from 'ol/interaction.js';

import { getCenter } from 'ol/extent.js'

import '../../../../../asset/style/neoviewer/draw_tool.css'

class DrawTool extends Component {

    constructor(props) {
        super(props);

        this.draw = null;
        this.snap = null;
    }

    componentDidUpdate = () => {
        this.props.slidemap.removeInteraction(this.draw);
        document.removeEventListener("keydown", this.escFunction, false);
        if (this.props.currentSelectedKey === this.props.toolKey.name) {
            this.draw = new Draw({
                source: this.props.vector.getSource(),
                type: this.props.toolKey.shape, 
                ...this.props.toolParams
            });

            if (this.props.snapDrawing) {
                this.snap = new Snap({ source: this.props.vector.getSource() });
                this.props.slidemap.addInteraction(this.snap);
            }

            this.props.slidemap.addInteraction(this.draw);
            this.draw.on("drawstart", this.onDrawStart, this);
            this.draw.on("drawend", this.onDrawEnd, this);
            document.addEventListener("keydown", this.escFunction, false);
        } 
    }

    escFunction = (event) => {
        if(event.keyCode === 27) {
            this.stopDrawing();
            event.stopPropagation();
        }
    }

    stopDrawing = () => {
        if (!this.props.continuousMode) {
            this.props.slidemap.removeInteraction(this.draw);
        }
        
        this.props.setDrawing(false);
    }

    onDrawStart = (e) => {
        this.props.setDrawing(true);
    }

    onDrawEnd = (e) => {
        let feature = e.feature;
        let geometry = feature.getGeometry();
        let params = this.props.getGeometricParams(geometry);
        this.props.addAnnotation(params.area, params.perimeter, this.props.toolKey, JSON.stringify(params.coordinates), getCenter(geometry.getExtent()),params.counter);
        this.stopDrawing();
    }

    render(){
        
        return (
            <div className={("drawing-tool-icon ") + (this.props.currentSelectedKey === this.props.toolKey.name ? " drawing-tool-open" : "")}
                onClick={() => this.props.onSelected(this.props.toolKey.name)}>
                <div>
                    {this.props.content}
                </div>
                <div className="drawing-tool-icon-text unselectable">
                    {this.props.toolKey.name}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(DrawTool);