import React, { Component } from 'react';
import cookie from "react-cookies";
import { CodepenSquareFilled, FolderOpenOutlined, FolderOutlined, EyeOutlined, BackwardOutlined , DownloadOutlined} from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
  Typography,
  Form,
} from 'antd';
import { globalUrlPrefix, openInNewTabType } from '../utils/const';
import axios from "axios";
import { updateUrlStateInReducer, updateCaseSearch, changePage } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert, markCaseUnread, markCaseAndSlidesRead } from '../action/cases.action'
import { makeMenuVisible } from "../action/context.menu.action";
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import { historyMP } from '../helper/history';
import FilterComp from "../component/case/filter";
import '../asset/style/custom_antd_table_style.css';
import { AuthHeader } from "../helper/auth.token";
import { message } from "antd";

var querystring = require('querystring');

const { Search } = Input;
const { Column, ColumnGroup } = Table;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const { Title } = Typography; 

const pageSize = 15;

class SummaryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      search: this.props.urlState.search,
      initializationDone: false,
      case_status: -1,
      selected_users: [],
      validationIds: [],
      selectedValidation: '',
      assignedValidations: {},
      validatonSummary: [],
      pathologistSummary: [],
      validationName:'',
      download_user_id : cookie.load('user_id'),
      all_users: [],
    }

    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
    this.getValidationIDs();
    this.getValidationSummary();
    this.getPathologistSummary();
    this.getValidationData(this.props.match.params.id);
    this.getAllUsers();
  }

  getAllUsers = () => {
    let url = `/api/get_all_users/`;
    axios.get(url).then(response=>{
      this.setState({
        all_users: response.data['users'],
      })
    })
  }

  getValidationData = (id) => {
    let url = `/api/get_validation_data/?id=${this.props.match.params.id}`;
    console.log("xxx")
    axios.get(url)
        .then(response => {
          console.log("naaam",response.data['name'])
          this.setState({
            validationName: response.data['name'],
          })
        })
  }

  getCases = (cases) => {
    console.log("cases",cases)
    let newData = []
    Object.keys(cases).sort(
      function (a, b) {
        return cases[a].displayOrder - cases[b].displayOrder;
      }
    ).map((key) => {
      let curr = cases[key];
      curr.key = curr.id;
      curr.case = this.getCaseDesign(curr);
      curr.type = openInNewTabType.CASE;
      newData.push(curr);
    });
    console.log("new-data",newData)
    return newData;
  }

  getCaseDesign = (caseInfo) => {
    const isLongCaseID = caseInfo.case_id.length > 15;
    const caseIdElem = (
      caseInfo.unread ? 
        <b>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</b> :
        <span>{isLongCaseID ? `${caseInfo.case_id.slice(0, 15)}...` : caseInfo.case_id}</span>
    );
    const finalCaseIdElem = isLongCaseID ? <Tooltip title={caseInfo.case_id} key={caseInfo.case_id}>{caseIdElem}</Tooltip> : caseIdElem;

    const isLongName = caseInfo.patient_name.length > 25;
    const nameElem = (
      caseInfo.unread ? 
        <b>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</b> :
        <span>{isLongName ? `${caseInfo.patient_name.slice(0, 25)}...` : caseInfo.patient_name}</span>
    );
    const finalNameElem = isLongName ? <Tooltip title={caseInfo.patient_name} key={caseInfo.patient_name}>{nameElem}</Tooltip> : nameElem;

    const isLongStatus = caseInfo.case_status.length > 35;
    const statusElem = (
      <Tag key={caseInfo.case_id} color="#3b7199" onClick={(event) => event.stopPropagation()}>
        {isLongStatus ? `${caseInfo.case_status.slice(0, 35)}...` : caseInfo.case_status}
      </Tag>
    );
    const finalStatusElem = isLongStatus ? <Tooltip title={caseInfo.case_status} key={caseInfo.case_status}>{statusElem}</Tooltip> : statusElem;

    const assigned_names = this.getAssignedNamesComponent(caseInfo);
    const assigned_validation = this.getAssignedValidationsComponent(caseInfo);
    //const assigned_validation = this.getAssignedValidations(caseInfo.case_id)
    return <Row>
      <Col span={3} offset={1}>
        {finalCaseIdElem}
      </Col>
      <Col span={5}>
        {finalNameElem}
      </Col>
      <Col span={5}>
        {finalStatusElem}
      </Col>
      <Col span={5}>
        {assigned_names}
      </Col>
      <Col span={5}>
        {assigned_validation}
      </Col>
    </Row>
  }


  getValidationSummary = () => {
    let url = `/api/fetch_validation_summary/?id=${this.props.match.params.id}`;
    axios.get(url)
        .then(response => {
          console.log("summary",response.data["result"])
          let selectedValidation=''
          if(this.state.validationIds.length>0){
            selectedValidation=this.state.validationIds[0]
          }
          this.setState({validatonSummary:response.data["result"]})
        })
  }

  getPathologistSummary = () => {
    let url = `/api/pathologist_agreement/?id=${this.props.match.params.id}`;
    axios.get(url)
        .then(response => {
          console.log("summary",response.data["result"])
          this.setState({pathologistSummary:response.data["result"]})
        })
  }

  getAssignedNamesComponent = (caseInfo) => {
    return caseInfo.assigned_users.map((user, index) => {
      const full_name = user.first_name + ' ' + user.last_name;
      const isLongUserName = full_name.length > 20;
      let color = 'blue';
      const userNameElem = (
        <Tag key={user.id} color={color} onClick={(event) => event.stopPropagation()} style={{ cursor: 'pointer' }}>
          {isLongUserName ? `${full_name.slice(0, 20)}...` : full_name}
        </Tag>
      );
      const finalUserTag = isLongUserName ? <Tooltip title={full_name} key={user.id}>{userNameElem}</Tooltip> : userNameElem;
      return <Popconfirm
        title={"Remove user " + full_name + " from this case?"}
        onConfirm={(event) => this.handleCancelAssignedUser(event, caseInfo, user.id)}
        okText="Yes"
        palcement="topRight"
        style={{ padding: 20 }}
        cancelText="No"
        onCancel={(event) => {
          event.stopPropagation();
        }}>
        {finalUserTag}
      </Popconfirm>
    });
  }

  getAssignedValidationsComponent = (caseInfo) => {
    return caseInfo.assigned_validations.map((validation, index)=>{
      let color = 'green';
      const validationTag = (
        <Tag key={validation.id} color={color} style={{ cursor: 'pointer' }}>
          {validation.validation_id}
        </Tag>
      );
      return validationTag
    });
  }

  handleCancelAssignedUser = (event, currCase, user_id) => {
    event.stopPropagation();
    let val = {
      id: currCase.id,
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      user_id: user_id
    };
    this.props.dispatch(removeUserUpdateAlert(currCase.id, val, this.props.urlState));
  }

  generateReport = () => {
    let url = `/api/get_pdf/?case_id=${this.state.selectedCase.case_id}`;
    window.open(url);
  }

  initializeSearch = () => {
    if (!this.state.initializationDone && this.props.urlState.search.length > 0) {
      this.setState({
        search: this.props.urlState.search,
        initializationDone: true,
      })
    }
  }

  onCaseSearch = (value) => {
    this.setState({
      selectedRowKeys: [],
    })
    this.props.dispatch(updateCaseSearch(value, this.props.urlState));
  }

  onInputChange = (e) => {
    if (e.target.value == '') {
      this.onSeachReset();
    }
    this.setState({
      search: e.target.value,
    });
  }

  onSeachReset = () => {
    if (this.state.search != '') {
      let search = '';
      this.props.dispatch(updateCaseSearch(search, this.props.urlState));
      this.setState({
        search,
        selectedRowKeys: [],
      });
    }
  }

  getStatusOptions = () => {
    let status_options = Object.keys(this.props.allCaseStatus).map((key) => {
      let value = this.props.allCaseStatus[key];
      return <Option key={value.id} value={value.id}>{value.status}</Option>
    });
    status_options.unshift(<Option value={-1} key={-1}>Select Status</Option>);
    return status_options;
  }

  getUserOptions = () => {
    return Object.keys(this.props.allUsers).map((key) => {
      let value = this.props.allUsers[key];
      return <Option key={value.id} value={value.id}>{value.first_name + ' ' + value.last_name}</Option>
    });
  }

  allUnreadCases = (selectedCases) => {
    for (let i = 0; i < selectedCases.length; i++) {
      if (this.props.allCases[selectedCases[i]].unread) {
        return false;
      }
    }
    return true;
  }

  makeCasesUnread = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseUnread(curr_case));
    });
  }

  makeCasesRead = () => {
    this.state.selectedRowKeys.map((value, index) => {
      let curr_case = this.props.allCases[value];
      this.props.dispatch(markCaseAndSlidesRead(curr_case));
    });
  }

  getValidationIDs = () => {
    let url = `/api/get_all_validation_id/`;
    axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
        .then(response => {
            console.log(response.data.ids)
            let data = response.data['data']
            let ids = []
            for(let i=0; i<data.length; i++){
              ids.push(data[i].id)
            }
            let op = ids.map((id,index) =><Option value={id} key={index}>{id}</Option>)
            this.setState({validationIds:op})
        })
    }
  

  getRowActionsIfAtleastOneRowSelected = () => {

    let unreadIcon = <Button onClick={this.makeCasesUnread}>
      <FolderOutlined /> Mark as unread
                      </Button>

    let readIcon = <Button onClick={this.makeCasesRead}>
      <FolderOpenOutlined /> Mark as read
                    </Button>

    return this.state.selectedRowKeys.length > 0 ?
      <Row>
        <Col span={4}>
          <ButtonGroup>
            <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allCases).map((value) => parseInt(value)) }) }}>
              Select All Cases
            </Button>
            <Button onClick={() => { this.setState({ selectedRowKeys: [] }) }}>
              Cancel Selection
            </Button>
          </ButtonGroup>
        </Col>
        <Col span={2} offset={1}>
          {this.allUnreadCases(this.state.selectedRowKeys) ?
            unreadIcon : readIcon
          }
        </Col>
        <Col span={4} offset={1}>
          <Select
            value={this.state.case_status}
            placeholder="Select Status"
            onChange={this.handleTypeChange}
            style={{ width: 220, marginLeft: 8 }}
          >
            {this.getStatusOptions()}
          </Select>
        </Col>
        <Col span={7} offset={1}>
          <Select
            mode="multiple"
            value={this.state.selected_users}
            placeholder="Select Users"
            onChange={this.handleUserChange}
            filterOption={this.filterOptionUsers}
            style={{ width: 500, marginLeft: 8 }}
          >
            {this.getUserOptions()}
          </Select>
        </Col>

        <Col span={7} offset={1}>
          <Select
            // mode="multiple"
            placeholder="Select Validation"
            onChange={this.handleValidationChange}
            style={{ width: 500, marginLeft: 8 }}
          >
            {/* <Option value="clia" key="1">"Clia"</Option> */}
            {this.state.validationIds}
            {/* {this.getValidationIDs().map(id=>(<Option key={id}>{id}</Option>))} */}
          </Select>
        </Col>

        <Col span={3} offset={1}>
          <Button onClick={this.sumitUsersAndStatus} disabled={this.state.selectedValidation===''}>
            Submit
          </Button>
        </Col>
      </Row>
      :
      <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.props.allCases).map((value) => parseInt(value)) }) }}>
        Select All Cases
      </Button>
  }

  handleTypeChange = (status) => {
    this.setState({
      case_status: status,
    });
  }

  handleUserChange = (value) => {
    this.setState({
      selected_users: value,
    })
  }

  handleValidationChange = (value) => {
      this.setState({
          selectedValidation: value,
      })
  }

  filterOptionUsers = (inputValue, option) => {
    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  }

  handleChangeAssignValidation = (selectedRowKeys, selectedValidation)=>{
      let url = "/api/assign_validation/"
      axios.post(url,querystring.stringify({selected_cases:selectedRowKeys, validation:selectedValidation}),{ headers: { Authorization: AuthHeader() }})
        .then(response => {
            console.log(response)
            message.success("Case/Cases Successfully Updated");
        })
    }
  

  sumitUsersAndStatus = () => {
    if (this.state.selected_users.length > 0) {
      this.state.selectedRowKeys.map((value, index) => {
        this.handleChangeAssignUsers(this.props.allCases[value], this.state.selected_users, this.state.selectedRowKeys);
      });
    }

    if (this.state.case_status !== -1) {
      this.state.selectedRowKeys.map((value, index) => {
        this.handleChangeStatus(this.props.allCases[value], this.state.case_status, this.state.selectedRowKeys);
      });
    }

    if (this.state.selectedValidation != ''){
        this.handleChangeAssignValidation(this.state.selectedRowKeys, this.state.selectedValidation);
    }

    this.setState({
      selected_users: [],
      case_status: -1,
    })
    window.location.reload();
  }

  handleChangeStatus = (currCase, input, selectedRows) => {
    let val = {
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      case_status: input
    };
    this.props.dispatch(caseUpdateAlert(currCase.id, val, selectedRows));
  };

  handleChangeAssignUsers = (currCase, input, selectedRows) => {
    let val = {
      id: currCase.id,
      case_id: currCase.case_id,
      displayOrder: currCase.displayOrder,
      assigned_users: input
    };
    this.props.dispatch(assignUsersUpdateAlert(currCase.id, val, selectedRows));
  };

  getFilters = () => {
    return this.state.selectedRowKeys.length > 0 ? undefined : <FilterComp />;
  }

  getRowActions = () => {
    return <Row>
      <Col span={this.state.selectedRowKeys.length > 0 ? 24 : 4}>
        <span style={{ marginRight: "10px" }}>
          {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected cases ? " : ""}
        </span>
        {this.getRowActionsIfAtleastOneRowSelected()}
      </Col>
      <Col span={this.state.selectedRowKeys.length > 0 ? 0 : 20}>{this.getFilters()}</Col>
    </Row>
  }

  getPaginationDiv = () => {
    return <Pagination
      total={this.props.total}
      showTotal={(total, range) =>
        this.isMobile ? `` : `Showing ${range[0]}-${range[1]} out of ${total} total cases`
      }
      current={this.props.urlState.page}
      pageSize={pageSize}
      defaultCurrent={1}
      onChange={this.updatePage}
      size="small"
      className="pagination-mp"
    />
  }

  updatePage = (pageNumber) => {
    historyMP.push('/' + globalUrlPrefix + '/cases?page=' + pageNumber);
    this.props.dispatch(changePage(pageNumber, this.props.urlState));
  }

  linkCase = (id) => {
    window.location.href = "/" + globalUrlPrefix + "/case/" + id;
  };

  openCliaDashboard = () => {
    window.location.href = "/" + globalUrlPrefix + "/clia_validations";
  }

  openAddNewValidationStudy = () => {
      window.location.href = "/" + globalUrlPrefix + "/addvalidation";
  }

  changeDownloadUserId = (value) => {
    this.setState({
      download_user_id: value,
    })
  }

  downloadSummary = () => {
    let url = `/api/create_table/?validation_id=${this.props.match.params.id}&user_id=${this.state.download_user_id}`;
    axios.get(url, {responseType: 'blob'}).then(res => {
      window.open(URL.createObjectURL(res.data));
      console.log("download success")
    })
  }

  render() {

    let all_users = this.state.all_users;
    console.log("all_users", all_users)
    let all_users_options = []
    for(let i=0; i<all_users.length; i++){
      console.log("user", all_users[i]['id'], all_users[i]['username'])
      all_users_options.push(
        <Select.Option key={i} value={all_users[i]['id']}>{all_users[i]['username']}</Select.Option>
      )
    }

    let initial_username=cookie.loadAll().username;
    for(let i=0;i<all_users.length;i++){
        if(parseInt(all_users[i].id) == parseInt(this.state.download_user_id)){
            initial_username = all_users[i].username;
            break;
        }
    }


    this.initializeSearch();
    let data = this.getCases(this.props.allCases);
    let columns = [
      {
        title: () => {
          return <div>
            <Row>
              <Col span={3} offset={1}>
                <b className="white-text">CASE ID</b>
              </Col>
              <Col span={5}>
                <b className="white-text">PATIENT'S NAME</b>
              </Col>
              <Col span={5}>
                <b className="white-text">STATUS</b>
              </Col>
              <Col span={5}>
                <b className="white-text">ASSIGNED USERS</b>
              </Col>
              <Col span={5}>
              <b className="white-text">VALIDATION STUDY</b>
              </Col>
            </Row>
          </div>
        },
        dataIndex: "case",
        key: "case",
      }
    ]

    const onRow = (record, rowIndex) => {
      return {
        onClick: event => {
          this.linkCase(record.id);
        },
        onContextMenu: (event) => {
          event.preventDefault();
          this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
        }
      };
    }

    let rowActions = [
      <Divider />,
      <Row className="row-actions">
        {this.getRowActions()}
      </Row>
    ];

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({
          selectedRowKeys: selectedRowKeys
        })
      },
      selectedRowKeys: this.state.selectedRowKeys,
      columnWidth: '10px',

    };

    let toshowValidations=this.state.validatonSummary;
 
    // if(this.state.selectedValidation!=''){
    //   toshowValidations = toshowValidations.filter(validation => {
    //     return validation['validation_assigned'].indexOf(this.state.selectedValidation)!=-1
    //   })
    // }
   
    console.log("to-show",toshowValidations)

    return (
      <div>
        <Spin tip="Loading..." spinning={this.props.isFetching} delay={500}>
          {this.props.isFetching === true ? <div></div> :
            <div>
              <Row>
                <Col span={24} offset={0} className="all-slides">
                  <br />
               
                  <Row>
                    <Title > {this.state.validationName} </Title>
                  </Row>
                  {/* <Divider /> */}
                  <Row>
                    <h1>PATHOLOGIST AGREEMENT SUMMARY</h1>
                        <Table
                        className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        //  columns={validation_columns}
                        dataSource={this.state.pathologistSummary}
                        size="small"
                        bordered
                        >
                            <Column title="PATHOLOGIST" span={1} dataIndex="user"/>
                            <Column title="% CASES IN AGREEMENT" span={1} dataIndex="percentage"/>
                            <Column title="TOTAL CASES" span={1} dataIndex="total"/>
                        </Table>
                  </Row>
                  <Row>
                    {this.state.all_users.length>0?
                    <div>
                      <Row>
                          <h2>DOWNLOAD SUMMARY</h2>
                          {/* <Col span={3}> */}
                            {cookie.loadAll().is_staff==="true"?
                            <React.Fragment>
                              <Form.Item style={{width:'500px'}}>
                                <Select onChange={this.changeDownloadUserId} value={initial_username} style={{width:'200px'}}>
                                  {all_users_options}
                                </Select>
                                <Button onClick={this.downloadSummary} style={{marginLeft:'20px'}}>
                                <DownloadOutlined />
                              </Button>
                              </Form.Item>
                             
                            </React.Fragment>: null}
                          {/* </Col> */}
                          {/* <Col> */}
                           
                          {/* </Col> */}
                      </Row>
                    </div>
                    : null}
                  </Row>
                  <Row>
                    <h1>VALIDATION SUMMARY</h1>
                    
                    <Table
                     className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                    //  columns={validation_columns}
                     dataSource={toshowValidations}
                     size="small"
                     style={{marginTop:'10px'}}
                     bordered
                    >
                      <Column title="CASE ID" span={1} dataIndex="case_id"/>
                      <Column title="SLIDE" span={1} dataIndex="slide_name"/>
                      <Column title="PATHOLOGIST" span={1} dataIndex="pathologist"/>
                      <Column title="DATE OF CASE" span={1} dataIndex="date"/>
                      <Column title="CASE TYPE" span={1} dataIndex="case_type"/>
                      {/* <Column title="PRE-GLASS DIAGNOSIS" span={1} dataIndex="pre_glass_diagnosis"/> */}
                      <Column title="POST-GLASS DIAGNOSIS" span={1} dataIndex="post_glass_diagnosis"/>
                      <Column title="QUALITY OF SCAN" span={1} dataIndex="quality_of_scan"/>
                      <Column title="DIAGNOSTIC CERTAINTY" span={1} dataIndex="diagnostic_certainty"/>
                      <Column title="CONCORDANCE DESIGNATION" span={1} dataIndex="concordance_designation"/>
                      <Column title="AGREE/DISAGREE" span={1} dataIndex="agree_disagree"/>
                      
                    </Table>
                  </Row>
                </Col>
              </Row>
            </div>

          }
        </Spin>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(SummaryView);