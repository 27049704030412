import React from  "react";
import App from '../utils/app'
import * as keys from './apps/app_keys'
import cookie from "react-cookies";

import ZoomControls from './apps/zoom_controls'
import PreviewMap from './apps/preview'
import AnnotationsApp from './apps/tagger/annotations_app'
import GridOverLay from './apps/grid_overlay'
import ScreenshotApp from './apps/screenshot'
import SettingsApp from './apps/settings'
import SlideInfoApp from './apps/slide_info'
import CaseInfoApp from './apps/case_info'
import ReportApp from './apps/cap_report'
import CapGuideApp from './apps/cap_guide'
import IHCApp from './apps/tagger/ihc'
import PresentApp from './apps/present'
import DisplaySizeCalibrationApp from './apps/display_size_calibration'
import ZStackApp from './apps/z_stack_app';
import ZStackControls from "./apps/z_stack_controls";
import BarcodeViewer from "./apps/barcode_viewer";
import { slideViewerType } from "../../utils/const";
import TRFApp from "./apps/uploaded_trf";
import CliaResultsApp from "./apps/clia_results";

const appify = (key, content, allAppsState) => {
    return (
        allAppsState.apps_installed[key.id + "_installed"] === undefined || allAppsState.apps_installed[key.id + "_installed"] === true ? 
            <App
                key={key.id}
                appKey={key}
                app_installed={allAppsState.apps_installed[key.id + "_installed"] || key.defaultInstalled || false} // if both are undefined -> by default is false
                viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                startTop={((allAppsState.app_state[key.id] || {}).position || {y : key.defaultY}).y}
                startLeft={((allAppsState.app_state[key.id] || {}).position || {x : key.defaultX}).x}
                defaultClosed={(allAppsState.app_state[key.id] || {}).closed}
                content={content} /> : undefined
    )
}

const getZoomControlsApp = (slideState, allAppsState) => {
    return appify(keys.zoomAppKey, 
                <ZoomControls 
                    view={slideState.view}
                    zoomScale={slideState.zoomScale}
                    ZValues={slideState.ZValues}
                    maxZoom={slideState.maxZoom} 
                    goHome={slideState.goHome} />, 
                    allAppsState
    )
}

const getAnnotationApp = (slideState, allAppsState) => {
    return appify(keys.annotationsAppKey,
                <AnnotationsApp
                    id={keys.annotationsAppKey.id}
                    slide={slideState.slide_data}
                    slidemap={slideState.slidemap}
                    zoomScale={slideState.zoomScale}
                    desiredZoomScale={slideState.desiredZoomScale} />,
                    allAppsState
    )
}

const getPreviewApp = (slideState, allAppsState) => {
    console.log("slidestate", slideState)
    return appify(keys.previewControlsKey, 
            <PreviewMap
                slidemap={slideState.slidemap}
                view={slideState.view}
                layers={slideState.layer}
                addPreviewMapControl={slideState.addPreviewMapControl}
                zoomScale={slideState.zoomScale}
                maxZoom={slideState.maxZoom}
                zoomLevels={slideState.zoomLevels} 
                tileSize={slideState.tileSize}
                uperpixel={slideState.slide_data.uperpixel}
                path={slideState.slide_data.path} />, 
                allAppsState
    )
}

const getGridApp = (slideState, allAppsState) => {
    return appify(keys.gridOverlayAppKey, 
                <GridOverLay
                    id={keys.gridOverlayAppKey.id}
                    slidemap={slideState.slidemap} />, 
                    allAppsState
    )
}

const getScreenshotApp = (slideState, allAppsState) => {
    return appify(keys.screenshotAppKey, 
                <ScreenshotApp
                    slideName={slideState.slide_data.name}
                    slideId={slideState.slide_data.id}
                    case={slideState.slide_data.case}
                    slidemap={slideState.slidemap} />, 
                    allAppsState
    )
}

const getSettingsApp = (slideState, allAppsState) => {
    return appify(keys.settingsAppKey,
                <SettingsApp
                    slideData={slideState.slide_data}
                    mapId={slideState.mapId}
                    slidemap={slideState.slidemap} />,
                    allAppsState
    )
}

const getSlideInfoApp = (slideState, allAppsState) => {
    return appify(keys.slideInfoAppKey, 
                <SlideInfoApp
                    slideData={slideState.slide_data}
                    viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                     />, 
                     allAppsState
    )
}

const getCaseInfoApp = (slideState, allAppsState) => {
    return appify(keys.caseInfoAppKey, 
                <CaseInfoApp
                    slideData={slideState.slide_data}
                     />, 
                     allAppsState
    )
}

const getIHCApp = (slideState, allAppsState) => {
    return appify(keys.ihcAppKey, 
                <IHCApp
                    id={keys.ihcAppKey.id}
                    slide={slideState.slide_data}
                    slidemap={slideState.slidemap} 
                    zoomScale={slideState.zoomScale}
                     />, 
                     allAppsState
    )
}

const getReportApp = (slideState, allAppsState) => {
    return appify(keys.reportAppKey, 
                <ReportApp
                    slideData={slideState.slide_data}
                     />, 
                allAppsState
    )
}

const getCapGuideApp = (slideState, allAppsState) => {
    return appify(keys.capGuideAppKey, 
                <CapGuideApp
                    slideData={slideState.slide_data}
                     />, 
                allAppsState
    )
}

const getPresentApp = (slideState, allAppsState) => {
    return appify(keys.presentAppKey, 
        <PresentApp
            id={keys.presentAppKey.id}
            slideData={slideState.slide_data}
            view={slideState.view} />, 
            allAppsState
    )
}

const getDisplaySizeCalibrationApp = (slideState, allAppsState) => {
    return appify(keys.displaySizeCalibrationKey, 
        <DisplaySizeCalibrationApp
            id={keys.displaySizeCalibrationKey.id}
            slideData={slideState.slide_data}
            view={slideState.view} />, 
            allAppsState
    )
}

// const getZStackApp = (slideState, allAppsState, updateTileCoordinates) => {
//     return appify(keys.ZStackKey, 
//         <ZStackApp
//             id={keys.ZStackKey.id}
//             slide_data={slideState.slide_data}
//             stackimage={slideState.stackimage}
//             imageLog={slideState.imageLog}
//             imageShape={slideState.imageShape}
//             updateTileCoordinates={updateTileCoordinates}
//             vectorLayer={slideState.vectorLayer} />, 
//             allAppsState
//     )
// }

const getZStackControlsApp = (slideState, allAppsState) => {
    return appify(keys.ZStackControlsKey, 
        <ZStackControls id={keys.ZStackControlsKey.id} />, 
            allAppsState
    )
}

const getTRFViewerApp = (slideState, allAppsState) => {
    return appify(keys.TRFViewerAppKey,
        <TRFApp 
        slideData={slideState.slide_data}/>,
        allAppsState
    )
}

const getBarcodeViewerApp = (slideState, allAppsState) => {
    return appify(keys.BarcodeViewerKey,
        <BarcodeViewer id={keys.BarcodeViewerKey.id}
        path={slideState.slide_data.path}/>,
            allAppsState
        )
}

const getCliaResultsApp = (slideState, allAppsState) => {
    console.log("slideState", slideState)
    return appify(keys.CliaResultsViewerKey,
            <CliaResultsApp 
                id = {keys.CliaResultsViewerKey.id}
                caseId = {slideState.slide_data.case}
                slideData = {slideState.slide_data}
                inValidationStudy = {slideState.slide_data.in_validation_study}
            />,
            allAppsState
        )
}

export const getAllApps = (slideState, allAppsState, updateTileCoordinates) => {
    if((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
        return [
            getSlideInfoApp(slideState, allAppsState),
            getZoomControlsApp(slideState, allAppsState), 
            // getPreviewApp(slideState, allAppsState)
        ]
    } else {
        return [
            getCaseInfoApp(slideState, allAppsState),
            getSlideInfoApp(slideState, allAppsState),
            getZoomControlsApp(slideState, allAppsState), 
            getPreviewApp(slideState, allAppsState),
            getAnnotationApp(slideState, allAppsState),
            getGridApp(slideState, allAppsState), 
            getScreenshotApp(slideState, allAppsState), 
            getSettingsApp(slideState, allAppsState),
            getReportApp(slideState, allAppsState),
            getCapGuideApp(slideState, allAppsState), 
            getIHCApp(slideState, allAppsState), 
            getPresentApp(slideState, allAppsState), 
            getDisplaySizeCalibrationApp(slideState, allAppsState), 
            // getZStackApp(slideState, allAppsState, updateTileCoordinates), 
            getZStackControlsApp(slideState, allAppsState),
            getTRFViewerApp(slideState, allAppsState),
            getBarcodeViewerApp(slideState, allAppsState),
            getCliaResultsApp(slideState, allAppsState),
        ]
    }
}
