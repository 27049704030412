import React, {Component} from "react";
import {connect} from 'react-redux';
import {Grid} from "@mui/material";
import AnnotationsExpandableList from "./AnnotationsExpandableList";
import {locateAnnotationOnMap} from "../utils/map_utils";
import {AnnotationsConstants} from "../../../utils/const";
import {annotationLayer} from "../utils/layers_info";

class AnnotationsApp extends Component {

    constructor(props) {
        super(props);

        this.drawer = annotationLayer.drawer;
    }

    initState = () => {
        this.mapState = this.props.mapsState[this.props.activeMapId];
        this.slideState = this.mapState.slideState;
        this.annotationState = this.mapState.annotationState;
    }

    emptyComponent = () => <Grid>
        No Annotations Available
    </Grid>;

    render() {
        this.initState();

        if (!(this.annotationState || {}).annotations) return this.emptyComponent();

        let annotations = this.annotationState.annotations.filter(annotation => annotation.anno_drawer === this.drawer);
        if (annotations.length === 0)
            return this.emptyComponent();

        return <AnnotationsExpandableList annotations={annotations}
                                          slideId={this.slideState.slide_data.id}
                                          onAnnotationClick={(annotation) =>
                                              locateAnnotationOnMap(this.slideState.slidemap, annotation)}
                                          colors={AnnotationsConstants.ANNO_COLORS}
                                          showDownload={true}/>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
        trigger: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(AnnotationsApp)
