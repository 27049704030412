import React from "react";
import { StarFilled } from '@ant-design/icons';
import { Tag, Spin, Tooltip } from 'antd';
import { getPreviewUrl } from '../../utils/utils';

const iconFontSize = "15px";

export const getPreviewComponent = (slide, is_cloud, isMobile) => {
    let previewPath = slide.preview_image;

    if (is_cloud === false) {
        previewPath = previewPath.replace("/static/", "");
        previewPath = getPreviewUrl(previewPath.replace('stitched_small_viewer', 'selection_marked'), slide);
    }
    return <img
        width={isMobile ? 100: 200}
        alt="logo"
        src={previewPath}
    />
}

export const getLabelComponent = (slide, is_cloud, isMobile) => {
    let labelPath = slide.preview_image;

    if (is_cloud === false) {
        labelPath = labelPath.replace("/static/", "");
        labelPath = getPreviewUrl(labelPath.replace('stitched_small_viewer', 'barcode_crop'), slide);
    }

    return <img
        height={96}
        alt="barcode-does-not-exist"
        src={labelPath}
    />
}

export const getNameComponentCaseSlide = (slide) => {
    return <span>{slide.name}</span>
}

export const getTagsComponent = (slide) => {
    return (
        <div>
            {slide.tags.map((tag, index) => {
            const isLongTag = tag.length > 20;
            let color = 'magenta';
            const tagElem = (
                <Tag key={tag} color={color}>
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
            );
            return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
            })}
        </div>
        );
}


export const getStarredComponent = (slide) => {
    return (
        <StarFilled
            style={{ color: slide.starred === true ? "#f5667b" : "", fontSize: iconFontSize, padding: 5, display: 'inline-table' }} />
    );
}