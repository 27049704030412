import {SearchAlertConstant} from "../actionTypes/search.constant";
import {urlEncoder} from "../action/slides.action";

export const getNewState = () => {
    return {
    isFetching : true,
    tags : [],
    searches : [],
    starred : false,
    barcode_failed : false,
    case_not_assigned : false,
    ordering : "-id",
    date__gte : '',
    date__lte : '', 
    page: 1, 
    numPages: 1, 
    count: 1
}}

const InitialState = getNewState();

export const searchReducer = ( state = InitialState , action) => {
    var returnableState = {}
    switch(action.type){
        case SearchAlertConstant.SEARCH_INITIATED:
            returnableState = {
                ...state,
                isFetching : true,
            }
            return returnableState;
        case SearchAlertConstant.SEARCH_SUCCESS:
            returnableState = {
                ...state,
                isFetching : false,
            }
            return returnableState;
        case SearchAlertConstant.SEARCH_FAILED:
            returnableState = {
                ...state, 
                err: action.error, 
                isFetching : false,
            }
            return returnableState;
        case SearchAlertConstant.UPDATE_URL_STATE:
            returnableState = Object.assign({}, action.urlState);
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertConstant.UPDATE_SEARCHES:
            returnableState = {
                ...state, 
            }
            returnableState.searches = action.searches;
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertConstant.UPDATE_TAGS:
            returnableState = {
                ...state, 
            }
            returnableState.tags = action.tags;
            updateUrl(returnableState);
            return returnableState;
        case SearchAlertConstant.CHANGE_PAGE:
            returnableState = {
                ...state
            }
            returnableState.page = action.page
        default :
            returnableState = state
            return returnableState;
    }
}

export const updateUrl = (state) => {
    var encodedUrl = urlEncoder(state);
    window.history.replaceState(state, 'filter_params', encodedUrl);
}