import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Descriptions, Divider, Input, Button, message, Spin, Checkbox } from 'antd';
import { ongoingMotionPreviewAction, updateDeviceIP, updateNumberOfSlots } from '../../../action/admin.state.action';
import { makePanZoomCanvas } from '../../../utils/panzoomcanvas';

import "../../../asset/style/manualmode/preview_app.css";

class PreviewApp extends Component {

    constructor(props) {
        super(props);

        let url = window.location.href.split('?')[1];
        let slot_id = -1;
        if (url != undefined) {
            let partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                let key = part.split('=')[0];
                let value = part.split('=')[1];
                if (key === 'slot_id') {
                    slot_id = parseFloat(value);
                }
            });
        }

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            numberOfSlots: 0,
            slotID: slot_id,
        }

        this.canvasRef = React.createRef();

        this.image = new Image();

        this.image.onload = this.onloadRunner;
        window.addEventListener("load", this.onloadRunner, false);

        this.onloadRunner = this.onloadRunner.bind(this);

    }

    componentDidMount = () => {
        this.getNumberOfSlots();
    }

    onloadRunner = () => {

        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));

        let canvas = this.canvasRef.current; 

        makePanZoomCanvas(canvas, this.image);

        var self=this;

        canvas.addEventListener('dblclick', function(e) { 

            let ctx = canvas.getContext("2d");
            let border = parseInt(getComputedStyle(canvas, null).getPropertyValue('border-left-width'));

            let x = parseInt(e.offsetX) - border;
            let y = parseInt(e.offsetY) - border;

            let pt = ctx.transformedPoint(x, y);

            self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, true));
            let url = "/server/devices/" + self.props.currentState.deviceId + "/settings/preview_tuning/move_to_pixel/?slot_id=" +
            self.state.slotID + "&x=" + Math.trunc(pt.x) + "&y=" + Math.trunc(pt.y);
            
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                    }
                    else {
                        console.log(response);
                        message.error("Not able to move to the specified position!!", 2.5);
                    }
                    self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
                })
            .catch(err => {
                console.log(err);
                message.error("Not able to move to the specified position!!", 2.5);
                self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
            });
        });
    }

    getNumberOfSlots = () => {
        this.setState({
            initError: false,
            errorMessage: '',
        });
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/manualmode/device_details";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        deviceIP: response.data.device_ip,
                        numberOfSlots: response.data.number_of_slots,
                    });
                    this.props.dispatch(updateDeviceIP(response.data.device_ip));
                    this.props.dispatch(updateNumberOfSlots(response.data.number_of_slots));
                    this.getPreview();
                }
                else {
                    console.log(response);
                    message.error("Not able to get number of slots!!", 2.5);
                    this.setState({
                        initError: true,
                        errorMessage: 'Not able to get number of slots!!',
                    });
                }
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get number of slots!!", 2.5);
                this.setState({
                    initError: true,
                    errorMessage: 'Not able to get number of slots!!',
                });
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
    }

    changeSlot = (slot_id) => {
        window.location.href = window.location.href.split("?")[0] + "?slot_id=" + slot_id;
    }

    getPreview = () => {
        if(this.state.slotID >= 0) {
            this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
            this.image.src = "http://" + this.state.deviceIP + "/api/preview/uncropped?slot_id=" + this.state.slotID + '&time=' + Date.now();
        }
    }

    render() {

        let slotsButton = [];
        let spanSize = 18 / this.state.numberOfSlots;


        for(let i = 0; i < this.state.numberOfSlots; i++) {
            slotsButton.push(
                <Col span={spanSize} key={i}>
                    <Button type={this.state.slotID == i ? "danger" : "primary"} size="small" onClick={() => this.changeSlot(i)}>
                        {i + 1}
                    </Button>
                </Col>
            )
        }

        return (
            <Row className="app-parent overlayed-component preview-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Row>
                                <Col offset={1} span={3}>
                                    <div style={{marginTop: 2}}>
                                        <b>Select Slot: </b>
                                    </div>
                                </Col>
                                {slotsButton}
                            </Row>
                            <br/>
                            <Row>
                                <Col offset={1}>
                                    <canvas ref={this.canvasRef} id="preview-canvas" width="800" height="300"></canvas>
                                </Col>
                            </Row>
                            
                        </Row>
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer
    }
}

export default connect(mapStateToProps)(PreviewApp);
