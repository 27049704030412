import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Row, message, Col, Input, Switch, Spin } from 'antd';
import { globalUrlPrefix } from '../utils/const';
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { getUnauthorizedComponent } from '../component/dashboard/slidelist_utils';
import '../asset/style/add_case.css';
import {displayError} from "../helper/display.error";
var querystring = require('querystring');

class EditUserView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            userId: this.props.match.params.id,
            username: '',
            firstName: '',
            lastName: '',
            isStaff: 0,
            isAudience: 0,
        }
    }

    componentDidMount = () => {
        this.getUserInfo();
    }

    getUserInfo = () => {
        this.setState({
            isFetching: true,
        });
        let url = `/api/user/` + this.state.userId + "/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    isFetching: false,
                    username: response.data.username,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name,
                    isStaff: response.data.is_staff ? 1 : 0,
                    isAudience: response.data.morpheus_setting.is_audience ? 1 : 0,
                });
            })
            .catch(err => {
                this.setState({
                    isFetching: false,
                });
                message.error("Failed to fetch user details. Contact Admin");
                console.log(err);
            });
    }

    changeFirstName = (event) => {
        this.setState({
            firstName: event.target.value
        });
    }

    changeLastName = (event) => {
        this.setState({
            lastName: event.target.value
        });
    }

    changeIsStaff = (checked) => {
        this.setState({
            isStaff: checked,
        });
    }

    changeIsAudience = (checked) => {
        this.setState({
            isAudience: checked,
        });
    }

    saveUser = () => {
        this.setState({
            isFetching: true,
        });
        let val = {
            id: this.state.userId,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            is_staff: this.state.isStaff,
            is_audience: this.state.isAudience,
        }

        let url = `/api/edit_user/`;
        axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
            .then(() => {
                this.setState({
                    isFetching: false,
                });
                window.location.href = "/" + globalUrlPrefix + "/manageusers/";
            })
            .catch(err => {
                this.setState({
                    isFetching: false,
                });
                displayError("Failed to Create User. Contact Admin", err);
            });

    }

    render() {

        return (
            (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ?
                <div>
                    <Spin spinning={this.state.isFetching}>
                        <Row>
                            <Col span={24} offset={0} className="all-slides">
                                <div className="heading-style main-heading-style">
                                    <b>Edit User</b>
                                </div>
                                <div>
                                    <Row>
                                        <Col offset={2} pull={1}>
                                            <div>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Username</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Input placeholder="Username" value={this.state.username}
                                                            className="input-field-add-case" style={{color: 'black'}}
                                                            onChange={this.changeUsername} autoComplete="off" disabled
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>First Name</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Input placeholder="First Name" value={this.state.firstName}
                                                            className="input-field-add-case" onChange={this.changeFirstName} autoComplete="off" />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Last Name</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Input placeholder="Last Name" value={this.state.lastName} className="input-field-add-case"
                                                            onChange={this.changeLastName} autoComplete="off" />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Is Staff</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Switch checked={this.state.isStaff} onChange={this.changeIsStaff} />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Is Audience</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Switch checked={this.state.isAudience} onChange={this.changeIsAudience} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>

                                <div className="submit-row-add-case">
                                    <Row>
                                        <Col span={2}>
                                            <Button type="primary"
                                                onClick={this.saveUser}
                                                disabled={!(this.state.username.length > 0 && this.state.firstName.length > 0)}>
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Spin>    
                </div>
                : <div>
                    {getUnauthorizedComponent()}
                </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        allUsernames: state.searchUserReducer.usernames,
    }
}

export default connect(mapStateToProps)(EditUserView);
