import {AnnoAlertConstant} from "../actionTypes/anno.constant";

const InitialState = {
    id : null,
    deleteId : null,
    select : false,
    data: {},
    isFetching: false,
    error: {}
}

export const annoUpdate = ( state = InitialState , action) => {
    switch(action.type){
        case AnnoAlertConstant.ANNO_UPDATE_INITIATED:
            return{
                ...state,
                isFetching : true,
            }
        case AnnoAlertConstant.ANNO_UPDATE_SUCCESS:
            return{
                ...state,
                isFetching : false,
                data : action.response
            }
        case AnnoAlertConstant.ANNO_UPDATE_FAILED:
            return{
                ...state,
                error : action.response,
                select : true
            }
        case AnnoAlertConstant.ANNO_SELECT_ID:
            return{
                ...state,
                id : action.response
            }
        case AnnoAlertConstant.ANNO_DELETE_SUCCESS:
            return{
                ...state,
                deleteId : action.response
            }
        default :
            return state
    }
}