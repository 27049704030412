import {DeviceAlertConstant} from '../actionTypes/device.constant';
import axios from 'axios';
import {AuthHeader} from "../helper/auth.token"

export const fetchDevicesList = () => ({
    type : DeviceAlertConstant.FETCHING_DEVICES_LIST
})

export const receivedDevicesListResponse = (type, response) => ({
    type : type,
    response : response
})

export const recievedDeviceProperties = (type, response) => ({
    type : type,
    response : response
})

export const getDevicesList = () => (dispatch) => {
    dispatch(fetchDevicesList())
    let url = `/api/device/`
    axios.get(url , {headers: {Authorization : AuthHeader()}})
        .then(response => {
            if(response.status === 200){
                dispatch(receivedDevicesListResponse(DeviceAlertConstant.RECEIVED_DEVICES_LIST, response))
            }
            else{
                dispatch(receivedDevicesListResponse(DeviceAlertConstant.FAILED_TO_RECEIVE_DEVICES_LIST, response))
            }
        })
        .catch(err => {
            dispatch(receivedDevicesListResponse(DeviceAlertConstant.FAILED_TO_RECEIVE_DEVICES_LIST, err))
            console.log(err)
        })
}

export const getDeviceProperties = (deviceId) => (dispatch) => {
    const url = "/server/devices/" + deviceId + "/settings/get_scanner_properties";
        axios
        .get(url)
        .then(response => {
            // for (let i = 0; i < res.data.cassetteSize; i++) {
            //     this.initSlot(i, res.data.defaultConfig);
            // }
            // this.setState(Object.assign({}, this.state, {
            //     cassetteSize: res.data.cassetteSize,
            //     isObjectiveSwitcherPresent: res.data.isObjectiveSwitcherPresent,
            //     allowScanOutputFormatChange: res.data.allowScanOutputFormatChange, 
            //     allowObjectiveChange: res.data.allowObjectiveChange,
            //     defaultConfig: res.data.defaultConfig
            // }));
            if (response.status === 200) {
                dispatch(recievedDeviceProperties(DeviceAlertConstant.RECIEVED_DEVICE_PROPERTIES, response))
            }
        })
        .catch(err => {
            console.log(err);
        });
}
