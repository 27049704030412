export const brightnessKey = {
    id: 'brightness', 
    name: 'Brightness', 
    cssKey: 'brightness',
    unit: "", 
    min: 0, 
    max: 100,
    multiplier: 2
}
export const contrastKey = {
    id: 'contrast', 
    name: 'Contrast', 
    cssKey: 'contrast',
    unit: "", 
    min: 0, 
    max: 100,
    multiplier: 2
}
export const hueKey = {
    id: 'hue', 
    name: 'Hue', 
    cssKey: 'hue-rotate',
    unit: "deg", 
    min: 0, 
    max: 100,
    multiplier: 360
}
export const saturationKey = {
    id: 'saturation', 
    name: 'Saturation', 
    cssKey: 'saturate',
    unit: "", 
    min: 0, 
    max: 100,
    multiplier: 1
}
export const invertKey = {
    id: 'invert', 
    name: 'Invert', 
    cssKey: 'invert',
    unit: "", 
    min: 0, 
    max: 100,
    multiplier: 1
}
export const grayscaleKey = {
    id: 'grayscale', 
    name: 'Grayscale', 
    cssKey: 'grayscale',
    unit: "", 
    min: 0, 
    max: 100,
    multiplier: 1
}