import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from "redux-logger";
import { rootReducer } from '../reducer';
import createSagaMiddleware from 'redux-saga';
import { DeviceAlertConstant } from '../actionTypes/device.constant';
import { ScanApiConstant } from '../actionTypes/scan.constant'
import { SlideActions } from '../actionTypes/slides.constant'

const loggerMiddleware = createLogger({
    predicate: (getState, action) => 
    action.type !== DeviceAlertConstant.RECEIVED_DEVICE_STATUS &&
    action.type !== ScanApiConstant.INIT_PREVIEW && 
    action.type !== ScanApiConstant.UPDATE_LIVE_VIEW &&
    action.type !== SlideActions.UPDATE_POLLING, 
  });

export const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            thunk,
            // loggerMiddleware,
            sagaMiddleware
        )
    )
)