import '@ant-design/compatible/assets/index.css';
import { CloudUploadOutlined, DeleteFilled, InfoCircleOutlined, StarFilled, ToTopOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import axios from "axios";
import _ from 'lodash';
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Table, Button, Row, Modal, message, Col, Divider, Upload, Input, Empty, Descriptions, Select } from 'antd';
import { changePage } from '../action/search.action';
import { deleteSlide, descUpdateAlert, slideUpdateAlert, startTiling, startUpload } from '../action/slides.action';
import '../asset/style/add_case.css';
import '../asset/style/custom_antd_table_style.css';
import SlideSuggestions from "../component/case/add.slide.modal";
import { AuthHeader } from "../helper/auth.token";
import { historyMP } from "../helper/history";
import { globalUrlPrefix, openInNewTabType, slideViewType } from '../utils/const';
import { getTilingComponent, getTagsComponent, getPreviewComponent, getLabelComponent, getDeleteComponent, getStarredComponent, getDbEntryComponent, getUploadComponent, getMorphleIDComponent, calculateColumnsWidth, decodeStringWithNewLine, getLoadingComponent, getErrorComponent, getAssignCaseComponent, getExportComponent, getObjectiveComponent, getAssignCaseButtonComponent, getScreenshotCarousel } from "../component/dashboard/slidelist_utils";
import { StopOutlined, PictureOutlined } from '@ant-design/icons';
import { makeMenuVisible } from "../action/context.menu.action";
import {getFileExtension, linkViewer, linkViewerNewTab} from "../utils/utils";

const { TextArea } = Input;
const { Option } = Select;

const EditableContext = React.createContext();

const EditableFormRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

class EditableCell extends Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

const ButtonGroup = Button.Group;

class AllScansCaseView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetchingCase: true,
            isFetchingFiles: true,
            isFetchingFilesSource: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            isFetchingComments: true,
            hasCaseThrownError: true,
            hasSlidesThrownError: true,
            hasCommentsThrownError: true,
            hasFilesThrownError: true,
            hasFilesSourceThrownError: true,
            hasPendingScansThrownError: true,
            selectedRowKeys: [],
            caseScans: {},
            caseScansArray: [],
            previewVisible: false,
            showFile: false,
            previewFile: '',
            caseFileList: [],
            caseFileListSource: [],
            currCase: [],
            pendingScans: [],
            comments: [],
            addSlideModalVisible: false,
            currentHoverRowID: -1,
            screenshotVisible: false,
            screenshots: [],
            sortingMethod: '-date_time'
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidMount = () => {
        this.getCase();
    }

    getCase = () => {
        this.setState({
            hasCaseThrownError: true,
            hasFilesThrownError: true,
            isFetchingCase: true,
            isFetchingFiles: true,
        });
        let url = `/api/case/` + this.props.case + `/`;
        axios
            .get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(response => {
                this.setState({
                    currCase: response.data,
                    isFetchingCase: false,
                    hasCaseThrownError: false,
                });

                let caseFiles = _.filter(this.state.currCase.files, (file) => {
                    return file
                });

                let caseFileList = _.map(caseFiles, (caseFile) => {
                    let newImage = {
                        uid: caseFile.id,
                        name: caseFile.path.split('/').pop(),
                        status: 'done',
                        url: caseFile.path,
                        label: caseFile.label,
                        storage_path: caseFile.storage_path
                    }
                    return newImage;
                });

                this.getCaseSlides(response.data);

                this.get_comments();

                this.setState({
                    caseFileList: caseFileList,
                    isFetchingFiles: false,
                    hasFilesThrownError: false,
                })

            })
            .catch(err => {
                message.error("Cases could not be retrieved. Contact Admin.");
                this.setState({
                    currCase: [],
                    caseFileList: [],
                    isFetchingCase: false,
                    isFetchingFiles: false,
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                    isFetchingFilesSource: false,
                    hasSlidesThrownError: true,
                    hasPendingScansThrownError: true,
                    hasCaseThrownError: true,
                    hasFilesThrownError: true,
                    hasFilesSourceThrownError: true,
                });
                console.log(err);
            });
    }

    getCaseSlides = (currCase) => {
        this.setState({
            hasSlidesThrownError: true,
            isFetchingSlides: true,
            isFetchingPendingScans: true,
            hasPendingScansThrownError: true,
        });
        let url = `/api/nonpagslides/?case_id=` + currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                let allSlidesUpdated = {};
                for (let i = 0; i < response.data.length; i++) {
                    allSlidesUpdated[i] = response.data[i];
                }

                this.setState({
                    caseScans: allSlidesUpdated,
                    caseScansArray: response.data,
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    hasSlidesThrownError: false,
                })
            })
            .catch(err => {
                message.error("Slides could not be retrieved. Contact Admin.");
                console.log(err);
                this.setState({
                    caseScans: {},
                    caseScansArray: [],
                    selectedRowKeys: [],
                    previewVisible: false,
                    showFile: false,
                    previewFile: '',
                    isFetchingSlides: false,
                    isFetchingPendingScans: false,
                })
            });
    }

    get_comments = () => {
        let url = '/api/microscopy_observations/?case_id=' + this.state.currCase.id;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let data = response.data;
                for (let i = 0; i < data.length; i++) {
                    data[i].key = data[i].id;
                    let modifiedValue = decodeStringWithNewLine(data[i].observation);
                    data[i].observation_original = data[i].observation;
                    data[i].observation = modifiedValue;
                }
                this.setState({
                    comments: data,
                    isFetchingComments: false,
                    hasCommentsThrownError: false,
                });
            })
            .catch(err => {
                console.log("Failed request", err);
                this.setState({
                    comments: [],
                    isFetchingComments: false,
                });
            });
    }

    handleUpload = (morphle_id, id, allIds, uploadDebug, uploadDebugOnly) => {
        this.props.dispatch(startUpload(morphle_id, id, allIds, uploadDebug, uploadDebugOnly));
    }

    handleDelete = (slide, button) => {
        if (button) {
            let newCaseScans = {};
            let newCaseScansArray = [];
            for (let i = 0; i < this.state.caseScansArray.length; i++) {
                if (slide.id != this.state.caseScansArray[i].id) {
                    newCaseScans[i] = this.state.caseScans[i];
                    newCaseScansArray.push(this.state.caseScansArray[i]);
                }
            }
            this.setState({
                caseScans: newCaseScans,
                caseScansArray: newCaseScansArray
            });
        }
        this.props.dispatch(deleteSlide(slide, this.props.urlState));
    }

    starSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], true);
        });
    }

    uploadSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            let slide = this.state.caseScans[value];
            this.props.dispatch(startUpload(slide.morphle_id, slide.id, this.props.allIds, false, false));
        });
    }

    tileSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.props.dispatch(startTiling(this.state.caseScans[value].morphle_id, this.props.allIds));
        });
    }

    unstarSelectedRows = () => {
        this.state.selectedRowKeys.map((value, index) => {
            this.handleStarButton(this.state.caseScans[value], false);
        });
    }

    deleteSelectedRows = (e) => {
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            this.handleDelete(this.state.caseScans[value], false);
        });
        let newCaseScans = {};
        let newCaseScansArray = [];
        for (let i = 0; i < this.state.caseScansArray.length; i++) {
            if (!this.state.selectedRowKeys.includes(i)) {
                newCaseScans[i] = this.state.caseScans[i];
                newCaseScansArray.push(this.state.caseScansArray[i]);
            }
        }
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        message.loading("Scheduling slides for Deletion");
    }

    handleStarButton = (slide, input) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].starred = input;
        newCaseScansArray[index].starred = input;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: slide.name,
            starred: input,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    handleNameChange = (slide, newName) => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => slide.id === item.id);
        newCaseScans[index].name = newName;
        newCaseScansArray[index].name = newName;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        let val = {
            morphle_id: slide.morphle_id,
            path: slide.path,
            date: slide.date,
            specimen_type: slide.specimen_type,
            name: newName,
            starred: slide.starred,
            displayOrder: slide.displayOrder
        };
        this.props.dispatch(slideUpdateAlert(slide.id, val));
    };

    openDeleteModal = () => {

        var deletedIDs = "";

        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            if (index != this.state.selectedRowKeys.length - 1) deletedIDs += (this.state.caseScans[value] || {}).name + ", ";
        });

        deletedIDs += this.state.caseScans[this.state.selectedRowKeys[this.state.selectedRowKeys.length - 1]].name;

        const deleteRowsHandle = this.deleteSelectedRows;

        Modal.confirm({
            title: 'Following scans will be deleted. Confirm?',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (<Row>{deletedIDs}</Row>),
            onOk() { deleteRowsHandle() },
            onCancel() { }
        });
    }

    removeFromCase = () => {
        let slides = []
        this.state.selectedRowKeys.map((value, index) => {
            if (this.state.caseScans[value] == undefined) return;
            slides.push(this.state.caseScans[value].id)
        });
        let url = `/api/remove_slides_from_case/?slides=${slides}`
        axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                if (response.status == 200) {
                    let caseScans = this.state.caseScansArray;
                    slides.map((slideValue, slideIndex) => {
                        caseScans.map((caseValue, caseIndex) => {
                            if (slideValue == caseValue.id) {
                                caseScans.splice(caseIndex, 1)
                            }
                        })
                    })
                    this.setState({
                        caseScansArray: caseScans,
                        selectedRowKeys: [],
                    })
                    message.success("Successfully removes slides");
                } else {
                    message.error("Error removing slides. Please contact Admin");
                }
            })
            .catch(err => {
                message.error("Error removing slides. Please contact Admin")
                console.log("Error removing slide. Please contact Admin");
            })
    }

    getRowActionsIfAtleastOneRowSelected = () => {
        if (this.state.caseScansArray.length > 0) {
            return this.state.selectedRowKeys.length > 0 ?
                <ButtonGroup key={1}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                    <Button key={0} onClick={() => { this.setState({ selectedRowKeys: [] }) }}>
                        Cancel Selection
                        </Button>
                    {cookie.loadAll().superuser === "true" ?
                        <Button key={1} onClick={this.tileSelectedRows}>
                            Start Tiling
                        </Button> : undefined}
                    {cookie.loadAll().deployment_mode === 'offline' ?
                        <Button key={-1} onClick={this.uploadSelectedRows}>
                            Upload
                            <CloudUploadOutlined style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={2} onClick={this.starSelectedRows}>
                        Star
                            <StarFilled style={{ color: "#f5667b", fontSize: "13px" }} />
                    </Button>
                    <Button key={3} onClick={this.unstarSelectedRows}>
                        Unstar
                            <StarFilled style={{ fontSize: "13px" }} />
                    </Button>
                    {cookie.loadAll().is_staff === "true" ?
                        <Button key={4} onClick={this.openDeleteModal}>
                            Delete
                                <DeleteFilled style={{ fontSize: "13px" }} />
                        </Button> : undefined}
                    <Button key={3} onClick={this.removeFromCase}>
                        Remove From Case
                        <StopOutlined />
                    </Button>
                </ButtonGroup> :
                <>
                <Col span={16}>
                    <Button onClick={() => { this.setState({ selectedRowKeys: Object.keys(this.state.caseScans).map((value) => parseInt(value)) }) }}>
                        Select All Scans
                    </Button>
                </Col>
                <Col span={2}>
                    <Select
                        value={this.state.sortingMethod}
                        placeholder="Sort List"
                        onChange={this.handleSortingMethodChange}
                        style={{width:230}}
                    >
                        <Option value="name">
                            {" "}
                            A-Z
                        </Option>
                        <Option value="-name">
                            {" "}
                            Z-A
                        </Option>
                        <Option value="date_time">
                            Oldest First
                        </Option>
                        <Option value="-date_time">
                            Recent First
                        </Option>
                        <Option value="number">
                            By Slide Number
                        </Option>
                    </Select>
                </Col>
              </>
        } else {
            return null;
        }

    }

    getRowActions = () => {
        return <Row>
            <Col>
                <span key={0} style={{ marginRight: "10px" }}>
                    {this.state.selectedRowKeys.length > 0 ? "What do you want to do with the selected scans ? " : ""}
                </span>
                {this.getRowActionsIfAtleastOneRowSelected()}
            </Col>
        </Row>
    }

    updatePage = (pageNumber) => {
        historyMP.push('/' + globalUrlPrefix + '/dashboard?page=' + pageNumber);
        this.props.dispatch(changePage(pageNumber, this.props.urlState));
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        let fileExtension = getFileExtension(file.name);
        if (fileExtension == 'jpg' || fileExtension == 'jpeg' || fileExtension == 'png') {
            this.setState({
                showFile: true,
            });
        } else {
            this.setState({
                showFile: false,
            });
        }
        this.setState({
            previewFile: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handleScreenshotPreview = (slide) => {
        this.setState({ 
            screenshotVisible: true, 
            screenshots: slide.screenshots 
        });
    }

    handleScreenshotCancel = () => {
        this.setState({
            screenshotVisible: false
        })
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handleChangeCaseFile = ({ fileList, file }) => {
        let flag = 0;
        let caseFileList = _.map(fileList, (caseFile) => {
            if (caseFile.response) {
                if (caseFile.response.result) {
                    let newImage = {
                        uid: caseFile.response.result.id,
                        name: caseFile.response.result.path.split('/').pop(),
                        status: 'done',
                        url: caseFile.response.result.path,
                        label: caseFile.response.result.label,
                        storage_path: caseFile.response.result.storage_path
                    }
                    return newImage;
                } else {
                    flag = 1;
                }
            } else {
                return caseFile;
            }
        });
        this.setState({
            caseFileList: caseFileList
        });
        if (flag != 0) {
            message.error("Failed to Upload File. Contact Admin");
        }

    }

    beforeUpload(file, fileList) {
        if (file.type == "application/pdf" || file.type.substring(0, 5) == "image") {
            return true;
        } else {
            message.error('Invalid file type selected!!');
            return false;
        }
    }

    removeFileFromCase = (file) => {
        let url = `/api/remove_case_file/?case_id=${this.state.currCase.case_id}&file=${file.uid}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let slide_file_list = this.state.slideFileList;
                _.remove(slide_file_list, (val) => {
                    return file.uid === val.uid
                });
                this.setState({
                    slideFileList: slide_file_list
                });
                message.success("Removed Slide Image from report " + file.name);
            })
            .catch(err => {
                message.error("Failed to Save Case. Contact Admin");
                console.log(err);
            });

    }

    getIntegersfromString = (string) => {
        let matches = string.match(/([0-9]+)/g)
        if (matches == null) {
            return 0
        } 
        return matches[0]
    }

    getLexicographicalSorting = (allSlides) => {
        return Object.keys(allSlides).sort(
            function (a, b) {
                return allSlides[a].name.localeCompare(allSlides[b].name);
            }
        )
    }

    getReverseLexicographicalSorting = (allSlides) => {
        return Object.keys(allSlides).sort(
            function (a, b) {
                return allSlides[b].name.localeCompare(allSlides[a].name);
            }
        )
    }

    getNumericalSorting = (allSlides, getIntegersfromString) => {
        return Object.keys(allSlides).sort(
            function (a, b) {
                return getIntegersfromString(allSlides[a].name) - getIntegersfromString(allSlides[b].name);
            }
        )
    }

    getChronologicalSorting = (allSlides) => {
        return Object.keys(allSlides).sort(
            function (a, b) {
                return new Date(allSlides[a].date).getTime() - new Date(allSlides[b].date).getTime();
            }
        ) 
    }

    getReverseChronologicalSorting = (allSlides) => {
        return Object.keys(allSlides).sort(
            function (a, b) {
                return new Date(allSlides[b].date).getTime() - new Date(allSlides[a].date).getTime();
            }
        )
    }

    handleSortingMethodChange = (value) => {
        this.setState({
            sortingMethod: value,
        })
    }

    getTableSource = (allSlides, getIntegersfromString, sortingMethod) => {
        let allSlidesList = []
        let sortedAllSlides = []

        switch (sortingMethod) {
            case 'name':
                sortedAllSlides = this.getLexicographicalSorting(allSlides)
                break;
            case '-name':
                sortedAllSlides = this.getReverseLexicographicalSorting(allSlides)
                break;
            case 'number':
                sortedAllSlides = this.getNumericalSorting(allSlides, getIntegersfromString)
                break;
            case 'date_time':
                sortedAllSlides = this.getChronologicalSorting(allSlides)
                break;
            case '-date_time':
                sortedAllSlides = this.getReverseChronologicalSorting(allSlides)
                break;
            default:
                break;
        }

        sortedAllSlides.map((key) => {
            let slide = allSlides[key];
            if (slide.slide_details == undefined || slide.slide_details == "") {
                slide.slide_details = "-";
            }
            if (!slide.isDeleted) {
                allSlidesList.push({
                    key: parseInt(key),
                    id: slide.id,
                    slide_details: slide.slide_details,
                    unread: slide.unread,
                    type: openInNewTabType.SLIDE,
                    slideCard: this.getSlideCard(slide),
                })
            }
        });
        return allSlidesList;
    }

    getSlideCard = (slide) => {
        return <div>
            <Row>
                <Col span={3} style={{ marginTop: 35 }}>
                    <Row>
                        <Col span={8}>
                            {getStarredComponent(slide, this.handleStarButton)}
                        </Col>
                        <Col span={8}>
                            {getUploadComponent(slide, this.props.allIds, this.handleUpload)}
                        </Col>
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={8}>
                                {getTilingComponent(slide, this.props.allIds, this.props.dispatch)}
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                <Col span={8} style={{ marginTop: 10 }}>
                    <Row>
                        {slide.unread ?
                            <b>{slide.name}</b> :
                            <span style={{fontWeight: 400}}>{slide.name}</span>
                        }
                    </Row>
                    {slide.case_id.length > 0 ?
                        <Row style={{ marginTop: 5, fontSize: 13 }}>
                            <span>{"CASE ID: " + slide.case_id}</span>
                        </Row> :
                        this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ?
                            <Row style={{ marginTop: 5 }}>
                                {getAssignCaseButtonComponent(slide, this.handleAssignCase)}
                            </Row> :
                            null
                    }
                    <Row style={{ marginTop: 10 }}>
                        {getTagsComponent(slide, this.props.dispatch, this.props.urlState)}
                    </Row>
                </Col>
                <Col span={4}>
                    {getPreviewComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={2}>
                    {getLabelComponent(slide, this.is_cloud, this.isMobile)}
                </Col>
                <Col span={slide.screenshots.length == 0 ? 7 : 6}>
                    {this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ?
                        <div>
                            <Row style={{ marginTop: 10 }}>
                                <Col offset={cookie.loadAll().superuser === "true" ? 4 : cookie.loadAll().is_staff === "true" ? 16 : 20} span={4}>
                                    {getAssignCaseComponent(slide, this.handleAssignCase)}
                                </Col>
                                {cookie.loadAll().is_staff === "true" ?
                                    <Col span={4}>
                                        {getExportComponent(slide, this.props.allIds, this.props.dispatch)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getDbEntryComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" ?
                                    <Col span={4}>
                                        {getMorphleIDComponent(slide)}
                                    </Col> :
                                    null
                                }
                                {cookie.loadAll().superuser === "true" || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_delete_slide_action ?
                                    <Col span={4}>
                                        {getDeleteComponent(slide, this.handleDelete)}
                                    </Col> :
                                    null
                                }
                            </Row>
                            <br />
                        </div> :
                        null
                    }
                    <Row style={this.state.currentHoverRowID == slide.id && cookie.loadAll().is_staff === "true" ? {} : { marginTop: 35 }}>
                        <Col offset={cookie.loadAll().superuser === "true" ? 0 : cookie.loadAll().is_staff === "true" ? 3 : 18} span={6}>
                            {slide.unread ?
                                <b>{slide.date}</b> :
                                <span>{slide.date}</span>
                            }
                        </Col>
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={5}>
                                {slide.unread ?
                                    <b>{slide.date}</b> :
                                    <span>{slide.date}</span>
                                }
                            </Col> :
                            null
                        }
                        {cookie.loadAll().superuser === "true" ?
                            <Col span={3}>
                                {getObjectiveComponent(slide, slideViewType.CASEVIEW)}
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={4}>
                                {slide.unread ?
                                    <b>{slide.scan_speed}</b> :
                                    <span>{slide.scan_speed}</span>
                                }
                            </Col> :
                            null
                        }
                        {cookie.loadAll().is_staff === "true" ?
                            <Col span={6}>
                                {slide.unread ?
                                    <b>{slide.scan_time}</b> :
                                    <span>{slide.scan_time}</span>
                                }
                            </Col> :
                            null
                        }
                    </Row>
                </Col>
                { slide.screenshots.length === 0 ? null :
                <Col span={1}>
                    <PictureOutlined onClick={(e) => {e.stopPropagation(); this.handleScreenshotPreview(slide)}} className="icon-hover" 
                        style={{marginTop: '25px', fontSize: '23px', marginLeft: '10px', padding: '10px'}} />
                </Col>}
            </Row>
        </div>
    }

    handleSave = row => {
        let newCaseScans = this.state.caseScans;
        let newCaseScansArray = this.state.caseScansArray;
        let index = newCaseScansArray.findIndex((item) => row.id === item.id);
        newCaseScans[index].slide_details = row.slide_details;
        newCaseScansArray[index].slide_details = row.slide_details;
        this.setState({
            caseScans: newCaseScans,
            caseScansArray: newCaseScansArray
        });
        this.sendDescToCloud(row.id, row.slide_details, newCaseScansArray[index].displayOrder);
    };

    sendDescToCloud = (slideId, desc, displayOrder) => {
        this.props.dispatch(descUpdateAlert(slideId, desc, displayOrder));
    };

    toAllSlidesPage = (e) => {
        this.setState({
            addSlideModalVisible: true,
        });
    }

    closeModal = () => {
        this.getCase();
        this.setState({
            addSlideModalVisible: false,
        });
    }

    render() {

        const comments_columns = [
            {
                title: 'User',
                dataIndex: 'user_name',
                key: 'user_name',
                width: 100,
            },
            {
                title: 'Scan Name',
                dataIndex: 'slide_name',
                key: 'slide_name',
                width: 200,
            },
            {
                title: 'Observation',
                dataIndex: 'observation',
                key: 'observation',
            },
        ];

        let overallFetchingState = (this.state.isFetchingCase && this.state.isFetchingComments && this.state.isFetchingFiles
            && this.state.isFetchingPendingScans && this.state.isFetchingSlides);

        let overallErrorState = (this.state.hasCaseThrownError && this.state.hasCommentsThrownError && this.state.hasFilesThrownError
            && this.state.hasPendingScansThrownError && this.state.hasSlidesThrownError);

        let tableSource = [];

        tableSource = this.getTableSource(this.state.caseScansArray, this.getIntegersfromString, this.state.sortingMethod);

        let finalColumns = [{
            dataIndex: 'slideCard',
            key: 'slideCard',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys: selectedRowKeys
                })
            },
            selectedRowKeys: this.state.selectedRowKeys
        };

        const onRow = (record, rowIndex) => {
            return {
                onContextMenu: (event) => {
                    event.preventDefault();
                    this.props.dispatch(makeMenuVisible({ visible: true, x: event.clientX, y: event.clientY, record: record, height: this.props.height }));
                },
                onMouseEnter: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: record.id,
                    });
                },
                onMouseLeave: (event) => {
                    event.preventDefault();
                    this.setState({
                        currentHoverRowID: -1,
                    });
                }
            };
        }

        let rowActions = this.isMobile ? undefined :
            [
                <Divider key={3} />,
                <Row key={0} className="row-actions">
                    {this.getRowActions()}
                </Row>
            ];

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        finalColumns = finalColumns.map(col => {
            if (col.dataIndex === 'tagsComponent') {
                return col;
            } else {
                return {
                    ...col,
                    onCell: record => ({
                        onClick: (event) => {
                            if (event.ctrlKey) {
                                linkViewerNewTab(record);
                            } else {
                                linkViewer(record);
                            }
                        },
                    }),
                };
            }
        });

        if (overallFetchingState || overallErrorState) {
            this.props.setTabsView(false);
        } else {
            this.props.setTabsView(true);
        }

        return (overallFetchingState) ?
            getLoadingComponent() :
            (overallErrorState) ?
                getErrorComponent() :
                <>
                <div>

                    <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Details</b>
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col offset={1}>
                                {this.state.isFetchingCase ? getLoadingComponent() :
                                    this.state.hasCaseThrownError ? getErrorComponent("Not able to retrieve the case details.") :
                                        <div>
                                            <Descriptions size="small" column={3}>
                                                <Descriptions.Item label={<b>Case ID</b>}>{this.state.currCase.case_id}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Patient's Name</b>}>{this.state.currCase.patient_name}</Descriptions.Item>
                                                <Descriptions.Item label={<b>DOB</b>}>{this.state.currCase.age_and_sex}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Receiving Date</b>}>{this.state.currCase.receiving_date}</Descriptions.Item>
                                                <Descriptions.Item label={<b>Reporting Date</b>}>{this.state.currCase.reporting_date}</Descriptions.Item>
                                            </Descriptions>
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>

                    <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Case Scans</b>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {cookie.loadAll().is_staff === "true" ?
                            <Button onClick={this.toAllSlidesPage}>
                                Add Slides to Case
                            </Button> : null
                        }
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col>
                                {this.state.isFetchingSlides ? getLoadingComponent() :
                                    this.state.hasSlidesThrownError ? getErrorComponent("Not able to retrieve slides of the case.") :
                                        <div>
                                            <Row key={0} className="fixed-controls-dashboard" id="fixed-controls-dashboard">
                                                <Col span={24}>
                                                    {rowActions}
                                                    <Divider />
                                                </Col>
                                            </Row>
                                            <Row key={1} className="scrollable-dashboard">
                                                <Table
                                                    key={2}
                                                    loading={this.state.isFetching}
                                                    dataSource={tableSource}
                                                    columns={finalColumns}
                                                    pagination={false}
                                                    showHeader={false}
                                                    rowSelection={this.isMobile ? undefined : rowSelection}
                                                    onRow={onRow}
                                                    components={components}
                                                    scroll={{ y: '550px' }}
                                                    className="custom-table-height custom-table-selection"
                                                    rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box editable-row unread' : 'slide-table-rows custom-hover-table-box editable-row read')}
                                                />
                                            </Row>
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>

                    <div className="heading-style sub-heading-style">
                        <b className="case-scans-heading-style">Microscopy Observations</b>
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col>
                                {this.state.isFetchingComments ? getLoadingComponent() :
                                    this.state.hasCommentsThrownError ? getErrorComponent("Not able to contact server. Please contact admin.") :
                                        <div>
                                            <Table
                                                className="custom-hover-table"
                                                style={{ whiteSpace: 'pre-wrap' }}
                                                key={4}
                                                dataSource={this.state.comments}
                                                columns={comments_columns}
                                                size='small'
                                                pagination={false}
                                                bordered
                                                scroll={{ y: '400px' }}
                                            />
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>
                        <Divider style={{ backgroundColor: "#ffffff00" }} />
                        <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                        <Modal visible={this.state.screenshotVisible} footer={null} onCancel={this.handleScreenshotCancel} width={1500}>
                            {getScreenshotCarousel(this.state.screenshots)}
                        </Modal>
                    </div>
                    <div>
                        <br />
                        <Row>
                            <Col>
                                {this.state.isFetchingFiles ? getLoadingComponent() :
                                    this.state.hasFilesThrownError ? getErrorComponent("Not able to contact server. Please contact admin.") :
                                        <div>
                                            <Upload
                                                listType="picture"
                                                action="/api/upload_case_file/"
                                                headers={{ Authorization: AuthHeader() }}
                                                fileList={this.state.caseFileList}
                                                data={{ 'case_id': this.state.currCase.case_id }}
                                                onPreview={this.handlePreview}
                                                onChange={this.handleChangeCaseFile}
                                                onRemove={this.removeFileFromCase}
                                                showUploadList={{
                                                    showDownloadIcon: true,
                                                    downloadIcon: <ToTopOutlined />,
                                                }}
                                                className='upload-list-inline'
                                                accept="image/*, .pdf"
                                                beforeUpload={this.beforeUpload}
                                            >
                                                <div style={{ textAlign: 'center' }}>
                                                    <Button>
                                                        <UploadOutlined /> Upload
                                                    </Button>
                                                </div>
                                            </Upload>
                                            <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel} width={1000}>
                                                {this.state.showFile ?
                                                    <img alt="example" style={{ width: '100%' }} src={this.state.previewFile} />
                                                    : <div>No preview available</div>
                                                }
                                            </Modal>
                                            {
                                                this.state.caseFileList.length > 0 ?
                                                    null :
                                                    <div>
                                                        <Empty description="No Files Found" />
                                                    </div>
                                            }
                                        </div>
                                }
                            </Col>
                        </Row>
                        <br />
                    </div>

                    <Divider style={{ backgroundColor: "#ffffff00" }} />
                    <SlideSuggestions modalVisible={this.state.addSlideModalVisible} currCase={this.state.currCase} closeModal={this.closeModal} />
                    </>
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        height: state.contextMenuReducer.popupProps.height
    }
}

export default connect(mapStateToProps)(AllScansCaseView);
