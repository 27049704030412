import { SessionConstant } from "../actionTypes/session.constant"

const InitialState = {
    sessionValid: true
}

export const sessionStatusReducer = (state=InitialState, action) => {
    switch(action.type){
        case SessionConstant.SESSION_VALIDITY_CHECK_INITIATED:
            return {
                ...state,
            }
        case SessionConstant.SESSION_VALIDITY_STATUS_RECEIVED:
            return {
                ...state,
                sessionValid: action.valid
            }
        case SessionConstant.SESSION_VALIDITY_CHECK_FAILED:
            return state    // TODO: think for an idea
        default :
            return state
    }
}