export const LivemodeConstants = {
    UPDATE_INPUT_FIELD_IN_FOCUS: "UPDATE_INPUT_FIELD_IN_FOCUS",
    UPDATE_Z_STACK_LEVELS: "UPDATE_Z_STACK_LEVELS",
    UPDATE_STEP_SIZE_Z_STACK: "UPDATE_STEP_SIZE_Z_STACK",
    UPDATE_MOVEMENT_STEP_SIZE: "UPDATE_MOVEMENT_STEP_SIZE",
    UPDATE_IMAGE_FETCHING: "UPDATE_IMAGE_FETCHING",
    UPDATE_DEVICE_ID: "UPDATE_DEVICE_ID",
    UPDATE_EXPOSURE: "UPDATE_EXPOSURE",
    UPDATE_GAMMA: "UPDATE_GAMMA",
    UPDATE_ACCESS_TO_LIVE_MODE: "UPDATE_ACCESS_TO_LIVE_MODE",
    UPDATE_USE_LIVE_MODE_IN_VIEW_MODE: "UPDATE_USE_LIVE_MODE_IN_VIEW_MODE",
    UPDATE_LATEST_LIVE_MODE_DATA_TIMESTAMP: "UPDATE_LATEST_LIVE_MODE_DATA_TIMESTAMP",
    UPDATE_GENERATED_ACCESS_CODE: "UPDATE_GENERATED_ACCESS_CODE",
    UPDATE_ACCESS_REVOKED: "UPDATE_ACCESS_REVOKED",
    UPDATE_TAKE_PREVIEW_IN_LIVE_MODE: "UPDATE_TAKE_PREVIEW_IN_LIVE_MODE",
    UPDATE_ATLEAST_ONE_IMAGE_FETCHED: "UPDATE_ATLEAST_ONE_IMAGE_FETCHED",
    TOGGLE_SHOW_CHAT: "TOGGLE_SHOW_CHAT",
    UPDATE_CHAT_MESSAGES: "UPDATE_CHAT_MESSAGES",
    UPDATE_CURRENT_MAP_POSITION: "UPDATE_CURRENT_MAP_POSITION",
    UPDATE_TILE_CENTERS: "UPDATE_TILE_CENTERS",
    UPDATE_SLIDE_DATA: "UPDATE_SLIDE_DATA",
    UPDATE_CURRENT_PREVIEW: "UPDATE_CURRENT_PREVIEW",
    UPDATE_SCHEDULED_PREVIEWS: "UPDATE_SCHEDULED_PREVIEWS",
    UPDATE_PREVIEWS_DONE: "UPDATE_PREVIEWS_DONE",
    UPDATE_USE_COVERSLIP_OBJECTIVE: "UPDATE_USE_COVERSLIP_OBJECTIVE",
    UPDATE_LAST_BOUND: "UPDATE_LAST_BOUND"
} 