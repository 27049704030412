import React, {Component} from  "react";
import {connect} from 'react-redux';
import {updateZStackLevel} from "../../../action/maps.state.action";
import "../../../asset/style/neoviewer/zoom_controls.css"
import {Add, Remove} from "@mui/icons-material";
import {BottomNavigation, BottomNavigationAction, Box, IconButton, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import { notifyError } from "../utils/display.notification";

class ZStackControl extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openPanel: false,
		}
	}

	initState = () => {
		this.activeMapId = this.props.gammaState.activeMapId;
		this.mapState = this.props.mapsState[this.activeMapId];
		this.slideState = this.mapState.slideState;
	}

	incrementZoomLevel = () => {
		if(!this.mapState.onStitchedLayer) {
			this.props.dispatch(updateZStackLevel(this.activeMapId, this.mapState.zStackLevel + 1));
		} else {
			notifyError("zstackerror", "Z Stack only allowed on higher zoom levels.", false, 2500);
		}
	}

	decrementZoomLevel = () => {
		if(!this.mapState.onStitchedLayer) {
			this.props.dispatch(updateZStackLevel(this.activeMapId, this.mapState.zStackLevel - 1));
		} else {
			notifyError("zstackerror", "Z Stack only allowed on higher zoom levels.", false, 2500);
		}
	}

	render() {
		this.initState();

		if (!this.slideState || this.slideState.slide_data.scan_speed !== "ZSTACK")
			return null;

		let maxZLevel = this.slideState.slide_meta.takeBidirectionalZStack ?
			(this.slideState.slide_meta.numZLevels - 1) / 2 : (this.slideState.slide_meta.numZLevels - 1);
		let minZLevel = this.slideState.slide_meta.takeBidirectionalZStack ?
			(-1 * ((this.slideState.slide_meta.numZLevels - 1) / 2)) : 0;

		let layersOverlayComponent;
		if(this.state.openPanel){
			layersOverlayComponent = <Box sx={{height:'20vh', width:'4.3vw', border:'5px',bgcolor: "rgba(0,60,136,0.8)",
										display: "flex", overflowY: 'scroll',}}>
										<Typography sx={{width:'100%'}}>
											<Stack direction="column" justifyContent={"space-around"} marginTop={1} marginBottom={1} sx={{height:'18vh'}} >
												<IconButton color={"secondary"}>
													<Typography sx={{fontSize:'0.8vw', height:'3vh',}}>ZStack</Typography>
												</IconButton>
												<IconButton
													color={"secondary"}
													onClick={this.incrementZoomLevel}
													disabled={this.mapState.zStackLevel >= maxZLevel || !this.slideState.slide_meta.takeZStack}
													sx={{height:'6vh'}}
												>
													<Add fontSize="large"/>
												</IconButton>
												{/*<span style={{textAlign: "center"}}>{this.mapState.zStackLevel}X</span>*/}
												<IconButton
													color={"secondary"}
													onClick={this.decrementZoomLevel}
													disabled={this.mapState.zStackLevel <= minZLevel || !this.slideState.slide_meta.takeZStack}
													sx={{height:'6vh'}}
												><Remove fontSize="large"/>
												</IconButton>
											</Stack>
										</Typography>
									</Box>
		}

		return(
		<Stack direction={"column-reverse"} alignItems={"flex-end"}>
			<BottomNavigation sx={{borderRadius:0, bgcolor:"rgba(0,60,136,0.8)"}} value={this.state.app}
							  onChange={() => this.setState({openPanel : !this.state.openPanel,})}>
				<BottomNavigationAction icon={<Typography variant={'h5'} align={"center"} gutterBottom={true}
				                                          color={this.state.openPanel?"secondary":"white"}>
					{(this.mapState.zStackLevel * this.slideState.slide_meta.levelJump).toFixed(1)}&micro;
				</Typography>} />
			</BottomNavigation>
			{layersOverlayComponent}
		</Stack>
		)
	}

	}
const mapStateToProps = (state) => {
	return {
		gammaState: state.gammaStateReducer,
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(ZStackControl);
