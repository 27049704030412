import React, {Component} from 'react';
import {connect} from "react-redux";
import {globalUrlPrefix} from "../../../utils/const";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from "@mui/material/Typography";
import {
    Box,
    Button,
    Divider,
    Grid,
    List, ListItem, ListItemSecondaryAction, ListItemText,
    MenuItem,
    Select, Skeleton, Stack,
    TextField
} from "@mui/material";
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import cookie from "react-cookies";
import {AuthHeader} from "../../../helper/auth.token";
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import {message} from "antd";
import queryString from "query-string";
import {limitString, openInNewTab, URLify} from "../../../utils/utils";
import {BorderVertical, OpenInNewRounded} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import {mapSlides} from "../utils/splitSyncUtils";
class CliaApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            slide_data: this.props.mapsState[this.props.activeMapId].slideState.slide_data,
            user_id: cookie.load('user_id'),
            fetching: true,
            currCase: "",
            valdn_diagnosis:"",
            valdn_scanQuality:"",
            valdn_diagnosticCertainity:"",
            valdn_concordanceDesignation:"",
            caseScansArray:[],
            fetchingCaseScans: true,
        }
    }

    componentDidMount = () => {
        if(this.state.slide_data.case){
            this.getCase();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(this.state.user_id !== prevState.user_id){
            this.getCaseValidationData();
        }
    }

    getCase = () => {
        let url = `/api/case/${this.state.slide_data.case}/`;
        axios.get(url, { headers: { Authorization: AuthHeader() }, 'Cache-Control': 'no-cache' })
            .then(res => {
                this.setState({
                    currCase: res.data,
                })
            }).then(() => {
                this.getCaseValidationData();
                this.getCaseSlides()
            })
    }

    getCaseSlides = () => {
        let url = `/api/nonpagslides/?case_id=` + this.state.currCase.id;
        axios
            .get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
            .then(response => {
                this.setState({
                    caseScansArray: response.data,
                })
            })
    }

    getCaseValidationData = () => {
        let url = `/api/user_case_slide_specific_validation_data/?case_id=${this.state.currCase.id}` +
            `&user=${parseInt(this.state.user_id)}&slide_id=${this.state.slide_data.id}`;
        this.setState({
            fetching: true,
        })
        axios.get(url).then(response => {
            let data = response.data['result'];
            this.setState({
                valdn_diagnosis: data['diagnosis'],
                valdn_scanQuality: data['quality_of_scan'],
                valdn_diagnosticCertainity:data['diagnostic_certainty'],
                valdn_concordanceDesignation:data['concordance_designation'],
                fetching: false,
            })
        })
    }

    updateValidationData = (e, type) => {
        if(type === "diagnosis"){
            this.setState({
                valdn_diagnosis: e.target.value,
            })
        }else if(type === "scan_quality"){
            this.setState({
                valdn_scanQuality: e.target.value,
            })
        }else if(type === "diagnostic_certainity"){
            this.setState({
                valdn_diagnosticCertainity: e.target.value,
            })
        }else if(type === "concordance_designation"){
            this.setState({
                valdn_concordanceDesignation: e.target.value,
            })
        }
    }

    getDiagnosisForm = () => {
        return <Grid sx={{marginTop:'2px'}}>
                    <Typography color={"secondary"} sx={{marginTop:'1vh'}}>Diagnosis</Typography>
                    <TextField
                        multiline
                        rows={4}
                        variant="filled"
                        fullWidth={true}
                        value={this.state.valdn_diagnosis}
                        onChange={(e)=>{this.updateValidationData(e, "diagnosis")}}
                    />
                    <Typography color={"secondary"} sx={{marginTop:'1vh'}}>Scan Quality</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"}
                            value={this.state.valdn_scanQuality}
                            onChange={(e)=>{this.updateValidationData(e, "scan_quality")}}>
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Adequate">Adequate</MenuItem>
                        <MenuItem value="Borderline Adequate">Borderline Adequate</MenuItem>
                        <MenuItem value="Inadequate">Inadequate</MenuItem>
                    </Select>
                    <Typography color={"secondary"} sx={{marginTop:'1vh'}}>Diagnostic Certainity</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"}
                            value={this.state.valdn_diagnosticCertainity}
                            onChange={(e) =>
                                this.updateValidationData(e, "diagnostic_certainity")}>
                        <MenuItem value="Highly Confident">Highly Confident</MenuItem>
                        <MenuItem value="Mostly Confident">Mostly Confident</MenuItem>
                        <MenuItem value="Not Confident">Not confident</MenuItem>
                    </Select>
                    <Typography color={"secondary"} sx={{marginTop:'1vh'}}>Concordance Designation</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"}
                            value={this.state.valdn_concordanceDesignation}
                            onChange={(e) =>
                                this.updateValidationData(e, "concordance_designation")}>
                        <MenuItem value="Complete Agreement">Complete Agreement </MenuItem>
                        <MenuItem value="Minor Disagree No Impact">Minor Disagree - No Clinical Impact </MenuItem>
                        <MenuItem value="Minor Disagree Minimal Impact">Minor Disagree - Minimal Clinical Impact
                        </MenuItem>
                        <MenuItem value="Major Disagreement">Major Disagreement</MenuItem>
                    </Select>
                </Grid>
    }

    getFormLoading = () => {
            return <div>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </div>
    }

    changeUser = (e) => {
        this.setState({
            user_id: e.target.value,
        })
    }

    getAssignedUsers = () => {
        let assigned_user_options;
        if((this.state.currCase||{}).assigned_users){
            let curr_case = JSON.parse(JSON.stringify(this.state.currCase))
            let assigned_users = curr_case['assigned_users']
            assigned_users.push({
                "id": cookie.load('user_id'),
                "username": cookie.load('username'),
            })
            assigned_user_options = Object.values(assigned_users).map((user, index) => {
                return <MenuItem key={index} value={user.id}>{user.username}</MenuItem>
            })
        }
        return <div>
            <Typography color={"secondary"}>User</Typography>
                    <Select color={"secondary"} sx={{width:'100%', height:'3vh'}} variant={"filled"} value={this.state.user_id}
                        onChange={(e)=>{this.changeUser(e)}}>
                        {assigned_user_options}
                    </Select>
                </div>
    }

    storeValidationData = () => {
        if(this.state.valdn_diagnosis === "" || this.state.valdn_scanQuality === "" ||
            this.state.valdn_diagnosticCertainity === ""){
            message.info("Please fill all the required fields")
            return;
        }
        let data = {
            user: parseInt(this.state.user_id),
            case_id: this.state.currCase.case_id,
            slide_id: this.state.slide_data.id,
            slide_name: this.state.slide_data.name,
            diagnosis: this.state.valdn_diagnosis,
            quality_of_scan: this.state.valdn_scanQuality,
            diagnostic_certainity: this.state.valdn_diagnosticCertainity,
            concordance_designation: this.state.concordance_designation,
            input_type: "POST-GLASS",
            notes: ''
        }
        let url = `/api/update_validation_data/`;
        axios.post(url, queryString.stringify(data), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if(response.status === 200){
                    message.error("Failed to update data, Contact Admin", 3);
                    return;
                }
                message.success("Successfully saved data", 3);
            })

    }

    openSlide = (e, id) => {
        if(this.state.slide_data.id === id){
            return;
        }
        let url = "/" + globalUrlPrefix + "/gamma-viewer/" + id;
        window.open(url, "_blank")
    }

    render() {
        if (!this.state.slide_data.case)
            return <div style={{marginTop:'30px'}}>
                <HelpOutlineIcon sx={{marginLeft:'40%', fontSize:'50px'}}/>
                <br/>
                <Typography sx={{fontSize:'18px'}}>
                    Slide doesnt belong to any case.
                </Typography>
            </div>;

        return <Stack direction={"column"}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                {<Button variant={"outlined"} size={"small"} color={"secondary"}
                         onClick={() => openInNewTab(`/${globalUrlPrefix}/clia_case/${this.state.slide_data.case}`)}
                         sx={{textAlign:'centre'}}>
                    <LaunchOutlinedIcon/> &nbsp; Clia Case
                </Button>
                }
                {this.state.slide_data.trf_uploaded ?
                    <Button variant={"outlined"} size={"small"} color={"secondary"} onClick={() => openInNewTab(URLify(
                        `/case-files/${this.state.slide_data.case}/case_trf/${this.state.slide_data.case}.pdf`))}>
                        <PageviewOutlinedIcon/> &nbsp; Clinical Info
                    </Button> : null
                }
            </Stack>
            <Divider style={{margin: 20, marginInline: 0}} />
            <Typography color={"secondary"} sx={{textAlign:'center'}}>Slides related to this case</Typography>
            <List>
                {this.state.caseScansArray.map((slide, idx) =>
                    <ListItem key={slide.id} disablePadding>
                        <ListItemText style={{margin: 0}}
                                      primary={limitString(`${idx+1}. ${slide.name}`, 24)} />
                        <ListItemSecondaryAction>
                            <Button sx={{color: morphleBaseTheme1.palette.text.primary, minWidth: 10, padding: 1}}
                                    edge="end" onClick={() => mapSlides(
                                        [...Object.values(this.props.mapsState).filter(
                                            slide_ => parseInt(slide_.id) !== parseInt(slide.id)), slide])}>
                                <BorderVertical fontSize={"small"} />
                            </Button>
                            <Button sx={{color: morphleBaseTheme1.palette.text.primary, minWidth: 10, padding: 1}}
                                    edge="end" onClick={e => this.openSlide(e, slide.id)} >
                                <OpenInNewRounded fontSize={"small"} />
                            </Button>
                        </ListItemSecondaryAction>

                    </ListItem>
                )}
            </List>
            <Divider style={{margin: 20, marginInline: 0}} />
            <Box border={0.5} padding={2}>
                {!this.state.fetching && cookie.loadAll().is_staff === "true" ? this.getAssignedUsers() :
                    this.getFormLoading()}
                {!this.state.fetching ? this.getDiagnosisForm(): this.getFormLoading()}
                {<Button color={"secondary"} onClick={this.storeValidationData}
                         disabled={this.state.valdn_scanQuality.length!==0 &&
                             cookie.loadAll().is_staff !== "true"}>
                    <SaveIcon/> &nbsp; Save
                </Button>}
            </Box>

        </Stack>

    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(CliaApp)
