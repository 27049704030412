import React, { Component } from 'react';

import {
  CloseOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  SettingOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Row, Popover, Button, Slider, Divider } from 'antd';
import { connect } from "react-redux";
import { resetToDefault, imageContrastChanged, imageBrightessChanged, imageSaturationChanged, imageGrayscaleChanged, imageInvertChanged, imageHueChanged, changeNameAndID, setAll } from "../../action/viewerSetting.action";
import { CircleSlider } from "react-circle-slider";
import { message, Select, Modal, Input } from "antd";
import axios from "axios";
import { AuthHeader } from "../../helper/auth.token";
import { updateSlide } from '../../action/slide.update.action';

const { Option } = Select;

class ScanControls extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isMenuOpen: false,
        changed: false,
        settings_list: [] 
      }
      this.viewer = this.props.viewer;

      let settings = {
        'brightness': this.props.slide_data.viewer_settings.brightness/100,
        'saturation': this.props.slide_data.viewer_settings.saturation/100,
        'hue': this.props.slide_data.viewer_settings.hue,
        'contrast': this.props.slide_data.viewer_settings.contrast*2/100,
        'invert': this.props.slide_data.viewer_settings.invert/100,
        'grayscale': this.props.slide_data.viewer_settings.grayscale/100,
        'name': this.props.slide_data.viewer_settings.name, 
        'id': this.props.slide_data.viewer_settings.id
      };

      this.props.dispatch(setAll(settings));
  }

  
  getAllSettings = () => {
    let url = `/api/viewersetting/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } }).then(response => {
      let settings_list = response.data.map((value) => {
        return (
          <Option key={value.id} label={value}>
            {value.name}
          </Option>
        );
      });
			this.setState({
				settings_list: settings_list,
      });
		})
		.catch(err => {
				console.log(err);
		});
  }

  componentDidMount = () => {
    this.getAllSettings();
  }

  slider = (value) => {
    this.viewer.getView().setRotation((value * Math.PI) / 180);
    this.setState({
      slideAngle: ((value * Math.PI) / 180),
    });
  }

  resetToDefaults = () => {
    this.props.dispatch(resetToDefault());
  }

  onContrastChange = (value) => {
    this.props.dispatch(imageContrastChanged(value * 2 /100));
    this.setState({
      changed: true
    });
  }

  onBrightnessChange = (value) => {
    this.props.dispatch(imageBrightessChanged(value / 100));
    this.setState({
      changed: true
    });
  }

  onSaturationChange = (value) => {
    this.props.dispatch(imageSaturationChanged(value /100));
    this.setState({
      changed: true
    });
  }

  onGrayscaleChange = (value) => {
    this.props.dispatch(imageGrayscaleChanged(value /100));
    this.setState({
      changed: true
    });
  }

  onInvertChange = (value) => {
    this.props.dispatch(imageInvertChanged(value /100));
    this.setState({
      changed: true
    });
  }

  onHueChange = (value) => {
    this.props.dispatch(imageHueChanged(value));
    this.setState({
      changed: true
    });
  }

  closeMenu = () => {
    this.setState(
      {
        isMenuOpen: false
      }
    );
  }

  openMenu = () => {
    this.setState(
      {
        isMenuOpen: true
      }
    );
  }

  updateSettings = () => {
    let val = {
      'brightness': this.props.viewerSettingData.brightness * 100,
      'saturation': this.props.viewerSettingData.saturation * 100,
      'hue': this.props.viewerSettingData.hue,
      'contrast': this.props.viewerSettingData.contrast * 100/2,
      'invert': this.props.viewerSettingData.invert * 100,
      'grayscale': this.props.viewerSettingData.grayscale * 100,
    }

    let url = `/api/viewersetting/${this.props.viewerSettingData.id}/`;
    axios.put(url, val, {headers: {Authorization: AuthHeader() }})
    .then(response => {
      this.setState({
        changed: false,
      });
      message.success("Updated Settings Successfully");
    })
    .catch(err => {
      this.setState({
        changed: true,
      });
      message.error("Failed to Save Settings. Contact Admin");
      console.log(err);
    });
  };

  handleSettingChange = (value, selected_setting_option) => {
    let selected_setting = selected_setting_option.props.label;

    let settings = {
      'brightness': selected_setting.brightness/100,
      'saturation': selected_setting.saturation/100,
      'hue': selected_setting.hue,
      'contrast': selected_setting.contrast*2/100,
      'invert': selected_setting.invert/100,
      'grayscale': selected_setting.grayscale/100,
      'name': selected_setting.name,
    };
    this.props.dispatch(setAll(settings));
    let val = {
      "morphle_id" : this.props.slide_data.morphle_id,
      "path": this.props.slide_data.path,
      "specimen_type" : this.props.slide_data.specimen_type,
      "date": this.props.slide_data.date,
      'name': this.props.slide_data.name,
      "settings": parseInt(selected_setting.id)
    };
    this.props.dispatch(updateSlide(this.props.slide_data.id ,val));
  }

  addSettings = (settings_name) => {
    let val = {
      'brightness': this.props.viewerSettingData.brightness * 100,
      'saturation': this.props.viewerSettingData.saturation * 100,
      'hue': this.props.viewerSettingData.hue,
      'contrast': this.props.viewerSettingData.contrast * 100/2,
      'invert': this.props.viewerSettingData.invert * 100,
      'grayscale': this.props.viewerSettingData.grayscale * 100,
      'name': settings_name
    };
    let url = `/api/viewersetting/`;
    axios.post(url, val, {headers: {Authorization: AuthHeader() }})
    .then(response => {
      let option = 
        <Option key={response.data.id} label={response.data} >
            {response.data.name}
        </Option>
      this.setState(prevState => ({
        changed: false,
        name: settings_name,
        settings_list: [...prevState.settings_list, option]
      }));
      message.success("Saved New Settings Successfully");
      let val = {
        "morphle_id" : this.props.slide_data.morphle_id,
        "path": this.props.slide_data.path,
        "specimen_type" : this.props.slide_data.specimen_type,
        "date": this.props.slide_data.date,
        'name': this.props.slide_data.name,
        "settings": parseInt(response.data.id)
      };
      this.props.dispatch(changeNameAndID(response.data.name, response.data.id));
      this.props.dispatch(updateSlide(this.props.slide_data.id ,val));
    })
    .catch(err => {
      this.setState(prevState => ({
        changed: true,
      }));
      message.error("Failed to Save Settings. Contact Admin");
      console.log(err);
    });
  }

  saveNewSettings = () => {
    Modal.confirm({
      title: 'Please enter New Settings Name',
      icon: <InfoCircleOutlined></InfoCircleOutlined>,
      content: (
          <Input placeholder="Enter Settings Name here" id="settings-name" />
      ),
      onOk:  () => {
        let settings_name = document.getElementById("settings-name").value;
        this.addSettings(settings_name);
      },
      onCancel: () => {}
    });
  }
  


  render() {

    let image_controls_menu = [
      <Row key="menu">
        <Row style={{ textAlign: "center", marginTop: "0%"}}>
              <div>Rotation</div>
                  <CircleSlider 
                    disabled={this.props.sync_browsing_enabled}
                    circleWidth={1}     
                    size={55}
                    showTooltip={true}
                    knobColor="#ff5722"
                    circleColor="rgba(0,60,136)" 
                    progressColor="rgba(0,60,136,0.75)"
                    knobRadius={4} 
                    value={this.viewer.getView().getRotation()} 
                    onChange={this.slider}
                    progressWidth={12} 
                    min={0} 
                    tooltipSize={20}
                    max={360}/>
                    <Button disabled={this.props.sync_browsing_enabled} style={{ padding: '0' }} onClick={()=> this.slider(0)} className='view-sidebar sidebar-textbutton'>
                      <UndoOutlined /><br></br>
                      Reset
                    </Button>
        </Row>
        <Divider></Divider>

        <Row key={0} style={{ marginTop: "5%"}}>
              Contrast: <Slider
              min={1}
              max={100}
              onChange={this.onContrastChange}
              value={this.props.viewerSettingData.contrast * 100/2}
            />
               <Divider></Divider>
                Brightness: <Slider
                min={1}
                max={100}
                onChange={this.onBrightnessChange}
                value={this.props.viewerSettingData.brightness * 100}
              />
                <Divider></Divider>
                Hue: <Slider
                min={1}
                max={100}
                onChange={this.onHueChange}
                value={this.props.viewerSettingData.hue}
              />
                <Divider></Divider>
                Saturation: <Slider
                min={1}
                max={100}
                onChange={this.onSaturationChange}
                value={this.props.viewerSettingData.saturation * 100}
              />
                <Divider></Divider>
                Invert: <Slider
                min={1}
                max={100}
                onChange={this.onInvertChange}
                value={this.props.viewerSettingData.invert * 100}
              />
                <Divider></Divider>
                Grayscale: <Slider
                min={1}
                max={100}
                onChange={this.onGrayscaleChange}
                value={this.props.viewerSettingData.grayscale * 100}
              />
                <Divider></Divider>
              <div style={{textAlign:'center'}}>
                <Button style={{ padding: '0px', width: '100%" !important' }} onClick={()=> this.resetToDefaults()} className='view-sidebar sidebar-textbutton'>
                  <UndoOutlined /><br></br>
                  Reset
                </Button>
                <Button disabled={!this.state.changed} style={{ padding: '0px', width: '100%" !important' }} onClick={()=> this.updateSettings()} className='view-sidebar sidebar-textbutton'>
                  <SaveOutlined /><br></br>
                  Update Settings
                </Button>
                <Button disabled={!this.state.changed} style={{ padding: '0px', width: '100%" !important' }} onClick={()=> this.saveNewSettings()} className='view-sidebar sidebar-textbutton'>
                  <PlusOutlined /><br></br>
                  Save New
                </Button>
              </div>
        </Row>
      </Row>
    ];

    let scanControlHeader = [
        <Row key="header">View Settings 
          <CloseOutlined style={{float: "right"}} onClick={this.closeMenu} /><br/>
          <h6>Setting Name:  
            <Select value={this.props.viewerSettingData.name} onChange={this.handleSettingChange}>
              {this.state.settings_list}
            </Select>
          </h6>
        </Row>
    ];

    return (
      <Row xs={{ span: 0, offset: 0 }} style={{marginTop: "5%"}}>
      <Popover 
        title={scanControlHeader}
        trigger="click" 
        content={image_controls_menu} 
        placement="right"
        visible={this.state.isMenuOpen}
        onClick={this.openMenu}>
        <Button className='view-sidebar sidebar-textbutton'>
          <SettingOutlined></SettingOutlined><br></br>
          Settings
        </Button>
      </Popover>
    </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewerSettingData: state.viewerSettingReducer,
  };
};

export default connect(mapStateToProps)(ScanControls);