import React from 'react';
import PropTypes from 'prop-types';
import { DownOutlined } from '@ant-design/icons';
// import Button from '@material-ui/core/Button';
import { Dropdown, Menu, Button } from 'antd';
import {urls} from '../../helper/backend.urls'
import {tokens} from '../../helper/backend.tokens'
import axios from 'axios';


const imgWithClick = { cursor: 'pointer' };

const Photo = ({ index, onClick, photo, margin, direction, top, left, key, wbcTypes}) => {
  const myKey = key;

  const url = `${urls['zeus_url']}/training_common/misclassifiedimage`;
  const headers = {'Content-Type': 'application/json', 'Authorization': "Token "+tokens['zeus_token']}

  const imgStyle = { margin: margin, display: 'block' };
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = event => {
    onClick(event, { photo, index });
  };

  const photoStyle = {
    position: 'relative'
  }

  const buttonStyle = {
    position: 'relative',
    border: "3px solid #FFFFFF",
  }

  const myClick = (event) => {
      console.log(photo);
  }

  // const wbcTypes = ["basophil", "eosinophils", "lymphocytes", "monocytes", "neutrophils"]

  const handleMenuClick = (e) => {
    // console.log(e["key"]);
    // console.log(photo.title);
    let json_data = {"image": photo.title, "subType": e["key"]}

    axios.post(url, json_data, { headers: headers})
      .then(response => {
          console.log(response);
          window.location.reload();
      })
      .catch(err => {
          console.log(err);
      });

  }
  const menu = (
    <Menu onClick={handleMenuClick}>
      {wbcTypes.map((value,index) => {return <Menu.Item key={value}> {value} </Menu.Item> })}
    </Menu>
  );


  return (
    <div style={buttonStyle}>
        <div >
            <Dropdown overlay={menu}>
                <Button shape="circle" size="small">
                    <DownOutlined></DownOutlined>
                </Button>
            </Dropdown>
        </div>
        <img
            key={key}
            style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
            {...photo}
            onClick={onClick ? handleClick : null}
        />
        
    </div>
  );
};

export const photoPropType = PropTypes.shape({
  key: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string,
  srcSet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
});

Photo.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  photo: photoPropType.isRequired,
  margin: PropTypes.number,
  top: props => {
    if (props.direction === 'column' && typeof props.top !== 'number') {
      return new Error('top is a required number when direction is set to `column`');
    }
  },
  left: props => {
    if (props.direction === 'column' && typeof props.left !== 'number') {
      return new Error('left is a required number when direction is set to `column`');
    }
  },
  direction: PropTypes.string,
};

export default Photo;