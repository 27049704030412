import { ViewerSettings } from "../actionTypes/viewerSettings.constant";
import axios from "axios";
import {AuthHeader} from "../helper/auth.token";
import {message} from "antd";

export const resetToDefault = () => ({
  type : ViewerSettings.IMAGE_RESET_DEFAULT,
});

export const imageContrastChanged = (value) => ({
  type : ViewerSettings.IMAGE_CONTRAST_CHANGED,
  response: value
});

export const imageBrightessChanged = (value) => ({
  type : ViewerSettings.IMAGE_BRIGHTNESS_CHANGED,
  response: value
});

export const imageHueChanged = (value) => ({
  type : ViewerSettings.IMAGE_HUE_CHANGED,
  response: value
});

export const imageGrayscaleChanged = (value) => ({
  type : ViewerSettings.IMAGE_GRAYSCALE_CHANGED,
  response: value
});

export const imageInvertChanged = (value) => ({
  type : ViewerSettings.IMAGE_INVERT_CHANGED,
  response: value
});

export const imageSaturationChanged = (value) => ({
  type : ViewerSettings.IMAGE_SATURATION_CHANGED,
  response: value
});

export const setAll = (value) => ({
  type : ViewerSettings.SET_ALL_SETTINGS,
  response: value
});


export const changeNameAndID = (value, id) => ({
  type : ViewerSettings.SET_NAME,
  response: value, 
  id: id
});

export const showSeenAreaChanged = () => ({
  type : ViewerSettings.SHOW_SEEN_AREA_CHANGED,
});


