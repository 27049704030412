import React from  "react";
import cookie from "react-cookies";
import ManualModeApp from '../utils/app_manual_mode';
import * as initialSetupKeys from './initial_setup_app_keys';
import * as initialSetupKeysLiveMode from './initial_setup_app_keys_live_view';
import * as qcKeys from './qc_app_keys';

import MotionApp from './apps/motion_app';
import CameraApp from './apps/camera_app';
import PreviewApp from './apps/preview_app';
import PreviewAppLiveMode from './apps/preview_app_live_mode';
import SavedImagesApp from './apps/saved_images_app';
import IntrafieldApp from './apps/intrafield_app';
import PreviewTuningApp from './apps/preview_tuning_app';
import PreviewTuningControlsApp from './apps/preview_tuning_controls_app';
import PreviewAppTest from './apps/preview_movement_test';
import SlotsMarkingApp from './apps/slotsMarkingApp';
import QCSettingsApp from './apps/qc_settings_app';

const appify = (key, content, allAppsState) => {
    return (
        <ManualModeApp
            key={key.id}
            appKey={key}
            startTop={((allAppsState.app_state[key.id] || {}).position || {y : key.defaultY}).y}
            startLeft={((allAppsState.app_state[key.id] || {}).position || {x : key.defaultX}).x}
            defaultClosed={(allAppsState.app_state[key.id] || {}).closed}
            content={content} />
    )
}

const getMotionApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.motionAppKey, 
                <MotionApp currentState={currentState} />,
                allAppsState
    )
}

const getCameraApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.cameraAppKey, 
                <CameraApp currentState={currentState} />,
                allAppsState
    )
}

const getPreviewApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.previewAppKey, 
                <PreviewApp currentState={currentState} />,
                allAppsState
    )
}

const getPreviewAppLiveMode = (currentState, allAppsState) => {
    return appify(initialSetupKeysLiveMode.previewAppLiveModeKey, 
                <PreviewAppLiveMode currentState={currentState} />,
                allAppsState
    )
}

const getSavedImagesApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.savedImagesAppKey, 
                <SavedImagesApp currentState={currentState} />,
                allAppsState
    )
}

const getIntrafieldApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.intrafieldAppKey, 
                <IntrafieldApp currentState={currentState} />,
                allAppsState
    )
}

const getPreviewTuningApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.previewTuningAppKey, 
                <PreviewTuningApp currentState={currentState} />,
                allAppsState
    )
}

const getPreviewTuningControlsApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.previewTuningControlsAppKey, 
                <PreviewTuningControlsApp currentState={currentState} />,
                allAppsState
    )
}

const getPreviewMovementTest = (currentState, allAppsState) => {
    return appify(initialSetupKeys.previewMovementTestKey, 
                <PreviewAppTest currentState={currentState} />,
                allAppsState
    )
}

const getSlotsMarkingApp = (currentState, allAppsState) => {
    return appify(initialSetupKeys.slotsMarkingAppKey, 
                <SlotsMarkingApp currentState={currentState} />,
                allAppsState
    )
}

const getQCSettingsApp = (currentState, allAppsState) => {
    return appify(qcKeys.qcSettingsAppKey, 
                <QCSettingsApp currentState={currentState} />,
                allAppsState
    )
}

export const getAllApps = (currentState, allAppsState, liveMode) => {
    if(!liveMode) {
        return [
            getMotionApp(currentState, allAppsState),
            getCameraApp(currentState, allAppsState),
            getPreviewApp(currentState, allAppsState),
            getSavedImagesApp(currentState, allAppsState),
            getIntrafieldApp(currentState, allAppsState),
            getPreviewTuningApp(currentState, allAppsState),
            getPreviewTuningControlsApp(currentState, allAppsState),
            getPreviewMovementTest(currentState, allAppsState),
            getSlotsMarkingApp(currentState, allAppsState),
            getQCSettingsApp(currentState, allAppsState),
        ]
    } else {
        return [
            getSavedImagesApp(currentState, allAppsState),
        ]
    }
}