import React from  "react";
import {Row, Col} from 'antd';
import { getTopLeft, getBottomRight, getIntersection, intersects } from 'ol/extent.js'
import ImageLayer from 'ol/layer/Image.js';
import {ImageCanvas as ImageCanvasSource} from 'ol/source.js';
import _ from 'lodash';

const colorList = {
    0: 'rgba(0, 255, 0, 0.5)',
    1: 'rgba(0, 255, 0, 0.5)',
    2: 'rgba(0, 255, 0, 0.5)',
    3: 'rgba(0, 255, 0, 0.5)',
    4: 'rgba(0, 255, 0, 0.5)',
    5: 'rgba(0, 255, 0, 0.5)',
    6: 'rgba(0, 255, 0, 0.5)',
    7: 'rgba(0, 255, 0, 0.5)',
    8: 'rgba(0, 255, 0, 0.5)',
    9: 'rgba(0, 255, 0, 0.5)',
    10: 'rgba(0, 255, 0, 0.5)',
    11: 'rgba(0, 255, 0, 0.5)',
    12: 'rgba(0, 255, 0, 0.5)',
    13: 'rgba(0, 255, 0, 0.5)',
}

const getExtentListFromIntersection = (intersectionList, newExtent) => {
    // this function works only if every individual bboxe in the intersectionlist has a corner
    let xSet = new Set(), xYDict = {};
    let newExtentMinY = newExtent[1];
    let newExtentMaxY = newExtent[3];
    let disjointExtents = []
    intersectionList.map((extent) => {
        xSet.add(extent[0]);
        xSet.add(extent[2]);
    });
    xSet.add(newExtent[0]);
    xSet.add(newExtent[2]);
    let xList = [...xSet].sort(function(a, b){return a-b}); // stores all the x cordinates of the boxes in ascending order
    //
    //  ______ur_____________
    //  |    |              |
    //  |__ul|              |
    //  |                   |
    //  |                   |
    //  |__dl_dr____________|
    //       x
    //       |- this is an x coord for which ur and ul is different
    // For every x co-ordinate we find the up-left(ul), up-right(ur), down-left(dl), down-right(dr) points
    // Actually only the ur and dl points are needed for forming boxes, but ul and dr are provided for the sake of closure.
    // so if a box starts from a x-coord, and if the x-coord == xmin then y = ur (the bbox standard format is xmin, ymin, xmax, ymax)
    //                                           the x-coord == xmax then y = dl
    xList.map(xCord => {
        xYDict[xCord] = {}
        xYDict[xCord]["ul"] = newExtentMinY;
        xYDict[xCord]["ur"] = newExtentMinY;
        xYDict[xCord]["dl"] = newExtentMaxY;
        xYDict[xCord]["dr"] = newExtentMaxY;
        intersectionList.map((extent) => {
        let xmin = extent[0]
        let ymin = extent[1]
        let xmax = extent[2]
        let ymax = extent[3]
        if(xCord >= xmin && xCord <= xmax){ // the x-coord lies inside the bbox
            if(ymin === newExtentMinY){ // the bbox shares a north corner with the newExtent
            if (xYDict[xCord]["ul"] < ymax) {
                if (xCord !== xmin)
                    xYDict[xCord]["ul"] = ymax;
            }
            if (xYDict[xCord]["ur"] < ymax) {
                if (xCord !== xmax)
                    xYDict[xCord]["ur"] = ymax;
            }
            
            }
            if(ymax === newExtentMaxY){  // the bbox shares a south corner with the newExtent
            if (xYDict[xCord]["dl"] > ymin) {
                if (xCord != xmin)
                    xYDict[xCord]["dl"] = ymin;
            }
            if (xYDict[xCord]["dr"] > ymin) {
                if (xCord != xmax)
                    xYDict[xCord]["dr"] = ymin;
            }
            }

        }

        });
    });
    // now we have all the ul,ur,dl,dr for all the x-coords
    for (var i=0; i<xList.length-1; i++){
        if(xYDict[xList[i]]["ur"] < xYDict[xList[i+1]]["dl"]) { // check for valid height
        disjointExtents.push([xList[i], xYDict[xList[i]]["ur"], xList[i+1], xYDict[xList[i+1]]["dl"]]); // a bbox from ith x-coord at north to (i+1)th x-coord at south
        }
    }

    return disjointExtents;
}
  
export const addToVisitedList = (view, zoomVisitedFullMap, zoomVisitedMap) => {
  
    let newExtent = view.calculateExtent();
    let currentZoom = view.getZoom();

    if (zoomVisitedMap[currentZoom] === undefined) {
        return;
    }

    if (zoomVisitedMap[currentZoom].length === 0) {
        zoomVisitedMap[currentZoom].push(newExtent);
        zoomVisitedFullMap[currentZoom] = [];
        zoomVisitedFullMap[currentZoom].push(newExtent);
        return;
    } 

    let newVisitedMap = zoomVisitedMap[currentZoom];
    let intersectionList = [];
    let return_out = false;

    zoomVisitedFullMap[currentZoom].map((extent) => {
    if (intersects(newExtent, extent)) {
        let tmp = getIntersection(newExtent, extent);
        if (tmp === newExtent) {
            return_out = true;
        }
        intersectionList.push(getIntersection(newExtent, extent));
    }
    });
    if (return_out) {
        return;  
    }
    if (_.isEmpty(intersectionList)) {
        newVisitedMap.push(newExtent);
    }
    else {
        getExtentListFromIntersection(intersectionList, newExtent).map((innerExtent) => {
            newVisitedMap.push(innerExtent);
        })
    }

    zoomVisitedMap[currentZoom] = newVisitedMap;
    zoomVisitedFullMap[currentZoom].push(newExtent);
}

export const getPreviewColorForZoom = (zoom) => {
  return colorList[zoom];
}

const canvasFunction = (pixelRatio, size, previewMap, view, zoomVisitedMap) => {
    let canvasWidth = size[0], canvasHeight = size[1];
    let canvas = document.createElement('canvas');
    let currentZoom = view.getZoom();
    canvas.setAttribute('width', canvasWidth);
    canvas.setAttribute('height', canvasHeight);
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0,0, canvasWidth, canvasHeight);
    let overViewExtent = previewMap.getOverviewMap().getView().calculateExtent();
    
    if (zoomVisitedMap[currentZoom] === undefined) {
      return;
    }
    
    zoomVisitedMap[currentZoom].map((extent) => {
      if (intersects(overViewExtent, extent)) {
          ctx.fillStyle = getPreviewColorForZoom(currentZoom);
          ctx.save();
          let corner1 = previewMap.getOverviewMap().getPixelFromCoordinate(getBottomRight(extent));
          let corner2 = previewMap.getOverviewMap().getPixelFromCoordinate(getTopLeft(extent));
          let rect = [corner2[0], corner2[1],(corner1[0] - corner2[0]) * pixelRatio, (corner1[1] - corner2[1]) * pixelRatio];
          ctx.fillRect(rect[0] * pixelRatio, rect[1] * pixelRatio, rect[2], rect[3]);
        }
    });

    return canvas;
  };

export const getColorLayer = (previewMap, view, zoomVisitedMap) => {
    
    let colorSource = new ImageCanvasSource({
        canvasFunction: (extent, resolution, pixelRatio, size, projection) => canvasFunction(pixelRatio, size, previewMap, view, zoomVisitedMap),
        projection: view.getProjection(),
        ratio: 1.0
      });
  
    let colorLayer = new ImageLayer({
        source: colorSource
    });

    return colorLayer;
}


    
export const getPreviewColorLegend = (zoomScale, maxZoom, currentZoom) => {
    let colorArrays = [[]];
    let colSpan = 6;
    let curSpan = 0;
    let maxColSpan = 22;
    let arrayIndex = 0;
    for(var i=0; i < maxZoom; i++) {
        let color = colorList[i].replace('0.5', '1.0')
        colorArrays[arrayIndex].push({'zoom': zoomScale[i], 'color': color, 'index': i});
        if (curSpan + colSpan > maxColSpan) {
            arrayIndex ++;
            colorArrays.push([]);
            curSpan = 0;
        }
        else {
            curSpan += colSpan;
        }
    }

    return(
        colorArrays.map((colorArray, index) => {
            return (
                <Row
                    key={index}
                    style={{
                        marginTop:5,
                        }}>
                    {
                    colorArray.map((value, index) => {
                        return (
                        <Col 
                            key={index}
                            span={colSpan} 
                            className='preview-tracking-legend'>
                            <Row
                                style={{
                                    border:"2px solid", 
                                    borderRadius: 5,
                                    borderColor: value['color'], 
                                    marginLeft:5,
                                    marginRight:5,
                                    background: value['index'] == currentZoom ? value['color'].replace('1.0', '0.7'): "white", 
                                    color: value['index'] == currentZoom ? "white" : "black"
                                    }}>
                                <Col 
                                    span={12} 
                                    offset={6}
                                    style={{
                                        marginLeft:10
                                    }}
                                    >
                                    {value['zoom']}x
                                </Col>
                            </Row>
                        </Col>);
                    })
                    }
                </Row>
            )
        })
    )
  };