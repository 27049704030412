import React, { Component } from 'react';
import { Row, Col, Radio, Icon, Divider } from 'antd';
import {
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    ArrowUpOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { connect } from "react-redux";
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from '../../helper/auth.token';
import {updateLiveView, updateLiveViewFullScreen, updateQuadrantView} from "../../action/liveview.action";
import { cameraAppKey } from './initial_setup_app_keys';
import { cameraModeTypes } from "../../utils/const";

import "../../asset/style/scanner/scanner.css";
import "../../asset/style/manualmode/camera_app.css";

class LiveView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveView: "",
        }

        this.timer = null;
        this.timerQuadrantMode = null;

    }

    componentDidMount() {
        if(this.props.liveView.liveImagePolling && !(this.props.appState || {}).closed) {
            if(this.props.cameraMode == cameraModeTypes.LIVE_CAMERA) {
                this.activateLiveCameraView();
            } else if(this.props.cameraMode == cameraModeTypes.QUADRANT_MODE) {
                this.activateQuadrantCameraView();
            } else {
                this.clearTimers();
                this.getLiveImage();
            }
        } else {
            this.clearTimers();
            this.getLiveImage();
        }
    }

    componentDidUpdate = (prevProps) => {
        if((prevProps.appState || {}).closed != (this.props.appState || {}).closed) {
            if(this.props.liveView.liveImagePolling && !(this.props.appState || {}).closed) {
                if(this.props.cameraMode == cameraModeTypes.LIVE_CAMERA) {
                    this.activateLiveCameraView();
                } else if(this.props.cameraMode == cameraModeTypes.QUADRANT_MODE) {
                    this.activateQuadrantCameraView();
                } else {
                    this.clearTimers();
                    this.getLiveImage();
                }
            } else {
                this.clearTimers();
                this.getLiveImage();
            }
        }
        if(prevProps.cameraMode != this.props.cameraMode) {
            if(this.props.liveView.liveImagePolling) {
                if(this.props.cameraMode == cameraModeTypes.LIVE_CAMERA) {
                    this.activateLiveCameraView();
                } else if(this.props.cameraMode == cameraModeTypes.QUADRANT_MODE) {
                    this.activateQuadrantCameraView();
                } else {
                    this.clearTimers();
                }
            } else {
                this.clearTimers();
                this.getLiveImage();
            }
        }
        if(prevProps.liveView.liveImagePolling != this.props.liveView.liveImagePolling) {
            if(this.props.liveView.liveImagePolling) {
                if(this.props.cameraMode == cameraModeTypes.LIVE_CAMERA) {
                    this.activateLiveCameraView();
                } else if(this.props.cameraMode == cameraModeTypes.QUADRANT_MODE) {
                    this.activateQuadrantCameraView();
                } else {
                    this.clearTimers();
                    this.getLiveImage();
                }
            } else {
                this.clearTimers();
                this.getLiveImage();
            }
        }
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    activateLiveCameraView = () => {
        clearInterval(this.timerQuadrantMode);
        this.timerQuadrantMode = null;
        this.timer = setInterval(() => this.getLiveImage(), 100);
    }

    activateQuadrantCameraView = () => {
        clearInterval(this.timer);
        this.timer = null;
        this.timerQuadrantMode = setInterval(() => this.getLiveQuadrantImage(), 100);
    }

    clearTimers = () => {
        clearInterval(this.timer);
        clearInterval(this.timerQuadrantMode);
        this.timer = null;
        this.timerQuadrantMode = null;
    }

    getLiveImage = () => {
        let url = '';
        let liveMode = cookie.loadAll().superuser !== 'true';
        if(this.props.fullScreenMode) {
            if(!this.props.liveMode) {
                url = '/server/devices/' + this.props.id + '/get_live_image?scale=2.05&zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
            } else {                
                url = '/server/devices/' + this.props.id + '/get_live_image?scale=2.1&zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
            }
        } else {
            if(!this.props.liveMode) {
                url = '/server/devices/' + this.props.id + '/get_live_image?zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
            } else {                
                url = '/server/devices/' + this.props.id + '/get_live_image?scale=2.5&zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
            }
        }
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if(this.props.fullScreenMode) {
                this.props.dispatch(updateLiveViewFullScreen(response.data));
            } else {
                this.props.dispatch(updateLiveView(response.data));
            }
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    getLiveQuadrantImage = () => {
        let partsOfUrl = "api~camera~get_quartered_image";
        let url = `/server/scano/` + this.props.id + `/` + partsOfUrl + '?quarter_size=' + this.props.adminState.quarter_size;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.props.dispatch(updateQuadrantView(response.data));
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    render() {

        let widthInMicrons = this.props.adminState.uperPixel * this.props.adminState.rectWidth * this.props.liveView.zoom;
        let heightInMicrons = this.props.adminState.uperPixel * this.props.adminState.rectHeight * this.props.liveView.zoom;
        let imageHeight = 0;
        if(document.getElementById("liveCameraImage") != null) {
            imageHeight = document.getElementById("liveCameraImage").clientHeight;
        }

        return (
            <Row className="rounded-container">
                <Col span={this.props.camera_app ? 24: 12} offset={this.props.camera_app ? 0 : 3}>
                    <Row style={this.props.camera_app ? {marginLeft: 10, marginTop: 10} : {}}>
                        {this.props.cameraMode == cameraModeTypes.LIVE_CAMERA ?
                            <Col span={24} className={this.props.camera_app ? "relativeParent" : ""}>
                                <Row>
                                    <Col span={23}>
                                        <Row>
                                            <i className="arrow display-inline left"></i>
                                            <i className={this.props.fullScreenMode ? "line-horizontal width-fullscreen-manual-mode display-inline" : 
                                                "line-horizontal width-normal-manual-mode display-inline"}></i>
                                            <span className="line-inner-text-horizontal display-inline">{Math.trunc(widthInMicrons) + " microns"}</span>
                                            <i className={this.props.fullScreenMode ? "line-horizontal width-fullscreen-manual-mode display-inline" : 
                                                "line-horizontal width-normal-manual-mode display-inline"}></i>
                                            <i className="arrow right display-inline"></i>
                                        </Row>
                                        <Row>
                                            <img id="liveCameraImage" alt="Live" className={this.props.camera_app ? "" : "live-view"} 
                                                src={this.props.fullScreenMode ? "data:image/png;base64," + this.props.liveView.data_fullscreen : 
                                                    "data:image/png;base64," + this.props.liveView.data} />
                                        </Row>
                                    </Col>
                                    <Col span={1}>
                                        <div style={{marginLeft: 10, marginTop: 18}}>
                                            <i className="arrow display-inline up"></i>
                                            <i className="line-vertical display-block" 
                                                style={this.props.fullScreenMode ? {height: (imageHeight * 0.435)} : {height: (imageHeight * 0.41)}}></i>
                                            <span className="line-inner-text-vertical display-block">{Math.trunc(heightInMicrons) + " microns"}</span>
                                            <i className="line-vertical display-block" 
                                                style={this.props.fullScreenMode ? {height: (imageHeight * 0.435)} : {height: (imageHeight * 0.41)}}></i>
                                            <i className="arrow down display-inline"></i>
                                        </div>
                                    </Col>
                                </Row>

                                {this.props.camera_app && this.props.showMarker ? 
                                    <PlusCircleOutlined
                                        style={{color: "#83f52c", fontSize: 50, zIndex: 500}}
                                        className="absoluteChild" />
                                    :null
                                }

                                {this.props.camera_app && this.props.showDirections ? 
                                    <div>
                                        <ArrowUpOutlined
                                            style={{color: "#83f52c", fontSize: 50, zIndex: 500}}
                                            className="absoluteChildUp" />
                                        <ArrowLeftOutlined
                                            style={{color: "#83f52c", fontSize: 50, zIndex: 500}}
                                            className="absoluteChildLeft" />
                                        <ArrowDownOutlined
                                            style={{color: "#83f52c", fontSize: 50, zIndex: 500}}
                                            className="absoluteChildDown" />
                                        <ArrowRightOutlined
                                            style={{color: "#83f52c", fontSize: 50, zIndex: 500}}
                                            className="absoluteChildRight" />
                                        <div style={{color: "#83f52c", fontSize: 15, zIndex: 500}} className="absoluteChildTextUp">Real Y+</div>
                                        <div style={{color: "#83f52c", fontSize: 15, zIndex: 500}} className="absoluteChildTextDown">Real Y-</div>
                                        <div style={{color: "#83f52c", fontSize: 15, zIndex: 500}} className="absoluteChildTextLeft">Real X+</div>
                                        <div style={{color: "#83f52c", fontSize: 15, zIndex: 500}} className="absoluteChildTextRight">Real X-</div>
                                    </div>
                                    :null
                                }
                            </Col> :
                            <Col span={24} className="quadrant-image">
                                <img id="liveCameraImage" alt="Live"
                                        src={"data:image/png;base64," + this.props.liveView.data_quadrant} />
                            </Col>
                        }
                    </Row>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        appState: state.adminUrlReducer.app_state[cameraAppKey.id],
        adminState: state.adminUrlReducer,
    };
};

export default connect(mapStateToProps)(LiveView);
