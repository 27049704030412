import React, { Component } from 'react';
import { ScanOutlined } from '@ant-design/icons';
import { Row, Col, Divider, Button, Progress } from 'antd';
import { connect } from "react-redux";
import "../../asset/style/scanner/scanner.css"
import { cancelScanning } from "../../action/preview_status.action"
import { sagaMiddleware } from "../../helper/store"
import {watchPollLiveView, startLiveView, stopLiveView} from "../../action/liveview.action"

class ScanProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveView: "",
        }
    }
    
    onCancelScanning = () => {
        this.props.dispatch(cancelScanning(this.props.id, this.props.loaderPresent));
    }

    componentDidMount() {
        sagaMiddleware.run(watchPollLiveView, this.props.id);
        this.props.dispatch(startLiveView());
    }

    componentWillUnmount() {
        this.props.dispatch(stopLiveView());
    }

    render() {

        let scanStatisticsPerSlot = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).currentCassetteStats || {});
        let cancellable = (((this.props.device || {}).scanner_health || {}).scanner_response || {}).cancellable;
        cancellable = cancellable == undefined ? false : cancellable;

        let yetToScan = [];
        let scansCompleted = [];
        let currentScanProgress = 0;
        let currentScanElapsedTime = 0;
        let yetToScanComp = "";
        let scansCompletedComp = [];
        let scannerMessage = "Scanning";
        let currentScanSlot = null;
        let PERCENT_LIMIT = 85;
        let otherScansInfo = null;

        Object.keys(scanStatisticsPerSlot).forEach(function(key) {
            let scanStats = scanStatisticsPerSlot[key];
            if (scanStats.scanCompleted) {
                scansCompleted.push({
                    slot: key, 
                    name: scanStats.scanName, 
                    morphleID: scanStats.morphleID
                });
            } else if (scanStats.yetToStart) {
                yetToScan.push({
                    slot: key, 
                    name: scanStats.scanName, 
                    morphleID: scanStats.morphleID
                });
            } else {
                currentScanProgress = Math.min(PERCENT_LIMIT, scanStats.percentDone).toFixed(2);
                currentScanElapsedTime = scanStats.timeTillNow;
                currentScanSlot = key;
            }
        });

        for(let i = 0; i < yetToScan.length - 1; i++) {
            yetToScanComp += "Slot " + (parseInt(yetToScan[i].slot) + 1) + ", "
        }
        if (yetToScan.length > 0) {
            yetToScanComp += "Slot " + (parseInt(yetToScan[yetToScan.length - 1].slot) + 1);
        }

        for(let i = 0; i < scansCompleted.length - 1; i++) {
            scansCompletedComp.push(
                <a style={{padding:10}} href={"/server/v4/tissue/" + scansCompleted[i].morphleID}>
                    {scansCompleted[i].name + " "}
                </a>
                );
        }
        if (scansCompleted.length > 0) {
            scansCompletedComp.push(
                <a style={{padding:10}} href={"/server/v4/tissue/" + scansCompleted[scansCompleted.length - 1].morphleID}>
                    {scansCompleted[scansCompleted.length - 1].name + " "}
                </a>
                );
        }

        if (currentScanElapsedTime === 0) {
            scannerMessage = "Setting Up"
        } else if (currentScanProgress >= PERCENT_LIMIT) {
            scannerMessage = "Almost Done"
        }

        let currentScanHeader = null;
        
        if (scansCompleted.length !== 0 || yetToScan.length !== 0) {
            otherScansInfo = [
                <Divider key={0} style={{color:"#535c68", padding:10, paddingTop:40}} className="arial-font">{yetToScan.length} Queued for Scanning</Divider>,
                <Row key={1} className="arial-font" style={{fontSize:15}}>{yetToScanComp}</Row>, 
                <Divider key={2} style={{color:"#535c68", paddingTop:40}} className="arial-font">{scansCompleted.length} Scans Completed</Divider> ,
                <Row key={3} className="arial-font" style={{fontSize:16}}>
                    {scansCompletedComp}
                </Row> 
            ]
            currentScanHeader = "Scanning Slot : " + (parseInt(currentScanSlot) + 1);
        } 

        return (
            <Row className="rounded-container">
                <Col span={6} offset={9} className="slot-menu scan-progress-section">
                    <Row><Divider style={{color:"#535c68"}} className="arial-font">{currentScanHeader}</Divider></Row>
                    <Row className="arial-font" style={{padding:10, fontSize:15}}>{scannerMessage}</Row>
                    <Progress type="circle" percent={parseFloat(currentScanProgress)} width={80} />
                    {otherScansInfo}
                    <Row className="start-scan-button-div">
                        <Button type="danger" icon={<ScanOutlined />} size={'large'} onClick={this.onCancelScanning} disabled={!cancellable}>Stop Scanning</Button>
                    </Row>
                    <Row><Divider style={{color:"#535c68"}} className="arial-font"></Divider></Row>
                </Col>
                {/* <Col span={12} offset={3}>
                    <Row><img alt="Live" className="live-view" src={"data:image/png;base64," + this.props.liveView.data} /></Row>
                </Col> */}
            </Row>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.deviceStatusReducer[ownProps.id], 
        liveView: state.liveViewReducer
    };
};

export default connect(mapStateToProps)(ScanProgress);
