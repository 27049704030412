import React from "react";
import axios from "axios";
import cookie from 'react-cookies';
import {message} from 'antd';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {notifyError} from "./display.notification";

var querystring = require('querystring');

export const uploadSlide = (slideId, runAi, callback) => {
    let url = `/api/deepbio_upload/?id=${slideId}&run_ai=${runAi || false}`
    axios.get(url).then(() => {
        message.info("Slide Upload Started")
        callback && callback();
    }).catch(error => notifyError("deepbio", "DeepBio Upload Failed"));
}


export const runDeepBioAI = (morphleId, slideId, selectedAnnotations, callback) => {
    let url = `/api/deep_bio_analysis_request/`
    axios.post(url,
    querystring.stringify({
        morphle_id: morphleId,
        slide_id: slideId,
        creator: parseInt(cookie.load('user_id')),
        checked_annotations: selectedAnnotations.filter(id => id !== 'ALL'),
    })).then(res => {
        message.info("Request Successful")
        callback(res);
    }).catch(callback);
}

export const getDeepBioResultComponent = (summary, showTableHead) =>
    summary ? <TableContainer component={Paper}>
            <Table sx={{ width: '95%' }} size="small" aria-label="a dense table">
                {showTableHead ?
                    <TableHead>
                        <TableRow>
                            <TableCell>Property</TableCell>
                            <TableCell align="right">Value</TableCell>
                        </TableRow>
                    </TableHead> : undefined
                }
                <TableBody>
                    {Object.entries(summary).map(([key, value]) =>
                        <TableRow>
                            <TableCell sx={{color:"#00f548"}}>{key}</TableCell>
                            <TableCell>{value}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer> : null;
