import { Button, Col, Divider, Progress, Row, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { watchPollStatus } from "../../../action/device_status.action";
import { startLiveView, stopLiveView, watchPollLiveView } from "../../../action/liveview.action";
import { cancelScanning } from "../../../action/preview_status.action";
import { getSlideStatus } from '../../../action/slides.action';
import "../../../asset/style/scanner/scanner.css";
import '../../../asset/style/workflow/scan-progress.css';
import { sagaMiddleware } from "../../../helper/store";
import cookie from "react-cookies";
import { globalUrlPrefix } from '../../../utils/const';
import { getBarcodeCrop } from '../preview_utils';

class ScanProgress extends Component {
    constructor(props) {
        super(props);

        let cassetteSizeArray = [];

        for (let i = 0; i < this.props.scannerProperties.cassetteSize; i++) {
            cassetteSizeArray.push(i);
        }

        this.state = {
            liveView: "",
            debugVisible: false,
            slotIndexes: this.props.scannerProperties.deviceType === "INDEX" ? [0] : cassetteSizeArray,
            stitcher_percent: -1,
            date: Date.now()
        }
        this.stitchingPoll = null;
    }
    
    onCancelScanning = () => {
        console.log("Cancelling scan");
        this.props.dispatch(cancelScanning(this.props.device_id, this.props.loaderPresent));
        if (((((this.props.device || {}).scanner_health || {}).scanner_response || {}).busyOrder || []).length !== 0) {
            console.log("had to call again Cancelling scan");
            setTimeout(this.onCancelScanning(), 10)
        }
        // this.props.handleProgressChange(0);
        window.location.pathname = globalUrlPrefix + '/scanners/' + this.props.device_id + '/';
    }

    onBackToControlPanel = () => {
        console.log("Going back to control panel");
        window.location.pathname = globalUrlPrefix + '/scanners/' + this.props.device_id + '/';
    }

    componentDidMount() {
        sagaMiddleware.run(watchPollStatus, this.props.dispatch, this.props.device_id);
        sagaMiddleware.run(watchPollLiveView, "1");
        this.stitchingPoll = setInterval(this.slideRunner, 500);
    }

    componentDidUpdate = () => {
        let scannerStatus = (((this.props.device || {}).scanner_health || {}).scanner_response || {});
        if (scannerStatus.erroredOut && !scannerStatus.scanning) {
            console.log('scan crashed', scannerStatus.erroredOut, scannerStatus.scanning);
            window.location.pathname = globalUrlPrefix + '/scanners/' + this.props.device_id + '/';
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.stitchingPoll);
        this.stitchingPoll = null;
    }

    slideRunner = () => {
        let scansCompleted = [];
        let scanStatisticsPerSlot = (((((this.props || {}).device || {}).scanner_health || {}).scanner_response || {}).currentCassetteStats || {});
        Object.keys(scanStatisticsPerSlot).forEach(function(key) {
            let scanStats = scanStatisticsPerSlot[key];
            if (scanStats.scanCompleted) { 
                scansCompleted.push({
                    slot: key, 
                    name: scanStats.scanName, 
                    morphleID: scanStats.morphleID
                });
            }
        });
        if (scansCompleted.length !== 0) {
            scansCompleted.map((scan) => {
                this.props.dispatch(getSlideStatus(scan.morphleID, scan.slot));
            })
        }
    }

    componentWillUnmount() {
        this.props.dispatch(stopLiveView());
    }

    toggleLiveView = () => {
        
        if (this.state.debugVisible) {
            this.props.dispatch(stopLiveView());
        } else {
            this.props.dispatch(startLiveView());
        }

        this.setState({
            debugVisible: this.state.debugVisible ? false : true,
        })
    }

    render() {

        let date = new Date();
        let scanStatisticsPerSlot = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).currentCassetteStats || {});
        let cancellable = (((this.props.device || {}).scanner_health || {}).scanner_response || {}).cancellable;
        cancellable = cancellable == undefined ? false : cancellable;

        let yetToScan = [];
        let scansCompleted = [];
        let currentScanProgress = 0;
        let currentScanElapsedTime = 0;
        let yetToScanComp = "";
        let scansCompletedComp = [];
        let scannerMessage = "Scanning";
        let currentScanSlot = null;
        let PERCENT_LIMIT = 85;
        let otherScansInfo = null;

        Object.keys(scanStatisticsPerSlot).forEach(function(key) {
            let scanStats = scanStatisticsPerSlot[key];
            if (scanStats.scanCompleted) {
                scansCompleted.push({
                    slot: key, 
                    name: scanStats.scanName, 
                    morphleID: scanStats.morphleID
                });
            } else if (scanStats.yetToStart) {
                yetToScan.push({
                    slot: key, 
                    name: scanStats.scanName, 
                    morphleID: scanStats.morphleID
                });
            } else {
                currentScanProgress = Math.min(PERCENT_LIMIT, scanStats.percentDone).toFixed(2);
                currentScanElapsedTime = scanStats.timeTillNow;
                currentScanSlot = key;
            }
        });

        for(let i = 0; i < yetToScan.length - 1; i++) {
            yetToScanComp += "Slot " + (parseInt(yetToScan[i].slot) + 1) + ", "
        }
        if (yetToScan.length > 0) {
            yetToScanComp += "Slot " + (parseInt(yetToScan[yetToScan.length - 1].slot) + 1);
        }

        // console.log((((this.props.device || {}).scanner_health || {}).scanner_response || {}), yetToScan); 

        for(let i = 0; i < scansCompleted.length - 1; i++) {
            scansCompletedComp.push(
                <a style={{padding:10}} href={"/server/v4/tissue/" + scansCompleted[i].morphleID}>
                    {scansCompleted[i].name + " "}
                </a>
                );
        }
        if (scansCompleted.length > 0) {
            scansCompletedComp.push(
                <a style={{padding:10}} href={"/server/v4/tissue/" + scansCompleted[scansCompleted.length - 1].morphleID}>
                    {scansCompleted[scansCompleted.length - 1].name + " "}
                </a>
                );
        }

        if (currentScanElapsedTime === 0) {
            scannerMessage = "Setting Up"
        } else if (currentScanProgress >= PERCENT_LIMIT) {
            scannerMessage = "Almost Done"
        }

        let currentScanHeader = null;

        if (scansCompleted.length !== 0 || yetToScan.length !== 0) {
            otherScansInfo = [
                <Divider key={0} style={{color:"#535c68", padding:10, paddingTop:40}} className="arial-font">{yetToScan.length} Queued for Scanning</Divider>,
                <Row key={1} className="arial-font" style={{fontSize:15}}>{yetToScanComp}</Row>, 
                <Divider key={2} style={{color:"#535c68", paddingTop:40}} className="arial-font">{scansCompleted.length} Scans Completed</Divider> ,
                <Row key={3} className="arial-font" style={{fontSize:16}}>
                    {scansCompletedComp}
                </Row> 
            ]
            currentScanHeader = "Scanning Slot : " + (parseInt(currentScanSlot) + 1);
        }

        let completedScans = {}
        for (let id in scansCompleted) {
            completedScans[scansCompleted[id].slot] = scansCompleted[id]
        }

        let slides = Object.keys(this.state.slotIndexes).map((index) => {
            return <Col span={4} key={index} className="centered">
                        <a href={completedScans.hasOwnProperty(index) && (this.props.slideStatus[index] || {}).status === 12 ? "/server/v4/tissue/" + (completedScans[index] || {}).morphleID : "#" }>
                        <div className="centered carousel-item" 
                            style={index in scanStatisticsPerSlot 
                                ? currentScanSlot === index ? {height: '486px', backgroundImage: 'url(/dev-ssd/scans_preview/S'+ (parseInt(index)+1) + 'T1R1/pre-processed/rotated_thumbnail.jpg?'+ this.state.date +')',
                                    backgroundSize: 'auto 350px', backgroundColor: 'rgb(255 255 255 / 40%)', animation: 'shadow-pulse4 1.5s infinite', backgroundRepeat: 'no-repeat',
                                    backgroundPositionY: 'top'}
                                     : {height: '486px', backgroundImage: 'url(/dev-ssd/scans_preview/S'+ (parseInt(index)+1) + 'T1R1/pre-processed/rotated_thumbnail.jpg)',
                                     backgroundSize: 'auto 350px', backgroundColor: 'rgb(255 255 255 / 50%)', backgroundRepeat: 'no-repeat', backgroundPositionY: 'top'}
                                : {backgroundColor: 'rgb(230 230 230 / 80%)', opacity: '0.3'}}>

                            { (index in scanStatisticsPerSlot)
                              || (currentScanSlot === index && (currentScanProgress < 100))
                              || (completedScans.hasOwnProperty(index))
                                ?
                                <div className="centered" style={{
                                    border: '0px',
                                    borderLeft: '3px solid #111111',
                                    backgroundColor: 'gray',
                                    backgroundImage: 'url(' + getBarcodeCrop(parseInt(index)) + ')',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat', transform: 'rotate(90deg)', position: 'relative',
                                    right: '0px', bottom: '-175px', height: '147px', width: '133px'}}></div>
                                : null }
                                
                            { currentScanSlot === index && currentScanProgress < 100 
                                ?   <div style={{position: 'absolute'}}>
                                        <Progress className="centered" type="circle" percent={parseFloat(currentScanProgress)} width={80} />
                                        <h4 className="centered">Scanning</h4>
                                    </div> 
                                : null 
                            }

                            { completedScans.hasOwnProperty(index) 
                                ?   <div style={{position: 'absolute'}}>
                                        <Progress type="circle" className="centered"
                                            percent={(this.props.slideStatus[index] || {}).status === 12                                                                                                                                                                                                         
                                                                ? 100 : Number((this.props.slideStatus[index] || 0).stitcher_percent).toFixed(2)} 
                                            width={80} />
                                        <h4 className="centered">
                                            { (this.props.slideStatus[index] || {}).status === 12 ? "Click to View" : "Stitching" }
                                        </h4>
                                    </div>
                                : null 
                            }
                        </div>
                        </a>
                    </Col>
        });

        let busyOrder = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).busyOrder || [])
        let scannerBusy = busyOrder.length !== 0 ? true : false;
        let busyDict = ((((this.props.device || {}).scanner_health || {}).scanner_response || {}).busyDict || {})
        let busyMessage = busyDict[busyOrder[0]]
        let isScanning = (((this.props.device || {}).scanner_health || {}).scanner_response || {}).scanning;
        let is_superuser = cookie.loadAll().superuser === "true";

        return (
            <Spin spinning={scannerBusy} tip={busyMessage}>
            <Spin spinning={(this.props.device || {}).uiBusy} tip={(this.props.device || {}).busyMessage}>
                <Row className='centered carousel'>
                    {slides}
                </Row>
                <Row className="centered">
                    <Col>
                    { is_superuser ?
                        <Button onClick={this.toggleLiveView} style={{margin: '20px'}} >
                            {this.state.debugVisible ? 'Close Debug' : 'View Debug'}
                        </Button> :  undefined
                    }
                    </Col>
                    <Col>
                        { isScanning ?
                        <Button type="danger" style={{margin: '20px'}}
                        onClick={this.onCancelScanning}
                        disabled={currentScanSlot === null}>
                            Stop Scanning
                        </Button>
                        : <Button type="primary" style={{margin: '20px'}}
                        onClick={this.onBackToControlPanel}>
                            Back to Control Panel
                        </Button>
                        }
                    </Col>
                </Row>
                <Row className="rounded-container" style={this.state.debugVisible ? {display: 'block'} : {display: 'none'}}>
                    <Col span={6} offset={3} className="slot-menu scan-progress-section">
                        <Row><Divider style={{color:"#535c68"}} className="arial-font">{currentScanHeader}</Divider></Row>
                        <Row className="arial-font" style={{padding:10, fontSize:15}}>{scannerMessage}</Row>
                        <Progress type="circle" percent={parseFloat(currentScanProgress)} width={80} />
                        {otherScansInfo}
                        <Row className="start-scan-button-div">
                            <Button type="danger" size={'large'} onClick={this.onCancelScanning} disabled={currentScanSlot === null}>Stop Scanning</Button>
                        </Row>
                        <Row><Divider style={{color:"#535c68"}} className="arial-font"></Divider></Row>
                    </Col>
                    <Col span={12} offset={3}>
                        <Row><img alt="Live" className="live-view" src={"data:image/png;base64," + this.props.liveView.data} /></Row>
                    </Col>
                </Row>
            </Spin>
            </Spin>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.deviceStatusReducer[ownProps.device_id], 
        liveView: state.liveViewReducer,
        previews : state.previewStatusReducer,
        slideStatus: state.slidesReducer,
    };
};

export default connect(mapStateToProps)(ScanProgress);
