import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import cookie from "react-cookies";
import { AuthHeader } from '../../helper/auth.token';
import { Spin, Row, Col, Button, message } from 'antd';
import { ongoingCameraAppAction, ongoingMotionCameraAction, ongoingMotionAppAction, startCameraAction, stopCameraAction } from '../../action/admin.state.action';

import "../../asset/style/manualmode/manualmode.css";


export class CommonlyUsedActionsManualMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }

    }

    startCamera = (e) => {
        this.props.dispatch(startCameraAction(this.props.deviceId, this.props.adminState));
    }

    stopCamera = (e) => {
        this.props.dispatch(stopCameraAction(this.props.deviceId, this.props.adminState));
    }

    mainLightOn = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~switch_on_main_light";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light on!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light on!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
    }

    mainLightOff = (e) => {
        this.props.dispatch(ongoingCameraAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~switch_off_main_light";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light off!!", 2.5);
                }
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light off!!", 2.5);
                this.props.dispatch(ongoingCameraAppAction(this.props.adminState, false));
            })
    }

    autoFocus = (e) => {
        this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, true));
        let partsOfUrl = "api~diagnostics~autofocus";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl + `?move=false&doDrop=false`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to do autofocus!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do autofocus!!", 2.5);
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
    }

    strobeFocus = (e) => {
        this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, true));
        let partsOfUrl = "api~diagnostics~autofocus";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl + `?move=false&doDrop=false&strobe=true`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let metrics = response.data.metricsMap;
                    message.info("Focus up : " + metrics.focused_z_up[0] + " Focus Down: " + metrics.focused_z_down[0]);
                }
                else {
                    console.log(response);
                    message.error("Not able to do autofocus!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do autofocus!!", 2.5);
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
    }

    dropDistanceAF = (e) => {
        this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, true));
        let partsOfUrl = "api~diagnostics~autofocus";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl + `?move=false&doDrop=true`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to do Drop Distance Autofocus!!", 2.5);
                }
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do Drop Distance Autofocus!!", 2.5);
                this.props.dispatch(ongoingMotionCameraAction(this.props.adminState, false));
            })
    }

    doHoming = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~diagnostics~homing";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to do homing!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to do homing!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    ejectSlide = (e) => {
        this.props.dispatch(ongoingMotionAppAction(this.props.adminState, true));
        let partsOfUrl = "api~scan~eject";
        let url = `/server/scano/` + this.props.deviceId + `/` + partsOfUrl + '?background=false';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to eject slide!!", 2.5);
                }
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to eject slide!!", 2.5);
                this.props.dispatch(ongoingMotionAppAction(this.props.adminState, false));
            })
    }

    getCameraAppButton = (text, onClickMethod) => {
        return (
            <Button size="small" type="primary" onClick={onClickMethod}>
                {text}
            </Button>
        )
    }

    render() {
        
        return (
            <div>
                <Spin spinning={this.props.adminState.motion_app_action || this.props.adminState.camera_app_action || 
                    this.props.adminState.preview_app_action}>
                    {cookie.loadAll().superuser === 'true' ? 
                        <Row className="buttons-row-margin-side-pan">
                            <Col offset={1} span={12}>
                                {this.getCameraAppButton('Start Camera', this.startCamera)}
                            </Col>
                            <Col span={11}>
                                {this.getCameraAppButton('Stop Camera', this.stopCamera)}
                            </Col>
                        </Row> : null
                    }
                    <Row className="buttons-row-margin-side-pan">
                        <Col offset={1} span={12}>
                            {cookie.loadAll().superuser === 'true' ? 
                                this.getCameraAppButton('Main Light On', this.mainLightOn)
                                : this.getCameraAppButton('Camera Light On', this.mainLightOn)
                            }
                        </Col>
                        <Col span={11}>
                            {cookie.loadAll().superuser === 'true' ? 
                                this.getCameraAppButton('Main Light Off', this.mainLightOff)
                                : this.getCameraAppButton('Camera Light Off', this.mainLightOff)
                            }
                        </Col>
                    </Row> 
                    <Row className="buttons-row-margin-side-pan">
                        <Col offset={1} span={12}>
                            {cookie.loadAll().superuser === 'true' ? 
                                this.getCameraAppButton('AutoFocus', this.autoFocus)
                                : this.getCameraAppButton('AutoFocus', this.autoFocus)
                            }
                        </Col>
                        <Col span={11}>
                            {cookie.loadAll().superuser === 'true' ? 
                                this.getCameraAppButton('Drop Distance AF', this.dropDistanceAF)
                                : this.getCameraAppButton('Drop Distance AF', this.dropDistanceAF)
                            }
                        </Col>
                    </Row> 
                    <Row className="buttons-row-margin-side-pan">
                        <Col offset={1} span={12}>
                            {cookie.loadAll().superuser === 'true' ? 
                                this.getCameraAppButton('StrobeFocus', this.strobeFocus)
                                : this.getCameraAppButton('StrobeFocus', this.strobeFocus)
                            }
                        </Col>
                        <Col span={11}>
                        </Col>
                    </Row> 
                    {cookie.loadAll().superuser === 'true' ? 
                        <Row className="buttons-row-margin-side-pan">
                            <Col offset={1} span={12}>
                                {this.getCameraAppButton('Do Homing', this.doHoming)}
                            </Col>
                            <Col span={11}>
                                {this.getCameraAppButton('Eject', this.ejectSlide)}
                            </Col>
                        </Row>  : null
                    }
                </Spin> 
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        adminState: state.adminUrlReducer
    };
};

export default connect(mapStateToProps)(CommonlyUsedActionsManualMode);
