import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, InputNumber, Divider, Input, Button, message, Spin } from 'antd';
import { ongoingQCSettingsAppAction } from '../../../action/admin.state.action';
import { qcSettingsAppConsts } from "../../../utils/const";

import "../../../asset/style/manualmode/qc_settings_app.css";

class QCSettingsApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scannerName: '',
            leverReduction: 0,
            minXSoftLimit: 0,
            maxXSoftLimit: 0,
            minYSoftLimit: 0,
            maxYSoftLimit: 0,
            minZSoftLimit: 0,
            maxZSoftLimit: 0,
            xStepsPermm: 0,
            yStepsPermm: 0,
            zStepsPermmOnScrew: 0,
            xMaxSpeed: 0,
            yMaxSpeed:0,
            zMaxSpeed: 0,
            xMaxAccel: 0,
            yMaxAccel:0,
            zMaxAccel: 0,
            leverReductionInputValue: 0,
            minXSoftLimitInputValue: 0,
            maxXSoftLimitInputValue: 0,
            minYSoftLimitInputValue: 0,
            maxYSoftLimitInputValue: 0,
            minZSoftLimitInputValue: 0,
            maxZSoftLimitInputValue: 0,
            xStepsPermmInputValue: 0,
            yStepsPermmInputValue: 0,
            zStepsPermmOnScrewInputValue: 0,
            xMaxSpeedInputValue: 0,
            yMaxSpeedInputValue:0,
            zMaxSpeedInputValue: 0,
            xMaxAccelInputValue: 0,
            yMaxAccelInputValue:0,
            zMaxAccelInputValue: 0,
        }

    }

    componentDidMount = () => {
        this.getScannerName();
    }

    getScannerName = () => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let url = `/server/get_scanner_name`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        scannerName: response.data,
                    })
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to fetch values!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to fetch values!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    getSettingValues = () => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~get_qc_settings";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    let data = response.data;
                    this.setState({
                        leverReduction: data[qcSettingsAppConsts.LEVER_REDUCTION],

                        minXSoftLimit: data[qcSettingsAppConsts.MIN_X_SOFT_LIMIT],
                        maxXSoftLimit: data[qcSettingsAppConsts.MAX_X_SOFT_LIMIT],
                        minYSoftLimit: data[qcSettingsAppConsts.MIN_Y_SOFT_LIMIT],
                        maxYSoftLimit: data[qcSettingsAppConsts.MAX_Y_SOFT_LIMIT],
                        minZSoftLimit: data[qcSettingsAppConsts.MIN_Z_SOFT_LIMIT],
                        maxZSoftLimit: data[qcSettingsAppConsts.MAX_Z_SOFT_LIMIT],

                        xStepsPermm: data[qcSettingsAppConsts.X_STEPS_PER_MM],
                        yStepsPermm: data[qcSettingsAppConsts.Y_STEPS_PER_MM],
                        zStepsPermmOnScrew: data[qcSettingsAppConsts.Z_STEPS_PER_MM_ON_SCREW],

                        xMaxSpeed: data[qcSettingsAppConsts.X_MAX_SPEED],
                        yMaxSpeed: data[qcSettingsAppConsts.Y_MAX_SPEED],
                        zMaxSpeed: data[qcSettingsAppConsts.Z_MAX_SPEED],

                        xMaxAccel: data[qcSettingsAppConsts.X_MAX_ACCEL],
                        yMaxAccel: data[qcSettingsAppConsts.Y_MAX_ACCEL],
                        zMaxAccel: data[qcSettingsAppConsts.Z_MAX_ACCEL],

                        leverReductionInputValue: data[qcSettingsAppConsts.LEVER_REDUCTION],

                        minXSoftLimitInputValue: data[qcSettingsAppConsts.MIN_X_SOFT_LIMIT],
                        maxXSoftLimitInputValue: data[qcSettingsAppConsts.MAX_X_SOFT_LIMIT],
                        minYSoftLimitInputValue: data[qcSettingsAppConsts.MIN_Y_SOFT_LIMIT],
                        maxYSoftLimitInputValue: data[qcSettingsAppConsts.MAX_Y_SOFT_LIMIT],
                        minZSoftLimitInputValue: data[qcSettingsAppConsts.MIN_Z_SOFT_LIMIT],
                        maxZSoftLimitInputValue: data[qcSettingsAppConsts.MAX_Z_SOFT_LIMIT],

                        xStepsPermmInputValue: data[qcSettingsAppConsts.X_STEPS_PER_MM],
                        yStepsPermmInputValue: data[qcSettingsAppConsts.Y_STEPS_PER_MM],
                        zStepsPermmOnScrewInputValue: data[qcSettingsAppConsts.Z_STEPS_PER_MM_ON_SCREW],

                        xMaxSpeedInputValue: data[qcSettingsAppConsts.X_MAX_SPEED],
                        yMaxSpeedInputValue: data[qcSettingsAppConsts.Y_MAX_SPEED],
                        zMaxSpeedInputValue: data[qcSettingsAppConsts.Z_MAX_SPEED],

                        xMaxAccelInputValue: data[qcSettingsAppConsts.X_MAX_ACCEL],
                        yMaxAccelInputValue: data[qcSettingsAppConsts.Y_MAX_ACCEL],
                        zMaxAccelInputValue: data[qcSettingsAppConsts.Z_MAX_ACCEL],
                    })
                }
                else {
                    console.log(response);
                    message.error("Not able to fetch values!!", 2.5);
                }
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to fetch values!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMinXSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MIN_X_SOFT_LIMIT +
            '&value=' + this.state.minXSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMaxXSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MAX_X_SOFT_LIMIT +
            '&value=' + this.state.maxXSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMinYSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MIN_Y_SOFT_LIMIT +
            '&value=' + this.state.minYSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMaxYSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MAX_Y_SOFT_LIMIT +
            '&value=' + this.state.maxYSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMinZSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MIN_Z_SOFT_LIMIT +
            '&value=' + this.state.minZSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setMaxZSoftLimit = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.MAX_Z_SOFT_LIMIT +
            '&value=' + this.state.maxZSoftLimitInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setXStepsPermm = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.X_STEPS_PER_MM +
            '&value=' + this.state.xStepsPermmInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setYStepsPermm = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Y_STEPS_PER_MM +
            '&value=' + this.state.yStepsPermmInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setZStepsPermmOnScrew = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Z_STEPS_PER_MM_ON_SCREW +
            '&value=' + this.state.zStepsPermmOnScrewInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setXMaxSpeed = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.X_MAX_SPEED +
            '&value=' + this.state.xMaxSpeedInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setYMaxSpeed = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Y_MAX_SPEED +
            '&value=' + this.state.yMaxSpeedInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setZMaxSpeed = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Z_MAX_SPEED +
            '&value=' + this.state.zMaxSpeedInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setXMaxAccel = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.X_MAX_ACCEL +
            '&value=' + this.state.xMaxAccelInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setYMaxAccel = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Y_MAX_ACCEL +
            '&value=' + this.state.yMaxAccelInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setZMaxAccel = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.Z_MAX_ACCEL +
            '&value=' + this.state.zMaxAccelInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    setLeverReduction = (e) => {
        this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, true));
        let partsOfUrl = "api~stage~set_qc_setting_values";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + '?type=' + qcSettingsAppConsts.LEVER_REDUCTION +
            '&value=' + this.state.leverReductionInputValue;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.getSettingValues();
                }
                else {
                    console.log(response);
                    message.error("Not able to set value!!", 2.5);
                    this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
                }
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to set value!!", 2.5);
                this.props.dispatch(ongoingQCSettingsAppAction(this.props.adminState, false));
            })
    }

    minXSoftLimitValueChange = (value) => {
        this.setState({
            minXSoftLimitInputValue: value
        })
    }

    maxXSoftLimitValueChange = (value) => {
        this.setState({
            maxXSoftLimitInputValue: value
        })
    }

    minYSoftLimitValueChange = (value) => {
        this.setState({
            minYSoftLimitInputValue: value
        })
    }

    maxYSoftLimitValueChange = (value) => {
        this.setState({
            maxYSoftLimitInputValue: value
        })
    }

    minZSoftLimitValueChange = (value) => {
        this.setState({
            minZSoftLimitInputValue: value
        })
    }

    maxZSoftLimitValueChange = (value) => {
        this.setState({
            maxZSoftLimitInputValue: value
        })
    }

    xStepsPermmValueChange = (value) => {
        this.setState({
            xStepsPermmInputValue: value
        })
    }

    yStepsPermmValueChange = (value) => {
        this.setState({
            yStepsPermmInputValue: value
        })
    }

    zStepsPermmOnScrewValueChange = (value) => {
        this.setState({
            zStepsPermmOnScrewInputValue: value
        })
    }

    xMaxSpeedValueChange = (value) => {
        this.setState({
            xMaxSpeedInputValue: value
        })
    }

    yMaxSpeedValueChange = (value) => {
        this.setState({
            yMaxSpeedInputValue: value
        })
    }

    zMaxSpeedValueChange = (value) => {
        this.setState({
            zMaxSpeedInputValue: value
        })
    }

    xMaxAccelValueChange = (value) => {
        this.setState({
            xMaxAccelInputValue: value
        })
    }

    yMaxAccelValueChange = (value) => {
        this.setState({
            yMaxAccelInputValue: value
        })
    }

    zMaxAccelValueChange = (value) => {
        this.setState({
            zMaxAccelInputValue: value
        })
    }

    leverReductionValueChange = (value) => {
        this.setState({
            leverReductionInputValue: value
        })
    }

    getQCSettingsRow = (haveRowStyle, heading, value, inputField, button) => {
        return (
            <Row style={haveRowStyle ? { marginTop: 5 } : {}}>
                <Col offset={2} span={7}>
                    <b>{heading}</b>
                </Col>
                <Col span={5}>
                    <span>{value}</span>
                </Col>
                <Col span={7}>
                    {inputField}
                </Col>
                <Col span={3}>
                    {button}
                </Col>
            </Row>
        )
    }

    getQCSettingsInputField = (inputValue, onChangeInput) => {
        return <InputNumber type="number" min={0} size="small"
            value={inputValue}
            onChange={onChangeInput} className="qc-settings-app-input-field"
        />
    }

    getZSoftLimitInputField = (inputValue, onChangeInput) => {
        return <InputNumber type="number" max={0} size="small"
            value={inputValue}
            onChange={onChangeInput} className="qc-settings-app-input-field"
        />
    }

    getQCSettingsButton = (text, onClickMethod, value1, value2) => {
        return (
            <Button size="small" type="primary" onClick={onClickMethod} disabled={value1 == value2 || value2 == null}>
                {text}
            </Button>
        )
    }

    render() {

        return (
            <Row className="app-parent overlayed-component qc-settings-app-width">
                <div style={{marginTop: 10}}>
                    <Spin spinning={this.props.adminState.motion_app_action}>
                        <Divider />
                        <h4 className="qc-settings-app-heading">Scanner Details</h4>
                        <Divider />
                        <Row>
                            <Col offset={2} span={7}>
                                <b>Scanner Name:</b>
                            </Col>
                            <Col span={12}>
                                <span>{this.state.scannerName}</span>
                            </Col>
                        </Row>
                        {this.getQCSettingsRow(true, 'Lever Reduction: ', this.state.leverReduction,
                            this.getQCSettingsInputField(this.state.leverReductionInputValue, this.leverReductionValueChange),
                            this.getQCSettingsButton('Set', this.setLeverReduction, this.state.leverReduction, this.state.leverReductionInputValue)
                        )}
                        <Divider />
                        <h4 className="qc-settings-app-heading">Soft Limits</h4>
                        <Divider />
                        {this.getQCSettingsRow(true, 'MIN-X: ', this.state.minXSoftLimit,
                            this.getQCSettingsInputField(this.state.minXSoftLimitInputValue, this.minXSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMinXSoftLimit, this.state.minXSoftLimit, this.state.minXSoftLimitInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'MAX-X: ', this.state.maxXSoftLimit,
                            this.getQCSettingsInputField(this.state.maxXSoftLimitInputValue, this.maxXSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMaxXSoftLimit, this.state.maxXSoftLimit, this.state.maxXSoftLimitInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'MIN-Y: ', this.state.minYSoftLimit,
                            this.getQCSettingsInputField(this.state.minYSoftLimitInputValue, this.minYSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMinYSoftLimit, this.state.minYSoftLimit, this.state.minYSoftLimitInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'MAX-Y: ', this.state.maxYSoftLimit,
                            this.getQCSettingsInputField(this.state.maxYSoftLimitInputValue, this.maxYSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMaxYSoftLimit, this.state.maxYSoftLimit, this.state.maxYSoftLimitInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'MIN-Z: ', this.state.minZSoftLimit,
                            this.getZSoftLimitInputField(this.state.minZSoftLimitInputValue, this.minZSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMinZSoftLimit, this.state.minZSoftLimit, this.state.minZSoftLimitInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'MAX-Z: ', this.state.maxZSoftLimit,
                            this.getZSoftLimitInputField(this.state.maxZSoftLimitInputValue, this.maxZSoftLimitValueChange),
                            this.getQCSettingsButton('Set', this.setMaxZSoftLimit, this.state.maxZSoftLimit, this.state.maxZSoftLimitInputValue)
                        )}
                        <Divider />
                        <h4 className="qc-settings-app-heading">Steps Per MM</h4>
                        <Divider />
                        {this.getQCSettingsRow(true, 'X: ', this.state.xStepsPermm,
                            this.getQCSettingsInputField(this.state.xStepsPermmInputValue, this.xStepsPermmValueChange),
                            this.getQCSettingsButton('Set', this.setXStepsPermm, this.state.xStepsPermm, this.state.xStepsPermmInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Y: ', this.state.yStepsPermm,
                            this.getQCSettingsInputField(this.state.yStepsPermmInputValue, this.yStepsPermmValueChange),
                            this.getQCSettingsButton('Set', this.setYStepsPermm, this.state.yStepsPermm, this.state.yStepsPermmInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Z on Screw: ', this.state.zStepsPermmOnScrew,
                            this.getQCSettingsInputField(this.state.zStepsPermmOnScrewInputValue, this.zStepsPermmOnScrewValueChange),
                            this.getQCSettingsButton('Set', this.setZStepsPermmOnScrew, this.state.zStepsPermmOnScrew, this.state.zStepsPermmOnScrewInputValue)
                        )}
                        <Divider />
                        <h4 className="qc-settings-app-heading">Speed</h4>
                        <Divider />
                        {this.getQCSettingsRow(true, 'X: ', this.state.xMaxSpeed,
                            this.getQCSettingsInputField(this.state.xMaxSpeedInputValue, this.xMaxSpeedValueChange),
                            this.getQCSettingsButton('Set', this.setXMaxSpeed, this.state.xMaxSpeed, this.state.xMaxSpeedInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Y: ', this.state.yMaxSpeed,
                            this.getQCSettingsInputField(this.state.yMaxSpeedInputValue, this.yMaxSpeedValueChange),
                            this.getQCSettingsButton('Set', this.setYMaxSpeed, this.state.yMaxSpeed, this.state.yMaxSpeedInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Z: ', this.state.zMaxSpeed,
                            this.getQCSettingsInputField(this.state.zMaxSpeedInputValue, this.zMaxSpeedValueChange),
                            this.getQCSettingsButton('Set', this.setZMaxSpeed, this.state.zMaxSpeed, this.state.zMaxSpeedInputValue)
                        )}
                        <Divider />
                        <h4 className="qc-settings-app-heading">Acceleration</h4>
                        <Divider />
                        {this.getQCSettingsRow(true, 'X: ', this.state.xMaxAccel,
                            this.getQCSettingsInputField(this.state.xMaxAccelInputValue, this.xMaxAccelValueChange),
                            this.getQCSettingsButton('Set', this.setXMaxAccel, this.state.xMaxAccel, this.state.xMaxAccelInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Y: ', this.state.yMaxAccel,
                            this.getQCSettingsInputField(this.state.yMaxAccelInputValue, this.yMaxAccelValueChange),
                            this.getQCSettingsButton('Set', this.setYMaxAccel, this.state.yMaxAccel, this.state.yMaxAccelInputValue)
                        )}
                        {this.getQCSettingsRow(true, 'Z: ', this.state.zMaxAccel,
                            this.getQCSettingsInputField(this.state.zMaxAccelInputValue, this.zMaxAccelValueChange),
                            this.getQCSettingsButton('Set', this.setZMaxAccel, this.state.zMaxAccel, this.state.zMaxAccelInputValue)
                        )}
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
    }
}

export default connect(mapStateToProps)(QCSettingsApp);
