import {ScanApiConstant} from "../actionTypes/scan.constant";

const InitialState = {
    polling: false,
    stale: true,
    data: undefined, 
    data_fullscreen: undefined,
    data_quadrant: undefined,
    url: undefined,
    error: undefined,
    errored_out: false,
    zoom: 1.0, 
    x: 0.0, 
    y: 0.0, 
    liveImagePolling: true,
    fullscreenMode: false,
}

export const liveViewReducer = ( state = InitialState , action) => {
    switch(action.type){
        case ScanApiConstant.START_LIVE_VIEW:
            return {
                ...state, 
                polling: true,
            }
        case ScanApiConstant.STOP_LIVE_VIEW:
            return {
                ...state, 
                polling: false,
                stale: true
            }
        case ScanApiConstant.UPDATE_LIVE_VIEW:
            return {
                ...state, 
                data: action.data, 
                stale: false
            }
        case ScanApiConstant.UPDATE_LIVE_VIEW_URL:
            return {
                ...state, 
                url: action.data, 
                stale: false
            }
        case ScanApiConstant.FAILED_IN_LIVE_VIEW_UPDATE:
            return {
                ...state, 
                stale: true,
                errorred_out: true, 
                error: action.err
            }
        case ScanApiConstant.UPDATE_ZOOM_LEVEL_LIVE_VIEW:
            return {
                ...state, 
                zoom: action.zoom,
                x: action.x, 
                y: action.y
            }
        case ScanApiConstant.START_LIVE_VIEW_FULL_SCREEN:
            return {
                ...state, 
                polling: true,
            }
        case ScanApiConstant.STOP_LIVE_VIEW_FULL_SCREEN:
            return {
                ...state, 
                polling: false,
                stale: true
            }
        case ScanApiConstant.UPDATE_LIVE_VIEW_FULL_SCREEN:
            return {
                ...state, 
                data_fullscreen: action.data, 
                stale: false
            }
        case ScanApiConstant.UPDATE_QUADRANT_VIEW:
            return {
                ...state, 
                data_quadrant: action.data, 
                stale: false
            }
        case ScanApiConstant.UPDATE_LIVE_IMAGE_POLLING_STATUS:
            return {
                ...state, 
                liveImagePolling: action.data
            }
        case ScanApiConstant.UPDATE_FULLSCREEN_MODE:
            return {
                ...state, 
                fullscreenMode: action.data
            }
        default :
            return state
    }
}