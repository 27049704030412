import { SlideActions } from "../actionTypes/slides.constant";
import { LoginAlertConstant } from "../actionTypes/login.alert.constant";
    
const allSlides = {
};

export const slidesReducer = (state = allSlides, action) => {
    let newState, currentState;
  switch (action.type) {
    case SlideActions.DELETE_SLIDE:
        currentState = Object.assign({}, state[action.id]);
        currentState.isDeleted = true;
        return {
          ...state,
          [action.id]: currentState,
        }

    case SlideActions.UPDATE_POLLING:
      delete action.data['keep_polling'];
      newState = Object.assign({}, state);
      Object.keys(action.data).forEach(id => newState[id] = Object.assign(newState[id], action.data[id]));
      return newState;

    case SlideActions.ALL_SLIDES_UPDATE_SUCCESS:
      newState = {}
      for (var i = 0; i < action.response.data.results.length; i++){
        let slide = action.response.data.results[i]
        newState[slide.id] = slide;
        newState[slide.id].displayOrder = i;
      }
      return newState;

    case LoginAlertConstant.LOGOUT:
      return {};

    case SlideActions.SLIDE_LIST_UPDATE_INITIATED:
        currentState = Object.assign({}, state[action.id]);
        currentState.isFetching = true;
        return {
          ...state,
          [action.id]: currentState
        }

    case SlideActions.SLIDE_LIST_UPDATE:
      currentState = Object.assign({}, state[action.id]);
      currentState = action.updatedInfo;
      currentState.isFetching = false;
      return {
        ...state,
        [action.id]: currentState
      }

    case SlideActions.SLIDE_LIST_DELETE:
        newState = Object.assign({}, state);
        delete newState[action.id];
        return newState;

    case SlideActions.SLIDE_LIST_UPDATE_FAILED:
        currentState = Object.assign({}, state[action.id]);
        currentState.isFetching = false;
        currentState.error = action.response
        return {
          ...state,
          [action.id]: currentState
        }

    case SlideActions.SET_SLIDE_LIST_ITEM_FETCHED:
        currentState = Object.assign({}, state[action.id]);
        currentState.isFetching = false;
        return {
          ...state,
          [action.id]: currentState
        }
    case SlideActions.SLIDE_STATUS_UPDATED:
        return {
          ...state,
          [action.id]: action.status
        }

    default:
      return state;

  }
};
