import React, {Component} from  "react";
import AbstractAnnotator from './abstract_annotator'
import { taggerKey } from './tagger_key'
import "../../../../asset/style/neoviewer/annotations_app.css"

export default class AnnotationsApp extends Component {

    constructor(props) {
        super(props);
    }

    render(){

        return (
            <AbstractAnnotator 
                creator={taggerKey.GENERIC}
                showOptions={true}
                openAppOnAnnoClick={true}
                {...this.props}
                />
        )
    }
}