import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import React, { Component } from 'react';
import { initPreviewForScan } from "../../../action/preview_status.action";
import { connect } from "react-redux";


class LoadSlidesPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount = () => {
        if (this.props.cassetteSize == 1 || this.props.cassetteSize == 2) {
            this.props.handleProgressChange(1);
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.previews == undefined && this.props.previews) {
            this.props.dispatch(initPreviewForScan());
        }
    }

    render() {
        return (
            <Row key={0} className="rounded-container load-slides-message-div" style={{height: '500px'}}>
                <Col className="scan-workflow-init" span={24}>
                    <Row style={{ fontSize: 20 }} className="arial-font">
                        {(this.props.cassetteSize == 1 || this.props.cassetteSize == 2) ? 
                            "Please click done to view preview." :
                            <div>
                                <Row>
                                    Please insert slides in the Cassette
                                </Row>
                                <br />
                                <Row>
                                    and
                                </Row>
                                <br />
                                <Row>
                                    Place the Cassette back in the slot.
                                </Row>
                            </div>
                        }
                    </Row>
                    <br></br>
                    <br></br>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Tooltip title="Click here when done" placement="rightTop">
                            <Col span={4} className="promt-done-button" onClick={(e) => {e.stopPropagation(); this.props.handleProgressChange(1);}}>
                                <Row style={{ color: "#26A65B" }}>
                                    <CheckCircleOutlined style={{ fontSize: 50 }}/>
                                </Row>
                                <Row className="arial-font">Done</Row>
                            </Col>
                        </Tooltip>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
    };
};

export default connect(mapStateToProps)(LoadSlidesPrompt);