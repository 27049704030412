import React, {Component} from  "react";
import {connect} from 'react-redux';
import { Row, Col, List, Typography } from 'antd';
import AbstractAnnotator from './abstract_annotator'
import { taggerKey } from './tagger_key'
import { convertSettingsToFilter, getPrefixedUrl } from '../../../../utils/utils';
import * as keys from './drawing/draw_tool_keys'
import { Button } from 'antd';
import AnnotationMap from './annotation_map/annotation_map'

import "../../../../asset/style/neoviewer/ihc.css"

class IHCApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    ihcViewCreator = (annotations, selectedAnnotationId, onSelectAnnotation, onDeleteAnnotation, doRefreshAnnotationsFromBackend) => {

        let annotationList, selectedAnnotationDrawer;
    
        if (annotations.length > 0) {
            annotationList =  <Col key={0}
                                    className="ihc-list annotation-list scrollbar" 
                                    span={4}>
                                    <List
                                        size="small"
                                        bordered
                                        dataSource={annotations}
                                        renderItem={(item, index) => <List.Item 
                                                                        key={item.id} 
                                                                        style={{cursor: 'pointer'}}
                                                                        className={item.id === selectedAnnotationId ? "selected-annotation" : ""}
                                                                        onClick={()=> onSelectAnnotation(item, index)}>
                                                                        <img 
                                                                            className="annotation-crop"
                                                                            alt={"annotation-crop"}
                                                                            style={{filter:convertSettingsToFilter(this.props.viewerSettingData), zIndex: 1}}
                                                                            src={getPrefixedUrl(this.props.slide.path + '/ugc/annotations/' + item.id + '/0/x0y0.jpg?d=' + new Date().getTime(), this.props.slide)} />
                                                                        {/* <Icon className="delete-annotation" type="close-circle" onClick={
                                                                            (e) => onDeleteAnnotation(e, item.id, index)
                                                                            }>
                                                                        </Icon> */}
                                                                        <Button
                                                                        type="default"
                                                                        shape="circle"
                                                                        style={{color: 'red'}}
                                                                        // icon={<CloseCircleOutlined />}
                                                                        className="delete-annotation"
                                                                        onClick={(e) => onDeleteAnnotation(e, item.id, index)}
                                                                    > X </Button>
                                                                    </List.Item>}
                                    />
                                </Col>;
        
            let selectedAnnotationContent;
        
            if (selectedAnnotationId != null) {
                let selected;
                for (let i = 0; i < annotations.length; i++) {
                    if (annotations[i].id === selectedAnnotationId) {
                        selected = annotations[i];
                        break;
                    }
                }
                selectedAnnotationContent = <Col className="selected-annotation-drawer">
                                                    <AnnotationMap 
                                                        slide={this.props.slide}
                                                        annotation={selected}
                                                        />
                                                </Col>
            }
    
            selectedAnnotationDrawer = <Col key={1}
                                            span={20}
                                            className="selected-annotation-drawer-col">
                                            <Row 
                                                className="ihc-drawer-row selected-annotation-drawer-row">
                                                {selectedAnnotationId != null ? 
                                                    selectedAnnotationContent : <div className="no-annotation-placeholder unselectable">No Annotation Selected</div>}
                                            </Row>
                                        </Col>
        }
    
        return [
            annotationList, 
            selectedAnnotationDrawer
        ]
    }

    render(){   

        return (
            <AbstractAnnotator 
                creator={taggerKey.IHC}
                showOptions={false}
                openAppOnAnnoClick={true}
                annotationsViewCreator={this.ihcViewCreator}
                drawToolKeys={[keys.freehandDrawingKey.name, keys.rectangleDrawingKey.name, keys.circleDrawingKey.name]}
                extraClass="ihc-app-parent"
                {...this.props}
                />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(IHCApp);
