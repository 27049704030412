import React from 'react';
import { Input, Spin, Modal, Row, Col, Checkbox } from 'antd';
import { connect } from 'react-redux'
import { changeMetricColumnVisibleStatus } from "../../action/admin.state.action";
import { metricType } from "../../utils/const";

import "../../asset/style/slide_statistics.css";

class MetricSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedKeyTitle: '',
            valueChange: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedKey.id != this.props.selectedKey.id) {
            this.setState({
                selectedKeyTitle: this.props.selectedKey.title,
            });
        }
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            selectedUserUsername: '',
        });
    }

    render() {

        return (
            <Modal
                title="Change Metric Settings"
                visible={this.props.modalVisible}
                // onOk={this.handleOk}
                onCancel={this.handleCancel}
                // okButtonProps={{ disabled: !(!this.state.errorFlag && this.state.newPassword.length > 0 && this.state.confirmNewPassword.length > 0) }}
            >
                <div>
                        <Row>
                            <Input placeholder="Title" value={this.state.selectedKeyTitle} className="input-field"
                                autoComplete="off" disabled style={{ color: 'black' }} />
                        </Row>
                        <br />
                        <Row>
                            <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[this.props.selectedKey.id] || {}).show_min} 
                                onChange={(e) => this.onChange(e, this.props.selectedKey, metricType.MIN)}>Show Min</Checkbox>
                        </Row>
                        <br />
                        <Row>
                            <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[this.props.selectedKey.id] || {}).show_max} 
                                onChange={(e) => this.onChange(e, this.props.selectedKey, metricType.MAX)}>Show Max</Checkbox>
                        </Row>
                        <br />
                        <Row>
                            <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[this.props.selectedKey.id] || {}).show_sum} 
                                onChange={(e) => this.onChange(e, this.props.selectedKey, metricType.SUM)}>Show Sum</Checkbox>
                        </Row>
                        <br />
                        <Row>
                            <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[this.props.selectedKey.id] || {}).show_avg} 
                                onChange={(e) => this.onChange(e, this.props.selectedKey, metricType.AVG)}>Show Avg</Checkbox>
                        </Row>
                        <br />
                        <Row>
                            <Checkbox className="custom-checkbox-style" checked={(this.props.metricState[this.props.selectedKey.id] || {}).show_num} 
                                onChange={(e) => this.onChange(e, this.props.selectedKey, metricType.NUM)}>Show Num</Checkbox>
                        </Row>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        metricState: state.adminUrlReducer.metric_state
    }
}

export default connect(mapStateToProps)(MetricSettings);