import React, {Component} from "react";
import {Divider, Grid, Stack} from "@mui/material";
import {connect} from "react-redux";
import Typography from "@mui/material/Typography";
import {StyledSwitch} from "../components/StyledComponents";
import {toggleKeyboardShortcuts, updateKeyboardShortcuts} from "../../../action/gamma.state.action";
import {defaultKeyboardShortcuts} from "../consts/defaultKeyboardShortcuts";


class ShortcutsApp extends Component {

	constructor(props) {
		super(props);

		if (Object.keys(this.props.keyboardShortcuts).length === 0)
			this.props.dispatch(updateKeyboardShortcuts(defaultKeyboardShortcuts));  // default for new users
	}

	isEnable = () => this.props.keyboardShortcutsEnable;

	toggle = () => this.props.dispatch(toggleKeyboardShortcuts(!this.isEnable()));

	// TODO: enable user to edit keyboard shortcuts
	updateKeyboardShortcut = (id, shortcut) =>
		this.props.dispatch(updateKeyboardShortcuts({
			[id]: shortcut
		}));

	render() {
		let keyboardShortcuts = this.props.keyboardShortcuts;

		return <Grid>
			<Grid item xs={12}>
				<h3 style={{color: "white", textAlign : 'center', marginTop: '10px'}}>Keyboard Shortcuts</h3>
				<Divider style={{margin: "10px"}} />
			</Grid>
			<Stack direction={"row"} alignItems="center" justifyContent={"space-between"}>
				<Typography sx={{fontWeight : 700}}>Enable</Typography>
				<StyledSwitch color={"secondary"} checked={this.isEnable()} onChange={this.toggle}></StyledSwitch>
			</Stack>
			<Divider style={{margin: "10px"}} />
			<Stack direction={"column"} spacing={1}>
				{Object.keys(keyboardShortcuts).sort((id1, id2) =>
					keyboardShortcuts[id1].shortcut.toString() > keyboardShortcuts[id2].shortcut.toString() ? 1 : -1)
					.map(id =>
					<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
						<Typography sx={{fontWeight : 500}}>{keyboardShortcuts[id].label}</Typography>
						<Typography color={"secondary"} sx={{mr: 2, fontWeight : 500}}>
							{keyboardShortcuts[id].shortcut}</Typography>
					</Stack>
				)}
			</Stack>
		</Grid>
	}
}
const mapStateToProps = (state) => ({
	keyboardShortcutsEnable: state.gammaStateReducer.keyboardShortcutsEnable,
	keyboardShortcuts: state.gammaStateReducer.keyboardShortcuts,
});

export default connect(mapStateToProps)(ShortcutsApp);
