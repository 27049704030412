import { CaseActions } from "../actionTypes/cases.constant";
import { LoginAlertConstant } from "../actionTypes/login.alert.constant";

const allCases = {
};

export const casesReducer = (state = allCases, action) => {
  switch (action.type) {
    case CaseActions.ALL_CASES_UPDATE_SUCCESS:
      var updatedInfo = {}
      for (var i = 0; i < action.response.data.results.length; i++){
        let currCase = action.response.data.results[i]
        updatedInfo[currCase.id] = currCase;
        updatedInfo[currCase.id].displayOrder = i;
      }
      return updatedInfo;
    case LoginAlertConstant.LOGOUT:
      return {};
    case CaseActions.CASE_LIST_UPDATE:
      var currentInfo = Object.assign({}, state[action.id]);
      currentInfo = action.updatedInfo;
      return {
        ...state,
        [action.id]: currentInfo
      }
    case CaseActions.CASE_LIST_DELETE:
      var newState = Object.assign({}, state);
      delete newState[action.id];
      return newState;
    default:
      return state;
  }
};
