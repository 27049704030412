import { ContextMenuConstants } from "../actionTypes/context.menu.constant"

export const makeMenuVisible = (popupProps) => ({
    type : ContextMenuConstants.MAKE_MENU_VISIBLE, 
    popupProps: popupProps,
})

export const makeMenuInvisible = () => ({
    type : ContextMenuConstants.MAKE_MENU_INVISIBLE, 
})

export const updateScrollHeight = (popupProps) => ({
    type : ContextMenuConstants.UPDATE_SCROLL_HEIGHT, 
    popupProps: popupProps,
})