import React, { Component } from "react";
import { connect } from "react-redux";

import {
    BgColorsOutlined,
    ControlOutlined,
    MobileOutlined,
    ReloadOutlined,
    ShareAltOutlined,
    ToolOutlined,
    UserOutlined,
    ExpandOutlined,
} from '@ant-design/icons';

import { Row, Col, Spin, message, Icon } from 'antd';
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { globalUrlPrefix } from "../utils/const";
import cookie from 'react-cookies';
import "../asset/style/scanner/scanner.css";

class SettingsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            deviceId: this.props.match.params.id,
        }
    }

    reloadSettings = () => {
        this.setState({
            isBusy: true,
        });
        const url = "/server/devices/" + this.state.deviceId + "/settings/reload/";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(res => {
                message.success("Settings reloaded successfully.");
                this.setState({
                    isBusy: false,
                });
            })
            .catch(err => {
                message.error("Reloading Failed. Contact Admin");
                this.setState({
                    isBusy: false,
                });
            });
    }

    render() {

        let scanner_control = <Row>
            <Col span={12} offset={6}>
                <Row className="scanner-main-control-buttons">
                    <Col span={6}>
                        <a href={"/server/devices/" + this.state.deviceId + "/settings/tune_intra_field/"} className="scanner-user-controls">
                            <Row><ToolOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Tune Intrafield</Row>
                        </a>
                    </Col>
                    <Col span={6}>
                        <a href={"/server/devices/" + this.state.deviceId + "/settings/tune_camera/"} className="scanner-user-controls">
                            <Row><BgColorsOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Exposure &amp; White Balance</Row>
                        </a>
                    </Col>
                    <Col span={6}>
                        <a href={"/" + globalUrlPrefix + "/stagecleaner/" + this.state.deviceId} className="scanner-user-controls">
                            <Row><MobileOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Stage Maintenance</Row>
                        </a>
                    </Col>
                    <Col span={6}>
                        <a href={"/" + globalUrlPrefix + "/videos/" + this.state.deviceId} className="scanner-user-controls">
                            <Row><UserOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Helper Guide</Row>
                        </a>
                    </Col>
                </Row>
                <Row className="scanner-main-control-buttons">
                    <Col offset={9} span={6}>
                        <a href={"/" + globalUrlPrefix + "/vignette_diagnostics/" + this.state.deviceId} className="scanner-user-controls">
                            <Row><ExpandOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Scanner Calibration</Row>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>;

        let admin_control = <Row>
            <Col span={12} offset={6}>
                <Row className="scanner-main-control-buttons">
                    <Col span={6}>
                        <a href={"/server/devices/" + this.state.deviceId + "/settings/preview_tuning/"} className="scanner-user-controls">
                            <Row><ControlOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Preview Tuning</Row>
                        </a>
                    </Col>
                    <Col span={6}>
                        <a href={"/server/devices/" + this.state.deviceId + "/settings/mapping_fine_tune/"} className="scanner-user-controls">
                            <Row><ShareAltOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Mapping Fine Tune</Row>
                        </a>
                    </Col>
                    <Col span={6} onClick={this.reloadSettings}>
                        <a className="scanner-user-controls">
                            <Row><ReloadOutlined className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Reload Settings</Row>
                        </a>
                    </Col>
                    <Col span={6}>
                        <a href={'/server/devices/' + this.state.deviceId + '/manualmode'} className="scanner-user-controls">
                            <Row><Icon type="sliders" className="scanner-main-control-icon" /></Row>
                            <Row className="arial-font">Old Manual Mode</Row>
                        </a>
                    </Col>
                </Row>
            </Col>
        </Row>;


        return (
            <div>
                <Row className="rounded-container">
                    <Col className="scanner-name arial-font" span={24}>
                        <div className="device-name">
                            Settings
                        </div>
                    </Col>
                </Row>
                <Spin spinning={this.state.isBusy} size="large">
                    {cookie.loadAll().superuser === "true" ?
                        admin_control : null
                    }
                    {scanner_control}
                </Spin>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        device: state.deviceStatusReducer[ownProps.match.params.id]
    };
};

export default connect(mapStateToProps)(SettingsView);
