import React from 'react';
import { Input, Tag, Tooltip, notification, Select, Modal, Row, Col } from 'antd';
import { connect } from 'react-redux'
import { slideListUpdateSuccess, slideListDeleteSuccess } from '../../action/slides.action';
import axios from 'axios';
import { message } from 'antd';
import { AuthHeader } from '../../helper/auth.token';
import { globalUrlPrefix } from '../../utils/const';


class MultipleCaseSuggestions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            name_suggestions: [],
            selectedCaseID: '',
        }
    }

    typeAheadSearch = (value) => {
        this.setState({
            inputValue: value,
        });
        if (value.length >= 1) {
            var prefix = value;
            let url = "/api/typeahead_case/?prefix=" + prefix;
            axios
                .get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    var res_list = response.data;
                    var i = 0;
                    for (i = 0; i < res_list.length; i++) {
                        res_list[i].display_text = res_list[i].display_text.replace(/^_/, "").toUpperCase();
                    }
                    this.setState({
                        name_suggestions: res_list,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({
                name_suggestions: []
            });
        }
    }

    selectCase = (values) => {
        if(values == -1) {
            window.location.href = "/" + globalUrlPrefix + "/addcase/?case_id=" + this.state.inputValue + "&slides=" + this.props.selectedRowKeys.join();
        } else {
            if (values.length > 0 || values >= 0) {
                this.setState({
                    selectedCaseID: values,
                    inputValue: '',
                    name_suggestions: [],
                });
            } else {
                this.setState({
                    inputValue: '',
                    name_suggestions: [],
                    selectedCaseID: '',
                });
            }
        }
    }

    onBlurAction = (values) => {
        this.setState({
            inputValue: '',
            name_suggestions: [],
        });
    }

    handleOk = () => {
        if (this.state.selectedCaseID.length > 0) {
            this.props.selectedRowKeys.map((value, index) => {
                this.handleAssignCase(this.props.allSlides[value]);
            });
        } else {
            this.props.closeModal();
            message.error("Some error occured. Not able to assign a case.", 2.5);
            this.setState({
                inputValue: '',
                name_suggestions: [],
                selectedCaseID: '',
            });
        }
    }

    handleAssignCase = (slide) => {
        let url = `/api/assign_case/?case_id=` + this.state.selectedCaseID + `&slide=` + slide.id + `&slide_name=` + slide.name;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200 || response.status === 301 || response.status === 302) {
                    if (this.props.urlState.case_not_assigned) {
                        this.props.dispatch(slideListDeleteSuccess(slide.id));
                    } else {
                        response.data.result.displayOrder = slide.displayOrder;
                        this.props.dispatch(slideListUpdateSuccess(slide.id, response.data.result));
                    }
                } else if (response.status === 403 || response.status === 401 || response.status === 400) {
                    message.error("Some error occured. Not able to assign a case.", 2.5);
                    console.log(response);
                }
                this.props.closeModal();
                this.setState({
                    inputValue: '',
                    name_suggestions: [],
                    selectedCaseID: '',
                });
            })
            .catch(err => {
                this.props.closeModal();
                if(err.response.status == 406) {
                    message.error(err.response.data, 2.5);
                } else {
                    message.error("Some error occured. Not able to assign a case.", 2.5);
                }
                console.log(err);
                this.setState({
                    inputValue: '',
                    name_suggestions: [],
                    selectedCaseID: '',
                });
            });
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            inputValue: '',
            name_suggestions: [],
            selectedCaseID: '',
        });
    }

    slideNameChange = (e) => {
        this.setState({
            selectedSlideNumber: e.target.value,
        });
    }

    getAllSelectedSlides = (selectedIds) => {
        return <div className={selectedIds.length > 3 ? "scroll-slide-list-modal" : ""}>
            {selectedIds.map((item, index) => (
                this.props.allSlides[item] ?
                    <div>
                        <Row>
                            <Col span={9} offset={1}>
                                <Input style={{ color: "black", cursor: "default" }}
                                    value={this.state.selectedCaseID}
                                    disabled
                                />
                            </Col>
                            <Col span={13} offset={1}>
                                <Input value={this.props.allSlides[item].name}
                                    style={{ color: "black", cursor: "default" }}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                    </div>
                : null
            ))}
        </div>
    }

    render() {

        let selected = [];

        let numSuggested = this.state.name_suggestions.length;

        let selectOptions = this.state.name_suggestions.map((item, index) => (
                                <Select.Option
                                    key={index}
                                    value={item.display_text}
                                    label={item.display_text}
                                    className='search-result-tag'
                                    size='small'
                                    type='tag'
                                >
                                    {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
                                </Select.Option>
                            ));

        if(this.state.inputValue.length > 0 && this.state.name_suggestions.findIndex((item) => this.state.inputValue.toUpperCase() == item.display_text) == -1) {
            selectOptions.unshift(<Select.Option
                                    key={-1}
                                    value={-1}
                                    label={this.state.inputValue}
                                    className='search-result-tag'
                                    size='small'
                                    type='tag'
                                    >
                                        {<span><b>"{this.state.inputValue.toUpperCase()}"</b> &nbsp; Create new Case</span>} 
                                        <span style={{ float: "right", fontSize: 12 }}></span>
                                    </Select.Option>
                                );
        }
        let tagSuggestionsBox = numSuggested > 0 ?

            <Select.OptGroup>
                {selectOptions}
            </Select.OptGroup> : (this.state.inputValue.length > 0 && this.state.name_suggestions.findIndex((item) => this.state.inputValue.toUpperCase() == item.display_text) == -1) ?
                                    <Select.Option
                                    key={-1}
                                    value={-1}
                                    label={this.state.inputValue}
                                    className='search-result-tag'
                                    size='small'
                                    type='tag'
                                    >
                                        {<span><b>"{this.state.inputValue.toUpperCase()}"</b> &nbsp; Create new Case</span>} 
                                        <span style={{ float: "right", fontSize: 12 }}></span>
                                    </Select.Option> : undefined;

        

        return (
            <Modal
                title="Assign Case"
                visible={this.props.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{ disabled: !(this.state.selectedCaseID.length > 0) }}
            >
                <div>
                    <Row>
                        <Col span={9} offset={1}>
                            <b>CASE ID</b>
                        </Col>
                        <Col span={12} offset={1}>
                            <b>SLIDE NAME</b>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            {this.getAllSelectedSlides(this.props.selectedRowKeys)}
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col offset={1}>
                            <p>Please enter Case ID in the input box below.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1}>
                            <Select
                                showSearch
                                allowClear
                                autoFocus
                                placeholder="Enter Case ID"
                                className="search-input"
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={null}
                                onSearch={this.typeAheadSearch}
                                onSelect={this.selectCase}
                                onBlur={this.onBlurAction}
                                optionLabelProp="label"
                                value={selected}
                            >
                                {tagSuggestionsBox}
                            </Select>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus,
        urlState: state.searchReducer,
        allSlides: state.slidesReducer
    }
}

export default connect(mapStateToProps)(MultipleCaseSuggestions);