import React, { Component } from 'react';
import { connect } from "react-redux";
import cookie from "react-cookies";
import { Collapse } from 'antd';
import { getInitialSetupAppIcons, getQCAppIcons } from './app_icon_maker';

const { Panel } = Collapse;



export class ManualModeApps extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div>
                {cookie.loadAll().superuser === 'true' ?
                    <Collapse defaultActiveKey="camera_modes">
                        <Panel header="Initial Setup Tools" key="initial_setup_tools">
                            {getInitialSetupAppIcons(this.props.app_state)}
                        </Panel>
                        <Panel header="QC Tools" key="qc_tools">
                            {getQCAppIcons(this.props.app_state)}
                        </Panel>
                        <Panel header="R &amp; D Tools" key="r_and_d_tools">
                            <p>R &amp; D Tools</p>
                        </Panel>
                    </Collapse> :
                    getInitialSetupAppIcons(this.props.app_state)
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        app_state: state.adminUrlReducer
    };
};

export default connect(mapStateToProps)(ManualModeApps);
