import React, { Component } from "react";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import SlideList from "../component/dashboard/slidelist";
import { updateUrlStateInReducer } from '../action/search.action'
import { historyMP } from "../helper/history";
import cookie from 'react-cookies';
import {message} from 'antd';
import { openDrawer } from "../action/dashboard.action";
import '../asset/style/dashboard.css';

class AllScansView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initValue: "&nbsp",
    };
    this.props.dispatch(updateUrlStateInReducer(this.props.urlStateDashboard));
    
    // Destroys all pending messages
    message.destroy();
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
  }

  render() {
    
    return (
      <div className="dashboard">
        <Row>
          <Col span={24} offset={0} className="all-slides" id="all-slides">
            <SlideList/>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allSlides: state.slidesReducer,
    numPages: state.dashboardReducer.numPages, 
    pageNumber: state.dashboardReducer.pageNumber,
    urlStateDashboard: state.searchReducer
  };
};

export default connect(mapStateToProps)(AllScansView);