import {ContextMenuConstants} from "../actionTypes/context.menu.constant";

const InitialState = {
    popupProps: {
        visible: false,
        x: 0, 
        y: 0, 
        record: {},
        height: 0,
    },
}

export const contextMenuReducer = ( state = InitialState , action) => {
    switch(action.type) {
        case ContextMenuConstants.MAKE_MENU_VISIBLE:
            return {
                ...state, 
                popupProps: action.popupProps,
            }
        case ContextMenuConstants.MAKE_MENU_INVISIBLE:
            return {
                ...state, 
                popupProps: {
                    visible: false,
                    x: 0, 
                    y: 0, 
                    record: {},
                    height: state.popupProps.height,
                },
            }
        case ContextMenuConstants.UPDATE_SCROLL_HEIGHT:
            return {
                ...state, 
                popupProps: action.popupProps,
            }
        default :
            return state
    }
}