import React, { Component } from "react";
import { Col, Row, DatePicker, Select, Button, Modal, Input } from "antd";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { getAssignedCases, getAll, clearAllFilters, getUnreadCases, getReadCases } from "../../action/search.case.action";


const Option = Select.Option;

class FilterComp extends Component {
  constructor(props) {
    super(props);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.state = {
    };
  }

  handleTypeChange(value) {
    if (value === "case_assigned") {
      this.props.dispatch(getAssignedCases(this.props.urlState));
    } else if (value === "unread") {
      this.props.dispatch(getUnreadCases(this.props.urlState));
    } else if (value === "read") {
      this.props.dispatch(getReadCases(this.props.urlState));
    } else {
      this.props.dispatch(getAll(this.props.urlState));
    }
  }

  handleClear() {
    this.props.dispatch(clearAllFilters());
  }

  render() {
    var isMobile = cookie.loadAll().isMobile === "true";

    return (
        this.props.isFetching === true ? 
          <Row >
          </Row>
         : 
          <Row style={isMobile ? {height:"6em"} : {}}>
            <Col span={7}>
              <Select
                value={this.props.urlState.case_assigned ? "case_assigned" : this.props.urlState.unread ? "unread" : this.props.urlState.read ? "read" : "all"}
                placeholder="Select Type"
                onChange={this.handleTypeChange}
                style={{width:180, marginLeft:8}}
              >
                <Option value="all">All</Option>
                <Option value="case_assigned">Case Assigned To Me</Option>
                <Option value="unread">Unread</Option>
                <Option value="read">Read</Option>
              </Select>
            </Col>
            {/* <Col span={3}>
              <Button
                style={{
                  marginLeft: 8,
                  backgroundColor: "white",
                  color: "gray"

                }}
                onClick={this.handleClear}
              >
                Clear Filters
              </Button>
            </Col> */}
          </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchCaseReducer.isFetching,
    loginStatus: state.loginAlerts.loginStatus,
    urlState: state.searchCaseReducer
  };
};

export default connect(mapStateToProps)(FilterComp);
