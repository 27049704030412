import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Button, message, Spin, Select, Switch } from 'antd';
import { updateSelectedPoint, updateSlotsCroppingMode, updateSelectedSlot } from '../../../action/preview.tuning.action';
import { ongoingMotionPreviewAction } from '../../../action/admin.state.action';
import { slotCorners } from '../../../utils/const';

import "../../../asset/style/manualmode/preview_tuning_controls_app.css";

var querystring = require('querystring');

const { Option } = Select;

class PreviewTuningControlsApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentTimestamp: "",
        }

    }

    componentDidMount = () => {
    }

    changePoint = (value) => {
        this.props.dispatch(updateSelectedPoint(value));
    }

    changeMode = (value) => {
        this.props.dispatch(updateSlotsCroppingMode(value));
    }

    changeSlot = (value) => {
        this.props.dispatch(updateSelectedSlot(value));
    }

    getPositionValuesRow = (point) => {
        return <Row>
            <Col offset={3} span={4}>
                <b>{"Point " + point}</b>
            </Col>
            <Col span={4}>
                <span>{this.props.previewTuning.positionValues[point][0]}</span>
            </Col>
            <Col span={4}>
                <span>{this.props.previewTuning.positionValues[point][1]}</span>
            </Col>
            <Col span={4}>
                <span>{this.props.previewTuning.pixelValues[point][0]}</span>
            </Col>
            <Col span={4}>
                <span>{this.props.previewTuning.pixelValues[point][1]}</span>
            </Col>
        </Row>
    }

    getSlotPositionValuesRow = (point) => {
        return <Row>
            <Col offset={3} span={4}>
                <b>{"Slot " + point}</b>
            </Col>
            <Col span={4}>
                <span>{(this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TL] == undefined ?
                    "[0, 0]" :
                    "[" + (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TL][0] + ", " +
                    (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TL][1] + "]"}</span>
            </Col>
            <Col span={4}>
                <span>{(this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TR] == undefined ?
                    "[0, 0]" :
                    "[" + (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TR][0] + ", " +
                    (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.TR][1] + "]"}</span>
            </Col>
            <Col span={4}>
                <span>{(this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BL] == undefined ?
                    "[0, 0]" :
                    "[" + (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BL][0] + ", " +
                    (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BL][1] + "]"}</span>
            </Col>
            <Col span={4}>
                <span>{(this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BR] == undefined ?
                    "[0, 0]" :
                    "[" + (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BR][0] + ", " +
                    (this.props.previewTuning.slotCropPositionValues[point] || {})[slotCorners.BR][1] + "]"}</span>
            </Col>
        </Row>
    }

    calculateMapping = () => {
        let pixelSet = [];
        let positionSet = [];
        let keys = Object.keys(this.props.previewTuning.pixelValues);
        for(let i = 0; i < keys.length; i++) {
            pixelSet.push(this.props.previewTuning.pixelValues[keys[i]]);
            positionSet.push(this.props.previewTuning.positionValues[keys[i]]);
        }
        let value = {
            'pixel_set': JSON.stringify(pixelSet),
            'position_set': JSON.stringify(positionSet),
        }

        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));

        let url = "/server/devices/" + this.props.currentState.deviceId + "/settings/preview_tuning/save_preview_mapping_settings_v2/"

        axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
            this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
                message.success("Successfully saved mapping", 2.5);
            }
        })
        .catch(err => {
            console.log(err);
            this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            message.error("Not able to calculate mapping", 2.5);
        });
    }

    getPreviewImages = () => {
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
        let partsOfUrl = "api~camera~get_raw_preview_images";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    currentTimestamp: "" + response.data,
                });
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get preview image!!", 2.5);
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
    }

    getStitchedImage = () => {
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/settings/get_stitched_preview_image/?timestamp=" + this.state.currentTimestamp;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get preview image!!", 2.5);
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
            })
    }

    render() {

        return (
            <Row className="app-parent overlayed-component preview-tuning-controls-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Col offset={3} span={11}>
                                <Switch checked={this.props.previewTuning.slotsCroppingMode} onChange={this.changeMode} />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col offset={3} span={10}>
                                <Button size="small" type="primary" onClick={this.getPreviewImages}>
                                    Get Preview Images
                                </Button>
                            </Col>
                            {this.props.adminState.numberOfSlots == 6 ?
                                <Col span={11}>
                                    <Button size="small" type="primary" onClick={this.getStitchedImage}>
                                        Get Stitched Image
                                    </Button>
                                </Col> : null
                            }
                        </Row>
                        <br/>
                        {!this.props.previewTuning.slotsCroppingMode ?
                            <div>
                                <Row>
                                    <Col offset={3}>
                                        <Select value={this.props.previewTuning.selectedPoint} style={{ width: 120 }} onChange={this.changePoint}>
                                            <Option value={1}>Point 1</Option>
                                            <Option value={2}>Point 2</Option>
                                            <Option value={3}>Point 3</Option>
                                            <Option value={4}>Point 4</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col offset={7} span={4}>
                                        <b>Virtual X</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>Virtual Y</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>Pixel X</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>Pixel Y</b>
                                    </Col>
                                </Row>
                                <br/>
                                {this.getPositionValuesRow(1)}
                                <br/>
                                {this.getPositionValuesRow(2)}
                                <br/>
                                {this.getPositionValuesRow(3)}
                                <br/>
                                {this.getPositionValuesRow(4)}
                                <br/>
                                <Row>
                                    <Col offset={9}>
                                        <Button size="small" type="primary" onClick={this.calculateMapping}>
                                            Calculate Mapping
                                        </Button>
                                    </Col>
                                </Row>
                            </div> :
                            <div>
                                <Row>
                                    <Col offset={3}>
                                        <Select value={this.props.previewTuning.selectedSlot} style={{ width: 120 }} onChange={this.changeSlot}>
                                            <Option value={0}>Slot 0</Option>
                                            <Option value={1}>Slot 1</Option>
                                            <Option value={2}>Slot 2</Option>
                                            <Option value={3}>Slot 3</Option>
                                            <Option value={4}>Slot 4</Option>
                                            <Option value={5}>Slot 5</Option>
                                        </Select>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col offset={7} span={4}>
                                        <b>TL</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>TR</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>BL</b>
                                    </Col>
                                    <Col span={4}>
                                        <b>BR</b>
                                    </Col>
                                </Row>
                                <br/>
                                {this.getSlotPositionValuesRow(0)}
                                <br/>
                                {this.getSlotPositionValuesRow(1)}
                                <br/>
                                {this.getSlotPositionValuesRow(2)}
                                <br/>
                                {this.getSlotPositionValuesRow(3)}
                                <br/>
                                {this.getSlotPositionValuesRow(4)}
                                <br/>
                                {this.getSlotPositionValuesRow(5)}
                                <br/>
                                <Row>
                                    <Col offset={9}>
                                        <Button size="small" type="primary" onClick={this.calculateMapping}>
                                            Calculate Mapping
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        previewTuning: state.previewTuningReducer
    }
}

export default connect(mapStateToProps)(PreviewTuningControlsApp);
