import React, {Component} from "react";
import {connect} from 'react-redux';
import {FormControl, Grid, IconButton, MenuItem, Select, Stack, TextField, Tooltip} from "@mui/material";
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import CircularProgress from '@mui/material/CircularProgress';
import {updateSlideData, updateViewerSettings} from "../../../action/maps.state.action";
import {createNewViewerSetting, loadAllViewerSettings} from "../../../action/gamma.state.action";
import {ReplayOutlined} from "@mui/icons-material";
import {viewerSettingsKeys} from "../utils/viewerSettingsKeys";
import {getSettingRow} from "../utils/settingsUtils";
import {degreeToRadian, radianToDegree} from "../../../utils/utils";


class SettingsApp extends Component {

    constructor(props) {
        super(props);
        this.rowHeight = '89px';

        this.props.dispatch(loadAllViewerSettings());
    }

    initSlideState = () => {
        this.activeMapId = this.props.gammaState.activeMapId;
        this.slideState = this.props.mapsState[this.activeMapId].slideState;
        this.allViewerSettings = this.props.gammaState.allViewerSettings;
        this.viewerSettings = ((this.slideState || {}).slide_data || {}).viewer_settings;
    }

    updateActiveSlideViewerSettingsId = (id) =>
        this.props.dispatch(updateSlideData(this.activeMapId, this.slideState.slide_data.id, {
            settings: id,
        }));

    restoreSettings = () => {
        if (this.slideState.backupViewerSettings) {
            this.props.dispatch(updateViewerSettings(this.activeMapId, this.slideState.backupViewerSettings));
            this.updateActiveSlideViewerSettingsId(this.slideState.backupViewerSettings.id);
        }
    }

    getViewerSettingsComponent = (key) => getSettingRow(key.name, key.icon, this.viewerSettings[key.id], key.min,
        key.max, value => this.props.dispatch(updateViewerSettings(this.activeMapId,
            {
                id: this.viewerSettings.id,
                [key.id]: parseInt(value),
            })));

    render() {
        this.initSlideState();

        if (!this.allViewerSettings || !this.viewerSettings)
            return <div style={{height: 'inherit'}}>
                <CircularProgress color={'secondary'} sx={{marginTop: '75%', marginLeft: '40%'}}/>
            </div>

        if (!this.slideState.backupViewerSettings || this.slideState.backupViewerSettings.id !== this.viewerSettings.id)
            this.slideState.backupViewerSettings = {...this.viewerSettings}

        let viewerSettingsSelect =
            <Grid container paddingBottom={1} direction={"row"} justifyContent={"space-between"}>
                <Tooltip title={"Restore"} placement={"right"}>
                    <IconButton color={"secondary"} onClick={() => this.restoreSettings()}>
                        <ReplayOutlined />
                    </IconButton>
                </Tooltip>
                <FormControl variant="standard">
                    <Tooltip title={"Choose Settings"} placement={"left"}>
                        <Select value={this.viewerSettings.id} MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "right",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "right",
                            }}}  onChange={e =>
                            this.updateActiveSlideViewerSettingsId(e.target.value)}>
                            {this.allViewerSettings.map(viewerSettings =>
                                <MenuItem value={viewerSettings.id}>{viewerSettings.name}</MenuItem>
                            )}
                            <TextField color='secondary' margin='normal' variant={"outlined"} placeholder={"+ new"}
                                       size="small" sx={{px: 1, mx:0, width: 80,}}
                                       inputProps={{maxLength: 15}}
                                       onKeyDown={(e) =>
                                           e.keyCode === 13 ? [this.props.dispatch(createNewViewerSetting({
                                               ...this.viewerSettings,
                                               name: e.target.value,
                                           })), e.target.value = ''] : e.stopPropagation()}/>
                        </Select>
                    </Tooltip>
                </FormControl>
            </Grid>

        let rotationMenu = getSettingRow("Rotation", <ThreeSixtyIcon/>,
            parseInt(radianToDegree(this.slideState.slidemap.getView().getRotation())), 0, 360,
            value => {
                this.slideState.slidemap.getView().setRotation(degreeToRadian(value));
                this.props.dispatch(updateSlideData(this.activeMapId, this.slideState.slide_data.id, {
                    viewer_rotation: parseInt(value),
                }));
            });

        let viewerSettingsComponents = viewerSettingsKeys.map(viewerSettingsKey =>
            this.getViewerSettingsComponent(viewerSettingsKey));

        return <Stack direction={"column"} spacing={2}>
            {viewerSettingsSelect}
            {rotationMenu}
            {viewerSettingsComponents}
        </Stack>
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        gammaState: state.gammaStateReducer,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(SettingsApp);
