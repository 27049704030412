import { MagicToolActions } from "../actionTypes/magic_tool.constant";

const InitialState = {
    unsaved_magic_annotations : [],
    start_point : '',
    fetching: false,
    curr_level: 0,
    reload: false,
    curr_point: '',
    unsaved_magic_annotations_raw : [],
}

export const MagicToolReducer = (state = InitialState, action) => {
    switch(action.type){
        case MagicToolActions.UPDATE_UNSAVED_MAGIC_ANNOTATIONS:
            return Object.assign({}, state, {
                unsaved_magic_annotations: action.response
            })

        case MagicToolActions.UPDATE_UNSAVED_MAGIC_ANNOTATIONS_RAW:
            return Object.assign({}, state, {
                unsaved_magic_annotations_raw: action.response,
            })

        case MagicToolActions.UPDATE_TOOL_START_POINT:
            return Object.assign({}, state, {
                start_point: action.response
            })

        case MagicToolActions.RESET_MAGIC_TOOL_DATA:
            return Object.assign({}, state, {
                unsaved_magic_annotations: [],
                start_point: '',
                curr_level: 0,
                reload: true,
            })

        case MagicToolActions.UPDATE_CURRENT_LEVEL:
            return Object.assign({}, state, {
                curr_level: action.response,
            })

        case MagicToolActions.UPDATE_CURR_POINT:
            return Object.assign({}, state, {
                curr_point: action.response,
                reload: true,
            })

        case MagicToolActions.FETCHING_DATA:
            return Object.assign({},state, {
                fetching: action.response,
            })

        case MagicToolActions.RELOAD:
            return Object.assign({}, state, {
                reload: action.response,
            })

        default :
            return state
    }
}
