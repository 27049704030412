import React from  "react";
import { SettingOutlined } from '@ant-design/icons';

export const qcSettingsAppKey = {
    id: "qc_settings_app", 
    title : "QC Settings App", 
    showTitle : true, 
    icon: <SettingOutlined />, 
    defaultY: 2, 
    defaultX: 30
}