import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { AdminStateConstants } from '../actionTypes/admin.state.constant';
import { message } from 'antd';
import { metricType } from "../utils/const";

export const updateAppClosedStatus = (appName, closed, urlState) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_APP_CLOSED_STATE,
        appClosedStatus: closed,
        appName: appName
    })
}

export const closeAllAdminApps = () => dispatch => {

    dispatch({
        type: AdminStateConstants.CLOSE_ALL_APPS_ADMIN,
    })
}

export const arrangeAppsLiveMode = () => dispatch => {

    dispatch({
        type: AdminStateConstants.ARRANGE_APPS_LIVE_MODE,
    })
}

export const updateAppPosition = (appName, position, allAppsState, urlState) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_APP_POSITION,
        appPosition: position,
        appName: appName
    });
}

export const updateAppClickTime = (appName, urlState) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_APP_CLICK_TIME,
        appName: appName
    });
}

export const updateCameraMode = (currentState, cameraMode) => dispatch => {

    let newState = {
        ...currentState,
        camera_mode: cameraMode
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const updateQuarterSize = (currentState, quarterSize) => dispatch => {

    let newState = {
        ...currentState,
        quarter_size: quarterSize
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const updateStepSize = (currentState, stepSize) => dispatch => {

    let newState = {
        ...currentState,
        step_size: stepSize
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const updateSteps = (currentState, steps) => dispatch => {

    let newState = {
        ...currentState,
        steps: steps
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingMotionAppAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        motion_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingCameraAppAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        camera_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingPreviewAppAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        preview_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingReloadSettingsAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        reload_settings_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingMotionCameraAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        motion_app_action: actionStatus,
        camera_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingMotionPreviewAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        motion_app_action: actionStatus,
        preview_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingMotionCameraPreviewAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        motion_app_action: actionStatus,
        preview_app_action: actionStatus,
        camera_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const ongoingImagesAction = (actionStatus) => dispatch => {

    dispatch({
        type: AdminStateConstants.IMAGES_ACTION_ONGOING,
        actionStatus: actionStatus
    })
}

export const ongoingQCSettingsAppAction = (currentState, actionStatus) => dispatch => {

    let newState = {
        ...currentState,
        qc_settings_app_action: actionStatus
    }

    dispatch({
        type: AdminStateConstants.UPDATE_ADMIN_STATE,
        urlState: newState
    })
}

export const updateDeviceIP = (deviceIP) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_DEVICE_IP,
        deviceIP: deviceIP
    })
}

export const updateNumberOfSlots = (numberOfSlots) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_NUMBER_OF_SLOTS,
        numberOfSlots: numberOfSlots
    })
}

export const updateLiveModePreviewVectorLayer = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_LIVE_MODE_PREVIEW_VECTOR_LAYER,
        value: value
    })
}

export const updateDoDropDistanceAF = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_DO_DROP_DISTANCE_AF,
        value: value
    })
}

export const updateDoAutoFocus = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_DO_AUTO_FOCUS,
        value: value
    })
}

export const updateTakeZStack = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_TAKE_Z_STACK,
        value: value
    })
}

export const updateShowZStackImages = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_SHOW_Z_STACK_IMAGES,
        value: value
    })
}

export const updateLastImageTimestampLiveMode = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_LAST_IMAGE_TIMESTAMP_LIVE_MODE,
        value: value
    })
}

export const updateZStackLevel = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_Z_STACK_LEVEL,
        value: value
    })
}

export const updateFocusMode = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_FOCUS_MODE,
        value: value
    })
}

export const updateLiveModeAction = (value, message) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_LIVE_MODE_ACTION,
        value: value,
        message: message
    })
}

export const updateMainLight = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_MAIN_LIGHT,
        value: value
    })
}

export const updateVisitedArea = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_AREA_VISITED,
        value: value
    })
}

export const updateCurrPoint = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_CURR_POINT,
        value: value
    })
}

export const updateCameraConfig = (width, height, uperpixel) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_CAMERA_CONFIG,
        width: width,
        height: height,
        uperpixel: uperpixel
    })
}

export const updatePreviewImageExtent = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_PREVIEW_IMAGE_EXTENT,
        value: value
    })
}

export const updateSlotID = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_SLOT_ID,
        value: value
    })
}

export const changeStatisticsColumnVisibleStatus = (status, key, metricState) => dispatch => {

    if(metricState[key.id] == undefined) {
        metricState[key.id] = {
            visible: status,
            show_min: false,
            show_max: false,
            show_sum: false,
            show_avg: true,
            show_num: false,
        }
    } else {
        metricState[key.id].visible = status;
    }

    dispatch({
        type: AdminStateConstants.UPDATE_METRIC_STATE,
        metricState: metricState
    })
}

export const changeMetricColumnVisibleStatus = (status, key, type, metricState) => dispatch => {

    if(metricState[key.id] == undefined) {
        metricState[key.id] = {
            visible: false,
            show_min: type == metricType.MIN ? true : false,
            show_max: type == metricType.MAX ? true : false,
            show_sum: type == metricType.SUM ? true : false,
            show_avg: type == metricType.AVG ? true : false,
            show_num: type == metricType.NUM ? true : false,
        }
    } else {
        switch(type) {
            case metricType.MIN:
                metricState[key.id].show_min = status;
                break;
            case metricType.MAX:
                metricState[key.id].show_max = status;
                break;
            case metricType.SUM:
                metricState[key.id].show_sum = status;
                break;
            case metricType.AVG:
                metricState[key.id].show_avg = status;
                break;
            case metricType.NUM:
                metricState[key.id].show_num = status;
                break;
            default:
                break;
        }
    }

    dispatch({
        type: AdminStateConstants.UPDATE_METRIC_STATE,
        metricState: metricState
    })
}

export const updateUseFourXForPreview = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_USE_FOUR_X_FOR_PREVIEW,
        value: value
    })
}

export const updateUseStreakFocus = (value) => dispatch => {

    dispatch({
        type: AdminStateConstants.UPDATE_USE_STREAK_FOCUS,
        value: value
    })
}

export const getSavedImages = () => dispatch => {
    dispatch(ongoingImagesAction(true));
    let url = `/api/get_diagnostics_camera_images/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                let newList = response.data.file_list.sort().reverse();
                dispatch(ongoingImagesAction(false));
                dispatch({
                    type: AdminStateConstants.UPDATE_SAVED_IMAGES,
                    images: newList
                });
            }
            else {
                console.log(response);
                message.error("Not able to fetch images!!", 2.5);
                dispatch(ongoingImagesAction(false));
                dispatch({
                    type: AdminStateConstants.UPDATE_SAVED_IMAGES,
                    images: []
                });
            }
        })
        .catch(err => {
            console.log(err);
            message.error("Not able to fetch images!!", 2.5);
            dispatch(ongoingImagesAction(false));
            dispatch({
                type: AdminStateConstants.UPDATE_SAVED_IMAGES,
                images: []
            });
        })
}

export const loadAdminSettings = () => dispatch => {
    let url = `/api/adminsetting/` + cookie.load('admin_setting').id + `/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                response.data.apps_initialised = true;
                dispatch({
                    type: AdminStateConstants.INIT_ADMIN_STATE,
                    urlState: response.data
                })
            }
            else {
                console.log(response);
            }
        })
        .catch(err => {
            console.log(err);
        })
}

export const startCameraAction = (deviceId, adminState) => dispatch => {
    dispatch(ongoingCameraAppAction(adminState, true));
    let partsOfUrl = "api~camera~start-camera";
    let url = `/server/scano/` + deviceId + `/` + partsOfUrl;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
            }
            else {
                console.log(response);
                message.error("Not able to start camera!!", 2.5);
            }
            dispatch(ongoingCameraAppAction(adminState, false));
        })
        .catch(err => {
            console.log(err);
            message.error("Not able to start camera!!", 2.5);
            dispatch(ongoingCameraAppAction(adminState, false));
        })
}

export const stopCameraAction = (deviceId, adminState) => dispatch => {
    dispatch(ongoingCameraAppAction(adminState, true));
    let partsOfUrl = "api~camera~stop-camera";
    let url = `/server/scano/` + deviceId + `/` + partsOfUrl;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
            }
            else {
                console.log(response);
                message.error("Not able to stop camera!!", 2.5);
            }
            dispatch(ongoingCameraAppAction(adminState, false));
        })
        .catch(err => {
            console.log(err);
            message.error("Not able to stop camera!!", 2.5);
            dispatch(ongoingCameraAppAction(adminState, false));
        })
}
