import React, {Component} from "react";
import {downloadAnnotations} from "../utils/annotations_app_utils";
import {DownloadOutlined} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import {Button, MenuItem, Popover} from "@mui/material";

class AnnotationsDownload extends Component {

	constructor(props) {
		super(props);

		this.state = {
			anchorEl: null,
		}
	}
	
	render() {
		return <>
			<Button sx={{padding: "inherit"}} onClick={e => this.setState({anchorEl: e.currentTarget})}>
				<DownloadOutlined sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}} />
			</Button>
			<Popover open={this.state.anchorEl} anchorEl={this.state.anchorEl}
			         onClose={() => this.setState({anchorEl: null,})}
			         anchorOrigin={{vertical: 'bottom', horizontal: 'left',}}>
				<MenuItem onClick={() =>
					downloadAnnotations(this.props.slideId, this.props.annoDrawer, 'json')}>
					.json
				</MenuItem>
				<MenuItem onClick={() =>
					downloadAnnotations(this.props.slideId, this.props.annoDrawer, 'csv')}>
					.csv
				</MenuItem>
			</Popover>
		</>
	}
}

export default AnnotationsDownload;
