import {PreviewTuningConstant} from "../actionTypes/preview.tuning.constant";

const InitialState = {
    selectedPoint: 1,
    slotsCroppingMode: false,
    pixelValues: {
        1: [0, 0],
        2: [0, 0],
        3: [0, 0],
        4: [0, 0]
    },
    positionValues: {
        1: [0, 0],
        2: [0, 0],
        3: [0, 0],
        4: [0, 0]
    },
    selectedSlot: 0,
    slotCropPositionValues: {
        0: {},
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
    },
}

export const previewTuningReducer = ( state = InitialState , action) => {
    let updatedState = {};
    switch(action.type){
        case PreviewTuningConstant.UPDATE_SELECTED_POINT:
            return {
                ...state, 
                selectedPoint: action.point,
            }
        case PreviewTuningConstant.UPDATE_PIXEL_VALUES:
            updatedState = Object.assign({}, state);
            updatedState.pixelValues[updatedState.selectedPoint] = action.pixelValue;
            return updatedState;
        case PreviewTuningConstant.UPDATE_POSITION_VALUES:
            updatedState = Object.assign({}, state);
            updatedState.positionValues[updatedState.selectedPoint] = action.positionValue;
            return updatedState;
        case PreviewTuningConstant.UPDATE_SLOTS_CROPPING_MODE:
            return {
                ...state, 
                slotsCroppingMode: action.slotsCroppingMode,
            }
        case PreviewTuningConstant.UPDATE_SELECTED_SLOT:
            return {
                ...state, 
                selectedSlot: action.slot,
            }
        case PreviewTuningConstant.UPDATE_SLOT_CROP_POSITION_VALUES:
            updatedState = Object.assign({}, state);
            updatedState.slotCropPositionValues[updatedState.selectedSlot] = action.slotCropPositionValue;
            return updatedState;
        default :
            return state
    }
}