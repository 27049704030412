import '@ant-design/compatible/assets/index.css';
import { CloudUploadOutlined, DeleteFilled, InfoCircleOutlined, StarFilled, ToTopOutlined, UploadOutlined } from '@ant-design/icons';
import axios from "axios";
import _ from 'lodash';
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
// const { Table, Input, InputNumber, Popconfirm, Form } = antd;
import { Table, Icon, Button, Row, Modal, message, Col, Pagination, PageHeader, Divider, Upload, Input, Empty, Descriptions, Select, Tag, InputNumber, Popconfirm, Typography , Form, Tooltip} from 'antd';
import { AuthHeader } from "../helper/auth.token";
import '../asset/style/custom_antd_table_style.css';

var querystring = require('querystring');
const { TextArea } = Input;

const data = [];

const EditableContext = React.createContext();

class EditableCell extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            user_concordance_enabled: false,
        }
    }

    

  getInput = (app) => {
    // console.log(this.props.input_type)
    console.log("input_record",this.props.record)
    let concordance_enabled = this.props.record.concordance_allow
    if (this.props.inputType === 'scan_quality') {
      return <Select style={{width:'120px'}} disabled={concordance_enabled && cookie.loadAll().is_staff=="false"}>
                <Select.Option value="High">High</Select.Option>
                <Select.Option value="Adequate">Adequate</Select.Option>
                <Select.Option value="Borderline Adequate">Borderline Adequate</Select.Option>
                <Select.Option value="Inadequate">Inadequate</Select.Option>
            </Select>
    }
    else if(this.props.inputType === 'diagnostic_certainity'){
        return <Select style={{width:'120px'}} disabled={concordance_enabled && cookie.loadAll().is_staff=="false"}>
                    <Select.Option value="Highly Confident">Highly Confident</Select.Option>
                    <Select.Option value="Mostly Confident">Mostly
                    confident</Select.Option>
                    <Select.Option value="Not Confident">Not confident</Select.Option>
                </Select>
    }
    else if(this.props.inputType === 'concordance'){
        return <Select style={this.props.app?{width:'150px'}:{width:'200px'}} 
        disabled={this.props.record.input_type == "PRE-GLASS" || !this.props.record.concordance_allow}>
                    <Select.Option value="Complete Agreement">Complete Agreement</Select.Option>
                    <Select.Option value="Minor Disagree No Impact">Minor Disagree - No Clinical Impact</Select.Option>
                    <Select.Option value="Minor Disagree Minimal Impact">Minor Disagree - Minimal Clinical Impact</Select.Option>
                    <Select.Option value="Major Disagreement">Major Disagreement</Select.Option>
                </Select>
    }
    else if(this.props.inputType === 'diagnosis'){
        console.log("boolean", this.props.app)
        return <TextArea style={this.props.app?{width:'150px'}:{width:'250px'}} disabled={concordance_enabled && cookie.loadAll().is_staff=="false"}/>
    }
    else{
        return <Input style={this.props.app?{width:'50px'}:{width:'120px'}} disabled/>;
    }
  };

  renderCell = ({ getFieldDecorator }) => {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      index,
      children,
      app,
      ...restProps
    } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ],
              initialValue: record[dataIndex],
            })(this.getInput())}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
            table_data: [],
            editingKey: '',
            currCase: this.props.currCase,
            // caseValidationData: this.props.caseValidationData,
            key:1,
            cooldownPeriod: 0,
            slidesPostGlassReason : this.props.slidesPostGlassReason,
            concordance_allow: false,
   };

    this.columns = [
        {
            title:'SCAN ID',
            width: 0,
            style:{display : 'none'},
            dataIndex: 'slide_id',
            editable: true,
        },
      {
        title: 'SCAN Name',
        dataIndex: 'slide_name',
        editable: true,
        render: (text, record)=> {
          console.log("record--")
          if(record.slide_id == this.props.currSlideId){
            return <span style={{color:'green'}}>{record.slide_name}</span>
          }else{
            return <span>{record.slide_name}</span>
          }
        }
      },
      {
        title: 'DIAGNOSIS TYPE',
        dataIndex: 'input_type',
        editable: true,
      },
      {
        title: 'DATE',
        dataIndex: 'date',
        width: "120px",
        editable: false,
      },
      {
        title: 'DIAGNOSIS',
        dataIndex: 'diagnosis',
        width: this.props.app?"170px":"320px",
        editable: true,
      },
      {
        title: 'QUALITY OF SCAN',
        dataIndex: 'quality_of_scan',
        editable: true,
      },
      {
        title: 'DIAGNOSTIC CERTAINTY',
        dataIndex: 'diagnostic_certainty',
        width: "120px",
        editable: true,
      },
      
    //   {
    //     title: 'DIAGNOSTIC CERTAINTY',
    //     dataIndex: 'diagnostic_certainty',
    //     editable: true,
    //   },
      {
        title: 'CONCORDANCE DESIGNATION',
        dataIndex: 'concordance_designation',
        editable: true,
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text, record) => {
            console.log("record",record)
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          let scan_id = record.slide_id
          let slidesPostGlassEligibility = this.props.slidesPostGlassEligibility
          let allow = slidesPostGlassEligibility[scan_id]
          console.log("splice",scan_id, allow)
          let disable_reason = ""
          if(record.date != ''){
            disable_reason="Once filled cant be filled again"
          }else if(!allow){
            disable_reason="Cool down period not over yet"
          }

          let edit_button;
          if(this.state.concordance_allow){
            edit_button = <a disabled={editingKey !== ''} onClick={() => this.edit(record,record.key,allow)}>
                              Edit
                          </a>    
          }
          else{
            edit_button = <a disabled={editingKey !== '' || (record.date != '' && cookie.loadAll().is_staff !== "true")} onClick={() => this.edit(record,record.key,allow)}>
                              Edit
                          </a>
          }
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {form => (
                  <Popconfirm title="Are you sure? Data once saved can't be modified." onConfirm={() => this.save(form, record.key)}>
                    <a
                      // onClick={() => }
                      style={{ marginRight: 8 }}
                    >
                      Save
                    </a>
                  </Popconfirm>
                )}
              </EditableContext.Consumer>
              <br></br>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
                  // <a disabled={editingKey !== '' || (record.date != '' && cookie.loadAll().is_staff !== "true")} onClick={() => this.edit(record,record.key,allow)}>
                  //   Edit
                  // </a>
                  <a disabled={this.state.concordance_allow?editingKey!='':editingKey !== '' || (record.date != '' && cookie.loadAll().is_staff !== "true") } onClick={() => this.edit(record,record.key,allow)}>
                    Edit
                  </a>
                  // {edit_button}
          );
        },
      },
    ];
  }


getConcordanceEditingStatus = () => {
  let user_id = cookie.loadAll().user_id;
  let url = `/api/get_concordance_enabled_status/?user_id=${user_id}`;
  axios.get(url).then(
    res => {
        console.log("res", res.data.enabled)
        this.setState({
          concordance_allow: res.data.enabled,
        })
    }
  )
}

  componentDidMount = () => {
    // this.setState({
    //   table_data: this.props.caseValidationData
    // })

    //get cooldown period from backend
    let url = '/api/cooldown_period/'
    axios.get(url).then(response => {
      this.setState({
        cooldownPeriod: response.data['days']
      })
    })
    this.getConcordanceEditingStatus();

  }

  componentDidUpdate = (prevProps) => {
    // console.log("xxx")
    if(prevProps.caseValidationData != this.props.caseValidationData){
      // this.setState({
      //   table_data: this.props.caseValidationData
      // })
      this.setState(Object.assign({}, this.state, {
        table_data: this.props.caseValidationData
      }));
    }
    if(prevProps.slidesPostGlassReason != this.props.slidesPostGlassReason){
      // this.setState({
      //   table_data: this.props.caseValidationData
      // })
      this.setState(Object.assign({}, this.state, {
        slidesPostGlassReason: this.props.slidesPostGlassReason
      }));
    }
  }

  isEditing = record => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  save(form, key) {
    form.validateFields((error, row) => {
    console.log("row", row)
    if(row["input_type"]=="PRE-GLASS" && (row['diagnosis']==""||row['quality_of_scan']==""|| row['diagnostic_certainty']=="")){
        message.error("Please fill all the required fields");
        return;
    }
    if(row["input_type"]=="POST-GLASS" && (row['diagnosis']==""||row['quality_of_scan']==""|| row['diagnostic_certainty']=="")){
        message.error("Please fill all the required fields");
        return;
    }
      let data = {
        user: parseInt(this.props.user_id),
        case_id : this.props.currCase.case_id,
        slide_id : row['slide_id'],
        slide_name : row['slide_name'],
        diagnosis : row['diagnosis'],
        quality_of_scan : row['quality_of_scan'],
        diagnostic_certainity : row['diagnostic_certainty'],
        concordance_designation : row['concordance_designation'],
        input_type : row['input_type'],
        notes: '',
      }

      let url = `/api/update_validation_data/`;
      axios.post(url, querystring.stringify(data), { headers: { Authorization: AuthHeader() } })
      .then(response => {
          if (response.status === 200) {
              if(response.data['error']=="true"){
                message.error(response.data['data'], 3);
                this.setState({
                  editingKey: '',
                })
                return;
              }
              message.success("Successfully saved data", 3);
              let slidesPostGlassReason = this.state.slidesPostGlassReason
              if (row['input_type']=="PRE-GLASS"){
                slidesPostGlassReason[row["slide_id"]]="Cool Down Period Not Over Yet"
              }
              let old_table_data = this.state.table_data
              let new_table_data = []
              let done = false;
              for(let i=0;i<old_table_data.length;i++){
                if(!(old_table_data[i]['slide_id'] == response.data['data']['slide_id'] && old_table_data[i]['input_type']==response.data['data']['input_type'])){
                  new_table_data.push(old_table_data[i])
                }
                else{
                  done = true;
                  new_table_data.push(response.data['data'])
                }
              }
              if(!done){
                new_table_data.push(response.data['data'])
              }
                this.setState({
                table_data: new_table_data,
                editingKey: '',
                slidesPostGlassReason: slidesPostGlassReason,
              })
          }
        //   window.location.reload();
      })
      .catch(err => {
          message.error("Failed to save data", 3);
      });
    });
  }

  edit(record, key, allow) {
    if(!allow && allow!=undefined && record.input_type == 'POST-GLASS'){
      console.log("xxx",this.props.slidesPostGlassReason[record.slide_id])
      if(this.props.slidesPostGlassReason[record.slide_id]==undefined){
        message.error("Please add PREGLASS data first",2)
        return;
      }
      message.error(this.props.slidesPostGlassReason[record.slide_id],2)
      return;
    }
    this.setState({ editingKey: key });
  }

  render() {
    console.log("abcd", this.props.app)
    let data  = JSON.parse(JSON.stringify(this.state.table_data));
    // let data = []; 
    let slides = this.props.caseScansArray
    let new_data = [];
    let count = 90;
    for(let i=0; i<slides.length; i++){
        let slide_id = slides[i].id
        let pre_glass_found = false;
        let post_glass_found = false;
        for(let j=0; j<data.length; j++){
            if(data[j].slide_id == slide_id){
                if(data[j].input_type == "PRE-GLASS"){
                    // data[j].key = count
                    // new_data.push(data[j])
                    // count+=1;
                    pre_glass_found=true
                    }
                else if(data[j].input_type == "POST-GLASS"){
                    // data[j].key = count
                    data[j]['concordance_allow'] = true;
                    new_data.push(data[j])
                    // count+=1;
                    post_glass_found=true
                    }
                }
            }
            if(!pre_glass_found){
                // new_data.push({
                //     date: "",
                //     diagnosis: "",
                //     diagnostic_certainty: "",
                //     input_type: "PRE-GLASS",
                //     key: count,
                //     notes:"",
                //     quality_of_scan:"",
                //     slide_id: slide_id,
                //     slide_name: slides[i].name,
                //     concordance_designation: ""
                // })
              count+=1
            }
            if(!post_glass_found){
                new_data.push({
                    date: "",
                    diagnosis: "",
                    diagnostic_certainty: "",
                    input_type: "POST-GLASS",
                    key: count,
                    notes:"",
                    quality_of_scan:"",
                    slide_id: slide_id,
                    slide_name: slides[i].name,
                    concordance_designation: "",
                    concordance_allow: false,
                })
                count+=1
            }
    }

    for(let i=0; i<new_data.length; i++){
      let curr_data = new_data[i];
      if(curr_data['input_type']=="PRE-GLASS" && curr_data['date']!=''){
        let found_postglass = false;
        for(let j=0; j<new_data.length; j++){
          if(new_data[j]['input_type']=="POST-GLASS" && curr_data['slide_id']==new_data[j]['slide_id']&&new_data[j]['date']!==''){
            found_postglass=true;
          }
        }
        if(!found_postglass && cookie.loadAll().is_staff === "false"){
          new_data[i]['diagnosis']="***";
          new_data[i]['quality_of_scan']="***";
          new_data[i]['diagnostic_certainty']="***";
          new_data[i]['concordance_designation']="***";
        }
      }
    }

    console.log("table_new_data", new_data)

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          inputType: col.dataIndex === 'quality_of_scan' ? 'scan_quality' : 
                        col.dataIndex === 'diagnostic_certainty' ? 'diagnostic_certainity':
                        col.dataIndex === 'concordance_designation'?'concordance':
                        col.dataIndex === 'diagnosis'?'diagnosis':'',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          app: this.props.app,
        }),
      };
    });

    return (
      <EditableContext.Provider value={this.props.form} key = {this.state.key}>
        <Table
            className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
            components={components}
            bordered
            //   dataSource={this.props.caseValidationData}
            dataSource={cookie.loadAll().is_staff === "false"?new_data:new_data}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
                onChange: this.cancel,
            }}
            // size="small"
        />
      </EditableContext.Provider>
    );
  }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;