import React, {Component} from  "react";
import {connect} from 'react-redux';
import axios from "axios";
import { AuthHeader } from "../../../helper/auth.token";
import { InfoCircleOutlined, PlusOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    Switch,
    Divider,
    Slider,
    Modal,
    Select,
    Input,
    Typography,
    message,
} from 'antd';
import { resetToDefault, imageContrastChanged, imageBrightessChanged, imageSaturationChanged, imageGrayscaleChanged, imageInvertChanged, imageHueChanged, setAll, showSeenAreaChanged, showSeenAreaStatus } from "../../../action/viewerSetting.action";
import * as enhancementKeys from './settings/enhancement_keys'
import { updateSlide } from '../../../action/slide.update.action';
import "../../../asset/style/neoviewer/settings.css"
import { convertSettingsToFilter } from "../../../utils/utils";

const { Option } = Select;

class SettingsApp extends Component {

    constructor(props) {
        super(props);
        let settings = {};

        for (var key in enhancementKeys) {
            let enhancementKey = enhancementKeys[key];
            settings[enhancementKey.id] = this.props.slideData.viewer_settings[enhancementKey.id];
        }

        settings.name = this.props.slideData.viewer_settings.name;
        
        this.state = {
            isMenuOpen: false,
            changed: false,
            settings_list: []
        }

        this.contrastSlider = null;
        this.brightnessSlider = null;
        this.hueSlider = null;
        this.saturationSlider = null;
        this.invertSlider = null;
        this.grayscaleSlider = null;

        this.props.dispatch(setAll(settings));
    }
    
    getAllSettings = () => {
        let url = `/api/viewersetting/`;
        axios.get(url, { headers: { Authorization: AuthHeader() } }).then(response => {
            this.setState({
                settings_list: response.data,
            });
        })
        .catch(err => {
                console.log(err);
        });
      }
    
    componentDidMount = () => {
        this.mapDiv = document.getElementById("map-" + this.props.mapId);
        // this.previewDiv = document.getElementById("preview-map-to-target");
        this.getAllSettings();
    }

    componentDidUpdate = (prevProps) => {
        let settingsValueChange = ((prevProps.viewerSettingData.contrast != this.props.viewerSettingData.contrast) ||
                                    (prevProps.viewerSettingData.brightness != this.props.viewerSettingData.brightness) ||
                                    (prevProps.viewerSettingData.hue != this.props.viewerSettingData.hue) ||
                                    (prevProps.viewerSettingData.saturation != this.props.viewerSettingData.saturation) ||
                                    (prevProps.viewerSettingData.invert != this.props.viewerSettingData.invert) ||
                                    (prevProps.viewerSettingData.grayscale != this.props.viewerSettingData.grayscale));

        
        let timestampChange = (prevProps.urlState.settings_timestamp != this.props.urlState.settings_timestamp);
        
        if(((this.props.urlState.app_state || {})['present_app'] || {}).code != undefined && settingsValueChange) {
            let url = `/api/set_sync_browsing_settings/?sync_code=${this.props.urlState.app_state['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&contrast=${this.props.viewerSettingData.contrast}&brightness=${this.props.viewerSettingData.brightness}&hue=${this.props.viewerSettingData.hue}&saturation=${this.props.viewerSettingData.saturation}&invert=${this.props.viewerSettingData.invert}&grayscale=${this.props.viewerSettingData.grayscale}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
              .then(response => {
              })
              .catch(err => {
                console.log("Failed Setting Key Value");
              });
        }
        if((this.props.urlState || {}).presentCode != undefined && timestampChange) {
            let url = `/api/get_sync_browsing_settings/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.props.urlState.slide_morphle_id}`;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                try {
                    if(response.data.contrast != undefined && response.data.contrast != undefined && response.data.contrast != undefined &&
                            response.data.contrast != undefined && response.data.contrast != undefined && response.data.contrast != undefined) {
                                this.props.dispatch(imageContrastChanged(parseInt(response.data.contrast)));
                                this.props.dispatch(imageBrightessChanged(parseInt(response.data.brightness)));
                                this.props.dispatch(imageSaturationChanged(parseInt(response.data.saturation)));
                                this.props.dispatch(imageGrayscaleChanged(parseInt(response.data.grayscale)));
                                this.props.dispatch(imageInvertChanged(parseInt(response.data.invert)));
                                this.props.dispatch(imageHueChanged(parseInt(response.data.hue)));
                    }
                } catch (err) {
                    console.log(err);
                }
            })
            .catch(err => {
                console.log("Failed Getting Value");
            });
        }
    }

    slider = (value) => {
        if(this.props.viewerSettingData.seen_area_status){
            message.info("Please TURN OFF show seen area in preview app to enable rotation",1.5)
            return;
        }
        this.props.slidemap.getView().setRotation((value * Math.PI) / 180);
        this.setState({
            slideAngle: ((value * Math.PI) / 180),
        });
    }

    resetToDefaults = () => {
        this.props.dispatch(resetToDefault());
    }

    onContrastChange = (value) => {
        this.props.dispatch(imageContrastChanged(value));
        this.setState({
            changed: true
        });
    }

    onBrightnessChange = (value) => {
        this.props.dispatch(imageBrightessChanged(value));
        this.setState({
            changed: true
        });
    }

    onSaturationChange = (value) => {
        this.props.dispatch(imageSaturationChanged(value));
        this.setState({
            changed: true
        });
    }

    onGrayscaleChange = (value) => {
        this.props.dispatch(imageGrayscaleChanged(value));
        this.setState({
            changed: true
        });
    }

    onInvertChange = (value) => {
        this.props.dispatch(imageInvertChanged(value));
        this.setState({
            changed: true
        });
    }

    onHueChange = (value) => {
        this.props.dispatch(imageHueChanged(value));
        this.setState({
            changed: true
        });
    }

    updateSettings = () => {
        let val = {};

        for (var key in enhancementKeys) {
            let enhancementKey = enhancementKeys[key];
            val[enhancementKey.id] = this.props.viewerSettingData[enhancementKey.id];
        }

        val.name =  this.props.viewerSettingData.name;

        let selected_setting_option = this.state.settings_list.find((setting) => {
            return setting.name === val.name;
        });

        let url = `/api/viewersetting/${selected_setting_option.id}/`;
        axios.put(url, val, {headers: {Authorization: AuthHeader() }})
        .then(response => {
            let oldSettingsList = this.state.settings_list;
            for (var i in oldSettingsList) {
                if (oldSettingsList[i].id == response.data.id) {
                    oldSettingsList[i] = response.data;
                    break;
                }
            }
            this.setState({
                changed: false,
                settings_list: oldSettingsList
            });
            message.success("Updated Settings Successfully");
        })
        .catch(err => {
            this.setState({
            changed: true,
            });
            message.error("Failed to Save Settings. Contact Admin");
            console.log(err);
        });
    };

    handleSettingChange = (value, opt) => {

        let selected_setting = this.state.settings_list.find((setting) => {
            return setting.id === opt.props.label.id;
        });

        let settings = {};

        for (var key in enhancementKeys) {
            let enhancementKey = enhancementKeys[key];
            settings[enhancementKey.id] = selected_setting[enhancementKey.id];
        }

        settings.name = selected_setting.name;

        this.props.dispatch(setAll(settings));
        let val = {
            "morphle_id" : this.props.slideData.morphle_id,
            "path": this.props.slideData.path,
            "specimen_type" : this.props.slideData.specimen_type,
            "date": this.props.slideData.date,
            'name': this.props.slideData.name,
            "settings": parseInt(value)
        };
        this.props.dispatch(updateSlide(this.props.slideData.id ,val));
    }

    addSettings = (settings_name) => {
        let val = {};

        for (var key in enhancementKeys) {
            let enhancementKey = enhancementKeys[key];
            val[enhancementKey.id] = this.props.viewerSettingData[enhancementKey.id];
        }

        val.name =  settings_name;
        
        let url = `/api/viewersetting/`;
        axios.post(url, val, {headers: {Authorization: AuthHeader() }})
        .then(response => {
            this.setState(prevState => ({
                changed: false,
                name: settings_name,
                settings_list: [...prevState.settings_list, response.data]
            }));
            message.success("Saved New Settings Successfully");
            this.props.dispatch(setAll(val));
        })
        .catch(err => {
            this.setState(prevState => ({
            changed: true,
            }));
            message.error("Failed to Save Settings. Contact Admin");
            console.log(err);
        });
    }

    saveNewSettings = () => {
        Modal.confirm({
            title: 'Please enter New Settings Name',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (
                <Input placeholder="Enter Settings Name here" id="settings-name" />
            ),
            onOk:  () => {
            let settings_name = document.getElementById("settings-name").value;
            this.addSettings(settings_name);
            },
            onCancel: () => {}
        });
    }
    
    render(){

        let cssStyle = convertSettingsToFilter(this.props.viewerSettingData);

        if (this.mapDiv) {
            this.mapDiv.style.filter = cssStyle;
            // this.previewDiv.style.filter = cssStyle;
        }

        let rotationMenu = <Row className="settings-div">
                                <Col>
                                    <Row>
                                        Rotation
                                    </Row>
                                    <Row>
                                        <Col span={20}>
                                            <Slider 
                                                disabled={(this.props.urlState || {}).presentCode != undefined}
                                                value={this.props.slidemap.getView().getRotation() * 180 / Math.PI} 
                                                onChange={this.slider}
                                                min={0} 
                                                max={360}/>
                                        </Col>
                                        <Col span={2}>
                                            <Button 
                                                className="settings-button"
                                                disabled={(this.props.urlState || {}).presentCode != undefined} 
                                                style={{ padding: '0' }} 
                                                onClick={()=> this.slider(0)}>
                                                <UndoOutlined /><br></br>
                                                <span className="settings-button-text">Reset</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
  

        let image_controls_menu = [
            <Row key={0} className="settings-div">
                    Contrast <Slider
                    min={1}
                    max={100}
                    onChange={this.onContrastChange}
                    onAfterChange={() => this.contrastSlider.blur()}
                    value={this.props.viewerSettingData.contrast}
                    ref={(slider) => this.contrastSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
                    Brightness <Slider
                    min={1}
                    max={100}
                    onChange={this.onBrightnessChange}
                    onAfterChange={() => this.brightnessSlider.blur()}
                    value={this.props.viewerSettingData.brightness}
                    ref={(slider) => this.brightnessSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
                    Hue <Slider
                    min={1}
                    max={100}
                    onChange={this.onHueChange}
                    onAfterChange={() => this.hueSlider.blur()}
                    value={this.props.viewerSettingData.hue}
                    ref={(slider) => this.hueSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
                    Saturation <Slider
                    min={1}
                    max={100}
                    onChange={this.onSaturationChange}
                    onAfterChange={() => this.saturationSlider.blur()}
                    value={this.props.viewerSettingData.saturation}
                    ref={(slider) => this.saturationSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
                    Invert <Slider
                    min={1}
                    max={100}
                    onChange={this.onInvertChange}
                    onAfterChange={() => this.invertSlider.blur()}
                    value={this.props.viewerSettingData.invert}
                    ref={(slider) => this.invertSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
                    Grayscale <Slider
                    min={1}
                    max={100}
                    onChange={this.onGrayscaleChange}
                    onAfterChange={() => this.grayscaleSlider.blur()}
                    value={this.props.viewerSettingData.grayscale}
                    ref={(slider) => this.grayscaleSlider = slider}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
                />
            </Row>, 
            <Row key={1}>
                <Col span={8} className="settings-button-col">
                    <Button 
                        onClick={()=> this.resetToDefaults()} 
                        className="settings-button"
                        disabled={(this.props.urlState || {}).presentCode != undefined}>
                        <UndoOutlined /><br></br>
                        <span className="settings-button-text">Reset</span>
                    </Button>
                </Col>
                <Col span={8} className="settings-button-col">
                    <Button 
                        disabled={!this.state.changed} 
                        onClick={()=> this.updateSettings()} 
                        className="settings-button"
                        disabled={(this.props.urlState || {}).presentCode != undefined}>
                        <SaveOutlined /><br></br>
                        <span className="settings-button-text">Update</span>
                    </Button>
                </Col>
                <Col span={8} className="settings-button-col">
                    <Button 
                        disabled={!this.state.changed} 
                        onClick={()=> this.saveNewSettings()} 
                        className="settings-button"
                        disabled={(this.props.urlState || {}).presentCode != undefined}>
                        <PlusOutlined /><br></br>
                        <span className="settings-button-text">Add New</span>
                    </Button>
                </Col>
            </Row>
        ];

        return (
            <div className="settings-app-parent app-parent overlayed-component">
                {rotationMenu}
                <Divider />
                <Row className="settings-div settings-updater">
                    Setting Name  
                    <Select 
                        className="settings-options"
                        value={this.props.viewerSettingData.name} 
                        onChange={this.handleSettingChange}
                        disabled={(this.props.urlState || {}).presentCode != undefined}>
                            {this.state.settings_list.map((value) => {
                                return (
                                <Option key={value.id} label={value}>
                                    {value.name}
                                </Option>
                                );
                            })
                        }
                    </Select>
                </Row>
                {image_controls_menu}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(SettingsApp);