export const dictSlideOrder = {
    OUTER_COL_DETAILS_SM : { span: 16, offset: 0 },
    OUTER_COL_DETAILS_MG : { span: 16, offset: 0 },
    OUTER_COL_DETAILS_LG : { span: 16, offset: 0 },
    INNER_COL_DETAILS_HEADING_SM : { span: 11, offset: 1 },
    INNER_COL_DETAILS_HEADING_MG : { span: 11, offset: 1 },
    INNER_COL_DETAILS_HEADING_LG : { span: 11, offset: 1 },
    INNER_COL_DETAILS_VALUE_SM : { span: 12, offset: 0 },
    INNER_COL_DETAILS_VALUE_MG : { span: 12, offset: 0 },
    INNER_COL_DETAILS_VALUE_LG : { span: 12, offset: 0 },
    FONTWEIGHT_HEADING : { fontWeight: 700 },
    FONTWEIGHT_DETAILS : { fontWeight: 300 },
}
