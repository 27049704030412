import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";

export const typeAheadSlideSearch = (prefix, callback) => {

	if (prefix.length < 1) return callback([]);

	axios
		.get("/api/typeahead_slide/?prefix=" + prefix, {
			headers: {
				Authorization: AuthHeader()
			}
		})
		.then(response => {
			callback(response.data.map(slide => ({
				...slide,
				name: slide.display_text.replace(/^_/, "").toUpperCase()
			})));
		})
		.catch(err => {
			console.log(err);
		});
}
