import React, {Component} from  "react";
import {connect} from 'react-redux';
import {Divider, Stack} from '@mui/material';
import HeadBarSettings from "./headbar_apps/headbar_settings";
import morphleLogo  from "../../asset/img/morphle-updated-logo.png"
import '../../asset/style/gammaviewer/header_gammaViewer.css'
import SplitSync from "./headbar_apps/SplitSync";
import GridOverlay from "./headbar_apps/GridOverlay";
import Screenshot from "./headbar_apps/Screenshot";
import Profile from "./headbar_apps/Profile";
import ShareLink from "./headbar_apps/ShareLink";
import SlideName from "./headbar_apps/SlideName";
import {openEndPoint} from "../../utils/utils";
import AnnotationDrawer from "./headbar_apps/anno_drawer";
class HeadBar extends Component {

    render() {
        if(Object.keys(this.props.mapsState).length === 0) {
            return <Stack direction={"row"} sx={{width: "100vw", height: '5vh'}}/>
        }
        for (let mapId in this.props.mapsState) {
            if (!this.props.mapsState[mapId].slideState)
                return <Stack direction={"row"} sx={{width: "100vw", height: '5vh'}}/>;
        }

        return (
            <Stack direction={"row"} alignItems="center"
                justifyContent="space-between" sx={{width:"100vw", height:'5vh'}}>
                <Stack spacing={2} justifyContent="flex-start"
                    direction={"row"}
                    divider={<Divider orientation="vertical" flexItem />}>
                        <img src={morphleLogo} className={"morphle-header-logo"} style={{cursor:'pointer'}}
                             onClick={(event) =>
                                 openEndPoint("/dashboard", event.ctrlKey)}
                             alt={"MorphleLogo"}
                             onAuxClick={() => openEndPoint("/dashboard", true)}
                        />
                        <HeadBarSettings />
                        <AnnotationDrawer />
                        <SplitSync />
                        <GridOverlay />
                        <Screenshot />
                        <></>
                </Stack>
                <Stack spacing={1} alignItems="center"
                    direction={"row"} justifyContent="flex-end"
                    divider={<Divider orientation="vertical" flexItem />}>
                    <div/>
                    {Object.keys(this.props.mapsState).length === 1 ? <SlideName mapId={0}/> : null}
                    <ShareLink />
                    <Profile />
                </Stack>
            </Stack>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        mapsState: state.mapsStateReducer,
    };
};


export default connect(mapStateToProps)(HeadBar);
