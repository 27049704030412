import React, {Component} from "react";
import {Stack} from "@mui/material";
import {getAllRightBottomApps} from "./app_maker";
import {updateAppInstalledStatus} from "../../action/gamma.state.action";
import {connect} from "react-redux";

class RightBottom extends Component {

	render() {
		return <Stack direction={"row-reverse"} spacing={0} alignItems={"flex-end"}>
			{getAllRightBottomApps(this.props.gammaState).map(app => {
				if (app.permission === undefined)
					this.props.dispatch(updateAppInstalledStatus(app.id, app.defaultPermission));
				else if (app.permission)
					return app.component;
				return null;
			})}
		</Stack>
	}
}

const mapStateToProps = (state) => {
	return {
		gammaState: state.gammaStateReducer,
	}
}

export default connect(mapStateToProps)(RightBottom);
