import { RegionSelectionConstants } from "../../actionTypes/region_selection.constant"

export const scanSpeed = {
    FAST: "FAST",
    MEDIUM: "MEDIUM",
    SLOW: "SLOW",
    ZSTACK: "ZSTACK"
}

export const specimenType = {
    HNE_TISSUE: "HNE_TISSUE", 
    BLOOD: "BLOOD", 
    LBC: "PAP", 
    IHC: "IHC",
    BM: "BM"
}

export const objectiveType = {
    FORTY_X: "FORTY_X", 
    HUNDRED_X: "HUNDRED_X", 
    TWENTY_X: "TWENTY_X", 
}

export const scanOutputFormat = {
    JPG: "JPG", 
    PNG: "PNG"
}

export class Slot {
    constructor(defaultConfig) {
        this.status = RegionSelectionConstants.NOT_STARTED;
        this.speed = defaultConfig.scanSpeed;
        this.doTilingOnly = defaultConfig.doTilingOnly;
        this.doTilingAfterScan = defaultConfig.doTilingAfterScan;
        this.specimenType = defaultConfig.specimenType;
        this.objectiveType = defaultConfig.objectiveType;
        this.scanOutputFormat = defaultConfig.scanOutputFormat;
        this.slideName = "";
        this.actualRegion = [-1, -1, -1, -1];
        this.zStackLevels = 10;
        this.stepSize = 1;
        this.actionOngoing = false;
        this.message = "";
    }
}