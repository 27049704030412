import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, message, Row, Select, Table , Divider} from 'antd';
import axios from "axios";
import moment from 'moment';
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { loadCaseIDs, loadCaseStatus, loadUsers } from '../action/cases.action';
import '../asset/style/add_case.css';
import { getLabelComponent, getNameComponentCaseSlide, getPreviewComponent, getStarredComponent, getTagsComponent } from "../component/case/add_case_utils";
import { AuthHeader } from "../helper/auth.token";
import { globalUrlPrefix } from '../utils/const';
var querystring = require('querystring');

const { TextArea } = Input;
const { Option } = Select;

const ButtonGroup = Button.Group;


class AddValidationView extends Component {
    constructor(props) {
        super(props);

        

        this.state = {
          validationIdList:[],
          newValidation:'',
          validation_check_flag: false,
          description: '',
          validationIds: [],
        }


        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidMount = () => {
        let url = `/api/get_all_validation_id/`;
        axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
            .then(response => {
                let data = response.data['data']
                let ids = []
                for(let i=0; i<data.length; i++){
                  ids.push(data[i].id)
                }
                // let ids = response.data.ids
               // let op = ids.map((id,index) =><Option value={id} key={index}>{id}</Option>)
                this.setState({validationIds:ids})
            })
        
    }

    componentDidUpdate = (prevProps) => {
        // if(prevProps.allCaseStatus != this.props.allCaseStatus) {
        //     Object.keys(this.props.allCaseStatus).map((key) => {
        //         if(this.props.allCaseStatus[key].status == 'New') {
        //             this.setState({
        //                 case_status: this.props.allCaseStatus[key].id,
        //             });
        //         }
        //     });
        // }
    }

    createValidation = (e) => {
        let url = `/api/create_validation/`;
        let validationId = this.state.newValidation
        let validationIds = this.state.validationIds
        for(let i=0; i<validationIds.length; i++){
            if(validationIds[i]==validationId){
                message.info("Validation Id already exists", 3);
                return;
            }
        }
        let description = this.state.description
        axios.post(url, querystring.stringify({id:validationId, user: parseInt(cookie.load('user_id')), description: description})).then(res=>{
            console.log(res)
            this.setState({newValidation:''})
            if(res)
                window.location.href = "/" + globalUrlPrefix + "/clia_validation"+"/"+validationId
        })
    }

    changeValidationName = (e) => {
        let index = this.state.validationIdList.findIndex((item)=>e.target.value.toLowerCase()==item.toLowerCase());
        if (index==-1){
            this.setState({
                newValidation:e.target.value,
                validation_check_flag:false
            });
        } else {
            this.setState({
                newValidation:e.target.value,
                validation_check_flag:false
            })
        }
    }

    changeDescription = (e) => {
        console.log(this.state.description)
        this.setState({
            description: e.target.value,
        })
    }

    render() {

        console.log("new",this.state.validationIds)
        return (
            <div>
                <Row>
                    <Col span={24} offset={0} className="all-slides">
                        <div className="heading-style main-heading-style">
                            <b>Create Validation Study</b>
                        </div>
                        <div>
                            <Row>
                                <Col offset={2} pull={1}>
                                    <div>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header">
                                                <b>Validation ID</b>
                                            </Col>
                                            <Col span={8}>
                                                {this.state.validation_check_flag ?
                                                    <div className="errormessage">
                                                        Case with this Validation ID already exists.
                                                            </div> : null
                                                }
                                                <Input placeholder="Validation ID" value={this.state.newValidation}
                                                    className={this.state.validation_check_flag ? "input-field-add-case errors-input" : "input-field-add-case"}
                                                    onChange={this.changeValidationName}
                                                />
                                            </Col>
                                        </Row>
                                        <br></br>
                                        <Row className="add-case-row">
                                            <Col span={5} className="add-case-row-header" >
                                                <b>Description</b>
                                            </Col>
                                            <Col span={6}>
                                                <TextArea rows={4} onChange={this.changeDescription} value={this.state.description}>

                                                </TextArea>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                        </div>


                        <div className="submit-row-add-case">
                            <Button type="primary"
                                onClick={this.createValidation}
                                disabled={!(!this.state.validation_check_flag && this.state.newValidation.length > 0)}>
                                Save
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        height: state.contextMenuReducer.popupProps.height,
        allCaseStatus: state.caseStatusReducer.case_status,
        allUsers: state.caseStatusReducer.users,
        allCaseIDs: state.caseStatusReducer.case_ids,
    }
}

export default connect(mapStateToProps)(AddValidationView);
