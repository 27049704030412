import React, { Component } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Row, Col, Popover, Badge, Divider, Button, message, Spin } from 'antd';
import { getSpecimenChooser, getObjectiveChooser, getSpeedPanel } from './slot_component_utils';


export const getScanProfileChooser = (scanProfile, onScanSpeedChange, onScanSpecimenChange, onScanObjectiveChange, hideScanProfileSelector, 
    visibleProfileSelector, handleVisibleChange, disabled) => {
    let scanProfileSelectorContent = <div>
            {getSpeedPanel(onScanSpeedChange, scanProfile)}
            {getSpecimenChooser(onScanSpecimenChange, scanProfile)}
            {/* {getObjectiveChooser(onScanObjectiveChange, scanProfile)} */}
    </div>;

    let scanProfileSelector =  <Popover
                                    content={<a onClick={hideScanProfileSelector}>Done</a>}
                                    title="Set Scanning Profile"
                                    trigger="click"
                                    placement="bottom"
                                    content={scanProfileSelectorContent}
                                    visible={visibleProfileSelector && !disabled}
                                    onVisibleChange={handleVisibleChange}
                                    >
                                    <Button className={"loader-action-button"} disabled={disabled} icon={<SettingOutlined />}></Button>
                                </Popover>;
    return scanProfileSelector;
}