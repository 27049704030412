import React, {Component} from 'react';
import HeaderComp from '../component/header'
import ViewerContentSync from './viewercontent-sync';


export class SyncViewerView extends Component {

    render() {
        return(
            <div>
                <ViewerContentSync />
            </div>
        )
    }
}