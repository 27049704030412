import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Switch, Slider, Statistic, Button } from 'antd';
import cookie from "react-cookies";
import { updateDigitalZoomStatus, updateZoom } from "../../action/morpheus.state.action"


class ZoomControls extends Component {

  constructor(props) {
      super(props);
      this.state = {
        max_zoom: this.props.max_zoom
      }
      this.zoomUnits = this.props.zoomUnits;
  }


  toggleDigitalZoom = () => {
    var checked = !this.props.urlState.digitalZoomStatus;
    this.enableDigitalZoom(checked);
    this.props.dispatch(updateDigitalZoomStatus(
      this.props.urlState, 
      checked
    ));
  }

  enableDigitalZoom = (checked) => {
    let currentMaxZoomlevel = this.props.viewer.getView().getMaxZoom();
    let currentZoomLevel = this.props.viewer.getView().getZoom();
    let finalMaxZoomLevel;
    if (checked) {
      finalMaxZoomLevel = currentMaxZoomlevel + 1;    
    } else {
      finalMaxZoomLevel = currentMaxZoomlevel - 1;
      if (currentZoomLevel === currentMaxZoomlevel) {
        this.props.viewer.getView().setZoom(currentMaxZoomlevel - 1);
      }
    }
    this.props.viewer.getView().setMaxZoom(finalMaxZoomLevel);
  }

  goToZoomLevel= (value) => {
    this.props.dispatch(updateZoom(this.props.urlState, this.props.viewer.getView().getZoom()));
    this.props.viewer.getView().setZoom(value);
  }
  
  render() {

    var isMobile = cookie.loadAll().isMobile === "true";
    var iOS = cookie.loadAll().iOS === "true";

    const marks = {};

    this.zoomUnits.map((value, index) => {
      if (index === (this.zoomUnits.length -1)) {
        if (!this.props.urlState.digitalZoomStatus) return;
        else {
          marks[index] = {
            style: {
              color: '#d95a00',
            },
            label: index === this.props.urlState.z ? <strong>{value}X</strong> : <span>{value}X</span>,
          }
          return;
        }
      }
      marks[index] = {
        style: {
          color: '#707070',
        },
        label: index === this.props.urlState.z ? <strong>{value}X</strong> : <span>{value}X</span>,
      }
    });
    
    var zoomFloor = parseInt(Math.floor(this.props.urlState.z));
    var zoomMantissa = this.props.urlState.z - zoomFloor;
    var zoomCeil = parseInt(Math.ceil(this.props.urlState.z));
    var actualZoomValue = (this.zoomUnits[zoomFloor] + (zoomMantissa * (this.zoomUnits[zoomCeil] - this.zoomUnits[zoomFloor]))).toFixed(2);
    
    if (!Number.isInteger(this.props.urlState.z)) {
      marks[this.props.urlState.z] = {
        style: {
          color: '#707070',
        },
        label: <strong>{actualZoomValue}X</strong>,
      }
    }

    var zoomControls = [
      <Slider key = {0} 
        className="zoom-slider"
        vertical
        min={0}
        max={this.props.urlState.digitalZoomStatus ? this.zoomUnits.length - 1 : this.zoomUnits.length - 2}
        marks={marks}
        step={null}
        tooltipVisible={false}
        value={this.props.urlState.z}
        onChange={(value) => this.goToZoomLevel(value)}
      />
      ]

    return (
      <Row style={{marginTop: "5%", textAlign:"center"}}>
          { isMobile && !iOS ? <Statistic valueStyle={{fontSize:15}} value={ actualZoomValue + 'X'}></Statistic> : zoomControls}
          <Button disabled={this.state.syncBrowsing} onClick={this.toggleDigitalZoom} className={'view-sidebar ' + (this.props.urlState.digitalZoomStatus ? 'sidebar-digizoom' : 'sidebar-textbutton')}>
              {this.props.urlState.digitalZoomStatus ? "Hide" : "Show"}<br></br>Digital Zoom
          </Button>
      </Row>
    );
  }

}


const mapStateToProps = state => {
  return {
    urlState: state.viewerUrlReducer
  };
};

export default connect(mapStateToProps)(ZoomControls);