import React,{Component} from 'react';
import {Row , Col, Affix, Switch, Select} from 'antd';
import PublicMap from '../component/viewer/viewerComp';
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { message } from "antd";
import { connect } from "react-redux";
import cookie from 'react-cookies';
import { View } from "ol";
import { Projection } from 'ol/proj.js'
import { Vector as VectorSource } from 'ol/source.js';

const { Option } = Select;


class ViewerContentSync extends Component {
    constructor(props) {
        super(props);

        var slides = [null, null];
        var centers = [null, null];
        var slideAngles = [0, 0];
        var zooms = [0, 0];
        var sync = false;

        if (window.location.hash !== '') {
          // try to restore center, zoom-level and rotation from the URL
          var viewer_hashes = window.location.hash.replace('#', '').split('&');
          for (var i = 0; i < viewer_hashes.length; i++) {
            if (viewer_hashes[i].length > 0) {
              var hash = viewer_hashes[i].replace('map' + i + '=', '');
              var parts = hash.split('/');
              if (parts.length === 6) {
                zooms[i] = parseFloat(parts[0], 10);
                centers[i] = [
                  parseFloat(parts[1]),
                  parseFloat(parts[2])
                ];
                slideAngles[i] = parseFloat(parts[3]);
                slides[i] = parseInt(parts[4]);
                sync = parts[5] === "true";
              }
            }
          }
        }
        
        this.state = { 
            sync: false,
            isSyncModeOn: sync,
            slide_selected: slides,
            isFetching: false,
            selected_slide_data: [null, null],
            slide_list: [null, null],
            views: [null, null],
            projections: [null, null],
            centers: centers,
            urls: [null, null],
            resolutionsList: [[], []],
            imageSizes: [null, null],
            slideAngles: slideAngles,
            zooms: zooms, 
            updatePermalinks: [null, null],
            sources: [null, null]
        }

        this.dx = 0;
        this.dy = 0;
        this.dz = 0;
        this.dr = 0;
        this.center1= [];
        this.center2 = [];
    }

    is_cloud = (cookie.loadAll().deployment_mode === 'offline')?"false":"true";

    initialiseSlideMap = (slide_data, index, newSelection) => {
        let imgWidth = slide_data.stitched_tile_width * slide_data.stitched_x_max;
        let imgHeight = slide_data.stitched_tile_height * slide_data.stitched_y_max;

        let resolutions = [];
        let z_levels = slide_data.z_levels.split(",");
        (z_levels).forEach((level, index) => {
          resolutions.push(slide_data.uperpixel * Math.pow(2, parseInt(level)));
        });
        resolutions = resolutions.reverse();
        let slideAngle = (0 * Math.PI) / 180;

        let getPointResolution = (resolution, point) => {
          var pointResolution = resolution;
          return pointResolution;
        };

        let center =  [imgWidth/8, 9*imgHeight/10];
        let source = new VectorSource({ wrapX: false });

        let proj = new Projection({
          code: 'MORPHLE',
          units: 'pixels',
          extent: [0, 0, imgWidth, imgHeight],
          metersPerUnit: 0.000001,
          global: true,
          getPointResolution: getPointResolution,
        });

        let view = new View({
          projection: proj,
          extent: proj.getExtent(),
          center: (this.state.centers[index] != null && !newSelection) ? this.state.centers[index] : center,
          zoom: newSelection ? 0 : this.state.zooms[index],
          maxResolution: resolutions[0],
          maxZoom: (resolutions.length - 1),
          rotation: newSelection ? 0 : this.state.slideAngles[index]
        });
        
        let static_path;
        if (this.is_cloud === "true") {
          static_path="https://storage.googleapis.com/"
        } else {
          if(slide_data.status === 12) {
            static_path = "/scan-hdd/" + slide_data.scandrive_id + "/";
          }
          else {
            static_path = "/scan-ssd/";
          }
        }
        
        var shouldUpdate = true;
        var updatePermalink = function(syncStatus) {
          if (!shouldUpdate) {
            // do not update the URL when the view was changed in the 'popstate' handler
            shouldUpdate = true;
            return;
          }
          var center = view.getCenter();
          var hash = 'map' + index + '=' +
              view.getZoom() + '/' +
              Math.round(center[0] * 100) / 100 + '/' +
              Math.round(center[1] * 100) / 100 + '/' +
              view.getRotation() + '/' + 
              slide_data.id + '/' + 
              syncStatus;
          
            var state = Object.assign({}, window.history.state, { 
            [index] : {
              zoom: view.getZoom(),
              center: view.getCenter(),
              rotation: view.getRotation(), 
              slide: slide_data.id,
            }
          });

          var viewer_hashes = window.location.hash.replace('#', '').split('&');
          var final_hash = "#"
          var valid_hashes = 0;
          for (var i = 0; i < viewer_hashes.length; i++) {
            if (viewer_hashes[i].length > 0) {
              valid_hashes += 1;
              if (i === index) {
                final_hash += hash + '&';
              } else {
                final_hash += viewer_hashes[i].replace('&', '') + '&';
              }
            }
          }

          if (index >= valid_hashes) {
            final_hash += hash + '&';
          }

          window.history.replaceState(state, 'map' + index, final_hash);
        };

        let url =  static_path + `${slide_data.bucket_name}/${slide_data.path}stitched/{z}/x{x}y{y}.jpg`;
        let slide_selected = this.state.slide_selected;
        slide_selected[index] = true;

        let sources = this.state.sources;
        sources[index] = source;
        let views = this.state.views;
        views[index] = view;
        let projections = this.state.projections;
        projections[index] = proj;
        let centers = this.state.centers;
        centers[index] = center;
        let urls = this.state.urls;
        urls[index] = url;
        let resolutionsList = this.state.resolutionsList;
        resolutionsList[index] = resolutions;
        let imageSizes = this.state.imageSizes;
        imageSizes[index] = [imgWidth, imgHeight];
        let selected_slide_data = this.state.selected_slide_data;
        selected_slide_data[index] = slide_data;
        let slideAngles = this.state.slideAngles;
        slideAngles[index] = slideAngle;
        let updatePermalinks = this.state.updatePermalinks;
        updatePermalinks[index] = updatePermalink;

        window.addEventListener('popstate', function(event) {
          if (event.state === null) {
            return;
          }
          for (var key in event.state) {
            var curstate = event.state[key];
            // slides[curstate.map_id] = curstate.slide;
            var map_id = parseInt(key);
            view = views[map_id];
            if (view !== undefined) {
              view.setRotation(curstate.rotation);
              view.setCenter(curstate.center);
              view.setZoom(curstate.zoom);
            }
          }
        });

        this.setState({
          views: views,
          projections: projections,
          centers: centers,
          urls: urls,
          resolutionsList: resolutionsList,
          imageSizes: imageSizes,
          selected_slide_data: selected_slide_data,
          slide_selected: slide_selected,
          isFetching: false,
          slideAngles: slideAngles, 
          updatePermalinks: updatePermalinks,
          sources: sources
        }, () => {
          try {
            this.trySwitchingSyncOn();
          } catch {
            console.log("Both views not initialized yet.");
          }
        });
    }

    getAllSlides = () => {
      this.setState({
        isFetching: true
      });
      let url = `/api/nonpagslides/`;
      axios.get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
            let slide_list = response.data.map((value) => {
              return (<Option key={value.name + "&&" + value.id}>{value.name}</Option>)
            });
            this.setState({
              isFetching: false,
              slide_list: slide_list
            });
          })
          .catch(err => {
            message.error("Slide could not be loaded. Contact Admin.");
            this.setState({
                isFetching: false
            });
            console.log(err);
          });
    }

    getSlide = (value, index, newSelection) => {
      this.setState({
        isFetching: true
      });
      let url = `/api/slide/${value}/`;
        axios
          .get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
            this.initialiseSlideMap(response.data, index, newSelection);
          })
          .catch(err => {
            message.error("Slide could not be found. Contact Admin.");
            this.setState({
                isFetching: false
            });
            console.log(err);
          });
    }
    
    switchChange = (checked) => {
      if (checked) {
        this.syncNow();
      }
      else {
        this.unSyncNow();
      }
    }

    syncNow = () => {
        if (this.state.slide_selected[0]  && this.state.slide_selected[1]) {
          let view1 = this.state.views[0];
          view1.on(['change:center', 'change:resolution','change:rotation'], this.syncView2);
          let view2 = this.state.views[1];
          view2.on(['change:center', 'change:resolution','change:rotation'], this.syncView1);
          let views = [view1, view2];
          this.setState({
            views: views, 
            isSyncModeOn: true
          }, () => {
            this.setSync();
          });
        }
    }

    unSyncNow = () => {
        this.setState({
            sync: false, 
            isSyncModeOn: false
        }, () => {
          this.state.updatePermalinks[0](this.state.isSyncModeOn);
          this.state.updatePermalinks[1](this.state.isSyncModeOn);
        });
    }

    syncView1 = () => {
      if (this.state.sync) {
          let view2 = this.state.views[1];
          let view1 = this.state.views[0];
          let center2 = view2.getCenter();
          let diff_x = center2[0] - this.center2[0];
          let diff_y = center2[1] - this.center2[1];
          let new_diff_x = diff_x*Math.cos(view2.getRotation() - view1.getRotation()) + diff_y*Math.sin(view2.getRotation()- view1.getRotation());
          let new_diff_y = diff_y*Math.cos(view2.getRotation()- view1.getRotation()) - diff_x*Math.sin(view2.getRotation()- view1.getRotation());
          let zoom = view2.getZoom();
          this.setState({
              sync: false
          });
          let moved_center = [this.center1[0] + new_diff_x, this.center1[1] + new_diff_y];
          view1.animate({
              center: moved_center,
              zoom: zoom - this.dz,
              duration: 0
          }, this.resetState);
          let views = this.state.views;
          views[0] = view1;
          this.setState({
            views: views
          });
      }
    }

    resetState = () => {
        this.setState({
            sync: true
        });
    }

    syncView2 = () => {
        if (this.state.sync) {
          let view1 = this.state.views[0];
          let view2 = this.state.views[1];
          let center1 = view1.getCenter();
          let diff_x = center1[0] - this.center1[0];
          let diff_y = center1[1] - this.center1[1];
          let new_diff_x = diff_x*Math.cos(view1.getRotation() - view2.getRotation()) + diff_y*Math.sin(view1.getRotation()- view2.getRotation());
          let new_diff_y = diff_y*Math.cos(view1.getRotation() - view2.getRotation()) - diff_x*Math.sin(view1.getRotation()- view2.getRotation());
          let zoom = view1.getZoom();
          this.setState({
              sync: false
          });
          let moved_center = [this.center2[0] + new_diff_x, this.center2[1] + new_diff_y];
          view2.animate({
              center: moved_center,
              zoom: zoom + this.dz,
              duration: 0
          }, this.resetState);
          let views = this.state.views;
          views[1] = view2;
          this.setState({
            views: views
          });
      } 
    }

    setSync = () => {
        this.setState({
            sync :true
        }, () => {
          this.state.updatePermalinks[0](this.state.isSyncModeOn);
          this.state.updatePermalinks[1](this.state.isSyncModeOn);
        });
        let rotation1 = this.state.views[0].getRotation();
        let rotation2 = this.state.views[1].getRotation();
        this.dr = rotation2 - rotation1;
        this.center1 = this.state.views[0].getCenter();
        this.center2 = this.state.views[1].getCenter();
        this.dx = this.center2[0] - this.center1[0];
        this.dy = this.center2[1] - this.center1[1];        
        let zoom1 = this.state.views[0].getZoom();
        let zoom2 = this.state.views[1].getZoom();
        this.dz = zoom2 - zoom1;
    }

    componentDidMount() {
      this.getAllSlides();

      for (var i = 0; i < this.state.slide_selected.length; i++) {
        if (this.state.slide_selected[i]) {
          this.getSlide(this.state.slide_selected[i], i, false);
        }
      }
    }
    
    trySwitchingSyncOn = () => {
      if(this.state.isSyncModeOn) {
          this.syncNow();
        }
    }

    render() {

      // if((this.state.slide_selected[0] && this.state.slide_selected[1])) {
      //   console.log("rendering c1, c2 : ", this.state.views[0].getCenter(), this.state.views[1].getCenter());
      // }

      const renderMap = (index) => {
        if (this.state.slide_selected[index] === true) {
          return(
              <PublicMap
                source={this.state.sources[index]} 
                map_id = {index}
                view = {this.state.views[index]}
                projection = {this.state.projections[index]}
                center = {this.state.centers[index]}
                url={this.state.urls[index]}
                resolutions={this.state.resolutionsList[index]}
                slide_data={this.state.selected_slide_data[index]}
                imageSize={this.state.imageSizes[index]}
                slideAngle={this.state.slideAngles[index]}
                sync_browsing_enabled={this.state.isSyncModeOn}
                updatePermalink={this.state.updatePermalinks[index]}
              />
          );
        }
        else {
          return(
          <h5>Please Select Slide</h5>);
        }
      }

      return(
          <div id ="viewer-maps">
              {this.state.isFetching === false?
                  (
                  <Row>
                      <div style={{ width: "100%", textAlign: "center", marginTop:"1%"}}>
                        <Switch
                          className="sync-switch"
                          checkedChildren={"UnSync"}
                          unCheckedChildren={"Sync"}
                          onChange={this.switchChange}
                          checked={this.state.isSyncModeOn}
                          disabled={!(this.state.slide_selected[0] && this.state.slide_selected[1])}
                          />
                      </div>
                    <Row className="content-mp" >
                      <Col span={11} offset={1} style={{marginTop : "15px"}} className="content-1-mp">
                        <Select
                            className="select-box"
                            mode="tags"
                            placeholder="Please select slide"
                            onChange={(value) => this.getSlide(value[0].substr(value[0].lastIndexOf('&') + 1), 0, true)}
                            style={{ width: '100%' }}
                        >
                            {this.state.slide_list}
                        </Select>
                      <br/>
                        <Affix offsetTop={15} >
                            {renderMap(0)}
                        </Affix>
                      </Col>
                      <Col span={11} offset={0} style={{marginTop : "15px", marginLeft:"10px"}} className="content-1-mp">
                        <Select
                            className="select-box"
                            mode="tags"
                            placeholder="Please select slide"
                            onChange={(value) => this.getSlide(value[0].substr(value[0].lastIndexOf('&') + 1), 1, true)}
                            style={{ width: '100%' }}
                        >
                            {this.state.slide_list}
                        </Select>
                        <Affix offsetTop={15} >
                            {renderMap(1)}
                        </Affix>
                      </Col>
                  </Row>
                  </Row>
                  ): (<h5>Loading Slides. Please wait.</h5>)
              }
          </div>
      )
    }
}

const mapStateToProps = state => {
    return {
    };
  };
  
export default connect(mapStateToProps)(ViewerContentSync);
