import React, { Component } from 'react';
import cookie from "react-cookies";
import {
  Button,
  Row,
} from 'antd';
import { globalUrlPrefix } from '../utils/const';
import { updateUrlStateInReducer } from '../action/search.case.action';
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import '../asset/style/custom_antd_table_style.css';
import axios from 'axios';
import {Result } from 'antd';

class CliaBarrier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token:"",
      tokenVerified:false,
      processing: true,
    }

    this.props.dispatch(updateUrlStateInReducer(this.props.urlState));
  }

  componentWillMount() {
    this.props.dispatch(openDrawer());
    this.getToken();
  }

  openCliaPage = () => {
    window.location.href = "/" + globalUrlPrefix + "/clia/";
  }

  openDashboard = () => {
    window.location.href = "/";
  }

  verifyToken = (token) =>{
    let url = `/api/token_verifier/?token=${token}`
    axios.get(url).then(res => {
      if(res.data["status"] === "CORRECT"){
        this.setState({tokenVerified:true})
        let token = res.data["token"]
        cookie.save('screen_supported', token, { path: '/'});
      }
      else{
        cookie.save('screen_supported', false, { path: '/'});
      }
      this.setState({processing:false})
    })
  }

  getToken = () => {
    let url = window.location.href.split('?')[1];
    let token = '';
    if (url) {
      let partsOfUrl = url.split('&');
      partsOfUrl.forEach((part) => {
          let key = part.split('=')[0];
          let value = part.split('=')[1];
          if (key === 'token') {
              token = value;
              this.setState({token:token})
              this.verifyToken(token)
            }
      });
    }
  }

  render() {
    let verified = cookie.loadAll().screen_supported
    if(this.state.tokenVerified === true){
      this.openCliaPage();
    }
    
    let processing = this.state.processing
    return (
      <Row>
      {processing?
        null
    :
    <div>
    <Result
      title="Your Screen Isnt Supported"
      extra={
        <Button type="primary" key="console" onClick={this.openDashboard}>
          Go To Dashboard
        </Button>
      }
    />
  </div>}
    </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(CliaBarrier);
