import React, { Component } from 'react';
import cookie from "react-cookies";
import { CodepenSquareFilled, FolderOpenOutlined, FolderOutlined, PlusOutlined, EyeOutlined, SmileOutlined , AreaChartOutlined} from '@ant-design/icons';
import {
  Col,
  Spin,
  Divider,
  Table,
  Input,
  Button,
  Row,
  Tooltip,
  Empty,
  Pagination,
  Tag,
  Select,
  Popconfirm,
} from 'antd';
import { globalUrlPrefix, openInNewTabType } from '../utils/const';
import axios from "axios";
import { updateUrlStateInReducer, updateCaseSearch, changePage } from '../action/search.case.action';
import { caseUpdateAlert, assignUsersUpdateAlert, removeUserUpdateAlert, markCaseUnread, markCaseAndSlidesRead } from '../action/cases.action'
import { makeMenuVisible } from "../action/context.menu.action";
import { connect } from "react-redux";
import { openDrawer } from "../action/dashboard.action";
import { historyMP } from '../helper/history';
import FilterComp from "../component/case/filter";
import '../asset/style/custom_antd_table_style.css';
import { AuthHeader } from "../helper/auth.token";
import { message, Result, List ,PageHeader, Typography,Modal} from "antd";

var querystring = require('querystring');

const { Search } = Input;
const { Column, ColumnGroup } = Table;
const ButtonGroup = Button.Group;
const Option = Select.Option;
const { Title } = Typography;

const { TextArea } = Input;

const pageSize = 15;

class validationStudies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            addValidationStudy: false,
            validationIdList:[],
            newValidation:'',
            validation_check_flag: false,
            description: '',
            validationIds: [],
        }
        this.Staffcolumns = [
            {
                title:'VALIDATION STUDY NAME',
                width: '20%',
                dataIndex: 'obj',
                render: obj => <a style={{color: 'darkblue'}} href={`clia_validation/${obj.id}`}>{obj.name}</a>,
            },{
                title: 'DESCRIPTION',
                width: '60%',
                dataIndex: 'description'
            }
            ,{
                title: 'CASE COUNT',
                width: '10%',
                dataIndex: 'count'
            },{
                title: 'SUMMARY',
                width: '10%',
                dataIndex: 'count',
                render : (text, record) => {
                    console.log("record_", record)
                    return <div>
                            <Tooltip title="View Summary" placement="right">
                                <AreaChartOutlined 
                                    onClick = {() => this.openSummary(record.obj.id, record.obj.name)}
                                    style = {{fontSize: '40px'}}
                                />
                            </Tooltip>
                        </div>
                }
            }
        ]

        this.nonStaffColumns = [
            {
                title:'VALIDATION STUDY NAME',
                width: '20%',
                dataIndex: 'obj',
                render: obj => <a style={{color: 'darkblue'}} href={`clia_validation/${obj.id}`}>{obj.name}</a>,
            },{
                title: 'DESCRIPTION',
                width: '60%',
                dataIndex: 'description'
            }
            ,{
                title: 'CASE COUNT',
                width: '10%',
                dataIndex: 'count'
            }
        ]
    }

    componentWillMount() {
        this.getValidationIDs();
        let url = `/api/get_all_validation_id/`;
            axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
                .then(response => {
                    let data = response.data['data']
                    let ids = []
                    for(let i=0; i<data.length; i++){
                    ids.push(data[i].id)
                    }
                    // let ids = response.data.ids
                // let op = ids.map((id,index) =><Option value={id} key={index}>{id}</Option>)
                    this.setState({validationIds:ids})
                })
    }


    getValidationIDs = () => {
        let url = `/api/get_all_validation_id/`;
        let data = []
        axios.post(url,querystring.stringify({user: parseInt(cookie.load('user_id'))}),{ headers: { Authorization: AuthHeader() }})
            .then(response => {
                console.log("res",response.data['data'])
                this.setState({
                    data: response.data['data'],
                })
            })
        }
  
    openAddNewValidationStudy = () => {
        // window.location.href = "/" + globalUrlPrefix + "/addvalidation";
        this.setState({
            addValidationStudy : true,
        })
    }
    
    openSummary = (id, name) => {
        window.location.href = "/" + globalUrlPrefix + "/summary/" + id ;
    }
  
    handleCancel = () => {
        this.setState({
            addValidationStudy : false,
        })
    }

    createValidation = (e) => {
        let url = `/api/create_validation/`;
        let validationId = this.state.newValidation
        let validationIds = this.state.validationIds
        for(let i=0; i<validationIds.length; i++){
            if(validationIds[i]==validationId){
                message.info("Validation Id already exists", 3);
                return;
            }
        }
        let description = this.state.description
        axios.post(url, querystring.stringify({id:validationId, user: parseInt(cookie.load('user_id')), description: description})).then(res=>{
            console.log(res)
            this.setState({newValidation:''})
            window.location.reload();
        })
    }

    changeValidationName = (e) => {
        let index = this.state.validationIdList.findIndex((item)=>e.target.value.toLowerCase()==item.toLowerCase());
        if (index==-1){
            this.setState({
                newValidation:e.target.value,
                validation_check_flag:false
            });
        } else {
            this.setState({
                newValidation:e.target.value,
                validation_check_flag:false
            })
        }
    }

    changeDescription = (e) => {
        console.log(this.state.description)
        this.setState({
            description: e.target.value,
        })
    }

    render() {
    console.log("validation_ids", this.state.validationIds)

        return (
        <div>
            <Row>
                <Title style={{marginLeft:'20px', marginTop:'30px'}}> CLIA VALIDATIONS </Title>
            </Row>
            <Row>
                    <Col span={3}>
                        {cookie.loadAll().is_staff === "true" ?
                            <Button onClick={this.openAddNewValidationStudy} 
                            style={{marginTop:'20px', marginLeft:'20px' }}>
                            Add Validation Study
                                            <PlusOutlined style={{ fontSize: "13px" }} />
                            </Button> : undefined}
                    </Col>
                    {/* <Col span={3}>
                      {cookie.loadAll().is_staff === "true" ?
                        <Button onClick={this.openSummary} 
                        style={{marginTop:'20px', marginLeft:'10px' }}>
                          View Summary
                                        <EyeOutlined style={{ fontSize: "13px" }}  />
                        </Button> : undefined}
                    </Col> */}
                    <br></br>
            </Row>
            {/* <Divider></Divider> */}
            <br />
            <Row>
                    <Table
                        className="custom-hover-table-case custom-table-height custom-table-selection custom-table-header"
                        rowClassName={(record, index) => (record.unread ? 'slide-table-rows custom-hover-table-box unread' : 'slide-table-rows custom-hover-table-box read')}
                        bordered
                        columns={cookie.loadAll().is_staff === "true" ? this.Staffcolumns : this.nonStaffColumns}
                        dataSource = {this.state.data}
                        rowClassName="editable-row"
                        size="small"
                        style={{marginLeft: '20px',marginRight: '20px'}}
                    >
                        <Row>{"study 1"}</Row>
                    </Table>
            </Row>
            <Modal
                visible={this.state.addValidationStudy}
                title="ADD VALIDATION STUDY"
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                footer={[
                    <Button key="back" onClick={this.handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit"
                            onClick={this.createValidation}
                            disabled={!(!this.state.validation_check_flag && this.state.newValidation.length > 0)}
                            type='primary'
                    >
                        Submit
                    </Button>,
                ]}
                >
                <Row>
                    <Col>
                        <div>
                            <Row className="add-case-row">
                                <Col span={5} className="add-case-row-header">
                                    <b>Name</b>
                                </Col>
                                <Col span={8}>
                                    {this.state.validation_check_flag ?
                                        <div className="errormessage">
                                            Case with this Validation ID already exists.
                                                </div> : null
                                    }
                                    <Input placeholder="Validation Study Name" value={this.state.newValidation}
                                        className={this.state.validation_check_flag ? "input-field-add-case errors-input" : "input-field-add-case"}
                                        onChange={this.changeValidationName}
                                    />
                                </Col>
                            </Row>
                            <br></br>
                            <Row className="add-case-row">
                                <Col span={5} className="add-case-row-header" >
                                    <b>Description</b>
                                </Col>
                                <Col span={19}>
                                    <TextArea rows={4} onChange={this.changeDescription} value={this.state.description}>

                                    </TextArea>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </div>
        );
    }
}

const mapStateToProps = state => {
  return {
    loginStatus: state.loginAlerts.loginStatus,
    allCases: state.casesReducer,
    allCaseStatus: state.caseStatusReducer.case_status,
    allUsers: state.caseStatusReducer.users,
    numPages: state.caseStatusReducer.numPages,
    pageNumber: state.caseStatusReducer.pageNumber,
    total: state.caseStatusReducer.total,
    urlState: state.searchCaseReducer,
    isFetching: state.searchCaseReducer.isFetching
  };
};

export default connect(mapStateToProps)(validationStudies);