import React, {Component} from  "react";
import {Row, Col, Divider, Switch } from 'antd';
import {connect} from 'react-redux';

import {View} from "ol";
import {OverviewMap} from 'ol/control.js';

import "../../../asset/style/neoviewer/preview.css"

class PreviewMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewColouring: false
        }

        this.previewMap = new OverviewMap({
          className: "preview-map",
          layers: this.props.layers,
          view: new View({
            projection: this.props.view.getProjection(),
            rotation: this.props.view.getRotation(),
            resolutions: this.props.view.getResolutions(),
          }),
          collapsible: false,
          collapsed: false
        });

        this.zoomVisitedMap = {};
        this.zoomVisitedFullMap = {};

        this.props.zoomLevels.map((level) => {
          this.zoomVisitedMap[parseInt(level)] = [];
        });
    }

    componentDidMount() {
        this.previewMap.setTarget('preview-map-to-target');
        this.props.addPreviewMapControl(this.previewMap);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.layers != prevProps.layers) {
            this.previewMap = new OverviewMap({
                className: "preview-map",
                layers: this.props.layers,
                view: new View({
                    projection: this.props.view.getProjection(),
                    rotation: this.props.view.getRotation(),
                    resolutions: this.props.view.getResolutions(),
                }),
                collapsible: false,
                collapsed: false
            });
            this.props.addPreviewMapControl(this.previewMap);
        }
    }

    switchPreviewColoring = (value) => {
        this.setState({
            previewColouring: value
        })
    }

    render(){

        return(
            <Row className='preview-component overlayed-component app-parent'>
                <Col span={24}>
                    <Row>
                        <Col span={24} id='preview-map-to-target'>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
    }
}

export default connect(mapStateToProps)(PreviewMap);