export const ViewerSettings = {
  IMAGE_CONTRAST_CHANGED: "IMAGE_CONTRAST_CHANGED",
  IMAGE_BRIGHTNESS_CHANGED: "IMAGE_BRIGHTNESS_CHANGED",
  IMAGE_HUE_CHANGED: "IMAGE_HUE_CHANGED",
  IMAGE_INVERT_CHANGED: "IMAGE_INVERT_CHANGED",
  IMAGE_GRAYSCALE_CHANGED: "IMAGE_GRAYSCALE_CHANGED",
  IMAGE_SATURATION_CHANGED: "IMAGE_SATURATION_CHANGED",
  IMAGE_RESET_DEFAULT: "IMAGE_REST_DEFAULT",
  SET_ALL_SETTINGS: "SET_ALL_SETTINGS", 
  SET_NAME: "SET_NAME",
  SHOW_SEEN_AREA_CHANGED: "SHOW_SEEN_AREA_CHANGED",
} 