
import {ViewerSettings} from "../actionTypes/viewerSettings.constant";

const InitialState = {
  contrast: 70,
  brightness: 100,
  hue: 0,
  saturation: 100,
  invert: 0,
  grayscale: 0,
  name: "Default", 
  id: -1,
  seen_area_status: false,
}

export const viewerSettingReducer = ( state = InitialState , action) => {
  switch(action.type){
      case ViewerSettings.IMAGE_CONTRAST_CHANGED:
          return Object.assign({}, state, {
            contrast: action.response
          })
      case ViewerSettings.IMAGE_BRIGHTNESS_CHANGED:
          return Object.assign({}, state, {
            brightness: action.response
          })  
      case ViewerSettings.IMAGE_GRAYSCALE_CHANGED:
          return Object.assign({}, state, {
            grayscale: action.response
          })
      case ViewerSettings.IMAGE_HUE_CHANGED:
          return Object.assign({}, state, {
            hue: action.response
          })
      case ViewerSettings.IMAGE_INVERT_CHANGED:
          return Object.assign({}, state, {
            invert: action.response
          })
      case ViewerSettings.IMAGE_SATURATION_CHANGED:
          return Object.assign({}, state, {
            saturation: action.response
      })
      case ViewerSettings.SET_ALL_SETTINGS:
          return Object.assign({}, action.response)
      case ViewerSettings.SET_NAME:
          return Object.assign({}, state, {name: action.response, id: action.id})
      case ViewerSettings.IMAGE_RESET_DEFAULT:
          return InitialState
      case ViewerSettings.SHOW_SEEN_AREA_CHANGED:
          return Object.assign({}, state, {
            seen_area_status: !state.seen_area_status
          })
      default :
          return state
  }
}