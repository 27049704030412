import {CLEAR_TRIGGER, TRIGGER} from "../actionTypes/triggers.constant";

export const triggerReducer = (state = {}, action) => {
    switch (action.type){
        case TRIGGER:
            return {...state, ...action.trigger};

        case CLEAR_TRIGGER:
            delete state[action.key];
            return {...state};

        default:
            return state;
    }
}
