import { ScanApiConstant } from '../actionTypes/scan.constant';
import axios from 'axios';
import { delay, put, call, take, race} from 'redux-saga/effects'
import cookie from "react-cookies";

function* pollLiveView(id) {
    while (cookie.load('token')) {
      try {
        let liveViewUrl = '/server/devices/' + id + '/get_live_image';
        const { data } = yield call(() => axios({ url: liveViewUrl }));
        yield put(updateLiveView(data));
        yield delay(100);
      } catch (err) {
        yield put(failedToUpdateLiveView(err));
      }
    }
}

export const watchPollLiveView = function* (id) {
	while (cookie.load('token')) {
  	yield take(ScanApiConstant.START_LIVE_VIEW);
    yield race([
      call(pollLiveView, id),
      take(ScanApiConstant.STOP_LIVE_VIEW)
    ]);
  }
}

export const updateLiveView = (data) => ({
    type: ScanApiConstant.UPDATE_LIVE_VIEW,
    data: data,
})

export const updateLiveViewUrl = (data) => ({
    type: ScanApiConstant.UPDATE_LIVE_VIEW_URL,
    data: data,
})

export const failedToUpdateLiveView = (err) => ({
    type: ScanApiConstant.FAILED_IN_LIVE_VIEW_UPDATE,
    err: err,
})

export const startLiveView = () => ({
  type : ScanApiConstant.START_LIVE_VIEW
})

export const stopLiveView = () => ({
  type : ScanApiConstant.STOP_LIVE_VIEW
})

export const updateZoomLevelLiveView = (zoom, x, y) => ({
  type : ScanApiConstant.UPDATE_ZOOM_LEVEL_LIVE_VIEW,
  zoom: zoom,
  x: x,
  y: y,
})

export const updateLiveViewFullScreen = (data) => ({
    type: ScanApiConstant.UPDATE_LIVE_VIEW_FULL_SCREEN,
    data: data,
})

export const updateQuadrantView = (data) => ({
    type: ScanApiConstant.UPDATE_QUADRANT_VIEW,
    data: data,
})

export const updateLiveImagePolling = (data) => ({
    type: ScanApiConstant.UPDATE_LIVE_IMAGE_POLLING_STATUS,
    data: data,
})

export const updateFullscreenMode = (data) => ({
    type: ScanApiConstant.UPDATE_FULLSCREEN_MODE,
    data: data,
})
