import {Alert, Fade, IconButton, Snackbar} from "@mui/material";
import React from "react";
import {store} from "../../../helper/store";
import {clearNotification, pushNotification} from "../../../action/gamma.state.action";
import {CloseRounded} from "@mui/icons-material";

const dispatchNotification = (notification) => {
	store.dispatch(pushNotification(notification));
}
export const closeNotification = (id) => store.dispatch(clearNotification(id));

const buildNotification = (id, message, severity, showClose, autoHideDuration) =>
	<Snackbar id={id} open={true} sx={{mt: 4}} TransitionComponent={Fade} anchorOrigin={{vertical: "top", horizontal: "right"}}
	          autoHideDuration={autoHideDuration ? autoHideDuration > 0 ? autoHideDuration : undefined : 5000}
	          onClose={() => closeNotification(id)}>
		<Alert severity={severity} action={showClose ?
			<IconButton color={severity} onClick={() => closeNotification(id)} >
				<CloseRounded />
			</IconButton> : null
		}>
			{message}
		</Alert>
	</Snackbar>

export const notifySuccess = (id, message, showClose, autoHideDuration) =>
	dispatchNotification(buildNotification(id, message, "success", showClose, autoHideDuration));

export const notifyInfo = (id, message, showClose, autoHideDuration) =>
	dispatchNotification(buildNotification(id, message, "info", showClose, autoHideDuration));

export const notifyWarning = (id, message, showClose, autoHideDuration) =>
	dispatchNotification(buildNotification(id, message, "warning", showClose, autoHideDuration));

export const notifyError = (id, message, showClose, autoHideDuration) =>
	dispatchNotification(buildNotification(id, message, "error", showClose, autoHideDuration));

