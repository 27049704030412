import { CaseActions } from "../actionTypes/cases.constant";
    
const InitialState = {
    numPages: 1, 
    pageNumber: 1,
    total: 1,
    case_status: {},
    case_ids: [],
    users: {},
}

export const caseStatusReducer = (state = InitialState, action) => {
  switch (action.type) {
    case CaseActions.CHANGE_PAGE_CASE:
        return {
            ...state, 
            pageNumber: action.pageNumber
        }
    case CaseActions.CHANGE_TOTAL_NUMBER_OF_PAGES_CASE:
        return {
            ...state, 
            numPages: action.numPages
        }
    case CaseActions.UPDATE_CASE_STATUS:
        var updatedInfo = {}
        for (var i = 0; i < action.status.length; i++){
            let status = action.status[i]
            updatedInfo[status.id] = status;
        }
        return {
            ...state, 
            case_status: updatedInfo
        }
    case CaseActions.UPDATE_CASE_IDS:
        return {
            ...state, 
            case_ids: action.case_ids
        }
    case CaseActions.UPDATE_USERS:
        var updatedInfo = {}
        for (var i = 0; i < action.users.length; i++){
            let user = action.users[i]
            updatedInfo[user.id] = user;
        }
        return {
            ...state, 
            users: updatedInfo
        }
    case CaseActions.CHANGE_TOTAL_CASE:
        return {
            ...state, 
            total: action.total
        }
    default:
      return state;
  }
};
