import { SearchAlertUserConstant } from "../actionTypes/search.user.constant";
import { loadUsers } from "./users.action";
import { getNewState } from "../reducer/search.case.reducer";

export const searchInitiated = () => ({
    type: SearchAlertUserConstant.USER_SEARCH_INITIATED
})

export const searchSuccess = () => ({
    type: SearchAlertUserConstant.USER_SEARCH_SUCCESS
})

export const searchFailed = (error) => ({
    type: SearchAlertUserConstant.USER_SEARCH_FAILED,
    response: error
})

export const clearAllFilters = () => dispatch => {
    dispatch(loadUsers(getNewState()));
}

export const changePage = (pageNumber, currentState) => dispatch => {
    dispatch(loadUsers({
        ...currentState, 
        page: pageNumber
    }))
}

export const updateUserSearch = (value, currentState) => dispatch => {
    var newState = {
        ...currentState,
        search: value, 
        page: 1
    }
    dispatch(loadUsers(newState));
}

export const updateUrlStateInReducer = (oldState) => dispatch => {
    var newState = urlParser(oldState);
    dispatch(loadUsers(newState));
}

export const urlParser = (parsedPartsOfUrl) => {
    try {
        var url = window.location.href.split('?')[1];
        var partsOfUrl = url.split('&');
        partsOfUrl.map((part) => {
            var key = part.split('=')[0];
            var value = part.split('=')[1];
            if (key === 'search') {
                if (value.length > 0) parsedPartsOfUrl.search = value;
            } else if (key === 'page') {
                parsedPartsOfUrl.page = parseInt(value);
            }
        });
    } catch (err) {
        console.log('Could not parse Url : ' + err.message);
    }
    return parsedPartsOfUrl;
}