import React, { Component } from 'react';
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from '../../helper/auth.token';
import { Row, Col, Divider, Button, message, Input } from 'antd';
import { connect } from "react-redux";
import { updateLiveModeAction } from '../../action/admin.state.action';
import { liveModeLoadingMessages } from "../../utils/const";
import { getLiveImageCurrentZ, updateGeneratedAccessCode, getNearestTile, getLiveImageMoveAndFocusDropDistance } from '../../action/livemode.action';
import { generateLiveModePrefixCode } from '../../utils/utils';

class LiveModeAdvancedSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    autoFocus = (doDropDistance) => {
        if (!this.props.adminState.live_mode_action) {
            if (this.props.adminState.slotID >= 0) {
                if (this.props.adminState.main_light) {
                    if (!this.props.adminState.doDropDistanceAF) {
                        let partsOfUrl = "api~diagnostics~autofocus_live_mode";
                        let url;
                        if (doDropDistance) {
                            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.DROP_DISTANCE_AF));
                            url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + `?move=false&doDrop=true` + `&accessCode=` + cookie.loadAll().livemode_access_code;
                        } else {
                            this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.AUTO_FOCUS));
                            url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + `?move=false&doDrop=false` + `&accessCode=` + cookie.loadAll().livemode_access_code;
                        }
                        axios.get(url, { headers: { Authorization: AuthHeader() } })
                            .then(response => {
                                if (response.status === 200) {
                                    let url = '/server/devices/' + this.props.livemode.deviceId + '/get_live_updated_image?scale=2.1&zoom=' + this.props.liveView.zoom +
                                        '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=true' + '&blur=false' + `&accessCode=` + cookie.loadAll().livemode_access_code; 
                                    this.props.dispatch(getLiveImageCurrentZ(url, this.props.adminState.slotID));
                                } else {
                                    if (doDropDistance) {
                                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
                                    } else {
                                        this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.AUTO_FOCUS));
                                    }
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                message.error("Not able to do autofocus!!", 2.5);
                                if (doDropDistance) {
                                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.DROP_DISTANCE_AF));
                                } else {
                                    this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.AUTO_FOCUS));
                                }
                            })
                    } else {
                        message.error("Please go to some position by clicking on the preview image first.", 2.5);
                    }
                } else {
                    message.error("Please switch on the main light.", 2.5);
                }
            } else {
                message.error("Please take the preview first.", 2.5);
            }
        } else {
            message.error("Action ongoing...");
        }
    }

    getLiveImage = () => {
        if (!this.props.adminState.live_mode_action) {
            if (this.props.liveView.url != undefined && this.props.livemode.atLeastOneImageFetched && this.props.livemode.currentMapPosition[this.props.adminState.slotID] != undefined) {
                let partsOfUrl = "api~stage~move_and_focus_drop_distance";
                let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl;
                if (this.props.adminState.slotID != -1) {
                    url += '?slotID=' + this.props.adminState.slotID;
                } else {
                    url += '?slotID=';
                }
                url += "&x=0&y=0&z=0&doDrop=true" +
                    "&doAF=" + this.props.adminState.doAutoFocus + "&numZStack=" + (this.props.livemode.zStackLevels * 2) + 
                    "&zStackStep=" + this.props.livemode.stepSizeZStack + "&timestamp=" + Date.now();

                let nearestTile = getNearestTile(this.props.livemode.currentMapPosition[this.props.adminState.slotID].x, this.props.livemode.currentMapPosition[this.props.adminState.slotID].y, this.props.livemode.tileCenters[this.props.adminState.slotID]);
                let nearestTileY = parseInt(nearestTile.split('_')[1]);

                url += `&nearestTileY=` + nearestTileY;

                url += `&doWB=true`;

                let errorMessage = '';
                if (this.props.adminState.doDropDistanceAF) {
                    errorMessage = liveModeLoadingMessages.DROP_DISTANCE_AF;
                } else if (this.props.adminState.doAutoFocus) {
                    errorMessage = liveModeLoadingMessages.AUTO_FOCUS;
                } else {
                    errorMessage = liveModeLoadingMessages.MOVE;
                }

                this.props.dispatch(getLiveImageMoveAndFocusDropDistance(url, errorMessage, this.props.adminState.slotID, this.props.adminState.takeZStack, [], false, this.props.adminState.areaVisited, this.props.adminState.liveModePreviewVectorLayer, this.props.livemode.currentMapPosition));
            } else {
                message.error("No image to do Coarse Focus on...");
            }
        } else {
            message.error("Action ongoing...");
        }
    }

    generateCode = () => {
        let code = generateLiveModePrefixCode();
        let partsOfUrl = "api~settings~set_generated_access_code_prefix";
        let url = `/server/scano_text/` + this.props.livemode.deviceId + `/` + partsOfUrl + `?code=` + code;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.props.dispatch(updateGeneratedAccessCode(code));
                } else {
                    console.log(response);
                    message.error("Not able to generate code. Please contact admin!!", 2.5);
                }
                // this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to generate code. Please contact admin!!", 2.5);
                // this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
    }

    render() {

        return (
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24} className="settings-heading">
                            <b>Advanced Settings</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1} span={22} className="custom-top-bottom-margin-divider">
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{textAlign: 'center'}}>
                            <Button size="small" 
                                // onClick={() => this.autoFocus(true)}
                                onClick={this.getLiveImage} 
                                className="card-color"
                                disabled={!this.props.livemode.accessToLiveMode || this.props.livemode.useLiveModeInViewMode}
                            >
                                Do Coarse Focus
                            </Button>
                        </Col>
                    </Row>
                    {(cookie.loadAll().superuser == 'true' || cookie.loadAll().staff == 'true') ?
                        <Row>
                            <Col span={24}>
                                <br />
                                <Row>
                                    <Col span={24} style={{textAlign: 'center'}}>
                                        <Button size="small" onClick={this.generateCode} className="card-color">
                                            Generate Code
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                {this.props.livemode.generatedAccessCode.length != 0 ?
                                    <Row>
                                        <Col span={24} style={{textAlign: 'center'}}>
                                            <span>{"Generated Code: "}</span><b>{this.props.livemode.generatedAccessCode}</b>
                                        </Col>
                                    </Row>
                                    :  null
                                }
                            </Col>
                        </Row>
                        : null
                    }
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(LiveModeAdvancedSettings);
