import React,{Component} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Grid, IconButton, Stack, Tooltip} from "@mui/material";
import {connect} from "react-redux";
import AppsIcon from '@mui/icons-material/Apps';
import {a11yProps} from "./utils/appBarUtils";
import {TabPanel} from "./utils/appBarUtils";
import {openApp} from "../../action/triggers.action";

class AppTabs extends Component{
	constructor(props) {
		super(props);
		this.state = {
			tabs: [],
			tabPanels: [],
		}
	}

	checkTrigger = () => {
		if (this.props.apps.find(app => app.id === this.props.trigger.openApp)) {
			this.setState({
				currentTab: this.props.trigger.openApp,
			});
			delete this.props.trigger.openApp;
		}
	}

	handleChange = (value) =>
		this.setState({
			currentTab: value === this.state.currentTab ? undefined : value,
		});

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(!prevState.currentTab && this.state.currentTab)
			this.props.dispatch(openApp('preview-controls'));
	}

	getTabComponent = (app) =>
		<Tooltip title={app.showTitleOnHover ? app.title : ""} placement={"right"} value={app.id}>
			<Tab icon={app.icon}  value={app.id} {...a11yProps(app.id)} onClick={() => this.handleChange(app.id)}
			     sx={{fontSize:'3vh'}} />
		</Tooltip>;

	getTabPanelComponent = (app) =>
		<TabPanel index={app.id} value={this.state.currentTab}>
			<Grid sx={{width:'15vw'}} >
				{app.component}
			</Grid>
		</TabPanel>;

	getTabs = (apps) => [
		this.props.allAppsTab ?
			<Tab icon={<AppsIcon sx={{fontSize:'inherit'}}/>} {...a11yProps(0)}
			     onClick={()=>this.handleChange("all")}
			     sx={{fontSize:'3vh'}} value={0}>
			</Tab> : undefined,
		...apps.filter(app => app.makeSideBarIcon).map(app => this.getTabComponent(app, this.handleChange)),
	];

	getTabPanels = (apps) => [
		this.props.allAppsTab ?
			<TabPanel index={"all"} value={this.state.currentTab}>
				<Grid container sx={{width:'15vw'}} >
					{apps.map(app =>
						<Grid item xs={4} sx={{textAlign:'center', height:'10vh'}}>
							<IconButton onClick={() => this.handleChange(app.id)} size={'large'} color={'secondary'}>
								{app.icon}
							</IconButton>
							<Typography noWrap={true}>
								{app.title}
							</Typography>
						</Grid>
					)}
				</Grid>
			</TabPanel> : undefined,
		...apps.map(app => this.getTabPanelComponent(app, this.state.currentTab))
	];

	render() {
		this.checkTrigger();
		let tabs = this.getTabs(this.props.apps);
		let tabPanels = this.getTabPanels(this.props.apps);
		return <Box sx={{flexGrow: 1, bgcolor: "background.paper", display: "flex", height: this.props.height}}>
			<Stack direction={this.props.direction}>
				<Tabs orientation="vertical" variant="sc`rollable" value={this.state.currentTab} onChange={this.handleChange}
				      aria-label="Vertical tabs example" sx={{ borderRight: 1, borderColor: "divider" }}>
					{tabs}
				</Tabs>
			</Stack>
			<React.Fragment>
				{tabPanels}
			</React.Fragment>
		</Box>;
	}
}

const mapStateToProps = (state) => {
	return {
		trigger: state.triggerReducer,
	}
}

export default connect(mapStateToProps)(AppTabs);

