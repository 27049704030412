
import React from 'react';
import { Icon, Input, message } from 'antd';
import axios from 'axios';
import { AuthHeader } from '../../../helper/auth.token';
import { connect } from 'react-redux';
import cookie from "react-cookies";

import '../../../asset/style/neoviewer/comments.css';

class CommentComp extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      comments: [],
      submitting: false,
      value: '',
      data: null,
      username: cookie.loadAll().username,
      userId: cookie.loadAll().user_id
    }
  }


  getComment(value) {
    if(value !== null) {
      let url = `/api/tile_viewer_comments/?annotation=${value}`
      axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
          if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
            let data = response.data;
            this.extractComment(data);
          }
          else if (response.status === 403 || response.status === 401 || response.status === 400) {
            message.error('Comments Update Failed', 2.5);
          }
        })
        .catch(err => {
        });
    }
  }


  extractComment = (data) => {
    var com = [];
    data.reverse().map(index => {
      let par = JSON.parse(index.comment);
      com.push(par);
      return par;
    });
    this.setState({
      comments: com
    });
    this.updateScroll();
  }

  sendComment(value) {
    let val = { "user": this.state.userId, "comment": JSON.stringify(value), "annotation": this.props.annoId };
    let url = `/api/tile_viewer_comments/`;
    axios.post(url, val, { headers: { Authorization: AuthHeader() } })
      .then(response => {
        if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
          this.updateScroll();
        }
        else if (response.status === 403 || response.status === 401 || response.status === 400) {
          message.error('Comments added Failed', 2.5);
        }
      })
      .catch(err => {
      });
  }

  componentDidMount() {
    this.getComment(this.props.annoId)
  }

  updateScroll() {
    let element = document.getElementById("messageBody");
    element.scrollTop = element.scrollHeight;
  }


  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }
    setTimeout(() => {
      let val = { "author": this.state.username, "content": this.state.value };
      this.sendComment(val);
    }, 100);

    this.setState({
      submitting: true,
    });

    setTimeout(() => {
      this.setState({
        submitting: false,
        value: '',
        comments: [
          ...this.state.comments,
          {
            author: this.state.username,
            content: this.state.value
          },
        ],
      });
    }, 100);
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.annoId !== this.props.annoId) {
      this.setState({
        comments: [],
        submitting: false,
        value: '',
        data: null
      })
      this.getComment(nextProps.annoId)
      return true
    }
  }


  render() {
    const { comments, submitting, value } = this.state;
    return (
      <div>
        <p style={{ marginBottom: '2%', fontWeight: 500, fontSize: '.7rem' }}>{`${comments.length} ${comments.length !== 1 ? 'comments' : 'comment'}`}</p>
        <div id='messageBody' className="scrollbar comments-body">
          {this.state.comments.map((value,index) => (
            <div id={this.props.annoId + "-" + index} key={this.props.annoId + "-" + index} className="ant-comment-content">
              <div className="ant-comment-content-author">
                <span className="ant-comment-content-author-name">{value.author}</span>
              </div>
              <div className="ant-comment-content-detail">
                {value.content}
              </div>
            </div>
          ))}
        </div>
        <Input 
          onPressEnter={this.handleSubmit}
          allowClear
          value={value}
          disabled={submitting}
          onChange={this.handleChange}
          addonAfter={<Icon type="enter" onClick={this.handleSubmit}></Icon>}
        />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};

export default connect(mapStateToProps)(CommentComp);
