import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { AuthHeader } from "../../../helper/auth.token";
import { Row, Col, Descriptions, Divider, Input, Button, message, Spin, Tabs } from 'antd';
import { ongoingMotionPreviewAction } from '../../../action/admin.state.action';
import { makePanZoomCanvas } from '../../../utils/panzoomcanvas';
import { previewTuningTestAppType } from "../../../utils/const";

import "../../../asset/style/manualmode/preview_tuning_app.css";

const { TabPane } = Tabs;

class PreviewAppTest extends Component {

    constructor(props) {
        super(props);
        
        let url = window.location.href.split('?')[1];
        let slot_id = -1;
        let type = '';
        if (url != undefined) {
            let partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                let key = part.split('=')[0];
                let value = part.split('=')[1];
                if (key === 'test_app_slot_id') {
                    slot_id = parseFloat(value);
                } else if (key === 'type') {
                    type = value;
                }
            });
        }

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            numberOfSlots: 0,
            slotID: slot_id,
            view: type == '' ? previewTuningTestAppType.STITCHED_IMAGE : type,
        }

        this.canvasRef = React.createRef();

        this.image = new Image();

        this.image.onload = this.onload_runner3;
        window.addEventListener("load", this.onload_runner3, false);

        this.onload_runner3 = this.onload_runner3.bind(this);

    }

    componentDidMount = () => {
        this.getStitchedImage();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.adminState.numberOfSlots == 0 && this.props.adminState.numberOfSlots != 0) { 
            this.getStitchedImage();
            if(this.props.adminState.numberOfSlots == 1) {
                this.setState({
                    view: previewTuningTestAppType.CROPPED_SLOT_IMAGE,
                })
            }
        }
    }

    getStitchedImage = () => {
        if(this.state.view == previewTuningTestAppType.STITCHED_IMAGE && this.state.slotID == 6) {
            this.image.src = "/diagnostics/preview/latest/stitched_preview_image.jpg";
        } else if(this.state.view == previewTuningTestAppType.FULL_SLOT_IMAGE && this.state.slotID >= 0) {
            this.image.src = "/diagnostics/preview/latest/" + (this.state.slotID * 2) + ".jpg";
        } else if(this.state.view == previewTuningTestAppType.CROPPED_SLOT_IMAGE && this.state.slotID >= 0) {
            this.image.src = "/diagnostics/preview/latest/cropped_" + this.state.slotID + ".jpg";
        }
    }

    onload_runner3 = () => {

        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));

        let canvas = this.canvasRef.current; 

        makePanZoomCanvas(canvas, this.image);

        var self=this;

        canvas.addEventListener('dblclick', function(e) { 

            let ctx = canvas.getContext("2d");
            let border = parseInt(getComputedStyle(canvas, null).getPropertyValue('border-left-width'));

            let x = parseInt(e.offsetX) - border;
            let y = parseInt(e.offsetY) - border;

            let pt = ctx.transformedPoint(x, y);

            self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, true));
            let url = '';
            if(self.state.view == previewTuningTestAppType.STITCHED_IMAGE && self.state.slotID == 6) {
                url = "/server/devices/" + self.props.currentState.deviceId + "/settings/preview_tuning/move_to_pixel_test/?x=" +
                    Math.trunc(pt.x) + "&y=" + Math.trunc(pt.y);
            } else if(self.state.view == previewTuningTestAppType.FULL_SLOT_IMAGE && self.state.slotID >= 0) {
                url = "/server/devices/" + self.props.currentState.deviceId + "/settings/preview_tuning/move_to_pixel_test_small/?slot_id=" +
                    self.state.slotID + "&x=" + Math.trunc(pt.x) + "&y=" + Math.trunc(pt.y);
            } else if(self.state.view == previewTuningTestAppType.CROPPED_SLOT_IMAGE && self.state.slotID >= 0) {
                url = "/server/devices/" + self.props.currentState.deviceId + "/settings/preview_tuning/move_to_pixel_test_cropped/?slot_id=" +
                    self.state.slotID + "&x=" + Math.trunc(pt.x) + "&y=" + Math.trunc(pt.y);
            }
            
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200) {
                    }
                    else {
                        message.error("Not able to move to the specified position!!", 2.5);
                    }
                    self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
                })
            .catch(err => {
                console.log(err);
                message.error("Not able to move to the specified position!!", 2.5);
                self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
            });
        });
    }

    changeView = (value) => {
        this.setState({
            view: value,
        })
    }

    changeSlot = (slot_id) => {
        window.location.href = window.location.href.split("?")[0] + "?type=" + this.state.view + "&test_app_slot_id=" + slot_id;
    }

    render() {

        let slotsButton = [];
        let numberOfSlots = this.props.adminState.numberOfSlots;
        let spanSize = 18 / numberOfSlots;


        for(let i = 0; i < numberOfSlots; i++) {
            slotsButton.push(
                <Col span={spanSize} key={i}>
                    <Button type={this.state.slotID == i ? "danger" : "primary"} size="small" onClick={() => this.changeSlot(i)}>
                        {i + 1}
                    </Button>
                </Col>
            )
        }

        return (
            <Row className="app-parent overlayed-component preview-tuning-app-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Col offset={1}>
                                <Tabs defaultActiveKey={this.state.view} onChange={this.changeView}>
                                    {this.props.adminState.numberOfSlots > 1 ?
                                        <TabPane tab="Stitched Image" key={previewTuningTestAppType.STITCHED_IMAGE}>
                                            <Button size="small" type="primary" onClick={() => this.changeSlot(6)}>
                                                Get the Image
                                            </Button>
                                        </TabPane> : null
                                    }
                                    {this.props.adminState.numberOfSlots > 1 ?
                                        <TabPane tab="Full Slot Image" key={previewTuningTestAppType.FULL_SLOT_IMAGE}>
                                            {slotsButton}
                                        </TabPane> : null
                                    }
                                    <TabPane tab="Cropped Slot Image" key={previewTuningTestAppType.CROPPED_SLOT_IMAGE}>
                                        {slotsButton}
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col offset={1}>
                                <canvas ref={this.canvasRef} id="preview-canvas" width="1000" height="400"></canvas>
                            </Col>
                        </Row>
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer
    }
}

export default connect(mapStateToProps)(PreviewAppTest);
