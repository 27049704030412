import React, { Component } from "react";
import { connect } from 'react-redux';
import { FolderOutlined } from '@ant-design/icons';
import { Row, Col, Spin, Tooltip, Button } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import { getSavedImages } from '../../../action/admin.state.action';

import "../../../asset/style/manualmode/saved_images_app.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class SavedImagesApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: [],
        }

    }

    componentDidMount = () => {
        this.props.dispatch(getSavedImages());
    }

    getSlides = () => {
        let slides = [];
        for(let i = 0; i < this.props.adminState.images.length; i++) {
            let url = 'http://0.0.0.0:3333/media/scandrive/diagnostics/default/maincamera/' + this.props.adminState.images[i];
            slides.push(
                <div key={i}>
                    <img src={url} height="644px"/>
                </div>
            );
        }
        return slides;
    }

    openDirectory = (event) => {
        let localPath = "app:///media/scandrive/diagnostics/default/maincamera/";
        event.stopPropagation();
        window.location.href = localPath;
    }

    render() {

        return (
            <Row className="app-parent overlayed-component saved-images-app-width">
                <div>
                    <Spin spinning={this.props.adminState.images_action}>
                        <Row>
                            <Col span={22}>
                                <Carousel infiniteLoop useKeyboardArrows dynamicHeight className="thumbs-style">
                                    {this.getSlides()}
                                </Carousel>
                            </Col>
                            <Col span={2} style={{paddingLeft: 18}}>
                                <div>
                                    <br/>
                                    <Tooltip title="Open Folder" placement="rightTop">
                                        <Button type={"primary"} size="small" onClick={this.openDirectory}>
                                            <FolderOutlined />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </div>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        liveView: state.liveViewReducer,
    }
}

export default connect(mapStateToProps)(SavedImagesApp);