export const lineDrawingKey = {
    name: 'Line', 
    db_key: 'line', 
    shape: 'LineString'
}
export const freelineDrawingKey = {
    name: 'Freeline', 
    db_key: 'line', 
    shape: 'LineString'
}
export const circleDrawingKey = {
    name: 'Circle', 
    db_key: 'circle', 
    shape: 'Circle'
}
export const freehandDrawingKey = {
    name: 'Freehand', 
    db_key: 'polygon', 
    shape: 'Polygon'
}
export const rectangleDrawingKey = {
    name: 'Rectangle', 
    db_key: 'polygon', 
    shape: 'Circle'
}
export const polygonDrawingKey = {
    name: 'Polygon', 
    db_key: 'polygon', 
    shape: 'Polygon'
}