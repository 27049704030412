import React, {Component} from "react";
import {connect} from "react-redux";
import {FormControl, Grid, IconButton, MenuItem, Select, TextField,} from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import {FaCircle} from "react-icons/all";
import Typography from "@mui/material/Typography";
import {AnnotationsConstants} from "../../../utils/const";
import {getFormattedArea, getFormattedLength} from "../../../utils/utils";
import AnnotationComments from "./annotationComments";
import VisibilityIcon from '@mui/icons-material/Visibility';
import {deleteAnnotation, updateAnnotation,} from "../../../action/maps.state.action";

class AnnotationListExpandedComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit_mode: false,
            filteredColor: AnnotationsConstants.ALL_COLORS,
            inputValue: this.props.annotation.title,
        }
    }

    toggleEditMode = () => {
        let edit_mode = this.state.edit_mode;
        this.setState({
            edit_mode: !edit_mode,
        })
    }

    getEditComponent = () => {
        return <IconButton onClick={this.toggleEditMode} color={'secondary'}>
            {this.state.edit_mode? <EditOffIcon/> : <ModeEditIcon/>}
        </IconButton>
    }

    updateAnnotationGeneric = (annotation) =>
        this.props.dispatch(updateAnnotation(this.props.activeMapId, annotation));

    getColorAndNameComponent = () => {
        let component;
        let colors = Object.values(AnnotationsConstants.ANNO_COLORS);
        if(this.state.edit_mode){
            component=<Grid display={"flex"} justifyContent={"center"}>
                            <FormControl  color="secondary" size="small" variant="filled" sx={{ minWidth: 70 }}>
                                <Select
                                    value = {this.props.annotation.color.toLowerCase()}
                                    label="Change Color"
                                    onChange={(e) => this.updateAnnotationGeneric({
                                        id: this.props.annotation.id,
                                        color: e.target.value,
                                    })} //, doRefreshAnnotationsFromBackend
                                    size={"small"}
                                >
                                    {colors.map(color => {
                                    return <MenuItem value={color.toLowerCase()}>
                                                <CircleIcon sx={{color:color.toLowerCase()}}/>
                                            </MenuItem>
                                })}
                                </Select>
                            </FormControl>

                            <TextField autoFocus color='secondary' margin='normal' variant="filled" value={this.state.inputValue}
                                       onKeyDown={(e) => {
                                           if (e.key === 'Enter') {
                                               this.updateAnnotationGeneric({
                                                   id: this.props.annotation.id,
                                                   title: this.state.inputValue,
                                               });
                                               this.setState({edit_mode:false})
                                               e.preventDefault();
                                           }
                                       }}
                                       onChange={(e) =>
                                           this.setState({
                                               inputValue : e.target.value
                                           })}
                                       sx={{px:1, backgroundColor:'#0b1c3b', justifyContent:'center'}}/>
                            <IconButton size="small" onClick={() => {
                                this.updateAnnotationGeneric({
                                    id: this.props.annotation.id,
                                    title: this.state.inputValue,
                                })
                                this.setState({edit_mode:false})
                            }}>
                                <CheckIcon color={'secondary'}/>
                            </IconButton>
                        </Grid>
        }else{
            component = <Grid display={"flex"} justifyContent={"center"}>
                            <FaCircle style={{color:this.props.annotation.color, marginTop:'0.3vh'}}/>
                            <Typography gutterBottom={true} sx={{marginLeft:'0.5vw'}}
                            >{this.props.annotation.title}</Typography>
                        </Grid>
        }
        return component;
    }

    getInfoComponent = () => {
        let annotation = this.props.annotation;
        return <Grid display={"flex"}>
            {
                annotation.shape === AnnotationsConstants.LINE ?
                    <div>
                        <b>Length : </b> {getFormattedLength(annotation.perimeter)}
                    </div>:
                    <div>
                        <b>Area : </b> {getFormattedArea(annotation.area)}
                        <p><b>Perimeter: </b> {getFormattedLength(annotation.perimeter)}</p>
                    </div>
            }
        </Grid>
    }

    getAppSpecificTools = () => {
        let tools = null;
        if (this.props.app === "DeepBio") {
            tools = <IconButton onClick={() => {this.props.switchDeepBioVisibility(this.props.annotation)}} color={'secondary'}>
                <VisibilityIcon/>
            </IconButton>
        }
        return tools;
    }

    render() {
        return <Grid >
            <Grid display="flex" justifyContent="flex-end">
                {this.getEditComponent()}
                <IconButton onClick={() =>
                    this.props.dispatch(deleteAnnotation(this.props.activeMapId, this.props.annotation))}
                            color={'secondary'}>
                    <DeleteIcon/>
                </IconButton>
            </Grid>
            <Grid>
                {this.getColorAndNameComponent()}
            </Grid>
            <br/>
            <Grid>
                {this.props.extra}
            </Grid>
            <br/>
            {this.getInfoComponent()}
            <AnnotationComments annoId={this.props.annotation.id}/>
        </Grid>
    }
}

const mapStateToProps = (state) => ({
    activeMapId: state.gammaStateReducer.activeMapId,
});

export default connect(mapStateToProps)(AnnotationListExpandedComponent)
