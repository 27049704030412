import {MainCameraPreviewConstant} from "../actionTypes/maincamerapreview.constant";

const InitialState = {
    polling: false,
    stale: true,
    data: undefined, 
    error: undefined,
    errored_out: false,
}

export const liveMainCameraPreviewReducer = ( state = InitialState , action) => {
    switch(action.type){
        case MainCameraPreviewConstant.START_LIVE_MAIN_CAMERA_PREVIEW:
            return {
                ...state, 
                polling: true,
            }
        case MainCameraPreviewConstant.STOP_LIVE_MAIN_CAMERA_PREVIEW:
            return {
                ...state, 
                polling: false,
                stale: true
            }
        case MainCameraPreviewConstant.UPDATE_LIVE_MAIN_CAMERA_PREVIEW:
            return {
                ...state, 
                data: action.data, 
                stale: false
            }
        case MainCameraPreviewConstant.FAILED_IN_LIVE_MAIN_CAMERA_PREVIEW_UPDATE:
            return {
                ...state, 
                stale: true,
                errorred_out: true, 
                error: action.err
            }
        default :
            return state
    }
}