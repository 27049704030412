import React from  "react";
import cookie from "react-cookies";
import * as initialSetupkeys from './initial_setup_app_keys';
import * as initialSetupkeysLiveView from './initial_setup_app_keys_live_view';
import * as qcKeys from './qc_app_keys';
import ManualModeAppIcon from './app_icon';

const appiconify = (key, content) => {
    return (
        <ManualModeAppIcon key={key.id} appKey={key} content={content} />
    )
    
}

export const getInitialSetupAppIcons = () => {

    let iconBoard = [];

    if(cookie.loadAll().superuser === 'true') {
        for (let app in initialSetupkeys) {
            let appDiv = appiconify(initialSetupkeys[app], initialSetupkeys[app].icon);
            if (appDiv != undefined) {
                iconBoard.push(appDiv);
            }
        }
    } else {
        for (let app in initialSetupkeysLiveView) {
            let appDiv = appiconify(initialSetupkeysLiveView[app], initialSetupkeysLiveView[app].icon);
            if (appDiv != undefined) {
                iconBoard.push(appDiv);
            }
        }
    }

    return iconBoard;
}

export const getQCAppIcons = () => {

    let iconBoard = [];

    for (let app in qcKeys) {
        let appDiv = appiconify(qcKeys[app], qcKeys[app].icon);
        if (appDiv != undefined) {
            iconBoard.push(appDiv);
        }
    }

    return iconBoard;
}