import { UserActions } from "../actionTypes/users.constant";
import { LoginAlertConstant } from "../actionTypes/login.alert.constant";

const allUsers = {
};

export const usersReducer = (state = allUsers, action) => {
  switch (action.type) {
    case UserActions.ALL_USERS_UPDATE_SUCCESS:
      var updatedInfo = {}
      for (var i = 0; i < action.response.data.results.length; i++){
        let currUser = action.response.data.results[i]
        updatedInfo[currUser.id] = currUser;
        updatedInfo[currUser.id].displayOrder = i;
      }
      return updatedInfo;
    case LoginAlertConstant.LOGOUT:
      return {};
    case UserActions.USER_LIST_UPDATE:
      var currentInfo = Object.assign({}, state[action.id]);
      currentInfo = action.updatedInfo;
      return {
        ...state,
        [action.id]: currentInfo
      }
    default:
      return state;
  }
};
