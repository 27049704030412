export const INIT_STATE = "INIT_STATE";
export const UPDATE_STATE = "UPDATE_STATE";
export const START_PRESENTING = "START_PRESENTING";
export const CLEAR_PRESENTING_STATUS = "CLEAR_PRESENTING_STATE";
export const CLEAR_FOLLOWING_STATUS = "CLEAR_FOLLOWING_STATE";
export const UPDATE_COLLAPSE_MENU_STATE = "UPDATE_COLLAPSE_MENU_STATE";
export const UPDATE_APP_INSTALLED_STATE = "UPDATE_APP_INSTALLED_STATE";
export const UPDATE_APP_CLOSED_STATE = "UPDATE_APP_CLOSED_STATE";
export const UPDATE_APP_POSITION = "UPDATE_APP_POSITION";
export const UPDATE_APP_CLICK_TIME = "UPDATE_APP_CLICK_TIME";
export const UPDATE_DEVICES_ZOOM_LEVELS = "UPDATE_DEVICES_ZOOM_LEVELS";
export const UPDATE_MORPHLE_ID = "UPDATE_MORPHLE_ID";
export const UPDATE_PRESENT_TIMESTAMP = "UPDATE_PRESENT_TIMESTAMP";
export const UPDATE_TILE_COORDINATE = "UPDATE_TILE_COORDINATE";
export const UPDATE_QUADRANT = "UPDATE_QUADRANT";
export const UPDATE_Z_STACK_PARAMS = "UPDATE_Z_STACK_PARAMS";
export const UPDATE_SHAPE = "UPDATE_SHAPE";
export const UPDATE_Z_STACK_LEVEL = "UPDATE_Z_STACK_LEVEL";
export const UPDATE_ON_STITCHED_LAYER = "UPDATE_ON_STITCHED_LAYER";
