import React from  "react";
import AppIcon from '../utils/app_icon'
import * as keys from './apps/app_keys'
import { slideViewerType } from "../../utils/const";

const appiconify = (key, content, allAppsState) => {
    return (
        allAppsState.apps_installed[key.id + "_installed"] === undefined || allAppsState.apps_installed[key.id + "_installed"] === true ? 
            <AppIcon
                    key={key.id}
                    appKey={key} 
                    viewerType={slideViewerType.TILE_SLIDE_VIEWER}
                    content={content}/> : undefined
    )
}

export const getAllAppIcons = (allAppsState) => {

    let iconBoard = [];

    for (let app in keys) {
        let appDiv = appiconify(keys[app], keys[app].icon, allAppsState);
        if (appDiv != undefined) {
            iconBoard.push(appDiv);
        }
    }

    return iconBoard;
}