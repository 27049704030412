import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col, Button, Select, Input, Typography, message } from 'antd'
import html2canvas from 'html2canvas';
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import { convertSettingsToFilter } from "../../../utils/utils"

import "../../../asset/style/neoviewer/screenshot.css"

const { Text } = Typography;

class ScreenshotApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            image_extension: ".jpeg",
            fileName: this.props.slideName.replace('/', '-').replace(/\s+/g, '') + "-morphle-image-",
            currCase: {},
        }
    }

    componentDidMount = () => {
        if (this.props.case != null) {
            this.getCase();
        }
    }

    getCase = () => {
        let url = `/api/case/` + this.props.case + `/`;
        axios
            .get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    currCase: response.data,
                });
            })
            .catch(err => {
                message.error("Case could not be retrieved. Contact Admin.");
                console.log(err);
            });
    }

    changeExtension = (value) => {
        this.setState({
            image_extension: value
        });
    }

    _download = (base64Image, fullName) => {
        var anchor = document.createElement('a');
        anchor.href = base64Image;
        anchor.style.display = 'none';
        anchor.setAttribute('download', fullName);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    }

    addToReport = (base64Image, name, extension) => {
        console.log(this.props)
        let data = {
            'image_data': base64Image.replace('data:image/' + extension + ';base64,', ''),
            'image_path': this.state.currCase.image_path_root,
            'filename': name + ".png",
            'case_id': this.state.currCase.case_id,
            'slide_id': this.props.slideId
        };
        let url = '/api/upload_report_image/';
        axios.post(url, data, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                message.success("Added Snap to Case " + this.state.currCase.case_id)
            });
    }

    takeScreenShot = (name, handler) => {
        document.getElementById('appboard').style.visibility = "hidden";
        document.getElementById('morphle-watermark').style.visibility = "visible";

        // let scaleLineControl = undefined;
        // this.props.slidemap.getControls().forEach(function (control) {
        //     if (control instanceof ScaleLine) {
        //         console.log(control);
        //         scaleLineControl = control;
        //     }
        // });
        // if(scaleLineControl != undefined){
        //     this.props.slidemap.removeControl(scaleLineControl);
        // }
        
        const img = document.getElementById('map-0');
        html2canvas(img).then(canvas => {
            let file_extension = this.state.image_extension.slice(1);
            let filter = convertSettingsToFilter(this.props.viewerSettingData);
            let downloadableCanvas = document.createElement('canvas');
            downloadableCanvas.width = canvas.width;
            downloadableCanvas.height = canvas.height;
            let context = downloadableCanvas.getContext('2d');
            downloadableCanvas.setAttribute('crossOrigin', 'anonymous');
            context.filter = filter;
            context.drawImage(canvas, 0, 0, canvas.width, canvas.height);
            let url = downloadableCanvas.toDataURL('image/' + file_extension, 0.97);
            handler(url, name, file_extension);
            document.getElementById('appboard').style.visibility = "visible";
            document.getElementById('morphle-watermark').style.visibility = "hidden";
            
            // this.props.slidemap.addControl(
            //     new ScaleLine({
            //         units: "metric",
            //         minWidth: 100, 
            //     })
            // );
        });
    }

    render() {
        let caseExists = (this.props.case !== "" && this.props.case !== null);

        let fileName = this.props.slideName.replace('/', '-').replace(/\s+/g, '') + "-morphle-image-"; // this.zoomUnits[this.viewer.getView().getZoom()] + "X";
        let extensionSelectAfter = (
            <Select defaultValue=".jpeg" style={{ width: 80 }} onSelect={this.changeExtension}
                disabled={(this.props.urlState || {}).presentCode != undefined}>
                <Select.Option value=".jpeg">.jpeg</Select.Option>
                <Select.Option value=".png">.png</Select.Option>
            </Select>
        );

        return (
            <Row className="app-parent overlayed-component screenshot-app-parent">
                <Text className="screenshot-filename-text unselectable">Filename: (Press Enter To Save)</Text>
                <Input
                    className="screenshot-name-input"
                    size="small"
                    defaultValue={fileName}
                    addonAfter={extensionSelectAfter}
                    onChange={(e) => e.target.value != undefined && e.target.value != "" ? this.setState({ fileName: e.target.value }) : undefined}
                    onPressEnter={(e) => this.takeScreenShot(e.target.value, this._download)}
                    disabled={(this.props.urlState || {}).presentCode != undefined} />
                <Row className="screenshot-save-button">
                    <Col span={caseExists ? 12 : 24}>
                        <Button onClick={() => this.takeScreenShot(this.state.fileName, this._download)}
                            disabled={(this.props.urlState || {}).presentCode != undefined}>Save</Button>
                    </Col>
                    <Col span={caseExists ? 12 : 0}>
                        <Button onClick={() => this.takeScreenShot(this.state.fileName, this.addToReport)}
                            disabled={(this.props.urlState || {}).presentCode != undefined}>Add To Case Files</Button>
                    </Col>
                </Row>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(ScreenshotApp);
