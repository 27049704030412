import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { presentAppKey } from '../component/neoviewer/apps/app_keys';
import {
    CLEAR_FOLLOWING_STATUS,
    CLEAR_PRESENTING_STATUS, INIT_STATE,
    START_PRESENTING,
    UPDATE_APP_CLICK_TIME,
    UPDATE_APP_CLOSED_STATE,
    UPDATE_APP_INSTALLED_STATE,
    UPDATE_APP_POSITION,
    UPDATE_COLLAPSE_MENU_STATE,
    UPDATE_DEVICES_ZOOM_LEVELS,
    UPDATE_MORPHLE_ID, UPDATE_ON_STITCHED_LAYER,
    UPDATE_PRESENT_TIMESTAMP,
    UPDATE_QUADRANT, UPDATE_SHAPE,
    UPDATE_STATE,
    UPDATE_TILE_COORDINATE, UPDATE_Z_STACK_LEVEL, UPDATE_Z_STACK_PARAMS
} from "../actionTypes/morpheus.state.constant";


export const resetMapParams = (currentState) => dispatch => {

    let newState = {
        ...currentState,
        x: -1,
        y: -1,
        z: 0,
        r: 0
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const clearPresentingStatus = () => dispatch => {
    dispatch({
        type: CLEAR_PRESENTING_STATUS
    })
}


export const clearFollowingStatus = () => dispatch => {
    dispatch({
        type: CLEAR_FOLLOWING_STATUS
    })
}


export const startPresenting = (currentState, code) => dispatch => {

    let newState = {
        ...currentState,
        app_state: {
            ...currentState.app_state,
            [presentAppKey.id]: {
                ...currentState.app_state[presentAppKey.id],
                code: code
            }
        }
    }

    dispatch({
        type: START_PRESENTING,
        urlState: newState
    })
}


export const updateMapParams = (currentState, x, y, z, r) => dispatch => {
    let newState = {
        ...currentState,
        x: x,
        y: y,
        z: z,
        r: r,
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateMapParamsPresent = (currentState, x, y, z, r, app_state) => dispatch => {
    let app_state_new = currentState.app_state;
    app_state_new['zoom-controls'] = app_state['zoom-controls'];
    app_state_new['zoom-controls'].position = app_state['zoom-controls'].position;

    app_state_new['case_info'] = app_state['case_info'];
    app_state_new['case_info'].position = app_state['case_info'].position;

    app_state_new['slide_info'] = app_state['slide_info'];
    app_state_new['slide_info'].position = app_state['slide_info'].position;

    app_state_new['preview-controls'] = app_state['preview-controls'];
    app_state_new['preview-controls'].position = app_state['preview-controls'].position;

    app_state_new['annotations'] = app_state['annotations'];
    app_state_new['annotations'].position = app_state['annotations'].position;

    app_state_new['grid-overlay'] = app_state['grid-overlay'];
    app_state_new['grid-overlay'].position = app_state['grid-overlay'].position;

    app_state_new['settings'] = app_state['settings'];
    app_state_new['settings'].position = app_state['settings'].position;

    app_state_new['screenshot'] = app_state['screenshot'];
    app_state_new['screenshot'].position = app_state['screenshot'].position;

    let newState = {
        ...currentState,
        x: x,
        y: y,
        z: z,
        r: r,
        app_state: app_state_new
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateZoom = (currentState, z) => dispatch => {

    let newState = {
        ...currentState,
        z: z,
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateGridShow = (currentState, showGrid) => dispatch => {

    let newState = {
        ...currentState,
        showGrid: showGrid
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateGridColor = (currentState, gridColor) => dispatch => {

    let newState = {
        ...currentState,
        gridColor: gridColor
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}

export const updateAnnoColor = (currentState, annoColor) => dispatch => {

    let newState = {
        ...currentState,
        annoColor: annoColor
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateGridSize = (currentState, gridSize) => dispatch => {

    let newState = {
        ...currentState,
        gridSize: gridSize
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateGridWidth = (currentState, gridWidth) => dispatch => {

    let newState = {
        ...currentState,
        gridWidth: gridWidth
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}


export const updateDigitalZoomStatus = (currentState, digitalZoomStatus) => dispatch => {

    let newState = {
        ...currentState,
        digitalZoomStatus: digitalZoomStatus
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}

export const updatePreviewColorStatus = (currentState, previewColor) => dispatch => {

    let newState = {
        ...currentState,
        previewColor: previewColor
    }

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}

export const updateCollapseMenuItem = (key, status, urlState) => dispatch => {
    
    dispatch({
        type: UPDATE_COLLAPSE_MENU_STATE,
        collapseMenuItemStatus: status,
        collapseMenuItemKey: key
    });
}

export const updateAppInstalledStatus = (appName, installed, urlState) => dispatch => {
    dispatch({
        type: UPDATE_APP_INSTALLED_STATE,
        appInstalledStatus: installed,
        appName: appName
    });
}

export const updateAppClosedStatus = (appName, closed, urlState) => dispatch => {

    if ((urlState.app_state['present_app'] || {}).code !== undefined) {
        setUpdatedAppStatePresentMode(appName, 'closed', closed, urlState);
    }

    dispatch({
        type: UPDATE_APP_CLOSED_STATE,
        appClosedStatus: closed,
        appName: appName
    })
}

export const updateAppPosition = (appName, position, allAppsState, urlState) => dispatch => {

    if ((urlState.app_state['present_app'] || {}).code !== undefined) {
        setUpdatedAppStatePresentMode(appName, 'position',position, urlState);
    }

    dispatch({
        type: UPDATE_APP_POSITION,
        appPosition: position,
        appName: appName
    });
}

export const updateAppClickTime = (appName, urlState) => dispatch => {

    if ((urlState.app_state['present_app'] || {}).code !== undefined) {
        let allAppStates = urlState.app_state;
        let currentAppState = allAppStates[appName] || {};
        let newAppState = Object.assign({}, allAppStates, {
            [appName]: Object.assign({}, currentAppState, {
                clicked_at: new Date().getTime()
            })
        });

        let appVsClickTime = [];

        for (let key in newAppState) {
            appVsClickTime.push({
                key: key,
                val: newAppState[key].clicked_at === undefined ? 0 : newAppState[key].clicked_at
            })
        }

        appVsClickTime = appVsClickTime.sort(function (a, b) {
            return a.val - b.val;
        });

        appVsClickTime.map((item, index) => {
            newAppState[item.key].zIndex = 9 + 2 * index;
        })

        let returnableState = Object.assign({}, urlState, {
            app_state: newAppState
        });

        let url = `/api/set_sync_browsing/?sync_code=${returnableState.app_state.present_app.code}&morphle_id=${returnableState.slide_morphle_id}&angle=${returnableState.r}&zoom=${returnableState.z}&center_x=${returnableState.x}&center_y=${returnableState.y}&app_state=${JSON.stringify(returnableState.app_state)}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
            })
            .catch(err => {
                console.log("Failed Setting Key Value");
            });
    }

    dispatch({
        type: UPDATE_APP_CLICK_TIME,
        appName: appName
    });
}

export const updateUrlStateInReducer = (oldState) => dispatch => {
    let newState = urlParser(oldState);

    dispatch({
        type: UPDATE_STATE,
        urlState: newState
    })
}

export const updateDeviceZoomRatio = (appName, screenSize, ratio) => dispatch => {

    dispatch({
        type: UPDATE_DEVICES_ZOOM_LEVELS,
        screenSize: screenSize,
        zoomRatio: ratio,
        appName: appName
    });
}

export const updateMorphleID = (morphle_id, slide_id) => dispatch => {

    dispatch({
        type: UPDATE_MORPHLE_ID,
        morphle_id: morphle_id,
        slide_id: slide_id
    });
}

export const updatePresentTimestamp = (grid_timestamp, settings_timestamp, annotations_timestamp, zoom_timestamp) => dispatch => {

    dispatch({
        type: UPDATE_PRESENT_TIMESTAMP,
        grid_timestamp: grid_timestamp,
        settings_timestamp: settings_timestamp,
        annotations_timestamp: annotations_timestamp,
        zoom_timestamp: zoom_timestamp
    });
}

export const updateTileCoordinate = (tileCoordinate) => dispatch => {

    dispatch({
        type: UPDATE_TILE_COORDINATE,
        tileCoordinate: tileCoordinate
    });
}

export const updateQuadrant = (quadrant) => dispatch => {

    dispatch({
        type: UPDATE_QUADRANT,
        quadrant: quadrant
    });
}

export const updateZStackParams = (takeZStack, numZLevels, takeBidirectionalZStack, levelJump) => dispatch => {
    dispatch({
        type: UPDATE_Z_STACK_PARAMS,
        takeZStack: takeZStack,
        numZLevels: numZLevels,
        takeBidirectionalZStack: takeBidirectionalZStack,
        levelJump: levelJump,
    });
}

export const updateShape = (value) => dispatch => {
    dispatch({
        type: UPDATE_SHAPE,
        value: value
    });
}

export const updateZStackLevel = (value) => dispatch => {
    dispatch({
        type: UPDATE_Z_STACK_LEVEL,
        value: value
    });
}

export const updateOnStitchedLayer = (value) => dispatch => {
    dispatch({
        type: UPDATE_ON_STITCHED_LAYER,
        value: value
    });
}

export const loadMorpheusSettings = () => dispatch => {
    let url = `/api/morpheussetting/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                response.data.apps_initialised = true;
                dispatch({
                    type: INIT_STATE,
                    urlState: response.data
                })
            }
            else {
                console.log(response);
            }
        })
        .catch(err => {
            console.log(err);
        })
}

export const urlParser = (parsedPartsOfUrl) => {
    try {
        const url = window.location.href.split('?')[1];
        if (url !== undefined) {
            const partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                var key = part.split('=')[0];
                var value = part.split('=')[1];
                if (key === 'x') {
                    parsedPartsOfUrl.x = parseFloat(value);
                } else if (key === 'y') {
                    parsedPartsOfUrl.y = parseFloat(value);
                } else if (key === 'z') {
                    parsedPartsOfUrl.z = parseFloat(value);
                } else if (key === 'r') {
                    parsedPartsOfUrl.r = parseFloat(value);
                } else if (key === 'digitalZoomStatus') {
                    parsedPartsOfUrl.digitalZoomStatus = value === 'true';
                } else if (key === 'presentCode') {
                    parsedPartsOfUrl.presentCode = value;
                }
            });
        }
    } catch (err) {
        console.log('Could not parse Url', window.location.href, err);
    }
    return parsedPartsOfUrl;
}

export const setUpdatedAppStatePresentMode = (appName, key, value, urlState) => {
    let allAppStates = urlState.app_state;
    let currentAppState = allAppStates[appName] || {};
    let newAppState = Object.assign({}, allAppStates, {
        [appName]: Object.assign({}, currentAppState, {
            [key]: value
        })
    });
    let returnableState = Object.assign({}, urlState, {
        app_state: newAppState
    });

    let url = `/api/set_sync_browsing/?sync_code=${returnableState.app_state.present_app.code}&morphle_id=${returnableState.slide_morphle_id}&angle=${returnableState.r}&zoom=${returnableState.z}&center_x=${returnableState.x}&center_y=${returnableState.y}&app_state=${JSON.stringify(returnableState.app_state)}`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
        })
        .catch(err => {
            console.log("Failed Setting Key Value");
        });
}
