import {DeviceAlertConstant} from "../actionTypes/device.constant";
var isEqual = require('lodash.isequal')

const InitialState = {
}

export const deviceStatusReducer = ( state = InitialState , action) => {
    const currentInfo = Object.assign({}, state[action.id]);
    switch(action.type){
        case DeviceAlertConstant.SET_DEVICE_UI_BUSY:
            currentInfo.uiBusy = true;
            currentInfo.busyMessage = action.busyMessage;
            return {
                ...state, 
                [action.id] : currentInfo
            }
        case DeviceAlertConstant.SET_DEVICE_UI_IDLE:
            currentInfo.uiBusy = false;
            currentInfo.busyMessage = "";
            return {
                ...state, 
                [action.id] : currentInfo
            }
        case DeviceAlertConstant.RECEIVED_DEVICE_STATUS:
            if (!('scanner_health' in currentInfo) || !isEqual(currentInfo.scanner_health, action.response.data)) {
                currentInfo.scanner_health = action.response.data;
                currentInfo.isFetching = false;
                currentInfo.errored_out = false;
                return {
                    ...state, 
                    [action.id]: currentInfo
                }
            } else {
                return state;
            }
        case DeviceAlertConstant.FAILED_TO_RECEIVE_DEVICE_STATUS:
            currentInfo.errored_out = true;
            currentInfo.isFetching = false;
            currentInfo.scanner_health = {status : false, resolution:"Fatal Error Occurred. Please Contact Administrator."};
            return {
                ...state, 
                [action.id]: currentInfo
            }
        default :
            return state
    }
}