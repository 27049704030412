import React, { Component } from "react";
import { connect } from "react-redux";
import Plot from 'react-plotly.js';
import axios from 'axios';
import { Drawer, Modal, Row, Col, Icon, Spin, message, Button } from 'antd';

class LivePlot extends Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            data: null,
            dataPath: this.props.match.params.path
        }
    }

    getPlotData = () => {
        let url = `/server/live_plot/` + this.state.dataPath;
        console.log("path ", url);
        axios.get(url)
            .then(response => {
                if (response.status !== 200) {
                    message.error("Error");
                } else {
                    this.setState({
                        data: response.data
                    })
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    doPolling = () => {
        this.getPlotData();
    }

    componentDidMount = () => {
        this.timer = setInterval(this.doPolling, 1000);
    }

    render() {
        return (
            <div id="scanner-div">
                <Row className="rounded-container">
                    <Col className="scanner-name arial-font" span={24}>
                        {"Live Data Plotter"}
                    </Col>
                </Row>
                <Row>
                    <Col offset={6} span={12}>
                        <Plot
                            data={(this.state.data || {}).data}
                            layout={{
                                height: 540, title: 'Live Data Plot',
                                xaxis: {
                                    title: {
                                        text: 'Time (Minutes)',
                                        font: {
                                            family: 'Courier New, monospace',
                                            size: 18,
                                            color: '#7f7f7f'
                                        }
                                    }
                                },
                                yaxis: {
                                    // range: [-0.1, 1.1],
                                    title: {
                                        text: 'Status',
                                        font: {
                                            family: 'Courier New, monospace',
                                            size: 18,
                                            color: '#7f7f7f'
                                        }
                                    }
                                }
                            }}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
    };
};

export default connect(mapStateToProps)(LivePlot);
