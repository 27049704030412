import React, { Component } from "react";
import { updateTagsAndSearches } from "../../action/search.action"
import { connect } from "react-redux";
import { SearchOutlined } from '@ant-design/icons';
import { Select } from "antd";
import axios from "axios";
import { AuthHeader } from "../../helper/auth.token";
import cookie from "react-cookies";

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tag_suggestions: [],
    };

    this.isMobile = cookie.loadAll().isMobile == 'true';
  }

  typeAheadSearch = (value) => {
    if (value.length >= 1 && value !== "#") {
      var prefix = value.replace('#', '_');
      let url = "/api/typeahead/?prefix=" + prefix;
      axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
          var res_list = response.data;
          var i=0;
          for (i=0; i<res_list.length; i++){
            res_list[i].display_text = res_list[i].display_text.replace(/^_/, "#");
          }
          this.setState({
            tag_suggestions: res_list
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      this.setState({
        tag_suggestions: []
      });
    }
  }

  updateUrlState = (values) => {
    var tags = [];
    var searches = [];

    values.map((value) => {
      if (value.startsWith('#') && value.length > 1) {
        tags.push(value);
      } else {
        searches.push(value);
      }
    });

    this.props.dispatch(updateTagsAndSearches(tags, searches, this.props.urlState));
  }

  handleChange = (values) => {
    this.setState({
      tag_suggestions: []
    });
    this.updateUrlState(values);
  }

  render() {
    let selected = [];

    selected.push(...this.props.urlState.tags);
    selected.push(...this.props.urlState.searches);

    let numSuggested = this.state.tag_suggestions.length;
    let tagSuggestionsBox = numSuggested > 0 ?

      <Select.OptGroup label={<div><b>{numSuggested}</b> result{numSuggested > 1 ? 's' : ''} found </div>}>
        {this.state.tag_suggestions.map((item, index) => (
          <Select.Option
            key={index}
            value={item.display_text}
            label={item.display_text}
            className='search-result-tag'
            size='small'
            type='tag'
          >
            {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
          </Select.Option>
        ))}
      </Select.OptGroup> : undefined;

    return (
      <Select
        showSearch
        allowClear
        className="search-input"
        placeholder={<div><SearchOutlined></SearchOutlined> Search by Name or {this.isMobile ? "# Tag" : "Tag (begin tag search with #)"} </div>}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        onSearch={this.typeAheadSearch}
        mode="tags"
        onChange={this.handleChange}
        optionLabelProp="label"
        value={selected}
      >
        {tagSuggestionsBox}
      </Select>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.searchReducer.isFetching, 
    urlState: state.searchReducer, 
  };
};

export default connect(mapStateToProps)(SearchBar);