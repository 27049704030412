import React, { Component } from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from "../../../helper/auth.token";
import { Map, View } from "ol";
import { Projection } from 'ol/proj.js';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import DoubleClickZoom from 'ol/interaction/DoubleClickZoom';
import {Fill, RegularShape, Stroke, Style} from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from 'ol/geom/Polygon';
import { getCenter } from 'ol/extent';
import { Row, Col, Button, message, Spin, Radio } from 'antd';
import { ongoingMotionPreviewAction, updateDeviceIP, updateNumberOfSlots, updateLiveModePreviewVectorLayer, updateDoDropDistanceAF, updateLiveModeAction, updateVisitedArea, updatePreviewImageExtent } from '../../../action/admin.state.action';
import {updateLiveView, updateLiveViewFullScreen} from "../../../action/liveview.action";
import { updateLiveView as updateLivePreview } from "../../../action/livemaincamerapreview.action";
import { liveModeLoadingMessages } from "../../../utils/const";
import { union } from "@turf/turf";

import "../../../asset/style/manualmode/preview_app.css";

class PreviewAppLiveMode extends Component {

    constructor(props) {
        super(props);

        let url = window.location.href.split('?')[1];
        let slot_id = -1;
        if (url != undefined) {
            let partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                let key = part.split('=')[0];
                let value = part.split('=')[1];
                if (key === 'slot_id') {
                    slot_id = parseFloat(value);
                }
            });
        }

        this.state = {
            initError: false,
            errorMessage: '',
            deviceIP: '',
            numberOfSlots: 0,
            slotID: slot_id,
            clickActionGoing: false,
        }

        this.canvasRef = React.createRef();

        this.image = new Image();

        this.image.onload = this.onloadRunner;

        this.onloadRunner = this.onloadRunner.bind(this);

    }

    componentDidMount = () => {
        this.getNumberOfSlots();
    }

    mainLightOn = (e) => {
        this.props.dispatch(updateLiveModeAction(true, liveModeLoadingMessages.MAIN_LIGHT_ON));
        let partsOfUrl = "api~stage~switch_on_main_light";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                }
                else {
                    console.log(response);
                    message.error("Not able to switch main light on!!", 2.5);
                }
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to switch main light on!!", 2.5);
                this.props.dispatch(updateLiveModeAction(false, liveModeLoadingMessages.MAIN_LIGHT_ON));
            })
    }

    onloadRunner = () => {
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
        this.mainLightOn();
        let extent = [0, 0, this.image.width, this.image.height];
        this.props.dispatch(updatePreviewImageExtent(extent));
        let projection = new Projection({
            code: 'preview-image',
            units: 'pixels',
            extent: extent,
        });

        let stroke = new Stroke({color: '#7CFC00', width: 2});
        let fill = new Fill({color: 'red'});


        let iconStyle = new Style({
            image: new RegularShape({
                fill: fill,
                stroke: stroke,
                points: 4,
                radius: 10,
                radius2: 0,
                angle: 0,
            }),
        });
        
        let iconFeature = new Feature({
                geometry: new Point([])
        
            });
        
        let vectorSource = new VectorSource({
            features: [iconFeature]
        });
        
        let vectorLayer = new VectorLayer({
            source: vectorSource
        });

        this.props.dispatch(updateLiveModePreviewVectorLayer(vectorLayer));
        
        iconFeature.setStyle(iconStyle);

        let map = new Map({
            controls: [],
            layers: [
                new ImageLayer({
                    source: new Static({
                        url: '/dev-ssd/last_preview/rotated_uncropped.jpg',
                        projection: projection,
                        imageExtent: extent,
                    }),
                }), vectorLayer ],
            target: 'map',
            view: new View({
                    projection: projection,
                    center: getCenter(extent),
                    zoom: 2,
                    maxZoom: 8,
            }),
        });

        let dblClickInteraction;
        map.getInteractions().getArray().forEach(function(interaction) {
            if (interaction instanceof DoubleClickZoom) {
                dblClickInteraction = interaction;
            }
        });

        map.removeInteraction(dblClickInteraction);

        var self=this;

        map.on('dblclick', function (evt) {

            if(self.props.adminState.main_light) {
                let selectedlong = evt.coordinate[0];
                let selectedlat = evt.coordinate[1];

                if(!(selectedlong < 0 || selectedlong > self.image.width || selectedlat < 0 || selectedlat > self.image.height)) {
                    let message = '';

                    if(self.props.adminState.doDropDistanceAF) {
                        message = liveModeLoadingMessages.DROP_DISTANCE_AF;
                    } else if(self.props.adminState.doAutoFocus) {
                        message = liveModeLoadingMessages.AUTO_FOCUS;
                    } else {
                        message = liveModeLoadingMessages.MOVE;
                    }

                    self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, true));
                    self.props.dispatch(updateLiveModeAction(true, message));
                    let url = "/server/devices/" + self.props.currentState.deviceId + "/settings/preview_tuning/move_to_pixel_and_focus/?slot_id=" +
                    self.state.slotID + "&x=" + Math.trunc(selectedlat) + "&y=" + Math.trunc(selectedlong) + "&doDrop=" + self.props.adminState.doDropDistanceAF + 
                    "&doAF=" + self.props.adminState.doAutoFocus;
                    
                    axios.get(url, { headers: { Authorization: AuthHeader() } })
                        .then(response => {
                            if (response.status === 200) {
                                self.props.dispatch(updateDoDropDistanceAF(false));
                                self.getLiveImage();
                                self.getLivePreviewImage();
                                self.markArea(Math.trunc(selectedlat), Math.trunc(selectedlong), vectorLayer);
                            }
                            else {
                                console.log(response);
                                message.error("Not able to move to the specified position!!", 2.5);
                            }
                            self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
                            self.props.dispatch(updateLiveModeAction(false, message));
                            self.setState({
                                clickActionGoing: false, 
                            });
                        })
                        .catch(err => {
                            console.log(err);
                            message.error("Not able to move to the specified position!!", 2.5);
                            self.props.dispatch(ongoingMotionPreviewAction(self.props.adminState, false));
                            self.props.dispatch(updateLiveModeAction(false, message));
                            self.setState({
                                clickActionGoing: false, 
                            });
                        });
                } else {
                    message.error("Soft limits Hit!!", 2.5);
                }
            } else {
                message.error("Please switch on the main light.", 2.5);
            }
        });

        this.setState({
            map,
        });
    }

    markArea = (x, y, vectorLayer) => {
        let partsOfUrl = "api~stage~get_selected_area_coordinates";
        let url = `/server/scano/` + this.props.currentState.deviceId + `/` + partsOfUrl + "?slot_id=" +
            this.state.slotID + "&x=" + x + "&y=" + y;
        
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                let bounds = [
                    [response.data["point 0"][1], response.data["point 0"][0]], 
                    [response.data["point 0"][1], response.data["point 1"][0]], 
                    [response.data["point 2"][1], response.data["point 1"][0]], 
                    [response.data["point 2"][1], response.data["point 0"][0]], 
                    [response.data["point 0"][1], response.data["point 0"][0]], 
                ];
                let recentPointStyle = new Style({
                    stroke: new Stroke({
                        color: '#1c568f',
                    }),
                    fill: new Fill({
                        color: 'rgba(28, 86, 143, 0.5)'
                    })
                });

                let pointStyle = new Style({
                    fill: new Fill({
                        color: 'rgba(124, 252, 0, 0.5)'
                    })
                });

                let feature = new Feature({
                    geometry: new Polygon([bounds]),
                });

                feature.setStyle(recentPointStyle);

                let allFeatures = [feature];

                let previousBounds = [...this.props.adminState.areaVisited];

                if(previousBounds.length > 0) {

                    let currBound = previousBounds[0];

                    let mergedFeature = new Feature({
                        geometry: new Polygon([currBound]),
                    });
                    let format = new GeoJSON();

                    for(let i = 1; i < previousBounds.length; i++) {
                        currBound = previousBounds[i];

                        let currFeature = new Feature({
                            geometry: new Polygon([currBound]),
                        });
                        mergedFeature = format.readFeature(union(format.writeFeatureObject(mergedFeature), format.writeFeatureObject(currFeature)));
                    }
                        
                    mergedFeature.setStyle(pointStyle);

                    allFeatures.push(mergedFeature);
                }

                if(!previousBounds.includes(bounds)) {
                    previousBounds.push(bounds);
                }

                this.props.dispatch(updateVisitedArea(previousBounds));
                
                vectorLayer.setSource(new VectorSource({
                    features: allFeatures,
                    wrapX: false
                }));
            })
            .catch(err => {
                console.log(err);
            });
    }

    getLiveImage = () => {
        let url = '';
        let liveMode = cookie.loadAll().superuser !== 'true';
        if(this.props.liveView.fullscreenMode) {
            url = '/server/devices/' + this.props.currentState.deviceId + '/get_live_updated_image?scale=2.1&zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
        } else {
            url = '/server/devices/' + this.props.currentState.deviceId + '/get_live_updated_image?scale=2.5&zoom=' + this.props.liveView.zoom + '&x=' + this.props.liveView.x + '&y=' + this.props.liveView.y + '&liveMode=' + liveMode;
        }
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if(this.props.liveView.fullScreenMode) {
                this.props.dispatch(updateLiveViewFullScreen(response.data));
            } else {
                this.props.dispatch(updateLiveView(response.data));
            }
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    getLivePreviewImage = () => {
        let liveMode = cookie.loadAll().superuser !== 'true';
        let url = '/server/devices/' + this.props.currentState.deviceId + '/get_live_updated_image?scale=14&liveMode=' + liveMode;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.props.dispatch(updateLivePreview(response.data));
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    getNumberOfSlots = () => {
        this.setState({
            initError: false,
            errorMessage: '',
        });
        this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
        this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
        let url = "/server/devices/" + this.props.currentState.deviceId + "/manualmode/device_details";
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    this.setState({
                        deviceIP: response.data.device_ip,
                        numberOfSlots: response.data.number_of_slots,
                    });
                    this.props.dispatch(updateDeviceIP(response.data.device_ip));
                    this.props.dispatch(updateNumberOfSlots(response.data.number_of_slots));
                    this.getPreview();
                }
                else {
                    console.log(response);
                    message.error("Not able to get number of slots!!", 2.5);
                    this.setState({
                        initError: true,
                        errorMessage: 'Not able to get number of slots!!',
                    });
                }
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to get number of slots!!", 2.5);
                this.setState({
                    initError: true,
                    errorMessage: 'Not able to get number of slots!!',
                });
                this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, false));
                this.props.dispatch(updateLiveModeAction(false, 'Loading...'));
            })
    }

    changeSlot = (e) => {
        window.location.href = window.location.href.split("?")[0] + "?slot_id=" + e.target.value;
    }

    getPreview = () => {
        if(this.state.slotID >= 0) {
            this.props.dispatch(ongoingMotionPreviewAction(this.props.adminState, true));
            this.props.dispatch(updateLiveModeAction(true, 'Loading...'));
            this.image.src = "http://" + this.state.deviceIP + "/api/preview/uncropped_rotated?slot_id=" + this.state.slotID + '&time=' + Date.now();
        }
    }

    resetVisitedArea = () => {
        this.props.adminState.liveModePreviewVectorLayer.setSource(new VectorSource({
            features: [],
            wrapX: false
        }));
        this.props.dispatch(updateVisitedArea([]));
    }

    render() {

        let slotsButton = [];

        for(let i = 0; i < this.state.numberOfSlots; i++) {
            slotsButton.push(
                <Radio.Button value={i}>{i + 1}</Radio.Button>
            )
        }

        return (
            <Row className="app-parent overlayed-component preview-app-live-mode-width">
                <br />
                <div>
                    <Spin spinning={this.props.adminState.preview_app_action || this.props.adminState.motion_app_action}>
                        <Row>
                            <Col offset={1} span={7} style={{marginTop: 3}}>
                                <b>Select Slot: </b>
                            </Col>
                            <Col span={16}>
                            <Radio.Group value={this.state.slotID} onChange={this.changeSlot}  buttonStyle="solid" size="small">
                                {slotsButton}
                            </Radio.Group>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col offset={1}>
                                <div id="map" class="map"></div>
                            </Col>
                        </Row>
                        {this.state.slotID >= 0 ?
                            <div>
                                <br/>
                                <Row key="reset_visited_area">
                                    <Col offset={7}>
                                        <Button type="primary" size="small" onClick={this.resetVisitedArea}>
                                            Reset Visited Area
                                        </Button>
                                    </Col>
                                </Row>
                            </div> : null
                        }
                    </Spin>
                </div>
                <br />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        adminState: state.adminUrlReducer,
        liveView: state.liveViewReducer,
    }
}

export default connect(mapStateToProps)(PreviewAppLiveMode);
