export const RegionSelectionConstants = {
    NOT_STARTED : "not_started",
    GETTING_IMAGE : "getting_image",
    FETCHED_IMAGE : "fetched_image",
    SELECTION_DONE : "selection_done",
    CONFIRMED : "confirmed", 
    ERRORED_OUT : "errored_out", 
    CANCELLED : "cancelled",
    START_DROP_DISTANCE_SELECTION : "start_drop_distance_selection",
    START_FOCUS_POINTS_SELECTION : "start_focus_points_selection"
}