export const DashboardConstants = {
    CHANGE_PAGE: "CHANGE_PAGE", 
    CHANGE_TOTAL_NUMBER_OF_PAGES: "CHANGE_TOTAL_NUMBER_OF_PAGES", 
    CHANGE_TOTAL_SLIDES: "CHANGE_TOTAL_SLIDES", 
    SET_BUSY: "SET_BUSY", 
    SET_IDLE: "SET_IDLE", 
    CLOSE_DRAWER: "CLOSE_DRAWER", 
    OPEN_DRAWER: "OPEN_DRAWER", 
    INIT_TAGS: "INIT_TAGS", 
    ADD_TAG: "ADD_TAG"
} 


