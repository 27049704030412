export const convertMapStateToUrlParams = (mapState) => {
	return `slideId=${mapState.slideId}&x=${mapState.x}&y=${mapState.y}&z=${mapState.z}&r=${mapState.r}&digitalZoomStatus=${mapState.digitalZoomStatus}`;
}

export const convertGammaStateToUrlParams = (gammaState) => {
	return `sync=${gammaState.sync}&activeMapId=${gammaState.activeMapId}`;
}

export const getMapsStateFromUrl = () => {
	let urlPart = window.location.href.split('?')[1];
	if (!urlPart) return [];

	let slides = [];
	urlPart.split('#').forEach(params => {
		let slide = {};
		params.split('&').forEach(param => {
			let key = param.split('=')[0];
			let value = param.split('=')[1];
			switch (key) {
				case 'slideId':
					slide.slideId = value;
					break;
				case 'x':
					slide.x = value ? parseFloat(value) : -1;
					break;
				case 'y':
					slide.y = value ? parseFloat(value) : -1;
					break;
				case 'z':
					slide.z = value ? parseFloat(value) : 0;
					break;
				case 'r':
					slide.r = value ? parseFloat(value) : 0;
					break;
				case 'digitalZoomStatus':
					slide.digitalZoomStatus = value ? value.toLowerCase() === 'true' : false;
					break;
				default:
					break;
			}
		});
		if (slide.slideId) slides.push(slide);
	});
	return slides;
}

export const getGammaStateFromUrl = () => {
	let urlPart = window.location.href.split('?')[2];
	if (!urlPart) return {};

	let gammaState = {};
	urlPart.split('&').forEach(param => {
		let key = param.split('=')[0];
		let value = param.split('=')[1];
		switch (key) {
			case 'sync':
				gammaState.sync = value ? value.toLowerCase() === 'true' : false;
				break;
			case 'activeMapId':
				gammaState.activeMapId = value ? value : '0';
				break;
			default:
				break;
		}
	});
	return gammaState;
}

