export const SlideActions = {
    ALL_SLIDES_UPDATE_SUCCESS: "ALL_SLIDES_UPDATE_SUCCESS",
    
    SLIDE_LIST_UPDATE_INITIATED: "SLIDE_LIST_UPDATE_INITIATED",
    SET_SLIDE_LIST_ITEM_FETCHED: "SET_SLIDE_LIST_ITEM_FETCHED", 
    SLIDE_LIST_UPDATE: "SLIDE_LIST_UPDATE",
    SLIDE_LIST_DELETE: "SLIDE_LIST_DELETE",
    SLIDE_LIST_UPDATE_FAILED: "SLIDE_LIST_UPDATE_FAILED", 
    DELETE_SLIDE: "DELETE_SLIDE", 
    START_POLLING: "START_POLLING",
    STOP_POLLING: "STOP_POLLING",
    UPDATE_POLLING: "UPDATE_POLLING", 
    FAILED_IN_TILING_POLLING_UPDATE: "FAILED_IN_TILING_POLLING_UPDATE", 
    START_TILING: "START_TILING", 
    TILING_ERRORED_OUT: "TILING_ERRORED_OUT",
    SLIDE_STATUS_UPDATED: "SLIDE_STATUS_UPDATED",
 }