import React, {Component} from  "react";
import { Divider, Button } from 'antd';
import CommentComp from "../neoviewer/apps/comments";


export class PopupComp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            device : []
        }
    }

    render() {
        return(
            <div id="popup" className="ol-popup hidden-mp">
                <Button style={this.closeButtonStyle} id="popup-closer" className="ol-popup-closer"></Button>
                <div id="popup-content">
                <h3 style={{textAlign : 'center ',fontWeight: 'bold', float: 'right',color: 'black',fontSize: '.7rem',marginTop: '3%'}}>Annotation Details</h3>
                <Divider/>
                <h4 id ='measure'>{}</h4>
                <Divider/>
                <CommentComp/>
                </div>
            </div>
        )
    }

    closeButtonStyle = {
        height: "1%",
        paddingRight: "0px",
        paddingLeft: "0px",
        width: "1%",
        marginBottom: "0px",
        paddingBottom: "0px",
        paddingTop: "0px",
        marginLeft: "12px",
        marginTop: "0px" ,
        background: "none", 
        color: "blue", 
        border: "none"

    }
}
