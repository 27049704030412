import React, {Component} from "react";
import {connect} from 'react-redux';
import {BottomNavigation, BottomNavigationAction, Box, Grid, Stack} from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Typography from "@mui/material/Typography";
import {getAnnotationsFeature, styleFunction} from '../utils/annotations_app_utils'
import Select from "ol/interaction/Select.js";
import {click, pointerMove} from 'ol/events/condition.js';
import {reloadMagicToolData, updateCurrentLevel} from "../../../action/magic_tool.action";
import {getEyeCheckbox} from "../components/components";
import {checkAppPermission} from "../utils/gammaScanUtils";
import {getAllLayers} from "../utils/layers_info";
import {removeAllSelectInteractions, removeAllVectorLayers} from "../utils/map_utils";
import {deleteAnnotation} from "../../../action/maps.state.action";

class Layers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openLayersPanel: false,
        }
        this.initVectorFlag = true;
        this.initState();
    }

    initState() {
        if (this.activeMapId && this.activeMapId !== this.props.activeMapId)
            this.removeSelectInteractions();

        this.activeMapId = this.props.gammaState.activeMapId;
        this.mapState = this.props.mapsState[this.activeMapId];
        this.slideState = this.mapState.slideState;
        this.annotationState = this.mapState.annotationState;
        this.deepBioState = this.mapState.deepBioState;
    }

    componentDidMount() {
        this.initialiseVectorLayersAndStore();
        document.addEventListener("keydown", this.keyDownListener, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyDownListener, false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // TODO: move it to slidemap
        if (prevProps.lastMapCount !== this.props.lastMapCount || prevProps.activeMapId !== this.activeMapId)
            this.initVectorFlag = true;

        if (this.initVectorFlag && this.slideState) {
            this.initVectorFlag = false;
            this.initialiseVectorLayersAndStore();
        }

        let prevAnnotationState = prevProps.mapsState[this.activeMapId].annotationState;
        if (this.annotationState && (prevAnnotationState || {}).annotations !== this.annotationState.annotations) {
            this.slideState.slidemap.getLayers().forEach(layer => {
                if (layer.values_.drawer !== undefined)
                    layer.setSource(
                        new VectorSource({
                            features: getAnnotationsFeature(this.annotationState.annotations.filter(
                                annotation => annotation.anno_drawer === layer.values_.drawer)),
                            wrapX: false,
                        })
                    );
            });
            // [Priyanshu] - if selected annotation is deleted, remove the selection feature
            if (this.selectFeatureInteraction &&
                !this.annotationState.annotations.map(annotation => annotation.id).includes(this.selectedAnnotationId))
                this.selectFeatureInteraction.getFeatures().clear();
        }

        if (!prevProps.magicToolData.reload && this.props.magicToolData.reload) {
            let features = this.annotationState.annotations.filter(annotation => annotation.anno_drawer === 0)
                .map(annotation => annotation.feature);
            let start = this.props.magicToolData.start_point;
            let current = this.props.magicToolData.curr_point;
            let delta = Math.abs(Math.pow((current[0]-start[0]),2)+Math.pow((current[1]-start[1]),2))
            let index = parseInt(delta/150)
            index = Math.min(index, Math.max(this.props.magicToolData.unsaved_magic_annotations.length-1,0))
            let temp_features = this.props.magicToolData.unsaved_magic_annotations[index];
            let all_feats = features
            if(temp_features !== undefined) {
                all_feats = all_feats.concat(temp_features);
            }
            this.annotationState.vector.setSource(
                new VectorSource({
                    features: all_feats,
                    wrapX: false
                })
            );
            this.props.dispatch(reloadMagicToolData(false));
            this.props.dispatch(updateCurrentLevel(index));
        }
    }

    keyDownListener = (event) => {
        if (event.target.tagName === 'INPUT' || event.shiftKey || event.ctrlKey || event.altKey)
            return;
        let annotation;
        switch (event.key) {
            case "Escape":
                if (this.selectFeatureInteraction)
                    this.selectFeatureInteraction.getFeatures().clear();
                break;
            case "Delete":
                if (this.selectedAnnotationId)
                    annotation = this.annotationState.annotations.find(annotation =>
                        annotation.id === this.selectedAnnotationId);
                this.props.dispatch(deleteAnnotation(this.props.activeMapId, annotation));
                break;
            default:
                break;
        }
    }

    // TODO: Move it to slidemap
    initialiseVectorLayersAndStore = () => {
        removeAllVectorLayers(this.slideState.slidemap);
        removeAllSelectInteractions(this.slideState.slidemap);
        let layers = getAllLayers().filter(layer => checkAppPermission(layer, this.props.gammaState));
        let vectors = layers.map(layer => {
            let vector = new VectorLayer({
                source: new VectorSource({}),
                ...layer,
            });
            let x_fields = this.slideState.slide_data.x_fields;
            let y_fields = this.slideState.slide_data.y_fields;
            let zIndex = (x_fields * 10000) + y_fields + 1000000000;
            vector.setZIndex(zIndex);
            this.slideState.slidemap.addLayer(vector);
            this.props.dispatch(layer.store(this.activeMapId, vector));
            if(layer.loadDataOnRender)
                this.props.dispatch(layer.dataLoad(this.activeMapId, this.slideState.slide_data.id));
            vector.setStyle(styleFunction);
            layer.vector = vector;
            return vector;
        });
        this.selectFeatureInteraction = new Select({
            condition: click,
            layers: vectors.filter(vector => vector.values_.onFeatureSelect),
            wrapX: false,
            hitTolerance: 5,
        });

        this.selectFeatureInteraction.on("select", e => {
            if (e.selected[0] && (!e.deselected[0] || e.selected[0].getId() !== e.deselected[0].getId() ||
                e.selected[0].getId() !== this.selectedAnnotationId)) {
                    this.selectedAnnotationId = e.selected[0].getId();
                    Object.values(e.target.featureLayerAssociation_).forEach(layer =>
                        layer.values_.onFeatureSelect(this.activeMapId, e.selected[0]));
            }
            else this.selectFeatureInteraction.getFeatures().clear();
        });
        this.slideState.slidemap.addInteraction(this.selectFeatureInteraction);
        this.hoverFeatureInteraction = new Select({
            condition: pointerMove,
            layers: vectors.filter(vector => vector.values_.onFeatureHover),
            wrapX: false,
            hitTolerance: 5,
        });
        this.slideState.slidemap.addInteraction(this.hoverFeatureInteraction);
    }

    removeSelectInteractions = () => {
        this.slideState.slidemap.getInteractions().forEach(interaction => {
            if (interaction instanceof Select)
                this.slideState.slidemap.removeInteraction(interaction);
        });
    }

    handleVectorLayerChange = (e, vector) => {
        let slidemap = this.slideState.slidemap;
        if(e.target.checked)
            slidemap.addLayer(vector);
        else
            slidemap.removeLayer(vector);
    }

    render() {
        this.initState();

        if (!this.slideState) return <div/>;

        let layersOverlayComponent;
        if (this.state.openLayersPanel) {
            layersOverlayComponent = <div>

                <Box sx={{
                    height: '20vh', width: '10vw', border: '5px', bgcolor: "rgba(0,60,136,0.8)",
                    display: "flex", overflowY: 'scroll'
                }}>
                    <Typography sx={{width: '100%'}}>
                        <Stack direction="column" spacing={2} justifyContent={"flex-start"} marginTop={1} marginBottom={1}>
                            {this.slideState.slidemap.getLayers().array_.filter(layer => layer instanceof VectorLayer)
                                .map(vector =>
                                    <Grid container sx={{height:'20px'}} sm={12}>
                                        <Grid item paddingX={1}>
                                            {getEyeCheckbox(undefined, true,
                                                e => this.handleVectorLayerChange(e, vector))}
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{marginTop:'7px'}}>
                                                {vector.values_.name}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Stack>
                    </Typography>

                </Box>
            </div>
        }
        return(
            <Stack direction={"column-reverse"} alignItems={"flex-end"} >
                <BottomNavigation sx={{borderRadius:0, bgcolor:"rgba(0,60,136,0.8)"}} value={this.state.app}
                                  onChange={() => this.setState({openLayersPanel : !this.state.openLayersPanel,})}>
                    <BottomNavigationAction
                        icon={this.state.openLayersPanel ? <LayersIcon color="secondary" /> : <LayersIcon />}
                        sx={{padding: 0}} />
                </BottomNavigation>
                {layersOverlayComponent}
            </Stack>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gammaState: state.gammaStateReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        lastMapCount: state.gammaStateReducer.lastMapCount,
        mapsState: state.mapsStateReducer,
        magicToolData: state.MagicToolReducer,
        trigger: state.triggerReducer,
    }
}

export default connect(mapStateToProps)(Layers);
