import {CLEAR_TRIGGER, TRIGGER} from "../actionTypes/triggers.constant";

export const trigger = (trigger) => ({
    type: TRIGGER,
    trigger,
});

export const clear = (key) => ({
    type: CLEAR_TRIGGER,
    key,
});

export const openApp = id => trigger({openApp: id});

export const expandAnnotation = id => trigger({expandAnnotation: id});
