import React from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`} style={{overflowY:'overlay'}} {...other}>
        {value === index &&
            <Box sx={{ p:1.5}}>
                {children}
            </Box>
        }
        </div>;
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export const a11yProps = (index) => ({
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
});
