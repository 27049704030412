export const SearchAlertConstant = {
    SEARCH_INITIATED : "SEARCH_INITIATED",
    SEARCH_SUCCESS : "SEARCH_SUCCESS",
    SEARCH_FAILED : "SEARCH_FAILED", 
    UPDATE_URL_STATE : "UPDATE_URL_STATE", 
    UPDATE_TAGS: "UPDATE_TAGS",
    UPDATE_SEARCHES: "UPDATE_SEARCHES", 
    STARRED_ONLY: "STARRED_ONLY", 
    NO_FILTER_ON_TYPE: "NO_FILTER_ON_TYPE", 
    CHANGE_PAGE: "CHANGE_PAGE"
} 


