import React, {Component} from 'react';
import { EditOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Row, Input, Divider, Table, message } from 'antd';
import {connect} from 'react-redux'
import { updateSlide } from '../../action/slide.update.action';
import EditableTagGroup from './viewer.tags'
import axios from 'axios';
import cookie from "react-cookies";
import { Link } from 'react-router-dom';
import { getPrefixedUrl } from '../../utils/utils'

class SlideInfoComp extends Component {
    constructor(props){
        super(props);
        this.state = {
            data :  this.props.slide_data,
            edit : false,
            name : this.props.slide_data.name,
            specimen_type :this.props.slide_data.specimen_type,
            case_details : (this.props.slide_data.case_details === null || this.props.slide_data.case_details === "")?"NIL":this.props.slide_data.case_details,
            scan_time: 0.0,
            slide_meta: {}, 
            slide_time: new Date(this.props.slide_data.date_time)
        }
        this.editFields = this.editFields.bind(this)
        this.saveFields = this.saveFields.bind(this)
        this.onChangeCaseDetails = this.onChangeCaseDetails.bind(this)
        this.onChangeName = this.onChangeName.bind(this)
        this.onChangeSpecimenType = this.saveFields.bind(this)
        console.log(this.state.slide_time.toLocaleString("en-US", {timeZone: "Asia/Kolkata"}));
    }

    fetchScanMeta = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/slide_meta.json');
        axios.get(url)
        .then(response => {
            this.setState({
                slide_meta: response.data
            });
        })
        .catch(err => {
          console.log("Failed request", err);
        });
    }

    fetchAutofocusMetrics = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/autofocus_metrics.json', this.state.data);
        axios.get(url)
        .then(response => {
            this.setState({
                autofocus_metrics: response.data
            });
        })
        .catch(err => {
          console.log("Failed request", err);
        });
    }

    fetchScanMetrics = () => {
        let url = getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/scan_metrics.json', this.state.data);
        axios.get(url)
        .then(response => {
            this.setState({
                scan_metrics: response.data
            });
        })
        .catch(err => {
          console.log("Failed request", err);
        });
    }

    fetchMetas = () => {
        // this.fetchScanMeta();
        // this.fetchAutofocusMetrics();
        // this.fetchScanMetrics();
    }

    componentDidMount = () => {
        if (cookie.loadAll().is_staff === "true") {
            this.fetchMetas();
        }
    }

    redoStitching = () => {
        axios.get('/server/redo_stitching/?morphle_id=' + this.state.data.morphle_id)
        .then(response => {
            message.success("Scheduled slide for Stitching")
        })
        .catch(err => {
            message.error("Could not schedule slide for Stitching")
        })
    }

    editFields(){
        this.setState({
            edit : true,
            tagedit : true
        })
    }


    saveFields(){
        let val = {"morphle_id" : this.props.slide_data.morphle_id,"path": this.props.slide_data.path,"date": this.props.slide_data.date,"specimen_type" : this.props.slide_data.specimen_type,"name" : this.state.name, "case_details" : this.state.case_details}
        this.props.dispatch(updateSlide(this.props.slide_data.id ,val ))
        this.setState({
            edit : false,
            tagedit : false
        })
    }

    onChangeName(e){
        this.setState({
            name : e.target.value
        })
    }

    onChangeSpecimenType(e){
        this.setState({
            specimen_type : e.target.value
        })
    }

    onChangeCaseDetails(e){
        this.setState({
            case_details : e.target.value
        })
    }

    render() {

        const columns = [
            {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
            render: text => <b>{text}</b>,
            },
            {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            }
        ];

        let slide_info = [];
        
        if (this.state.slide_meta.scan_speed !== undefined) {
            slide_info.push({
                key: 'Scan Speed',
                value: this.state.slide_meta.scan_speed,
            });
        }

        if (cookie.loadAll().is_staff === "true") {
            slide_info.push(
                {
                key: 'Total Scan Time',
                value: (this.state.slide_meta.scan_time_raw/60000).toFixed(2) ,
                }, 
                {
                key: 'Area of Box Scanned',
                value: parseFloat((this.state.slide_meta.scan_area_mm2 * 100 / this.state.slide_meta.scannable_percentage).toFixed(2)),
                }
            )
        }
        slide_info.push(
            {
                key: 'WBC Classifier',
                value: <a href={"/classifier/"+this.props.slide_data.morphle_id+"/blood/wbc5/"} target="_blank" rel="noopener noreferrer"> See classified WBCs </a>
            }
        );
        if (cookie.loadAll().superuser === "true") {
            slide_info.push(
                {
                    key: 'Scan Time Without Rescan',
                    value: ((this.state.slide_meta.scan_time_raw - this.state.slide_meta.total_rescan_time_raw)/60000).toFixed(2) ,
                }, 
                {
                    key: 'Morphle ID', 
                    value: this.props.slide_data.morphle_id
                },
                {
                    key: 'Estimated Time for 15x15', 
                    value: (this.state.slide_meta['estimated_time_for_15x15 (225mm2)_raw']/60000).toFixed(2)
                }, 
                {
                    key: 'Scan Area (mm2)', 
                    value: this.state.slide_meta.scan_area_mm2
                }, 
                {
                    key: 'Total Focus Time', 
                    value: ((this.state.slide_meta.total_focus_time_raw + this.state.slide_meta.rescan_focus_time_raw)/60000).toFixed(2)
                }, 
                {
                    key: 'Total Translation Time', 
                    value: ((this.state.slide_meta.total_translation_time_raw + this.state.slide_meta.rescan_translation_time_raw)/60000).toFixed(2)
                }, 
                {
                    key: 'Total Compensation Time', 
                    value: (this.state.slide_meta.total_compensation_time_raw/60000).toFixed(2)
                },
                {
                    key: 'Total Cleanup Time', 
                    value: ((this.state.slide_meta.cleanup_time)/60000).toFixed(2)
                }, 
                {
                    key: 'Main Scan Focus Time', 
                    value: ((this.state.slide_meta.total_focus_time_raw)/60000).toFixed(2)
                }, 
                {
                    key: 'Main Scan Translation Time', 
                    value: (this.state.slide_meta.total_translation_time_raw/60000).toFixed(2)
                },  
                {
                    key: 'Total Rescan Time', 
                    value: (this.state.slide_meta.total_rescan_time_raw/60000).toFixed(2)
                }, 
                {
                    key: 'Rescan Translation Time', 
                    value: (this.state.slide_meta.rescan_translation_time_raw/60000).toFixed(2)
                }, 
                {
                    key: 'Rescan Focus Time', 
                    value: (this.state.slide_meta.rescan_focus_time_raw/60000).toFixed(2)
                }, 
                {
                    key: 'Rescan Percent', 
                    value: 'See Old Viewer'
                },
                {
                    key: 'Scannable Percentage', 
                    value: this.state.slide_meta.scannable_percentage
                }, 
                {
                    key: 'Total Images', 
                    value: this.state.slide_meta.total_images
                }, 
                {
                    key: 'Swap Count', 
                    value: 'See Old Viewer'
                }, 
                {
                    key: 'Swap Percent', 
                    value: 'See Old Viewer'
                }, 
                {
                    key: 'Tilt Angle', 
                    value: this.state.slide_meta.tilt_angle
                }, 
                {
                    key: 'Tile Detection Time', 
                    value: this.state.slide_meta.tilt_detection_time
                },  
            )
        }

        let slide_info_card = [
            <Row key="scan-time">
                <h4 className='slide-info-mp' style={{textAlign : 'center', margin: "5px"}}>Scan Details</h4>
                <Divider />
                <Table className="slide-info-table scrollbar" columns={columns} dataSource={slide_info} pagination={false} showHeader={false}/>
            </Row>
        ];

        let slide_links_card = [
            cookie.loadAll().superuser === "true"?(
            <Row key={0}>
                <h4 className='slide-info-mp' style={{textAlign : 'center', margin: "5px"}}>Important Links</h4>
                <Divider />
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/slide_meta.json', this.state.data)}>Slide Meta</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/device_settings.json', this.state.data)}>Settings</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/autofocus_metrics.json', this.state.data)}>Autofocus Metrics</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/scan_metrics.json', this.state.data)}>Scan Metrics</a></Row>
                <Row><a href={getPrefixedUrl(this.state.data.bucket_name + "/" + this.state.data.path + 'meta/stage_metrics.json', this.state.data)}>Compensation Metrics</a></Row>
                <Row><a href={'/v' + this.state.data.viewer_flag + '/tissue/' + this.state.data.morphle_id}>Old Viewer</a></Row>
                <Row onClick={this.redoStitching}><a>Redo Stitching</a></Row>
            </Row>):<div></div>
        ]

        return (
            <div>
            <Row>
                <Col span={24} className="sidebar-card slide-info" style={{paddingBottom : "5%"}}>
                    <h3 className='slide-info-mp' style={{textAlign : 'center', margin: "5px" }}>Slide Information
                    <span style={{float :'right'}}>{!this.state.edit ? <EditOutlined className='icon-mp' onClick={this.editFields} /> : <SaveOutlined className='icon-mp' onClick={this.saveFields} />}</span></h3>
                    <Divider/>
                    <div className="info-content-mp" >
                        <Col sm={{ span: 24, offset: 0 }} mg={{ span: 24, offset: 0}} lg={{ span: 24, offset: 0 }}>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Name</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>{!this.state.edit ? <span>{this.state.name}</span> : <Input defaultValue={this.state.name} onChange={this.onChangeName} /> }</Col>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Scan Type</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>{this.state.specimen_type}</Col>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Case ID</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>{this.props.slide_data.case !==""? (<Link to={"/case/" + this.props.slide_data.case.id}>{this.props.slide_data.case.case_id}</Link>): "Not Assigned"}</Col>
                            <Col sm={{ span: 11, offset: 1 }} mg={{ span: 11, offset: 1}} lg={{ span: 11, offset: 1 }} style={{fontWeight : 700}}>Tags</Col>
                            <Col sm={{ span: 12, offset: 0 }} mg={{ span: 12, offset: 0}} lg={{ span: 12, offset: 0 }} style={{fontWeight : 300}}>
                                <EditableTagGroup tag={this.props.slide_data.tags} 
                                    edit={this.state.tagedit} 
                                    morphle_id={this.props.slide_data.morphle_id} 
                                    path={this.props.slide_data.path}
                                    date={this.props.slide_data.date}
                                    specimen_type={this.props.slide_data.specimen_type}
                                    name={this.state.name}
                                    case_details={this.state.case_details}
                                    slide_id={this.props.slide_data.id}
                                />
                            </Col>
                            <Divider style={{top: "5px"}}/>
                            {slide_links_card}
                            {slide_info_card}
                        </Col>
                    </div>
                </Col>
            </Row>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus
    }
}

export default connect(mapStateToProps)(SlideInfoComp, EditableTagGroup)
