import React from  "react";
import AppIcon from '../utils/app_icon';
import * as keys from './apps/app_keys';
import { zoomAppKey, slideInfoAppKey } from './apps/app_keys';
import cookie from "react-cookies";
import { slideViewerType } from "../../utils/const";
// import { previewControlsKey } from './apps/app_keys';

const appiconify = (key, content, allAppsState) => {
    return (
        allAppsState.apps_installed[key.id + "_installed"] === undefined || allAppsState.apps_installed[key.id + "_installed"] === true ? 
            <AppIcon
                    key={key.id}
                    appKey={key} 
                    viewerType={slideViewerType.NORMAL_SLIDE_VIEWER}
                    content={content}/> : undefined
    )
}

export const getAllAppIcons = (allAppsState) => {

    let iconBoard = [];

    for (let app in keys) {
        if(!((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) ||
            (((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) && (keys[app].id == zoomAppKey.id ||
                /*keys[app].id == previewControlsKey.id || */keys[app].id == slideInfoAppKey.id))) {
            let appDiv = appiconify(keys[app], keys[app].icon, allAppsState);
            if (appDiv != undefined) {
                iconBoard.push(appDiv);
            }
        }
    }

    return iconBoard;
}
