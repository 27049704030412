import React, {Component} from  "react";
import {connect} from "react-redux";
import {Grid, Tooltip} from "@mui/material";
import {getPrefixedUrl} from "../../../utils/utils";

class Barcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRotation: 0,
        }
    }

    rotate = () => {
        let rotation = this.state.currentRotation
        rotation = (rotation+90)%360
        this.setState({
            currentRotation:rotation
        })
        document.getElementById('barcode-img').style.transformOrigin="50% 50%"
        document.getElementById('barcode-img').style.transform=`rotate(${rotation}deg)`
    }

    render() {
        this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
        if (!this.slideState) return <div />;

        return <Grid sx={{textAlign:'center'}}>
            <Tooltip title={"Click to Rotate 90"}>
                <img id='barcode-img' alt={'barcode'}
                 src = {getPrefixedUrl(`${this.slideState.slide_data.path}pre-processed/barcode_crop.jpg`,
                     this.slideState.slide_data)}
                     style={{height:'18.5vh', width: 'auto', cursor: 'pointer'}}
                     onClick={this.rotate}
                />
            </Tooltip>
        </Grid>
    }
}

const mapStateToProps = (state) => {
    return {
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(Barcode);
