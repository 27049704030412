import React from 'react';
import { Input, Select, Modal, Row, Col, Switch } from 'antd';
import { connect } from 'react-redux'
import { changeOwnerFilteredSlides, changeSlideOwner } from '../../action/slides.action';
import { loadGroups } from "../../action/users.action";
import axios from 'axios';
import { message } from 'antd';
import { AuthHeader } from '../../helper/auth.token';
const Option = Select.Option;


class ChangeSlideOwner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedType: -1,
            selectedOwner: -1,
            createnewOwner: false,
            newOwnerName: '',
            errorOwnerName: false,
        }
    }

    handleOk = () => {
        if (this.state.createnewOwner) {
            if (this.state.newOwnerName.length > 0) {
                let url = `/api/create_new_group/?new_owner_name=` + this.state.newOwnerName;
                axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
                    .then(response => {
                        if (response.status === 200) {
                            if (!this.props.filteredSlides) {
                                this.props.selectedRowKeys.map((value, index) => {
                                    this.props.dispatch(changeSlideOwner(this.props.allSlides[value], response.data.result.id));
                                });
                            } else {
                                this.props.dispatch(changeOwnerFilteredSlides(this.props.urlState, response.data.result.id, this.props.total));
                            }
                            this.props.dispatch(loadGroups());
                        }
                    })
                    .catch(err => {
                        message.error("Not able to create new owner.", 2.5);
                        console.log(err);
                    })
            } else {
                message.error("Some error occured. Not able to change owner.", 2.5);
            }
        } else {
            if (this.state.selectedOwner != -1) {
                if (!this.props.filteredSlides) {
                    this.props.selectedRowKeys.map((value, index) => {
                        this.props.dispatch(changeSlideOwner(this.props.allSlides[value], this.state.selectedOwner));
                    });
                } else {
                    this.props.dispatch(changeOwnerFilteredSlides(this.props.urlState, this.state.selectedOwner, this.props.total));
                }
            } else {
                message.error("Some error occured. Not able to change owner.", 2.5);
            }
        }
        this.handleCancel();
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            selectedType: -1,
            selectedOwner: -1,
            createnewOwner: false,
            newOwnerName: '',
            errorOwnerName: false,
        });
    }

    slideNameChange = (e) => {
        this.setState({
            selectedSlideNumber: e.target.value,
        });
    }

    getAllSelectedSlides = (selectedIds) => {
        return <div className={selectedIds.length > 3 ? "scroll-slide-list-modal" : ""}>
            {selectedIds.map((item, index) => (
                this.props.allSlides[item] ?
                    <div>
                        <Row>
                            <Col span={9} offset={1}>
                                <Input style={{ color: "black", cursor: "default" }}
                                    value={this.state.selectedCaseID}
                                    disabled
                                />
                            </Col>
                            <Col span={13} offset={1}>
                                <Input value={this.props.allSlides[item].name}
                                    style={{ color: "black", cursor: "default" }}
                                    disabled
                                />
                            </Col>
                        </Row>
                        <br />
                    </div>
                    : null
            ))}
        </div>
    }

    getOwnerOptions = () => {
        let owner_options = Object.keys(this.props.allGroups).map((key) => {
            let value = this.props.allGroups[key];
            return <Option key={value.id} value={value.id}>{value.name}</Option>
        });
        owner_options.unshift(<Option value={-1} key={-1}>Select Owner</Option>);
        return owner_options;
    }

    handleOwnerChange = (owner) => {
        this.setState({
            selectedOwner: owner,
        });
    }

    toggleNewOwner = (checked) => {
        this.setState({
            createnewOwner: checked,
            newOwnerName: '',
            selectedOwner: -1,
        });
    }

    changeNewOwnerName = (event) => {
        let index = -1;
        Object.keys(this.props.allGroups).map((key) => {
            let value = this.props.allGroups[key];
            if (event.target.value.toLowerCase() == value.name.toLowerCase()) {
                index = key;
            }
        });
        if (index == -1) {
            this.setState({
                newOwnerName: event.target.value,
                errorOwnerName: false,
            });
        } else {
            this.setState({
                newOwnerName: event.target.value,
                errorOwnerName: true,
            });
        }
    }

    render() {

        let getInfoMessage = (
            <div>
                <br />
                <Row style={{ marginLeft: 8 }}>
                    <div>Owner of total <b>{this.props.filteredSlides ? this.props.total : this.props.selectedRowKeys.length}</b> slides will be changed.</div>
                </Row>
            </div>
        )

        return (
            <Modal
                title="Change Owner"
                visible={this.props.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{
                    disabled: !((!this.state.createnewOwner && this.state.selectedOwner != -1) ||
                        (this.state.createnewOwner && this.state.newOwnerName.length > 0 && !this.state.errorOwnerName))
                }}
            >
                <div>
                    <Row>
                        <Col span={8} style={{ marginLeft: 8 }}>
                            <b>Create new Owner: </b>
                        </Col>
                        <Col span={6}>
                            <Switch checked={this.state.createnewOwner} onChange={this.toggleNewOwner} />
                        </Col>

                    </Row>
                    <br />
                    {!this.state.createnewOwner ?
                        <Row>
                            <Select
                                value={this.state.selectedOwner}
                                placeholder="Select Owner"
                                onChange={this.handleOwnerChange}
                                style={{ width: 250, marginLeft: 8 }}
                            >
                                {this.getOwnerOptions()}
                            </Select>
                        </Row> :
                        <Row>
                            <Input placeholder="Owner Name" value={this.state.newOwnerName} onChange={this.changeNewOwnerName}
                                style={{ width: 250, marginLeft: 8 }} />
                        </Row>
                    }
                    {this.state.errorOwnerName ?
                        <div style={{ color: "red", marginLeft: 8 }}>
                            Owner with this name already exists.
                        </div> : null
                    }
                    {getInfoMessage}
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginAlerts.loginStatus,
        urlState: state.searchReducer,
        allSlides: state.slidesReducer,
        allGroups: state.searchUserReducer.groups,
        total: state.dashboardReducer.total,
    }
}

export default connect(mapStateToProps)(ChangeSlideOwner);