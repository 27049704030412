import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React, { Component } from 'react';
import { initPreviewForScan } from "../../../action/preview_status.action";
import { connect } from "react-redux";


class LoadSlidesPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.previews == undefined && this.props.previews) {
            this.props.dispatch(initPreviewForScan());
        }
    }

    render() {
        return (
            <Row key={0} className="rounded-container" style={{height: '500px'}}>
                <Col className="scan-workflow-init" span={24}>
                    <Row style={{ fontSize: 20, paddingTop: 200 }} className="arial-font">
                        Please insert slides in the cassette and place in the slot.
                    </Row>
                    <br></br>
                    <br></br>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Col span={4} style={{ cursor: "pointer" }}>
                            <Row style={{ color: "#26A65B" }}>
                                <CheckCircleOutlined style={{ fontSize: 50 }} onClick={(e) => {e.stopPropagation(); this.props.handleProgressChange(1)}}/>
                            </Row>
                            <Row className="arial-font">Done</Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        previews : state.previewStatusReducer,
    };
};

export default connect(mapStateToProps)(LoadSlidesPrompt);