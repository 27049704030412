import React, {Component} from "react";
import {connect} from "react-redux";
import {Grid3x3} from "@mui/icons-material";
import {morphleBaseTheme1} from "../themes";
import {updateGridState, updateGridStatus} from "../../../action/maps.state.action";
import {Stack, Tooltip} from "@mui/material";
import GridReference from "../../../custom_libs/gridReference";
import axios from "axios";
import {AuthHeader} from "../../../helper/auth.token";

class GridOverlay extends Component {

	initState = () => {
		this.activeMapId = this.props.activeMapId;
		this.mapState = this.props.mapsState[this.activeMapId];
		this.slideState = this.mapState.slideState;
		this.gridState = this.mapState.gridState;
	}

	componentDidMount = () => {
		if(((this.props.appState || {})['present_app'] || {}).code !== undefined) {
			let url = `/api/set_sync_browsing_grid/?sync_code=${this.props.appState['present_app'].code}&morphle_id=${this.slideState.slide_morphle_id}&gridColor=${this.gridState.color}&gridSize=${this.gridState.size}&gridWidth=${this.gridState.width}`;
			axios.get(url, { headers: { Authorization: AuthHeader() } })
				.then(response => {
				})
				.catch(err => {
					console.log("Failed Setting Key Value");
				});
		}
	}

	componentDidUpdate = (prevProps) => {
		let prevGridState = prevProps.mapsState[this.activeMapId].gridState;
		let gridValueChange = ((prevGridState.color !== this.gridState.color) ||
			(prevGridState.size !== this.gridState.size) || (prevGridState.width !== this.gridState.width));

		let timestampChange = (prevProps.urlState.grid_timestamp !== this.props.urlState.grid_timestamp);

		if(((this.props.appState || {})['present_app'] || {}).code !== undefined && gridValueChange) {
			let url = `/api/set_sync_browsing_grid/?sync_code=${this.props.appState['present_app'].code}&morphle_id=${this.slideState.slide_morphle_id}&gridColor=${this.gridState.color}&gridSize=${this.gridState.size}&gridWidth=${this.gridState.width}`;
			axios.get(url, { headers: { Authorization: AuthHeader() } })
				.then()
				.catch(err => {
					console.log("Failed Setting Key Value");
				});
		}

		if((this.props.urlState || {}).presentCode !== undefined && timestampChange) {
			let url = `/api/get_sync_browsing_grid/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.slideState.slide_morphle_id}`;
			axios.get(url, { headers: { Authorization: AuthHeader() } })
				.then(response => {
					try {
						if(response.data.grid_color !== undefined && response.data.grid_size !== undefined &&
							response.data.grid_width !== undefined) {
							this.props.dispatch(updateGridState(this.activeMapId, {
								color: response.data.grid_color,
								size: parseInt(response.data.grid_size),
								width: parseInt(response.data.grid_width),
							}));
						}
					} catch (err) {
						console.log(err);
					}
				})
				.catch(err => {
					console.log("Failed Getting Value");
				});
		}
	}

	toggleGrid = () => this.props.dispatch(updateGridStatus(this.activeMapId, !this.gridState.grid));

	drawGrid = () => {

		if (this.gridState.gridRef) {
			this.slideState.slidemap.removeControl(this.gridState.gridRef);
		}

		let extent = this.slideState.slidemap.getView().getProjection().getExtent();
		let height = extent[3];
		let width = extent[2];

		let gridEveryU = this.gridState.size;
		let gridCols = width / gridEveryU;
		let gridRows = height / gridEveryU;

		this.gridState.gridRef = new GridReference(
			{
				extent: extent,
				size: [gridCols, gridRows],
				target: document.querySelector(".options div"),
				property: "commune",
				color: '#' + this.gridState.color,
				width: this.gridState.width
			}
		);

		this.slideState.slidemap.addControl(this.gridState.gridRef);
	}

	render() {
		this.initState();
		if (!this.slideState) return <div />;

		if (this.gridState.grid)
			this.drawGrid();
		else if (this.gridState.gridRef)
			this.slideState.slidemap.removeControl(this.gridState.gridRef);

		return <Stack direction={"row"} justifyContent="space-between" alignItems='center'>
			<Tooltip title={"Grid"} placement={"bottom"}>
				{this.gridState.grid ?
					<Grid3x3 sx={{color: morphleBaseTheme1.palette.headBarIcon.secondary, cursor:'pointer'}}
					         onClick={this.toggleGrid}
					/> :
					<Grid3x3 sx={{color: morphleBaseTheme1.palette.headBarIcon.primary, cursor:'pointer'}}
					         onClick={this.toggleGrid}
					/>
				}
			</Tooltip>
		</Stack>
	}
}

const mapStateToProps = (state) => {
	return {
		urlState: state.viewerUrlReducer,
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(GridOverlay)
