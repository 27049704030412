import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { Button, Row, message, Col, Input, Switch, Spin } from 'antd';
import { globalUrlPrefix } from '../utils/const';
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import _ from 'lodash';
import { loadUsernames } from '../action/users.action';
import { getUnauthorizedComponent } from '../component/dashboard/slidelist_utils';
import '../asset/style/add_case.css';
var querystring = require('querystring');

const { Password } = Input;

class AddUserView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            username: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            isStaff: 0,
            isAudience: 0,
            userCheckFlag: false,
            errorFlag: false,
            errorMessage: '',
        }

        this.props.dispatch(loadUsernames());
    }

    changeUsername = (event) => {
        let index = this.props.allUsernames.findIndex((item) => event.target.value.toLowerCase() == item.toLowerCase());
        if (index == -1) {
            this.setState({
                username: event.target.value,
                userCheckFlag: false,
            });
        } else {
            this.setState({
                username: event.target.value,
                userCheckFlag: true,
            });
        }
    }

    changeFirstName = (event) => {
        this.setState({
            firstName: event.target.value
        });
    }

    changeLastName = (event) => {
        this.setState({
            lastName: event.target.value
        });
    }

    changeIsStaff = (checked) => {
        this.setState({
            isStaff: checked,
        });
    }

    changeIsAudience = (checked) => {
        this.setState({
            isAudience: checked,
        });
    }

    changePassword = (event) => {
        this.setState({
            password: event.target.value,
            errorFlag: false,
            errorMessage: '',
        });
    }

    changeConfirmPassword = (event) => {
        this.setState({
            confirmPassword: event.target.value,
            errorFlag: false,
            errorMessage: '',
        });
    }

    createUser = (e) => {
        this.setState({
            isFetching: true,
        });
        if (this.state.password.length < 8) {
            this.setState({
                errorFlag: true,
                errorMessage: 'Use 8 characters or more for your password.',
                confirmPassword: '',
                isFetching: false,
            });
            return;
        } else if (this.state.password != this.state.confirmPassword) {
            this.setState({
                errorFlag: true,
                errorMessage: 'Passwords didn\'t match. Please try again.',
                confirmPassword: '',
                isFetching: false,
            });
            return;
        }
        let val = {
            username: this.state.username,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            is_staff: this.state.isStaff,
            is_audience: this.state.isAudience,
            password: this.state.password,
        }

        let url = `/api/create_new_user/`;
        axios.post(url, querystring.stringify(val), { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    isFetching: false,
                });
                window.location.href = "/" + globalUrlPrefix + "/manageusers/";
            })
            .catch(err => {
                this.setState({
                    isFetching: false,
                });
                message.error("Failed to Create User. Contact Admin");
                console.log(err);
            });

    }

    render() {

        return (
            (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_user_actions ?
                <div>
                    <Spin spinning={this.state.isFetching}>
                        <Row>
                            <Col span={24} offset={0} className="all-slides">
                                <div className="heading-style main-heading-style">
                                    <b>Create User</b>
                                </div>
                                <div>
                                    <Row>
                                        <Col offset={2} pull={1}>
                                            <div>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Username</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        {this.state.userCheckFlag ?
                                                            <div className="errormessage">
                                                                User with this Username already exists.
                                                                    </div> : null
                                                        }
                                                        <Input placeholder="Username" value={this.state.username}
                                                            className={this.state.userCheckFlag ? "input-field-add-case errors-input" : "input-field-add-case"}
                                                            onChange={this.changeUsername} autoComplete="off"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>First Name</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Input placeholder="First Name" value={this.state.firstName}
                                                            className="input-field-add-case" onChange={this.changeFirstName} autoComplete="off" />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Last Name</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Input placeholder="Last Name" value={this.state.lastName} className="input-field-add-case"
                                                            onChange={this.changeLastName} autoComplete="off" />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Is Staff</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Switch checked={this.state.isStaff} onChange={this.changeIsStaff} />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Is Audience</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Switch checked={this.state.isAudience} onChange={this.changeIsAudience} />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Password</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Password placeholder="Password" value={this.state.password} className="input-field-add-case"
                                                            onChange={this.changePassword} autoComplete="new-password" />
                                                    </Col>
                                                </Row>
                                                <Row className="add-case-row">
                                                    <Col span={5} className="add-case-row-header">
                                                        <b>Confirm Password</b>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Password placeholder="Confirm Password" value={this.state.confirmPassword}
                                                            className="input-field-add-case" onChange={this.changeConfirmPassword} autoComplete="new-password" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                </div>

                                <div className="submit-row-add-case">
                                    <Row>
                                        <Col span={2}>
                                            <Button type="primary"
                                                onClick={this.createUser}
                                                disabled={!(!this.state.userCheckFlag && this.state.username.length > 0 && this.state.firstName.length > 0 &&
                                                    !this.state.errorFlag && this.state.password.length > 0 && this.state.confirmPassword.length > 0)}>
                                                Save
                                            </Button>
                                        </Col>
                                        {this.state.errorFlag ?
                                            <Col span={22}>
                                                <div className="error-message-general"> {this.state.errorMessage} </div>
                                            </Col> :
                                            null
                                        }
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                    </Spin>    
                </div>
                : <div>
                    {getUnauthorizedComponent()}
                </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        allSlides: state.slidesReducer,
        allIds: Object.keys(state.slidesReducer),
        numPages: state.dashboardReducer.numPages,
        urlState: state.searchReducer,
        allUsernames: state.searchUserReducer.usernames,
    }
}

export default connect(mapStateToProps)(AddUserView);
