import React, {Component} from  "react";
import { CSSTransition } from 'react-transition-group';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { Row, Col } from 'antd';

import "../../asset/style/utils/expandable.css"

export default class Expandable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showExpanded: false,
            showClosed: true
        }
    }

    setShowExpanded = (value) => {
        this.setState({
            showExpanded: value
        })
    }

    setShowClosed = (value) => {
        this.setState({
            showClosed: value
        })
    }

    render(){

        let expandedContent = <Row>
            <Col>
                <Row>
                    <CloseCircleTwoTone className="close-expandable-icon" twoToneColor="#de2323" /> 
                </Row>
                {this.props.expandedContent}
            </Col>
        </Row>

        return (
            <CSSTransition
                in={this.state.showExpanded}
                timeout={200}
                classNames={{
                    enter: "expandable-enter " + this.props.expandedClassName, 
                    enterDone: this.props.expandedClassName, 
                    exit: "expandable-exit " + this.props.closedClassName, 
                    exitDone: this.props.closedClassName
                }}
                onEnter={() => this.setShowClosed(false)}
                onExited={() => this.setShowClosed(true)}
            >   
                <div className={this.props.className + " overlayed-component"} onClick={() => this.setShowExpanded(this.state.showClosed)}>
                    {!this.state.showExpanded ? this.props.closedContent : undefined}
                    {this.state.showExpanded ? expandedContent : undefined}
                </div>
            </CSSTransition>
        )
    }
}