import { MainCameraPreviewConstant } from '../actionTypes/maincamerapreview.constant';

export const updateLiveView = (data) => ({
    type: MainCameraPreviewConstant.UPDATE_LIVE_MAIN_CAMERA_PREVIEW,
    data: data,
})

export const failedToUpdateLiveView = (err) => ({
    type: MainCameraPreviewConstant.FAILED_IN_LIVE_MAIN_CAMERA_PREVIEW_UPDATE,
    err: err,
})

export const startLiveView = () => ({
  type : MainCameraPreviewConstant.START_LIVE_MAIN_CAMERA_PREVIEW
})

export const stopLiveView = () => ({
  type : MainCameraPreviewConstant.STOP_LIVE_MAIN_CAMERA_PREVIEW
})