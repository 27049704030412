import { ScanApiConstant } from "../actionTypes/scan.constant";
import { Slot, scanSpeed, specimenType } from "../component/scanner/slots";
import { RegionSelectionConstants } from "../actionTypes/region_selection.constant";
import tray from "../component/scanner/tray";
import cookie from "react-cookies";

const InitialState = {
    scanProfiles: {
        '-1': {
            speed: 'FAST',
            objectiveType: 'FORTY_X',
            specimenType: 'BLOOD',
            zStackLevels: 10,
            stepSize: 1,
            actionOngoing: false,
            message: "",
        }
    }
}

export const previewStatusReducer = ( state = InitialState , action) => {
    const currentInfo = Object.assign({}, state[action.slot]);
    let scanProfiles = Object.assign({}, state.scanProfiles);
    switch(action.type){
        case ScanApiConstant.INIT_PREVIEW:
            return {
                ...state,
                [action.slot] : new Slot(action.defaultConfig)
            }
        case ScanApiConstant.TAKING_PREVIEW:
            currentInfo.status = RegionSelectionConstants.GETTING_IMAGE;
            return {
                ...state, 
                [action.slot] : currentInfo
            }
        case ScanApiConstant.TAKEN_PREVIEW:
            if (action.response.slideName == "unknown"){
                currentInfo.status = RegionSelectionConstants.GETTING_IMAGE;
                return {
                    ...state, 
                    [action.slot] : currentInfo
                }
            }
            currentInfo.status = RegionSelectionConstants.CONFIRMED;
            
            currentInfo.response = action.response
            if (currentInfo.slideName === "") {
                currentInfo.slideName = action.response.slideName;
            }
            currentInfo.region = undefined;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.REGION_SELECTION_DONE:
            currentInfo.status = RegionSelectionConstants.SELECTION_DONE;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SELECTION_CANCELLED:
            currentInfo.status = RegionSelectionConstants.FETCHED_IMAGE;
            currentInfo.region = undefined;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SELECTION_CONFIRMED:
            currentInfo.region = action.region;
            currentInfo.actualRegion = action.actualRegion;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SPEED_CHANGE:
            currentInfo.speed = action.speed;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.Z_STACK_LEVELS_CHANGE:
            currentInfo.zStackLevels = action.value;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.STEP_SIZE_CHANGE:
            currentInfo.stepSize = action.value;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SPEED_CHANGE_FOR_ALL_SLOTS:
            let currentSlots = Object.assign({}, state);
            for(let k in currentSlots) {
                currentSlots[k] = Object.assign({}, currentSlots[k], {speed: action.speed});
            }
            return currentSlots;
        case ScanApiConstant.NAME_CHANGE:
            currentInfo.slideName = action.name;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.ERRORED_WHILE_TAKING_PREVIEW:
        case ScanApiConstant.ERRORED_WHILE_CONFIRMING_SELECTION:
            currentInfo.status = RegionSelectionConstants.ERRORED_OUT;
            currentInfo.response = action.response
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SPECIMEN_TYPE_CHANGE:
            currentInfo.specimenType = action.specimenType;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.OBJECTIVE_TYPE_CHANGE:
            currentInfo.objectiveType = action.objectiveType;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SCAN_OUTPUT_FORMAT_CHANGE:
            currentInfo.scanOutputFormat = action.scanOutputFormat;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SET_DO_TILING_ONLY:
        case ScanApiConstant.UNSET_DO_TILING_ONLY:
            currentInfo.doTilingOnly = action.type === ScanApiConstant.SET_DO_TILING_ONLY;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SET_DO_TILING_AFTER_SCAN:
        case ScanApiConstant.UNSET_DO_TILING_AFTER_SCAN:
            currentInfo.doTilingAfterScan = action.type === ScanApiConstant.SET_DO_TILING_AFTER_SCAN;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.SPEED_CHANGE_FOR_TRAY:
            scanProfiles[action.tray] = scanProfiles[action.tray] || {};
            scanProfiles[action.tray].speed = action.speed
            return {
                ...state,
                scanProfiles
            }
        case ScanApiConstant.SPECIMEN_CHANGE_FOR_TRAY:
            scanProfiles[action.tray] = scanProfiles[action.tray] || {};
            scanProfiles[action.tray].specimenType = action.specimenType
            return {
                ...state,
                scanProfiles
            }
        case ScanApiConstant.OBJECTIVE_CHANGE_FOR_TRAY:
            scanProfiles[action.tray] = scanProfiles[action.tray] || {};
            scanProfiles[action.tray].objectiveType = action.objectiveType
            return {
                ...state,
                scanProfiles
            }
        case ScanApiConstant.UPDATE_DROP_DISTANCE_POINT:
            if ((cookie.loadAll().superuser === "true") || ((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).show_focus_points_selection_workflow)) {
                currentInfo.status = RegionSelectionConstants.START_DROP_DISTANCE_SELECTION;
            } else {
                currentInfo.status = RegionSelectionConstants.CONFIRMED;
            }
            currentInfo.dropDistancePoint = action.dropDistancePoint
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_DROP_DISTANCE_POINT_ONLY:
            currentInfo.dropDistancePoint = action.dropDistancePoint
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_DROP_DISTANCE_POINT_LENGTH:
            currentInfo.dropDistancePointLength = action.dropDistancePoint.length
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_FOCUS_POINTS:
            currentInfo.focusPoints = action.focusPoints
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_STATUS:
            currentInfo.status = action.status
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_ACTION_ONGOING:
            currentInfo.actionOngoing = action.actionOngoing;
            currentInfo.message = action.message;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        case ScanApiConstant.UPDATE_CANVAS_SIZE:
            currentInfo.size = action.size;
            return {
                ...state, 
                [action.slot]: currentInfo
            }
        default :
            return state
    }
}
