import React, { Component } from 'react';
import { Row, Col, Tooltip, Button } from 'antd';
import { connect } from "react-redux";
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from '../../../helper/auth.token';
import "../../../asset/style/scanner/scanner.css"
import "../../../asset/style/manualmode/live_camera_preview_app.css";
import { sagaMiddleware } from "../../../helper/store";
import {updateLiveView} from "../../../action/livemaincamerapreview.action";
import { updateLiveView as updateMainLiveView, updateLiveViewFullScreen } from "../../../action/liveview.action";
import {updateZoomLevelLiveView} from "../../../action/liveview.action";
import panAndZoomHoc from 'react-pan-and-zoom-hoc';
import { isFullScreen } from 'react-request-fullscreen';
import { mainCameraPreviewAppKey, cameraAppKey } from '../initial_setup_app_keys';

const InteractiveDiv = panAndZoomHoc('div');

class LiveCameraPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            liveView: "",
            x: 0,
            y: 0,
            scale: 1,
        }

        this.height = 146;
        this.width = 175;

        this.timer = null;
    }

    componentDidMount() {
        if(this.props.mainCameraLiveView.liveImagePolling && !(this.props.appState || {}).closed) {
            this.timer = setInterval(() => this.getLiveImage(), 100);
        } else {
            clearInterval(this.timer);
            this.timer = null;
            this.getLiveImage();
        }
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.mainCameraLiveView.liveImagePolling && (prevProps.appState || {}).closed != (this.props.appState || {}).closed) {
            if(!(this.props.appState || {}).closed) {
                this.timer = setInterval(() => this.getLiveImage(), 100);
            } else {
                clearInterval(this.timer);
                this.timer = null;
                this.getLiveImage();
            }
        }
        if(prevProps.resetCameraCount != this.props.resetCameraCount) {
            this.resetArea();
        }
        if(prevProps.mainCameraLiveView.liveImagePolling != this.props.mainCameraLiveView.liveImagePolling) {
            if(this.props.mainCameraLiveView.liveImagePolling) {
                this.timer = setInterval(() => this.getLiveImage(), 100);
            } else {
                clearInterval(this.timer);
                this.timer = null;
                this.getLiveImage();
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    }

    getLiveImage = () => {
        let url = '/server/devices/' + this.props.currentState.deviceId + '/get_live_image?scale=14&liveMode=false';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.props.dispatch(updateLiveView(response.data));
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    resetArea = () => {
        this.setState({
            x: 0,
            y: 0,
            scale: 1,
        });

        this.props.dispatch(updateZoomLevelLiveView(1.0, 0.0, 0.0));

    }
 
    handlePanAndZoom = (x, y, scale) => {
        let fixedScale = Math.round( scale * 1e2 ) / 1e2;;
        this.setState({
            x, 
            y, 
            scale: fixedScale,
        });
        let width = fixedScale * this.width;
        let height = fixedScale * this.height;
        const p1 = this.transformPoint({x: 0, y: 0});
        let finalX = p1.x * this.width > this.width - width ? this.width - width : p1.x * this.width < 0 ? 0 : p1.x * this.width;
        let finalY = p1.y * this.height > this.height - height ? this.height - height : p1.y * this.height < 0 ? 0 : p1.y * this.height;

        this.props.dispatch(updateZoomLevelLiveView(fixedScale, (finalX / this.width).toFixed(2), (finalY / this.height).toFixed(2)));

    }
 
    handlePanMove = (x, y) => {
        this.setState({
            x, 
            y,
        });
        let width = this.state.scale * this.width;
        let height = this.state.scale * this.height;
        const p1 = this.transformPoint({x: 0, y: 0});
        let finalX = p1.x * this.width > this.width - width ? this.width - width : p1.x * this.width < 0 ? 0 : p1.x * this.width;
        let finalY = p1.y * this.height > this.height - height ? this.height - height : p1.y * this.height < 0 ? 0 : p1.y * this.height;

        this.props.dispatch(updateZoomLevelLiveView(this.state.scale, (finalX / this.width).toFixed(2), (finalY / this.height).toFixed(2)));
    }

    transformPoint({ x, y }) {
        return {
            x: this.state.scale * (x - this.state.x),
            y: this.state.scale * (y - this.state.y)
        };
    }

    render() {

        const p1 = this.transformPoint({x: 0, y: 0});

        let width = this.state.scale * this.width;
        let height = this.state.scale * this.height;

        let finalX = p1.x * this.width > this.width - width ? this.width - width : p1.x * this.width < 0 ? 0 : p1.x * this.width;
        let finalY = p1.y * this.height > this.height - height ? this.height - height : p1.y * this.height < 0 ? 0 : p1.y * this.height;

        return (
            // <Row className="app-parent overlayed-component main-camera-preview-app-width">
            //     <div>
            //         <Row className="rounded-container">
                        <Col span={24}>
                            <Row>
                                <Col span={22} style={{position: 'relative'}}>
                                    <img id="liveCameraPreviewImage" alt="Live" 
                                        src={"data:image/png;base64," + this.props.liveView.data} />
                                    <div style={{width: this.width, height: this.height, border: '1px solid green', position: 'absolute', left: 0, top: 0 }}>
                                        <InteractiveDiv
                                            x={this.state.x}
                                            y={this.state.y}
                                            scale={this.state.scale}
                                            scaleFactor={1.1}
                                            minScale={0}
                                            maxScale={1}
                                            onPanAndZoom={(x, y, scale) => this.handlePanAndZoom(x, y, scale)} style={{width: this.width, height: this.height, border: '1px solid green', position: 'relative'}}
                                            onPanMove={(x, y) => this.handlePanMove(x, y)}
                                            disableZoomToMouse={true}
                                        >
                                            <div style={{position: 'absolute', transform: `translate(${finalX}px, ${finalY}px) translate(${this.state.scale}px, ${this.state.scale}px)`, width: `${this.state.scale * this.width}px`, height: `${this.state.scale * this.height}px`, backgroundColor: '#83f52c', opacity: 0.5}}/>
                                        </InteractiveDiv>
                                    </div>
                                </Col>
                            </Row>
                            <div>
                                <br/>
                                <div style={{fontSize: 13, marginRight: 10}}>
                                    Scroll on the preview image to zoom in and out.
                                </div> 
                                <div style={{fontSize: 13, marginRight: 10, marginTop: 10}}>
                                    Move green rectangle on the preview image to view that area.
                                </div>
                            </div>
                            {((isFullScreen() && this.state.scale < 0.51) || (!isFullScreen() && this.state.scale < 0.34)) ?
                                <div>
                                    <br/>
                                    <div className="errormessage">
                                        You are now viewing in Digital Zoom Mode.
                                    </div> 
                                </div> :
                                null
                            }
                        </Col>
            //         </Row>
            //     </div>
            // </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveMainCameraPreviewReducer,
        appState: state.adminUrlReducer.app_state[cameraAppKey.id],
        mainCameraLiveView: state.liveViewReducer,
    };
};

export default connect(mapStateToProps)(LiveCameraPreview);
