import React, { Component } from 'react';
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from '../../helper/auth.token';
import { Row, Col, Icon, Divider, Tooltip, Input, Button, message, Tag } from 'antd';
import { connect } from "react-redux";

const {TextArea} = Input;

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        }
    }

    componentDidMount = () => {
    }

    getMessagesRow = () => {
        let messages = this.props.livemode.messages;
        return messages.map((message, index) => {
            let date = new Date(message.timeStamp).toLocaleString();
            let timeDifference = ((Date.now() - message.timeStamp) / 1000);
            if (timeDifference < 0) {
                timeDifference = 0;
            }
            let timeDifferenceString = Math.trunc(timeDifference) + ' secs ago';
            if (timeDifference >= 0) {
                timeDifferenceString = Math.trunc((timeDifference / 60)) + ' mins ago';
            }
            return (
                // <Row style={{padding: 10}}>
                //     <Col span={3} style={{fontSize: 11}}>
                //         {/* {date.substr(0, date.length - 3)} */}
                //         {timeDifferenceString}
                //     </Col>
                //     <Col span={3}>
                //         <Tag color="#1b1b1b">{message.user}</Tag>
                //     </Col>
                //     <Col span={16}>
                //         {message.message}
                //     </Col>
                // </Row>
                <Row style={{margin: 10}}>
                    {message.user != cookie.loadAll().username ?
                        <Col span={22} style={{background: '#111111', padding: 10, border: '1px solid #111111', borderTopRightRadius: 10, borderBottomRightRadius: 10, borderBottomLeftRadius: 10}}>
                            <Row style={{fontSize: 12, fontWeight: 'bold'}}>
                                {message.user}
                            </Row>
                            <Row>
                                {message.message}
                            </Row>
                            <Row style={{textAlign: 'right', fontSize: 10}}>
                                {timeDifferenceString}
                            </Row>
                        </Col> :
                        <Col offset={2} span={22} style={{background: '#1b1b1b', padding: 10, border: '1px solid #1b1b1b', borderTopLeftRadius: 10, borderBottomLeftRadius: 10, borderBottomRightRadius: 10}}>
                            <Row style={{fontSize: 12, fontWeight: 'bold'}}>
                                {message.user}
                            </Row>
                            <Row>
                                {message.message}
                            </Row>
                            <Row style={{textAlign: 'right', fontSize: 10}}>
                                {timeDifferenceString}
                            </Row>
                        </Col>
                    }
                    <Col>
                    
                    </Col>
                    
                </Row>
            );
        });
    }

    onMessageChange = (e) => {
        if (e.target.value.length <= 400) {
            this.setState({
                message: e.target.value,
            });
        }
    }

    sendMessage = () => {
        let partsOfUrl = "api~settings~update_chat_message";
        let url = `/server/scano/` + this.props.livemode.deviceId + `/` + partsOfUrl + `?message=` + this.state.message + `&timestamp=` + Date.now() + `&user=` + cookie.loadAll().username;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 200) {
                    // let objDiv = document.getElementById("chatMessageDiv");
                    // objDiv.scrollTop = objDiv.scrollHeight;
                }
                else {
                    console.log(response);
                    message.error("Not able to send message.", 2.5);
                }
                this.setState({
                    message: '',
                });
            })
            .catch(err => {
                console.log(err);
                message.error("Not able to send message.", 2.5);
                this.setState({
                    message: '',
                });
            })
    }

    render() {

        return (
            <Row className="relative-position" style={{height: '100%'}}>
                <Row style={{height: '2%'}}>
                </Row>
                <Row style={{height: '86%'}} className="messages-div" id="chatMessageDiv">
                    <Col span={22} offset={1}>
                        {this.getMessagesRow()}
                    </Col>
                </Row>
                <Row style={{height: '2%'}}>
                </Row>
                <Row style={{height: '10%'}}>
                    <Col offset={1} span={22}>
                        <Input 
                            // autosize={{ minRows: 2, maxRows: 2 }}
                            value={this.state.message} 
                            onChange={this.onMessageChange} 
                            onPressEnter={this.sendMessage}
                            style={{width: '100%', cursor: 'auto', paddingLeft: 25, paddingRight: 25}}
                            className="message-input-field custom-input-background-placeholder-color"  
                        />
                    </Col>
                    {/* <Col offset={1} span={3}>
                        <Button onClick={this.sendMessage} 
                            style={{margin: 0}}
                            className={"eject-button unselected-style"}
                        >
                            <Row style={{fontSize: 10}}>
                                SEND
                            </Row>
                        </Button>
                    </Col> */}
                </Row>
                <div className="app-icon-closeable-handle settings-close-icon-div" onClick={this.props.toggleChat}>
                    <Tooltip 
                        title="Click to Close"
                        placement="right"
                        overlayStyle={{
                            fontSize:"10px"
                        }}
                    >
                        <span className="settings-close-icon">x</span>
                    </Tooltip> 
                </div>
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        liveView: state.liveViewReducer,
        adminState: state.adminUrlReducer,
        livemode: state.livemodeReducer,
    };
};

export default connect(mapStateToProps)(Chat);
