import React, {Component} from  "react";
import {connect} from 'react-redux';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Slider, message } from 'antd';
import sample_card_black from "../../../asset/img/credit_card_black.png"
import { updateDeviceZoomRatio } from "../../../action/morpheus.state.action"
import * as keys from './app_keys';

import "../../../asset/style/neoviewer/display_size_calibration.css"

class DisplaySizeCalibrationApp extends Component {

    constructor(props) {
        super(props);
        let screenSizeString = window.screen.width + 'x' + window.screen.height;
        if(screenSizeString == '1920x1080' && 
            (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[screenSizeString] == undefined) {
                this.props.dispatch(updateDeviceZoomRatio(this.props.id, screenSizeString, 1));
            }
        this.state = {
            zoomRatio: (((this.props.urlState || {}).app_state || {})[keys.displaySizeCalibrationKey.id] || {})[screenSizeString] || 1,
        }
    }

    updateZoomRatio = (value) => {
        this.setState({
            zoomRatio: value,
        })
    }

    saveZoomRatio = (e) => {
        let screenSize = window.screen.width + 'x' + window.screen.height;
        this.props.dispatch(updateDeviceZoomRatio(this.props.id, screenSize, this.state.zoomRatio));
        message.success("Zoom ratio set successfully", 2.5);
    }

    incrementZoomLevel = (e) => {
        this.setState({
            zoomRatio: this.state.zoomRatio + 0.01,
        });
    }

    decrementZoomLevel = (e) => {
        this.setState({
            zoomRatio: this.state.zoomRatio - 0.01,
        });
    }

    render() {   

        return (
            <div className="app-parent overlayed-component box-component">
                <br/>
                <Row>
                    <Col offset={1} span={23}>
                        <div style={{fontSize: 13}}>
                            <ol style={{paddingLeft: 20, paddingRight: 5}}>
                                <li>Place the credit/debit card as shown in the image.</li>
                                <li>Make the width of the card on the screen equal to your card by using slider or using the +/- buttons.</li>
                                <li>Click save button.</li>
                            </ol>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col offset={1} span={23} push={18}>
                        <Button onClick={this.incrementZoomLevel}>
                            <PlusOutlined />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col offset={1} span={19}>
                        <img src={sample_card_black} width={180} height={210 * this.state.zoomRatio} style={{marginTop: 8}} />
                    </Col>
                    <Col span={4}>
                        <Slider
                            min={1}
                            max={1.7}
                            value={this.state.zoomRatio}
                            onChange={this.updateZoomRatio}
                            step={0.01}
                            vertical
                            className="custom-slider-style slider-height"
                            tooltipVisible={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col offset={1} span={23} push={18}>
                        <Button onClick={this.decrementZoomLevel}>
                            <MinusOutlined />
                        </Button>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col offset={1} span={23} push={8}>
                        <Button type="primary"  onClick={this.saveZoomRatio}>Save</Button>
                    </Col>
                </Row>
                <br/>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer
    }
}

export default connect(mapStateToProps)(DisplaySizeCalibrationApp);