import React, { Component } from "react";
import HeaderComp from "../component/header";
import { connect } from "react-redux";
import Icon, { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Row, Col, List, Spin, Modal, Input } from 'antd';
import { Link } from 'react-router-dom'
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token"
import { setUIBusy, setUIIdle } from "../action/utils.action";
import { historyMP } from '../helper/history';
import { message } from "antd";
import cookie from "react-cookies";
import { globalUrlPrefix } from "../utils/const";

class CaseListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: undefined, 
            askedToDelete: {}
        }
    }

    getCases = value => {
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/case/?case_category=` + this.props.match.params.id;
        axios
        .get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.setState({
                cases : response.data
            })
            this.props.dispatch(setUIIdle());
        })
        .catch(err => {
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }
  
    componentDidMount = () => {
      this.getCases();
    }
  
    addCase = (case_id) => {
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/case/`;
        let val = {"case_category" : parseInt(this.props.match.params.id), 'case_id': case_id, owner: cookie.loadAll().user_id
    };
        axios
        .post(url, val, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.props.dispatch(setUIIdle());
            historyMP.push(`/` + globalUrlPrefix + `/case/${response.data.id}`);
        })
        .catch(err => {
            message.error("New Case Creation Failed. Contact Admin")
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }

    askForCaseId = () => {
        Modal.confirm({
            title: 'Please enter Case ID',
            icon: <InfoCircleOutlined></InfoCircleOutlined>,
            content: (
                <Input placeholder="Enter case ID here" id="case-id" />
            ),
            onOk:  () => {
              let case_id = document.getElementById("case-id").value;
              this.addCase(case_id);
            },
            onCancel: () => {}
          });
    }

    askedToDelete = (id) => {
        let newAskedToDelete = Object.assign({}, this.state.askedToDelete);
        if (this.state.askedToDelete[id] !== undefined) {
            delete newAskedToDelete[id];
        } else {
            newAskedToDelete[id] = true;
        }
        this.setState(
            Object.assign(
                {}, 
                this.state, 
                {
                    askedToDelete: newAskedToDelete
                }
            )
        );
    }

    deleteCase = (id) => {
        this.props.dispatch(setUIBusy("Loading"));
        let url = `/api/case/` + id + `/`;
        axios
        .delete(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.askedToDelete(id);
            this.getCases();
        })
        .catch(err => {
            this.props.dispatch(setUIIdle());
            console.log(err);
        });
    }

    deleteAction = (id) => {
        return this.state.askedToDelete[id] !== undefined ? 
        [<span>Confirm Delete</span>, <a onClick={() => this.deleteCase(id)}>Yes</a>, <a onClick={() => this.askedToDelete(id)}>No</a>]
        :
        [<DeleteOutlined style={{fontSize:"1.3em"}} onClick={() => this.askedToDelete(id)}></DeleteOutlined>];
    }

    render() {

        var actionButton = 
        [<Row className="add-to-list-icon" key={0}>
            <Icon type="plus">
            </Icon>
        </Row>, 
        <Row className="add-to-list-text" key={1}>
            Add Case
        </Row>];

        var addToList = 
        <Row>
            <Col span={24} className="generic-list-item">
                <Row style={{cursor: 'pointer'}} onClick={this.askForCaseId}>
                    {actionButton}
                </Row>
            </Col>
        </Row>

        return (  
            <Spin spinning={this.props.uiBusy} tip={this.props.busyMessage} size="large">
                <Row className="scanner-block">
                    <Col className="header-style-1 arial-font" span={24}>
                        Cases
                    </Col>
                </Row>  
                <Row className="scanner-block">
                    <Col span={24}>
                        <List
                            header={addToList}
                            itemLayout="horizontal"
                            dataSource={this.state.cases}
                            renderItem={item => (
                                <List.Item className="generic-list-item" actions={this.deleteAction(item.id)}>
                                <Link to={"/" + globalUrlPrefix + "/case/" + item.id + "/"} className="list-item-link">
                                    <List.Item.Meta
                                    title={<p>{item.case_id}</p>}
                                    />
                                </Link>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Spin>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        uiBusy: state.utilsReducer.uiBusy,
        busyMessage: state.utilsReducer.busyMessage
    };
};

export default connect(mapStateToProps)(CaseListView);