import React, { Component } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox, Alert, Divider } from "antd";
import { connect } from "react-redux";
import cookie from 'react-cookies';
import { login, getCsrfToken } from "../action/login.action";
import { Redirect } from "react-router-dom";
import {Row , Col} from 'antd';
import logo_full from "../asset/img/logo-mp.png"
import {Link} from 'react-router-dom';
import { checkIfMobile } from '../utils/utils'
import { globalUrlPrefix } from '../utils/const'

import '../asset/style/login.css'

class NormalLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      redirectPage: this.props.redirectPage,
      prevLocationStatus: this.props.prevLocationStatus
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    let isMobile = checkIfMobile();
    document.documentElement.style.setProperty('--login-div-size', isMobile ? "20em" : "40em");
  }

  componentWillMount() {
    this.props.dispatch(getCsrfToken());
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.dispatch(login(values));
      }
    });
  };

  renderForm() {
    const { getFieldDecorator } = this.props.form;

    let content = [];
    content = content.concat([
        <Form.Item>
            {getFieldDecorator("username", {
                rules: [{ required: true, message: "Please input your username!" }]
            })(
            <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Username"
            />
            )}
        </Form.Item>,
        <Form.Item>
          {getFieldDecorator("password", {
                rules: [{ required: true, message: "Please input your Password!" }]
          })(
            <Input
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
            />
          )}
        </Form.Item>,
        <Form.Item style={{ display: "none" }}>
          {
              getFieldDecorator("csrfmiddlewaretoken", {
              valuePropName: "Checked",
              initialValue: this.props.csrfToken
          })(<Checkbox />)}
        </Form.Item>
    ]);
    if(this.props.isServerUnreachable) {
        content.push(<div style={{color:"red"}}>Server is Unreachable. Please Contact Administrator.</div>);
    }
    if(this.props.isLockedOut) {
        content.push(<div style={{color:"red"}}>Too many failed login attempts. Please Contact Administrator.</div>);
    }
    if(this.props.isForbidden && !this.props.isLockedOut) {
        content.push(<div style={{color:"red"}}>Access denied. Please Contact Administrator.</div>);
    }
    if(this.props.isWrongCredential) {
        content.push(<div style={{color:"red"}}>Invalid Username or Password. <br/> Mail at contact[at]morphle.in to reset if forgotten.</div>);
    }
    content.push(
            <Form.Item>
                  {
                    getFieldDecorator("remember", {
                    valuePropName: "checked",
                    initialValue: true
                  })(<Checkbox>Remember me</Checkbox>)}
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={this.props.isFetching}>
                    Enter
                  </Button>
            </Form.Item>
    );

    return content;
  }

  renderContent() {


        if(this.props.message && this.props.resolution) {
            return [
              <div className="logout-message">
                <Alert message={decodeURIComponent(this.props.message) + " " + decodeURIComponent(this.props.resolution)} type="error"/>
                <Divider style={{padding: "1px"}}/>
                <Button type="primary" style={{marginTop: "20px"}}><Link to="/login">Click here to login</Link></Button>
              </div>,
            ];
        }
        let content = [];
        content.push(<p className="morphle-title unselectable" > Login to Morphle </p>);
        if(this.props.demoLogin) {
            let elems = [
                <p key={0} className="demo-login">For Accessing Demo Scans</p>,
                <p key={1} className="demo-login"><b>username</b> : morphledemo</p>,
                <p key={2} className="demo-login"><b>password</b> : demouser1234</p>
            ];
            content = content.concat(elems);
        }

        content.push(
            <Form onSubmit={this.handleSubmit}>
                { this.renderForm() }
            </Form>
        );

        return content;
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.loginStatus === true) {
      if(this.state.prevLocationStatus) {
        return <Redirect to={this.state.redirectPage} />;
      } else {
        if(this.props.is_staff || this.props.superuser || (JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) {
          return <Redirect to={"/" + globalUrlPrefix + "/dashboard"} />;
        } else {
          return <Redirect to={"/" + globalUrlPrefix + "/cases"} />;
        }
      }
    } else {
      if(this.props.proxyLoginStatus) {
        return (
          <Row></Row>
        );
      } else {
        return (
          <Row>
            <Col className="login-form overlayed-component">
              <Link to={'/'}><img className="login-logo" src={logo_full} /></Link>
              { this.renderContent() }
            </Col>
            {/* <Col span={4} >
            <img height="500px" margin-top="1%" alt="Morphle Brochure"
              src={require('../asset/img/morphle-brochure.jpg')} 
            />
            </Col> */}
          </Row>
        );
      }
    }
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);

const mapStateToProps = state => {
  return state.loginAlerts;
};

export default connect(mapStateToProps)(WrappedNormalLoginForm);
