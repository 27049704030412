import cookie from "react-cookies";
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token";
import { TileViewerConstants } from "../actionTypes/tile.viewer.state.constant";

export const resetMapParams = (currentState) => dispatch => {

    let newState = {
        ...currentState,
        x: -1,
        y: -1,
        z: 0,
        r: 0
    }

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_STATE,
        urlState: newState
    })
}


export const updateMapParams = (currentState, x, y, z, r) => dispatch => {
    let newState = {
        ...currentState,
        x: x,
        y: y,
        z: z,
        r: r,
    }

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_STATE,
        urlState: newState
    })
}


export const updateZoom = (currentState, z) => dispatch => {

    let newState = {
        ...currentState,
        z: z,
    }

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_STATE,
        urlState: newState
    })
}


export const updateDigitalZoomStatus = (currentState, digitalZoomStatus) => dispatch => {

    let newState = {
        ...currentState,
        digitalZoomStatus: digitalZoomStatus
    }

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_STATE,
        urlState: newState
    })
}

export const updateAppClosedStatus = (appName, closed) => dispatch => {

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_APP_CLOSED_STATE,
        appClosedStatus: closed,
        appName: appName
    })
}

export const updateAppPosition = (appName, position) => dispatch => {

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_APP_POSITION,
        appPosition: position,
        appName: appName
    });
}

export const updateAppClickTime = (appName) => dispatch => {

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_APP_CLICK_TIME,
        appName: appName
    });
}

export const updateTileViewerUrlStateInReducer = (oldState) => dispatch => {
    var newState = tileViewerUrlParser(oldState);

    dispatch({
        type: TileViewerConstants.UPDATE_TILE_VIEWER_STATE,
        urlState: newState
    })
}

export const updateShape = (value) => dispatch => {
    dispatch({
        type: TileViewerConstants.UPDATE_SHAPE,
        value: value
    });
}

export const updateIsDrawing = (value) => dispatch => {
    dispatch({
        type: TileViewerConstants.UPDATE_IS_DRAWING,
        value: value
    });
}

export const loadMorpheusSettings = () => dispatch => {
    let url = `/api/morpheussetting/` + JSON.parse(localStorage.getItem('morpheus_setting')).id + `/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                response.data.apps_initialised = true;
                dispatch({
                    type: TileViewerConstants.INIT_TILE_VIEWER_STATE,
                    urlState: response.data
                })
            }
            else {
                console.log(response);
            }
        })
        .catch(err => {
            console.log(err);
        })
}

export const tileViewerUrlParser = (parsedPartsOfUrl) => {
    try {
        var url = window.location.href.split('?')[1];
        if (url != undefined) {
            var partsOfUrl = url.split('&');
            partsOfUrl.map((part) => {
                var key = part.split('=')[0];
                var value = part.split('=')[1];
                if (key === 'x') {
                    parsedPartsOfUrl.x = parseFloat(value);
                } else if (key === 'y') {
                    parsedPartsOfUrl.y = parseFloat(value);
                } else if (key === 'z') {
                    parsedPartsOfUrl.z = parseFloat(value);
                } else if (key === 'r') {
                    parsedPartsOfUrl.r = parseFloat(value);
                } else if (key === 'digitalZoomStatus') {
                    parsedPartsOfUrl.digitalZoomStatus = value === 'true' ? true : false;
                }
            });
        }
    } catch (err) {
        console.log('Could not parse Url', window.location.href, err);
    }
    return parsedPartsOfUrl;
}
