import React from  "react";

// export const objectiveTypeKey = {
//     id: "objective_type", 
//     title : "Objective Type", 
//     component: "objectiveTypeComponent",
//     isMetric : false
// }

// export const xStepKey = {
//     id: "x_step", 
//     title : "X Step", 
//     component: "xStepComponent",
//     isMetric : false
// }

// export const yStepKey = {
//     id: "y_step", 
//     title : "Y Step", 
//     component: "yStepComponent",
//     isMetric : false
// }

// export const uperpixelKey = {
//     id: "uperpixel", 
//     title : "Uperpixel", 
//     component: "uperpixelComponent",
//     isMetric : false
// }

// export const imgTypeKey = {
//     id: "img_type", 
//     title : "Img Type", 
//     component: "imgTypeComponent",
//     isMetric : false
// }

export const scanAreaKey = {
    id: "scan_area", 
    title : "Scan Area", 
    component: "scanAreaComponent",
    isMetric : false
}

export const scanSpeedKey = {
    id: "scan_speed", 
    title : "Scan Speed", 
    component: "scanSpeedComponent",
    isMetric : false
}

export const gapsFoundKey = {
    id: "gaps_found", 
    title : "Gaps Found", 
    component: "gapsFoundComponent",
    isMetric : false
}

export const totalScanTimeKey = {
    id: "total_scan_time", 
    title : "Total Scan Time", 
    component: "totalScanTimeComponent",
    isMetric : true
}

export const fineFocusStreakTimeKey = {
    id: "fine_focus_streak_time", 
    title : "Fine Focus Streak Time", 
    component: "fineFocusStreakTimeComponent",
    isMetric : true
}

export const fineFocusTimeKey = {
    id: "fine_focus_time", 
    title : "Fine Focus Time", 
    component: "fineFocusTimeComponent",
    isMetric : true
}

export const dropDistanceTimeKey = {
    id: "drop_distance_time", 
    title : "Drop Distance Time", 
    component: "dropDistanceTimeComponent",
    isMetric : true
}

export const totalFineFocusTimeKey = {
    id: "total_fine_focus_time", 
    title : "Scan Fine Focus Time",
    component: "totalFineFocusTimeComponent",
    isMetric : true
}

export const scanContinousModeSingleImageCaptureTimeKey = {
    id: "scan_continuous_mode_single_image_capture_time", 
    title : "Scan Continous Mode Single Image Capture Time", 
    component: "scanContinousModeSingleImageCaptureTimeComponent",
    isMetric : true
}

export const scanImageSendFromBaumerTimeKey = {
    id: "scan_image_send_from_baumer_time", 
    title : "Scan Image Send From Baumer Time", 
    component: "scanImageSendFromBaumerTimeComponent",
    isMetric : true
}

export const removeVignetteTimeKey = {
    id: "remove_vignette_time", 
    title : "Remove Vignette Time", 
    component: "removeVignetteTimeComponent",
    isMetric : true
}

export const lensUndistortionTimeKey = {
    id: "lens_undistortion_time", 
    title : "Lens Undistortion Time",
    component: "lensUndistortionTimeComponent", 
    isMetric : true
}

export const cameraUndistortionTimeKey = {
    id: "camera_undistortion_time", 
    title : "Camera Undistortion Time", 
    component: "cameraUndistortionTimeComponent",
    isMetric : true
}

export const normalizerTimeKey = {
    id: "normalizer_time", 
    title : "Normalizer Time", 
    component: "normalizerTimeComponent",
    isMetric : true
}

export const cropTimeKey = {
    id: "crop_time", 
    title : "Crop Time", 
    component: "cropTimeComponent",
    isMetric : true
}

export const backgroundDetectorTimeKey = {
    id: "background_detector_time", 
    title : "Background Detector Time", 
    component: "backgroundDetectorTimeComponent",
    isMetric : true
}

export const featureCalculationTimeKey = {
    id: "feature_calculation_time", 
    title : "Feature Calculation Time", 
    component: "featureCalculationTimeComponent",
    isMetric : true
}

export const txtyCalculationTimeKey = {
    id: "txty_calculation_time", 
    title : "TXTY Calculation Time", 
    component: "txtyCalculationTimeComponent",
    isMetric : true
}

export const txtyRetryTimeKey = {
    id: "txty_retry_time", 
    title : "TXTY Retry Time", 
    component: "txtyRetryTimeComponent",
    isMetric : true
}

export const totalFeatureCalculationTimeKey = {
    id: "total_feature_calculation_time", 
    title : "Total Feature Calculation Time", 
    component: "totalFeatureCalculationTimeComponent",
    isMetric : true
}

export const totalPostprocessingTimeKey = {
    id: "total_postprocessing_time", 
    title : "Total Postprocessing Time", 
    component: "totalPostprocessingTimeComponent",
    isMetric : true
}

export const motionToPathunitTimeKey = {
    id: "motion_to_pathunit_time", 
    title : "Motion To Pathunit Time", 
    component: "motionToPathunitTimeComponent",
    isMetric : true
}

export const streakAdjustAfterDynamicFocusTimeKey = {
    id: "streak_adjust_after_dynamic_focus_time", 
    title : "Streak Adjust After Dynamic Focus Time", 
    component: "streakAdjustAfterDynamicFocusTimeComponent",
    isMetric : true
}

export const streakChangeTimeKey = {
    id: "streak_change_time", 
    title : "Streak Change Time", 
    component: "streakChangeTimeComponent",
    isMetric : true
}

export const stroberStreakeHertzKey = {
    id: "strober_streak_hertz", 
    title : "Strober Streake Hertz", 
    component: "stroberStreakeHertzComponent",
    isMetric : true
}

export const stripHertzKey = {
    id: "strip_hertz", 
    title : "Strip Hertz", 
    component: "stripHertzComponent",
    isMetric : true
}

export const compensationTimeKey = {
    id: "compensation_time", 
    title : "Compensation Time", 
    component: "compensationTimeComponent",
    isMetric : true
}

export const stroberStreakTranslationTimeKey = {
    id: "strober_streak_translation_time", 
    title : "Strober Streak Translation Time", 
    component: "stroberStreakTranslationTimeComponent",
    isMetric : true
}

export const stripTranslationTimeKey = {
    id: "strip_translation_time", 
    title : "Strip Translation Time", 
    component: "stripTranslationTimeComponent",
    isMetric : true
}

export const totalTranslationTimeWithoutCompensationKey = {
    id: "total_translation_time_without_compensation", 
    title : "Total Translation Time Without Compensation", 
    component: "totalTranslationTimeWithoutCompensationComponent",
    isMetric : true
}

export const createPostprocessorBankTimeKey = {
    id: "create_postprocessor_bank_time", 
    title : "Create Postprocessor Bank Time", 
    component: "createPostprocessorBankTimeComponent",
    isMetric : true
}

export const postprocessorInitTimeKey = {
    id: "postprocessor_init_time", 
    title : "Postprocessor Init Time", 
    component: "postprocessorInitTimeComponent",
    isMetric : true
}

export const createPathTimeKey = {
    id: "create_path_time", 
    title : "Create Path Time", 
    component: "createPathTimeComponent",
    isMetric : true
}

export const cpuWaitTimeKey = {
    id: "cpu_wait_time", 
    title : "CPU Wait Time", 
    component: "cpuWaitTimeComponent",
    isMetric : true
}

export const cleanupTimeKey = {
    id: "cleanup_time", 
    title : "Cleanup Time", 
    component: "cleanupTimeComponent",
    isMetric : true
}

export const boilerplateTimeKey = {
    id: "boilerplate_time", 
    title : "Boilerplate Time", 
    component: "boilerplateTimeComponent",
    isMetric : true
}

export const unaccountedTimeKey = {
    id: "unaccounted_time", 
    title : "Unaccounted Time", 
    component: "unaccountedTimeComponent",
    isMetric : true
}