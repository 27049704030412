import React, {Component} from 'react';
import axios from "axios";
import { AuthHeader } from '../../../helper/auth.token';
import cookie from "react-cookies";
import {Divider, Grid, IconButton, InputBase, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import DoneIcon from '@mui/icons-material/Done';
import {displayError} from "../../../helper/display.error";

class AnnotationComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            submitting: false,
            value: '',
            data: null,
            username: cookie.loadAll().username,
            userId: cookie.loadAll().user_id,
        }
    }

    componentDidMount() {
        this.getComment(this.props.annoId);
    }

    getComment(value) {
        if(value !== null) {
            let url = `/api/comments/?annotation=${value}`
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
                        this.extractComment(response.data);
                    }
                    else if (response.status === 403 || response.status === 401 || response.status === 400) {
                        displayError('Comments Update Failed');
                    }
                })
                .catch(err => displayError("Comments Update Failed", err));
            }
    }

    handleSubmit = () => {
        if (!this.state.value) {
            return;
        }
        setTimeout(() => {
            let val = { "author": this.state.username, "content": this.state.value };
            this.sendComment(val);
        }, 100);

        this.setState({
            submitting: true,
        });

        setTimeout(() => {
            this.setState({
                submitting: false,
                value: '',
                comments: [
                    ...this.state.comments,
                    {
                        author: this.state.username,
                        content: this.state.value
                    },
                ],
            });
        }, 100);
    }

    extractComment = (data) => {
        var com = [];
        data.reverse().map(index => {
            let par = JSON.parse(index.comment);
            com.push(par);
            return par;
        });
        this.setState({
            comments: com
        });
        this.updateScroll();
    }

    updateScroll() {
        let element = document.getElementById("messageBody");
        if (element)
            element.scrollTop = element.scrollHeight;
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value,
        });
    }

    sendComment(value) {
        let val = { "user": this.state.userId, "comment": JSON.stringify(value), "annotation": this.props.annoId };
        let url = `/api/comments/`;
        axios.post(url, val, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
                    this.updateScroll();
                }
                else if (response.status === 403 || response.status === 401 || response.status === 400)
                    displayError('Failed to add comments');
            })
            .catch(err => displayError("Failed to add comments", err));
    }

    render() {
        const {comments, submitting, value} = this.state;
        return (
            <Grid sx={{border:'2px solid grey', borderRadius:2,}}>
                <Grid>
                    <Typography sx={{marginLeft:'0.3vw'}}>{`${comments.length} ${comments.length !== 1 ? 'comments' : 'comment'}`}</Typography>
                </Grid>
                <Grid id={'messageBody'} sx={{maxHeight:'120px', width:'100%', overflowY:'auto', minHeight:'120px', borderRadius: 2,}}>
                    {
                        this.state.comments.map(value=> (
                            <React.Fragment>
                                <Grid sx={{minHeight:'30px', width:'95%'}}>
                                    <Grid sx={{color:'white', fontSize:'0.7em', paddingLeft:'10px'}}>{value.author}</Grid>
                                    <Grid sx={{color: 'white', paddingLeft:'10px'}}>{value.content}</Grid>
                                    <Divider/>
                                </Grid>
                            </React.Fragment>
                        ))
                    }
                </Grid>
                <Divider color={'white'}/>
                <Grid>
                    <Paper
                        component={"form"}
                        sx={{ display: 'flex', alignItems: 'center', width: '100%',  height:'50px', borderRadius: 2, }}
                    >
                        <InputBase
                            sx={{px:2, borderRadius: 2, flex:1, verticalAlign:'center', backgroundColor:'#0b1c3b',}}
                            size={'small'}
                            placeholder="Add Comment"
                            onChange={this.handleChange}
                            disabled={submitting}
                            value={value}
                            onKeyPress={(e)=> {
                                if(e.key === 'Enter'){
                                    this.handleSubmit();
                                    e.preventDefault();
                                }
                            }}
                        />
                        <Divider orientation={'vertical'}/>
                        <IconButton color={"secondary"} onClick={()=>{this.handleSubmit()}}>
                            <DoneIcon/>
                        </IconButton>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default AnnotationComments;

