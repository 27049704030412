import React, {Component} from 'react';
import HeaderComp from '../component/header'
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { message } from "antd";
import CaseComp from '../component/case/caseComp';
import { Spin, Divider } from 'antd';

export class CaseView extends Component {
  constructor(props) {
    super(props);

    this.state= {
      isFetching: true,
      case_id: this.props.case,
      case: null
    }
  }


  getCase = value => {
    this.setState({
      isFetching: true
    });
    let url = `/api/case/${value}/`;
    axios
      .get(url, { headers: { Authorization: AuthHeader() } })
      .then(response => {
          this.setState({
              isFetching: false,
              case: response.data,
              case_id: value,
          });
      })
      .catch(err => {
        message.error("Case could not be found. Contact Admin.");
        this.setState({
            isFetching: false
        });
        console.log(err);
      });
  }

  componentDidMount = () => {
    this.getCase(this.state.case_id);
  }

  render() {
      return(
          <div>
              <Divider/>
              <Spin tip="Loading..." spinning={this.state.isFetching} delay={500}>
                  {this.state.isFetching === true? <div></div>:
                    <CaseComp
                      key={this.state.case_id}
                      case={this.state.case}
                     />
                  }
              </Spin>
              
          </div>
      )
  }
}