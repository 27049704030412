import { DashboardConstants } from "../actionTypes/dashboard.constant"

export const changePage = (pageNumber) => ({
    type : DashboardConstants.CHANGE_PAGE, 
    pageNumber: pageNumber
})

export const changeTotalNumberOfPages = (numPages) => ({
    type : DashboardConstants.CHANGE_TOTAL_NUMBER_OF_PAGES, 
    numPages: numPages
})

export const changeTotal = (total) => ({
    type : DashboardConstants.CHANGE_TOTAL_SLIDES, 
    total: total
})

export const setDashboardBusy = () => ({
    type: DashboardConstants.SET_BUSY
})

export const setDashboardIdle = () => ({
    type: DashboardConstants.SET_IDLE
})

export const closeDrawer = () => ({
    type: DashboardConstants.CLOSE_DRAWER
})

export const openDrawer = () => ({
    type: DashboardConstants.OPEN_DRAWER
})

export const addTagToTagList = (tag) => ({
    type : DashboardConstants.ADD_TAG, 
    tag: tag
})