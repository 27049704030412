import {UtilConstant} from "../actionTypes/utils.constant";

const InitialState = {
    uiBusy: false
}

export const utilsReducer = ( state = InitialState , action) => {
    const currentInfo = Object.assign({}, state);
    switch(action.type){
        case UtilConstant.SET_UI_BUSY:
            currentInfo.uiBusy = true;
            currentInfo.busyMessage = action.busyMessage;
            return currentInfo
        case UtilConstant.SET_UI_IDLE:
            currentInfo.uiBusy = false;
            currentInfo.busyMessage = "";
            return currentInfo
        default :
            return state
    }
}