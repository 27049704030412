import React, {Component} from  "react";
import {connect} from 'react-redux';
import { CloseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Row, Col, Button, Divider, Typography } from 'antd';
import { copyStringToClipboard, otpGenerator } from '../../../utils/utils'
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";
import { updateMapParamsPresent, clearPresentingStatus, clearFollowingStatus, startPresenting, updatePresentTimestamp } from "../../../action/morpheus.state.action"
import { globalUrlPrefix } from '../../../utils/const'

import "../../../asset/style/neoviewer/present.css"

const {Text} = Typography;

class PresentApp extends Component {

    constructor(props) {
        super(props);

        this.state = {
            presenting: false
        }

        if ((this.props.urlState || {}).presentCode != undefined) {
            this.state.following = true;
            this.state.presentCode = this.props.urlState.presentCode;
            this.timer = setInterval(() => this.followMaster(), 100);
        } else if ((this.props.urlState.app_state[this.props.id] || {}).code != undefined) {
            this.state.presentCode = this.props.urlState.app_state[this.props.id].code;
            this.state.presenting = true;
        }
    }

    componentDidMount = () => {
        if (this.state.presenting) {
            this.startPushingStateToServer();
        }
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.urlState.slide_id != this.props.urlState.slide_id && this.state.presenting === true) {
            this.setPresentBrowsingData(this.props.view.getRotation(), this.props.view.getZoom(), this.props.view.getCenter()[0], this.props.view.getCenter()[1]);
        }
    }

    onButtonClick = () => {
        if (this.state.following) {
            clearInterval(this.timer);
            this.timer = null;
            this.setState({
                following: false
            })
            this.props.dispatch(clearFollowingStatus());
        }
        else if (!this.state.presenting) {
            this.startPresenting();
        } else {
            this.stopPresenting();
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
        this.timer = null;
    }

    updateMap() {
        this.props.view.setCenter([this.props.urlState.x, this.props.urlState.y]);
        this.props.view.setZoom(this.props.urlState.z);
        this.props.view.setRotation(this.props.urlState.r);
    }

    stopPresenting = () => {
      let url = `/api/clean_sync_browsing/?sync_code=${this.state.presentCode}&morphle_id=${this.props.slideData.morphle_id}`;
      axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            this.setState({
                presenting: false
            })
            this.props.dispatch(clearPresentingStatus());
        })
        .catch(err => {
          console.log("Failed Cleaning");
        });
    }

    followMaster = () => {
        let url = `/api/get_sync_browsing/?sync_code=${this.state.presentCode}&morphle_id=${this.props.slideData.morphle_id}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            try {
                if(response.data.slide_id != undefined && response.data.slide_id != -1 && response.data.slide_id != this.props.slideData.id) {
                        window.location.href = '/' + globalUrlPrefix + '/viewer/' + response.data.slide_id + '?presentCode=' + this.state.presentCode;
                } else if(response.data.slide_id != undefined && response.data.center_x != undefined && response.data.center_y != undefined
                    && response.data.zoom != undefined && response.data.angle != undefined && response.data.app_state != undefined
                    && response.data.grid_timestamp != undefined && response.data.settings_timestamp != undefined 
                    && response.data.annotations_timestamp != undefined && response.data.zoom_timestamp != undefined) {
                        this.props.dispatch(updateMapParamsPresent(
                            this.props.urlState, 
                            parseFloat(response.data.center_x), parseFloat(response.data.center_y),
                            parseFloat(response.data.zoom),
                            parseFloat(response.data.angle),
                            JSON.parse(response.data.app_state),
                        ));
                        this.props.dispatch(updatePresentTimestamp(
                            parseFloat(response.data.grid_timestamp),
                            parseFloat(response.data.settings_timestamp),
                            parseFloat(response.data.annotations_timestamp),
                            parseFloat(response.data.zoom_timestamp)
                        ));
                }
            } catch (err) {
                console.log(err);
            }
            this.updateMap();
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
    }

    startPresenting = () => {
        let presentCode = otpGenerator(4);
        
        this.setState({
            presenting: true, 
            presentCode: presentCode
        }, () => {
            this.setPresentBrowsingData(this.props.view.getRotation(), this.props.view.getZoom(), this.props.view.getCenter()[0], this.props.view.getCenter()[1]);
            this.props.dispatch(startPresenting(this.props.urlState, presentCode));
        })

        this.startPushingStateToServer();

        copyStringToClipboard(window.location.href.split('?')[0] + '?presentCode=' + presentCode);
    }

    startPushingStateToServer = () => {
        let listener = () => {
            if (this.props.urlState.app_state[this.props.id].closed) {
                this.props.view.un(['change:center', 'change:resolution', 'change:rotation'], listener);
                this.setState({
                    presenting: false
                })
            }
            if (this.state.presenting === true) {
                this.setPresentBrowsingData(this.props.view.getRotation(), this.props.view.getZoom(), this.props.view.getCenter()[0], this.props.view.getCenter()[1]);
            } 
        }

        this.props.view.on(['change:center', 'change:resolution', 'change:rotation'], listener);
    }
    
    setPresentBrowsingData = (angle, zoom, center_x, center_y) => {
        let url = `/api/set_sync_browsing/?sync_code=${this.state.presentCode}&morphle_id=${this.props.slideData.morphle_id}&angle=${angle}&zoom=${zoom}&center_x=${center_x}&center_y=${center_y}&slide_id=${this.props.urlState.slide_id}&app_state=${JSON.stringify(this.props.urlState.app_state)}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
          })
          .catch(err => {
            console.log("Failed Setting Key Value");
          });
    }

    copyPresentLink = (e) => {
        copyStringToClipboard(window.location.href.split('?')[0] + '?presentCode=' + this.state.presentCode);
    }

    render(){   

        let codeDiv = undefined;

        let showStopIcon = this.state.presenting || this.state.following;

        if (this.state.presenting) {
            codeDiv = [
                <Divider key={0}/>, 
                <Row key={1}>
                    <div style={{textAlign:"center"}}>
                        <Button size="small" onClick={this.copyPresentLink}>
                            Copy Link
                        </Button>
                    </div>
                    <div style={{textAlign:"center"}}>or share the code <b>{this.props.slideData.id + "-" + this.state.presentCode}</b> to start presenting!</div>
                    <div style={{textAlign:"center", fontSize:12}}>The link is already copied to clipboard</div>
                </Row>
            ]
        }

        return (
            <Row className="app-parent overlayed-component present-app-parent">
                <Col>
                    <Button className="present-button" onClick={this.onButtonClick}>
                        <LegacyIcon className={showStopIcon ? "present-icon-presenting present-icon" : "present-icon"} type={showStopIcon ? <CloseCircleOutlined /> : <PlayCircleOutlined />}></LegacyIcon>
                        <div>{showStopIcon ? "Stop" : "Start"}</div>
                        <div>Presentation</div>
                    </Button>
                </Col>
                <br/>
                {codeDiv}
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer
    }
}

export default connect(mapStateToProps)(PresentApp);