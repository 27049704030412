import React, { Component } from 'react';

import {
	CheckCircleFilled,
	CloseOutlined,
	DoubleLeftOutlined,
	DoubleRightOutlined,
	PlusOutlined,
} from '@ant-design/icons';

import { message, Tooltip, Empty } from 'antd';
import { connect } from 'react-redux'
import axios from 'axios';
import cookie from "react-cookies";
import { AuthHeader } from "../../../helper/auth.token"

import '../../../asset/style/neoviewer/slide_info.css'
import '../../../asset/style/custom_antd_table_style.css';
import { globalUrlPrefix, openInNewTabType } from '../../../utils/const';
import { encodeStringWithNewLine, decodeStringWithNewLine, getLoadingComponent, getErrorComponent } from "../../dashboard/slidelist_utils";
import { updateCase } from '../../../action/slide.traversal.action';
import {
	Grid,
	Button,
	Divider,
	TextField,
	Table,
	TableRow,
	TableCell,
	Paper,
	TableContainer,
	TableBody, TableHead, Stack
} from "@mui/material";
import {displayError} from "../../../helper/display.error";
import Typography from "@mui/material/Typography";
// import { DataGrid } from '@mui/x-data-grid';


class CaseInfoApp extends Component {
	constructor(props) {
		super(props);
		this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
		this.state = {
			slide_data: this.slideState.slide_data,
			slides: [],
			curr_case: {},
			sample_id: this.slideState.slide_data.name,
			patient_id: '',
			patient_name: '',
			age_sex: '',
			patient_scans_done: [],
			patient_scans_pending: [],
			inputValue: '',
			inputVisible: false,
			users: {},
			comments: [],
			viewMode: true,
			viewModeSlideFailed: false,
			lastIndex: -1,
			isFetchingDetails: true,
			isFetchingCases: true,
			isFetchingComments: true,
			hasDetailsThrownError: true,
			hasCasesThrownError: true,
			hasCommentsThrownError: true,
			unread_count: 0,
		}
	}

	get_patient_details = () => {
		this.setState({
			isFetchingDetails: true,
			hasDetailsThrownError: true,
			isFetchingCases: true,
			hasCasesThrownError: true,
		});
		let url = '/api/case/' + this.state.slide_data.case + `/`;
		axios.get(url, { headers: { Authorization: AuthHeader() } })
			.then(response => {
				let curr_case = {};
				if (response.data.id) {
					curr_case = response.data;
					this.get_patient_slides(curr_case.id);
					this.setState({
						viewMode: true,
						curr_case,
						patient_id: (curr_case.case_id || ''),
						patient_name: (curr_case.patient_name || ''),
						age_sex: (curr_case.age_and_sex || ''),
						isFetchingDetails: false,
						hasDetailsThrownError: false,
					});
				} else {
					this.setState({
						viewMode: false,
						isFetchingDetails: false,
					});
				}
			})
			.catch(err => {
				console.log("Failed request", err);
				this.setState({
					viewMode: true,
					isFetchingDetails: false,
					isFetchingCases: false,
				});
			});
	}

	get_patient_slides = (case_id) => {
		let url = '/api/nonpagslides/?case_id=' + case_id;
		axios.get(url, { headers: { Authorization: AuthHeader() } })
			.then(response => {
				let all_patient_scans_done = response.data;
				let patient_scans_done = [];
				let unread_count = 0;
				for (let i = 0; i < all_patient_scans_done.length; i++) {
					all_patient_scans_done[i].key = all_patient_scans_done[i].id;
					all_patient_scans_done[i].original_name = all_patient_scans_done[i].name;
					all_patient_scans_done[i].name = all_patient_scans_done[i].unread ?
						<b> {this.getSlideNameComponent(all_patient_scans_done[i])} </b> :
						this.getSlideNameComponent(all_patient_scans_done[i]);
					all_patient_scans_done[i].type = openInNewTabType.SLIDE;
					if(this.state.slide_data.id !== all_patient_scans_done[i].id) {
						patient_scans_done.push(all_patient_scans_done[i]);
						if(all_patient_scans_done[i].unread) {
							unread_count++;
						}
					}
				}
				this.props.dispatch(updateCase(case_id, all_patient_scans_done, this.state.slide_data.id));
				this.setState({
					patient_scans_done,
					isFetchingCases: false,
					hasCasesThrownError: false,
					unread_count,
				});
			})
			.catch(err => {
				console.log("Failed request", err);
				this.setState({
					isFetchingCases: false,
				});
			});
	}

	getSlideNameComponent = (slide) => {
		return (
			<span>
                {slide.name} &nbsp;
				<CheckCircleFilled style={{color: "blue"}} />
            </span>
		);
	}

	get_comments = () => {
		let url = '/api/microscopy_observations/?slide_id=' + this.state.slide_data.id;
		axios.get(url, { headers: { Authorization: AuthHeader() } })
			.then(response => {
				let data = response.data;
				for (let i = 0; i < data.length; i++) {
					data[i].key = data[i].id;
					let modifiedValue = decodeStringWithNewLine(data[i].observation);
					data[i].observation_original = data[i].observation;
					data[i].observation = modifiedValue;
				}
				this.setState({
					comments: data,
					isFetchingComments: false,
					hasCommentsThrownError: false,
				});
			})
			.catch(err => {
				console.log("Failed request", err);
				this.setState({
					isFetchingComments: false,
				});
			});
	}

	componentDidMount = () => {
		this.get_comments();
		this.get_patient_details();
	}

	addComment = () => {
		let modifiedValue = encodeStringWithNewLine(this.state.inputValue);
		let val = {
			"slide": this.state.slide_data.id,
			"case": this.state.curr_case.id,
			"observation": modifiedValue,
			"user": parseInt(cookie.loadAll().user_id)
		};
		let url = `/api/microscopy_observations/`;
		axios.post(url, val, { headers: { Authorization: AuthHeader() } })
			.then(response => {
				if (response.status === 201 || response.status === 200 || response.status === 301 || response.status === 302) {
					let comments = this.state.comments;
					response.data.key = response.data.id;
					let modifiedValue = decodeStringWithNewLine(response.data.observation);
					response.data.observation_original = response.data.observation;
					response.data.observation = modifiedValue;
					comments.push(response.data);
					this.setState({
						comments,
						inputValue: '',
						inputVisible: false,
						lastIndex: comments.length - 1,
					});
					message.success('Observation Added Successfully', 2.5);
					this.handleScroll();
				}
				else if (response.status === 403 || response.status === 401 || response.status === 400) {
					displayError('Observation not inserted');
				}
			})
			.catch(error => {
				displayError('Observation not inserted', error);
			});
	}


	handleScroll = () => {
		document.querySelector('.scroll-row').scrollIntoView();
	}

	showInput = () => {
		this.setState({ inputVisible: true }, () => this.input.focus());
	}

	handleInputChange = (e) => {
		this.setState({ inputValue: e.target.value });
	}

	changeInputView = () => {
		this.setState({ inputVisible: false, inputValue: '' });
	}

	goToCase = () => {
		let case_id = this.state.slide_data.case;
		window.location.href = "/" + globalUrlPrefix + "/case/" + case_id;
	}

	goToPrevSlide = () => {
		window.location.href = `/${globalUrlPrefix}/${(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).viewer}/${this.props.prev_slide_id}`;
	}

	goToNextSlide = () => {
		window.location.href = `/${globalUrlPrefix}/${(JSON.parse(localStorage.getItem('morpheus_setting')) || {}).viewer}/${this.props.next_slide_id}`;
	}

	saveInputRef = input => this.input = input

	render() {

		this.slideState = this.props.mapsState[this.props.activeMapId].slideState;
		if (!this.slideState) return <div />;

		let overallFetchingState = (this.state.isFetchingCases && this.state.isFetchingComments && this.state.isFetchingDetails);

		let overallErrorState = (this.state.hasCasesThrownError && this.state.hasCommentsThrownError && this.state.hasDetailsThrownError);

		let patient_scans_done_count = 'Other Case Scans Done (Total ' + this.state.patient_scans_done.length + ' scans found)';

		let slide_number = 'Slide ' + (this.props.current_index + 1) +  ' of ' + this.props.case_slides_in_store.length;
		let unread_slide_text = '(' + this.state.unread_count + ' unread)';

		const createTableRow = (key, value) =>
			<TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell component={"th"} scope={"row"}>{key}</TableCell>
				{value ? <TableCell align={"right"}>{value}</TableCell> : undefined}
			</TableRow>;

		let patient_scans_done_card = [
			<Grid container key="slide-info-details">
				<Grid item xs={12}>
					<h3 style={{ color: "white", textAlign: 'center', margin: "5px" }}>{patient_scans_done_count}</h3>
					<Divider style={{margin: "10px"}} />
				</Grid>
				{this.state.isFetchingCases ? getLoadingComponent() :
					this.state.hasCasesThrownError && false ? getErrorComponent("Not able to retrieve scans.") :
						this.state.patient_scans_done.length > 0 ?
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>Slide</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.state.patient_scans_done.map(scan =>
											createTableRow(scan.name))
										}
									</TableBody>
								</Table>
								<Divider style={{margin: "10px"}} />
							</TableContainer> : <Empty description="No Scans Found" />
				}
			</Grid>
		];

		let comments_info_card = [
			<Grid container key="comment-info-details">
				<Grid item xs={12}>
					<h3 style={{ color: "white", textAlign: 'center', margin: "5px" }}>Microscopy Observations</h3>
					<Divider style={{margin: "10px"}} />
				</Grid>

				{!this.state.inputVisible && !this.state.isFetchingComments && !this.state.hasCommentsThrownError &&
					<Button onClick={this.showInput} color={"secondary"} disabled={(this.props.urlState || {}).presentCode !== undefined}>
						<PlusOutlined /> New Observation
					</Button>
				}
				{this.state.inputVisible && !this.state.isFetchingComments && !this.state.hasCommentsThrownError &&
					<Grid container>
						<Grid item xs={10}>
							<TextField
								multiline
								inputRef={this.saveInputRef}
								placeholder="Observation"
								value={this.state.inputValue}
								rows={3}
								onChange={this.handleInputChange}
							/>
						</Grid>
						<Grid item xs={2}>
							<Tooltip placement="bottomRight" title="Cancel">
								<Button color={"error"} onClick={this.changeInputView}>
									<CloseOutlined />
								</Button>
							</Tooltip>
						</Grid>
						<Grid item xs={2} />
						<Grid item margin={"10px"}>
							<Button variant={"contained"} size={"small"} color="success" onClick={this.addComment}>
								Insert
							</Button>
						</Grid>
					</Grid>
				}
				<Grid item xs={12}>
					<Divider style={{margin: "10px"}} />
				</Grid>
				{this.state.isFetchingComments ? getLoadingComponent() :
					this.state.hasCommentsThrownError ? getErrorComponent("Not able to retrieve observations.") :
						<TableContainer component={Paper}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>User</TableCell>
										<TableCell align={"right"}>Observation</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.comments.map(comment =>
										createTableRow(comment.user_name, comment.observation))
									}
								</TableBody>
							</Table>
							{this.state.comments.length < 1 ?
								<Grid item xs={12}>
									<h4 style={{color: "red", textAlign: "center"}}>No data to show</h4>
								</Grid> : undefined
							}
							<Divider style={{margin: "10px"}} />
						</TableContainer>
				}
			</Grid>
		];

		return overallFetchingState ? getLoadingComponent() : overallErrorState ? getErrorComponent() :
			this.state.viewModeSlideFailed ? getErrorComponent() : !this.state.viewMode ?
				<h4 style={{color: "white", textAlign: "center", margin: "20px"}}>No Cases Found</h4> :
				<Grid>
					{!this.state.hasCasesThrownError && this.props.case_slides_in_store.length > 1 ?
						<Grid>
							<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
								<Button variant={"outlined"} color={"secondary"} size={"small"}
								        onClick={this.goToPrevSlide}
								        disabled={(this.props.urlState || {}).presentCode}>
									<DoubleLeftOutlined /> Prev Slide
								</Button>
								<Stack sx={{width: 0.5}} direction={"column"} alignItems={"center"}>
									<Typography sx={{fontWeight: 20}}>{slide_number}</Typography>
									<Typography sx={{fontWeight: 20}}>{unread_slide_text}</Typography>
								</Stack>
								<Button variant={"outlined"} color={"secondary"} size={"small"}
								        onClick={this.goToNextSlide}
								        disabled={(this.props.urlState || {}).presentCode !== undefined}>
									Next Slide <DoubleRightOutlined />
								</Button>
							</Stack>
							<Divider style={{margin: "10px"}} />
						</Grid> : undefined
					}
					{this.state.isFetchingDetails ? getLoadingComponent() :
						this.state.hasDetailsThrownError ?
							getErrorComponent("Not able to retrieve slide details.") :
								<Grid>
									<Stack direction={"column"}>
										{[
											["Patient Name", this.state.patient_name],
											["DOB/SEX", this.state.age_sex],
											["SAMPLE ID", this.state.sample_id],
										].map(row =>
											<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
												<Typography sx={{color: 'white', fontWeight : 700}}>{row[0]}</Typography>
												<Typography sx={{color: 'white', fontWeight : 500}}>{row[1]}</Typography>
											</Stack>
										)}
									</Stack>
									<Grid item>
										<span style={{float :'left', marginTop: "20px"}}>
                                            {(this.state.slide_data.case &&
	                                            this.state.slide_data.case !== '') ?
	                                            <Button color={"secondary"} onClick={this.goToCase}
	                                                    style={{ marginLeft: "20px" }}
	                                                    disabled={(this.props.urlState || {}).presentCode}>
		                                            Go to the Case
	                                            </Button> : null
                                            }
                                        </span>
									</Grid>
								</Grid>
					}
					<Grid item xs={12}>
						<Divider style={{margin: "10px"}} />
					</Grid>
					{comments_info_card}
					{patient_scans_done_card}
				</Grid>
	}
}

const mapStateToProps = (state) => {
	return {
		next_slide_id: state.slideTraversalReducer.next_slide_id,
		prev_slide_id: state.slideTraversalReducer.prev_slide_id,
		case_slides_in_store: state.slideTraversalReducer.slides,
		current_index: state.slideTraversalReducer.current_index,
		urlState: state.viewerUrlReducer,
		activeMapId: state.gammaStateReducer.activeMapId,
		mapsState: state.mapsStateReducer,
	}
}

export default connect(mapStateToProps)(CaseInfoApp)
