import React, { Component } from "react";
import { connect } from 'react-redux';
import { Row, Col, Button, Icon, Empty } from 'antd';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';
import { getPrefixedUrl } from '../../../utils/utils';
import { updateTileCoordinate, updateQuadrant } from "../../../action/morpheus.state.action";

import "../../../asset/style/neoviewer/z_stack_app.css"

class ZStackApp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            imageLoading: true,
            zLevel: 0,
            xOffset: 0,
            yOffset: 0,
            currentZ: 0,
        }
        this.zStackAppRef = React.createRef();
    }

    imageLoaded = () => {
        this.setState({
            imageLoading: false,
        });
    }

    componentDidMount = () => {
        let self = this;
        this.zStackAppRef.current.addEventListener('keydown', function (e) {
            if (e.code == 'KeyW') {
                self.changeTiledImageCoordinatesBy(0, -1);
            } else if (e.code == 'KeyA') {
                self.changeTiledImageCoordinatesBy(-1, 0);
            } else if (e.code == 'KeyS') {
                self.changeTiledImageCoordinatesBy(0, 1);
            } else if (e.code == 'KeyD') {
                self.changeTiledImageCoordinatesBy(1, 0);
            }
        }, true);

        this.zStackAppRef.current.addEventListener('wheel', function (e) {
            if (e.deltaY > 0) {
                self.decrementZLevel();
            } else if (e.deltaY < 0) {
                self.incrementZLevel();
            }
        }, true);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.urlState.tileCoord != this.props.urlState.tileCoord || prevProps.urlState.quadrant != this.props.urlState.quadrant) {
            this.setState({
                currentZ: this.props.urlState.z,
            });
        }
    }

    changeTiledImageCoordinatesBy = (xChange, yChange) => {
        let coordinates = this.props.urlState.tileCoord;

        let xOffset = this.props.urlState.quadrant[0];
        let yOffset = this.props.urlState.quadrant[1];
        let zLevels = this.props.slide_data.z_levels.split(",");
        let maxZLevel = parseInt(zLevels[zLevels.length - 1]);

        let xCoord = coordinates[0] + xChange;
        let yCoord = coordinates[1] + yChange;

        if (this.state.currentZ >= maxZLevel - 1) {

            let maxIndex = Math.pow(2, 2 - (maxZLevel - this.state.currentZ));

            if ((xOffset == 0 && xChange == -1)
                || (xOffset == maxIndex - 1 && xChange == 1)
                || (yOffset == 0 && yChange == -1)
                || (yOffset == maxIndex - 1 && yChange == 1)) {
                let imageName = "x" + xCoord + "y" + yCoord + ".jpg";
                if (this.props.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
                    this.updateTile(xCoord, yCoord);
                    let newXOffset = (xOffset + xChange) % maxIndex;
                    let newYOffset = (yOffset + yChange) % maxIndex;
                    newXOffset = newXOffset < 0 ? newXOffset + maxIndex : newXOffset;
                    newYOffset = newYOffset < 0 ? newYOffset + maxIndex : newYOffset;
                    this.props.dispatch(updateQuadrant([newXOffset, newYOffset]));
                    this.updateRectangle(xCoord, yCoord, newXOffset, newYOffset);
                }
            } else {
                this.props.dispatch(updateQuadrant([xOffset + xChange, yOffset + yChange]));
                this.updateRectangle(coordinates[0], coordinates[1], xOffset + xChange, yOffset + yChange);
            }
        } else {
            this.updateTile(xCoord, yCoord);
            this.updateRectangle(xCoord, yCoord, xOffset, yOffset);
        }
    }

    updateTile = (xCoord, yCoord) => {
        let imageName = "x" + xCoord + "y" + yCoord + ".jpg";
        if (this.props.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
            this.props.updateTileCoordinates([xCoord, yCoord]);
            this.props.dispatch(updateTileCoordinate([xCoord, yCoord]));
        }
    }

    updateRectangle = (xCoord, yCoord, newXOffset, newYOffset) => {
        let bounds = this.getBounds([xCoord, yCoord], [newXOffset, newYOffset]);
        let imageName = "x" + xCoord + "y" + yCoord + ".jpg";
        if (this.props.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {

            let style = new Style({
              stroke: new Stroke({
                  color: '#50ace9',
              }),
              fill: new Fill({
                  color: 'rgba(0, 0, 0, 0.0)'
              })
            });

            let feature = new Feature({
                geometry: new Polygon(bounds),
            });

            feature.setStyle(style);
            // feature.set('color', 'black');

            this.props.vectorLayer.setSource(new VectorSource({
                features: [feature],
                wrapX: false
            }));
        }
    }

    getBounds = (tileCoord, quadrant) => {
      let imageName = "x" + tileCoord[0] + "y" + tileCoord[1] + ".jpg";
      let maxZoom = parseInt(this.props.slide_data.z_levels.split(",")[this.props.slide_data.z_levels.split(",").length - 1]);
      let divisions = Math.pow(2, this.props.urlState.z == maxZoom ? 2: this.props.urlState.z == maxZoom - 1 ? 1 : 0);
      let widthThreshold = (2049 * this.props.slide_data.uperpixel) / divisions;
      let heightThreshold = (2449 * this.props.slide_data.uperpixel) / divisions;
      let xOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[0] : 0;
      let yOffset = this.props.urlState.z >= maxZoom - 1 ? quadrant[1] : 0;
  
      if (this.props.imageLog["map"]["stitching_info"]["map"][imageName] != undefined) {
        let imagePos = this.props.imageLog["map"]["stitching_info"]["map"][imageName]["map"]["Absolute Position"]["map"];
        let pointA = [(xOffset * widthThreshold) + (imagePos["X"] * this.props.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.props.imageShape[1] - ((imagePos["Y"] * this.props.slide_data.uperpixel) + heightThreshold)];
        let pointB = [(xOffset * widthThreshold) + (imagePos["X"] * this.props.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.props.imageShape[1] - ((imagePos["Y"] * this.props.slide_data.uperpixel) + heightThreshold)];
        let pointC = [(xOffset * widthThreshold) + (imagePos["X"] * this.props.slide_data.uperpixel) + widthThreshold, (-1 * yOffset * heightThreshold) + this.props.imageShape[1] - (imagePos["Y"] * this.props.slide_data.uperpixel)];
        let pointD = [(xOffset * widthThreshold) + (imagePos["X"] * this.props.slide_data.uperpixel), (-1 * yOffset * heightThreshold) + this.props.imageShape[1] - (imagePos["Y"] * this.props.slide_data.uperpixel)];
        return [[pointA, pointB, pointC, pointD, pointA]];
      } else {
        return [[]];
      }
    }

    getImage = (url) => {
        let filterValue = "brightness(" + (this.props.viewerSettingData.brightness / 100) + ") contrast(" +
            (2 * (this.props.viewerSettingData.contrast / 100)) + ") saturate(" + (this.props.viewerSettingData.saturation / 100) +
            ") invert(" + (this.props.viewerSettingData.invert / 100) + ") grayscale(" + (this.props.viewerSettingData.grayscale / 100) + ")";

        let xOffset = this.props.urlState.quadrant[0];
        let yOffset = this.props.urlState.quadrant[1];

        let zLevels = this.props.slide_data.z_levels.split(",");
        let maxZLevel = parseInt(zLevels[zLevels.length - 1]);

        let topMargin = -yOffset * this.props.stackimage.size[1];
        let leftMargin = -xOffset * this.props.stackimage.size[0];

        let scaleFactor = this.state.currentZ >= maxZLevel - 1 ? Math.pow(2, 2 - (maxZLevel - this.state.currentZ)) : 1;
        let marginStyle = this.state.currentZ >= maxZLevel - 1 ? topMargin + "px 0px 0px " + leftMargin + "px" : "0px 0px 0px 0px";

        return <div style={{ width: this.props.stackimage.size[0], height: this.props.stackimage.size[1], overflow: 'hidden' }}>
            <img
                src={url}
                alt=""
                style={
                    {
                        width: this.props.stackimage.size[0] * scaleFactor,
                        height: this.props.stackimage.size[1] * scaleFactor,
                        filter: filterValue,
                        margin: marginStyle
                    }
                }
                onLoad={() => this.imageLoaded()}
            />
        </div>
    }

    incrementZLevel = () => {
        let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
        if (this.state.zLevel < maxZLevel) {
            this.setState({
                zLevel: this.state.zLevel + 1,
            });
        }
    }

    decrementZLevel = () => {
        let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;
        if (this.state.zLevel > minZLevel) {
            this.setState({
                zLevel: this.state.zLevel - 1,
            });
        }
    }

    render() {

        let url = '';
        if (this.props.slide_data != undefined && this.props.stackimage) {
            let lastTilingLevel = this.props.slide_data.tiling_z_levels.split(",")[this.props.slide_data.tiling_z_levels.split(",").length - 1];
            let urlSuffix;
            if (this.state.zLevel == 0) {
                urlSuffix = `${this.props.slide_data.path}tiled/`;
            } else {
                urlSuffix = `${this.props.slide_data.path}stack/`;
            }
            url = getPrefixedUrl(urlSuffix, this.props.slide_data);
            if(this.state.zLevel !== 0){
                url = `${url}S${this.state.zLevel}/`;
            }
            url = `${url}${lastTilingLevel}/x${this.props.urlState.tileCoord[0]}y${this.props.urlState.tileCoord[1]}.${this.props.slide_data.img_type}`;
        }

        let maxZLevel = this.props.urlState.takeBidirectionalZStack ? (this.props.urlState.numZLevels - 1) / 2 : (this.props.urlState.numZLevels - 1);
        let minZLevel = this.props.urlState.takeBidirectionalZStack ?  (-1 * ((this.props.urlState.numZLevels - 1) / 2)) : 0;

        return (
            <div className="app-parent overlayed-component box-component z-stack-app-width">
                <br />
                <div ref={this.zStackAppRef} tabindex="-1">
                    {this.props.urlState.takeZStack ?
                        this.props.slide_data != undefined && this.props.stackimage ?
                            <div>
                                <Row>
                                    <Col offset={11} span={2}>
                                        <Button type="primary" size="small" onClick={() => this.changeTiledImageCoordinatesBy(0, -1)}><Icon type="up" /></Button>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col span={1} style={{ marginTop: 280, marginLeft: 4 }}>
                                        <Button type="primary" size="small" onClick={() => this.changeTiledImageCoordinatesBy(-1, 0)}><Icon type="left" /></Button>
                                    </Col>
                                    <Col span={21} style={{ marginLeft: 7 }}>
                                        {this.getImage(url)}
                                    </Col>
                                    <Col span={1} style={{ marginLeft: 4 }}>
                                        <Row>
                                            <Button type="primary" size="small" onClick={this.incrementZLevel}
                                                style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
                                                disabled={this.state.zLevel >= maxZLevel}>
                                                <Icon type="plus" />
                                            </Button>
                                        </Row>
                                        <Row>
                                            <Button size="small" style={{ cursor: 'default', width: 30, borderRadius: 0 }}>
                                                {this.state.zLevel}
                                            </Button>
                                        </Row>
                                        <Row>
                                            <Button type="primary" size="small" onClick={this.decrementZLevel}
                                                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
                                                disabled={this.state.zLevel <= minZLevel}>
                                                <Icon type="minus" />
                                            </Button>
                                        </Row>
                                        <Row style={{ marginTop: 200 }}>
                                            <Button type="primary" size="small" onClick={() => this.changeTiledImageCoordinatesBy(1, 0)}><Icon type="right" /></Button>
                                        </Row>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col offset={11}>
                                        <Button type="primary" size="small" onClick={() => this.changeTiledImageCoordinatesBy(0, 1)}><Icon type="down" /></Button>
                                    </Col>
                                </Row>
                                <br />
                                <div>
                                    <b style={{fontSize: 13, marginLeft: 15}}>
                                        Instructions:
                                    </b>
                                    <div style={{fontSize: 13, marginLeft: 15}}>
                                        Use W, A, S, D keys to move top, left, bottom, and right respectively.
                                    </div> 
                                    <div style={{fontSize: 13, marginLeft: 15}}>
                                        Please click on the app first before panning around.
                                    </div>
                                </div>
                                <br/>
                            </div> :
                            <div style={{textAlign: 'center'}}>
                                <br />
                                <Empty description="No Image Selected" />
                                <br />
                                <br />
                            </div> :
                        <div style={{textAlign: 'center'}}>
                            <br />
                            <Empty description="Z Stack not taken for this scan" />
                            <br />
                            <br />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        viewerSettingData: state.viewerSettingReducer,
    }
}

export default connect(mapStateToProps)(ZStackApp);
