import React, {Component} from  "react";
import { connect } from "react-redux";
import Draggable from '../utils/draggable';
import { CloseCircleTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { updateAppClosedStatus } from '../../action/admin.state.action';
import { draggableType } from '../../utils/const';

import "../../asset/style/utils/app.css";

class ManualModeApp extends Component {
    constructor(props) {
        super(props);

        let closed = this.props.defaultClosed === undefined ? true : this.props.defaultClosed;
        this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, closed, this.props.urlState));
    }

    closeApp = () => {
        this.props.dispatch(updateAppClosedStatus(this.props.appKey.id, true, this.props.urlState));
    }

    render(){
        let app = undefined;
        let title = this.props.appKey.showTitle ? this.props.appKey.title : '';

        app = <div style={(this.props.appState[this.props.appKey.id] || {}).closed ? {display: "none"} : {}}>
                <Draggable
                    id={this.props.appKey.id}
                    startBottom={this.props.startBottom}
                    startLeft={this.props.startLeft}
                    startTop={this.props.startTop}
                    startRight={this.props.startRight}
                    type={draggableType.MANUAL_MODE}
                    content={
                        <div 
                            id={this.props.id}
                            className={"closeable"}>
                            <div 
                                className="closeable-handle" 
                                onClick={this.closeApp}>
                                <Tooltip 
                                    title="Click to Close"
                                    placement="right"
                                    mouseLeaveDelay={0}
                                    overlayStyle={{
                                        fontSize:"10px"
                                    }}>
                                    <CloseCircleTwoTone className="close-icon" twoToneColor="#de2323" />   
                                </Tooltip> 
                            </div>
                            
                            <div className="app-title">{title}</div>
                            {this.props.content}
                        </div>
                    } />
                </div>

        return app;
    }
}

const mapStateToProps = (state, ownProps) => {
  return {
      appState: state.adminUrlReducer.app_state,
      urlState: state.adminUrlReducer
  };
};

export default connect(mapStateToProps)(ManualModeApp);