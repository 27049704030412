import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Tooltip, notification, Select } from 'antd';
import {connect} from 'react-redux'
import { slideUpdateInitiated, slideUpdateSuccess, slideUpdateFailed } from '../../action/slide.update.action';
import { addTagToTagList } from '../../action/dashboard.action';
import { loadTags } from '../../action/slides.action';
import axios from 'axios';
import {message} from 'antd';
import {AuthHeader} from '../../helper/auth.token'
let querystring = require('querystring');


class EditableTagGroup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tags: this.props.tag,
            inputVisible: false,
            inputValue: '',
            tag_suggestions: [],
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tag !== this.props.tag) {
            this.setState({
                tags: this.props.tag,
                inputVisible: false,
                inputValue: '',
                tag_suggestions: [],
            });
        }
    }

    typeAheadSearch = (value) => {
        if (value.length >= 1) {
          var prefix = '_' + value;
          let url = "/api/typeahead/?prefix=" + prefix;
          axios
            .get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
              var res_list = response.data;
              var i=0;
              for (i=0; i<res_list.length; i++){
                res_list[i].display_text = res_list[i].display_text.replace(/^_/, "#");
              }
              this.setState({
                tag_suggestions: res_list
              });
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          this.setState({
            tag_suggestions: []
          });
        }
    }

    handleClose = (removedTag) => {
        const tags = this.state.tags.filter(tag => tag !== removedTag);
        this.setState({ tags });
        this.removeSlideTag(removedTag);
    }
    openNotification = (value) => {
        notification.open({
          message: value,
          placement :'bottomRight'
        //   description: 'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        });
        notification.config({
            placement: 'bottomRight',
          });
      };


    removeSlideTag= (removedTag) => {
        let url = `/api/remove_slide_tag/`;
        let val = {"title": removedTag, "slide_id" : this.props.morphle_id};
        axios.post(url, querystring.stringify(val), {headers: {Authorization : AuthHeader()}})
            .then(response => {
            if(response.status === 200 || response.status === 301 || response.status === 302) {
                this.props.dispatch(slideUpdateSuccess(response));
                this.props.dispatch(loadTags());
                this.openNotification('Slide Update Successful')
            }
            else if(response.status === 403 || response.status === 401 || response.status === 400) {
                this.props.dispatch(slideUpdateFailed(response));
                this.openNotification('Slide Update Failed')
            }
        })
            .catch(err =>{
                this.props.dispatch(slideUpdateFailed(this.props.slide_id, err));
        });
    }

    showInput = () => {
        this.setState({ inputVisible: true }/*, () => this.input.focus()*/);
    }

    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    }

    addSlideTag= (values) => {
        if(values.length > 0) {
            const {inputValue}  = this.state;
            let { tags } = this.state;
            let stripped = ('#' + values.toString().replace('#', '').replace(' ', '')).toLowerCase();
            if (stripped && tags.indexOf(stripped) === -1) {
                tags = [...tags, stripped];
            }

            this.props.dispatch(slideUpdateInitiated());
            let url = `/api/add_slide_tag/`;
            let val = {"title": stripped, "slide_id" : this.props.morphle_id};
            axios.post(url, querystring.stringify(val), {headers: {Authorization : AuthHeader()}})
                .then(response => {
                if(response.status === 200 || response.status === 301 || response.status === 302) {
                    this.props.dispatch(slideUpdateSuccess(this.props.slide_id, response));
                    if(!this.props.allTags.includes(stripped)) {
                        this.props.dispatch(addTagToTagList(stripped));
                    }
                    message.success('Slide Update Successful', 2.5);
                }
                else if(response.status === 403 || response.status === 401 || response.status === 400) {
                    this.props.dispatch(slideUpdateFailed(this.props.slide_id, response));
                    message.error('Slide Update Failed', 2.5);
                }
            })
                .catch(err =>{
                    this.props.dispatch(slideUpdateFailed(this.props.slide_id, err));
            });
            this.setState({
                tags,
                inputVisible: false,
                inputValue: '',
                tag_suggestions: []
            });
        } else {
            this.setState({
                inputVisible: false,
                inputValue: '',
                tag_suggestions: []
            });
        }
    }

    onBlurAction= (values) => {
        this.setState({
            inputVisible: false,
            inputValue: '',
            tag_suggestions: []
        });
    }

    //saveInputRef = input => this.input = input

    render() {
        const { tags, inputVisible, inputValue } = this.state;

        let selected = [];

        let numSuggested = this.state.tag_suggestions.length;
        let tagSuggestionsBox = numSuggested > 0 ?

            <Select.OptGroup label={<div><b>{numSuggested}</b> result{numSuggested > 1 ? 's' : ''} found </div>}>
                {this.state.tag_suggestions.map((item, index) => (
                <Select.Option
                    key={index}
                    value={item.display_text}
                    label={item.display_text}
                    className='search-result-tag'
                    size='small'
                    type='tag'
                >
                    {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
                </Select.Option>
                ))}
            </Select.OptGroup> : undefined;

        return (
            <div>
                {tags.map((tag, index) => {
                const isLongTag = tag.length > 20;
                let color = 'magenta';
                let tagElem;
                if((this.props.urlState || {}).presentCode != undefined || ((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience)) {
                    tagElem = (
                        <Tag key={tag} color={color}>
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                } else {
                    tagElem = (
                        <Tag key={tag} closable='true' color={color} onClose={() => this.handleClose(tag)}>
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </Tag>
                    );
                } 
                return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
                })}
                {inputVisible && !((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) && (
                    <Select
                        showSearch
                        allowClear
                        autoFocus
                        className="search-tag"
                        showArrow={false}
                        filterOption={false}
                        notFoundContent={null}
                        mode="tags"
                        onSearch={this.typeAheadSearch}
                        onSelect={this.addSlideTag}
                        onBlur={this.onBlurAction}
                        optionLabelProp="label"
                        value={selected}
                        style={{width:'100%'}}
                    >
                        {tagSuggestionsBox}
                    </Select>
                )}
                {!inputVisible && this.props.edit && !((JSON.parse(localStorage.getItem('morpheus_setting')) || {}).is_audience) && (
                <Tag
                    onClick={this.showInput}
                    style={{ background: '#fff', borderStyle: 'dashed' }}
                >
                    <PlusOutlined /> New Tag
                </Tag>
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus,
        urlState: state.viewerUrlReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        allTags: state.dashboardReducer.allTags
    }
}

export default connect(mapStateToProps)(EditableTagGroup)
