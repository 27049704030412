import {Input, Slider, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import {limitNumber} from "../../../utils/utils";

export const getSettingRow = (name, icon, value, min, max, update) =>
	<Stack direction={"column"}>
		<Typography>{name}</Typography>
		<Stack direction={"row"} justifyContent={"space-between"} spacing={2}>
			{icon}
			<Slider value={value} min={min} max={max} color={'secondary'}
			        onChange={(event, value) => update(limitNumber(value, min, max))}/>
			<Input value={value} size={'small'} sx={{width: '50px'}}
			       onChange={event => update(limitNumber(event.target.value, min, max))}
			       inputProps={{min, max, type: 'number', 'aria-labelledby': 'input-slider',}}/>
		</Stack>
	</Stack>;
