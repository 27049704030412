import React, {Component} from "react";
import {Box, Checkbox, IconButton, List, MenuItem, Select, Stack, Tooltip, Typography} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircleIcon from "@mui/icons-material/Circle";
import AnnotationsDownload from "./annotationsDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import {connect} from "react-redux";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AnnotationAccordion from "./AnnotationAccordion";
import {deleteAnnotation} from "../../../action/maps.state.action";

class AnnotationsExpandableList extends Component {

	constructor(props) {
		super(props);

		this.state = {
			colorFilter: "ALL",
		}
	}

	checkTrigger = () => {
		if (this.props.trigger.expandAnnotation)
			this.props.annotations.forEach(annotation =>
				annotation.expanded = this.props.trigger.expandAnnotation === annotation.id);
		delete this.props.trigger.expandAnnotation;
	}

	changeFilterColor = (e) => {
		if (this.props.onColorFilter)
			this.props.onColorFilter(e.target.value);
		this.setState({
			colorFilter: e.target.value,
		});
	}

	actionOnSelected = () => {
		this.props.actionOnSelected && this.props.actionOnSelected(
			this.props.annotations.filter(annotation => annotation.checked).map(annotation => {
				annotation.checked = false;
				return annotation;
			}));
		this.setState({});
	}

	getDefaultTopComponent = () => {
		let menuItems = [];
		menuItems.push(
			<MenuItem value={"ALL"}>
				<Typography>ALL</Typography>
			</MenuItem>
		)
		Object.entries(this.props.colors).forEach(([name, color]) =>
			menuItems.push(
				<MenuItem label={name} value={color.toLowerCase()}>
					<CircleIcon fontSize="small" sx={{color:color.toLowerCase()}}/>
					{this.props.showColorLabels ? name : null}
				</MenuItem>
			)
		)
		return <Stack direction={"row"} paddingX={1.2} paddingY={0.5} justifyContent={"space-between"}>
			<Select variant={"filled"} value = {this.state.colorFilter} onChange={this.changeFilterColor}>
				{menuItems}
			</Select>
			{this.props.showDownload ? <AnnotationsDownload slideId={this.props.slideId} annoDrawer={0} /> : undefined}
		</Stack>
	}

	getMultipleSelectTopComponent = () =>
		<Stack direction={"row"} paddingX={1.2} justifyContent={"space-between"}>
			<Tooltip title={"Select All"} placement={"right"}>
				<Checkbox edge={"start"}
				          checked={this.props.annotations.reduce((checked, annotation) =>
					          checked && annotation.checked, true) || false}    // || false to handle undefined
				          disableRipple size={'medium'}
				          icon={<CheckBoxOutlineBlankIcon/>}
				          checkedIcon={<CheckBoxIcon color={"secondary"}/>}
				          onChange={e => {
					          this.props.annotations.forEach(annotation => annotation.checked = e.target.checked);
					          this.setState({});
				          }}/>
			</Tooltip>
			{this.props.actionOnSelected ?
				<Tooltip title={this.props.actionTooltip || "Run Action on selected annotation"} placement={"top"}>
					<IconButton onClick={this.actionOnSelected}
					            color={"secondary"}><PlayArrowIcon/></IconButton>
				</Tooltip> :
				undefined
			}
			<Tooltip title={"Delete Selected Annotations"} placement={"right"}>
				<IconButton onClick={() => this.props.annotations.filter(annotation => annotation.checked)
					.forEach(annotation =>
						this.props.dispatch(deleteAnnotation(this.props.activeMapId, annotation)))}
				            color={'secondary'}>
					<DeleteIcon/>
				</IconButton>
			</Tooltip>
		</Stack>
	getTopComponent = () =>
		<Box height={30}>
			{this.props.annotations.reduce((previousValue, currentValue) =>
				previousValue || currentValue.checked, false) ? this.getMultipleSelectTopComponent() :
				this.getDefaultTopComponent()}
		</Box>

	render() {
		this.checkTrigger();

		// if onColorFilter function is passed then do not filter internally, passed function will filter externally
		let annotations = this.props.onColorFilter ? this.props.annotations :
			this.props.annotations.filter(annotation => this.state.colorFilter === 'ALL' ||
				annotation.color.toLowerCase() === this.state.colorFilter);

		return <Box>
			{this.getTopComponent()}
			<List>{annotations.map(annotation =>
					<AnnotationAccordion annotation={annotation} onAccordionChange={() => {
						this.props.annotations.forEach(annotation_ =>
							annotation_.expanded = annotation.id === annotation_.id && !annotation.expanded
						);
						this.setState({});
					}} onCheckboxChange={(e) => {
						annotation.checked = e.target.checked;
						this.setState({});
					}} onAnnotationClick={this.props.onAnnotationClick}/>
				)}
			</List>
		</Box>
	}

}const mapStateToProps = (state) => {
	return {
		activeMapId: state.gammaStateReducer.activeMapId,
		trigger: state.triggerReducer,
	}
}

export default connect(mapStateToProps)(AnnotationsExpandableList)
