import React from 'react';
import { Input, Tag, Tooltip, notification, Select, Modal, Row, Col } from 'antd';
import {connect} from 'react-redux'
import { slideListUpdateSuccess, slideListDeleteSuccess } from '../../action/slides.action';
import { getLabelComponentRotated } from "../dashboard/slidelist_utils";
import axios from 'axios';
import {message} from 'antd';
import {AuthHeader} from '../../helper/auth.token';
import cookie from "react-cookies";
import { globalUrlPrefix } from '../../utils/const';


class CaseSuggestions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValue: '',
            name_suggestions: [],
            selectedCaseID: '',
            selectedSlideNumber: '',
        }

        this.is_cloud = cookie.loadAll().deployment_mode === 'cloud';
        this.isMobile = cookie.loadAll().isMobile == 'true';
        this.iOS = cookie.loadAll().iOS === "true";
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedSlide.id != this.props.selectedSlide.id) {
            this.setState({
                selectedCaseID: this.props.selectedSlide.case == null ? '' : this.props.selectedSlide.case_id,
                selectedSlideNumber: this.props.selectedSlide.name,
            })
        }
    }

    typeAheadSearch = (value) => {
        this.setState({
            inputValue: value,
        });
        if (value.length >= 1) {
            var prefix = value;
            let url = "/api/typeahead_case/?prefix=" + prefix;
            axios
                .get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    var res_list = response.data;
                    var i=0;
                    for (i=0; i<res_list.length; i++){
                        res_list[i].display_text = res_list[i].display_text.replace(/^_/, "").toUpperCase();
                    }
                    this.setState({
                        name_suggestions: res_list,
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            this.setState({
                name_suggestions: []
            });
        }
    }

    selectCase = (values) => {
        if(values == -1) {
            window.location.href = "/" + globalUrlPrefix + "/addcase/?case_id=" + this.state.inputValue + "&slides=" + this.props.selectedSlide.id;
        } else {
            if(values.length > 0 || values >= 0) {
                this.setState({
                    selectedCaseID: values,
                    inputValue: '',
                    name_suggestions: [],
                });
            } else {
                this.setState({
                    inputValue: '',
                    name_suggestions: [],
                    selectedCaseID: '',
                    selectedSlideNumber: '',
                });
            }
        }
    }

    onBlurAction = (values) => {
        this.setState({
            inputValue: '',
            name_suggestions: [],
        });
    }

    handleOk = () => {
        if(this.state.selectedCaseID.length > 0) {
            let url = `/api/assign_case/?case_id=` + this.state.selectedCaseID + `&slide=` + this.props.selectedSlide.id + `&slide_name=` + this.state.selectedSlideNumber;
            axios.get(url, { headers: { Authorization: AuthHeader() } })
                .then(response => {
                    if (response.status === 200 || response.status === 301 || response.status === 302) {
                        if(this.props.urlState.case_not_assigned) {
                            this.props.dispatch(slideListDeleteSuccess(this.props.selectedSlide.id));
                        } else {
                            response.data.result.displayOrder = this.props.selectedSlide.displayOrder;
                            this.props.dispatch(slideListUpdateSuccess(this.props.selectedSlide.id, response.data.result));
                        }
                    } else if (response.status === 403 || response.status === 401 || response.status === 400) {
                        message.error("Some error occured. Not able to assign a case.", 2.5);
                        console.log(response);
                    }
                    this.props.closeModal();
                    this.setState({
                        inputValue: '',
                        name_suggestions: [],
                    });
                })
                .catch(err => {
                    this.props.closeModal();
                    if(err.response.status == 406) {
                        message.error(err.response.data, 2.5);
                    } else {
                        message.error("Some error occured. Not able to assign a case.", 2.5);
                    }
                    console.log(err);
                    this.setState({
                        inputValue: '',
                        name_suggestions: [],
                    });
                });
        } else {
            this.props.closeModal();
            message.error("Some error occured. Not able to assign a case.", 2.5);
            this.setState({
                inputValue: '',
                name_suggestions: [],
            });
        }
    }

    handleCancel = () => {
        this.props.closeModal();
        this.setState({
            inputValue: '',
            name_suggestions: [],
        });
    }

    slideNameChange = (e) => {
        this.setState({
            selectedSlideNumber: e.target.value,
        });
    }

    render() {

        let selected = [];

        let numSuggested = this.state.name_suggestions.length;

        let selectOptions = this.state.name_suggestions.map((item, index) => (
            <Select.Option
                key={index}
                value={item.display_text}
                label={item.display_text}
                className='search-result-tag'
                size='small'
                type='tag'
            >
                {item.display_text} <span style={{ float: "right", fontSize: 12 }}></span>
            </Select.Option>
        ));

        if(this.state.inputValue.length > 0 && this.state.name_suggestions.findIndex((item) => this.state.inputValue.toUpperCase() == item.display_text) == -1) {
            selectOptions.unshift(<Select.Option
                                    key={-1}
                                    value={-1}
                                    label={this.state.inputValue}
                                    className='search-result-tag'
                                    size='small'
                                    type='tag'
                                    >
                                        {<span><b>"{this.state.inputValue.toUpperCase()}"</b> &nbsp; Create new Case</span>} 
                                        <span style={{ float: "right", fontSize: 12 }}></span>
                                    </Select.Option>
                                );
        }

        let tagSuggestionsBox = numSuggested > 0 ?

            <Select.OptGroup /*label={<div><b>{numSuggested}</b> result{numSuggested > 1 ? 's' : ''} found </div>}*/>
                {selectOptions}
            </Select.OptGroup> : (this.state.inputValue.length > 0 && this.state.name_suggestions.findIndex((item) => this.state.inputValue.toUpperCase() == item.display_text) == -1) ?
                                    <Select.Option
                                    key={-1}
                                    value={-1}
                                    label={this.state.inputValue}
                                    className='search-result-tag'
                                    size='small'
                                    type='tag'
                                    >
                                        {<span><b>"{this.state.inputValue.toUpperCase()}"</b> &nbsp; Create new Case</span>} 
                                        <span style={{ float: "right", fontSize: 12 }}></span>
                                    </Select.Option> : undefined;

        return (
            <Modal
                title={this.props.selectedSlide.case == null ? "Assign Case" : "Change Name"}
                visible={this.props.modalVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
                okButtonProps={{disabled: !(this.state.selectedCaseID.length > 0 && this.state.selectedSlideNumber.length > 0)}}
            >
                <div>
                    {this.props.selectedSlide.id ?
                        <Row>
                            <Col span={8} offset={9}>
                                {getLabelComponentRotated(this.props.selectedSlide, this.is_cloud, this.isMobile)}
                            </Col> 
                        </Row> : null
                    }
                    <Row>
                        <Col span={9} offset={1}>
                            <b>CASE ID</b>
                        </Col>
                        <Col span={12} offset={1}>
                            <b>SLIDE NAME</b>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={9} offset={1}>
                            <Input style={{color: "black", cursor: "default"}} 
                                    value={this.state.selectedCaseID} 
                                    disabled 
                            />
                        </Col>
                        {/* <Col span={1} style={{marginTop: '5px', paddingLeft: '7px'}}>
                            <b> - </b>
                        </Col> */}
                        <Col span={13} offset={1}>
                            <Input  value={this.state.selectedSlideNumber} 
                                    onChange={this.slideNameChange} 
                            />
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col offset={1}>
                            <p>Please enter Case ID in the input box below.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={1}>
                            <Select
                                showSearch
                                allowClear
                                autoFocus
                                placeholder="Enter Case ID"
                                className="search-input"
                                showArrow={false}
                                filterOption={false}
                                notFoundContent={null}
                                onSearch={this.typeAheadSearch}
                                onSelect={this.selectCase}
                                onBlur={this.onBlurAction}
                                optionLabelProp="label"
                                value={selected}
                            >
                                {tagSuggestionsBox}
                            </Select>
                        </Col>
                    </Row>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => {
    return{
        loginStatus :  state.loginAlerts.loginStatus,
        urlState: state.searchReducer,
        allSlides: state.slidesReducer
    }
}

export default connect(mapStateToProps)(CaseSuggestions);