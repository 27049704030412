import React, { Component } from "react";
import {Stack, IconButton, Tooltip} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import {morphleBaseTheme1} from "../themes";
import {connect} from "react-redux";
import {message} from "antd";
import {convertGammaStateToUrlParams, convertMapStateToUrlParams} from "../utils/linkMakerUtils";

class ShareLink extends Component {

    copyShareableLinkToClipboard = () => {
        let url = `${window.location.href.split("?")[0]}?`;

        for (let mapId in this.props.mapsState)
            url += `${convertMapStateToUrlParams(this.props.mapsState[mapId])}#`;
        url += `?${convertGammaStateToUrlParams(this.props.gammaState)}`;

        let input = document.createElement('textarea');
        input.innerHTML = url;
        document.body.appendChild(input);
        input.select();
        let result = document.execCommand('copy');
        document.body.removeChild(input);
        message.info("Shareable link copied to clipboard")
        return result;
    }

    render() {
        return <Stack direction={"row"} spacing={1} justifyContent="flex-start" sx={{alignItems: 'center'}}>
            <Tooltip title={"Share as Link"} placement={"bottom"}>
                <IconButton size="small" onClick={this.copyShareableLinkToClipboard}>
                    <ShareIcon sx={{color: morphleBaseTheme1.palette.headBarIcon.primary}}/>
                </IconButton>
            </Tooltip>
        </Stack>
    }
}


const mapStateToProps = (state) => {
    return {
        mapsState: state.mapsStateReducer,
        gammaState: state.gammaStateReducer,
    }
}
export default connect(mapStateToProps)(ShareLink)
