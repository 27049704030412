import React, {Component} from  "react";
import {connect} from 'react-redux';
import { Row, Col, Button, Switch, Divider, Slider, Typography, Popover } from 'antd';
import { CirclePicker as ColorPicker } from 'react-color';
import GridReference from "../../../custom_libs/gridReference";
import { AuthHeader } from "../../../helper/auth.token";
import axios from "axios";

import "../../../asset/style/neoviewer/grid_overlay.css"

const {Text} = Typography;

class GridOverlay extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gridColor: "e91e63",
            gridSize: 500, 
            gridWidth: 3, 
        }

        this.gridRef = null;
    }

    componentDidUpdate = (prevProps, prevState) => {

      let gridValueChange = ((prevState.gridColor != this.state.gridColor) ||
                              (prevState.gridSize != this.state.gridSize) ||
                              (prevState.gridWidth != this.state.gridWidth));

        
      let timestampChange = (prevProps.urlState.grid_timestamp != this.props.urlState.grid_timestamp);

      if(((this.props.appState || {})['present_app'] || {}).code != undefined && gridValueChange) {
        let url = `/api/set_sync_browsing_grid/?sync_code=${this.props.appState['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&gridColor=${this.state.gridColor}&gridSize=${this.state.gridSize}&gridWidth=${this.state.gridWidth}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
          })
          .catch(err => {
            console.log("Failed Setting Key Value");
          });
      }

      if((this.props.urlState || {}).presentCode != undefined && timestampChange) {
        let url = `/api/get_sync_browsing_grid/?sync_code=${this.props.urlState.presentCode}&morphle_id=${this.props.urlState.slide_morphle_id}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            try {
              if(response.data.grid_color != undefined && response.data.grid_size != undefined && response.data.grid_width != undefined) {
                  this.setState({
                    gridColor: response.data.grid_color,
                    gridSize: parseInt(response.data.grid_size),
                    gridWidth: parseInt(response.data.grid_width),
                  })
              }
            } catch (err) {
                console.log(err);
            }
        })
        .catch(err => {
            console.log("Failed Getting Value");
        });
      }
    }

    componentDidMount = () => {
      if(((this.props.appState || {})['present_app'] || {}).code != undefined) {
        let url = `/api/set_sync_browsing_grid/?sync_code=${this.props.appState['present_app'].code}&morphle_id=${this.props.urlState.slide_morphle_id}&gridColor=${this.state.gridColor}&gridSize=${this.state.gridSize}&gridWidth=${this.state.gridWidth}`;
        axios.get(url, { headers: { Authorization: AuthHeader() } })
          .then(response => {
          })
          .catch(err => {
            console.log("Failed Setting Key Value");
          });
      }
    }
  
    changeGridColor = (color, event) => this.setState({gridColor: color.hex.replace('#', '')});

    changeGridSize = (size) => this.setState({gridSize: size});

    changeGridWidth = (width) => this.setState({gridWidth: width});

    drawGrid = () => {
  
      if (this.gridRef) {
        this.props.slidemap.removeControl(this.gridRef);
      }
  
      var extent = this.props.slidemap.getView().getProjection().getExtent();
      var height = extent[3];
      var width = extent[2];
  
      var gridEveryU = this.state.gridSize;
      var gridCols = width / gridEveryU;
      var gridRows = height / gridEveryU;
  
      this.gridRef = new GridReference(
        {
          extent: extent,
          size: [gridCols, gridRows],
          target: document.querySelector(".options div"),
          property: "commune",
          color: '#' + this.state.gridColor,
          width: this.state.gridWidth
        }
      );
  
      this.props.slidemap.addControl(this.gridRef);
    }
  
    render(){ 
        
        if (!(this.props.appState[this.props.id] || {}).closed) {
            this.drawGrid();
        } else if (this.gridRef) {
            this.props.slidemap.removeControl(this.gridRef);
        }

        const marks = {
            500: {
              style: {
                color: 'rgba(19, 144, 228, 0.863)',
              },
              label: <strong className="unselectable">micro<br></br>scope</strong>,
            },
          };

        let gridOptions =
        <Row>
            <Text className="grid-overlay-option-text unselectable">Size</Text>
            <Slider
                style={{ width: "100%" }}
                min={2}
                max={6}
                value={this.state.gridWidth}
                onChange={this.changeGridWidth}
                disabled={(this.props.urlState || {}).presentCode != undefined}
            />
            <Divider />
            <Text className="grid-overlay-option-text unselectable">Color</Text>
            <ColorPicker
                className="grid-overlay-color-picker"
                color={'#' + this.state.gridColor}
                circleSize={15}
                circleSpacing={5}
                onChangeComplete={(this.props.urlState || {}).presentCode != undefined ? null : this.changeGridColor}
                colors={["#e91e63", "#D6D6D6", "#3f51b5", "#4caf50", "#ff9800", "#ffeb3b", "#607d8b"]} />
                <Divider />
                <Text className="grid-overlay-option-text unselectable">Spacing</Text>
                <Slider
                    style={{ width: "100%" }}
                    min={100}
                    max={1500}
                    step={100}
                    dots={true}
                    value={this.state.gridSize}
                    onChange={this.changeGridSize}
                    marks={marks}
                    disabled={(this.props.urlState || {}).presentCode != undefined}
            />
        </Row>

        return (
            <div className="grid-overlay-app-parent app-parent overlayed-component">
                <Divider />
                {gridOptions}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appState: state.viewerUrlReducer.app_state,
        urlState: state.viewerUrlReducer
    }
}

export default connect(mapStateToProps)(GridOverlay);