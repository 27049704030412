import { UserActions } from '../actionTypes/users.constant';
import { searchInitiated, searchFailed } from "../action/search.case.action"
import { SearchAlertUserConstant } from "../actionTypes/search.user.constant";
import axios from 'axios';
import { AuthHeader } from "../helper/auth.token"
import { message } from "antd";
var querystring = require('querystring');

export const changePage = (pageNumber) => ({
    type : SearchAlertUserConstant.USER_CHANGE_PAGE, 
    pageNumber: pageNumber
})

export const changeTotalNumberOfPages = (numPages) => ({
    type : UserActions.CHANGE_TOTAL_NUMBER_OF_PAGES_USER, 
    numPages: numPages
})

export const changeTotal = (total) => ({
    type : UserActions.CHANGE_TOTAL_USER, 
    total: total
})

export const allUsersLoadingSuccess = (success) => (dispatch) => {
    dispatch({
        type: UserActions.ALL_USERS_UPDATE_SUCCESS,
        response: success
    });
    dispatch(changeTotalNumberOfPages(success.data.total_pages));
    dispatch(changePage(success.data.page_number));
    dispatch(changeTotal(success.data.count));
}

export const userListUpdateSuccess = (id, updatedInfo) => ({
    type: UserActions.USER_LIST_UPDATE,
    updatedInfo: updatedInfo,
    id: id
})

export const userListUpdateFailed = (id, error) => ({
    type: UserActions.USER_LIST_UPDATE_FAILED,
    response: error,
    id: id
})


export const loadUsers = (partsOfUrl) => dispatch => {
    dispatch(searchInitiated());

    var encodedUrl = urlEncoder(partsOfUrl);
    let url = `/api/paginated_users/` + encodedUrl;
    axios.get(url, { headers: { Authorization: AuthHeader(), 'Cache-Control': 'no-cache' } })
        .then(response => {
            if (response.status === 200) {
                dispatch(allUsersLoadingSuccess(response));
                dispatch({
                    type: SearchAlertUserConstant.USER_UPDATE_URL_STATE,
                    urlState: {
                        ...partsOfUrl,
                        isFetching: false,
                        page: response.data.page_number,
                        numPages: response.data.total_pages,
                        total: response.data.count
                    },
                });
                dispatch(loadGroups());
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}


export const loadGroups = () => dispatch => {
    let url = `/api/group/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch({
                    type: UserActions.UPDATE_GROUPS,
                    groups: response.data,
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}


export const loadUsernames = () => dispatch => {
    let url = `/api/get_usernames/`;
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200) {
                dispatch({
                    type: UserActions.UPDATE_USERNAMES,
                    usernames: response.data,
                });
            }
            else {
                dispatch(searchFailed(response));
            }
        })
        .catch(err => {
            dispatch(searchFailed(err));
        })
}

export const assignGroupsUpdateAlert = (id, value, selectedRows = []) => (dispatch) => {
    let url = `/api/assign_groups_to_user/`;
    
    axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(getUser(id, value, selectedRows));
            }
        })
        .catch(err => {
            console.log(err);
        });
}

export const removeGroupUpdateAlert = (id, value, urlState) => (dispatch) => {
    let url = `/api/remove_group_from_user/`;
    
    axios.post(url, querystring.stringify(value), { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                dispatch(getUser(id, value));
                message.success("User successfully removed from the group");
            }
        })
        .catch(err => {
            console.log(err);
        });
}

export const getUser = (id, value, selectedRows = []) => (dispatch) => {
    let url = `/api/user/${id}`
    axios.get(url, { headers: { Authorization: AuthHeader() } })
        .then(response => {
            if (response.status === 200 || response.status === 301 || response.status === 302) {
                response.data.displayOrder = value.displayOrder;
                dispatch(userListUpdateSuccess(id, response.data));
                if(selectedRows.length > 0 && id == selectedRows[selectedRows.length - 1]) {
                    message.success("User/User Successfully Updated");
                }
            }
        })
        .catch(err => {
            console.log(err);
        });
}

export const urlEncoder = (partsOfUrl) => {
    var encodedUrl = '?';
    if (partsOfUrl == undefined) {
        return encodedUrl;
    }
    if ('search' in partsOfUrl) {
        encodedUrl += partsOfUrl.search != '' ? '&search=' + partsOfUrl.search : '';
    }
    if ('page' in partsOfUrl) {
        encodedUrl += '&page=' + partsOfUrl.page;
    }
    return encodedUrl;
}