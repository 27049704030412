import React, { Component } from "react";
import { Col, Row, message, Select, Table, Spin } from "antd";
import { connect } from "react-redux";
import axios from "axios";
import { AuthHeader } from "../helper/auth.token";
import { monthMapping } from "../utils/const";

import '../asset/style/dashboard.css';
import '../asset/style/add_case.css';

const { Option } = Select;

class StatsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            selectedYear: new Date().getFullYear(),
            fullStatsData: {},
        };
    }

    componentDidMount = () => {
        this.getStatsData();
    }

    getStatsData = () => {
        this.setState({
            isFetching: true,
        });

        let url = '/api/get_stats_data';
        axios.get(url, { headers: { Authorization: AuthHeader() } })
            .then(response => {
                this.setState({
                    isFetching: false,
                });
                this.setState({
                    fullStatsData: response.data,
                });
            })
            .catch(err => {
                message.error("Failed to get stats. Contact Admin");
                console.log(err);
                this.setState({
                    isFetching: false,
                });
            });
    }

    handleChangeYear = (value) => {
        this.setState({
            selectedYear: value,
        });
    }

    getYearOptions = () => {
        let yearOptions = [];
        for(let i = 2018; i <= new Date().getFullYear(); i++) {
            yearOptions.push(
                <Option value={i}>{i}</Option>
            )
        }
        return yearOptions;
    }

    getScansStatForTable = () => {
        let statData = [];
        if(this.state.fullStatsData[this.state.selectedYear] != undefined) {
            let yearData = this.state.fullStatsData[this.state.selectedYear];
            for(let i = 1; i < 13; i++) {
                statData.push(
                    {
                        'key': i,
                        'month': monthMapping[i],
                        'totalScans': yearData[i].total_scans,
                        'timeComponent': this.getTimeComponent(yearData[i]),
                        'sizeComponent': this.getSizeComponent(yearData[i]),
                        'archivedSizeComponent': this.getArchivedSizeComponent(yearData[i]),
                        'scannedAreaComponent': this.getScannedAreaComponent(yearData[i]),
                        'scanSpeedComponent': this.getScanSpeedComponent(yearData[i]),
                        'objectiveTypeComponent': this.getObjectiveTypeComponent(yearData[i])
                    }
                )
            }
        }
        return statData;
    }

    getTimeComponent = (monthData) => {
        return <div>
            <Row>
                <Col span={8}>
                    <span>{"Mean: "}</span>
                </Col>
                <Col span={16}>
                    <span>{Math.floor(((monthData.mean_time / 1000) / 60)) + " min. " + Math.floor(((monthData.mean_time / 1000) % 60)) + " sec."}</span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Median: "}</span>
                </Col>
                <Col span={16}>
                    <span>{Math.floor(((monthData.median_time / 1000) / 60)) + " min. " + Math.floor(((monthData.median_time / 1000) % 60)) + " sec."}</span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Sum: "}</span>
                </Col>
                <Col span={16}>
                    <span>{Math.floor(((monthData.sum_time / 1000) / 60)) + " min. " + Math.floor(((monthData.sum_time / 1000) % 60)) + " sec."}</span>
                </Col>
            </Row>
            <Row>
                <b>{monthData.missing_slide_meta + " missing"}</b>
            </Row>
        </div>
    }

    getSizeComponent = (monthData) => {
        return <div>
            <Row>
                <Col span={8}>
                    <span>{"Mean: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.mean_size / 1024 / 1024 / 1024) < 1 ? (monthData.mean_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.mean_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Median: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.median_size / 1024 / 1024 / 1024) < 1 ? (monthData.median_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.median_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Sum: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.sum_size / 1024 / 1024 / 1024) < 1 ? (monthData.sum_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.sum_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <b>{monthData.missing_size_meta + " missing"}</b>
            </Row>
        </div>
    }

    getArchivedSizeComponent = (monthData) => {
        return <div>
            <Row>
                <Col span={8}>
                    <span>{"Mean: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.mean_archived_size / 1024 / 1024 / 1024) < 1 ? (monthData.mean_archived_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.mean_archived_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Median: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.median_archived_size / 1024 / 1024 / 1024) < 1 ? (monthData.median_archived_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.median_archived_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Sum: "}</span>
                </Col>
                <Col span={16}>
                    <span>{(monthData.sum_archived_size / 1024 / 1024 / 1024) < 1 ? (monthData.sum_archived_size / 1024 / 1024).toFixed(2) + " MB" : 
                        (monthData.sum_archived_size / 1024 / 1024 / 1024).toFixed(2) + " GB"}
                    </span>
                </Col>
            </Row>
            <Row>
                <b>{monthData.missing_size_meta + " missing"}</b>
            </Row>
        </div>
    }

    getScannedAreaComponent = (monthData) => {
        return <div>
            <Row>
                <Col span={8}>
                    <span>{"Mean: "}</span>
                </Col>
                <Col span={16}>
                    <span>{monthData.mean_area.toFixed(2)}</span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Median: "}</span>
                </Col>
                <Col span={16}>
                    <span>{monthData.median_area.toFixed(2)}</span>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <span>{"Sum: "}</span>
                </Col>
                <Col span={16}>
                    <span>{monthData.sum_area.toFixed(2)}</span>
                </Col>
            </Row>
            <Row>
                <b>{monthData.missing_slide_meta + " missing"}</b>
            </Row>
        </div>
    }

    getScanSpeedComponent = (monthData) => {
        let scanSpeedRows = [];
        let keys = Object.keys(monthData.scan_speed);
        for(let i = 0; i < keys.length; i++) {
            scanSpeedRows.push(
                <Row>
                    <Col span={12}>
                        <span>{keys[i] + ": "}</span>
                    </Col>
                    <Col span={12}>
                        <span>{monthData.scan_speed[keys[i]]}</span>
                    </Col>
                </Row>
            );
        }
        return <div>
            {scanSpeedRows}
            <Row>
                <b>{monthData.missing_scan_speed + " missing"}</b>
            </Row>
        </div>
    }

    getObjectiveTypeComponent = (monthData) => {
        let scanSpeedRows = [];
        let keys = Object.keys(monthData.objective_type);
        for(let i = 0; i < keys.length; i++) {
            scanSpeedRows.push(
                <Row>
                    <Col span={12}>
                        <span>{keys[i] + ": "}</span>
                    </Col>
                    <Col span={12}>
                        <span>{monthData.objective_type[keys[i]]}</span>
                    </Col>
                </Row>
            );
        }
        return <div>
            {scanSpeedRows}
            <Row>
                <b>{monthData.missing_objective_type + " missing"}</b>
            </Row>
        </div>
    }

    render() {

        let columns = [
            {
                title: 'Month',
                dataIndex: 'month',
                key: 'month',
            },
            {
                title: 'Total Scans',
                dataIndex: 'totalScans',
                key: 'totalScans',
            },
            {
                title: 'Time',
                dataIndex: 'timeComponent',
                key: 'timeComponent',
            },
            {
                title: 'Size',
                dataIndex: 'sizeComponent',
                key: 'sizeComponent',
            },
            {
                title: 'Archived Size',
                dataIndex: 'archivedSizeComponent',
                key: 'archivedSizeComponent',
            },
            {
                title: 'Scanned Area (mm2)',
                dataIndex: 'scannedAreaComponent',
                key: 'scannedAreaComponent',
            },
            {
                title: 'Scan Speed',
                dataIndex: 'scanSpeedComponent',
                key: 'scanSpeedComponent',
            },
            {
                title: 'Objective Type',
                dataIndex: 'objectiveTypeComponent',
                key: 'objectiveTypeComponent',
            },
        ];

        let scansStat = this.getScansStatForTable();

        return (
            <div>
                <Spin spinning={this.state.isFetching}>
                    <div>
                        <Row>
                            <Col span={24} offset={0} className="all-slides">
                                <div className="heading-style main-heading-style">
                                    <b>Stats Dashboard</b>
                                </div>
                                <div>
                                    <br />
                                    <Row>
                                        <Col span={2} style={{padding: "5px 0px 0px 20px"}}>
                                            <b>{"Select Year: "}</b>
                                        </Col>
                                        <Col span={5}>
                                            <Select style={{width: 75}}
                                                value={this.state.selectedYear}
                                                placeholder="Select Year"
                                                onChange={this.handleChangeYear}
                                            >
                                                {this.getYearOptions()}
                                            </Select>
                                        </Col>
                                    </Row>
                                    <br/>
                                    <Table
                                        dataSource={scansStat}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Spin>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps)(StatsDashboard);