import React, {Component} from "react";
import {connect} from "react-redux";
import {Box, Grid, Popover, Slider, Tab, Tabs, Tooltip} from '@mui/material';
import Brightness6Icon from '@mui/icons-material/Brightness6';
import ContrastIcon from '@mui/icons-material/Contrast';
import * as enhancementKeys from '../utils/viewerSettingsKeys'
import {brightnessKey, contrastKey, grayscaleKey, hueKey, invertKey, saturationKey} from '../utils/viewerSettingsKeys'
import {updateBrightness, updateContrast,} from "../../../action/maps.state.action";

const Settings = {
    "brightness": "brightness",
    "contrast": "contrast",
}

class HeadBarSettings extends Component {
    constructor(props) {
        super(props);
        // we might have to figure out a way to false all these when anywhere else click on window
        this.state = {
            anchorEl : null,
            anySelected : false,
            value: null
        }
    }

    initSlideData = () => {
        this.activeMapId = this.props.activeMapId;
        this.viewerSettings = this.props.mapsState[this.activeMapId].slideState.slide_data.viewer_settings;
    }

    handleTabChange = (event, value) => {
        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,
            anySelected: true,
            value: value,
        });
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
            anySelected: false,
            value: null
        });
    }

    getImageEnhancementSliderComponent = (enhancementKey, onChange) => {
        let value = this.viewerSettings[enhancementKey.id];
        return <Grid item sm={9} marginLeft={3} marginTop={'0.3vh'}>
            <Slider
                disabled = {(this.props.urlState || {}).presentCode !== undefined}
                value = {value}
                defaultValue={value}
                key={`slider-${enhancementKey.id}`}
                min={enhancementKey.min}
                max={enhancementKey.max}
                onChange={onChange}
                color={'secondary'}
            />
        </Grid>
    }

    render() {
        this.initSlideData();
        let notReady = (!this.viewerSettings || this.viewerSettings[contrastKey.id] === undefined ||
            this.viewerSettings[brightnessKey.id] === undefined || this.viewerSettings[hueKey.id] === undefined ||
            this.viewerSettings[saturationKey.id] === undefined || this.viewerSettings[invertKey.id] === undefined ||
            this.viewerSettings[grayscaleKey.id] === undefined);

        let PopoverContent = null;
        if (!notReady) {
            if (this.state.value === Settings.brightness)
                PopoverContent = this.getImageEnhancementSliderComponent(enhancementKeys.brightnessKey,
                    (event, newValue) =>
                        this.props.dispatch(updateBrightness(this.activeMapId, this.viewerSettings.id, newValue)));
            else if (this.state.value === Settings.contrast)
                PopoverContent = this.getImageEnhancementSliderComponent(enhancementKeys.contrastKey,
                    (event, newValue) =>
                        this.props.dispatch(updateContrast(this.activeMapId, this.viewerSettings.id, newValue)));
        }

        return (
            <Box>
                <Tabs
                    value={this.state.value}
                    onChange={this.handleTabChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tooltip title={"Brightness"} placement={"bottom"} value={Settings.brightness}>
                        <Tab icon={<Brightness6Icon/>} value={Settings.brightness} />
                    </Tooltip>
                    <Tooltip title={"Contrast"} placement={"bottom"} value={Settings.contrast}>
                        <Tab icon={<ContrastIcon />}  value={Settings.contrast} />
                    </Tooltip>
                </Tabs>
                <Popover open={this.state.anySelected}
                         anchorEl={this.state.anchorEl}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         onClose={this.handleClose}
                >
                    <div>
                        <Grid container sx={{width:'10vw'}} alignItems={"center"}>
                            {PopoverContent}
                        </Grid>
                    </div>
                </Popover>
            </Box>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        urlState: state.viewerUrlReducer,
        activeMapId: state.gammaStateReducer.activeMapId,
        mapsState: state.mapsStateReducer,
    }
}

export default connect(mapStateToProps)(HeadBarSettings)
